export default {
	"box-name": "DiNapoli-page-cn",
	"section1": {
		"text1": "乔尔.帝纳波利",
		"text2": "<li>斐波那契技术传奇教父</li><li>帝纳波利点位法创始人</li><li>黄金比率应用头号权威</li>",
		"text3": "帝纳波利是一位资深交易员，拥有超过55年的实战交易经验，至今仍每日交易。他的交易生涯成就斐然，包括60年代在纽交所交易Nifty 50指数，成功预测1987年的市场崩盘，以及在9/11事件后立即投身期货交易。自1982年合约开盘以来，他一直专注于标普指数期货的交易。帝纳波利在置换移动平均线、高级斐波那契技术、摆荡指标预测器和MACD预测器方面的研究享有盛誉。他创造的领先指标，使他成为备受追捧的专家。作为一名拥有超过25年经验的注册交易顾问（CTA），他曾在全球范围内举办讲座，并撰写了广受好评的著作《帝纳波利点位交易法》。该书在全球发行，已被翻译成13种语言，还将有更多版本面世。",
		"text4": "帝纳波利点位交易法",
		"text5": "《帝纳波利点位交易法》是迄今为止，关于斐波那契分析在价格枢轴应用上最全面的书籍。这本权威性的指南为交易者提供了宝贵的见解和技巧，使他们能够以更高的精准度和信心优化交易策略。",
		"text6": "<li>全球黄金率投资交易最权威的著作</li><li>《帝纳波利点位交易法》因在实际交易中的卓越表现，被视为有史以来最伟大的交易书籍之一</li><li>在全球被翻译成超过13种语言</li>",
		"text7": "免费领取",
		"text8": "帝纳波利MT4/MT5指标",
		"text9": "帝纳波利的领先指标，以其高效的趋势识别和支撑阻力价位的精准度量，受到全球交易者的青睐。通过融合多种技术分析方法，这些指标为交易者提供强大的决策支持，并显著提高交易成功率。",
		"text10": "精准趋势捕捉",
		"text11": "置换移动均线（DMA）系统能够快速识别市场趋势，及时抓住机遇",
		"text12": "有效的超买/超卖信号",
		"text13": "摆动指标能够发出市场转折的可靠信号",
		"text14": "精准的支撑位和阻力位",
		"text15": "基于斐波那契分位水平优化交易策略",
		"text16": "深受专业投资者好评",
		"text17": "这么多年来，我所认识的所有真正赚了大钱的优秀交易员，都上过你的课",
		"text18": "伊利诺伊州Watseka总裁，随机指标（RSI）发明人",
		"text19": "您无法买到经验，但帝纳波利的书能让您避免昂贵的教训。",
		"text20": "一位拥有超过30年行业经验的市场专家和交易专家，创立了Bressert Trading Group，致力于提供专业的交易策略和服务。",
		"text21": "他在《帝纳波利点位交易法》中介绍的方法非常实用，在牛熊市以及股票和期货交易中都能同样有效地发挥作用。新手交易员可以缩短几年的学习曲线，而经验丰富的交易员也能学会新的技巧。",
		"text22": "LBR Group的杰出交易者、作家、教育家",
		"text23": "了解更多关于帝纳波利点位法",
		"text24": "了解更多关于帝纳波利点位法和帝纳波利指标专家",
		"text25": "了解更多",
	},
}