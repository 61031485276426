export default {
	"screen0": {
		"title": "Văn bản pháp luật",
		"tit": "Văn bản pháp luật",
		"list": [
			"Hỗ trợ email",
			"Hỗ trợ trò chuyện trực tiếp"
		]
	},
	"screen1": {
		"list": [{
			"title": "1. Điều khoản và Điều kiện của Trang web",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Website-Terms-and-Conditions.pdf"
		}, {
			"title": "2. Thông báo Thừa nhận và Tiết lộ Rủi ro",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Risk-Acknowledgement-And-Disclosure-Notice.pdf"
		}, {
			"title": "3. Chính sách bảo mật",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Privacy-Policy.pdf"
		}, {
			"title": "4. Chính sách thực thi",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Execution-Policy.pdf"
		}],
		"tit": "在找什么吗？",
		"name": "您可以搜索站内",
		"placeholder": "全站搜索",
		"btn": "搜索",
		"text": "或是洽询我们的专人服务",
		"btn1": "联系专人服务"
	}
}

