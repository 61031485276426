export default {
  "screen0": {
    "title": "信頼の<br>MAMソリューション",
    "content": "EBCマルチアカウントマネージャー（MAM）は、アロケーション最適化とリアルタイムレポート、シンプルなインターフェースなどの機能を統合した、マネーマネージャー向けに開発された発注ツールです。ユーザーは一つのマスターアカウントを通じて、関連するサブアカウントの取引を管理できます。このプラットフォームは、ファンドマネージャーと、ファンド運用のプロフェッショナルを求めるお客様を強い信頼関係で結びつけます。",
    "list": [
      "柔軟なアロケーション",
      "リアルタイムレポート",
      "シンプルなインターフェース"
    ],
    "btn": "アカウント作成",
    "rightTitle": "EBCマルチアカウントマネージャー（MAM）は、アロケーション最適化とリアルタイムレポート、シンプルなインターフェースなどの機能を統合した発注ツールです。"
  },
  "screen1": {
    "pageEnTitle": "Asset Management",
    "pageTitle": "アセットマネジメント",
    "title": "MetaTrader 4 用に設計",
    "desc": "マルチアカウントマネージャー（MAM）は、MetaTrader4の資金管理システムと連携可能なように設計されており、自動売買プログラム（EA）を使用して複数のアカウントを管理するトレーダーにとって最適なツールです。ファンドマネージャーは、1つのMT4インターフェースで、効率的な取引と複数アカウントの管理ができます。",
    "text": "迅速、効率的、正確に<span>多くの投資家に対して</span>取引できます。",
    "btn": "もっと詳しく見る",
    "imgUrl": "en"
  },
  "screen2": {
    "pageEnTitle": "Intelligent Transaction",
    "pageTitle": "スマートトレード",
    "title": "一つの取引アカウントで、複数の投資家と取引ができます",
    "desc": "マルチアカウントマネージャー（MAM）はマスターアカウントに相当するもので、互いに独立した複数のサブアカウントを持ちます。トレーダーは取引ロット数の合計を入力するだけで、システムがトレーダーの選んだパラメーターに従って取引を実行、異なる個人トレーダーにロット数が自動的に割り当てられます。一つの取引アカウントで、複数のトレーダーに効率的かつ正確、そして迅速に取引ができます。"
  },
  "screen3": {
    "pageEnTitle": "Advantages & Functions",
    "pageTitle": "MAM機能",
		"pageTitle2": "MAMのメリット",
    "title": "あらゆる取引や資金管理に対応します",
    "content": [
      "あらゆる取引や資金管理に対応します",
      "損益分配、コピー取引分配など、10の分配方法が利用可能です",
      "取引アカウントは無制限",
      "フルアカウント、ミニアカウント、ミニマムシェアアカウントをサポート",
      "16桁までの正確な損益分岐計算",
      "注文種類を問わないストップロス/テイクプロフィット、ペンディングオーダー機能",
      "メインアカウントまたは個別のサブアカウントで約定された注文の部分決済",
      "スマート自動取引システムによるお客様のアカウントのサポート"
    ],
    "desc": "Apply for a MAM Account",
    "btn": "MAMアカウントを申請する",
    "line1": "パラメータ割り当ての即時更新",
    "line2": "シンプルなインターフェースですべてのアカウントを管理",
    "line3": "管理アカウントの資産と損益をリアルタイムでモニタリング",
    "line4": "グループ取引の実行",
    "line5": "管理アカウントの位置情報をリアルタイムに公開",
    "line6": "過去のレポートから利益配分を計算",
    "btn1": "MAMアカウントを申請する"
  },
	"styleName": "en-style"
}
