export default {
	"screen0": {
		"title": "Pure Trading Pure Power",
		"subTitle": "سودا يولىدا مېڭىش بەك تەس، بەلكىم ئەڭ ئاخىرقى بىر مىللىسىكۇنت قالغان بولۇشى مۇمكىن. <br>تەڭداشسىز بازار ئىجازىتى سەۋىيەسى  ئادەتتىن تاشقىرى تۆۋەن كېچىكتۈرۈلگەن IT ئاساسىي قۇرۇلمىسى "
	},
	"screen1": {
		"line1": "سودىنىڭ ئىجرا قىلىنىش سۈرئىتى تۆۋەن ",
		"line2": "سانلىق مەلۇمات يوللاش تۇراقلىقلىقى يۇقىرى ",
		"line3": "پۇل مۇئامىلە مەخسۇس لىنىيىسىدىكى ئوپتىك تالا <br>بەلباغ كەڭلىكى تۆۋەن بولماسلىقى كېرەك. ",
		"line4": "قەدەم ئالغاندا، دۇنيا بىلەن تەڭ قەدەم ئېلىش كېرەك "
	},
	"screen2": {
		"title": "تېخنىكا قوللانغان سودا		",
		"desc": "EBC مەخسۇس مۇلازىمېتىر Equinix LD4، HK3، TY3، NY4 (تەسىس قىلىنىش ئالدىدا) لاردا تەسىس قىلىنغان بولۇپ، پۈتۈن يەر شارىنى قاپلىغان پۇل مۇئامىلە ئېكولوگىيە سىستېمىسىنى بەرپا قىلىدۇ s",
		"title1": "پەن-تېخنىكا ئىقتىدارى سودىسى، EBC سودىسىنىڭ ئىجرا قىلىنىش سۈرئىتى 20 مىللىسېكۇنتقا تۆۋەنلەيدۇ. ",
		"desc1": "يۇمشاق ماتېرىيال ئېنىقلىمىسى ھېسابلاش، ساقلاش سىغىمى ۋە تور ئاساسىدا، دەرىجىدىن تاشقىرى يۇغۇرۇلما ئاساسىي قۇرۇلما% 98.75لىك سىستېما مۇقىملىقىنى ئېلىپ كەلدى ",
		"desc2": "<p>ھازىرقى پۇل مۇئامىلە بازىرىدا، سۈرئەت پايدىغا تەڭ، چەبدەسلىك سودىدا پايدىغا ئېرىشىشتە ئىنتايىن مۇھىم؛ </p> <p>بىز سودا مۇلازىمېتىرىنى دۇنيا بويىچە ئالدىنقى قاتاردا تۇرىدىغان سانلىق مەلۇمات مەركىزى Equinix LD4، HK3، TY3، NY4 (تەسىس قىلىنىش ئالدىدا) قىلىپ ئورناتتۇق، يەر شارى بويىچە ئادەتتىن تاشقىرى يۇقىرى سۈرئەتلىك مەخسۇس لىنىيەنىڭ ئۇلىنىشى رايونلار ئارا كېچىكتۈرۈلۈشنى ئەڭ زور دەرىجىدە ئازايتتى، خېرىدارلار مۇقىم سودىدىن بەھرىمەن بولدى؛ </p> <p>ئوتتىن مۇداپىيەلىنىش تېمى ئېلىپ كەلگەن چەكلىمە ۋە قىستا-قىستاڭ خەلقئارا بەلباغ كەڭلىكى تۆۋەن سۈپەتلىك سودا كەچۈرمىشىنى كەلتۈرۈپ چىقارغان. بىز 30 غا يېقىن DC (ئاپەت زاپاس مۇلازىمېتىرىنىمۇ ئۆز ئىچىگە ئالىدۇ) نى سەپلەيمىز، ياۋروپا-ئاسىيا مۇلازىمېتىرى ئارىسىدا باراۋەر تور پۇل مۇئامىلە مەخسۇس لىنىيەسىنى تۇتاشتۇرۇش (Peer-peernetwworking) ئىشقا ئاشۇرۇلىدۇ؛ </p> <p>ئەنئەنىۋى مۇلازىمېتىرنىڭ ياۋروپا-ئاسىيا لىنىيەسىگە سېلىشتۇرغاندا، EBC لوندوندىن ئاسىياغىچە بولغان كېچىكمە كونترول 100ms ئىچىدە بولىدۇ، ئەنئەنىۋى بېدىك سودىگەرنىڭ لوندوندىن ئاسىياغىچە بولغان يەتكۈزۈش ۋاقتى 270ms دىن يۇقىرى بولىدۇ، بۇ شۈبھىسىزكى ئاسىيادىكى خېرىدارلارغا ئەڭ پايدىلىق. </p>"
	},
	"screen3": {
		"title": "بىزگە ئايانكى، مۇۋەپپەقىيەت يۇمشاق دېتال تېخنىكىسى بىلەن چەكلەنمەيدۇ، بەلكى بىر پۈتۈن بولىدۇ. ",
		"desc": "EBC سودا بازىرىدىكى ئەڭ ياخشى يۆتكىلىشچانلىققا كاپالەتلىك قىلىدۇ، سىز كۆپ مۈلۈكنىڭ ئېقىشىغا ئېرىشىش بىلەن بىر ۋاقىتتا تېخىمۇ چوڭ جانلىقلىققا ئىگە بولىسىز ",
		"plant": "تاشقى پېرېۋوت سودا سۇپىسىنى چۈشەنگىڭىز بارمۇ",
		"btn": "ئۆگىنىش مەركىزى /سودا سۇپىسى بۆلىكى "
	},
	"screen4": {
		"enTitle": "Driving You to Succeed",
		"title": "كەسىپتە ئالدىنقى قاتاردا تۇرىدىغان پۇل مۇئامىلە پەن-تېخنىكىسى سودىنى چەكسىز ئىمكانىيەتكە ئىگە قىلدى "
	},
	"screen5": {
		"plant": "سودا مەھسۇلاتلىرىنى چۈشىنىش ",
		"btn": "ئۆگىنىش مەركىزى /سودا سۇپىسى بۆلىكى",
		"title": "ئەڭ كىچىك خەۋپ-خەتەر ئەڭ چوڭ سودا پۇرسىتى ",
		"desc": "EBCنىڭ ئادەتتىن تاشقىرى تۆۋەن كېچىكتۈرۈپ پولىمېرلاش، ئەقلىي ئىقتىدارلىق زاكاز يولى ۋە باھانى ئەلالاشتۇرۇش ماتورى، سىزنى تېخىمۇ يۇقىرى ئىشەنچىلىك ۋە ئەڭ ياخشى زاكاز بىلەن تەمىنلەيدۇ ",
		"list": [
			"‎سودامۇلازىمېتىر",
			"پولىمىرلاپ باھا مەلۇم قىلىش ",
			"زاكاز تالونىنى يوللاش ",
			"يۇقىرى ئېقىمدىكى كۆچمە مەبلەغ كۆلچىكى"
		],
		"link1": "كۆرگەزمە سودا ۋاقتى ",
		"link2": "تەكشۈرۈش نۇقتىسى پەرقى ۋە بارلىق مەھسۇلاتلار"
	},
	"screen6": {
		"title": "سودىغا كۆڭۈل بۆلىدىغان سىز IT ئاساسىي قۇرۇلمىسى، مەشغۇلات سىستېمىسى، تور ۋە بارلىق قاتتىق دېتال جەھەتتىكى مەسىلىلەردىن ئەنسىرىمىسىڭىزمۇ بولىدۇ. چۈنكى سىزنىڭ كۆڭۈل بۆلىدىغىنىڭىز، بىز ھەممىمىز ئەڭ ياخشى ھەرىكەتچانلىققا ئېرىشىش ۋە يۇقىرى ئىقتىدارلىق زاكازنى ئىجرا قىلىشنى ئويلاشتۇق. ",
		"desc": "ئالدىنقى بەش خوت چوڭقۇرلۇقىدىكى زاكازلارنىڭ ئىجرا قىلىنىش ئەھۋالىدىن قارىغاندا، EBC پەقەت 75-125ms لا بۇ سودىنى تاماملىغان "
	},
	"screen7": {
		"pageTitle": "باھانىڭ بايقىلىشى ۋە زاكاز تالونىنىڭ يەتكۈزۈلۈشى ",
		"pageEnTitle": "Discover Prices & Transfer Orders",
		"title": "EBC fix (پۇل مۇئامىلە ئۇچۇرلىرىنى ئالماشتۇرۇش كېلىشىمى) ئارقىلىق ئۇلانغان بانكىلار ئارا باھانى پولىمېرلاپ، خېرىدارلارنى بىرىنچى دەرىجىلىك كۆچمە سورۇننىڭ بىۋاسىتە بازار ئىجازىتى بىلەن تەمىنلەيدۇ، مەخسۇس فىزىكىلىق HUB قاتتىق دېتال پولىمېرلانغاندىن كېيىنكى باھا تېخىمۇ تۆۋەن نۇقتا پەرقى ۋە ئىمكانىيەتلىك سىجىل سودا چوڭقۇرلۇقىغا ئىگە بولىدۇ ",
		"desc": "",
		"imgUrl": "vi"
	},
	"screen8": {
		"title": "بۇ EBC bridgeئارقىلىق پولىمېرلانغاندىن كېيىنكى مەھسۇلات نۇقتا پەرقى جەدۋىلى، چوڭقۇرلۇق جەدۋىلى ",
		"desc": "تېز چۈشىنىش",
		"link1": "تۆت خىل مۈلۈك تۈرى",
		"link2": "سودا تۈرى+100",
		"link3": "查看全部",
		"trList": [
			"层级",
			"买入价",
			"买入的交易量",
			"点差",
			"卖出价",
			"卖出价交易量"
		],
		"smallTrList": [
			"层级",
			"买入价",
			"点差",
			"卖出价"
		]
	},
	"screen9": {
		"title": "سودا قارا قۇتىسى ",
		"smallTitle": "ئالگورىزىم سېتىش تەرتىپىنى ئېنىق ئەلالاشتۇرۇڭ ، بۇنداق بولغاندا% 85 تىن ئارتۇق زاكاز تېخىمۇ ياخشى باھادا سېتىلىدۇ ",
		"desc": " سۇپىمىزقارا قۇتا سودىسى قىلىدۇ، نەچچە ئون مىليون قېتىملىق تارىخىي سودا سانلىق مەلۇماتى، سودا قىلغۇچىلارنىڭ سودا ئادىتى، ئابۇنىچىلارنىڭ خاسلىقى قاتارلىق رەسىملەر، نۆۋەتتىكى بازار ئەھۋالى ۋە سودا تور مۇھىتى قاتارلىقلارنى ئاساس قىلىپ، چوڭ سانلىق ئىستاتىستىكىلىق ئانالىز، ئېھتىماللىق مودېلى، نېرۋا تورى مودېلى، سىستېما كونترول نەزەرىيەسى مودېلى ۋە سۈنئىي ئەقلىي ئىقتىدار ھېسابلاش ئۇسۇلى قاتارلىقلارنى قوللىنىپ، ئۇنىۋېرسال ھۆكۈم قىلىپ، ئەڭ ئاخىرىدا ھەر بىر سودا قىلغۇچىنىڭ خاسلىقىغا تېخىمۇ ماس كېلىدىغان LP نى ماسلاشتۇرۇپ، ئەڭ ياخشى پۈتۈشۈش باھاسى ھاسىل قىلىدۇ ",
		"link1": "كۆرگەزمە سودا ۋاقتى ",
		"link2": " نۇقتىسى پەرقى ۋە بارلىق مەھسۇلاتلار تەكشۈرۈش",
		"imgUrl": "vi"
	},
	"screen10": {
		"desc": "بىز EBC سودا قارا قۇتا بىلەن ئەنئەنىۋى سودا سىستېمىسىنى سېلىشتۇرۇش تەجرىبىسى ئېلىپ بارىمىز. (بۇنىڭ ئىچىدە ئاز دېگەندە 100 زاكاز تالونىنى ئۆز ئىچىگە ئالىدۇ) ، تۆۋەندىكىدەك تارقىلىش خەرىتىسىگە ئېرىشكىلى بولىدۇ",
		"list": [
			"ھەر بىر نەچچە تايانچ پونكىت بىر كۈنلۈك سودىغا ۋەكىللىك قىلىدۇ. ",
			"توغرا ئوق زاكاز تالونىنىڭ پۈتۈشۈش نىسبىتىنى ئىپادىلەيدۇ. ",
			"نۇقتىنىڭ چوڭ-كىچىكلىكى زاكاز مىقدارىنىڭ نىسپىي چوڭ-كىچىكلىكىگە ۋەكىللىك قىلىدۇ. ",
			"تىك ئوق سودا باھاسىنىڭ ياخشىلىنىش نىسبىتىنى ئىپادىلەيدۇ. "
		],
		"btn": "مەبلەغ سېلىشنى باشلاش ",
		"title": "تەجرىبە قىلىش ئارقىلىق، بىز شۇنى بايقايمىزكى: باھانى ئەلالاشتۇرۇش نىسبىتى، زاكاس تالونىنىڭ پۈتۈشۈش نىسبىتى ۋە ھېسابلاش ئۇسۇلىنىڭ مۇقىملىقى جەھەتتە، EBC سودا قارا قۇتىسى ئەنئەنىۋى سودا سېستىمىسىدىن كۆپ ئۈستۈن تۇرىدۇ."
	}
}

