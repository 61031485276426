export default {
  "screen0": {
    "title": "진지하게 거래하는 사람은，진지한 대우를 받을 가치가 있습니다.",
    "enTitle": "Trust & Respect",
    "btn": "동영상 보기"
  },
  "screen1": {
    "line1": "믿을수 있는 제휴 파트너",
    "line2": "거래 세계에서",
    "line3": "가장 환영받는 자산",
    "line4": "리스크 경고: 차액거래CFDs는 전문적이고 복잡한 금융거래 상품입니다. 높은 레버리지로 인하여 손실에 대한 위험성이 커질수 있습니다. 전세계에서 75%이상의 투자자들은 투자의 위험성을 제대로 인지하지 못하고 있습니다. 더 자세한 내용은 저희와 문의해주시기 바랍니다.",
    "list": [{
      "enTitle": "외환",
      "title": "spot index contracts for differences",
      "desc": "전세계서 가장 사랑받는 36개의 주요 및 차요 거래 통화쌍. 최저 0핍부터 시작되는 거래",
      "more": "더 알아보기"
    }, {
      "enTitle": "인덱스 CFDS",
      "title": "Derivatives",
      "desc": "전세계에서 최고의 지수 제공<br />가치와 볼륨이 가장 높은 영역",
      "more": "더 알아보기"
    }, {
      "enTitle": "대중상품",
      "title": "commodities",
      "desc": "만약 고객님꼐서 투자 조합의 다양성을 원하신다면 안전자산 (예: 귀금속)에너지 (원유. 천연가스) 등은 합리한 선택입니다.",
      "more": "더 알아보기"
    }, {
      "enTitle": "주식 차익 계약",
      "title": "contract for stock price difference",
      "desc": "주식 차익 계약 글로벌 가장 성숙된 36 가지 거래소 주식 거래 실시간 가격 채정, 실제 주식 시장 표현 연계.",
      "more": "더 알아보기"
    }]
  },
  "screen2": {
    "title1": "EBC",
    "title2": "<span>글로벌 Top2 라이센스 동시 규제</span>",
    "desc": "고객님은 가장 안전한 규제기관의 감독과 전문 투자기관의 트레이딩 환경에서 거래를 체험할수 있습니다.",
    "list": [{
      "title": "영국 FCA",
      "desc": "EBC Financial Group (UK) Ltd 는 영국FCA 금융감독원의 규제를 받고 있습니다. 라이센스 <br />번호: 927552<br/>사이트 주소：<a href='https://www.ebcfin.co.uk'>www.ebcfin.co.uk</a>"
    }, {
      "title": "호주 ASIC",
      "desc": "EBC Global Pty Ltd는 호주 금육감독원의 규제를 받고 있습니다. 라이센스 <br /> 번호: 500991"
    }]
  },
  "screen3": {
    "line1": "Your Faithful Partner",
    "line2": "Driving you",
    "line3": "to succeed",
    "line4": "전문적이고 선진적인 과학기술로 더 나은 거래 결과를 만드세요.",
    "line5": "배치 지역<br /> 런던 LD5; 뉴욕NY4; 싱가폴SG1; 동경TY3; 홍콩HK2",
    "line6": "초고속 전선으로 연결된 독립 서버",
    "list": [{
        "num": "1000",
        "text": "초단위 주문 체결량"
      },
      {
        "num": "5",
        "unit": "×24",
        "text": "VIP급 서비스"
      },
      {
        "num": "50",
        "unit": "+",
        "text": "거래 종목"
      },
      {
        "num": "20",
        "unit": "+",
        "text": "글로벌 고성능 데이터 센터(DC)"
      },
      {
        "num": "100",
        "unit": "+",
        "text": "데이터 전송 안정성"
      },
      {
        "num": "98.75",
        "unit": "%",
        "text": "업타임"
      },
      {
        "num": "0.0",
        "unit": "PIPS",
        "text": "RAW ECN点差 인터뱅크 수준의 AW ECN 스프레드"
      },
      {
        "btn": "EBC테크 체험"
      }
    ]
  },
  "screen4": {
    "enTitle": "가격의 발견 & 주문의 전송",
    "title": "price discovery <br /> and order transmis",
    "desc": "EBC는 fix(금융정보교환협정)를 통해 접속한 은행간 견적을 취합하여 최고 등급의 유동성을 제공하며 전용 HUB 하드웨어를 통한 호가는 보다 낮은 스프레드와 지속 거래 가능한 심도를 제공합니다.",
    "line1": "EBC트레이딩 블랙박스",
    "line2": "트레이딩 블랙박스.",
    "line3": "더 정확한 시장가 체결",
    "line4": "보다 우수한 가격으로 85% 이상의 주문체결",
    "list": ["트레이딩 블랙박스", "트레이딩 분포도", "다양한 실험을 통하여 EBC의 트레이딩 환경은 전통적인 거래 시스템보다 더 나은 가격 및 빠른 속도로 체결된다는것을 확인하였습니다."],
    "rightDesc": "EBC는 fix(금융정보교환협정)를 통해 접속한 은행간 견적을 취합하여 최고 등급의 유동성을 제공하며 전용 HUB 하드웨어를 통한 호가는 보다 낮은 스프레드와 지속 거래 가능한 심도를 제공합니다.",
    "more": "더 알아보기",
    "btn": "거래시작"
  },
  "screen5": {
    "btn": "거래시작",
    "title": "EBC 거래공간",
    "enTitle": "EBC Priviate Room",
    "desc": "<span> “모든 청산은 같은 표준이 아닙니다. </span>모든 상황에 맞는것이 그 답안입니다”",
    "textList": ["Private Room 은 당신의 부분적인 거래정보를 숨길수 있습니다. 이는 큰 규모의 거래량 에서 엄중한 슬리피지 또는 Last Look 으로 인해 주문 거부가 발생되지 않도록 하며 가장 유리한 가격에 주문을 체결할수 있음을 의미합니다. ",
      "만약 당신은 “큰손 투자자”이고 시장의 다른 투자자한테 노출을 원하지 않는다면 EBC의 Private Room 은 최선의 선택일것입니다."
    ],
    "ftTitle": "EBC THE FUTURE",
    "ftDesc": "<i> <span> EBC </span>기관 투자자를 향해 </i>제공되는",
    "ftDesc1": "최고의 유동성.",
    "ftBtn": "제휴 신청",
    "ftList": [{
      "title": "안전성",
      "desc": "초고속 인터넷 광선 Equinix로 20밀리초 이내에 고객님의 주문이 체결됩니다."
    }, {
      "title": "규제성",
      "desc": "고객님의 자금은 글로벌 최고의 은행에 위탁되고 엄격한 규제를 받고있습니다."
    }, {
      "title": "유동성",
      "desc": "EBC는 국제 최고의 은행과 장기적인 제휴관계를 맺고 있으므로 최저의 거래 원가를 체험할수 있습니다."
    }, {
      "title": "제휴 파트너"
    }]
  },
  "urlLan":"ko"
}

