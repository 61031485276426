export default {
  "box-name": "en-style2",
  "public-btn-text1": "สมัครตอนนี้",
  "public-btn-text2": "สินค้าทั้งหมด",
  screen0: {
    title: "เงินปันผลจาก CFD",
    desc: "โปรดทราบ: การรวบรวมเงินปันผล CFD จะแสดงในยอดคงเหลือใน MT4 ของคุณเป็นการฝากหรือถอน โดยมีหมายเหตุว่า “เงินปันผล”",
  },
  screen1: {
    title: "ธุรกรรมที่ปลอดภัย",
  },
  screen2: {
    title: "โลกการเงินอยู่ใกล้แค่เอื้อม",
  },
  指数差价合约与股息调整: "ดัชนี CFD และการปรับเงินปันผล",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "ทำความเข้าใจกลไกการปรับและการจัดสรรเงินปันผลของการเทรดหุ้นและดัชนี CFD ",
  了解指数差价合约: "เรียนรู้เพิ่มเติมเกี่ยวกับดัชนี CFD",
  指数差价合约: "ดัชนี CFD",
  指数差价合约允许投资者通过股票市场指数的价格波动进行交易:
    "ดัชนี CFD ช่วยให้นักลงทุนสามารถเทรดตามความผันผวนของราคาดัชนีตลาดหุ้นโดยไม่ต้องถือครองสินทรัพย์จริง และมีดัชนีให้เลือกลงทุนได้หลากหลาย เช่น S&P 500, Dow Jones และ DAX ของเยอรมนี นำเสนอการเทรดที่ยืดหยุ่น",
  股息调整: "การปรับเงินปันผล",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "เงินปันผล คือ เงินที่บริษัทแจกจ่ายให้กับผู้ถือหุ้นเป็นประจำจากกำไรหรือกำไรสะสมของบริษัท เมื่อหุ้นจ่ายเงินปันผล ผลิตภัณฑ์ CFD ของหุ้นและดัชนีที่เกี่ยวข้องก็จะมีการปรับเงินปันผลตามที่กำหนดด้วย",
  除息日与交易优化: "วันหยุดจ่ายเงินปันผลและการเพิ่มประสิทธิภาพการเทรด",
  指数差价合约的除息日是由指数提供商根据成分股的除息日设定的:
    "วันหยุดจ่ายเงินปันผลสำหรับดัชนี CFD ถูกกำหนดโดยผู้ให้บริการดัชนีตามวันที่หุ้นพื้นฐานจะหยุดจ่ายเงินปันผล หากคุณถือสถานะซื้อคุณจะได้รับเงินปันผล แต่ถ้าคุณถือสถานะขายคุณจะต้องจ่ายเงินปันผล การรู้วันที่เหล่านี้ล่วงหน้าจะช่วยให้คุณจัดการผลตอบแทนได้อย่างมีประสิทธิภาพ",
  股息调整明细: "รายละเอียดการปรับเงินปันผล",
  差价合约交易中相关产品的股息数据:
    "ข้อมูลเงินปันผลสำหรับผลิตภัณฑ์ที่เกี่ยวข้องในการเทรด CFD จะถูกแสดงแยกกันระหว่างเงินปันผลของสถานะซื้อ และสถานะขาย",
  即刻交易: "เริ่มเทรด",
  股息的常见问题: "คำถามที่พบบ่อย",
  什么是股息和除息日: "1.เงินปันผลคืออะไร และวันหยุดเงินปันผลคืออะไร?",
  股息是上市公司从利润或保留资金中定期分配给股东的资金answer:
    "เงินปันผล คือ เงินที่บริษัทแจกจ่ายให้กับผู้ถือหุ้นเป็นประจำจากกำไรหรือกำไรสะสมของบริษัท เมื่อหุ้นจ่ายเงินปันผล ผลิตภัณฑ์ CFD ของหุ้นและดัชนีที่เกี่ยวข้องก็จะมีการปรับเงินปันผลตามที่กำหนดด้วย<br /><br />วันหยุดจ่ายเงินปันผลสำหรับดัชนี CFD จะถูกกำหนดโดยผู้ให้บริการดัชนีตามวันหยุดเงินปันผลของหุ้นในกลุ่ม ซึ่งจะเป็นวันจ่ายเงินปันผลด้วยเช่นกัน",
  股息调整的时间: "2.เวลาในการปรับเงินปันผล",
  指数差价合约股息调整通常会在除息日的answer:
    "การปรับเงินปันผลสำหรับดัชนี CFD มักจะแสดงในบัญชี MT4 และ MT5 ของคุณ ในช่วงเวลา 05:00 ถึง 06:00 (GMT+3) ของวันหยุดเงินปันผล โดยจะมีข้อความระบุว่า 'เงินปันผล'",
  股息对指数交易的影响: "3.เงินปันผลส่งผลกระทบต่อการเทรดดัชนีอย่างไร",
  持有多头仓位answer:
    "หากถือสถานะซื้อ: การปรับเงินปันผลจะถูกฝากเข้าบัญชี MT4 และ MT5 ของคุณ<br /><br />หากถือสถานะขาย: การปรับเงินปันผลจะถูกหักออกจากบัญชี MT4 และ MT5 ของคุณ",

  股息调整的计算公式: "4.สูตรการคำนวณการปรับเงินปันผล",
  多头仓位answer:
    "สูตรการคำนวณสำหรับสถานะซื้อ: จำนวนล็อต × ขนาดสัญญา × จำนวนเงินปันผลสถานะซื้อ (คำนวณเป็นสกุลเงินดัชนี)<br /><br />สูตรการคำนวณสำหรับสถานะขาย: จำนวนล็อต × ขนาดสัญญา × จำนวนเงินปันผลสถานะขาย (คำนวณเป็นสกุลเงินดัชนี)",
  股息调整计算示例: "5.ตัวอย่างการคำนวณการปรับเงินปันผล",
  以SPXUSD为例answer: `จำนวน 1 สแตนดาร์ดล็อตในช่วงปิดตลาดก่อนวันหยุดเงินปันผล (วันที่ 10 ตุลาคม) การคำนวณการปรับเงินปันผลจะเป็นดังนี้:<br /><br />

การปรับเงินปันผล = 1 (สแตนดาร์ดล็อต) × 100 (ขนาดสัญญา) × 0.050$ (จำนวนเงินปันผลสถานะซื้อ) = 5$<br /><br />

วันหยุดเงินปันผลในช่วงเวลา 05:00 ถึง 06:00 (GMT+3) จำนวน 5$ จะถูกโอนเข้าสู่บัญชี MT4 และ MT5 ของคุณ โดยข้อความระบุว่า 'เงินปันผล'<br /><br />

หากคุณถือสถานะขาย จำนวน 5$ จะถูกหักออกจากบัญชี MT4 และ MT5 ของคุณ โดยข้อความระบุว่า 'เงินปันผล'`,
  注意事项: "6.รายละเอียดสำคัญ",
  股息调整仅适用于指数和股票差价合约交易answer: `การปรับเงินปันผลใช้เฉพาะสำหรับการเทรด CFD ของดัชนีและหุ้น และจะปรับยอดคงเหลือในบัญชีตามประเภทสถานะ (ซื้อหรือขาย) จำนวนเงินปันผลและผลกระทบของมันอาจแตกต่างกันไปตามดัชนีและสภาวะตลาดที่เฉพาะเจาะจง หากมีข้อสงสัยใดๆ รบกวนติดต่อผู้จัดการบัญชีของคุณหรือเยี่ยมชมเว็บไซต์ของเราเพื่อรับข้อมูลรับเพิ่มเติม`,
  list:['Trading Products', 'Long Dividend', 'Short Dividend', 'EX-Dividend Day']
};
