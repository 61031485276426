export default {
  "screen0": {
    "title": "真剣に取引をする人に、正面から向き合います。",
    "enTitle": "Trust & Respect",
    "btn": "動画を視聴する"
  },
  "screen1": {
    "line1": "信頼できるパートナーとして",
    "line2": "世界のトレーディングにおいて",
    "line3": "最も人気のある資産",
    "line4": "注意事項：CFDは専門的で複雑な金融取引商品であり、レバレッジにより高い損失リスクを伴います。 世界の投資家の75％が損失を経験しています。EBCを理解する前に、投資の性質とそれに伴うリスクを十分に理解していることを確認してください。",
    "list": [{
      "enTitle": "Derivatives",
      "title": "デリバティブ取引",
      "desc": "最狭スプレッド0から、世界で人気の高いメジャー、マイナー、エキゾチックな通貨ペア36種類取引が可能。",
      "more": "詳しく見る"
    }, {
      "enTitle": "Index CFDs",
      "title": "商品CFD取引",
      "desc": "EBCは常に世界中から最も人気で価値があり、成長性の高い金融商品を厳選してお客様に提供します。",
      "more": "詳しく見る"
    }, {
      "enTitle": "Commodities",
      "title": "コモディティ取引",
      "desc": "ポートフォリオ分散をお考えなら、安全資産（貴金属など）やエネルギー市場（原油、天然ガスなど）を組み合わせた分散投資が理想です。",
      "more": "詳しく見る"
    }, {
      "enTitle": "Share CFDs",
      "title": "株式指数CFD",
      "desc": "世界の成熟市場と新興市場の36の取引所で、実際の株式相場と連動したフレキシブルな取引が可能です。",
      "more": "詳しく見る"
    }]
  },
  "screen2": {
    "title1": "EBCは",
    "title2": "世界トップクラスの<span>二つの金融監督機関の認可を取得しています</span>",
    "desc": "安全性とパフォーマンスにおいて<br />あなたは機関投資家レベルの環境で取引をお楽しみ頂けます。",
    "list": [{
      "title": "英国FCAの認可",
      "desc": "EBC Financial Group (UK) Ltdは英国金融行為監督機関(FCA)の認可を受け規制を遵守しています。<br />規制番号：:927552<br/>Webサイト：<a href='https://www.ebcfin.co.uk'>www.ebcfin.co.uk</a>"
    }, {
      "title": "豪州ASICの認可",
      "desc": "EBC Global Pty Ltdは豪州証券投資委員会(ASIC)の認可を受け規制を遵守しています。<br />規制番号: 500991"
    }]
  },
  "screen3": {
    "line1": "Your Faithful Partner",
    "line2": "Driving you",
    "line3": "to succeed",
    "line4": "先進のフィンテックが、金融取引の可能性を無限に拡げる",
    "line5": "ロンドンLD5、ニューヨークNY4、シンガポールSG1、東京TY3、香港HK2に<br />専用回線での超高速通信を実現する",
    "line6": "独自のサーバーを設置",
    "list": [{
        "num": "1000",
        "text": "1秒あたりの最大注文数"
      },
      {
        "num": "5",
        "unit": "×24",
        "text": "VIP優待サービス"
      },
      {
        "num": "50",
        "unit": "+",
        "text": "取引バリエーション"
      },
      {
        "num": "20",
        "unit": "+",
        "text": "グローバルに構築した超高性能データセンター（DC）"
      },
      {
        "num": "100",
        "unit": "+",
        "text": "グローバル会員数"
      },
      {
        "num": "98.75",
        "unit": "%",
        "text": "データ通信最大安定度"
      },
      {
        "num": "0.0",
        "unit": "PIPS",
        "text": "インターバンクレベルのRAW ECNスプレッド"
      },
      {
        "btn": "テクノロジー追求について"
      }
    ]
  },
  "screen4": {
    "enTitle": "Discover Prices & Transfer Orders",
    "title": "価格検索<br />と注文約定",
    "desc": "EBCは、FIX（金融情報交換プロトコル）を介して届く銀行間取引市場の相場を集約し、お客様に最も流動性の高い取引市場価格を提供しております。加えて、専用のハードウェアであるハブ(HUB)を介して価格を集約した後、最狭スプレッドと深い取引の維持を実現します。",
    "line1": "EBC Trading Black-Box",
    "line2": "トレーディングブラックボックスの",
    "line3": "アルゴリム最適化により",
    "line4": "85%以上の注文がお客様にとって有利な価格で約定",
    "list": ["トレーディングブラックボックス", "取引チャート", "分析結果から次のことが導き出せます：<br />EBC Trading Black Boxは従来の取引システムに比べ、価格最適化率、注文充填率、アルゴリズムの安定性においてはるかに優れています"],
    "rightDesc": "EBCは、FIX（金融情報交換プロトコル）を介して届く銀行間取引市場の相場を集約し、お客様に最も流動性の高い取引市場価格を提供しております。加えて、専用のハードウェアであるハブ(HUB)を介して価格を集約した後、最狭スプレッドと深い取引の維持を実現します。",
    "more": "詳しく見る",
    "btn": "投資をはじめる"
  },
  "screen5": {
    "btn": "投資をはじめる",
    "title": "EBCトレーディングルーム",
    "enTitle": "EBC Private Room",
    "desc": "<span>“決済は一律に同じものではありません。</span>すべての状況にあった答えがあります。”",
    "textList": ["プライベートルームでは、お客様の取引情報の一部が非表示にできます。ラストルック(Last Look)による注文遅延や拒否がなく、お客様は大口注文をより良い価格で約定することができます。",
      "高額投資をするトレーダーが自らの取引手法とトレードスタイルを他人に知られたくない場合、EBCのプライベートルームプランは最適です。"
    ],
    "ftTitle": "EBC THE FUTURE",
    "ftDesc": "<i><span>EBCが</span>機関投資家の方たちに</i>提供するのは",
    "ftDesc1": "世界トップクラスの流動性です",
    "ftBtn": "私たちとのパートナーシップについて",
    "ftList": [{
      "title": "安全性",
      "desc": "P2Pで世界中のエクイニクスと専用光回線で結び約定まで0.02秒という超高速取引を実現しました。"
    }, {
      "title": "規則遵守",
      "desc": "当社の資金は世界一流の銀行で明確に他の資産と隔離され信託保全されます。"
    }, {
      "title": "流動性",
      "desc": "EBCは国際的な多くの一流銀行と長年にわたるパートナー関係を築いており、お客様に超低価格な取引サービスが提供できます。"
    }, {
      "title": "パートナーシップ"
    }]
  },
  "urlLan":"ja"
}
