export default {
    "box-class-name": "en-no1-box",
    "lang": "vn",
    "挑战最强交易环境": "Thách thức môi trường giao dịch mạnh nhất",
    "利润差额奖金": "Thưởng chênh lệch lợi nhuận",
    "EBC的最强交易环境等您挑战": "Môi trường giao dịch mạnh nhất của EBC đang chờ bạn chinh phục! Thử thách thành công, bạn sẽ nhận được phần thưởng chênh lệch lợi nhuận lên đến 150% và không có mức giới hạn tối đa.",
    "距挑战结束剩余": "Cách thời gian kết thúc còn",
    "天": "D",
    "时": "H",
    "分": "M",
    "秒": "S",
    "本次活动时间为2024年1月1日": "Thời gian diễn ra sự kiện: 1/1/2024 00:00:00 - 30/9/2024 23:59:59（GMT+2）",
    "发起挑战": "Thách Thức Ngay",
    "挑战规则": "Luật Thách Thức",
    "EBC致力于为投资者提供极低交易成本": `EBC cam kết cung cấp cho các nhà đầu tư chuyên nghiệp chi phí giao dịch cực thấp, độ sâu thị trường chất lượng cao và khớp lệnh hiệu quả. Để kiểm tra và hoàn thiện môi trường giao dịch của chúng tôi, EBC đặc biệt ra mắt chương trình "Thách thức môi trường giao dịch mạnh nhất`,
    "开设账户": "Mở Tài Khoản",
    "在EBC平台开设一个交易账户": "Mở một tài khoản giao dịch tại EBC",
    "选择平台": "Lựa chọn nền tảng",
    "选择一家具备FCA或ASIC监管资质的交易商平台": "Lựa chọn nền tảng giao dịch được quản lý bởi FCA hoặc ASIC",
    "相同策略交易": "Đồng bộ chiến lược giao dịch",
    "使用相同的交易策略在EBC和所选平台进行交易": "Sử dụng cùng một chiến lược trên cả EBC và nền tảng mà bạn đã lựa chọn",
    "收益比较": "So sánh lợi nhuận",
    "在EBC的盈利低于或亏损高于所选择交易商": "Nếu lợi nhuận tại EBC thấp hơn hoặc mức lỗ cao hơn so với nền tảng giao dịch đã chọn, thì thử thách được coi là thành công.",
    "卓越的交易环境": "Môi trường giao dịch xuất sắc",
    "EBC以一流的交易执行速度和先进的技术支持": "EBC xây dựng trải nghiệm giao dịch xuất sắc với tốc độ khớp lệnh hàng đầu và hỗ trợ kỹ thuật tiên tiến, đáp ứng các yêu cầu cao của nhà đầu tư.",
    "科技赋能交易": "Công nghệ hỗ trợ giao dịch",
    "部署于伦敦LD4": "Các máy chủ độc lập được triển khai tại London LD4, New York NY4, Singapore SG1, Tokyo TY3 và Hong Kong HK2, được kết nối bằng các đường truyền tốc độ cao, cung cấp độ trễ cực thấp, định tuyến lệnh thông minh và tối ưu hóa báo giá, xây dựng hệ sinh thái tài chính siêu tốc toàn cầu.",
    "让85%以上的订单成交于更优的价格": "Để 85% lệnh trở lên được khớp với giá tốt hơn",
    "EBC交易黑盒": "Black Box của EBC tối ưu hóa chính xác các thuật toán đặt lệnh, vượt trội hơn so với các hệ thống truyền thống về tối ưu hóa giá, tốc độ khớp lệnh, và độ ổn định của thuật toán",
    "EBC交易空间": "Không gian giao dịch EBC",
    "无论您是高频交易者": `Cho dù bạn là người giao dịch tần xuất cao, hay là nhà giao dịch lệnh lớn, Private Room của EBC đều sẽ xây dựng kênh giao dịch riêng dành cho bạn dựa trên cơ sở khớp thanh khoản, thậm chí là ẩn một phần thông tin giao dịch của bạn để bảo vệ "con Át chủ" của bạn, điều này cũng có nghĩa là đơn hàng của bạn không chỉ nhận được báo giá tổng thể hơn, mà còn giúp tránh bị đánh úp trong quá trình giao dịch`,
    "国际顶级流动性": "Tính thanh khoản hàng đầu quốc tế",
    "EBC与多家国际顶级银行建立了长期稳定的合作关系": "EBC có mối quan hệ hợp tác lâu dài và ổn định với nhiều ngân hàng quốc tế hàng đầu trên thế giới, kết hợp với báo cáo thanh khoản chất lượng cao, cung cấp mốc chênh lệch thị trường có tính cạnh tranh, giúp nhà đầu tư dễ dàng tận hưởng chi phí giao dịch cực thấp.",
    "活动须知": "ĐIỀU CẦN BIẾT",
    "参与账户": "Tài Khoản Tham Gia",
    "新用户": "Người dùng mới: Sau khi mở tài khoản mới trên trang web chính thức, hãy liên hệ với bộ phận dịch vụ khách hàng trực tuyến để mở tài khoản VIP để tham gia thử thách.",
    "现有用户": "Người dùng hiện tại: Liên hệ bộ phận chăm sóc khách hàng trực tuyến, mở tài khoản VIP và tham gia thử thách.",
    "对比交易商平台要求": "Yêu Cầu Nền Tảng",
    "选择持有英国FCA或澳洲ASIC监管的交易商平台": "Nền tảng được chọn phải được quản lý chính thức bởi FCA của Anh hoặc ASIC của Úc. Không bao gồm đăng ký AR, EEA hoặc tài khoản Trading Name.",
    "交易策略": "Chiến Lược Giao Dịch",
    "仅限以下主流货币对": "Giới hạn các cặp tiền tệ chính sau : AUD/USD, USD/CAD, EUR/USD, USD/CHF, GBP/USD, USD/JPY, NZD/USD.",
    "在EBC和所选交易商使用相同的交易资金和交易策略": "Sử dụng cùng một nguồn vốn giao dịch và chiến lược giao dịch trên cả EBC và nền tảng giao dịch đã chọn, tất cả các chiến lược đều phải thuộc phạm vi của các hoạt động giao dịch thông thường.",
    "奖金结算": "Trả Thưởng",
    "挑战者在其他平台的交易返佣可计入盈利": "Khoản hoàn trả hoa hồng từ giao dịch trên các nền tảng khác của người tham gia có thể được tính vào lợi nhuận, nhưng cần cung cấp thông tin chứng minh tương ứng.",
    "挑战者可在任意挑战时间内选择结算": "Người thách thức có thể chọn thanh khoản trong bất kỳ khoảng thời gian nào và mỗi thẻ ID chỉ có thể tham gia một lần.",
    "挑战结束后": "Sau khi thách thức kết thúc, hãy gửi lệnh bán để kết thúc. Phần thưởng sẽ được cấp vào tài khoản giao dịch trong vòng hai ngày làm việc sau khi xem xét và có thể được sử dụng để giao dịch hoặc rút tiền",
    "重要声明": "Lưu Ý Quan Trọng",
    "参赛者应知晓交易风险": "Người tham gia phải hiểu rõ rủi ro giao dịch, quy tắc, quyền lợi và các quy tắc giao dịch của sản phẩm trên nền tảng. EBC sẽ không chịu trách nhiệm đối với các vấn đề giao dịch và tổn thất tài khoản do tình huống bất khả kháng gây ra.",
    "任何不遵守比赛规则或篡改数据的行为": "EBC có quyền hủy tư cách tham gia cuộc thi đối với bất kỳ hành vi nào vi phạm quy tắc thi đấu hoặc giả mạo dữ liệu, đồng thời bảo lưu quyền truy cứu trách nhiệm pháp lý.",
    "EBC有权要求参赛者提供有争议的订单或账户的补充材料": "EBC có quyền yêu cầu người tham gia cung cấp tài liệu bổ sung về các lệnh giao dịch hoặc tài khoản đang tranh chấp.",
    "本次挑战赛旨在提供公平": "Cuộc thi này nhằm tạo ra một môi trường công bằng, minh bạch, và EBC bảo lưu quyền sửa đổi, miễn trừ hoặc giải thích các quy tắc cuộc thi.",
    "EBC有权根据法律法规": "EBC có quyền hủy bỏ hoặc chấm dứt chương trình theo quy định của pháp luật, lệnh tư pháp hoặc hành chính.",
    "本活动最终解释权归EBC所有":"Quyền quyết định cuối cùng của chương trình này thuộc về EBC."

}