<template>
    <div class="page-title">
        <div class="page-title__hd" :style="{backgroundColor: bgColor}">
            <div class="page-title__en">{{enText}}</div>
            <div class="page-title__text" v-if="showText_">{{text}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            enText: {
                type: String,
                default: ''
            },
            text: {
                type: String,
                default: ''
            },
            bgColor: {
                type: String,
                default: '#f7f7f7'
            }
        },
        computed: {
            showText_() {
                return String(this.text).indexOf('.pageTitle') === -1
            }
        }
    }
</script>

<style lang="scss" scoped="scoped">
    .page-title{
        &__hd{
            display: inline-block;
            border-top: 2px solid #7d1614;
            background-color: #f7f7f7;
            position: relative;
            height: 42px;
            padding: 0 8px;
            white-space: nowrap;
            line-height: 42px;
            text-align: center;
        }
    
        &__en {
            font-size: 12px;
            color: #8b8b8b;
        }
    
        &__text {
            margin-top: -15px;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.6;
            color: #333333;
			white-space: normal;
        }
    }
</style>
