export default {
  "screen0": {
    "title": "ئىشىنىشكە ئەرزىيدىغان<br>MAM ھەل قىلىش لايىھەسى",
    "content": "O ئالاھىدە كەسپىي مەبلەغ باشقۇرغۇچىلار ئۈچۈن بىر خىل Multi + Account سۇپىمىزكۆپ سچوت مەبلەغ باشقۇرۇش سىستېمىسىنى بەرپا قىلىپ، سىزنىڭ زاكاز قىلىنغان تەقسىمات ئۇسۇلىنى قوللىنىشىڭىزغا رۇخسەت قىلىدۇ، تاق شەكىللىك تالون چۈشۈرۈش يۇمشاق دېتالى، ئەمەلىي ۋاقىتلىق دوكلات ئىقتىدارى ۋە ئەمەلىي ۋاقىتتا يېڭىلانغان شېرىنكانە دوكلاتى ئارقىلىق كۆپ سچوتنىڭ بىر تۇتاش باشقۇرۇلۇشىنى ئىشقا ئاشۇرىدۇ. فوند دىرېكتورى ۋە كەسپىي خادىملار مەبلەغ باشقۇرىدىغان خېرىدارلارنى ئىشەنچلىك ھەمكارلىق يولى بىلەن تەمىنلىدى. ",
    "list": [
      "ئېلاستىك تەقسىمات ",
      "دەرھال دوكلات قىلىش ",
      "ئاددىي مەشغۇلات چېگرا يۈزى "
    ],
    "btn": "MAM ھېسابات نومۇرىنى ئىلتىماس قىلىش ",
    "rightTitle": "EBC كۆپ سچوت باشقۇرغۇچى سىستېمىسى (MAM) ئېلاستىك تەقسىملەش، دەل ۋاقتىدا دوكلات قىلىش، ئاددىي مەشغۇلات كۆرۈنمە يۈزىنى بىرىكتۈرگەن "
  },
  "screen1": {
    "pageEnTitle": "Asset Management",
    "pageTitle": "مەبلەغ باشقۇرۇش ",
    "title": "MetaTrader 4 ئۈچۈن لايىھەلەنگەن    ",
    "desc": "ئەقلىي ئىقتىدارلىق تولۇق ئاپتوماتىك سىستېما (EA) دىن پايدىلىنىپ كۆپ ھېساباتنى باشقۇرىدىغان سودىگەرلەرگە نىسبەتەن ئېيتقاندا، كۆڭۈلدىكىدەك تاللاش ھېسابلىنىدۇ. مەبلەغ باشقۇرۇش تەرتىپى بىرلا خىل MT4 كۆرۈنمە يۈزى ئۈنۈملۈك سودىسىنى ئەمەلگە ئاشۇردى ھەمدە كۆپ خىل ھېساباتنى باشقۇردى. ",
    "text": "便可快捷、有效、准确的，为多个<span>投资者</span>进行交易。",
    "btn": "تېخىمۇ كۆپ چۈشىنىش ",
    "imgUrl": "zh"
  },
  "screen2": {
    "pageEnTitle": "Intelligent Transaction",
    "pageTitle": "ئەقلىي ئىقتىدارلىق سودا ",
    "title": "يەككە سودا ئىسچوتى ئارقىلىق، نۇرغۇن مەبلەغ سالغۇچىلارغا تېز سودا قىلغىلى بولىدۇ. ",
    "desc": "MAM ئىسچوتى بىر ئاساسىي ئىسچوتقا باراۋەر بولۇپ، ئاستىدا بىر قانچە ئەگەشمە ئىسچوت بار، ئۇلار ئۆز ئارا مۇستەقىل بولۇپ، بىر خىل ئەگەشمە تالون شەكلى ھېسابلىنىدۇ. مەبلەغ سالغۇچى پەقەت سودا باش قول سانىنى كىرگۈزسىلا، سىستېما مەبلەغ سالغۇچى تاللىغان تەقسىمات ئۇسۇلى بويىچە سودىنى ئىجرا قىلىپ، سودىلاشقان قول سانىنى ئوخشاش بولمىغان شەخسىي مەبلەغ سالغۇچىلارغا ئاپتوماتىك تەقسىم قىلىپ بېرىدۇ. ئىسچوت باشقۇرغۇچىلار يەككە سودا ئىسچوتى ئارقىلىق، كۆپ مەبلەغ سالغۇچىلار ئۈچۈن تېز، ئۈنۈملۈك ۋە توغرا سودا قىلالايدۇ. "
  },
  "screen3": {
    "pageEnTitle": "Advantages & Functions",
    "pageTitle": "MAM ئىقتىدارى    ",
		"pageTitle2": "MAM نىڭ ئەۋزەللىكى    ",
    "title": "ھەر قانداق تۈردىكى سودا ۋە مەبلەغ باشقۇرۇشقا ماس كېلىدۇ",
    "content": [
      "ھەر قانداق تۈردىكى سودا ۋە مەبلەغ باشقۇرۇشقا ماس كېلىدۇ",
      "ئون خىل تەقسىمات ئۇسۇلىنى تاللاشقا بولىدۇ، ئۇ پايدا-زىياننى تەقسىم قىلىش، سودىدا كۆپەيتىپ تەقسىم قىلىش قاتارلىقلارنى ئۆز ئىچىگە ئالىدۇ. ",
      "سودا ھېسابات نومۇرى سانى چەكلەنمەيدۇ ",
      "سودا-مۇكەممەل ھېسابات نومۇرى، كىچىك ھېسابات نومۇرى، ئەڭ كىچىك ئۈلۈش ھېسابات نومۇرى ",
      "پايدا-زىيان تەقسىمات نۇقتىسى 16 خانىغىچە ئېنىقلىنىدۇ",
      "خالىغان زاكاز تۈرى، زىياننى توختىتىش / پايدا توختىتىش، زاكاز بېجىرىش ئىقتىدارى ",
      "ئاساسىي ھېسابات نومۇرى ئىجرا قىلىنىدىغان زاكاز تالونى ياكى شەخسىي زاكاز تالونىنىڭ بىر قىسمى تاقىلىدۇ. ",
      "ئابونتلار تېرمىنالىنى باشقۇرۇش ھېسابات نومۇرى ئەقلىي ئىقتىدارلىق تولۇق ئاپتوماتىك سىستېما سودىسىنى قوللايدۇ. "
    ],
    "desc": "Apply for a MAM Account",
    "btn": "MAM ھېسابات نومۇرىنى ئىلتىماس قىلىش ",
    "line1": "تەقسىملەش پارامېتىرىنى دەرھال ئۆزگەرتىش ",
    "line2": "بارلىق باشقۇرۇش ھېسابات نومۇرىنى ئاشكارىلاشتا ئەمەلىي ئورۇننى كونترول قىلىش كېرەك. ",
    "line3": "ئاددىي بىر كۆرۈنمە يۈز بارلىق باشقۇرۇش ھېسابات نومۇرىنى كونترول قىلىدۇ",
    "line4": "« توپ سودىسى» نى ئىجرا قىلىش ",
    "line5": "ﺑﺎﺷﻘﯘرۇش ﮬﯧﺴﺎﺑﯩﻨﯩﯔ ﺋﻪﻣﻪﻟﯩﻲ ﻣﯜﻟﯜك ۋە ﭘﺎﻳﺪا-زﯨﻴﯩﻨﻰ ﻧﺎزارەت ﻗﯩﻠﯩ ",
    "line6": "تارىخىي دوكلاتقا ئېرىشىش ھەمدە پايدىنى ھېسابلاش. ",
    "btn1": "MAM ھېسابات نومۇرىنى ئىلتىماس قىلىش "
  },
	"styleName": "en-style"
}
