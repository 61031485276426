export default {
	"fcb-class-name": "en-fcb-box",
	"video-btn": "WATCH FULL VIDEO",
	"fcb-page1": {
		"text1": "OFFICIAL PARTNER OF FC BARCELONA",
		"text2": "EBC Financial Group is honored to be the exclusive foreign exchange partner of FC Barcelona, a collaboration that intertwines financial expertise with sporting excellence. This partnership grants EBC the unique privilege to engage in specialized business activities within the foreign exchange domain.",
		"text3": "Our partnership encompasses a range of services, including foreign exchange transactions, trading, brokering (including CFDs), and advisory services.",
	},
	"fcb-page2": {
		"text1": "Dedicated to Passion",
		"text2": "Every encounter fueled by passion is a formidable challenge.<br>It transcends numbers, surpassing mere wins and losses.<br>On the journey to drive change, we are not alone.<br>We arrive with industry-defining standards to revolutionize.<br>We aspire to harness knowledge and technology,<br>To remember our origins at the starting line and give our all.",
	},
	"fcb-page3": {
		"text1": "More Than a Club",
		"text2": "Whether you're a fan or not, you recognize a legend.",
		"text3": "FC Barcelona, with a history spanning over 120 years, stands as one of the most beloved football club worldwide. It has witnessed the brilliance of iconic stars and coaches like Maradona, Messi, Ronaldinho And Guardiola. With a style that sets it apart, FC Barcelona embodies the essence of being \"more than a club.\"",
		"text4": "And EBC? We're more than just trading; we're committed to building a comprehensive global financial ecosystem.",
	},
	"fcb-page4": {
		"text1": "Setting the Benchmark",
		"text2": "A team studded with stars, a training system that cherishes passions and dreams, a trophy cabinet that speaks of proud achievements, and a commitment to positive societal contributions have all established FC Barcelona as a benchmark in the industry.",
		"text3": "The collaboration between EBC and FC Barcelona is a testament to mutual recognition of each other's strengths. EBC remains true to its commitment to treat every trading enthusiast with seriousness. With top-tier global regulation, a comprehensive protection system, and widely acclaimed international influence, EBC is setting the industry benchmark.",
	},
	"fcb-page5": {
		"text1": "Technological Innovation Crafting the 'Dream Team'",
		"text2": "FC Barcelona is globally renowned for its unique style of play – celebrated for their focus on ball possession, short passing, and team collaboration. This strategy underscores the victory of technical skill and tactical intelligence over mere physicality, influencing the evolution of modern football tactics.",
		"text3": "Similarly, EBC is dedicated to technological breakthroughs, consistently at the forefront of international standards, creating a compliant, professional, efficient, and stable global trading ecosystem for investors."
	},
	"fcb-page6": {
		"text1": "Striving for Excellence to Better the World",
		"text2": "Respect, effort, ambition, teamwork, and humility form the core values of FC Barcelona. The club's commitment to fostering these values and inspiring players to achieve their best resonates with EBC's ethos.",
		"text3": "EBC is grounded in principles of integrity and respect, valuing the passion and expectations of every trading enthusiast. We are dedicated to promoting transparency in the industry, driving its growth, and contributing to the construction of an equitable society.",
		"text4": "Together, Let's Build The Future.",
	},
	"fcb-page7": {
		"text1": "Latest Collaborative Highlights",
	}
}