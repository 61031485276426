export default {
	"screen0": {
		"title": "Giải pháp đáng <br>tin cậy cho MAM",
		"content": "Trình quản lý nhiều tài khoản EBC (MAM) được thiết kế dành riêng cho người quản lý tài sản cung cấp cho họ mức độ kiểm soát cao mà họ cần để tối đa hóa lợi nhuận. Giải pháp này cung cấp một phương thức hợp tác đáng tin cậy cho các nhà quản lý tài sản và khách hàng đang tìm kiếm các chuyên gia để quản lý quỹ.",
		"list": [
			"弹性分配",
			"即时报告",
			"简易操作界面"
		],
		"btn": "Đăng ký tài khoản MAM",
		"rightTitle": "EBC 多账户管理者系统（MAM）整合了弹性分配、<br>即时报告，以及简易化操作界面。"
	},
	"screen1": {
		"pageEnTitle": "Asset Management",
		"pageTitle": "资金管理",
		"title": "Được thiết kế cho MetaTrader 4",
		"desc": "Đó là lý tưởng cho các nhà giao dịch chuyên nghiệp quản lý nhiều tài khoản bằng Expert Advisors (EA). MAM mở rộng chức năng của Nền tảng MetaTrader 4 bằng cách cho phép người quản lý tài sản giao dịch và quản lý hiệu quả nhiều tài khoản bằng các công cụ phần mềm tích hợp từ một giao diện MT4 duy nhất.",
		"text": "便可快捷、有效、准确的，为多个<span>投资者</span>进行交易。",
		"btn": "了解更多",
		"imgUrl": "en"
	},
	"screen2": {
		"pageEnTitle": "Intelligent Transaction",
		"pageTitle": "Giao dịch thông minh",
		"title": "通过单一交易账户，便可快捷的为多个投资者进行交易",
		"desc": "Phần mềm MT4 MAM cho phép các nhà đầu tư kết nối tài khoản MT4 của họ với Nhà giao dịch chính và sao chép các giao dịch trên các phương thức phân bổ đã chọn và tự động phân bổ các giao dịch cho các nhà đầu tư cá nhân khác nhau. Người quản lý tài khoản có thể thực hiện giao dịch cho nhiều nhà đầu tư một cách nhanh chóng, hiệu quả và chính xác từ một tài khoản giao dịch."
	},
	"screen3": {
		"pageEnTitle": "Advantages & Functions",
		"pageTitle": "chức năng MAM",
		"pageTitle2": "Ưu điểm của MAM",
		"title": "Mười phương pháp phân bổ bao gồm Phân bổ P&L và Phân bổ máy photocopy thương mại",
		"content": [
			"Đối với bất kỳ loại giao dịch và quản lý tài sản nào",
			"Mười phương pháp phân bổ bao gồm Phân bổ P&L và Phân bổ máy photocopy thương mại",
			"Tài khoản giao dịch không giới hạn",
			"Giao dịch – Toàn bộ, Nhỏ & Tài khoản lô siêu nhỏ",
			"Độ chính xác tối đa của Phân bổ P&L lên tới 16 chữ số",
			"Mọi loại đơn đặt hàng, SL đầy đủ, TP & Chức năng đặt hàng đang chờ xử lý",
			"Đóng một phần đơn đặt hàng bằng cách thực thi tài khoản chính hoặc đơn đặt hàng phụ riêng lẻ",
			"Cho phép Expert Advisor (EA) giao dịch tài khoản được quản lý từ phía khách hàng"
		],
		"desc": "Apply for a MAM Account",
		"btn": "申请MAM资金管理者账号",
		"line1": "Thay đổi tức thì các thông số phân bổ",
		"line2": "Kiểm soát theo thời gian thực đối với các vị thế mở của tất cả các tài khoản được quản lý",
		"line3": "Kiểm soát tất cả các tài khoản được quản lý từ một giao diện duy nhất",
		"line4": "Thực hiện “Lệnh nhóm”",
		"line5": "Giám sát vốn chủ sở hữu và P&L theo thời gian thực của các tài khoản được quản lý",
		"line6": "Nhận báo cáo lịch sử và tính hoa hồng",
		"btn1": "Đăng ký tài khoản MAM"
	},
	"styleName": "en-style"
}

