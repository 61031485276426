export default {
	"malaria-class-name": "en-ubm-box",
	"malaria-page1": {
		"text1": "EBC Financial Group은 유엔 기금과 협력하여 말라리아 퇴치 운동을 지원합니다. 말라리아를 물리친 세대가 되어, 공평하고 새로운 시대를 이루고자 합니다.",
		"text2": "",
	},
	"malaria-page2": {
		"text1": "말라리아: 어린이 건강에 가장 큰 위협 중 하나인 전염병",
		"text2": "2022년에는 전 세계적으로 85개국에서 약 2억 4,900만 명의 말라리아 감염 환자와 60.8만 건의 말라리아 사망 환자가  예상되며, 이 중 76% 이상은 5세 이하의 어린이입니다.1분 동안 이 글을 읽는 동안에도 거의 한 명의 아이가 말라리아로 사망합니다.",
		"text3": "2.49<i>억</i>",
		"text4": "감염",
		"text5": "60.8<i>만</i>",
		"text6": "사망",
		"text7": "76%<i>+</i>",
		"text8": "아이",
		"text9": "데이터 출처: 세계보건기구(WHO) '2023년 세계 말레리아 보고서'",
	},
	"malaria-page3": {
		"text1": "함께 힘을 합쳐 말라리아를 퇴치합시다.",
		"text2": "말라리아 퇴치 운동은 유엔 기금이 시작한 세계적인 운동으로, 전 세계의 참여자들을 모아 말라리아를 퇴치하기 위해 노력하는 활동을 말합니다.EBC는 기업 책임을 적극적으로따르며, 유엔 기금이 이끄는 세계적인 말라리아 퇴치 운동을 전적으로 지지합니다.",
		"text3": "60",
		"text4": "영향을 미치는 주요 국가",
		"text5": "4000<i>만</i>",
		"text6": "보호 대상자",
		"text7": "183,450",
		"text8": "모기장 텐트",
	},
	"malaria-page4": {
		"text1": "말라리아 퇴치 5K 자선 달리기'에 참여해 보세요.",
		"text2": "2024년 4월 25일부터 5월 5일까지, 세계 각지의 지지자들과 함께하여 '말라리아 퇴치' 자선 마라톤에 참여하여, 말라리아에 대한 인식을 높이고 자금을 모아 말라리아 치료 프로젝트와 치료도구를 지원하고 있습니다.EBC 그룹은 유엔 기금과 손을 잡고, 전 세계의 사람들과 함께 말라리아 없는 세계를 만들어 가고자 합니다.",
		"text3": "지금 바로 지원하세요",
	},
	"malaria-page5": {
		"text1": "저희는 몸소 실천 중입니다",
	},
}