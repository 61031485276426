export default {
  "screen0": {
    "enTitle": "Your Trust, Our Protection",
    "title": "توردا سودا قىلىش بىخەتەرلىكى ۋە،تور ئالدامچىلىقىنىڭ ئالدىنى ئالىمىز",
    "text": "سۇپىمىزدا، بىز بىر قاتار بىخەتەر، دەل ۋاقتىدا ۋە ئاسان مەشغۇلات قىلغىلى بولىدىغان مەبلەغ كىرگۈزۈپ چىقىرىش ئۇسۇلى بىلەن تەمىنلەيمىز. سىز ھەر ۋاقىت ئېرىشكەن پايدىنى يا دەسلەپكى مەبلىغىڭىزنى. چىقىرىشقا بولىدۇ،<br /><br />ھېساباتىڭىزنى تولۇق قوغداشقا كاپالەتلىك قىلىش ئۈچۈن، بىز توردا ھەق تۆلەشكە قارىتا كەسىپنىڭ يۇقىرى دەرىجىلىك شىفىرلاشتۇرۇشنى يولغا قويدۇق ھەمدە نورمىدىن سىرت دەلىللەش تەدبىرلىرىنى قوللاندۇق، سىزنىڭ EBC دىكى بارلىق مەبلىغىڭىزنىڭ بىخەتەر بولۇشىغا تولۇق كاپالەتلىك قىلىمىز",
    "rightText": "پۇرسەت بولسا كىشىلىك ھاياتنى كونترول قىلىپ، دەرھال يولغا چىقىش كېرەك"
  },
  "screen1": {
    "smallEnTitle": "Multiple Deposit Methods",
    "smallTitle": "كۆپ خىل مەبلەغ سېلىش ئۇسۇلى",
    "title": "بىز بانكا بىرلەشمىسى دېبېت كارتىسى، تېلېگرامما پېرېۋوتى، PayPal قاتارلىق كۆپ خىل پۇل كىرگۈزۈش ئۇسۇللىرىنى قوللايمىز ",
    "desc": "سىزنىڭ تېخىمۇ كۆپ قالدۇق مەبلەغنى سودىغا ئىشلىتىشىڭىزگە كاپالەتلىك قىلىش ئۈچۈن EBC سىزنىڭ سودا ھېساباتىدىن مەبلەغ كىرگۈزۈشىڭىزگە ياكى بۇ ھېساباتتىن مەبلەغ ئاجرىتىشىڭىزغا ھېچقانداق ھەق ئالمايدۇ ",
    "list": ["مەبلەغ سېلىش ئۇسۇلى", "ئەڭ تۆۋەن مەبلەغ سېلىش", "مەبلەغ ھېساباتقا چۈشۈش ۋاقتى", "ئەڭ تۆۋەن مەبلەغ چىقىرىش", "ﭼﯩﻘﯩﻢ ﻗﯩﻠﯩﻨﻐﺎن ﭘﯘل ", "رەسمىيەت ھەققى ", ""],
    "summary": "چۈشەندۈرۈش",
    "btn": "مەبلەغ سېلىشنى باشلاش",
    "summaryDesc": "<p> مەبلەغ ھېساباتقا چۈشۈشۋاقتى ھەر قايسى بانكا، ئوتتۇرىدىكى ئاپپاراتلارنىڭ بېجىرىش ئەھۋالىغا باغلىق</p> <p>كونكرېت چەكلىك سوممىسى مۇئامىلىدارلار بانكىسى، كارتا تارقاتقۇچى بانكىنىڭ بەلگىلىمىسى بويىچە ئوخشاش بولماسلىقى مۇمكىن</p>  <p>دىققەت قىلىڭ، ئەگەر سىز پۇل ئېلىش ئىلتىماسىنى تاپشۇرغاندا، سىزنىڭ ھېساباتىڭىزمال ئىللىقلىق بولسا   ئالغان سوممىنىڭ چوڭ-كىچىكلىكىگە دىققەت قىلىڭ</p>",
    "imgUrl": "zh"
  },
  "screen2": {
    "title": " دۇنياۋى مىليونلىغان سودا قىلغۇچىلارنىڭ بىرى بولۇش،",
    "desc": "پەقەت ئۈچ قەدەم بىلەنلا، ئىنتايىن تېز سۈرئەتتە بىخەتەر ھېساب ئېچىش تەجرىبىسىدىن بەھرىمەن بولغىلى بولىدۇ",
    "list": [{
      "title": "ھېسابات ئېچىش",
      "desc": "ئاساسىي ئۇچۇرلارنى تاپشۇرۇش، بىخەتەر، ئاددىي بولغان توردا ئىلتىماس قىلىش بېتى"
    }, {
      "title": "شەخسىي ئۇچۇرنى تاپشۇرۇش",
      "desc": "پەقەت بىر مىنۇت ئىچىدە ھېسابات ئېچىپ تولدۇرۇشنى ئاسانلا تاماملىغىلى بولىدۇ"
    }, {
      "title": "مەبلەغ سېلىپ قوزغىتىش",
      "desc": "ئەڭ تۆۋەن پۇل كىرگۈزۈش سوممىسى 200 دوللار"
    }],
    "btn1": "تەقلىدىي ھېسابات ئېچىش",
    "btn2": "ھەقىقىي ھېسابات ئېچىش",
    "yuyan": "zh"
  },
  "screen3": {
    "title": "مەبلەغ كىرگۈزۈپ چىقىرىشتىكى كۆرۈلىدىغان مەسىلىلەر",
    "faqList": [{
      "title": "مەن ھېساباتىمغا قانداق پۇل كىرگۈزىمەن؟？",
      "desc": "سىز ھېسابات تۇرغۇزغاندىن كېيىن، بىزنىڭ تور تېرمىنالىمىز ئارقىلىق سىزنىڭ چىقىم قىلىش دەسلەپكى تاللاش تۈرىڭىزگە كىرگىلى بولىدۇ <br>  كونكرېت مەشغۇلات ئۇسۇلى<br>  سىستېما ئالدى بىلەن سىزنىڭ پۇل كىرگۈزۈش ھېساباتىنى تاللىشىڭىزنى ئەسكەرتىدۇ<br>  ئاندىن پۇل تۆلەش ئۇسۇلى جەدۋىلىنىڭ ئوڭ تەرىپىدىكى كۇنۇپكىنى ئىشلىتىپ پۇل تۆلەش ئۇسۇلىنى تاللاش كېرەك <br>  ھېساباتىڭىزغا پۇل كىرگۈزمەكچى بولغان سوممىنى كىرگۈزۈڭ<br> بارلىق چۈشەندۈرۈش، ماددا ۋە شەرتلەرنى تەپسىلىي ئوقۇپ چىقىڭ، ئەگەر قوشۇلسىڭىز<br> « مەن بارلىق چۈشەندۈرۈشلەرنى ئوقۇپ بولدۇم ھەمدە پۇل تۆلەش مەشغۇلاتىغا قوشۇلىدىغان ماددىلار ۋە شەرتلەر» نى تاللاشقا كاپالەتلىك قىلىڭ<br>  تاپشۇرۇش"
    }, {
      "title": "مەن نېمە ئۈچۈن بانكا بىرلەشمىسى كارتىسى ئىشلىتىپ پۇل كىرگۈزسەم مۇۋەپپەقىيەت قازىنالمايمەن؟ ",
      "desc": "ئەگەر سىزنىڭ كارتىڭىز مەبلەغ كىرگۈزۈشتە مەغلۇپ بولسا،يەنە بىر قېتىم مەبلەغ كىرگۈزۈپ، سىناپ كۆرۈڭ ۋە تەكشۈرۈش،<br> سىز كارتا ئۇچۇرىڭىزنى توغرا كىرگۈزدىڭىزمۇ؟ <br> سىز ئىناۋەتلىك كارتا ئىشلەتتىڭىزمۇ؟<br> كارتىڭىزدا يېتەرلىك مەبلەغ بارمۇ؟<br> ئەگەر يۇقىرىقى مەسىلە مەۋجۇت بولمىسا، ئەمما سىز يەنىلا مۇۋەپپەقىيەتلىك ھالدا مەبلەغ سالمىسىڭىز، ئۇنداقتا سىزنىڭ كارتا تارقاتقۇچى بانكىڭىز سىزنىڭ كارتىڭىزغا پۇل كىرگۈزۈش ھوقۇقى بەرمىگەن بولۇشى مۇمكىن.ئەگەر شۇنداق بولسا، باشقا كارتىلارنى ئىشلىتىڭ ياكى سىزنىڭ سودا ھېساباتىڭىزدىكى باشقا پۇل تۆلەش ئۇسۇلىنى ئىشلىتىڭ"
    }, {
      "title": "قانداق قىلغاندا ھېساباتتىن پۇل چىقارغىلى بولىدۇ؟",
      "desc": "سىز خالىغان ۋاقىتتا ھېساباتتىن،مەبلىغىڭىزنى چىقىرىپ قويسىڭىز،بولىدۇ. بىزنىڭ ھېسابات بۆلۈمىمىز ھەر كۈنى ئىلتىماسنى بىر تەرەپ قىلىدۇ. پۇل چىقىرىپ سىزنىڭ بانكا ھېساباتىڭىزغا يۆتكەپ بېرىلىدۇ. ئەگەر بۇ تۈرنى ئىشلەتكىلى بولمىسا، بىز مەبلەغنى خېرىدارنىڭ بانكا ھېساباتىغا ئۇرۇپ بېرىمىز. يەنە دىققەت قىلىڭ<br> بانكا تېلېگرامما ئارقىلىق پۇل پېرېۋوت قىلغاندا، ھەرقايسى بانكىلارنىڭ ئەمەلىي بىر تەرەپ قىلغان ۋاقتى ئۆلچەم قىلىنىدۇ. ھەر قايسى بانكىلارنىڭ ھېسابات ۋاقتى ئوخشىمايدۇ، تەپسىلىي بانكىدىن مەسلىھەت سوراڭ. <br>  بانكا بىرلەشمىسى پۇل چىقىرىدۇ، ئەگەر كەچ سائەت 10 دىن بۇرۇن بولسا، بىر خىزمەت كۈنى ھېساباتقا كىرىدۇ. <br>ئەگەر ھېساباتتا مال ئىللىقلىق ھالەتتە مەبلىغىڭىزنى چىقارماقچى بولسىڭىزسودا ھېساباتىدىكى ئالدىن تۆلەش نىسبىتىنىڭ چىقارغان پۇل سوممىسىدىن يەنىلا% 100 يۇقىرى بولۇشىغا كاپالەتلىك قىلىڭ، بولمىسا سۇپا سىزنىڭ پۇل ئېلىش ئىلتىماسىڭىزنى بىر تەرەپ قىلالمايدۇ"
    }, {
      "title": "رەسمىيەت ھەققى ئالامدۇ؟",
      "desc": " سۇپىمىزدا،ھەر قانداق چىقىم قىلىش ئۇسۇلىنى قوللانغان پۇل كىرگۈزۈش ياكى چىقىرىشقا نىسبەتەن، ھېچقانداق ھەق ئالمايدۇ. ئەمما شۇنىڭغا دىققەت قىلىش كېرەككى، سىز بەزى خەلقئارالىق بانكا ئاپپاراتلىرىغا پۇل تۆلىسىڭىز ياكى بۇ ئورگانلاردىن پۇل تۆلىسىڭىز خىراجەت پەيدا بولۇشى مۇمكىن. يەنە دىققەت قىلىڭ، سىزنىڭ بانكىڭىز EBC غا تۆلىگەن پۇلنى ئالدىن نەق پۇل تۆلىگەن دەپ قارىشى مۇمكىن، ھەمدە سىزنىڭ ئالدىن تاپشۇرغان نەق پۇل قائىدىسىگە ئاساسەن سىزگە ھېسابات تالونى كېسىپ بېرىدۇ. "
    }, {
      "title": "مەن باشقىلارنىڭ كارتىسىدا مەبلەغ سېلىپ چىقارسام بولامدۇ؟",
      "desc": "ياق، EBC ئۈچىنچى تەرەپتىن كەلگەن پۇلنى قوبۇل قىلمايدۇ. سىزنىڭ سودا ھېساباتىڭىزغا كىرگەن بارلىق پۇلنىڭ سىزنىڭ نامىڭىزدىكى ھېساباتتىن كەلگەنلىكىگە كاپالەتلىك قىلىڭ. ئەگەر سودا ئىسچوتى ئىگىسى بىرلەشمە ئىسچوت ئىچىدىكى بىر تەرەپ بولسا، بىرلەشمە ئىسچوتتىكى پۇل تۆلەشنى قوبۇل قىلىدۇ. <br><br> ئەگەر بىز ئۈچىنچى تەرەپ تەمىنلىگەن مەبلەغ دەپ گۇمان قىلساق، مەبلەغنى پۇل ئەۋەتكۈچىگە قايتۇرۇپ بېرىش ۋە ھېساباتىڭىزدىكى قالدۇق سوممىنى توڭلىتىش ھوقۇقىنى ساقلاپ قېلىپ، سالاھىيەت ئىسپاتى ۋە مەبلەغ مەنبەسىنى ئىسپاتلىغاندىن كېيىن ئاندىن بىر تەرەپ قىلىمىز. تەكشۈرۈلمىگەن ئەھۋالدا، ھېساباتىڭىزدىكى قالدۇق سوممىنى توڭلىتىش ھوقۇقىنى ساقلايمىز، سىز ھېساباتىڭىزدىكى قالدۇق سوممىنى ئېلىۋالسىڭىز بولمايدۇ. <br><br>  ئۈچىنچى تەرەپتىن مەبلەغ چىقىرىش قوبۇل قىلىنمايدۇ ئەگەر ئالاھىدە ئەھۋال بولسا، بىز بىلەن ئالاقىلىشىڭ "
    }]
  },
  "funding1":{
		"text1": "الإيداع والسحب",
		"text2": "نضع الأمن الالكتروني على رأس أولوياتنا.",
		"btn": "إيداع الآن",
	},
	"funding2":{
		"text1": "حلول الصناديق العالمية المتعددة العملات",
		"text2": "إيداع وسحب فوري للأموال بالعملات العالمية الرئيسية. قم بتمكين تحويل الأموال بسهولة على مدار الساعة طوال أيام الأسبوع من خلال تبسيط المعاملات الدولية متعددة العملات",
	},
	"funding3":{
		"text1": "عزل الصندوق وحفظه بشكل مستقل",
		"text2": "حساب الخدمات المصرفية للشركات",
		"text3": "تمتلك EBC حسابًا مصرفيًا للشركات في باركليز والذي يتطلب حدًا أدنى لدوران يبلغ 6.5 مليون جنيه إسترليني وعمليات تدقيق مالية صارمة وفحصًا للخلفية. التزامًا بـ CASS، تحتفظ مجموعة EBC (المملكة المتحدة) بأموال العملاء المحتفظ بها في حساب عهدة من خلال خطاب الثقة لضمان السلامة.",
		"btn": "للاطلاع اكثر",
	},
	"funding4":{
		"text1": "تأمين ضد الأضرار الفنية",
		"text2": "تمكين الأمن الشامل لأموال العملاء. قلل من خسارة الأموال من خلال تأمينات التعويضات المهنية المتعددة.",
	},
	"funding5":{
		"text1": "طرق الإيداع والسحب",
		"text2": "نحن ندعم التحويل المصرفي المحلي والتحويل البنكي الدولي والمحفظة الإلكترونية والعملات المشفرة وما إلى ذلك.",
		"text3": "طرق الإيداع",
		"text4": "العملات المدعومة",
		"text5": "حد إيداع واحد",
		"text6": "وقت معالجة الايداع",
		"text7": "وقت معالجة السحب",
		"text8": "يەرلىك بانكا",
		"text9": "دەرھال",
		"text10": "1 خىزمەت كۈنى",
		"text11": "خەلقئارالىق سىم يوللاش",
		"text12": "چەكلىمىسىز",
		"text13": "2-5 خىزمەت كۈنى",
		"text14": "ئېلېكترونلۇق ھەميان",
		"text15": "رەقەملىك پۇل",
	}
}
