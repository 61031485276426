export default {
    "box-class-name": "en-box",
    "lang": "en",
    "MT4是最受欢迎的交易平台之一": "MT4 is widely preferred by traders globally for its intuitive interface and robust features.",
	"内核稳定流畅": "Offers advanced chart analysis to help traders create precise strategies.",
	"支持EA交易": "Supports EA trading systems for automated operations.",
	"活跃用户社区": "Advanced technological tools to meet diverse needs.",
	"立即注册": "Register Now",
    "MetaTrader 4下载": "MetaTrader 4 Download",
    "MT4移动版": "MT4 Mobile Version",
    "随时随地进行交易": "Trade anywhere, anytime, stay on top of the market trends and manage your account with ease.",
    "下载Android版": "Download Android Version",
    "下载IOS版": "Download IOS Version",
    "下载iPad版": "Download iPad Version",
    "MT4桌面版": "MT4 Desktop Version",
    "全面的交易工具和高级图表分析": "Advanced chart analysis and comprehensive trading tools designed for professional traders' high-level needs.",
    "下载Windows版": "Download Windows Version",
    "下载MacOS版": "Download MacOS Version",
    "MT4网页版": "MT4 Webtrader",
    "无需下载": "No need for downloads to access the global marketplace at breakneck speeds, easily enabling full-rounded online transaction management.",
    "打开网页版": "Open Webtrader"
}