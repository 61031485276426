export default {
  "srceen0": {
    "title": "レバレッジと必要証拠金",
    "subTitle": "取引における一定のルール",
    "desc": "お客様の資産を保護するため、EBCのレバレッジは最大500倍までを提供しております。",
    "rateTitle": " レバレッジ最高倍率 ",
    "rateNum": "500倍",
    "rateDesc": "証拠金率0.20%"
  },
  "srceen1": {
    "pageEnTitle": "Secure Transaction",
    "pageTitle": "取引銘柄と最大レバレッジ",
    "subTitle": "適切なレバレッジ倍率の選択",
    "desc1": "取引銘柄により、最大レバレッジが異なります。",
    "desc2": "下表の各取引銘柄の最大レバレッジと証拠金率を参照し、リスクマネジメントにお役立てください。",
    "btn": "今すぐ取引",
    "title":"取引商品一覧はこちら"
  }
}
