export default {
	"box-name": "partner2-page-en",
	"section1": {
		"text1": "อิทธิพลที่มีต่อพันธมิตร",
		"text2": "เราสร้างระบบนิเวศนวัตกรรมใหม่ที่จะเป็นศูนย์รวมของนักออกแบบเนื้อหา ผู้เชี่ยวชาญด้านการตลาด และตัวแทนซื้อขาย ซึ่งช่วยให้นักเทรดได้แสดงศักยภาพอย่างเต็มที่ ปลดล็อคทุกขีดจำกัด เปิดโอกาสก้าวสู่ระดับโลก",
		"text3": "ธุรกิจครอบคลุมมากกว่า 20 ประเทศ",
		"text4": "ศูนย์รวมผู้เชี่ยวชาญชั้นนำในอุตสาหกรรม",
		"text5": "โครงการความร่วมมือที่หลากหลาย",
		"text6": "ระบบสนับสนุนทรัพยากรที่ครอบคลุม",
	},
	"section2": {
		"text1": "แผนความร่วมมือ",
		"text2": "Introducing Broker (IB)",
		"text3": "ผู้ที่ต้องการเรียนรู้ นักเทรดมืออาชีพ นักเทรดมือสมัครเล่น และบริษัทต่างๆ",
		"text4": "<li>การวางแผนพัฒนาธุรกิจ</li><li>โอกาสในการเข้าถึงและเชื่อมโยงแบรนด์</li><li>สนับสนุนทรัพยากรการซื้อขายและแคมเปญการตลาด</li>",
		"text5": "เข้าร่วมเป็น IB",
		"text6": "CPA Marketing Partner",
		"text7": "เจ้าของเว็บไซต์ หรือ blogger,<br> KOL, online marketer",
		"text8": "<li>แพ็คเกจรางวัล</li><li>แผนความร่วมมือที่กำหนดเอง</li><li>สนับสนุนการตลาดผ่านสื่อมัลติมีเดีย</li>",
		"text9": "ร่วมเป็นพันธมิตรด้าน Marketing",
		"text10": "นักกลยุทธ์",
		"text11": "นักวิเคราะห์มืออาชีพ นักเทรดมือสมัครเล่น และนักกลยุทธ์",
		"text12": "<li>ออกแบบของรางวัลสุดพิเศษ</li><li>กิจกรรมมากมายทั้งแบบออนไลน์และออฟไลน์</li><li>สนับสนุนทรัพยากรการซื้อขายและแคมเปญการตลาด</li>",
		"text13": "ร่วมเป็นผู้สนับสนุนเชิงกลยุทธ์",
	},
	"section3": {
		"text1": "สนับสนุนทรัพยากรทางการตลาด",
		"text2": "เปิดโอกาสทำให้แบรนด์เป็นที่รู้จัก",
		"text3": "แพลตฟอร์มสำหรับการให้ความรู้ ส่งเสริมการสร้างภาพลักษณ์ที่น่าจดจำให้กับแบรนด์ของพันธมิตร",
		"text4": "ขยายเครือข่ายในอุตสาหกรรม",
		"text5": "เชื่อมต่อกับผู้เชี่ยวชาญชั้นนำในอุตสาหกรรม แลกเปลี่ยนประสบการณ์ สำรวจแนวโน้มของอุตสาหกรรมในปัจจุบัน",
		"text6": "สนับสนุนทรัพยากรทางการตลาด",
		"text7": "จัดเตรียมสื่อทางการตลาด สนับสนุนการทำกิจกรรม ปรับแต่งกลยุทธ์ส่งเสริมการขายให้เหมาะสมกับแบรนด์ของพันธมิตร",
		"text8": "จัดฝึกอบรมสำหรับนักวิเคราะห์มืออาชีพ",
		"text9": "การฝึกอบรมนักวิเคราะห์มืออาชีพ เพื่อเพิ่มทักษะและความเชี่ยวชาญ เท่าทันการเปลี่ยนแปลงของตลาด",
		"text10": "เครื่องมือ order flow",
		"text11": "เครื่องมือสำคัญสำหรับนักเทรดมืออาชีพ ตัวช่วยระบุเงินทุนหลักและปรับปรุงอัตราการทำกำไรให้กับธุรกรรม",
		"text12": "พัฒนาทักษะส่งเสริมการเรียนรู้",
		"text13": "สนับสนุนการสร้างเนื้อหาเชิงให้ความรู้ พัฒนาศักยภาพนักเทรด เรียนรู้เทคนิคระดับมืออาชีพ",
	},
	"section4": {
		"text1": "พันธมิตร",
		"text2": "Joe DiNapoli",
		"text3": "<li>ฉายา Godfather of Fibonacci </li><li>ผู้คิดค้น DiNapoli Levels </li><li>ผู้เริ่มใช้ FibNodes software</li>",
		"text4": "Joe DiNapoli มีประสบการณ์ในตลาดกว่า 55 ปี เป็นที่รู้จักด้านการคาดการณ์จุดเปลี่ยนของวัฏจักรที่แม่นยำ ได้แก่ เหตุการณ์หุ้นดาวโจนส์ตกในปี 1987 และจุดต่ำสุดของตลาดในปี 2002 เป็นต้น นอกจากเขาจะเป็นที่ปรึกษาด้านการเทรดสินค้าโภคภัณฑ์ที่จดทะเบียน (CTA) มานานกว่า 25 ปีแล้ว เขายังเป็นผู้เขียนหนังสือ \"DiNapoli Levels\" ซึ่งได้รับการแปลถึง 13 ภาษาและเป็นที่รู้จักไปทั่วโลก",
		"text5": "เรียนรู้เพิ่มเติม",
		"text6": "<li>Lawrence มีประสบการณ์ในด้านการก่อสร้างเชิงพาณิชย์ก่อน ที่จะผันตัวเข้าสู่ตลาดการเงินแบบเต็มตัว การได้รู้จักกับเทคนิค \"DiNapoli Levels\" ของ Joe DiNapoli เป็นจุดเปลี่ยนให้เขาตัดสินใจปรับปรุงแนวทางของตัวเอง นับตั้งแต่ที่ได้ร่วมงานสัมมนาของ Joe DiNapoli ที่กรุงเทพฯ เมื่อ 17 ปีก่อนทำให้เขาสามารถคาดการณ์ความเคลื่อนไหวของตลาดได้แม่นยำ รวมถึงนำทางการลงทุนในช่วงวิกฤตตลาด Covid ปัจจุบันเขาเป็นผู้เชี่ยวชาญของ DiNapoli คอยให้คำปรึกษาแก่นักลงทุนมือใหม่และให้ความรู้เกี่ยวกับการลงทุนในด้านต่างๆ</li>",
		"text7": "<li>Pieter มีความสนใจการลงทุนตั้งแต่สมัยมัธยมปลาย เขาเป็น นักเศรษฐศาสตร์ที่มีคุณวุฒิระดับเกียรตินิยม เริ่มลงทุนแบบ เต็มตัวที่บริษัทในท้องถิ่นของแอฟริกาใต้ โดยเริ่มต้นในตลาด ที่หลากหลาย เขาเป็นลูกศิษย์ของ DiNapoli ตั้งแต่ปี 2003 และเริ่มต้นสอนเทคนิค DiNapoli ตั้งแต่ปี 2009 ปัจจุบันเขา จัดการดูแลบัญชีของตัวเองเป็นส่วนหนึ่งของกองทุนเฮดจ์-    -ฟันด์ในแอฟริกาใต้เป็นที่ปรึกษาของบริษัทสินค้าโภคภัณฑ์ในแอฟริกาใต้และเป็นผู้เขียนจดหมายข่าว D-Levels Financial Forecast ถือว่าความสำเร็จส่วนใหญ่มาจากการให้คำปรึกษาที่เหมาะสมจาก Joe DiNapoli</li>",
		"text8": "<li>คุณมนต์ชัย ผู้ก่อตั้งสมาคมนักวิเคราะห์ทางเทคนิคในประเทศไทยและประธานร่วมองค์กร CMT Association Thailand Chapters  รวมถึงเป็นประธาน Efuturesthai Trading Academy องค์กรที่จัดตั้งขึ้นเพื่อสอนและฝึกอบรมการวิเคราะห์ ทางเทคนิคให้กับนักลงทุนชาวไทยโดยตรง<br><br>คุณมนต์ชัยเป็นนักลงทุนมืออาชีพนักวิเคราะห์ทางเทคนิค เชี่ยวชาญด้านเทคนิค Fibonacci (DiNapoli Levels) และการเทรดแบบอัลกอริทึม คุณมนต์ชัยได้ร่วมงานกับ Joe DiNapoli ในปี 2011 และเคยจัดงานสัมมนาร่วมกับภาครัฐและภาคเอกชนในประเทศไทยหลายครั้ง รวมถึงเป็นผู้แปลหนังสือ “Trading with DiNapoli Levels” ฉบับภาษาไทย ครั้งแรกในปี 2022</li>",
		"text9": "<li>Jason เป็นผู้เชี่ยวชาญในด้าน Golden Ratio trading และเป็นหุ้นส่วนที่ Bright Futures Fund (USA)ดำรงตำแหน่งผู้จัดการทั่วไปของ Golden Ratio Investment Consulting Co., Ltd. (USA) เป็นวิทยากรรับเชิญที่ New York Institute of Finance (สาขาปักกิ่ง) และ Chicago Mercantile Exchange นอกจากนี้เขายังเป็นหนึ่งในที่ปรึกษาเหรียญทอง ในการแข่งขัน National Real Trading Competition จัดโดย Futures Daily และเป็นผู้ แปลหนังสือ \"Trading with DiNapoli Levels\" ฉบับภาษาจีน ในฐานะผู้เชี่ยวชาญด้านเทคนิคของ DiNapoli เขาเคยทำนายจุดเปลี่ยนสำคัญของดัชนี Shanghai Composite เมื่อปี 2008 และจุดสูงสุดจุดต่ำสุดของดัชนี Dow Jones ดัชนี US Dollar น้ำมันดิบของสหรัฐฯ และ Gold futures </li>",
	},
	"section5": {
		"text1": "แบ่งปันข้อมูลเพิ่มเติม",
		"text2": "เมื่อวันที่ 12 กรกฎาคม 2024 EBC Group และผู้เชี่ยวชาญด้านเทคนิค DiNapoli ได้จัดพิธีประกาศความร่วมมืออย่างเป็นทางการ ณ กรุงเทพฯ ประเทศไทย ร่วมสนทนาเกี่ยวกับกลยุทธ์การเทรดและการรวมพลังของทั้งสองฝ่าย เพื่อก้าวสู่การเป็นผู้นำในตลาดโลก",
		"text3": "แบ่งปันกลยุทธ์",
		"text4": "DiNapoli และทีมงานได้ร่วมแบ่งปันกลยุทธ์การเทรดขั้นสูง ซึ่งได้รับความสนใจจากสื่อมวลชนและนักเทรดเป็นอย่างมาก",
		"text5": "แจกหนังสือพร้อมลายเซ็น DiNapoli",
		"text6": "ของรางวัลสุดพิเศษ หนังสือ DiNapoli Levels พร้อมลายเซ็นจำนวนจำกัด ช่วยทำให้ DiNapoli ได้รับความนิยมมากขึ้น",
		"text7": "เสริมสร้างอิทธิพลของทั้งสองฝ่าย",
		"text8": "ความร่วมมือครั้งนี้ส่งเสริมการพัฒนาศักยาภาพด้านเทคนิคในการทำธุรกรรม ซึ่งได้รับการยอมรับและทำให้ทั้งสองฝ่ายมีอิทธิพลในอุตสาหกรรมมากขึ้น",
	},
}