export default {
	"box-name": "partner2-page-en",
	"section1": {
		"text1": "ساودا قىلغۇچىلارنى كۈچەيتىش تۆرى",
		"text2": "بىز مەزمۇن يارىتىشچىلار، بازار ھىساباتى مۇتەخەسسىسلىرى ۋە ۋاكالەتچىلەرنى بىرلىكتە قوزغاتقاق ئاكتىپ ئېكولوگىيىلىك سېستېما ھاسىل قىلىمىز، ساودا قىلغۇچىلارغا كۈچ-قۇۋۋەت بېرىپ، پۈتۈن دۇنيادىكى پۇرسەتلەرنى كېڭەيتىمىز ۋە ئۇلارنىڭ چوققا ئىقتىدارىنى ئاچقىلايمىز.",
		"text3": "20 دىن ئارتۇق دۆلەتتە پائالىيەت بۇلۇۋاتىدۇ",
		"text4": "ئېنگ كىلايىن سەنئەت مۇتەخەسسىسلىرىنىڭ مەخسۇسىيىتىدىن پايدىلىغۇلىدۇ",
		"text5": "تۈرلۈك ھەمكارلىق ئۇسۇلىنى تەمىنلەيدۇ",
		"text6": "تولۇقلا ياردەم خاتنى تەمىنلەيدۇ",
	},
	"section2": {
		"text1": "ھەمكارلىق مودېللىرى",
		"text2": "تانىشتۇرۇش ساوداچىلار (IB)",
		"text3": "ئوپەتېدۇ، كەسپى ساوداچىلار، مۇستەقىل مالىيە باشقۇرغۇچى ۋە ۋەكىللەر",
		"text4": "<li>بىزمېس تەرەققىيات پىلانىلاشتۇرۇش</li><li>ماركا ھەمكارلىقى ۋە ئاشكارىلانغان پۇرسەتلەر</li><li>ساودا مەنپەئىتى ۋە بازار ياردىمى</li> ",
		"text5": "IB بۆلۈش",
		"text6": "CPA بازاردىشى ھەمكار",
		"text7": "بلوگ يېزىدىغانلار، KOLلار <br> ۋە ئونلاين بازاردېشىلار",
		"text8": "<li>مۇكاپات بۆلەكلىرى</li><li>زېھىنلىك ھەمكارلىق پىلانلىرى</li><li>كۆپ مېدىيىلىك بازارداش ياردىمى</li>  ",
		"text9": "بازاردىشى ھەمكار بۆلۈش",
		"text10": "ستراتېگىيلەر ھەمكارچىسى",
		"text11": "كەسپىلىك ئانالىستلار، تەجىربىلىك ساوداچىلار ۋە<br> سەنئەيت مۇتەخەسسىسلىرى",
		"text12": "<li>خاس بۆلەك</li><li>توردا ۋە تورسىز بازار يەنئى قىلىش</li><li>ساودا مەنپەئىتى ۋە بازار ياردىمى</li> ",
		"text13": "ستراتېگىك ھەمكار بۆلۈش",
	},
	"section3": {
		"text1": "بازار ياردىمى",
		"text2": "مۇخبىرلاردا نام تاپشۇرۇش",
		"text3": "مەخسۇسىيىتىنى كۆرۈندۈرىدىغان سەھەرە تەمىنلىپ، ستراتېگىيلەر ھەمكارلار ئۈچۈن ماركا ئېتىراپىنى ئاشۇرۇش.",
		"text4": "ئانچار ئۈچۈن بوغلاشتۇرۇشنى كېڭەيتىش",
		"text5": "تۆپى ئەڭ يېڭى كەسپى مۇتەخەسسىسلىرى بىلەن ئۇلىنىش، تەجىربە ھەمبەھر قىلىش ۋە بازار ئارايىمىز.",
		"text6": "بازارلارداش مەخسۇلىيەت ياردىمى",
		"text7": "بازار يئۈڕۈش مەزمۇنى ھەمپارلاشتۇرغىلى، مەخسۇسىيەتلىك تەشۋىقات ئۇسۇلى بىلەن قاندىلاشنى تەمىنلەش.",
		"text8": "يۇقۇرى دەرىجىلىك ئانالىستلىق ترېنېر پىروگراممىلىرى",
		"text9": "بىلمىنى ئاشۇرۇش ۋە مەخسۇسىيىتىنى كېڭەيتىش ئۈچۈن يۇقۇرى دەرىجىلىك ئانالىستلىق ترېنېر پىروگراممىلىرى.",
		"text10": "بېلەتنىڭ ئاققىنى قوراللىرى",
		"text11": "ئەڭ ئەسلى ساوداچىلار ئۈچۈن بوي بەلەقلىرىنى ئايرىپ بېرىدىغان زۇرۇر قوراللار ۋە كەسپىي تېژىيەت يۇكارلىنىش نەتىجىلىرىنى ياخشىلەش.",
		"text12": "تۇيغۇيى كۆتۈرۇشنى كېڭەيتىش",
		"text13": "بىلم بانک قۇۋۋەتتەشكەن قابىلىنى ئەزەللەش، تەسىرنى كېڭەيتىش، ۋە ئەيسىي تېتى بىقريرەت تەسىرنە تولۇقلايدۇ.",
	},
	"section4": {
		"text1": "ئىستراتېگىيىلىك شېرىكلەر",
		"text2": "Joe DiNapoli",
		"text3": "<li>فىبوناچچىنىڭ نايىبى</li><li>DiNapoli دەرىجىلىرىنىڭ قۇرغۇچىسى</li><li>ئالتۇن نىسبەت ساودا ئۇسۇلى قوللىنىڭ ئاخىر تەرتىپلىك شەخسى دەپ ئىتىراپ قىلىنغان</li> ",
		"text4": "Joe DiNapoli 55 يىلىمدىن ئارتۇق ساودا تەجىربىسىگە ئىگىدۇر، بۇنىڭغا قوشۇمچە 1987 يىلى بازار قازاسىنى پاناڭ ھاۋا، S&P كېلەجەكنى 1982 يىلىدىن باشلاپ ساودا قىلدى. كوزنىڭچى ئورۇنلاشتۇرۇرۇلغان يۇڭرى سۈركۇنچۇ، ئىلغار فىبۇناچچى ئۇسۇلى ۋە MACD تەخمىنى دا ئازھاردا تۇرىدۇ، ئۇنىڭ 13 تىلغە تەرجىمە قىلىنغان «DiNapoli دەرىجىلىرى بىلەن ساودا قىلىشتا» مەشھۇر كىتىپىنىڭ ئاپتورى. 25 يىلىمدىن ئارتۇق ۋاقىت مەزگىلدە خىش ھەوەسلىلىك يازما مۇرۇنگە ئىگە يوق بىرCTR. دۇنيائۇيكى مەككە-مۇكەرمەدە پەن پەرمان، ۋە ھەر كۈنى ساودا ئىلتىجاتى .",
		"text5": "تېخى بەتەڭ",
		"text6": "<li>Lawrence, with a background in commercial construction, transitioned to the financial markets for a more fulfilling career. Discovering Joe DiNapoli's \"DiNapoli Levels\" transformed his trading approach. Since attending Joe's seminar in Bangkok 17 years ago, Lawrence accurately predicts market movements, including navigating the Covid Market Crash. Now a DiNapoli Expert, he mentors new traders to avoid pitfalls and accelerate learning.</li>",
		"text7": "<li>Pieter has been interested in trading since high school. As a Cum Laude qualified economist, he started full time trading at a local South African firm, intensely trading a broad spectrum of markets. He has been a student of DiNapoli studies since 2003 and teaching the DiNapoli method across the world since 2009. Today, he manages his own account, is part of a South African hedge fund and advisor to South African commodity firms. He is also the author of the D-Levels Financial Forecast newsletter. He attributes much of his success to Joe DiNapoli’s mentorship.</li>",
		"text8": "<li>Monchai Kongthanapakdi is founder of Technical Analysts Association in Thailand and Co-chair of CMT Association Thailand Chapters as well as president of Efuturesthai Trading Academy, an organization established for teaching and training technical analysis for Thai investors.<br><br>He is a full-time trader, technical analyst, and investor who specializes in Fibonacci (DiNapoli Levels) and Algorithmic trading. Monchai has been working with Mr. Joe DiNapoli since 2011 to conduct several public and private seminars in Thailand including a recent translation of Trading with DiNapoli Levels (Thai Edition) in 2022.</li>",
		"text9": "<li>President of Fibonacci Investment Consulting, LLC, USA; Guest Lecturer at the New York Institute of Finance Beijing Branch and the Chicago Board of Trade; Gold Medal Mentor at the National Real-Money Trading Competition of China Futures Daily; Translator of the Chinese edition of \"Trading with DiNapoli Levels\". As a senior expert of the DiNapoli-Levels, he has successfully predicted nearly all the turning points of the Shanghai Composite Index since 2008, as well as several intermediate tops and bottoms of the Dow Jones Index, the US Dollar Index, US crude oil futures, and US gold futures.</li>",
	},
	"section5": {
		"text1": "تېخىمۇ كۆپ تونۇش",
		"text2": "2024-يىلى 7-ئاينىڭ 12-كۈنى، EBC مالىيە گۇرۇپپىسى ۋە كەسپىي ساوداچى Joe DiNapoli بانكوك, جەھاندىكى ساودا ئۇسۇلى ۋە زۇڭغۇ بازاردا ھەمكارلىق قىلىش گوزۈرۇتى بىلەن بىرگىك گزېتلەك ئۇسۇلى ئۆتتى.",
		"text3": "ساودا ئۇسۇلى بىلەن بەھىلەشمەك",
		"text4": "DiNapoli ۋە ئۇنىڭ كوماندىسى بۇ خارەزاردىلا ئىلغار ساودا ئۇسۇلىنى مۇھاكىمە قىلدى، يەردىكى تەھلىچىلەر، VIP قوپاللىق باشچىلىرىمىزنىڭ جاھۇلاپ مۇھىت كېلەكتە تولۇق كۆرسىتىلىدى.",
		"text5": "مۇقاۋرلانغان ھاستا كىتاپىنى تەسلىم قىلدى",
		"text6": "گوزۈرۇتتە، DiNapoli مۇخلارىلارغا ئاسوپ قلىنغان كىتاپ رۇيپىنى تەسلىم قىلدى.",
		"text7": "بىرگىلىكتىكى ئېتىمالىرچىلىك ۋە ئوروش مېۋەلەنسنى ئېچىدى",
		"text8": "بۇ ھەۈكارلىق كەپىرى ساۇداغا تەجىربە يېڭىلىق ئېلبەندىك ئىرى تەىدن بۇرىتە كەلگۈسى پەرق ئالغا ياۋۇغۇلەك.",
	},
}