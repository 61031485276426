export default {
	"fca1": "Các bước mở tài khoản thanh toán thanh khoán FCA",
	"fca1-m": "Các bước mở tài khoản thanh toán thanh khoán FCA",
	"fca2": "EBC Financial Group (UK) Ltd được ủy quyền và quản lý bởi Cơ quan Quản lý Tài Chính (FCA). Tất cả các lệnh giao dịch được chuyển trực tiếp tới nhóm thanh toán quốc tế. Số giấy phép: 927552<br><br>Mở tài khoản thanh toán thanh khoản FCA tại EBC Financial Group (UK) Ltd cho phép bạn có thể yêu cầu bồi thường lến đến 85.000£ theo Điều khoản bồi thường FSCS.",
	"fca3": "Định hướng Công cụ Tài chính thị trường (MiFID) của liên minh Châu Âu và các bản sửa đổi tiếp theo MiFID II, phân loại các nhà đầu tư thành \"nhà đầu tư nhỏ lẻ\" hoặc \"nhà đầu tư chuyên nghiệp\" theo kiến thức, kinh nghiệm và chuyên môn của họ.<br><br>Theo quy định của FCA, EEBC Financial Group (UK) Ltd chỉ chấp nhận các nhà đầu tư chuyên nghiệp và nhà đầu tư tổ chức, đồng thời có thể mở tài khoản thanh toán thanh khoản FCA với đòn bẩy gấp 100. Tiền của nhà đầu tư sẽ được lưu trữ độc lập trong tài khoản tách biệt của Ngân hàng Barclays.",
    "fca4":{
		"title":"Nhà đầu tư chuyên nghiệp phải đáp ứng được 2 trong 3 yêu cầu dưới đây:",
		"title-m":"Nhà đầu tư chuyên nghiệp phải đáp ứng được 2 trong 3 yêu cầu dưới đây:",
		"desc":"Tần xuất giao dịch trung bình trong năm đạt mốc 10 lần/quý",
		"desc1":"Nắm giữ hạng mục đầu tư hơn 500.000 EUR, bao gồm tiết kiệm tiền mặt và công cụ tài chính",
		"desc2":"Có ít nhất 1 năm kinh nghiệm làm việc trong ngành tài chính, hiểu về CFD hoặc giao dịch phái sinh."
	},
	"fca5":{
		"title":"Các bước mở tài khoản thanh toán thanh khoán FCA",
		"desc":"Tải mẫu đơn xin và điền đầy đủ",
		"desc1":"Chuẩn bị tài liệu và chứng thực",
		"desc2":"Gửi tài liệu và đăng ký mail",
		"desc3":"Phê duyệt hợp quy và ký kết tài liệu",
		"desc4":"Mở và cài đặt thành công tài khoản FCA",
		"btn":"Tải mẫu đơn đăng ký nhà đầu tư chuyên nghiệp",
		"btn1":"Tải mẫu đơn đăng ký nhà đầu tư tổ chức"
	},
	"fca6":{
		"title":"Mở tài khoản thanh toán thanh khoản FCA Bảo vệ theo quy định có thẩm quyền nhất và đảm bảo tài chính an toàn nhất",
		"title-m":"Mở tài khoản thanh toán thanh khoản FCA Bảo vệ theo quy định có thẩm quyền nhất và đảm bảo tài chính an toàn nhất",
		"desc":"Cơ quan Quản lý Tài Chính (FCA)",
		"desc1":"email: ",
		"desc2":""
	},
	"lang":"en"
}

