export default {
	"security-class-name": "security-en-box",
	"security-page1": {
		"text1": "กฎระเบียบระดับสากล",
		"text2": "ตรวจสอบใบอนุญาต",
	},
	"security-page2": {
		"text1": "คุ้มครองอย่างครอบคลุม",
		"text2": "แผนค่าชดเชยจากกองทุน FSCS สูงสุด 85,000 ปอนด์",
		"text3": "EBC ดำเนินการภายใต้ใบอนุญาตตามกฎระเบียบแบบเต็มรูปแบบจาก FCA  เป็นหนึ่งในโบรกเกอร์ที่สามารถเปิดบัญชีหักล้างสภาพคล่องของ FCA ได้ โดย FCA จะรับประกันคุ้มครองแผนค่าชดเชยแก่ผู้ใช้บริการทุกรายสูงสุด 85,000 ปอนด์ หลังจากก่อตั้งกองทุน FSCS ได้มีการเพิ่มฐานค่าชดเชยอย่างต่อเนื่อง คาดการณ์ว่าอัตราค่าตอบแทนต่อปีจะอยู่ที่ 500-600 ล้านปอนด์ ปัจจุบันได้มีการจ่ายค่าชดเชยไปแล้วกว่า 5.5 พันล้านปอนด์",
		"text4": "บัญชีนิติบุคคลของ Barclays Bank",
		"text5": "EBC มีบัญชีนิติบุคคลของ Barclays Bank ซึ่งตามข้อกำหนดต้องมีเงินหมุนเวียนขององค์กรหรือเงินฝากมากกว่า 6.5 ล้านปอนด์และต้องผ่านการตรวจสอบเส้นทางประวัติทางการเงินอย่างเข้มงวด โดยบัญชี Barclays ของ  EBC group (UK) ได้ปฏิบัติตามกฎระเบียบ CASS อย่างเคร่งครัดและจัดการกองทุนของลูกค้าอย่างอิสระ เพื่อความปลอดภัยและความเป็นอิสระของกองทุน",
		"text6": "คุ้มครองค่าชดเชยสูงสุด 20,000 ยูโร",
		"text7": "The Financial Commission เป็นองค์กรระดับโลกที่ร่วมทำหน้าที่ยุติข้อพิพาทการให้บริการทางการเงิน (EDR) ดำเนินการแก้ไขข้อพิพาทอย่างรวดเร็วและยุติธรรม ผ่านการพิจารณาคดีที่เข้มงวด โปร่งใส และเปิดกว้าง ในปัจจุบันบริษัทได้มีดำเนินการจ่ายค่าชดเชยไปแล้วกว่า 51 ล้านดอลลาร์สหรัฐ และดำเนินการแก้ปัญหาข้อพิพาทไปแล้วเกือบ 10,000 คดี EBC ได้เข้าร่วมกองทุนเงินทดแทนเพื่อสามารถคุ้มครองค่าชดเชยเพิ่มเติมแก่ผู้ใช้งานได้สูงสุดถึง 20,000 ยูโร",
		"text8": "ประกันภัยความรับผิดทางวิชาชีพคุ้มครองสูงสุด 10 ล้านดอลลาร์",
		"text9": "EBC มีการซื้อประกันภัยความรับผิดทางวิชาชีพทุกปีด้วยจำนวนเงินประกันคุ้มครองมากกว่า 10 ล้านดอลลาร์สหรัฐ จากสถาบัน Lloyds of London และบริษัท AON โดยบริษัทประกันภัยจะจ่ายค่าชดเชยเต็มจำนวนสำหรับการสูญเสียของผู้ใช้และการเรียกร้องที่เกิดจากความประมาทเลินเล่อหรือความผิดพลาดของผู้เอาประกันภัยภายใต้กระบวนการให้บริการ ในขณะเดียวกันภายในระยะคุ้มครองของประกัน ลูกค้าทุกคนจะได้รับความคุ้มครองด้วยโซลูชั่นที่ต่อเนื่องและเป็นมืออาชีพจากผู้เชี่ยวชาญด้านประกันภัยโดยตรง",
		"text10": "เรียนรู้เพิ่มเติม",
	},
}