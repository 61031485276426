<template>
	<div class="home-header">
		<nav class="navbar fixed-top navbar-expand-lg" :style="{'background-color':backgroundColor}">
			<div class="container-fluid home-header-bd">
				<router-link class="navbar-brand" to="/" :class="$t('CFooters.className')"><img src="@/assets/logo.png"
						alt="" width="145" height="60">
				</router-link>
				<button class="navbar-toggler" @click="showHeadMenu()">
					<img src="../../assets/image/Home/head-icon.png">
				</button>

				<div class="collapse navbar-collapse justify-content-around" :class="{'headState':headMenu}">
					<ul class="navbar-nav" :class="{'dropdown-roll':rollState}">
						<template v-for="(item, index) in $t('CFooters.headlist')">
							<li :key="index" v-if="item.menu && isMobil" class="nav-item dropdown"
								@click="dropdownshow(index)" title="手机">
								<a class="nav-link dropdown-toggle" href="#" :id="'navbarDropdown' + index"
									role="button" data-bs-toggle="dropdown" aria-expanded="false" v-html="item.text"
									:class="$t('CFooters.className')">
								</a>
								<ul class="dropdown-menu" :aria-labelledby="'navbarDropdown' + index"
									:class="{'show':menuIndex === index}">
									<li v-for="(me, cIndex) in item.menu" :key="cIndex"
										@click.stop="dropdownshow1(cIndex)">
										<router-link v-if="me.url" class="dropdown-item"
											:to="!$store.state.suffix ? me.url : ('/' + $store.state.suffix + me.url)" v-html="me.text"></router-link>
										<div v-else>
											<a class="nav-link dropdown-toggle" :class="{'color-h':!rollState}" href="#"
												:id="'navbarDropdown1' + cIndex" role="button" data-bs-toggle="dropdown"
												aria-expanded="false" v-html="me.text">
											</a>
											<ul class="dropdown-menu" :aria-labelledby="'navbarDropdown1' + cIndex"
												:class="{'show':threeIndex === cIndex}">
												<li v-for="(tm, tIndex) in me.menu" :key="tIndex">
													<router-link class="dropdown-item"
														:to="!$store.state.suffix ? tm.url : ('/' + $store.state.suffix + tm.url)"
														v-html="tm.text"></router-link>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</li>
							<li :key="index" v-if="item.menu && !isMobil" class="nav-item dropdown"
								@mouseenter="dropdownshow(index)" @mouseleave='dropdownhied()'>
								<a class="nav-link dropdown-toggle" href="#" :id="'navbarDropdown' + index"
									role="button" data-bs-toggle="dropdown" aria-expanded="false" v-html="item.text">
								</a>
								<ul class="dropdown-menu" :aria-labelledby="'navbarDropdown' + index"
									:class="{'show':menuIndex === index}">
									<li v-for="(me, cIndex) in item.menu" :key="cIndex"
										@mouseenter="dropdownshow1(cIndex)" @mouseleave='dropdownhied1()'>
										<router-link v-if="me.url" class="dropdown-item"
											:to="!$store.state.suffix ? me.url : ('/' + $store.state.suffix + me.url)" v-html="me.text"></router-link>
										<div v-else>
											<a class="nav-link dropdown-toggle three-link"
												:class="{'color-h':!rollState}" href="#"
												:id="'navbarDropdown1' + cIndex" role="button" data-bs-toggle="dropdown"
												aria-expanded="false" v-html="me.text">
											</a>
											<ul class="dropdown-menu" :aria-labelledby="'navbarDropdown1' + cIndex"
												:class="{'show':threeIndex === cIndex}">
												<li v-for="(tm, tIndex) in me.menu" :key="tIndex">
													<router-link class="dropdown-item"
														:to="!$store.state.suffix ? tm.url : ('/' + $store.state.suffix + tm.url)"
														v-html="tm.text"></router-link>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</li>
							<li :key="index" class="nav-item" v-if="item.url">
								<a href="https://ebcfinsns.co/" class="nav-link active"
									v-if="item.url == 'https://ebcfinsns.co/'">{{item.text}}</a>
								<router-link class="nav-link"
									:class="{'c-008aed': item.url === '/Login','active': item.url === '/win' || item.url === '/from0tohero'}"
									:to="!$store.state.suffix ? item.url : ('/' + $store.state.suffix + item.url)" v-html="item.text" v-else></router-link>
							</li>
						</template>
					</ul>
					<ul class="navbar-nav">
						<li class="nav-item nav-item-c49a6c">
							<a :href="$store.state.khUrl">{{$t('CFooters.zhuce')}}
							</a>|
							<a :href="$store.state.loginUrl">{{$t('CFooters.login')}}
							</a>
						</li>
					</ul>
					<language mode="home" :bgColor="backgroundColor" :textStyle="{fontSize: '16px',color: '#fff'}" />
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				backgroundColor: 'rgba(61, 1, 1, .2)',
				rollState: true,
				headMenu: false,
				menuIndex: -1,
				threeIndex: -1,
				isMobil: '',
				languageList: [{
						text: '简体中文',
						lang: 'zh_CN',
						id: 'cn'
					},
					{
						text: 'English',
						lang: 'en_US',
						id: 'en'
					},
					{
						text: '繁體中文',
						lang: 'zh_TW',
						id: 'tw'
					},
					{
						text: '한 국 어',
						lang: 'ko_KR',
						id: 'ko'
					},
					{
						text: '日本語',
						lang: 'ja_JP',
						id: 'jp'
					},
					{
						text: 'ภาษาไทย',
						lang: 'th_TH',
						id: 'th'
					},
					{
						text: 'ئۇيغۇر تىلى',
						lang: 'weiwuer',
						id: 'ug'
					}
				],
				showMenu: false
			}
		},
		computed: {
			// th: {
			//   text: 'ภาษาไทย',
			//   lang: 'th_TH'
			// },
		},
		mounted() {
			this.isMobil = this._isMobile()
			window.addEventListener('scroll', this.handleScroll, true)
		},
		created() {

		},
		methods: {
			showHeadMenu() {
				this.headMenu = !this.headMenu
			},
			handleChangeLamguage(language) {
				console.log(language);
				if (language != 'zh' && this.$route.path == '/tools') {
					this.$router.push({
						name: 'Home',
					})
				}
				this.$i18n.locale = language
				localStorage.setItem('language', language)
				this.reload();
			},
			showLangMenu() {
				this.showMenu = !this.showMenu
			},
			handleScroll() {
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
				this.backgroundColor = `rgba(61, 1, 1, ${ scrollTop / 4 / 120 + 0.2})`
				if (scrollTop > 200) {
					this.rollState = false
				} else {
					this.rollState = true
				}
			},
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			dropdownshow(idx) {
				if (this.menuIndex === -1) {
					this.menuIndex = idx
				} else {
					this.menuIndex = -1
				}
			},
			dropdownhied() {
				this.menuIndex = -1
			},
			dropdownshow1(idx) {
				if (this.threeIndex == -1) {
					this.threeIndex = idx
				} else {
					this.threeIndex = -1
				}
			},
			dropdownhied1() {
				this.threeIndex = -1
			}
		}
	}
</script>

<style lang="scss">
	.home-header {
		.headState {
			display: block !important;
		}

		.navbar-nav {
			.nav-item {
				&:last-child {
					display: none;
				}

				&:nth-child(1) {
					display: block;
				}
			}
		}

		.head-lang {
			position: relative;

			&__title {
				color: #fff;
				font-size: 16px;
				cursor: pointer;
				line-height: 1.75;
				letter-spacing: .5px;
				padding: 6px 20px;
				display: flex;
				align-items: center;

				img {
					width: 20px;
					height: 20px;
					margin-right: 4px;
				}

				&::after {
					display: inline-block;
					margin-left: 0.255em;
					vertical-align: 0.255em;
					content: "";
					border-top: 0.3em solid;
					border-right: 0.3em solid transparent;
					border-bottom: 0;
					border-left: 0.3em solid transparent;
				}
			}

			&__menu {
				position: absolute;
				top: 120%;
				right: 0;
				left: 0;
				border-top: 4px solid #6c100f;
				min-width: 100px;

				&.showClass {
					position: relative;
				}

				&__item {
					text-align: center;
					display: inline-block;
					padding: 15px;
					cursor: pointer;
					width: 100%;
					font-weight: 400;
					font-size: 1rem;
					color: #fff;

					&:hover {
						background-color: #fff;
						color: #6c100f;
					}
				}
			}
		}

		.navbar {
			background-color: rgba(61, 1, 1, 0);
			flex-direction: column;
			padding: 0;
		}

		&-clearfix {
			height: 120px;
		}

		&-bd {
			padding: 0;
			width: 100%;

			.navbar-brand {
				padding-left: 55px;
				padding-top: 20px;
				padding-bottom: 20px;
			}

			.nav-item {
				font-size: 16px;

				.active {
					background-color: #c09b6e;
					margin-right: 10px;
					border-radius: 5px;
					padding: 2px 15px !important;
					color: #3a0502;
					margin-top: 4px;
				}

				.dropdown-menu {
					min-width: 140px;

					li {
						position: relative;
					}

					.dropdown-menu {
						position: absolute;
						left: 100%;
						top: 0;
					}
				}

				&-c49a6c {
					color: #c49a6c;
					line-height: 1.45;

					a {
						padding: 0 10px;
						color: inherit;
					}
				}
			}

			.dropdown-roll {
				.nav-item {
					.dropdown-menu {
						background-color: rgba(#f7f7f7, 0.04);
						color: #fff;
						border-top: 4px solid #6c100f;

						.dropdown-item {
							color: #fff;

							&:hover {
								background-color: rgba(#eee, 0.1);
								color: #c49a6c;
							}
						}
					}
				}

			}

			.nav-link {
				padding: 6px 20px !important;
				font-size: 16px;
				line-height: 1.75;
				letter-spacing: 0.5px;
				color: #fff;
			}

			.color-h {
				color: #212529;
			}

			.three-link::after {
				transform: rotate(-90deg);
			}
		}
	}

	@media screen and (max-width: 1024px) and (min-width:769px) {
		.home-header-bd {
			.nav-item {
				.nav-link {
					padding: 6px 8px !important;
					white-space: nowrap;

					&.en-box {
						padding: 6px 0px !important;
					}

					&.th-box {
						padding: 6px 0px !important;
					}

					&.vi-box {
						padding: 6px 0px !important;
					}

					&.ja-box {
						padding: 6px 0px !important;
					}
				}
			}

			.navbar-brand {
				padding-left: 15px !important;
				margin-right: 10px;

				&.en-box {
					padding-left: 6px !important;
				}

				&.th-box {
					padding-left: 6px !important;
				}

				&.vi-box {
					padding-left: 6px !important;
				}

				&.ja-box {
					margin-right: 0px;
				}
			}
		}
	}

	@media (max-height: 375px) {
		.justify-content-around {
			height: 200px;
			overflow-y: auto;
		}
	}

	@media (max-width: 767px) {
		.home-header {
			&-clearfix {
				height: 66px;
			}

			&-bd {
				// background-color: #fff;
				border-bottom: 1px solid #7d1614;

				.nav-link {
					// color: #333;
				}

				.nav-item {
					.active {
						background-color: initial;
						margin-right: 0px;
						border-radius: 0;
						padding: 6px 20px !important;
						color: #fff;
						margin-top: 0px;
					}

					&-c49a6c {
						padding-left: 8px;
					}
				}
			}

			.justify-content-around {
				max-height: 500px;
				overflow-y: auto;
			}

			.dropdown-menu {
				position: relative !important;
				left: 0 !important;
			}

			.navbar-brand {
				padding-left: 20px;
				padding-top: .3125rem;
				padding-bottom: .3125rem;
			}

			.navbar-toggler {
				&:focus {
					box-shadow: none;
				}

				.icon {
					width: 30px;
					height: 30px;
					background-color: #000;
				}
			}

			.color-h {
				color: #212529;
			}

			.fixed-menu {
				position: fixed;
				bottom: 150px;
				right: 0;
				transform: scale(.75);
				transform-origin: 100% 50%;
				z-index: 100;
			}
		}
	}
</style>