export default {
    "h1-title": "Trading Tools",
    "imgUrlLang": "-en",
    "public-btn-text": "Instant experience",
    screen0: {
        "title": "Create a library of tools to meet your trading needs",
    },
    screen1: {
        "title": "Overlay Chart Indicator",
        "desc": "This tool enables investors to gain a clear understanding of the relative strength of candlesticks within any given period for two commodities. Additionally, it can identify the effectiveness of breakthroughs.",
    },
    screen2: {
        "title": "MACD professional indicator (double line)",
        "desc": "This MACD indicator tool is widely used for stocks and futures. It offers several advantages over the MACD included with MT4, making it better suited to traders' preferences and habits.",
    },
    screen3: {
        "title": "MTDriver profit and loss statistical indicators",
        "desc": "This tool can help investors pinpoint profit and loss for different periods",
    },
    screen4: {
        "title": "Price Change Mobile Push Notification",
        "desc": "Most investors have limited time to watch the market, and mobile phone notification of price changes can help them grasp market trends while avoiding some market noises",
    },
    screen5: {
        "title": "One-click closing EA",
        "desc": "The operation interface is located in the chart interface, which is convenient for investors to stop losses more quickly and conveniently when extreme market conditions occur",
    },
    screen6: {
        "title": "Timing order EA",
        "desc": "Put it in the \MQL4\Experts folder, an EA that places orders at a specified time, the usage is very simple, users please explore by themselves",
    },
    screen7: {
        "title": "Equity Monitor EA",
        "desc": "Put it in the \MQL4\Experts folder, monitor the EA of the net value change, put the sound file you want to prompt under \Sounds, and then fill in the name of the corresponding music file in the music name of the EA",
    }
}