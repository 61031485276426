export default {
	"esg-class-name": "en-esg",
	"esg-banner": {
		"btn": "더 알아보기",
		"text1": "EBC Financial Group은 유엔 기금과 협력하여 말라리아 퇴치 운동을 지원합니다.<br>말라리아를 물리친 세대가 되어, 공평하고 새로운 시대를 이루고자 합니다.",
		"text2": "EBC는 전 세계 아이들의 발전을 지원합니다.",
		"text3": "EBC와 옥스퍼드 대학의 공동 세미나경제학자들이 하는 일",
		"text3-2": "",
		"text4": "EBC는 세계 걸가이드·걸스카우트협회와 협력합니다.<br>여성의 발전을 지원하고, 미래 여성 리더를 창출합니다.",
		"text5": "Together , Let's Build The Future",
		"text6": "EBC는 지속적으로 아동 자선 및 전문 교육, 인재 양성에 헌신하는 동시에 지속 가능한 발전의 책임을 맡고 있습니다."
	},
	"esg-nav": {
		"text1": "우리의 비전",
		"text2": "우리의 조치",
		"text3": "우리의 프로젝트",
		"text4": "저희는 몸소 실천 중입니다",
	},
	"esg-page1": {
		"text1": "우리의 비전",
		"text2": "교육이 미래를 이끌어 갑니다",
		"text3": "어린이 교육에 집중하여 교육 환경을 개선하고, 고등교육기관과 협력하여 개방형 교류 공간을 구축합니다.",
		"text4": "공정한 사회를 함께 만들어 갑니다",
		"text5": "자선 단체를 지원하여 더 많은 사람들에게 진료 받을 권리를 제공하고, 사회 평등을 촉진하기 위해 노력합니다.",
		"text6": "직원들이 스스로 자부심을 느낄 수 있도록 노력합니다.",
		"text7": "우리는 직원들이 자부심과 가치를 느낄 수 있는 회사를 만들며, 개방적이고 포용적인 세계를 구축하기 위해 노력합니다.",
		"text8": "녹색 가정을 실천합니다",
		"text9": "우리는 지속 가능한 발전을 회사의 장기 계획으로 설정하여, 자원 절약을 촉진하고 이상적인 가정을 건설합니다.",
	},
	"esg-page2": {
		"text1": "우리의 조치",
		"text2": "환경",
		"text3": "<span>지속 가능한 자원 활용:</span><br>지속 가능한 포장 재료를 사용하여 폐기물 배출을 줄이고 순환 경제를 촉진합니다.",
		"text4": "<span>탄소 발자국 감소 정책:</span><br>세계적인 기후 변화 과제에 참여하여 재생 가능 에너지를 사용하는 기업들과 협력합니다.",
		"text5": "사회",
		"text6": "<span>자선 단체와 연계: </span><br>자선 기부 또는 자원 봉사와 같은 방법으로, 평등하고 포용적인 사회를 구축하는 데 기여합니다.",
		"text7": "<span>공동 목표를 가진 파트너와 협력합니다:</span><br>파트너가 환경 보호와 사회적 책임을 준수하도록 이끌어 사회 발전을 촉진합니다.",
		"text8": "기업지배구조",
		"text9": "<span>다양성과 포용성:</span><br>다양성과 포용성을 강조하는 직장 환경을 제공하고, 차별에 반대하며 기회 평등을 실현합니다.",
		"text10": "<span>직원 관리:</span><br>다양한 직원 복지 프로그램과 직무 로드맵을 통해 직원 교육 및 기술 향상을 지원합니다.",
	},
	"esg-page3": {
		"text1": "우리의 프로젝트",
		"text2": "교육 기금",
		"text3": "EBC는 교육이 사회 발전의 기반임을 믿습니다.",
		"text4": "자선 단체 및 각 대학과 협력하여 교육 혁신을 촉진합니다. 특별 기금을 마련하여 자원 부족 지역의 아동 및 공공 교육을 지원합니다.",
		"text5": "의료 기금",
		"text6": "작은 선의로 아름다운 변화를 이루어 낼 수 있습니다.",
		"text7": "의료 기금을 마련하여 요양원에 지원함으로써 독거노인에게 위로의 손길을 전달하고, 병든 아이들에게 희망의 빛을 밝혀줍니다.",
		"text8": "사랑의 기금",
		"text9": "개인 가정이 회사의 '가족'을 지원합니다.",
		"text10": "우리는 가정 문화를 존중하며, 직원들의 부모를 돌보고 형제 자매를 사랑하기 위한 직원 관리 기금을 마련했습니다.",
		"text11": "기부 자금",
		"text12": "재난 이후, 모든 사람은 새로운 시작이 필요해졌습니다.",
		"text13": "팬데믹 시, EBC는 내부 자금을 활용하여 전염병 발병지역의 사람들과 가정에 지원을 제공했습니다.",
	},
	"esg-page4": {
		"text1": "저희는 몸소 실천 중입니다",
	}
}