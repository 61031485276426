export default {
	"box-class-name": "en-no1-box",
	"lang": "en",
	"button-text": "Begin Challenge",
	"no1-page2": {
		"text1": "Up to 1,000 order executions per second",
		"text2": "Fast order fulfillments of<br> 20ms",
		"text3": "Stable data transmission of up to 98.75%",
		"text4": "Investment-level RAW ECN spreads of 0.0 pips",
	},
	"no1-page3": {
		"text1": "Regulated by FCA in the UK",
		"text2": "EBC Financial Group (UK) Ltd is Authorised and regulated by the Financial Conduct Authority (FCA) in the United Kingdom<br><span>Reference Number: 927552</span>",
		"text3": "Regulated by ASIC in Australia",
		"text4": "EBC Financial Group (Australia) Pty Ltd is Authorised and regulated by the Australian Securities and Investments Commission (ASIC).<br><span>Reference Number: 500991</span>",
	},
	"no1-page4": {
		"text1": "The most robust trading environment<br>Beyond the confines of EBC lies not a limit, but an invitation to challenge the standard<br>If the challenge is a success<br>EBC promises a 150% reward on return gap, with no cap<br>Simply start the challenge<br>and let EBC's prowess take care of the rest",
		"text2": "Prizes: 150% of the return gap with no cap",
	},
	"no1-page5": {
		"text1": "Facilitating more than <span>85<i>%</i></span><br> of orders executed at better prices",
		"text2": "EBC Blackbox excels with precisely optimized algorithms, surpassing traditional trading systems in price optimization, order fill rate, and algorithmic stability.",
	},
	"no1-page6": {
		"text1": "Rules of Challenge",
		"text2": "Upholding the highest standards with integrity and respect",
		"text3": "Minimum desposit requirement",
		"text4": "Choice of trading platform",
		"text5": "Challenge condition",
		"text6": "Requirement on successful challenge",
		"text7": "No reqiurement on minimum deposit",
		"text8": "Regulated by FCA in the UK",
		"text9": "Regulated by ASIC in Australia",
		"text10": "The chosen trading platform should be regulated by either FCA in the United Kingdom or ASIC in Australia",
		"text11": "The same volume of trading and strategy should be applied in both EBC and the chosen trading platform",
		"text12": "If the yield from EBC is lower than the challenge platform, challenger will be compensated with 150% of margin",
		"text13": "All challengers are entitled to a free EBC VIP account.EBC offers cross-platform copy trading app for free.",
	},
	"no1-page7": {
		"text1": "Notes",
		"text2": "1. Trading account for challenge<br>a) New user: To initiate a new challenge, please open a new account in EBC's official website, then contact our customer service to upgrade it to VIP account <br>b) Existing user: To initiate a new challenge, please contact our customer service to upgrade your account to VIP account.",
		"text3": "2. The chosen platform should be regulated by FCA in the United Kingdom or ASIC in Australia. Trading platform regulated by AR &EEA, and Trading Name accounts are not accepted for challenge.",
		"text4": "3. Only the following currency pairs are accepted: AUD/USD, USD/CAD, EUR/USD, USD/CHF, GBP/USD, USD/JPY, NZD/USD",
		"text5": "4. The deployed trading strategy and the amount of trading funds in EBC and the chosen trading platform should be within standard parameters",
		"text6": "5. Challenger can choose to settle at anytime within the event period. Each user with a single ID card can initiate one challenge only.",
		"text7": "6. Please submit your settlement statement after the challenge. Your bonus will be transferred to your trading account within two working days after the clearance of approval process.",
		"text8": "7. You will be required to supply relevant supporting documents if there are suspicions of rebate payments from your trading account.",
	},
	"no1-page8": {
		"text1": "The campaign will expire in ...",
		"text2": "Start: 00:00 GMT+2 18 Jan. End: 23:59 GMT+2 1 March.",
		"text2-style": "font-size:12px",
	},
	"button1-style": "",
	"button2-style": "left: 63px;width: 183px;",
	"button3-style": "right: 87px;",
	"button4-style": "right: -82px;",
}