export default {
	"leftNav": [{ "id": "#Trading", "name": "ซื้อขาย" },
		{ "id": "#Regulation", "name": "การกำกับดูแล" },
		{ "id": "#Security", "name": "ความปลอดภัย" },
		{ "id": "#Fintech", "name": "เทคโนโลยีทางการเงิน" },
		{ "id": "#Black-Box", "name": "กล่องดำธุรกรรม" },
		{ "id": "#Private-Room", "name": "พื้นที่ซื้อขาย" },
		{ "id": "#Liquidity", "name": "สภาพคล่อง" },
		{ "id": "#Honor", "name": "ให้เกียรติ" },
		//{ "id": "#News", "name": "ข่าว" }
	],
	"pages1": {
		"h1":"ความไว้วางใจและการนับถือ",
		"title": "เทรดเดอร์ที่จริงจัง ต้องได้รับบริการที่จริงใจจากเรา",
		"btn": " ดูวิดีโอฉบับเต็ม "
	},
	"pages2": {
		"title": "สภาพคล่องระดับมืออาชีพที่ส่งผลต่อโลกการเทรดของคุณ",
		"title1": "สัมผัสประสบการณ์ใหม่ ในโลกแห่งโอกาสและความได้เปรียบในการเทรด",
		"btn": " ซื้อขายสินค้า ",
		"text": " ฟอเร็กซ์ ",
		"text1": " สินค้าโภคภัณฑ์ ",
		"text2": " ดัชนี CFD ",
		"text3": " หุ้น CFD "
	},
	"pages3": {
		"title": "กฎระเบียบระดับสากล",
		"title1": "บริษัทในเครือของ EBC Financial Group ได้รับการควบคุมและอนุญาตในเขตอำนาจศาลท้องถิ่นของตน",
		"btn": "เข้าใจกฎระเบียบ",
		"text": "FCA ในสหราชอาณาจักร",
		"text1": "EBC Financial Group (UK) Ltd ได้รับอนุญาตและควบคุมโดยหน่วยงานทางการเงิน. หมายเลขอ้างอิง: 927552",
		"text2": "ASIC ในออสเตรเลีย",
		"text3": "บริษัท EBC Financial Group (Australia) Pty Ltd ถูกอนุญาตและควบคุมโดยสำนักงานคณะกรรมการกำกับหลักทรัพย์และการลงทุน ASIC. หมายเลขอ้างอิง:500991",
		"text4": "CIMA (หมู่เกาะเคย์แมน)",
		"text5": "EBC FINANCIAL GROUP (CAYMAN) LTD ได้รับอนุญาตและควบคุมโดยหน่วยงานด้านการเงินของหมู่เกาะเคย์แมน (CIMA). หมายเลขควบคุม: 2038223"
	},
	"pages4": {
		"title": "พลังขับเคลื่อน สู่ความสำเร็จ",
		"title1": "ความเป็นมืออาชีพและการใช้เทคโนโลยีชั้นนำทำให้เกิดความเป็นไปได้ของการเทรดที่ไม่มีที่สิ้นสุด",
		"btn": " สำรวจเทคโนโลยี",
		"text": " การประมวลผล 1,000 คำสั่งต่อวินาท ",
		"text1": " การดำเนินการตามคำสั่งที่รุนแรง ",
		"text2": " ของเวลาทำงาน ",
		"text3": " ค่าสเปรด 0 pip ของ ECN อยู่ในระดับเดียวกันกับธนาคารระดับโลก",
	},
	"pages5": {
		"title": "ออเดอร์คำสั่งซื้อขายจะได้รับราคาที่ดีกว่าถึง 85%",
		"title1": "ที่บันทึกข้อมูลการเทรดใน EBC ที่เหมาะสมและแม่นยำ",
		"btn": " กล่องดำธุรกรรม"
	},
	"pages6": {
		"title": "ห้องส่วนตัวของ EBC",
		"title1": "ห้องค้าส่วนตัว (Private Room) จะปกปิดข้อมูลการซื้อขายบางส่วนเพื่อให้นักเทรดได้ราคาซื้อขายที่ได้เปรียบขึ้นและปกป้องจากการโดนผู้อื่นทำการเทรดตรงข้ามเรา",
		"btn": " พื้นที่ซื้อขาย"
	},
	"pages7": {
		"title": "สภาพคล่อง",
		"title1": "EBC ได้สร้างความสัมพันธ์ความร่วมมือระยะยาวและมั่นคงกับธนาคารระหว่างประเทศชั้นนำของโลก ช่วยให้คุณทำธุรกรรมได้อย่างง่ายดายด้วยต้นทุนที่ต่ำมาก",
		"btn": "สภาพคล่อง EBC"
	},
	"pages8": {
		"btn": "ข่าวเพิ่มเติม"
	},
	"pages9": {
		"title": "คุ้มครองอย่างครอบคลุม เพื่อความสบายใจ",
		"title1": "EBC ยึดถือความปลอดภัยของกองทุนเป็นอันดับแรก เงินทุนของลูกค้าจะถูกจัดเก็บในบัญชีของ Barclays Bank ที่อดำเนินการร่วมกับ FCA, Financial Commission, Lloyds of London และ AON เพื่อสร้างระบบการรักษาความปลอดภัยที่ครอบคลุมรอบด้าน",
		"btn": "ความปลอดภัย"
	},
	"lang": "en"
}

