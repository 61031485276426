export default {
  lang:'ko',
  指数差价合约: "지수차익계약",
  无需持有基础资产: "기초자산 소유 없이 유연하게 거래 - 글로벌 기회 포착",
  掌控全球机遇: "",
  即刻开户: "바로 계좌 개설하기",
  美股指数差价合约: "미국 주가지수 CFD",
  点差大幅度降低: "스프레드 감소, 유동성 증가, 글로벌 시장 전반의 저가 거래의 이점을 누릴 수 있습니다.",
  美国道琼斯工业平均指数: "US Dow Jones Industrial Average Index Spot CFD",
  美国标准普尔500指数: "US S&P 500 Index Spot CFD",
  美国纳斯达克100指数: "US NASDAQ-100 Index Spot CFD",
  香港恒生指数: "Hong Kong Hang Sheng Index Spot CFD",
  中国A50指数:"China A50 Index Spot CFD",
  降幅: "-Reduction Percentage (%)",
  亚洲时间: "Asian Session",
  欧洲时间: "European Session",
  美国时间: "US Session",
  品种: "Symbol",
  指数描述: "Description",
  指数点差: "Spreads in Index Points",
  开设模拟账户: "데모 계좌 개설하기",
  开设真实账户: "실제 계정 개설하기",
  产品优势: "이점",
  无需持有基础资产2:
    "상승장과 하락장 모두에서 기회를 포착할 수 있는 저렴하고 유연한 레버리지를 즐기세요.",
  灵活高杠杆: "유연한 레버리지",
  杠杆高达: "최대 100:1까지 활용하여 각 거래의 수익을 증폭하는 동시에 위험을 효과적으로 관리할 수 있습니다.",
  放大每笔交易收益: "",
  实时弹性定价: "실시간 동적 가격 책정",
  根据全球市场波动实时调整: "당사의 가격은 글로벌 시장 변동에 따라 즉시 조정되므로 항상 최상의 거래 조건을 확보할 수 있습니다.",
  确保您每笔交易享受最优交易条件: "",
  极致的交易速度: "익스트림 패스트 트레이드",
  平均执行速度20毫秒: "평균 실행 속도가 20밀리초이므로 빠르게 행동하여 시장에서 앞서 나갈 수 있습니다.",
  助您抢占市场先机: "",
  完全透明的交易: "거래의 완전한 투명성",
  无隐藏费用或额外成本: "숨겨진 수수료나 추가 비용이 없어 모든 거래가 투명하고 공정하게 이루어질 수 있습니다.",
  确保您每笔交易的透明和公平: "",
  全球指数交易深度: "글로벌 시장 깊이",
  查看所有产品: "모든 제품 보기",
  指数差价合约相关问题: "지수차익계약 관련 문제",
  list: [
    {
      title: "1. 지수 CFD란 무엇인가요?",
      content: `지수 CFD는 일반적으로 주가 지수 CFD를 의미하며, 주가
                        지수의 가격 변동을 반영하고 해당 가격 변동으로 인한
                        이익이나 손실을 제공합니다. <br /><br />

                        일반적인 주식 투자와 비교해서, 주가 지수는 대표적으로
                        주식 동향을 반영하며 특정 국가의 산업과 전체 경제의 척도
                        역할을 합니다. 예를 들어 독일 DAX 30 지수는 해당 국가의
                        30개 대표적인 블루칩 주식으로, 독일 주식 시장 전반의
                        상황을 반영합니다. <br /><br />

                        지수 CFD를 통해 개별 주식의 복잡한 분석을 전체 시장 심리
                        분석으로 투자 위험을 줄이고, 개별 주식의 극단적인 가격
                        변동을 피함으로써 전반적으로 안전성이 높아지게 됩니다.`,
    },
    {
      title:"2. 지수 CFD로 어떤 지수를 거래할 수 있나요?",
      content:`지수 CFD는 전세계의 다양한 주가 지수를 거래할 수
                        있습니다. <br /><br />

                        EBC는 다우존스 지수, DAX 30 지수, STOXX 50 지수, S&P 500
                        지수, 니케이 225 지수 등과 같은 주요 지수를 제공합니다.
                        EBC 계정 하나만으로, 전 세계 다양한 시장에서 선택할 수
                        있으며, EBC의 경쟁력 있는 스프레드와 유연한 레버리지
                        비율을 통해 전 세계의 활발한 시장 속에서 성공할 수
                        있습니다.`
    },
    {
      title:"3. 지수 CFD 거래의 레버리지는 어떻게 되나요?",
      content: `EBC는 지수 CFD 거래에 최대 100배의 레버리지를 제공할 수
                        있습니다. 이는 합법적이고 규제된 범위 내에서 허용되는
                        최대 레버리지 비율로, 안전성과 자금 활용률을 균형 있게
                        고려한 것입니다.`
    },
    {
      title:"4. 지수 CFD 계약 양은 어떻게 결정되나요?",
      content:`지수마다 계약 수가 다릅니다. <br /><br />

                        EBC는 글로벌 주요 지수 CFD를 제공하며, 예를 들어
                        100GBP(영국 FTSE 100), 200AUD(오스트레일리아 S&P/ASX
                        200), CNIUSD(중국 A50), D30EUR(독일 DAX 30), E50EUR(유럽
                        STOXX 50), F40EUR(프랑스 CAC 40), HSIUSD(홍콩 50),
                        NASUSD(미국 나스닥 100), U30USD(미국 다우 존스 30)의
                        경우 1계약 당 크기는 10입니다. <br /><br />

                        또한 225JPY(니케이 225), SPXUSD(미국 S&P 500)의 경우
                        1계약 당 크기는 100입니다. <br /><br />

                        최소 거래량은 모든 지수 CFD에서 0.1입니다.`
    },
    {
      title:"5. 지수 CFD 거래의 장점은 어떤것들이 있나요?",
      content: `자금 활용률 향상: 지수 거래의 레버리지는 일반적으로
                        낮지만, 지수 CFD를 통해 더 적은 자금으로 거래에 참여할
                        수 있습니다. 그러나 레버리지 상품이므로 리스크가 항상
                        함께 한다는걸 명심해야 하며, 레버리지 거래 시에는
                        포지션을 유지해야 합니다. <br /><br />

                        양방향 거래: 주식 지수는 주식 시장의 전반적인 변동
                        흐름을 반영합니다. 지수 CFD를 통해 양방향 거래를 할 수
                        있어 더 큰 수익의 기회를 얻을 수 있습니다. <br /><br />

                        리스크 분산: 주식 지수는 주로 대표적인 주식들로 구성되어
                        해당 시장에서 최상의 주식들의 집합입니다. 개별 주식의
                        동향이 지수에 직접적인 영향을 미치기 어려워서 시장
                        리스크를 효과적으로 분산시킬 수 있습니다.`
    },
    {
      title:"6. 지수 CFD 거래의 공부 방법은 무엇인가요?",
      content:`지수 CFD는 여러 주식의 추이를 반영하므로, 일부분이 아닌
                        전체 시장에 주목해야 합니다. <br /><br />

                        일반적으로 주식 지수는 중앙 은행의 통화 정책과 국가의
                        경제 데이터와 직접적인 관련이 있습니다. 예를 들면, 어떤
                        국가의 중앙 은행이 긴축적인 통화 정책을 시행하면 시장은
                        자금 부족 우려로 주가를 압박할 수 있습니다. 마찬가지로
                        어떤 국가의 경제 데이터가 좋지 않으면, 투자 기대가
                        낮아져 주가를 압박할 수 있습니다.`
    }
  ],
  每个认真交易的人:"성실하게 거래하는 사람은 모두 진지하게 대우받을 가치가 있습니다."
};
