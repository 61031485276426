export default {
  "srceen0": {
    "title": "เลเวอเรจและมาร์จิ้น",
    "subTitle": "เงื่อนไขสำคัญสองข้อที่กำหนดวิธีการซื้อ <span style='color:#800'>ขายของคุณ</span>",
    "desc": "เนื่องจากความไม่แน่นอนและความผันผวนสูงในตลาดการเงินระหว่างประเทศ EBC ให้ความสำคัญกับความปลอดภัยของทรัพย์สินของลูกค้าเป็นสำคัญ.เราเสนอตัวเลือกให้ลูกค้าทุกคนเลือกเลเวอเรจที่ต้องการตั้งแต่ 1:1 (ไม่มีเลเวอเรจ) จนถึงสูงสุด 1000:1.",
    "rateTitle": "อัตราส่วนเลเวอเรจสูงถึง",
    "rateNum": "1000:1",
    "rateDesc": "ระดับมาร์จิ้น 0.10%"
  },
  "srceen1": {
    "pageEnTitle": "การทำธุรกรรมที่ปลอดภัย",
    "pageTitle": "",
    "subTitle": "การเลือกอัตราส่วนเลเวอเรจที่เหมาะสมที่สุด",
    "desc1": "โปรดพิจารณาอย่างรอบคอบว่าอัตราเลเวอเรจใดเหมาะสมกับความต้องการของคุณ. ",
    "desc2": "โปรดพิจารณาอย่างรอบคอบว่าอัตราเลเวอเรจใดเหมาะสมกับความต้องการของคุณ.",
    "btn": "เริ่มการเทรด",
    "title":""
  }
}
