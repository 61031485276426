<template>
	<div class="box1">
		<div class="swiper-container" :style="`width: ${width}; height: ${height};`">
			<div class="swiper-wrapper">
				<div class="swiper-slide news-li" :title="item.tit" v-for="(item, index) in newData" :key="index" v-if="item.tips != '' && index < 10">
					<div class="thumbnail">
						<a href="#" @click="jumPage(item)" class="news-li__img">
							<img :src="item.logo" :alt="item.tit"></a>
						<div class="caption">
							<h3><a>{{item.tit}}</a></h3>
							<p>{{item.tips}}</p>
							<span>{{item.time}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 如果需要导航按钮 -->
		<div class="swiper-button-prev"><img src="../../assets/image/index/prev.png" alt=""></div>
		<!--左箭头。如果放置在swiper外面，需要自定义样式。-->
		<div class="swiper-button-next"><img src="../../assets/image/index/next.png" alt=""></div>
		<!--右箭头。如果放置在swiper外面，需要自定义样式。-->
	</div>
</template>
<script>
	import 'swiper/css/swiper.css'
	import Swiper from 'swiper'
	export default {
		name: 'BannerSlide',
		props: {
			newData: { // 图片数组
				type: Array,
				default: () => {
					return []
				}
			},
			width: { // 宽度
				type: String,
				default: '100%'
			},
			height: { // 高度
				type: String,
				default: '480px'
			}
		},
		data() {
			return {
				swiper: null,
				num: 3
			}
		},
		created() {
			if (this.isMobile()) {
				this.num = 1
			} else {
				this.num = 3
			}
		},
		mounted() {
			this.swiper = new Swiper('.swiper-container', {
				lazy: {
					loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
					loadPrevNextAmount: 2 // 默认为1，设置在延迟加载图片时，提前多少个slide
				},
				zoom: { // 开启缩放功能，双击slide会放大/缩小，并且在手机端可双指触摸缩放
					maxRatio: 3, // 默认3，设置缩放的最大放大比例，如果要在单个slide设置放大比例，可以在其上添加data-swiper-zoom="3"
					minRatio: 2, // 默认为1，最小缩放焦距（缩小倍率）
					toggle: true // 默认为true，是否允许双击缩放，为false时，不允许双击缩放，只允许手机端触摸缩放
				},
				navigation: { // 如果需要前进后退按钮
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				scrollbar: { // 如果需要滚动条
					el: '.swiper-scrollbar',
					hide: true // 滚动条是否自动隐藏，默认false
				},
				slidesPerView: this.num,
				mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
				direction: 'horizontal', // 滑动方向
				speed: 500, // 切换速度，自动滑动开始到结束的时间
				grabCursor: true, // 悬浮时鼠标样式切换
				centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
				effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
				cubeEffect: { // cube效果参数
					slideShadows: false, // 是否开启slide投影，默认true
					shadow: false, // 是否开启投影，默认true
					shadowOffset: 20, // 投影距离。默认 20，单位px。
					shadowScale: 1 // 投影缩放比例。默认0.94。
				},
				// autoplay: true, // 启动自动切换，等同于以下设置
				autoplay: {
					delay: 3000, // 多少秒切换一次，默认3000ms
					disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
					waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
				},
				loop: true // 循环模式选项
			})
			// 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换
			this.swiper.el.onmouseover = function() { // 鼠标覆盖停止自动切换
				this.swiper.autoplay.stop()
			}
			this.swiper.el.onmouseout = function() { // 鼠标离开开始自动切换
				this.swiper.autoplay.start()
			}
		},
		methods: {
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
			jumPage(idx) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: idx.id
					}
				})

			}
		}
	}
</script>
<style lang="scss" scoped>
	.box1{
		position: relative;
		.swiper-button-prev {
			left: -50px;
		}
		.swiper-button-next {
			right: -30px;
		}
	}
	.swiper-button-prev:after,
	.swiper-button-next:after {
		display: none;
	}

	

	.news-li {
		padding: 0;
		margin-bottom: 30px;
		background-color: initial;
		padding-right: 20px;
		width: 33%;
	}

	.news-li {
		&__img {
			max-height: 280px;
			display: inline-block;
			overflow: hidden;
		}

		.thumbnail {
			background-color: #fff;
			padding-bottom: 20px;
			border-radius: 5px;
			height: 100%;
		}
	}

	.news-li img {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		transform: none;
		object-position: left;
		width: 100%;
		height: auto;
	}

	.news-li h3 a {
		margin: 14px 16px 10px;
		font-size: 20px;
		font-weight: 600;
		line-height: 1.5;
		display: block;
		color: #333;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.news-li p {
		margin: 0 16px 10px;
		color: #666;
		font-size: 16px;
		line-height: 1.5;
		font-family: auto;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.news-li span {
		padding: 0 16px 10px;
		color: #666;
		font-size: 14px;
	}

	@media(max-width:750px) {
		.news-li {
			padding-right: 0;
		}
		.swiper-button-prev {
			display: none;
		}
		.swiper-button-next {
			display: none;
		}
	}
</style>