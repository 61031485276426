export default {
  "screen0": {
    "title": "Legal Document",
    "tit": "قانۇن ھۆججىتى ",
    "list": [
      "پوچتا ئالاقىسى ",
      "ئاپتوماتىك لىنىيەدىكى مۇلازىمەتچى خادىم"
    ]
  },
  "screen1": {
    "list": [{
      "title": "نىزام ۋە ماددىلار ",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Website-Terms-and-Conditions.pdf"
    }, {
      "title": "خەۋپ-خەتەر ئەسكەرتمىسى ئۇقتۇرۇشى ",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Risk-Acknowledgement-And-Disclosure-Notice.pdf"
    }, {
      "title": "شەخسىي ئۇچۇرلارنى ساقلاش ماددىسى",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Privacy-Policy.pdf"
    }, {
      "title": "ئىجرا قىلىش ماددىلىرى ",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Execution-Policy.pdf"
    }],
    "tit": "سىز نېمە ئىزدىمەكچى؟",
    "name": "پۈتۈن تور بېكەت بويىچە ئىزدىسىڭىز بولىدۇ",
    "placeholder": "پۈتۈن تور بېكەت بويىچە ئىزدەش",
    "btn": "ئىزدەش",
    "text": "بىزنىڭ مەخسۇس مۇلازىمەت خادىمىدىن مەسلىھەت سوراش",
    "btn1": "مەخسۇس مۇلازىمەت خادىمى بىلەن ئالاقىلىشىش مۇلازىمىتى،"
  }
}
