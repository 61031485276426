export default {
	"box-name": "partner2-page-cn",
	"section1": {
		"text1": "影响力联盟",
		"text2": "一个汇聚内容创作者、市场推广专家和经纪人的创新生态，让优秀交易者们携手并进，展拓全球机遇，解锁无限可能。",
		"text3": "业务遍布20多个国家",
		"text4": "汇聚多位行业顶尖专家",
		"text5": "多元化的合作支持方案",
		"text6": "全方位的资源支持体系",
	},
	"section2": {
		"text1": "合作方案",
		"text2": "介绍经纪人（IB）",
		"text3": "行业教育家、专业交易员、交易爱好者和公司",
		"text4": "<li>业务发展规划</li><li>品牌联动曝光机会</li><li>交易资源&营销活动支持</li>",
		"text5": "成为介绍经纪人",
		"text6": "CPA营销伙伴",
		"text7": "网站/博客所有者、媒体KOL、互联网营销者",
		"text8": "<li>组合奖励模式</li><li>定制化合作方案</li><li>多媒体营销素材支持</li>",
		"text9": "成为营销伙伴",
		"text10": "策略支持者",
		"text11": "专业分析师、交易爱好者、策略提供者",
		"text12": "<li>定制专属实物礼品</li><li>线上线下联名活动</li><li>交易资源&营销活动支持</li>",
		"text13": "成为策略支持者",
	},
	"section3": {
		"text1": "资源支持",
		"text2": "品牌曝光机会",
		"text3": "提供平台展示专业知识，助力联盟伙伴的品牌获得广泛认可。",
		"text4": "扩展行业人脉",
		"text5": "与行业顶尖专家建立联系，交流经验，共探行业趋势。",
		"text6": "营销资源支持",
		"text7": "提供营销素材和活动支持，为联盟伙伴定制推广策略。",
		"text8": "高级分析师培训",
		"text9": "专业分析师培训，提升技能和专业知识。",
		"text10": "订单流工具",
		"text11": "识别主力资金、提高交易胜率，专业交易者必备工具。",
		"text12": "知识影响力扩展",
		"text13": "协助知识型创作者内容整合，扩大影响力，提升专业形象。",
	},
	"section4": {
		"text1": "联盟成员",
		"text2": "乔尔.帝纳波利",
		"text3": "<li>斐波那契技术传奇教父</li><li>帝纳波利点位法创始人</li><li>黄金比率应用头号权威</li>",
		"text4": "Joe DiNapoli拥有55年市场经验，曾预测1987年道指大跌和2002年市场底部等周期性拐点，在全球享有盛誉。作为一名注册商品交易顾问（CTA）超过25年，撰写了《帝纳波利点位交易法（DiNapoli Levels）》，该书已被翻译成13种语言，风靡全球。",
		"text5": "了解更多",
		"text6": "<li>Lawrence拥有商业建筑行业背景，之后转型进入金融市场，以追求一个更有成就感的职业发展。在发现DiNapoli Levels后，他的交易方式发生了转变。自从17年前于泰国曼谷参加帝纳波利研讨会以来，Lawrence多次准确预测市场走势，包括应对新冠疫情引发的市场崩盘。目前，作为一名帝纳波利交易法专家，他指导新交易者规避陷阱，加快他们的学习进程。</li>",
		"text7": "<li>Pieter对于交易浓厚的兴趣，始于高中时期。作为一名荣获优等生荣誉的经济学家，他在一家南非本土公司开启了全职交易的生涯，并广泛涉足于各类市场。自2003年起，他开始深入学习帝纳波利理论，并于2009年开始在全球范围内传授帝纳波利点位法。目前，他管理自己的账户，担任南非一家对冲基金的成员，并为南非的商品公司提供咨询服务。Pieter还是《D-Levels金融预测》新闻资讯的作者，他将自己的大部分成就归功于乔尔·帝纳波利的悉心指导。</li>",
		"text8": "<li>Monchai Kongthanapakdi是泰国技术分析师协会的创始人，也是CMT协会泰国分会的联合主席，并担任Efuturesthai交易学院院长，该学院旨在为泰国投资者提供技术分析和培训。Monchai是一名全职交易员、技术分析师和投资者，擅长斐波那契分析（DiNapoli Levels）和算法交易。自2011年起，Monchai与帝纳波利合作，在泰国举办了多场公开和私人研讨会，并参与了2022年《帝纳波利点位交易法》（泰文版）的翻译工作。</li>",
		"text9": "<li>黄金率交易大师，美国布莱特期货基金合伙人，美国黄金率投资咨询有限公司总经理，美国纽约金融学院北京分院和芝加哥期货交易所特邀讲师，期货日报全国实盘大赛金牌导师，中文版«帝纳波利点位交易法»一书译者。作为全球帝纳波利点位交易法资深专家，自2008年以来成功预测上证指数几乎所有的转折点，以及美国道琼斯指数、美元指数、美国原油和黄金期货的多个阶段性顶部和底部。</li>",
	},
	"section5": {
		"text1": "更多分享",
		"text2": "2024年7月12日，EBC集团与交易大师帝纳波利在曼谷举行全球签约仪式，探讨交易策略，进一步提升双方在全球市场的影响力。",
		"text3": "分享交易策略",
		"text4": "帝纳波利及其团队分享了先进交易策略，吸引全球媒体和投资者关注。",
		"text5": "现场赠发签名书",
		"text6": "活动现场赠发帝纳波利限量版亲笔签名书，提升个人品牌知名度。",
		"text7": "提升双方影响力",
		"text8": "此次合作推动交易专业化发展，为双方带来行业认可和影响力提升。",
	},
}