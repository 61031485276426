export default {
	"screen0": {
		"title": "Giải pháp PANM đơn <br>giản hoá sự phức tạp",
		"content": "EBC đã tùy chỉnh mô hình quản lý phân bổ tỷ lệ phần trăm mới PAMM (Percentage Allocation Management Module) cho các nhà đầu tư và nhà quản lý quỹ đang tìm kiếm các chuyên gia để quản lý tài khoản. Nhà đầu tư và nhà quản lý quỹ có thể đăng nhập vào giao diện nền của phiên bản web để thực hiện giao dịch quản lý quỹ và lợi nhuận phân phối, xây dựng chi tiết, tải xuống bản ghi và các chức năng toàn diện khác; giao diện phiên bản web ngắn gọn và rõ ràng, với các chức năng toàn diện và tổ chức rõ ràng.",
		"tips": "EBC为资金管理者，投资者提供了权责清晰，功能全面的百分比分配管理模式（PAMM）；网页版后台简洁清晰，化繁为简。"
	},
	"screen1": {
		"pageEnTitle": "CLEAR DUTIES",
		"pageTitle": "Trách nhiệm rõ ràng",
		"title": "PAMM账户能够满足多方面参与者要求",
		"c1Title": "Nhà đầu tư",
		"c1Desc": "Tận hưởng dịch vụ giao dịch một cửa và chia sẻ kết quả giao dịch của các nhà quản lý quỹ. Các nhà đầu tư có thể tự do lựa chọn các nhà quản lý quỹ đáp ứng sở thích rủi ro và mục tiêu lợi nhuận của riêng họ. Điều khoản water mark cao tạo động lực cho các nhà quản lý quỹ và bảo vệ quyền lợi của chính họ.",
		"c2Title": "Người quản lý quỹ",
		"c2Desc": "Nhà giao dịch có kinh nghiệm giao dịch phong phú có thể đóng vai trò là người quản lý quỹ tài khoản PAMM, các người quản lý quỹ có thể quản lý nhiều tài khoản giao dịch cùng một lúc và sử dụng quỹ của bản thân và quỹ chung của nhà đầu tư để thực hiện các giao dịch nhằm thu lợi nhuận. Một phần thu nhập hoặc chi phí giao dịch được sử dụng làm thù lao lao động của người quản lý quỹ tài khoản.",
		"desc": "投资者：享受一站式交易服务，并分享资金管理者的交易成果。<br>投资者可以自由选择符合自身风险偏好和盈利目标的资金管理者。<br>高水位（high water mark）条款激励资金管理者并保护自身权益。<br><br>资金管理者：拥有丰富交易经验的交易员能够扮演PAMM账户资金管理者的角色，<br>资金管理者可以同时管理多个交易账户，使用自己资金和投资者的集合资金进行交易以获取利润。交易收益或成本的一部分作为账户资金管理者的劳动薪酬。"
	},
	"screen2": {
		"pageEnTitle": "SIMPLE IS THE BEST",
		"pageTitle": "Đơn giản hoá sự phức tạp",
		"title": "网页版后台简洁清晰，化繁为简",
		"desc": "Cung cấp các giải pháp toàn diện bao gồm quản lý thông tin hàng ngày, lot giao dịch/phân phối lợi nhuận, tải bản ghi, v.v. Nền tảng của phiên bản web đơn giản và rõ ràng, đơn giản hóa sự phức tạp."
	},
	"screen3": {
		"pageEnTitle": "FEATURES AND FUNCTIONS",
		"pageTitle": "Ưu điểm của PAMM",
		"title": "适用于任何类型的交易和资金管理",
		"text1": "Lên tới 9 ngôn ngữ",
		"text2": "An toàn quỹ và giao dịch trơn tru",
		"text3": "Hai phương pháp phân phối lợi nhuận chủ đạo (phân phối theo giá trị ròng và phân phối theo số dư)",
		"text4": "Điều khoản mực nước cao (high water mark) cung cấp các ưu đãi để người quản lý quỹ bảo vệ nhà đầu tư",
		"text5": "Giao diện của phiên bản web thân thiện và rõ ràng",
		"text6": "Cung cấp cho các người quản lý quỹ 5 cách kiếm lời (Chia sẻ lợi nhuận/phí quản lý/phí quản lý hàng năm/hoa hồng trên mỗi lot/hoa hồng trên mỗi lệnh)"
	},
	"imgUrl": "en",
	"styleName": "en-style",
	"btn-text": "Lập tức đăng ký tài khoản PAMM"
}