export default {
  "box-class-name": "en-box",
  "lang": "th",
  "MT4是最受欢迎的交易平台之一": "MetaTrader 4 (MT4) หนึ่งในแพลตฟอร์มการเทรดที่ได้รับความนิยมมากที่สุดในปัจจุบัน และเป็นที่ยอมรับในระดับสากลจากเทรดเดอร์ทั่วโลกในด้านเทคโนโลยีขั้นสูง การใช้งานสะดวกและความปลอดภัย",
	"内核稳定流畅": "มีฟังก์ชันการวิเคราะห์กราฟที่หลากหลาย ช่วยให้เทรดเดอร์กำหนดกลยุทธ์การเทรดได้แม่นยำ",
	"支持EA交易": "รองรับระบบเทรดอัจฉริยะ EA ให้ผู้ใช้งานสามารถดำเนินการได้ด้วยระบบตัวช่วยอัตโนมัติ",
	"活跃用户社区": "มีตัวบ่งชี้ทางเทคนิคและเครื่องมือวิเคราะห์ในตัวมากมาย ตอบสนองทุกความต้องการของนักเทรดที่หลากหลาย",
	"立即注册": "ลงทะเบียนตอนนี้",
  "MetaTrader 4下载": "ดาวน์โหลด MetaTrader 4",
  "MT4移动版": "MT4 สำหรับ มือถือ",
  "随时随地进行交易": "เทรดได้ทุกที่ทุกเวลา ติดตามแนวโน้มของตลาดได้อย่างใกล้ชิด เตรียมความพร้อมจัดการบัญชีของคุณได้อย่างง่ายดาย",
  "下载Android版": "MT4 สำหรับ Android",
  "下载IOS版": "MT4 สำหรับ IOS",
  "下载iPad版": "MT4 สำหรับ iPad",
  "MT4桌面版": "MT4 Desktop",
  "全面的交易工具和高级图表分析": "เครื่องมือการเทรดที่คลอบคลุมและวิเคราะห์กราฟขั้นสูงตอบสนองทุกความต้องการของนักเทรดมืออาชีพ",
  "下载Windows版": "MT4 สำหรับ Windows",
  "下载MacOS版": "MT4 สำหรับ MacOS",
  "MT4网页版": "MT4 WebTrader",
  "无需下载": "ไม่ต้องดาวน์โหลดแอพก็เข้าถึงตลาดโลกได้ทำธุรกรรมออนไลน์ได้อย่างสะดวก รวดเร็ว และครอบคลุม",
  "打开网页版": "เปิดใช้งาน Webtrader"
}