export default {
  "screen0": {
    "title": "Legal Document",
    "tit": "법률 문서",
    "list": [
      "이메일 연락처",
      "온라인 직원"
    ]
  },
  "screen1": {
    "list": [{
      "title": "1. 웹 사이트 이용 약관",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Website-Terms-and-Conditions.pdf"
    }, {
      "title": "2. 위험 경고 고지",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Risk-Acknowledgement-And-Disclosure-Notice.pdf"
    }, {
      "title": "3. 은둔 조항",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Privacy-Policy.pdf"
    }, {
      "title": "4. 시행 조항",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Execution-Policy.pdf"
    }],
    "tit": "무엇을 찾고 있습니까?",
    "name": "사이트를 검색 할 수 있습니다",
    "placeholder": "사이트 검색",
    "btn": "검색",
    "text": "또는 전담 서비스에 문의하십시오.",
    "btn1": "전용 서비스에 문의"
  }
}
