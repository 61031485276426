export default {
	"box-class-name": "en-no1-box",
	"lang": "th",
	"button-text": "เริ่ิ่มต้นการท้าทาย",
	"no1-page2": {
		"text1": "ประมวลผลคำสั่งซื้อ 1000 รายการต่อวินาที ",
		"text2": "ดำเนินการคำสั่งภายใน 20ms",
		"text3": "ความเสถียรสูงสุด 98.75%",
		"text4": "บัญชี RAW ECN สเปรดต่ำสุด 0.0 pips",
	},
	"no1-page3": {
		"text1": "กำกับดูแลโดยองค์กร FCA ของหราชอาณาจักร",
		"text2": "EBC FINANCIAL GROUP (UK) LTD ได้รับอนุญาตและกำกับดูแลโดย Financial Conduct Authority (FCA)<br><span>หมายเลขอ้างอิง: 927552</span>",
		"text3": "กำกับดูแลโดยองค์กร ASIC ของออสเตรเลีย",
		"text4": "EBC Financial Group (Australia) Pty Ltd ได้รับอนุญาตและควบคุมโดย Australian Securities and Investments Commission (ASIC)<br><span>หมายเลขอ้างอิง: 500991</span>",
	},
	"no1-page4": {
		"text1": "สภาพแวดล้อมการเทรดที่ดีที่สุด<br>ไม่ใช่ว่า EBCนั้นไม่มีขีดจำกัด แต่เราต้องการเชิญคุณมาร่วมขีดสร้างเส้นมาตราฐานแห่งการท้าทายนี้ไปด้วยกัน<br>หากคุณดำเนินการท้าทายนี้สำเร็จ<br>EBC การันตีมูลค่ารางวัล 150% จากส่วนต่างของผลตอบแทน โดยไม่มีขีดจำกัด<br>คุณดูแลเพียงขั้นตอนเริ่มต้นเท่านั้น<br>ที่เหลือเป็นหน้าที่ของ EBC เพื่อพิสูจน์ความแข็งแกร่ง",
		"text2": "รางวัล 150% จากส่วนต่างของผลตอบแทน โดยไม่มีขีดจำกัด",
	},
	"no1-page5": {
		"text1": "มากกว่า <span>85<i>%</i></span><br>ของคำสั่งซื้อขายได้กำไรสูงสุด",
		"text2": "การเพิ่มประสิทธิภาพระบบคำสั่งซื้อเพื่อความแม่นยำ กล่องดำธุรกรรม EBC นั้นเหนือกว่าระบบการซื้อขายแบบดั้งเดิมเป็นอย่างมาก ในแง่ของอัตราการปรับราคาให้เหมาะสม อัตราความสำเร็จของคำสั่ง และความเสถียรของระบบ",
	},
	"no1-page6": {
		"text1": "เกณฑ์การแข่งขัน",
		"text2": "ยึดถือความซื่อสัตย์และความเคารพเป็นหลัก เพื่อสร้างความท้าทายได้มาตราฐานสูงสุด",
		"text3": "เงื่อนไขการฝาก",
		"text4": "การเลือกแพลตฟอร์ม",
		"text5": "กลยุทธ์เดียวกัน",
		"text6": "แข่งขันสำเร็จ",
		"text7": "ไม่มีข้อกำหนดฝากเงิน",
		"text8": "กำกับดูแลโดยองค์กร FCA ของหราชอาณาจักร",
		"text9": "กำกับดูแลโดยองค์กร ASIC ของออสเตรเลีย",
		"text10": "แพลตฟอร์มที่เลือกใช้นั้นมีคุณสมบัติถูกต้องตามกฎระเบียบของ FCA สหราชอาณาจักร หรือ ASIC ออสเตรเลีย",
		"text11": "ใช้เงินทุนและกลยุทธ์การซื้อขายเดียวกันใน EBC และตัวแทนจำหน่ายที่เลือกได้",
		"text12": "หากกำไรใน EBC ต่ำกว่าแพลตฟอร์มอื่น ๆ จะได้รับการชดเชย 150% ของส่วนต่างกำไร",
		"text13": "ผู้เข้าร่วมมีสิทธิ์ลงทะเบียนเปิดบัญชี EBC VIP ฟรี EBC มีแอพพลิเคชั่นการซื้อขายสำเนาข้ามแพลตฟอร์ม",
	},
	"no1-page7": {
		"text1": "คำแนะนำการแข่งขัน",
		"text2": " 1.บัญชีผู้เข้าแข่งขัน<br>a) ผู้ใช้ใหม่: หลังจากเปิดบัญชีใหม่บนเว็บไซต์ทางการ EBC เรียบร้อย ให้ติดต่อฝ่ายบริการลูกค้าออนไลน์ขอเปิดบัญชี VIP เพื่อเข้าร่วมการแข่งขัน<br>b) ผู้ใช้ปัจจุบัน: ติดต่อฝ่ายบริการลูกค้าออนไลน์ขอเปิดบัญชี VIP สามารถเข้าร่วมแข่งขันได้ทันที",
		"text3": "2. แพลตฟอร์มที่เลือกจะต้องได้รับการควบคุมดย FCA สหราชอาณาจักร หรือ ASIC ออสเตรเลีย ทั้งนี้บัญชีที่ลงทะเบียน AR, EEA หรือบัญชีTrading Nameไม่ได้รับอนุญาตให้เข้าร่วมแข่งขันได้",
		"text4": "3. คู่สกุลเงินหลักของการแข่งขัน- AUD/USD, USD/CAD, EUR/USD, USD/CHF, GBP/USD, USD/JPY, NZD/USD",
		"text5": "4. ใช้เงินทุนและกลยุทธ์การซื้อขายเดียวกันใน EBC และตัวแทนจำหน่ายที่เลือก และกลยุทธ์ทั้งหมดจะต้องอยู่ภายในขอบเขตของการซื้อขายปกติ",
		"text6": "5. ผู้เข้าแข่งขันสามารถเลือกที่จะชำระเงินในช่วงเวลาแข่งขันช่วงใดก็ได้ บัตรประจำตัวประชาชนแต่ละใบสามารถยืนยันตัวตน เพื่อเข้าร่วมแข่งได้เพียงครั้งเดียวเท่านั้น",
		"text7": "6. หลังจากการสิ้นสุดลแข่งขันสิ้นสุดลง ให้ส่งคำสั่งส่งมอบเพื่อปิดตำแหน่ง รางวัลจะถูกส่งไปยังบัญชีเทรดภายใน 2 วันทำการหลังจากการตรวจสอบ หลักจากนั้นถึงสามารถใช้ดำเนินการซื้อขายต่อหรือถอนออกมาได้",
		"text8": "7. หากมีการคืนเงินค่าคอมมิชชันในบัญชี ลูกค้าจำเป็นต้องให้ความร่วมมือและแสดงหลักฐานที่เกี่ยวข้องเพื่อใช้ยืนยันตัวตน",
	},
	"no1-page8": {
		"text1": "กิจกรรมหมดเขตใน...",
		"text2": "เริ่มต้น: 00:00 GMT+2 วันที่ 18 มกราคม สิ้นสุด: 23:59 GMT+2 วันที่ 1 มีนาคม",
		"text2-style": "",
	},
	"button1-style": "left: -7px;",
	"button2-style": "left: 99px;",
	"button3-style": "",
	"button4-style": "",
}