export default {
  "box-name": "en-style2",
  "public-btn-text": "ھازىر ھېسابات ئېچىڭ",
  "screen0": {
    "title": "  .مەبلەغ سالغۇچىلارغا نىسبەتەن بىر ھەقىقىي EBC <br>يەر شارى بويىچە يۇقىرى دەرىجىلىك ئېقىشچانلىقىنى تەمىنلەيدۇ. ",
    "enTitle": "EBC THE FUTURE",
    "list": [{
        "title": "بىخەتەرلىك",
        "desc": "باراۋەر تور ئوپتىك تالا مەخسۇس لىنىيەسى ئارقىلىق يەر شارى Equinix نى تۇتاشتۇرۇپ، 20 مىللىسېكۇنتلۇق ئىنتايىن تېز زاكاز تالونىنى ئىجرا قىلىش ئىشقا ئاشۇرۇلدى."
      },
      {
        "title": "بەلگىلىمىگە ئۇيغۇنلۇقى",
        "desc": "بارلىق خېرىدارلارنىڭ مەبلىغى دۇنيادىكى ئەڭ يۇقىرى دەرىجىلىك بانكىلارغا ھاۋالە قىلىنىپ، قاتتىق نازارەت قىلىندى ۋە باشقۇرۇلدى."
      },
      {
        "title": "ئېقىشچانلىقى ",
        "desc": "سۇپىمىزخەلقئارادىكى نۇرغۇن يۇقىرى دەرىجىلىك بانكىلار بىلەن ئۇزاق مۇددەتلىك مۇ， قىم ھەمكارلىق مۇناسىۋىتى ئورنىتىپ، سىزنى ناھايىتى ئاسانلا ئادەتتىن تاشقىرى تۆۋەن سودا         تەننەرخىدىن بەھرىمەن قىلدى."
      }
    ]
  },
  "screen1": {
    title: "سۇيۇقلۇقنىڭ چوڭقۇرلۇقى    ",
    "trList": [
      "层级",
      "买入价",
      "买入的交易量",
      "点差",
      "卖出价",
      "卖出价交易量"
    ],
    "mobileTrList": [
      "层级",
      "买入价",
      "点差",
      "卖出价"
    ]
  },
  "screen2": {
    "pageEnTitle": "Clearing System",
    "pageTitle": "‎راسچوت سىستېمىسى",
    "title": "بىخەتەر، سۈزۈك، ئىشەنچلىك بولغان ھېسابات ئېكولوگىيە EBCسىستېمىسى"
  },
  "screen3": {
    "pageEnTitle": "EBC Priviate Room",
    "pageTitle": " سودا بوشلۇقى EBC",
    "title": "« ھېساب ئېلىش بىرلىككە كەلگەن ئۆلچەم ئەمەس، بەلكى بارلىق ئەھۋالغا ئۇيغۇن كېلىدىغان بارلىق جاۋاب» . ",
    "desc": "ئەگەر سىز بىر« چوڭ سودىگەر» بولسىڭىز، بازاردا باشقىلارغا ئۆزىڭىزنىڭ« تەگلىك» ىڭىزنى بىلدۈرگىڭىز يوق<br>EBCنىڭ Private Room پىلانى سىزنىڭ ئەڭ ياخشى تاللىشىڭىز بولىدۇ"
  },
  "screen4": {
    "title": "رول سىزنىڭ مەخسۇس ئېقىشچانلىقىڭىز<span>يەر شارى بازىرىنى ئاسانلا كونترول قىلىش</span>",
    "desc": " سىزنىڭ بىر قىسىم سودا ئۇچۇرلىرىڭىزنى يوشۇرىدۇ، بۇ سىز چۈشۈرگەن« چوڭ تالون» نىڭ تېخىمۇ ياخشى باھادا پۈتۈشەلەيدىغانلىقىدىن دېرەك بېرىدۇ، ئەمما ئېغىر بولغان سىيرىلىش نۇقتىسى مەۋجۇت ئەمەس ياكى ئەڭ ئاخىرقى قاراپ تۇرۇش باھاسى (LastLook) سەۋەبلىك زاكازنىڭ رەت قىلىشىغا ئۇچرايدۇ. رەسىمدە كۆرسىتىلگەندەك، Private Room سودىسى ئارقىلىق پۈتۈشۈش باھاسى تېخىمۇ ياخشى بولغان. "
  },
  "screen5": {
    "it": "پەقەت ۋاقىت ، ماڭا تېخىمۇ ياخشى تونۇشتۇرۇڭ    ",
    "ct": "* سودىدا تېيىلىپ كېتىش، كېچىكتۈرۈش قاتارلىق تۈرلۈك ئېنىقسىز ئامىللار بولۇشى مۇمكىن، بەلكىم نوپۇزلۇق سودىلاشقۇچىلارنىڭ بۇ ئىنچىكە نۇقتىلارغا دىققەت قىلمىغان بولۇشىڭىز مۇمكىن، لېكىن سىزگە ھەر قانداق قولايسىزلىق يۈز بەرسە، ۋاقتىدا بىزگە ئىنكاس قايتۇرۇڭ. EBC يۇقىرى سۈپەت ۋە يولۇچىلار روھىنى تەشەببۇس قىلىپ، ئېنىقسىز كەلگۈسىنى پائال قۇچاقلىغان. خېرىدارلارغا ناھايىتى ئاددىي، ساپ، ئازادە سودا تەسىراتى ئېلىپ كېلىشكە تىرىشىدۇ. ",
    "title": "مۆلچەردىن ئېشىپ كەتكەن ئابونت تەسىراتىنى داۋاملىق يارىتىش كېرەك. ",
    "desc": "بىز سىزنىڭ پىكرىڭىزنى ئاكتىپ ئاڭلايمىز، ھەمدە سىزنىڭ خاسلاشقان سودا تەسىراتىڭىزنى كۈچەيتىشكە ئىنتايىن ئەھمىيەت بېرىمىز. EBC تەرەققىيات كوللېكتىپى باشتىن-ئاخىر يېڭى ئىقتىدار، يېڭى تېخنىكىنى تەتقىق قىلىش ۋە ياخشىلاشقا كۈچ چىقىرىپ، خېرىدارلارنىڭ توردا سودا قىلىش تەسىراتىنى يەنىمۇ ئەلالاشتۇردى. ",
    "btn": "سۇپا ئەۋزەللىكى ئۈستىدە ئىزدىنىش "
  }
}
