<template>
	<div class="bottom-fixed">
		<div class="container">
			<div class="row">
				<div class="col-md-1"></div>
				<div class="col-md-7">
					<img class="img1" src="../../assets/image/bottom/bottom11.png" alt="" srcset="">
					<span class="span1">Trustpilot</span>
					<span class="span2">Excellent</span>
					<img class="img2" src="../../assets/image/bottom/bottom22.png" alt="">
					<span class="span3">4.9</span>
					<img class="img3" src="../../assets/image/bottom/bottom33.png" alt="">
					<span class="span4">VERIFIED COMPANY</span>
				</div>
				<div class="col-md-4 button">
					<a :href="$store.state.khUrl">{{$t('Header.zhuce')}}</a>
					<div data-id="TemX0bShXuY" class="livechat_button">
						
					</div>
				</div>
			</div>
		</div>
		<noscript>
			<a href="https://www.livechatinc.com/chat-with/12340599/" rel="nofollow"> Chat with us </a>, powered by
			<a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow " target="_blank ">LiveChat</a>
		</noscript>
	</div>
</template>

<script>
	export default {
		name: "",
	}

</script>

<style lang="scss" scoped>
	.bottom-fixed {
		position: fixed;
		bottom: 0;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		height: 70px;
	}

	.bottom-fixed .span1,
	.span2,
	.span3,
	.span4 {
		color: #efefef;
		font-size: 16px;
		float: left;
		line-height: 72px;
		font-family: auto;
	}

	.bottom-fixed .span1 {
		margin-right: 20px;
	}

	.bottom-fixed .span2 {
		margin-right: 10px;
	}

	.bottom-fixed .span3 {
		margin-right: 10px;
		font-size: 14px;
	}

	.bottom-fixed .span4 {
		font-size: 14px;
	}

	.bottom-fixed .img1 {
		width: 28px;
		display: inline-block;
		float: left;
		margin: 21px 10px;
		margin-left: 0px;
		margin-right: 8px;
	}

	.bottom-fixed .img2 {
		width: 135px;
		display: inline-block;
		float: left;
		margin: 23px 10px;
	}

	.bottom-fixed .img3 {
		width: 20px;
		display: inline-block;
		float: left;
		margin: 25px 20px 25px 10px;
	}
	.bottom-fixed .button{
		display: flex;
		align-items: center;
	}
	.bottom-fixed .button a {
		width: max-content;
		height: 40px;
		display: inline-block;
		background: #3D0101;
		color: #AF8147;
		border-radius: 3px;
		margin-right: 9px;
		font-size: 16px;
		float: left;
		line-height: 40px;
		text-align: center;
		padding: 0 20px;
	}

	.bottom-fixed .button .livechat_button {
		display: inline-block;
		float: left;
		

		a {
			padding: 0;
			margin: 0;
			background: none;
		}

		img {
			height: 37px;
		}
	}

	.bottom-fixed .button>a:hover {
		background: #AF8147;
		color: #3D0101;
	}

	.bottom-fixed .chat:hover svg path {
		fill: #3D0101;
	}

	.bottom-fixed .chat:hover svg circle {
		fill: #AF8147;
	}

	.bottom-fixed .chat svg {
		margin-right: 4px;
		margin-bottom: 2px;
	}

	.bottom-fixed .chat {
		padding-bottom: 7px !important;
	}

</style>

