import Vue from 'vue'
import requireFn from '@/serve'

function openUrl(url, attr) {
	console.log(url);
	var a = document.createElement('a');
	a.href = url;
	a.setAttribute('target', '_blank');
	a.setAttribute('id', 'startTelMedicine');
	if (attr) {
		for (var i in attr) {
			a.setAttribute(i, attr[i]);
		}
	}
	// 防止反复添加
	if (document.getElementById('startTelMedicine')) {
		document.body.removeChild(document.getElementById('startTelMedicine'));
	}
	document.body.appendChild(a);
	a.click();
	setTimeout(() => {
		document.body.removeChild(a);
	}, 10)
}
function getSeoInfo(item) {
	let data = new Promise((resolve, reject) =>{
		requireFn("/ebc/api/getSeo/run",item).then(res=>{
			resolve(res.data.data)
		})
	})
	return data
}
export default getSeoInfo