export default {
    "h1-title": "交易工具",
    "imgUrlLang": "",
    "public-btn-text": "即刻体验",
    screen0: {
        "title": "创建满足您交易需求的工具库",
    },
    screen1: {
        "title": "同图商品叠加工具",
        "desc": "该工具可以让投资者清晰地了解到两种商品任意周期任意K线的相对强弱，此外还可识别突破的有效性",
    },
    screen2: {
        "title": "MACD专业指标(双线)",
        "desc": "这是一款股票和期货常用的MACD指标工具，与MT4自带的MACD相比，更加符合中国用户的看盘习惯",
    },
    screen3: {
        "title": "MTDriver盈亏统计指标",
        "desc": "该工具可以帮助投资者精确地定位不同周期的盈亏",
    },
    screen4: {
        "title": "价格变动手机推送通知",
        "desc": "大部分投资者的盯盘时间较为有限,价格变动手机推送可以帮助他们在规避部分市场噪音的情况下把握市场的动向",
    },
    screen5: {
        "title": "一键平仓EA",
        "desc": "操作界面位于图表界面中，方便投资者在极端行情发生的时候更迅速便捷地进行止损",
    },
    screen6: {
        "title": "定时下单EA",
        "desc": "放在\MQL4\Experts文件夹下，一款指定时间下单的EA，用法很简单，用户请自行摸索",
    },
    screen7: {
        "title": "净值监控EA",
        "desc": "放在\MQL4\Experts文件夹下，监控净值变动的EA，把想要提示的声音文件放在\Sounds下，然后在EA的音乐名称填对应音乐文件的名字",
    }
}