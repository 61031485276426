export default {
	"malaria-class-name": "en-ubm-box",
	"malaria-page1": {
		"text1": "EBC Partners with United to Beat Malaria to End Malaria for a Brighter Future",
		"text2": "",
	},
	"malaria-page2": {
		"text1": "Malaria Stands as One of <br>The Most Perilous Threats to Children’s Health Worldwide",
		"text2": "In 2022, an estimated 249 million cases of malaria and 608,000 deaths occurred in 85 countries worldwide. Shockingly, over 76% of these deaths were among children under 5 years old.As you read this, approximately one child dies from malaria every minute.",
		"text3": "249<i>million</i>",
		"text4": "Infected",
		"text5": "608,000",
		"text6": "Deaths",
		"text7": "76%<i>+</i>",
		"text8": "Children",
		"text9": "*Data source: World Health Organization \"World Malaria Report 2023\"",
	},
	"malaria-page3": {
		"text1": "Let’s Be the Generation to End Malaria",
		"text2": "The United to Beat Malaria campaign, spearheaded by the United Nations Foundation, is a global initiative rallying supporters  worldwide to fight malaria. EBC actively fulfills its corporate social responsibility by joining forces with the United to Beat Malaria campaign to confront this pressing issue.",
		"text3": "60",
		"text4": "Countries impacted",
		"text5": "40<i>million</i>",
		"text6": "People protected",
		"text7": "183,450",
		"text8": "Insecticide-treated bed nets donated",
	},
	"malaria-page4": {
		"text1": "Join the global community to combat malaria by taking part in the Move Against Malaria 5k Virtual Run 2024",
		"text2": "Between April 25th and May 5th, 2024, join the Move Against Malaria Charity Run to raise awareness and funds for the development of life-saving malaria treatment tools and programmes.<br>EBC Financial Group and the United Nations Foundation invite individuals who share our vision to join hands with us in creating a healthier world for all.",
		"text3": "Join Now",
	},
	"malaria-page5": {
		"text1": "Our Efforts",
	},
}