export default {
	"esg-class-name": "en-esg",
	"esg-banner": {
		"btn": "Learn more",
		"text1": "EBC Financial Group Partners with United to Beat Malaria, a Campaign of the United Nations Foundation, to End Malaria for a Brighter Future for All",
		"text2": "Empowering Children's Growth: EBC's Global Initiatives",
		"text3": "EBC Financial Group Supports Oxford’s Department of Economics Webinar Series, \"What Economists Really Do\"",
		"text3-2": "",
		"text4": "EBC Partners with the World Association of Girl Guides and Girl Scouts: Empowering Women's Growth and Cultivating Tomorrow's Leaders",
		"text5": "Together , Let's Build The Future",
		"text6": "EBC Financial Group is committed to the principles of Environmental, Social, and Governance (ESG). We go beyond financial performance and prioritize creating a positive impact for the environment, our communities, and the future we all share."
	},
	"esg-nav": {
		"text1": "Our Vision",
		"text2": "Our Measures",
		"text3": "Our Initiatives",
		"text4": "Our Efforts",
	},
	"esg-page1": {
		"text1": "Our Vision",
		"text2": "Fostering Futures<br> Through Education",
		"text3": "Our commitment to education emphasizes enhancing conditions for children and forging partnerships with higher education institutions. We aim to nurture open platforms for knowledge exchange, fostering innovation and learning.",
		"text4": "Advancing <br>Social Equity",
		"text5": "By supporting charitable initiatives, we champion equal health rights and social equity. Our efforts are geared towards empowering communities, ensuring fairness, and enabling all individuals to prosper.",
		"text6": "Empowering<br> Our Employees",
		"text7": "We're dedicated to creating a workplace where employees feel valued and proud. Our culture celebrates openness and inclusion, inspiring our team to contribute positively to a diverse and accepting global community.",
		"text8": "Cultivating a <br>Sustainable Environment",
		"text9": "Sustainability is integral to our strategic vision. We advocate for prudent resource use and are committed to crafting a sustainable future, demonstrating our dedication to environmental stewardship and creating a better world for tomorrow.",
	},
	"esg-page2": {
		"text1": "Our Measures",
		"text2": "Environmental Initiatives",
		"text3": "<span>Sustainable Resource Management:</span><br>Our commitment extends to utilizing eco-friendly packaging materials, reducing waste, and promoting the principles of a circular economy, all aimed at diminishing our ecological footprint.",
		"text4": "<span>Carbon Footprint Reduction: </span><br>We actively engage in efforts to combat global warming, forming alliances with entities that prioritize renewable energy, thereby contributing to environmental sustainability.",
		"text5": "Social Commitments",
		"text6": "<span>Philanthropic Endeavors: </span><br>We are dedicated to fostering an equitable and inclusive society through our support for charitable foundations, via donations and volunteerism.",
		"text7": "<span>Ethical Partnerships:</span><br>We ensure our partners uphold stringent standards of environmental stewardship and social responsibility, reinforcing our commitment to societal advancement.",
		"text8": "Governance Practices",
		"text9": "<span>Diversity and Inclusion:</span><br>We are committed to creating a workplace that celebrates diversity and inclusivity, opposes discrimination, and advocates for equal opportunities for everyone.",
		"text10": "<span>Employee Wellbeing:</span><br>We prioritize comprehensive welfare and professional development for our employees, supporting their continuous learning and skill enhancement to nurture their growth and success within our organization.",
	},
	"esg-page3": {
		"text1": "Our Initiatives",
		"text2": "Education Fund",
		"text3": "EBC Upholds the Belief that Education is the Keystone of Societal Progress.",
		"text4": "Our collaboration with non-profits and academic institutions underscores our drive for educational reform. The EBC Education Fund channels resources towards nurturing young minds and enhancing educational infrastructure in underserved communities, illustrating our pledge to educational empowerment.",
		"text5": "Healthcare Fund",
		"text6": "A Benevolent Heart Catalyzes Positive Change.",
		"text7": "This fund extends aid to care facilities, providing solace to the elderly without dependents and delivering hope to children facing health challenges, underscoring our commitment to societal health and welfare.",
		"text8": "Employee Support Fund",
		"text9": "Nurturing Individual Well-being to Empower Our Team.",
		"text10": "The Employee Support Fund is designed to assist our colleagues in caring for their families, promoting a sense of unity and support within our organization, and affirming our dedication to our employees' comprehensive well-being.",
		"text11": "Humanitarian Aid Fund",
		"text12": "In the Aftermath of a Disaster, We All Contribute to Reconstruction.",
		"text13": "Our Humanitarian Aid Fund supports recovery and aid initiatives for communities affected by global crises, embodying our ethos of compassion and collective responsibility in times of need.",
	},
	"esg-page4": {
		"text1": "Our Efforts",
	}
}