export default {
	"screen0": {
		"title": "A Trusted Solution<br> For MAM",
		"content": "The EBC Multi Account Manager (MAM) is designed specifically for asset managers that provides them with the high level of control that they need to maximize returns. This solution provides a reliable way of cooperation for asset managers and clients seeking professionals to manage funds.",
		"list": [
			"Flexible allocation methods",
			"Real-time reporting",
			"Easy to use interface"
		],
		"btn": "Apply for a MAM account",
		"rightTitle": "The MAM software tool combines flexible allocation methods with real-time reporting of performance and commissions through an easy to use interface."
	},
	"screen1": {
		"pageEnTitle": "Asset Management",
    "pageTitle": "",
		"title": "Designed for MetaTrader 4",
		"text": "It can trade for multiple <span> investors </span> quickly, effectively and accurately.",
		"desc": "It is ideal for professional traders that manage multiple accounts utilizing Expert Advisors (EAs). MAM broadens the functionality of the MetaTrader 4 Platform by enabling the asset manager to effectively trade and manage multiple accounts with integrated software tools from a single MT4 interface. ",
		"btn": "learn more",
		"imgUrl": "en"
	},
	"screen2": {
		"pageTitle": "Intelligent Transaction",
		"pageEnTitle": "Intelligent Transaction",
		"title": "Multiple trading accounts can be traded effectively from a single account",
		"desc": "MT4 MAM Software lets investors connect their MT4 accounts with a Master Trader and copy trades on the selected allocation methods, and allocate the transactions to different individual investors automatically. Account managers can execute transactions for multiple investors quickly, effectively, and accurately from a single transaction account."
	},
	"screen3": {
		"pageEnTitle": "Advantages & Functions",
    	"pageTitle": "MAM Function",
		"pageTitle2": "Advantages of MAM",
		"title": "<span style='color:#800'>For any type of trading and asset management</span>",
		"content": [
			"For any type of trading and asset management",
			"Ten Allocation Methods including P&L Allocation and Trade Copier Allocation",
			"Unlimited Trading Accounts",
			"Trades – Full, Mini & Micro Lot Accounts",
			"Max Precision of P&L Allocation up to 16 Digits",
			"Any Order Types, Full SL, TP & Pending Order Functionality",
			"Partial Close of Orders by Master Account Execution or Individual Sub-Orders",
			"Allows Expert Advisor (EA) Trading of Managed Accounts from Client Side"
		],
		"desc": "Apply for a MAM Account",
		"btn": "apply for mam fund manager account number",
		"line1": "Instant Change of Allocation Parameters",
		"line2": "Real Time Control of Open Positions of All the Managed Accounts",
		"line3": "Control All the Managed Accounts from a Single Interface",
		"line4": "“Group Order” execution",
		"line5": "Real Time Equity and P&L Monitoring of the Managed Accounts",
		"line6": "Get Historical Reports and Calculate Commissions",
		"btn1": "Get a MAM Account Today"
	},
	"styleName": "en-style"
}
