export default {
  "srceen0": {
    "title": "레버리지 및 마진",
    "subTitle": "거래 방법을 정의하는 열쇠",
    "desc": "금융 시장의 불확실성과 큰 변동 가능성을 고려하여 고객 거래의 안전을 보호하기 위해，현재 EBC에서 제공하는 최대 레버리지는 500 : 1입니다.",
    "rateTitle": "레버리지는 ",
    "rateNum": "500:1",
    "rateDesc": "마진 비율 0.20 %"
  },
  "srceen1": {
    "pageEnTitle": "Secure Transaction",
    "pageTitle": "안전한 거래",
    "subTitle": "적절한 레버리지 비율 선택",
    "desc1": "투자 목표와 위험 허용 범위를 신중하게 고려하고 적절한 레버리지 비율을 선택하세요.",
    "desc2": "위험 관리 및 통제를 더 잘 실현하기 위해 각 자산의 보증금 요건과 최대 레버리지를 이해하려면 아래 표를 참조하시기 바랍니다.",
    "btn": "투자 시작",
    "title":""
  }
}
