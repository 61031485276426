export default {
  "box-name": "en-style2",
  "public-btn-text": "สมัครตอนนี้",
  "screen0": {
    "title": "EBC ให้สภาพคล่องระดับ<br>โลกอย่างแท้จริง สำหรับนักลงทุนสถาบัน    ",
    "enTitle": "<text style='color:#6c100f;font-family: 'Source Serif Pro', Georgia, 'Times New Roman', serif;'>EBC ในอนาคต</text>",
    "list": [{
        "title": "ความปลอดภัย",
        "desc": "การเชื่อมต่อกับศูนย์ Equinix ทั่วโลกผ่านใยแก้วนำแสงของเครือข่ายเพียร์ทูเพียร์ เพื่อให้เกิดการดำเนินการตามคำสั่งที่รวดเร็วมากถึง 20 มิลลิวินาที"
      },
      {
        "title": "การยินยอม",
        "desc": "เงินของลูกค้าทั้งหมดจะถูกเก็บไว้ในบัญชีทรัสต์อิสระที่เป็นส่วนตัวกับธนาคารชั้นนำและอยู่ภายใต้กฎระเบียบที่เข้มงวด "
      },
      {
        "title": "สภาพคล่อง",
        "desc": "EBC ได้สร้างความสัมพันธ์ ความร่วมมือระยะยาวและความมั่นคงกับธนาคารระหว่างประเทศชั้นนำของโลก ช่วยให้คุณสามารถทำธุรกรรมได้อย่างง่ายดายด้วยต้นทุนที่ต่ำมาก"
      }
    ]
  },
  "screen1": {
    title: "ความลึกของสภาพคล่อง",
    "trList": [
      "hierarchy",
      "buying price",
      "trading volume bought ",
      "point difference",
      "selling price ",
      "selling price volume "
    ],
    "mobileTrList": [
      "hierarchy",
      "buying price",
      "point difference",
      "selling price "
    ]
  },
  "screen2": {
    "pageEnTitle": "ระบบการหักบัญชี",
    "pageTitle": "ระบบการหักบัญชี",
    "title": "EBC มีระบบนิเวศที่ปลอดภัยโปร่งใสและเชื่อถือได้"
  },
  "screen3": {
    "pageEnTitle": "EBC private room",
    "pageTitle": "พื้นที่ซื้อขาย EBC",
    "title": "ไม่มีเกณฑ์ที่เหมือนกันสำหรับการชำระบัญชี ดังนั้นต้องพิจารณาความเป็นไปได้ทั้งหมด ",
    "desc": "หากคุณเป็น “นักลงทุนรายใหญ่” และไม่ต้องการเปิดเผย “คำสั่งซื้อจำนวนมาก” ของคุณกับผู้อื่น ห้องส่วนตัว EBC จะเป็นตัวเลือกที่ดีที่สุดของคุณ"
  },
  "screen4": {
    "title": "ควบคุมสภาพคล่องเฉพาะของคุณและเข้าใจตลาดโลกได้อย่างง่ายดาย",
    "desc": "ห้องส่วนตัวสามารถซ่อนข้อมูลการทำธุรกรรมของคุณบางส่วน ซึ่งหมายความว่า ' คำสั่ งซื้ อจำนวนมาก ' ของคุณอาจได้ตำแหน่งราคาที่ดีกว่า โดยไม่มีสลิปเพจหรือการปฏิเสธอย่างร้ายแรงเนื่องจาก Last Look ดังที่แสดงในรูปด้านบน คำสั่งซื้อจำนวนมากที่ซื้อขายผ่านห้องส่วนตัว จะได้รับราคาที่น่าพึงพอใจมากกว่าที่คาดการณ์ไว้"
  },
  "screen5": {
    "it": "เวลาเท่านั้น ให้คุณรู้จักฉันดีขึ้น",
    "ct": "* อาจมีสลิปเพจ ความล่าช้า และปัจจัยความไม่แน่นอนหลายประการในการทำธุรกรรม บางทีคุณอาจไม่สังเกตเห็นรายละเอียดเหล่านี้จากเทรดเดอร์คนอื่นๆ แต่หากคุณมีข้อสงสัย โปรดส่งคำถามหรือข้อคิดเห็นให้เราได้ทันที EBC เราให้การดูแลลูกค้าด้วยความทุ่มเททั้งกายและทั้งทางจิตวิญญาณ และไม่กลัวอนาคตที่ไม่แน่นอน มุ่งมั่นที่จะนำประสบการณ์การซื้อขายแบบใหม่ที่เรียบง่าย ใสสะอาด และสะดวกสบายมาสู่ลูกค้า",
    "title": "เตรียมพบกับสิ่งที่อยู่เหนือจินตนาการของคุณ",
    "desc": "เราจะรับฟังความคิดเห็นของคุณอย่างจริงจังและให้ความสำคัญอย่างยิ่งต่อการยกระดับประสบการณ์การซื้อขายที่เป็นส่วนตัวของคุณ ทีมพัฒนา EBC มุ่งมั่นที่จะวิจัย พัฒนา ปรับปรุงฟังก์ชันและเทคโนโลยีใหม่ ๆ เพื่อเพิ่มประสิทธิภาพประสบการณ์การซื้อขายออนไลน์ของลูกค้าของเราให้ดียิ่งขึ้น ",
    "btn": "ดูข้อได้เปรียบของเรา"
  }
}
