export default {
  lang:'ja',
  指数差价合约: "株価指数CFD",
  无需持有基础资产: "実物資産を保有する必要がなく、グローバルでの取引チャンスを柔軟に掴む",
  掌控全球机遇: "",
  即刻开户: "今すぐ口座開設",
  美股指数差价合约: "米株価指数CFD",
  点差大幅度降低: "スプレッドが大幅に縮小し、流動性が強化された低コストの取引環境",
  美国道琼斯工业平均指数: "US Dow Jones Industrial Average Index Spot CFD",
  美国标准普尔500指数: "US S&P 500 Index Spot CFD",
  美国纳斯达克100指数: "US NASDAQ-100 Index Spot CFD",
  香港恒生指数: "Hong Kong Hang Sheng Index Spot CFD",
  中国A50指数:"China A50 Index Spot CFD",
  降幅: "-Reduction Percentage (%)",
  亚洲时间: "Asian Session",
  欧洲时间: "European Session",
  美国时间: "US Session",
  品种: "Symbol",
  指数描述: "Description",
  指数点差: "Spreads in Index Points",
  开设模拟账户: "今すぐデモ口座を開設する",
  开设真实账户: "今すぐリアル口座を開設する",
  产品优势: "株価指数CFDの魅力",
  无需持有基础资产2:
    "現物資産を保有する必要がなく、高いレバレッジが効いているので、少ない元金からお取引可能",
  灵活高杠杆: "高いレバレッジ",
  杠杆高达: "最大レバレッジが100倍、取引収益を最大限に拡大する",
  放大每笔交易收益: "",
  实时弹性定价: "リアルタイムの取引価格",
  根据全球市场波动实时调整: "市場の変動に応じてリアルタイムに調整し、各取引に最良な取引価格を提供する",
  确保您每笔交易享受最优交易条件: "",
  极致的交易速度: "迅速な注文執行速度",
  平均执行速度20毫秒: "平均執行速度は20msで、取引チャンスを瞬時に捉える",
  助您抢占市场先机: "",
  完全透明的交易: "透明な取引環境",
  无隐藏费用或额外成本: "追加の手数料やコストはなく、取引の透明性と公平性を確保する",
  确保您每笔交易的透明和公平: "",
  全球指数交易深度: "株価指数の流動性深さ",
  查看所有产品: "取引銘柄とスプレッド一覧はこちら",
  指数差价合约相关问题: "株価指数CFD取引に関するよくある質問",
  list: [
    {
      title: "1．株価指数CFDとは？",
      content: `株価指数CFDとは、株価指数市場でレバレッジを効かせて取引し、株価指数の値動きによって損益を得る取引です。<br />

                        例えば、日経平均株価指数は、225の代表銘柄で構成され、日本株式市場全体の状況を反映しています。<br />
                        株価指数CFDを取引することで、個別の株式に対する複雑な分析から市場全体の分析に簡素化します。また、個別銘柄の極端な価格変動を回避し、リスク分散を図ることができます。`,
    },
    {
      title:"2. 株価指数CFDで取引できるものは？",
      content:`世界中の株価指数を取引することができます。
                        EBCは、世界中の主要株価指数を厳選し、NYダウン平均株価、ドイツDAX30指数、ユーロ・ストックス50指数、S&P
                        500指数、日経225指数などの銘柄を提供しています。<br />

                        また、EBCに口座を1つ開設するだけで、すべての銘柄を一元取引が可能です。<br />
                        さらに、競争力のあるスプレッドと柔軟なレバレッジをご利用いただけます。`
    },
    {
      title:"3. 株価指数CFDのレバレッジは？",
      content: `EBCは、コンプライアンス及び規制に遵守し、またお客様の投資安全性と資本効率のバランスを十分に考慮したうえ、株価指数CFD取引に最大100倍のレバレッジを提供しております。`
    },
    {
      title:"4. 株価指数CFDの契約サイズは？",
      content:`取引銘柄によって契約サイズが異なります。<br />
                        100GBP（FTSE 100）、<br />
                        200AUD（S&P/ASX 200）、<br />
                        CNIUSD（中国A50指数）、<br />
                        D30EUR（DAX 30）、<br />
                        E50EUR（STOXX 50）、<br />
                        F40EUR（CAC 40）、<br />
                        HSIUSD（Hang Seng Index）、<br />
                        NASUSD（NASDAQ 100）、<br />
                        U30USD（Dow Jones Industrial
                        Average）の1ロットあたりの契約サイズは10です。<br />

                        225JPY（Nikkei 225）、<br />
                        SPXUSD（S&P 500）<br />
                        の1ロットあたりの契約サイズは100です。<br />
                        なお、株価指数CFDの最小取引数量は0.1ロットで最大取引量は40ロットす。`
    },
    {
      title:"5. 株価指数CFD取引の魅力は？",
      content: ` 1.資金効率の向上<br />
                        株価指数取引では通常、レバレッジが低いことが多いですが、株価指数CFD取引では高いレバレッジを効かせてより少ない資金で参加することができます。ただし、レバレッジとリスクは共存するものであるため、適切なポジション管理を心がけてください。<br />

                        2.両建て取引<br />
                        株価指数CFDを通じて、トレーダーはロングとショートの両方の取引を行うことができ、より大きな利益を得る可能性があります。<br />

                        3.リスク分散<br />
                        株価指数は通常、市場で最も代表的な銘柄で構成されています。特定の株式の動向が指数に直接与える影響は限られているため、市場リスクを効果的に分散することができます。`
    },
    {
      title:"6. 株価指数CFD取引する際の注意点は？",
      content:`特定の銘柄ではなく、市場全体の動きに注意をする必要があります。<br />
                        一般的に、株価指数は中央銀行の金融政策や経済指標と密接に関係しています。例えば、中央銀行が引き締めな金融政策を実施すると、市場では資金不足が予測され、株価が落下する可能性があります。同様に、経済指標の悪化は市場の投資期待を低下させることから、株価が下落する可能性があります。`
    }
  ],
  每个认真交易的人:"すべてのトレーダーに、誠実な対応を。"
};
