export default {
	"official-class-name": "official-en-box",
	"lang": "ug",
	"official-page1": {
		"text1": "رەسمىي كانالنى دەلىللەش",
		"text2": "زىيەنكار ھەربىر ئىشلارنى توسۇش ئۈچۈن، بۇ بەت ئارقىلىق ئۇچۇر تەتقىق قىلىپ، كانالنىڭ ھەقىقىلىكىنى دەلىللەڭ.",
		"text3": "تولۇق تور بېتى ئۇلانمىسىنى كىرگۈزۈڭ",
		"text4": "تەتقىق قىلىۋېتىڭ"
	},
	"official-page2": {
		"text1": "EBC نىڭ رەسمىي باياناتى:",
		"text2": "1. ھازىر EBC پەقەت رەسمىي MetaTrader 4 (MT4) سودا سۇپىسىنى ئېچىپ بېرىپ، رەسمىي تور بېتىدىن MT4 غا ئەسلى سەرۋارنى چۈشۈرۈڭ.",
		"text3": "2. ھازىر EBC ھېچقانداق شىفىرپۇل ۋە ئۇنىڭغا باغلاشتۇرۇلغان CFD سودىسىنى قىلىپ باقمىغان.",
		"text4": "3. EBC ھېچقانداق باشقا ئىنۋېستىتسىيە تەكلىپىنى بەرمەيدۇ، سودىنىڭ پايدىسىغا ھېچقانداق كاپالەت بەرمەيدۇ.",
		"text5": "4. ھەرقانداق EBC نىڭ نامىنى ئوغرىلاپ ئىشلىتىشكە قارشى، بىزدە ھەمدەم قانۇنىي ھەرەكەتنى قوللىنىمىز.",
		"text6": "5. EBC ئىشلىنىشچىلەرنىڭ ھوقۇق-مەنپەئەتلىرىنى قوغداپ كېلىپ، ئەگەر قانداق شۇپىلىك پائالىيەتنى كۆرۈسىڭىز، بىزدە نىڭ خەلقىمىزگە تەتقىق قىلىپ تېڭىلىپ ، ياكى قانداق مىدىيا ياكى <a href=\"mailto:cs@ebc.com\" style=\"color: #4D0101;\">cs@ebc.com</a> ئېلخەتى ئارقىلىق بىز بىلەن ئالاقىلىشىڭ. بىز تېز جەۋاپ قايتۇرۇپ، زىيەنكا شەخسىگە بىلمىكچىلى كېرەكلىك قانۇنىي توردۇرۇپ بېرىمىز."
	},
	"official-page3": {
		"text1": "رەسمىي توربېتىنى تەتقىق قىلىپ بېقىڭ، ئۇچۇرۇڭىز رەسمىي دەلىللەنگەن",
		"text2": "قايتىدىن تەتقىق قىلىپ بېقىڭ",
		"text3": "سىز تەتقىق قىلغان ئۇچۇر رەسمىي دەلىللەنگەن ئەمەس، رەسمىي دەلىللەنگەن كاناللار بىلەن ئالدىم-بىرىڭ.",
		"text4": "بۇ ئۇچۇرنى خەۋەر قىلىڭ",
		"text5": "ئەگەر سىز بۇ ئۇچۇرنىڭ زىيەنكارلىق قىلىپ مەقسەت ئوغرىلانغان دەيسىڭىز، بىزگە خەۋەر قىلىپ بېرىڭ",
		"text6": "خەۋەر ئۇچۇرى",
		"text7": "توربېتى ئادرېسى",
		"text8": "*ئەگەر قانداق قانۇنىي كېرەكلىك ھەمدەم مەسلىھەت ئالماقچى بولسىڭىز،",
		"text8-1": "بۇ يەرگە بايانات تولدۇرۇڭ",
		"text9": "بايانات ئىشتارۇپ قويۇڭ",
		"text10": "فىمىيە/ئۈنۈشى",
		"text11": "ياقىمىلىق شىخ نامىڭىزنى كىرگۈزۈڭ",
		"text12": "ئېلخەت",
		"text13": "ئېلخەتنى كىرگۈزۈڭ",
		"text14": "تېلېفون نومۇرى",
		"text15": "تېلېفون نومۇرىنى كىرگۈزۈڭ",
		"text16": "ئۈنۈش مەلۇماتى",
		"text17": "ئۇچۇرنى تولۇقلاڭ",
		"text18": "تەستىق نومۇرى",
		"text19": "تەستىق نومۇرىنى كىرگۈزۈڭ",
		"text20": "سۆزلۈش",
		"text21": "خەۋەر قىلىپ بېرىڭ",
		"text22": "تاپشۇرۇلدى، تەشەككۈرلەر."
	}
}