export default {
    "box-class-name": "en-no1-box",
    "lang": "en",
    "挑战最强交易环境": "Challenge The Best Trading Environment",
    "利润差额奖金": "Profit Margin Incentive",
    "EBC的最强交易环境等您挑战": "Challenge Yourself in EBC's Top Trading Environment! Successfully complete the challenge to earn a 150% profit margin bonus, with no limits.",
    "距挑战结束剩余": "Time Remaining for the Challenge",
    "天": "D",
    "时": "H",
    "分": "M",
    "秒": "S",
    "本次活动时间为2024年1月1日": "The event time is:1/1/2024 00:00:00 - 30/9/2024 23:59:59（GMT+2）",
    "发起挑战": "Challenge Now",
    "挑战规则": "Challenge Rules",
    "EBC致力于为投资者提供极低交易成本": "EBC is dedicated to provide traders with extremely low trading costs, high-quality market depth, and efficient order execution. To test and enhance our trading environment, we are launching the Ultimate Trading Challenge.",
    "开设账户": "Register an Account",
    "在EBC平台开设一个交易账户": "Register an Account on the EBC Trading Platform",
    "选择平台": "Select Your Platform ",
    "选择一家具备FCA或ASIC监管资质的交易商平台": "Select a Trading Platform Regulated by FCA or ASIC",
    "相同策略交易": "Identical Strategy Trading",
    "使用相同的交易策略在EBC和所选平台进行交易": "Trade with the same strategy on both EBC and your chosen platform.",
    "收益比较": "Profit Comparison",
    "在EBC的盈利低于或亏损高于所选择交易商": "The challenge is considered successful if your profits on EBC are lower or your losses are higher compared to the selected broker.",
    "卓越的交易环境": "Exceptional Trading Environment",
    "EBC以一流的交易执行速度和先进的技术支持": "EBC delivers an exceptional trading experience with top-tier execution speed and advanced technology, meeting the high standards of investors.",
    "科技赋能交易": "Technology-Enhanced Trading",
    "部署于伦敦LD4": "Our independent servers, located in London LD4, New York NY4, Singapore SG1, Tokyo TY3, and Hong Kong HK2, are connected via ultra-fast dedicated lines. They offer ultra-low latency aggregation, smart order routing, and quote optimization engines, creating a global high-speed financial ecosystem.",
    "让85%以上的订单成交于更优的价格": "Achieve Better Prices for Over 85% of Orders",
    "EBC交易黑盒": "EBC's Trading Black Box precisely optimizes order algorithms, surpassing traditional systems in price optimization, order execution rates, and algorithm stability.",
    "EBC交易空间": "EBC Trading Space ",
    "无论您是高频交易者": "Whether you’re a high-frequency trader or handle large orders, EBC’s Private Room offers a tailored trading channel with liquidity matching. It even allows you to conceal portions of your trades to protect your strategy. This ensures your orders receive better aggregated quotes and are shielded from potential targeting during execution.",
    "国际顶级流动性": "Global Top-Tier Liquidity ",
    "EBC与多家国际顶级银行建立了长期稳定的合作关系": "EBC has established long-term, stable partnerships with multiple leading international banks, integrating high-quality liquidity quotes to offer highly competitive market spreads.",
    "活动须知": "Event Guidelines",
    "参与账户": "Eligible Accounts",
    "新用户": "New Users: Open a new account on the official website, then contact online support to activate a VIP account and join the challenge.",
    "现有用户": "Existing Users: Contact EBC support to activate your VIP account and participate in the challenge.",
    "对比交易商平台要求": "Platform Requirement",
    "选择持有英国FCA或澳洲ASIC监管的交易商平台": "Select a trading platform regulated by the UK FCA or the Australian ASIC. Accounts registered under AR, EEA, or Trading Name are not eligible.",
    "交易策略": "Trading Strategy ",
    "仅限以下主流货币对": "Only the following major currency pairs are allowed: AUD/USD, USD/CAD, EUR/USD, USD/CHF, GBP/USD, USD/JPY, and NZD/USD.",
    "在EBC和所选交易商使用相同的交易资金和交易策略": "Use the same trading capital and strategy on both EBC and the selected broker. All strategies must fall within the scope of normal trading practices.",
    "奖金结算": "Bonus Settlement",
    "挑战者在其他平台的交易返佣可计入盈利": "Rebates from other platforms can be counted as profit, but challengers must provide corresponding proof.",
    "挑战者可在任意挑战时间内选择结算": "Challengers can choose to settle at any time during the challenge, with each ID allowed to participate only once.",
    "挑战结束后": "After the challenge ends, submit the closing settlement slip. The bonus will be credited to your trading account within two business days after verification and can be used for trading or withdrawal.",
    "重要声明": "Important Notes",
    "参赛者应知晓交易风险": "Participants should be aware of the trading risks, rules, rights, and product trading rules on the platform. EBC is not responsible for any trading issues or account losses caused by force majeure.",
    "任何不遵守比赛规则或篡改数据的行为": "EBC reserves the right to disqualify participants for any violation of competition rules or data tampering and may pursue legal action.",
    "EBC有权要求参赛者提供有争议的订单或账户的补充材料": "EBC may request additional materials for disputed orders or accounts.",
    "本次挑战赛旨在提供公平": "This challenge aims to provide a fair and just environment. EBC reserves the right to amend, waive, or interpret the competition rules.",
    "EBC有权根据法律法规": "EBC has the right to cancel or terminate the event based on laws, regulations, judicial, or administrative orders.",
    "本活动最终解释权归EBC所有":"The final interpretation of this event belongs to EBC."

}