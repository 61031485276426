export default {
  "box-class-name": "oxford-box-en",
  "button-text": "Find out more",
  "oxford-page1": {
    text1: "WHAT<br> ECONOMISTS<br> REALLY<br> DO",
    text2: "The Economics of Tax Evasion",
    text3: "Sarah Clifford & David Barrett",
    text4: "Reserve Now",
    text5: "6 MARCH 2024 12PM GMT",
  },
  "oxford-page2": {
    text1: "A Thriving Economy Through Education",
    text2:
      "EBC Financial Group Supports Oxford’s Department of Economics CSR Initiatives Spotlighted through the upcoming webinar “What Economists Really Do”",
    text3:
      "In our ongoing commitment to corporate social responsibility (CSR), EBC Financial Group proudly supports initiatives that drive societal progress. Whether it’s supporting education, fostering talent development or promoting environmental sustainability, our commitment to CSR guides us to make a meaningful difference. We believe in leveraging our resources and expertise to create positive change and contribute to a better future for all.",
    text4:
      "Our latest collaboration exemplifies this commitment. We’re proud to announce our partnership with the University of Oxford’s Department of Economics, an institution renowned for its academic excellence and dedication to societal advancement. Through this collaboration, we’re harnessing the power of education to make a lasting impact. Together, we encourage individuals to innovate and drive positive change in communities around the world. Join us on this journey as we aim to create a brighter, more equitable future for generations to come.",
    text5: "EVENT INTRODUCTION",
    text6:
      "Set to take place on 6 March 2024 at 12pm GMT, the webinar on “What Economists Really Do” will delve into the topic of “The Economics of Tax Evasion.” The discussion sheds light on issues the financial industry faces today and how economics and public education can be used to improve policies and facilitate a thriving economy.",
  },
  "oxford-page3": {
    text1: "RELATED PEOPLE",
    text2:
      "Associate Professor of Economics, Department of Economics, the University of Oxford",
    text3:
      "Sarah specializes in public economics with a focus on economic behaviour related to taxation, particularly enforcement. Her research encompasses international corporate taxation, multinational tax avoidance, and personal income tax evasion by households. Her areas of expertise include public economics and applied microeconomics.",
    text4:
      "Professor of Economics，Nuffield College，Associate Head (External Engagement)",
    text5:
      "In Abi’s research on Applied Economics, she often focused on the econmetrics of consumer and family choices. Her research main themes: the development of empirical methods to bring new modes to decision-making to data, expoit large-scale datasets to better understand labour markets, and developing frameworks for quantifying access to justice in the UK legal system.",
    text6: "CEO, EBC Financial Group (UK) Ltd.",
    text7:
      "Mr. Barrett has over 35 years of expertise in foreign exchange, fixed income, and derivatives, gained from Tier One Institutions to smaller business ventures. With a comprehensive understanding of economic cycles and regulatory environments, he offers invaluable insights into risk management and business strategy, having navigated through global financial crises and regulatory changes.",
  },
  "oxford-page4": {
    text1: "About EBC Financial Group",
    text2:
      "Founded in London, EBC Financial Group offers innovative trading services in forex, commodities and indices with integrity and a client-centric focus. Recognised with multiple awards, our dedicated services drive client’s growth through advanced infrastructure, proprietary tools and liquidity access swift order execution and 24/7 customer support.<br><br>With an extensive international footprint, we navigate local markets keenly whilst upholding integrity and ethical practices. For those seeking a dedicated partner with a client-first philosophy, EBC Financial Group emerges as a clear choice in the dynamic world of finance.<br><br>Exceptional Brilliance Care for Every Committed Trader.",
    text3: "About Department of Economics, the University of Oxford",
    text4:
      "The University of Oxford’s Department of Economics is globally renowned for its impactful research, influential policy contributions, vibrant early-career community, and esteemed undergraduate and graduate programmes, making it one of the largest and most diverse academic economist communities.",
  },
  lang: "en",
  经济学家都干了什么: "",
  如何利用气候经济学: "Sustaining Sustainability: Balancing ",
  挖掘投资机遇: "Economic Growth and Climate Resilience",
  "2024年11月14日": "14 November 2024 14:30 (GMT)",
  "牛津大学Michael Dummett讲座厅":
    "Michael Dummett Lecture Hall, Oxford University",
  预约报名: "Register Now",
  EBC邀您游览牛津大学: `Join us at Oxford's "What Economists Really Do" to forge a sustainable economy together `,
  EBC金融集团再次携手牛津大学: `As part of our continued collaboration with the University of Oxford, EBC Financial Group is proud to partner with its Department of Economics to be a sponsor for the 4th edition of "What Economists Really Do" (WERD). Join us for our first-ever hybrid seminar with the Department of Economics on 14 November 2024, exploring the critical balance between economic growth and climate resilience. This event will also be the first time WERD features a panel discussion and live Q&A session, offering insights from leading experts in the field. `,
  活动介绍: "About the Event",
  "本次线下研讨会将于11月14日14:30": `The event will take place on 14 November 2024, at 14:30 (GMT) at Christ Church College, University of Oxford, with a complimentary live video stream available for global attendees.`,
  EBC金融专家还将与您一同漫步在新学院的回廊: `During the UN Global Compact Leaders' Summit 2024, CEO Sanda Ojiambo highlighted the transformative potential of 200 trillion USD in global private financial assets, emphasising the need for innovative mechanisms to unlock this capital, particularly in emerging markets with financing gaps. The event aims to advance the dialogue on how capital markets can deliver economic returns and long-term environmental resilience, echoing the UN's call for the financial sector to lead in creating a sustainable future.`,
  与会嘉宾: "Guest Speakers",
  牛津大学环境复原力及马丁系统韧性负责人:
    "Head of Environmental Resilience and Martin Systems at the University of Oxford",
  "Nicola Ranger是牛津大学环境复原力及马丁系统韧性负责人": `Nicola Ranger leads Environmental Resilience and Martin Systems Resilience at the University of Oxford. She founded the Global Finance and Economics Group at the Environmental Change Institute, collaborating with financial institutions and governments to advance research at the intersection of finance and climate. Ranger has developed stress testing methods with the IMF, World Bank, NGFS, and the UK Climate Financial Risk Forum. She also advises the EU Commission's EMDE Sustainable Investment High-Level Expert Group, the UK Green Tech (Taxonomy) Advisory Group/LNAS, FCDO, and the London School of Economics. `,
  查看更多: "Find Out More",
  牛津大学经济学系教授:
    "Professor of Economics of the University of Oxford",
    牛津大学经济学系教授2: "Professor of Economics at the University of Oxford",
  "Andrea Chiavari 是牛津大学经济学系教授": `Andrea Chiavari is a professor at the University of Oxford's Department of Economics, specialising in macroeconomics with a focus on corporate dynamics and technological change. He explores how economic models can foster sustainable development, studying climate change's impact on corporate productivity and capital allocation, while enhancing economic efficiency and resilience in uncertain environments. `,
  "Banu Demir Pakel 是牛津大学经济学系教授": `Banu is a professor of economics at the University of Oxford, specialising in the intersection of international trade and development economics. Her research examines how firms adjust to trade-related shocks and how these adjustments influence aggregate economic outcomes like growth and income. She is a research affiliate of the CEPR (International Trade and Regional Economics Programme) and an affiliate of the CESifo Research Network in Munich.`,
  "EBC金融集团（英国）首席执行官": "CEO of EBC Financial Group (UK) Ltd",
  "David Barrett是EBC 金融集团 (英国) CEO": `David is the CEO of EBC Financial Group (UK) Ltd, with over thirty-five years of experience in Foreign Exchange, Fixed Income, Commodities, Derivatives, and CFDs. While he has spent much of his career in Tier One Institutions, he has also specialised in building and restructuring smaller businesses. This background has equipped him with extensive knowledge in strategy, business management, product development, derivative structuring, funding, and oversight in these areas. `,
  往期回顾: "Past Episode: Uncover the Reality of Tax Evasion",
  EBC金融集团联合牛津大学经济学系第一期: `EBC Financial Group co-hosted the episode of "The Economics of Tax Evasion" of "What Economists Really Do" with the Department of Economics at the University of Oxford. `,
  "2024年3月6日EBC与牛津大学联合举办主题为": `EBC Financial Group partnered with the Department of Economics at the University of Oxford to present the "Economics of Tax Evasion" episode in the last season of "What Economists Really Do" on 6 March 2024. <br/>

The webinar examined how the tax system impacts economic development and the role of macroeconomists in protecting the global economy. `,
  关于EBC金融集团: "About EBC Financial Group",
  "EBC Financial Group 成立于英国伦敦": `Founded in the esteemed financial district of London, EBC Financial Group (EBC) is renowned for its comprehensive suite of services that includes financial brokerage, asset management, and comprehensive investment solutions. EBC has quickly established its position as a global brokerage firm, with an extensive presence in key financial hubs such as London, Hong Kong, Tokyo, Singapore, Sydney, the Cayman Islands, and across emerging markets in Latin America, Southeast Asia, Africa, and India. <br/>
  
EBC Financial Group’s subsidiaries are licensed and regulated in multiple jurisdictions: EBC Financial Group (UK) Limited is regulated by the UK's Financial Conduct Authority (FCA), EBC Financial Group (Cayman) Limited is regulated by the Cayman Islands Monetary Authority (CIMA), EBC Financial Group (Australia) Pty Ltd, and EBC Asset Management Pty Ltd are regulated by Australia's Securities and Investments Commission (ASIC).<br/> 
At the core of EBC Group are seasoned professionals with over 30 years of profound experience in major financial institutions, having adeptly navigated through significant economic cycles from the Plaza Accord to the 2015 Swiss franc crisis.<br/>
EBC is the Official Foreign Exchange Partner of FC Barcelona and a partner of United to Beat Malaria, a United Nations Foundation campaign focused on improving global health outcomes. `,
  关于牛津大学经济学系:
    "About the Department of Economics, University of Oxford",
  牛津大学是享誉全球的顶尖高等学府: `The University of Oxford is a world-renowned institution and one of the largest research centres in the UK. With nearly a thousand years of history, it fosters an open academic environment and advances society through groundbreaking economic research and international collaborations. `,
  牛津大学经济学系以其专业权威的学术研究: `The Department of Economics at the University of Oxford is renowned for its research and contributions to global economic and public policy. It features a diverse community of economists dedicated to fostering an inclusive academic environment and driving economic progress through innovation.`,
};
