export default{
	"box-name": "",
	"public-btn-text": "即刻開戶",
	"screen0": {
		"title": "EBC為機構投資者<br>提供全球頂級流動性",
		"enTitle": "EBC THE FUTURE",
		"list": [{
				"title": "安全性",
				"desc": "通過對等網絡的光纖專線連結全球Equinix,實現20毫秒的極速訂單執行。"
			},
			{
				"title": "合規性",
				"desc": "我們的資金託管在世界頂級銀行,受到嚴格監管。"
			},
			{
				"title": "流動性",
				"desc": "EBC與多家國際頂級銀行建立了長期穩定的合作關係,讓您輕鬆享有超低交易成本。"
			}
		]
	},
	"screen1": {
		title: '流動性深度',
		"trList": [
			"層級",
			"買入價",
			"買入的交易量",
			"點差",
			"賣出價",
			"賣出價交易量"
		],
		"mobileTrList": [
			"層級",
			"買入價",
			"點差",
			"賣出價"
		]
	},
	"screen2": {
		"pageEnTitle": "Clearing System",
		"pageTitle": "清算系統",
		"title": "EBC安全、透明、可信的清算生態系統"
	},
	"screen3": {
		"pageEnTitle": "EBC Priviate Room",
		"pageTitle": "EBC交易空間",
		"title": "“清算不是統一的標準,而是適合所有情况的所有答案”",
		"desc": "如果您是一比特“大交易家”,您不想在市場上讓別人知道您的“底牌”,EBC的Private Room計畫將會是您最優之選。"
	},
	"screen4": {
		"title": "掌舵你的專屬流動性<span>輕鬆駕馭全球市場</span>",
		"desc": "Private Room可以隱藏您的部分交易資訊,意味著您下的“大單”可以在一個更好的價格成交,而不存在嚴重的滑點或是因最後觀望價格（LastLook）而遭到訂單拒絕。如圖所示,通過Private Room交易的訂單成交價格更優。"
	},
	"screen5": {
		"it":"唯有時間，讓您，更了解我",
		"ct": "*交易中可能會有滑點、有延遲有各種不確定性因素,或許資深交易人士的您不會注意到這些細微之處,但如有任何對您產生的不便,請及時回饋我們。EBC宣導高品質與極客精神,積極擁抱不確定性的未來。力求為客戶帶來極簡、純淨、舒適的交易新體驗。",
		"title": "持續創造超預期的用戶體驗",
		"desc": "我們積極傾聽您的意見,並且非常重視增强您的個性化交易體驗。EBC開發團隊始終致力於研發和改進新功能新技術,以進一步優化客戶的線上交易體驗。",
		"btn": "瞭解EBC優勢"
	}
}
