export default {
    "box-class-name": "cn-box",
    "lang": "cn",
    "MetaTrader 5 (MT5) 是新一代多资产交易平台": "MT5是新一代多资产交易平台，集成了更先进的交易功能和分析工具，满足全球交易者的多样化需求。",
    "提供市场深度信息": "多资产交易，连接全球市场",
    "支持6种挂单交易类型": "6种挂单类型，灵活掌控风险",
    "内置经济日历": "先进市场深度，追踪订单流动",
    "立即注册": "立即注册",
    "MetaTrader 5下载": "MetaTrader 5下载",
    "MT5移动版": "MT5移动版",
    "随时随地进行交易": "随时随地进行交易，掌握市场动态，轻松管理账户。",
    "下载Android版": "下载Android版",
    "下载IOS版": "下载IOS版",
    "下载iPad版": "下载iPad版",
    "MT5桌面版": "MT5桌面版",
    "全面的交易工具和高级图表分析": "全面的交易工具和高级图表分析，满足专业交易者的高阶需求。",
    "下载Windows版": "下载Windows版",
    "下载MacOS版": "下载MacOS版",
    "MT5网页版": "MT5网页版",
    "无需下载": "无需下载，极速访问全球市场，轻松实现全方位的在线交易管理。",
    "打开网页版": "打开网页版",
    "MT4与MT5的区别": "MT4与MT5的区别",
    "MT4和MT5均由MetaQuotes开发": "MT4和MT5均由MetaQuotes公司开发，但MT5并不是MT4的简单升级，MT5在功能和适用范围上有显著扩展",
    "项目": "项目",
    "发布时间": "发布时间",
    "2005年": "2005年",
    "2010年": "2010年",
    "交易市场": "交易市场",
    "主要是外汇": "主要是外汇",
    "包括股票": "包括股票、期货、外汇等",
    "挂单类型": "挂单类型",
    "4种": "4种",
    "6种": "6种",
    "时间框架": "时间框架",
    "9种": "9种",
    "21种": "21种",
    "技术指标": "技术指标",
    "30个": "30个",
    "38个": "38个",
    "市场深度":"市场深度",
    "不支持":"不支持",
    "支持":"支持",
    "财经日历":"财经日历",
    "内置":"内置",
    "操作界面":"操作界面",
    "更直观简单":"更直观简单",
    "更加现代化灵活":"更加现代化灵活"
}