export default {
	"cfa1": {
		"title": "เทรดเดอร์มืออาชีพทุกคนสมควรได้รับการดูแลอย่างใกล้ชิดจากเรา",
		"h1": "Creator Incentive Programme",
		"h1wap": "Creator Incentive Programme",
		"text": "EBC สนับสนุนการผลิตเนื้อหาคอนเทนต์เกี่ยวกับด้านการเงิน",
		"text1": "EBC จัดโปรแกรมสร้างแรงบันดาลให้เหล่าครีเอเตอร์ขึ้นมา เพื่อโปรโมตคอนเทนต์ให้โดนใจเหล่านักเทรดมืออาชีพ",
		"text2": "โบนัสสุดพิเศษสำหรับครีเอเตอร์",
		"list": ["กฎระเบียบระดับสากล","EBC Million Dollar Challenge","สภาพแวดล้อมการเทรดที่มีการแข่งขันสูง","เครื่องมือ Order Flow","สภาพคล่องสูง","สมาคมการเทรด","ดําเนินการคําสั่งระดับมิลลิวินาที","ให้การสนับสนุนด้านช่องทางสื่อ","Swap Free (ตามระยะเวลาที่กำหนด)"],
		"list1":["เรียนรู้จากมืออาชีพ","วิเคราะห์แบบเรียลไทม์","วิเคราะห์ตัวชี้วัดทางเศรษฐกิจ","หลักสูตรที่คุ้มค่า"]
	},
	"cfa2":"诚邀您加入",
	"cfa3":{
		"title":"ลำดับการรับรางวัล",
		"tips":"รางวัล (USD)",
		"tips1":"ค่าเฉลี่ยสำหรับการอ้างอิงเท่านั้น",
		"reward_list": [360, 880, 1800, 3200, 5400, 9000, '11000+'],
		"reward_list_m": ['11000+', 9000, 5400, 3200, 1800, 880, 360],
	},
	"cfa4":"สถาบัน EBC",
	"cfa5":"*คุณสมบัติในการสมัคร ครีเอเตอร์จะต้องมีคอลัมน์เพื่อสร้าง เนื้อหาคอนเทนต์ที่มีคุณภาพ เช่น การวิเคราะห์ตลาดและคําแนะนําการซื้อขาย รวมถึงแบบฟอร์ม แต่ไม่จํากัดบทความ รายงานหรือหลักสูตร<br />*หากเกิดกรณีที่ครีเอเตอร์ส่งผลงาน แล้วถูกค้นพบว่ามีการลอกเลียนแบบจะถูกตัดสิทธิ์<br />*ครีเอเตอร์สามารถสมัครรับรางวัลโบนัสได้ หากเนื้อหาที่ส่งเข้าประกวดมาถูกต้องตรงตามข้อกําหนด<br />*รางวัลจะถูกแจกจ่ายเป็นรายเดือน<br />*สนใจสมัครติดต่อ AM หรืออีเมลไปที่ mkt@ebc.com<br />*ครีเอเตอร์ทุกคนที่ละเมิดกฎจะถูกตัดสิทธิ์และรางวัลทั้งหมดจะถูกเรียกคืน<br />*EBC ขอสงวนสิทธิ์ในการเปลี่ยนแปลงได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า",
	"cfa6":{
		"title":"เทรดเดอร์มืออาชีพทุกคนสมควรได้รับการดูแลอย่างใกล้ชิดจากเรา",
		"titlewap":"สนใจสมัครติดต่อ AM หรืออีเมลไปที่ mkt@ebc.com",
		"desc":"EBC Financial Group (UK) Ltd <br />ได้รับการควบคุมและการกำกับดูแลโดย FCA <br />หมายเลขอ้างอิง 927552",
		"desc1":"EBC Financial Group (Australia) Pty Ltd<br />ได้รับการควบคุมและการกำกับดูแลโดย ASIC<br />หมายเลขอ้างอิง 500991"
	},
	"lang": "en"
}