export default {
  "screen0": {
    "title": "تارىخنى ئۆرنەك قىلىپ، كەلگۈسىگە نەزەر سېلىش ",
    "desc": "EBCكۆپ ئۆلچەملىك سانلىق مەلۇمات مەركىزى، دۇنيادىكى ئاساسىي ئېقىمدىكى مۈلۈك تۈرىدىن 10 نەچچە خىلنى ئۆز ئىچىگە ئالىدۇ ",
    "list": [
      "100 يىللىق پۇل پاخاللىقى تەڭشەلگەندىن كېيىنكى ئاساسلىق بازار كۆرسەتكۈچى ",
      "20 يىلدىن ئېشىپ كەتكەن ئاساسلىق پۇل بازىرى ئۆزگىرىپ تۇرىدۇ. ",
      "يېرىم ئەسىردىن كۆپرەك قىممەتلىك مېتاللارنىڭ دەھشەتلىك دولقۇنىنى باشتىن كەچۈردى. ",
      "1946-يىلىدىن باشلاپ خام نېفىتنىڭ تارىخىي ئۆرلەش-چۈشۈش ئەھۋالى كۆرۈلگەن ",
      "يېقىنقى 23 يىلدا شىمالىي ئامېرىكا تەبىئىي گاز بازىرىدىكى داۋالغۇش "
    ],
    "text": "ئۆتمۈش ۋە كەلگۈسى <br />ھەممە نەرسە سىزنىڭ كونتروللىقىڭىزدا "
  },
  "screen1": {
    "pageEnTitle": "Dow Jones - DJIA - 100 Year Historical Chart",
    "pageTitle": "داۋجونېس كۆرسەتكۈچى (DJIA) نىڭ 100 يىللىق تارىخىي ئىسخېمىسى ",
    "title": "ئۆتكەن 100 يىلدىن بۇيانقى دوۋ جونېس سانائەت ئوتتۇرىچە (DJIA) كۆرسەتكۈچ تارىخىي گىرافىكى ",
    "list": [
      "تارىخىي سانلىق مەلۇماتلاردا پۇل پاخاللىقى تەڭشەلگەندىن كېيىنكى CPI سانلىق مەلۇماتى ئىشلىتىلگەن بولۇپ، ھەر بىر سانلىق نۇقتا ئاي ئاخىرىدىكى ئاخىرقى باھاغا ۋەكىللىك قىلىدۇ ",
      "نۆۋەتتىكى ئايلىق باھا ئەڭ يېڭى سائەتلىك ئاخىرقى باھانى ئۆلچەم قىلىدۇ.",
      "2020-يىلى 10-ئاينىڭ 20-كۈنىگىچە، داۋجېئانىس سانائەت ئوتتۇرىچە كۆرسەتكۈچىنىڭ نۆۋەتتىكى باھاسى 28، 308.79 "
    ]
  },
  "screen2": {
    "pageEnTitle": "NASDAQ Composite - 45 Year Historical Chart",
    "pageTitle": "ناسداك ئۇنىۋېرسال كۆرسەتكۈچى ئۆتكەن 45 يىللىق تارىخ ئىسخېمىسى ",
    "title": "1971-يىلىدىن بۇيانقى ناسداك ئۇنىۋېرسال كۆرسەتكۈچىنىڭ تارىخىي خەرىتىسى ",
    "list": [
      "تارىخىي سانلىق مەلۇماتلاردا پۇل پاخاللىقى تەڭشەلگەندىن كېيىنكى CPI سانلىق مەلۇماتى ئىشلىتىلگەن بولۇپ، ھەر بىر سانلىق نۇقتا ئاي ئاخىرىدىكى ئاخىرقى باھاغا ۋەكىللىك قىلىدۇ ",
      "نۆۋەتتىكى ئايلىق باھا ئەڭ يېڭى سائەتلىك ئاخىرقى باھانى ئۆلچەم قىلىدۇ.",
      " بۇ2020-يىلى 10- ئاينىڭ 20- كۈنىگىچە ناسداك ئۇنىۋېرسال كۆرسەتكۈچىنىڭ نۆۋەتتىكى باھاسى 11، 516.49 "
    ],
    "text": "پاي چېكى باھا پەرقى توختامىنى چۈشىنىش",
    "btn": "ئۆگىنىش مەركىزى lab"
  },
  "screen3": {
    "pageEnTitle": "EUR USD- Historical Chart",
    "pageTitle": "ياۋرونىڭ دوللارغا ئايرىۋاشلىنىش نىسبىتى تارىخى خەرىتىسى ",
    "title": "بۇ1999-يىلىدىن بۇيانغا ياۋرونىڭ ئامېرىكا دوللىرى ئايرىۋاشلىنىدىغان كۈندىلىك سۈرەت خەرىتىسىنىڭ باھا يۈزلىنىشى "
  },
  "screen4": {
    "pageEnTitle": "GBP USD - Historical Chart",
    "pageTitle": "فوند ستېرلىڭنى دوللارغا ئايرىۋاشلاش پېرېۋوت نىسبىتى تارىخىي دىئاگراممىسى ",
    "title": "بۇ1971-يىلىدىن بۇيان، ئەنگىلىيە فونىستىرلىڭنى ئامېرىكا دوللىرىغا ئايرىۋاشلاش كۈندىلىك خەرىتىسىنىڭ باھا يۈزلىنىشى ",
    "text": "تاشقى پېرېۋوت سودىسىنى چۈشىنىش",
    "btn": "Lab ئۆگىنىش مەركىزى تاشقى پېرېۋوت سودىسى"
  },
  "screen5": {
    "pageEnTitle": "Gold Price - Last 10 Years",
    "pageTitle": "ئۆتكەن ئون يىلدا ئالتۇن باھاسى ",
    "title": "ئۆتكەن 10 يىلدا ئامېرىكا دوللىرى بىلەن ھېسابلىنىدىغان ئالتۇن باھاسىنىڭ يۈزلىنىشى ",
    "list": [
      "بۇ2020-يىلى 19-ئۆكتەبىرگىچە، نۆۋەتتىكى باھاسى 911.70 1دوللار"
    ]
  },
  "screen6": {
    "pageEnTitle": "WTI Crude Oil Prices - 10 Year Daily Chart",
    "pageTitle": " خام نېفىت باھاسىنىڭ 10 يىللىق كۈندىلىك سىزىقى خەرىتىسى يۈزلىنىشى ",
    "title": "ئۆتكەن 10 يىلدا غەربىي تېكساس ئوتتۇرا سۈپەتلىك خام نېفىتىنىڭ كۈندىلىك ئاخىرقى باھاسى ",
    "list": [
      "كۆرسىتىلگەن باھا ئامېرىكا دوللىرى بويىچە ھېسابلىنىدۇ",
      "2020-يىلى 10-ئاينىڭ 20-كۈنىگىچە، WTI خام نېفىتىنىڭ نۆۋەتتىكى ھەر تۇڭىنىڭ باھاسى 41.65 ئامېرىكا دوللىرى "
    ]
  },
  "screen7": {
    "pageEnTitle": "Natural Gas Prices - Historical Chart",
    "pageTitle": "تەبىئىي گاز باھاسىنىڭ تارىخىي دىئاگراممىسى ",
    "title": "ھېنرى تۈگۈنى تەبىئىي گازىنىڭ تارىخىي باھاسى ",
    "list": [
      "كۆرسىتىلگەن باھا ئامېرىكا دوللىرى بويىچە ھېسابلىنىدۇ",
      "2020-يىلى 10-ئاينىڭ 14-كۈنىگىچە، تەبىئىي گازنىڭ نۆۋەتتىكى باھاسى 2.02 ئامېرىكا دوللىرى"
    ],
    "text": "كۆپ مىقدارلىق تاۋارنى چۈشەنگىڭىز بارمۇ",
    "btn": "Lab ئۆگىنىش مەركىزى/ كۆپ مىقدارلىق تاۋار"
  }
}
