export default {
  "screen0": {
    "title": "Legal Document",
    "tit": "Legal Document",
    "list": [
      "Email Support",
      "Live Chat Support"
    ]
  },
  "screen1": {
    "list": [{
      "title": "1. Website Terms and Conditions",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Website-Terms-and-Conditions.pdf"
    }, {
      "title": "2. Risk Acknowledgement And Disclosure Notice",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Risk-Acknowledgement-And-Disclosure-Notice.pdf"
    }, {
      "title": "3. Privacy Policy",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Privacy-Policy.pdf"
    }, {
      "title": "4. Execution Policy",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Execution-Policy.pdf"
    }],
    "tit": "What are you looking for?",
    "name": "Search Website",
    "placeholder": "Type your question here...",
    "btn": "Search",
    "text": "Contact our Customer Service Specialist",
    "btn1": "Contact"
  }
}
