export default {
    screen0: {
        title: '每个认真交易的人，都值得被认真对待。',
        enTitle: 'Trust & Respect',
        btn: '观看全视频'
    },
    screen1: {
        line1: '您可信赖的合作伙伴',
        line3: '<span>交易世界上</span>最受欢迎的资产',
        line4: '风险提示：差价合约（CFDs）是一种专业且复杂的金融交易产品，由于杠杆原因会带来较大的损失风险。全球75%的投资者曾出现过亏损。请确保您完全知晓和理解该项投资的性质和所涉及的风险，再来了解我们。',
        list: [{
            enTitle: 'Derivatives',
            title: '现货指数差价合约',
            desc: '最低0点差即可交易36种全球最受欢迎的主要、次要及异国货币兑',
            more: '了解更多'
        }, {
            enTitle: 'Index CFDs',
            title: '货币交易',
            desc: 'EBC为您甄选一系列顶级指数，<br />积极探索全球经济中最有价值和最活跃的领域',
            more: '了解更多'
        }, {
            enTitle: 'Commodities',
            title: '大宗商品',
            desc: '如果您考虑投资组合多元化，<br />那么避险资产（如贵金属）、<br />能源市场（如原油、天然气）会是您的理想选择',
            more: '了解更多'
        }, {
            enTitle: 'Share CFDs',
            title: '股票差价合约',
            desc: '交易全球成熟和新兴市场 36 家交易所股票，实时弹性定价，与实际股市表现挂钩',
            more: '了解更多'
        }]
    
    },
    screen2: {
        title1: '<em>EBC坐拥</em>全球<span>两大顶级监管</span>',
        title2: '<em>EBC坐拥全球</em><span>两大顶级监管</span>',
        desc: '您可享受诚信、安全<br />和性能标准的机构级别交易环境',
        list: [{
            title: '英国 FCA 监管',
            desc: 'EBC Financial Group (UK) Ltd 由英国金融行为监管局(FCA)授权和监管。<br />监管号:927552'
        }, {
           title: '澳洲 ASIC 监管',
           desc: 'EBC Global Pty Ltd由澳大利亚证券和投资委员会(ASIC)授权和监管。<br />监管号:500991'
        }]
    },
    screen3: {
        line1: 'Your Faithful Partner',
        line3: '<span>Driving you</span>to succeed',
        line4: '专业领先的金融科技，让交易无限可能。',
        line5: '部署于<br />伦敦LD5; 纽约NY4; 新加坡SG1; 东京TY3; 香港HK2',
        line6: '超高速专线连接的独立服务器',
        list: [{
            num: '1000',
            text: '每秒最高聚合订单'
        }, {
            num: '5×24',
            text: 'VIP尊享服务'
        }, {
            num: '50+',
            text: '交易品种'
        }, {
            num: '20+',
            text: '覆盖全球超高性能数据中心（DC）'
        }, {
            num: '100+',
            text: '全球成员超过'
        }, {
            num: '98.75%',
            text: '数据传输稳定高达'
        }, {
            num: '0.0pips',
            text: '同业银行级别的RAW ECN点差'
        }, {
            btn: '探索科技'
        }]
    },
    screen4: {
        enTitle: 'Discover Prices & Transfer Orders',
        title: '价格的发现<br />与订单的传输',
        desc: 'EBC通过fix（金融信息交换协议）将接入的银行间报价进行聚合，为客户提供一级流动性场所的直接市场准入，通过专用物理HUB硬件聚合后的报价具有更低的点差和可持续交易的深度。',
        line1: 'EBC Trading Black-Box',
        line3: '<em>交易黑盒</em>精准优化抛单算法',
        line4: '让85%以上的订单成交于更优的价格',
        list: ['交易黑盒', '交易分布图', '经过实验，我们可以发现：<br />在价格优化率、订单成交率以及算法稳定性方面，EBC交易黑盒远远优于传统交易系统'],
        rightDesc: 'EBC通过fix（金融信息交换协议）将接入的银行间报价进行聚合，为客户提供一级流动性场所的直接市场准入，通过专用物理HUB硬件聚合后的报价具有更低的点差和可持续交易的深度。',
        more: '了解更多',
        btn: '开始投资'
    },
    screen5: {
        btn: '开始投资',
        title: 'EBC交易空间',
        enTitle: 'EBC Priviate Room',
        desc: '<span>“清算不是统一的标准，</span>而是适合所有情况的所有答案”',
        textList: ['Private Room 可以隐藏您的部分交易信息，意味着您下的“大单”可以在一个更好的价格成交，而不存在严重的滑点或是因最后观望价格(Last Look)而遭到订单拒绝。', '如果您是一位“大交易家”，您不想在市场上让别人知道您的“底牌”，EBC 的 Private Room 计划将会是您最优之选。'],
        ftTitle: 'EBC THE FUTURE',
        ftDesc: '<i><span>EBC</span>面向机构投资者</i>提供一个真正的全球<em>顶级流动性</em>',
        ftBtn: '与我们合作',
        ftList: [{
            title: '安全性',
            desc: '通过对等网络的光纤专线链接全球Equinix，实现20毫秒的极速订单执行。'
        }, {
            title: '合规性',
            desc: '我们的资金托管在世界顶级银行，受到严格监管。'
        }, {
            title: '流动性',
            desc: 'EBC与多家国际顶级银行建立了长期稳定的合作关系，让您轻松享有超低交易成本。'
        }, {
            title: '合作伙伴'
        }],
        ftBtn: '与我们合作'
    }
}