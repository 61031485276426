export default {
  "box-class-name": "oxford-box-en",
  "button-text": "Tìm hiểu thêm",
  "oxford-page1": {
    text1: "WHAT ECONOMISTS REALLY DO",
    text2: "KINH TẾ HỌC TRÁNH THUẾ",
    text3: "Sarah Clifford & David Barrett",
    text4: "Tìm hiểu thêm",
    text5: "12 GIỜ TRƯA GMT NGÀY 6 THÁNG 3 NĂM 2024",
  },
  "oxford-page2": {
    text1: "NỀN KINH TẾ PHÁT TRIỂN DỰA VÀO GIÁO DỤC",
    text2:
      "TẬP ĐOÀN TÀI CHÍNH EBC HỖ TRỢ CÁC SÁNG KIẾN CSR CỦA KHOA KINH TẾ OXFORD, NỔI BẬT QUA HỘI THẢO TRỰC TUYẾN SẮP TỚI “CÁC NHÀ KINH TẾ THỰC SỰ LÀM GÌ”",
    text3:
      "Trong cam kết liên tục của chúng tôi về trách nhiệm xã hội của doanh nghiệp (CSR), Tập đoàn Tài chính EBC tự hào hỗ trợ các sáng kiến thúc đẩy tiến bộ xã hội. Cho dù đó là hỗ trợ giáo dục, bồi dưỡng phát triển tài năng hay thúc đẩy sự bền vững về môi trường, cam kết của chúng tôi đối với CSR sẽ hướng dẫn chúng tôi tạo ra sự khác biệt có ý nghĩa. Chúng tôi tin vào việc tận dụng nguồn lực và kiến thức chuyên môn của mình để tạo ra sự thay đổi tích cực và đóng góp vào một tương lai tốt đẹp hơn cho tất cả mọi người.",
    text4:
      "Sự hợp tác mới nhất của chúng tôi minh chứng cho cam kết này. Chúng tôi tự hào tuyên bố hợp tác với Khoa Kinh tế của trường Đại học Oxford, một ngôi trường nổi tiếng về sự xuất sắc trong học tập và sự cống hiến cho sự tiến bộ xã hội. Thông qua sự hợp tác này, chúng tôi đang khai thác sức mạnh của giáo dục để tạo ra tác động lâu dài. Cùng nhau, chúng tôi khuyến khích các cá nhân đổi mới và thúc đẩy sự thay đổi tích cực trong cộng đồng trên toàn thế giới. Hãy tham gia cùng chúng tôi trên hành trình này vì chúng tôi mong muốn tạo ra một tương lai tươi sáng hơn, công bằng hơn cho các thế hệ mai sau.",
    text5: "GIỚI THIỆU SỰ KIỆN",
    text6:
      "Dự kiến diễn ra vào ngày 6 tháng 3 năm 2024 lúc 12 giờ trưa GMT, hội thảo trực tuyến về “Các nhà kinh tế thực sự làm gì” sẽ đi sâu vào chủ đề “Kinh tế học tránh thuế”. Cuộc thảo luận làm sáng tỏ các vấn đề mà ngành tài chính phải đối mặt ngày nay và cách sử dụng kinh tế và giáo dục công để cải thiện chính sách và tạo điều kiện cho một nền kinh tế thịnh vượng.",
  },
  "oxford-page3": {
    text1: "NHỮNG NGƯỜI LIÊN QUAN",
    text2: "Phó Giáo sư Kinh tế, Khoa Kinh tế, Đại học Oxford",
    text3:
      "Sarah chuyên về kinh tế công cộng, tập trung vào hành vi kinh tế liên quan đến thuế, đặc biệt là việc thực thi. Nghiên cứu của cô bao gồm thuế doanh nghiệp quốc tế, tránh thuế đa quốc gia và trốn thuế thu nhập cá nhân của các hộ gia đình. Lĩnh vực chuyên môn của cô bao gồm kinh tế công cộng và kinh tế vi mô ứng dụng.",
    text4:
      "Giáo sư Kinh tế, Cao đẳng Nuffield, Phó Trưởng phòng (Hợp tác đối ngoại)",
    text5:
      "Trong nghiên cứu của Abi về Kinh tế Ứng dụng, cô thường tập trung vào kinh tế lượng về sự lựa chọn của người tiêu dùng và gia đình. Các chủ đề chính trong nghiên cứu của cô bao gồm: phát triển các phương pháp thực nghiệm để đưa các phương thức mới vào việc ra quyết định vào dữ liệu, khai thác các bộ dữ liệu quy mô lớn để hiểu rõ hơn về thị trường lao động và phát triển các khuôn khổ để định lượng khả năng tiếp cận công lý trong hệ thống pháp luật của Anh.",
    text6: "CEO, EBC Financial Group (UK) Ltd.",
    text7:
      "Ông Barrett có hơn 35 năm chuyên môn về ngoại hối, thu nhập cố định và các công cụ phái sinh, với kinh nghiệm trong công việc có được từ các tổ chức cấp 1 đến các doanh nghiệp nhỏ. Với sự hiểu biết toàn diện về chu kỳ kinh tế và môi trường pháp lý, ông đã cung cấp những hiểu biết có giá trị về quản lý rủi ro và chiến lược kinh doanh nhằm ứng phó với cuộc khủng hoảng tài chính toàn cầu và những thay đổi về quy định.",
  },
  "oxford-page4": {
    text1: "VỀ EBC FINANCIAL GROUP",
    text2:
      "Được thành lập tại London, EBC Financial Group cung cấp các dịch vụ giao dịch về ngoại hối, hàng hóa và chỉ số với sự chính trực và tập trung vào khách hàng. Được công nhận với nhiều giải thưởng, dịch vụ tận tâm của chúng tôi thúc đẩy tăng trưởng khách hàng thông qua cơ sở hạ tầng tiên tiến, các công cụ chuyên dụng và khả năng truy cập thanh khoản, khớp lệnh nhanh và hỗ trợ khách hàng 24/7.<br><br>Với dấu ấn quốc tế rộng khắp, chúng tôi điều hướng thị trường địa phương một cách nhạy bén và duy trì tính liêm chính và đạo đức. Đối với những người đang tìm kiếm một đối tác tận tâm với triết lý đặt khách hàng lên hàng đầu, EBC Financial Group là một lựa chọn tuyệt vời trong thế giới tài chính năng động.<br><br>Cung cấp cho mỗi nhà giao dịch trung thành sự chăm sóc vượt trội và xuất sắc.",
    text3: "VỀ KHOA KINH TẾ, ĐẠI HỌC OXFORD",
    text4:
      "Khoa Kinh tế của Đại học Oxford nổi tiếng toàn cầu nhờ nghiên cứu có tác động, đóng góp chính sách có ảnh hưởng, cộng đồng khởi nghiệp sôi động và các chương trình đại học và sau đại học được đánh giá cao, khiến trường trở thành một trong những cộng đồng kinh tế học thuật lớn nhất và đa dạng nhất.",
  },
  lang: "vn",
  经济学家都干了什么: "Các nhà kinh tế thực sự làm gì?",
如何利用气候经济学: "Làm thế nào để khai thác cơ hội đầu tư thông qua kinh tế học khí hậu?",
挖掘投资机遇: "",
"2024年11月14日": "14/11/2024 14:30 (GMT)",
"牛津大学Michael Dummett讲座厅": "Giảng đường Michael Dummett, Đại học Oxford.",
预约报名: "ĐĂNG KÝ NGAY",
EBC邀您游览牛津大学: "EBC mời bạn tham quan Đại học Oxford và tham gia Hội thảo Toàn cầu",
EBC金融集团再次携手牛津大学: `EBC Financial Group một lần nữa hợp tác với Đại học Oxford để tổ chức Hội thảo Toàn cầu vào ngày 14 tháng 11 năm 2024. Đây sẽ là sự kiện Hybrid đầu tiên (kết hợp online và offline) trong quan hệ hợp tác giữa chúng tôi, đồng thời cũng là lần đầu tiên buổi thảo luận "Các nhà kinh tế học đã làm gì?" của Oxford có phần hỏi đáp nổi bật trong các phiên thảo luận bàn tròn. Sự kiện này đánh dấu cột mốc quan trọng trong việc thắt chặt quan hệ đối tác giữa EBC và Đại học Oxford. Hội thảo sẽ tập trung vào chủ đề cân bằng giữa tăng trưởng kinh tế và khả năng chống chịu với biến đổi khí hậu, nhằm khám phá những thách thức của kinh tế học khí hậu và triển vọng đầu tư, hỗ trợ nhà đầu tư tìm kiếm cơ hội tiềm năng.`,
活动介绍: "GIỚI THIỆU SỰ KIỆN",
"本次线下研讨会将于11月14日14:30": `Hội thảo trực tiếp lần này sẽ diễn ra vào lúc 14:30 (GMT) ngày 14 tháng 11 tại trường Đại học Oxford, thuộc Học viện Christ Church, đồng thời được phát trực tiếp cho các nhà đầu tư toàn cầu.`,
EBC金融专家还将与您一同漫步在新学院的回廊:`Các chuyên gia tài chính của EBC sẽ cùng bạn dạo bước trong hành lang của New College, nơi tái hiện những khoảnh khắc ma thuật từ bộ phim "Harry Potter và Chiếc cốc lửa", hoặc bạn có thể đắm mình trong bầu không khí học thuật hàng trăm năm của Oxford tại Thư viện Bodleian, một thánh địa mà mọi học giả trên thế giới đều khao khát đến thăm, để trải nghiệm sự quyến rũ độc đáo của văn hóa Oxford.`,
与会嘉宾: "KHÁCH MỜI",
牛津大学环境复原力及马丁系统韧性负责人:
  "Trưởng bộ phận Khả năng phục hồi môi trường và hệ thống Martin tại Đại học Oxford",
"Nicola Ranger是牛津大学环境复原力及马丁系统韧性负责人": `Nicola Ranger là người đứng đầu chương trình về Khả năng phục hồi môi trường và hệ thống Martin tại Đại học Oxford. Bà đã thành lập Nhóm Tài chính và Kinh tế Toàn cầu thuộc Viện Nghiên cứu Biến đổi Môi trường của Đại học Oxford và hợp tác chặt chẽ với các tổ chức tài chính, ngân hàng trung ương và chính phủ để thúc đẩy các nghiên cứu tiên phong trong các lĩnh vực giao thoa giữa tài chính, khí hậu, tự nhiên và phân tích. Ranger đã tham gia phát triển các phương pháp kiểm tra khả năng chịu đựng và phân tích kịch bản, bao gồm cả việc hợp tác với IMF, Ngân hàng Thế giới, NGFS và Diễn đàn Rủi ro Tài chính Khí hậu của Anh. Bà cũng giữ vai trò cố vấn cho Ủy ban châu Âu về đầu tư bền vững, Nhóm Tư vấn Công nghệ xanh của Vương quốc Anh, FCDO, và Trường Kinh tế Chính trị London.`,
查看更多: "Tìm hiểu thêm",
牛津大学经济学系教授: "Giáo sư khoa Kinh tế tại đại học Oxford",
"Andrea Chiavari 是牛津大学经济学系教授": `Andrea Chiavari là giáo sư khoa Kinh tế tại Đại học Oxford, chuyên về kinh tế vĩ mô, đặc biệt là động lực doanh nghiệp và sự thay đổi công nghệ. Nghiên cứu của ông khám phá cách các mô hình kinh tế có thể hỗ trợ phát triển bền vững. Ông tập trung nghiên cứu tác động của biến đổi khí hậu đến năng suất doanh nghiệp và phân bổ vốn, đặc biệt là cách nâng cao hiệu quả và khả năng chống chịu của nền kinh tế trong bối cảnh bất định, cũng như vai trò của công nghệ trong việc thúc đẩy tăng trưởng kinh tế.`,
"Banu Demir Pakel 是牛津大学经济学系教授": `Banu Demir Pakel là giáo sư khoa Kinh tế tại Đại học Oxford, chuyên về thương mại quốc tế và kinh tế phát triển. Bà nghiên cứu cách doanh nghiệp thích ứng với các cú sốc thương mại và tác động của những điều chỉnh này đến các kết quả kinh tế vĩ mô, như tăng trưởng kinh tế và mức thu nhập. Bà cũng là thành viên nghiên cứu tại Trung tâm Nghiên cứu Chính sách Kinh tế châu Âu (CEPR) và Mạng lưới nghiên cứu CESifo Munich.`,
"EBC金融集团（英国）首席执行官": "CEO, EBC Financial Group (UK) Ltd",
"David Barrett是EBC 金融集团 (英国) CEO": `David Barrett, CEO của EBC Financial Group (UK), có hơn 35 năm kinh nghiệm trong việc phân bổ tài sản quốc tế và phòng ngừa rủi ro cấu trúc. Ông đã trải qua nhiều giai đoạn thị trường khác nhau và từng dẫn dắt công tác điều hành tại Tập đoàn AIG, một trong những tập đoàn đầu tư lớn nhất thế giới. David đã góp phần thúc đẩy những cải cách quan trọng về cấu trúc sản phẩm và quản lý rủi ro tuân thủ tại Phố Wall, đồng thời xây dựng khung quản lý rủi ro và thanh khoản khả thi cho thời kỳ hậu khủng hoảng tài chính.`,
往期回顾: "Tổng kết kỳ trước",
EBC金融集团联合牛津大学经济学系第一期:
  "EBC Financial Group hợp tác với Khoa Kinh tế Đại học Oxford tổ chức hội thảo kỳ 1: “Các nhà kinh tế thực sự làm gì”",
"2024年3月6日EBC与牛津大学联合举办主题为": `Vào ngày 6 tháng 3 năm 2024, EBC và Đại học Oxford đã phối hợp tổ chức hội thảo với chủ đề “Các nhà kinh tế thực sự làm gì”, tập trung thảo luận cách kinh tế học có thể góp phần cải thiện chính sách và thúc đẩy sự phát triển kinh tế. Hội thảo đi sâu vào “Kinh tế học tránh thuế”, phân tích toàn diện xu hướng kinh tế phát sinh từ các chính sách thuế khác nhau và vai trò của các nhà kinh tế trong việc đảm bảo an ninh kinh tế toàn cầu.`,
关于EBC金融集团: "VỀ EBC FINANCIAL GROUP",
"EBC Financial Group 成立于英国伦敦": `EBC Financial Group được thành lập tại London, Vương quốc Anh, là một tập đoàn tài chính tổng hợp cung cấp các dịch vụ như môi giới tài chính, quản lý tài sản và đầu tư định cư. EBC đã được cấp phép và chịu sự quản lý tại nhiều khu vực pháp lý tài chính quan trọng trên toàn cầu, bao gồm giấy phép toàn diện từ FCA Anh, ASIC Úc và CIMA Cayman. EBC có các chi nhánh và văn phòng tại London, Hồng Kông, Tokyo, Sydney và Cayman.Các thành viên cốt lõi của EBC Group có hơn 30 năm kinh nghiệm làm việc tại các tổ chức tài chính lớn, đã trải qua nhiều chu kỳ kinh tế quan trọng, từ Hiệp định Plaza năm 1985, Khủng hoảng tài chính châu Á năm 1997, Khủng hoảng tài chính toàn cầu năm 2008 đến sự kiện "Thiên nga đen" của đồng franc Thụy Sĩ năm 2015.EBC là đối tác chính thức trong lĩnh vực tài chính của Câu lạc bộ Bóng đá Barcelona, đồng thời hợp tác cùng Quỹ Liên Hợp Quốc để chung tay đánh bại bệnh sốt rét, cải thiện hệ thống y tế toàn cầu.`,
关于牛津大学经济学系: "VỀ KHOA KINH TẾ, ĐẠI HỌC OXFORD",
牛津大学是享誉全球的顶尖高等学府: `Đại học Oxford là một trong những trường đại học hàng đầu thế giới, đồng thời cũng là một trong những tổ chức nghiên cứu lớn nhất Vương quốc Anh. Trong gần một nghìn năm lịch sử, Oxford luôn nỗ lực xây dựng một môi trường học thuật cởi mở và bao dung, tạo ra các nghiên cứu kinh tế sáng tạo, thúc đẩy hợp tác và trao đổi quốc tế đa lĩnh vực, góp phần thúc đẩy sự phát triển và tiến bộ của xã hội loài người.`,
牛津大学经济学系以其专业权威的学术研究: `Khoa Kinh tế của Đại học Oxford nổi tiếng toàn cầu nhờ những nghiên cứu học thuật chuyên sâu và có tầm ảnh hưởng, cùng những đóng góp xuất sắc cho chính sách kinh tế và công cộng trên toàn thế giới. Đây là một trong những cộng đồng các nhà kinh tế học đa dạng nhất trên thế giới. Trong suốt thời gian qua, khoa luôn cam kết xây dựng một môi trường học thuật cởi mở và bao dung, tạo ra những nghiên cứu kinh tế sáng tạo, góp phần thúc đẩy sự phát triển của kinh tế toàn cầu.`,
  经济学家都干了什么: "Các nhà kinh tế thực sự làm gì?",
  如何利用气候经济学:
    "Làm thế nào để khai thác cơ hội đầu tư thông qua kinh tế học khí hậu?",
  挖掘投资机遇: "",
  "2024年11月14日": "14/11/2024 14:30 (GMT)",
  "牛津大学Michael Dummett讲座厅":
    "Giảng đường Michael Dummett, Đại học Oxford.",
  预约报名: "ĐĂNG KÝ NGAY",
  EBC邀您游览牛津大学:
    "EBC mời bạn tham quan Đại học Oxford và tham gia Hội thảo Toàn cầu",
  EBC金融集团再次携手牛津大学: `EBC Financial Group một lần nữa hợp tác với Đại học Oxford để tổ chức Hội thảo Toàn cầu vào ngày 14 tháng 11 năm 2024. Đây sẽ là sự kiện Hybrid đầu tiên (kết hợp online và offline) trong quan hệ hợp tác giữa chúng tôi, đồng thời cũng là lần đầu tiên buổi thảo luận "Các nhà kinh tế thực sự làm gì?" của Oxford có phần hỏi đáp nổi bật trong các phiên thảo luận bàn tròn. Sự kiện này đánh dấu cột mốc quan trọng trong việc thắt chặt quan hệ đối tác giữa EBC và Đại học Oxford. Hội thảo sẽ tập trung vào chủ đề cân bằng giữa tăng trưởng kinh tế và khả năng chống chịu với biến đổi khí hậu, nhằm khám phá những thách thức của kinh tế học khí hậu và triển vọng đầu tư, hỗ trợ nhà đầu tư tìm kiếm cơ hội tiềm năng.`,
  活动介绍: "GIỚI THIỆU SỰ KIỆN",
  "本次线下研讨会将于11月14日14:30": `Hội thảo trực tiếp lần này sẽ diễn ra vào lúc 14:30 (GMT) ngày 14 tháng 11 tại trường Đại học Oxford, thuộc Học viện Christ Church, đồng thời được phát trực tiếp cho các nhà đầu tư toàn cầu.`,
  EBC金融专家还将与您一同漫步在新学院的回廊: `Các chuyên gia tài chính của EBC sẽ cùng bạn dạo bước trong hành lang của New College, nơi tái hiện những khoảnh khắc ma thuật từ bộ phim "Harry Potter và Chiếc cốc lửa", hoặc bạn có thể đắm mình trong bầu không khí học thuật hàng trăm năm của Oxford tại Thư viện Bodleian, một thánh địa mà mọi học giả trên thế giới đều khao khát đến thăm, để trải nghiệm sự quyến rũ độc đáo của văn hóa Oxford.`,
  与会嘉宾: "KHÁCH MỜI",
  牛津大学环境复原力及马丁系统韧性负责人:
    "Trưởng bộ phận Khả năng phục hồi môi trường và hệ thống Martin tại Đại học Oxford",
  "Nicola Ranger是牛津大学环境复原力及马丁系统韧性负责人": `Nicola Ranger là người đứng đầu chương trình về Khả năng phục hồi môi trường và hệ thống Martin tại Đại học Oxford. Bà đã thành lập Nhóm Tài chính và Kinh tế Toàn cầu thuộc Viện Nghiên cứu Biến đổi Môi trường của Đại học Oxford và hợp tác chặt chẽ với các tổ chức tài chính, ngân hàng trung ương và chính phủ để thúc đẩy các nghiên cứu tiên phong trong các lĩnh vực giao thoa giữa tài chính, khí hậu, tự nhiên và phân tích. Ranger đã tham gia phát triển các phương pháp kiểm tra khả năng chịu đựng và phân tích kịch bản, bao gồm cả việc hợp tác với IMF, Ngân hàng Thế giới, NGFS và Diễn đàn Rủi ro Tài chính Khí hậu của Anh. Bà cũng giữ vai trò cố vấn cho Ủy ban châu Âu về đầu tư bền vững, Nhóm Tư vấn Công nghệ xanh của Vương quốc Anh, FCDO, và Trường Kinh tế Chính trị London.`,
  查看更多: "Tìm hiểu thêm",
  牛津大学经济学系教授: "Giáo sư khoa Kinh tế tại đại học Oxford",
  牛津大学经济学系教授2: "Giáo sư khoa Kinh tế tại đại học Oxford",
  "Andrea Chiavari 是牛津大学经济学系教授": `Andrea Chiavari là giáo sư khoa Kinh tế tại Đại học Oxford, chuyên về kinh tế vĩ mô, đặc biệt là động lực doanh nghiệp và sự thay đổi công nghệ. Nghiên cứu của ông khám phá cách các mô hình kinh tế có thể hỗ trợ phát triển bền vững. Ông tập trung nghiên cứu tác động của biến đổi khí hậu đến năng suất doanh nghiệp và phân bổ vốn, đặc biệt là cách nâng cao hiệu quả và khả năng chống chịu của nền kinh tế trong bối cảnh bất định, cũng như vai trò của công nghệ trong việc thúc đẩy tăng trưởng kinh tế.`,
  "Banu Demir Pakel 是牛津大学经济学系教授": `Banu Demir Pakel là giáo sư khoa Kinh tế tại Đại học Oxford, chuyên về thương mại quốc tế và kinh tế phát triển. Bà nghiên cứu cách doanh nghiệp thích ứng với các cú sốc thương mại và tác động của những điều chỉnh này đến các kết quả kinh tế vĩ mô, như tăng trưởng kinh tế và mức thu nhập. Bà cũng là thành viên nghiên cứu tại Trung tâm Nghiên cứu Chính sách Kinh tế châu Âu (CEPR) và Mạng lưới nghiên cứu CESifo Munich.`,
  "EBC金融集团（英国）首席执行官": "CEO, EBC Financial Group (UK) Ltd",
  "David Barrett是EBC 金融集团 (英国) CEO": `David Barrett, CEO của EBC Financial Group (UK), có hơn 35 năm kinh nghiệm trong việc phân bổ tài sản quốc tế và phòng ngừa rủi ro cấu trúc. Ông đã trải qua nhiều giai đoạn thị trường khác nhau và từng dẫn dắt công tác điều hành tại Tập đoàn AIG, một trong những tập đoàn đầu tư lớn nhất thế giới. David đã góp phần thúc đẩy những cải cách quan trọng về cấu trúc sản phẩm và quản lý rủi ro tuân thủ tại Phố Wall, đồng thời xây dựng khung quản lý rủi ro và thanh khoản khả thi cho thời kỳ hậu khủng hoảng tài chính.`,
  往期回顾: "Tổng kết kỳ trước",
  EBC金融集团联合牛津大学经济学系第一期:
    "EBC Financial Group hợp tác với Khoa Kinh tế Đại học Oxford tổ chức hội thảo kỳ 1: “Các nhà kinh tế thực sự làm gì”",
  "2024年3月6日EBC与牛津大学联合举办主题为": `Vào ngày 6 tháng 3 năm 2024, EBC và Đại học Oxford đã phối hợp tổ chức hội thảo với chủ đề “Các nhà kinh tế thực sự làm gì”, tập trung thảo luận cách kinh tế học có thể góp phần cải thiện chính sách và thúc đẩy sự phát triển kinh tế. Hội thảo đi sâu vào “Kinh tế học tránh thuế”, phân tích toàn diện xu hướng kinh tế phát sinh từ các chính sách thuế khác nhau và vai trò của các nhà kinh tế trong việc đảm bảo an ninh kinh tế toàn cầu.`,
  关于EBC金融集团: "VỀ EBC FINANCIAL GROUP",
  "EBC Financial Group 成立于英国伦敦": `EBC Financial Group được thành lập tại London, Vương quốc Anh, là một tập đoàn tài chính tổng hợp cung cấp các dịch vụ như môi giới tài chính, quản lý tài sản và đầu tư định cư. EBC đã được cấp phép và chịu sự quản lý tại nhiều khu vực pháp lý tài chính quan trọng trên toàn cầu, bao gồm giấy phép toàn diện từ FCA Anh, ASIC Úc và CIMA Cayman. EBC có các chi nhánh và văn phòng tại London, Hồng Kông, Tokyo, Sydney và Cayman.Các thành viên cốt lõi của EBC Group có hơn 30 năm kinh nghiệm làm việc tại các tổ chức tài chính lớn, đã trải qua nhiều chu kỳ kinh tế quan trọng, từ Hiệp định Plaza năm 1985, Khủng hoảng tài chính châu Á năm 1997, Khủng hoảng tài chính toàn cầu năm 2008 đến sự kiện "Thiên nga đen" của đồng franc Thụy Sĩ năm 2015.EBC là đối tác chính thức trong lĩnh vực tài chính của Câu lạc bộ Bóng đá Barcelona, đồng thời hợp tác cùng Quỹ Liên Hợp Quốc để chung tay đánh bại bệnh sốt rét, cải thiện hệ thống y tế toàn cầu.`,
  关于牛津大学经济学系: "VỀ KHOA KINH TẾ, ĐẠI HỌC OXFORD",
  牛津大学是享誉全球的顶尖高等学府: `Đại học Oxford là một trong những trường đại học hàng đầu thế giới, đồng thời cũng là một trong những tổ chức nghiên cứu lớn nhất Vương quốc Anh. Trong gần một nghìn năm lịch sử, Oxford luôn nỗ lực xây dựng một môi trường học thuật cởi mở và bao dung, tạo ra các nghiên cứu kinh tế sáng tạo, thúc đẩy hợp tác và trao đổi quốc tế đa lĩnh vực, góp phần thúc đẩy sự phát triển và tiến bộ của xã hội loài người.`,
  牛津大学经济学系以其专业权威的学术研究: `Khoa Kinh tế của Đại học Oxford nổi tiếng toàn cầu nhờ những nghiên cứu học thuật chuyên sâu và có tầm ảnh hưởng, cùng những đóng góp xuất sắc cho chính sách kinh tế và công cộng trên toàn thế giới. Đây là một trong những cộng đồng các nhà kinh tế học đa dạng nhất trên thế giới. Trong suốt thời gian qua, khoa luôn cam kết xây dựng một môi trường học thuật cởi mở và bao dung, tạo ra những nghiên cứu kinh tế sáng tạo, góp phần thúc đẩy sự phát triển của kinh tế toàn cầu.`,
};
