export default {
	"fcb-class-name": "en-fcb-box",
	"video-btn": "ดูวิดีโอฉบับเต็ม",
	"fcb-page1": {
		"text1": "OFFICIAL PARTNER OF FC BARCELONA",
		"text2": "EBC เป็นพันธมิตรในวงการการเงินเพียงผู้เดียวของสโมสรฟุตบอลบาร์เซโลน่า เราครอบคลุมการแลกเปลี่ยนเงินตราต่างประเทศ การลงทุน เป็นนายหน้าซื้อขายหลักทรัพย์ (รวมถึง CFD) และเป็นผู้บริการให้คำปรึกษาทางด้านการเงินโดยตรง",
		"text3": "",
	},
	"fcb-page2": {
		"text1": "ทุ่มเทให้กับความสนใจ",
		"text2": "การเผชิญหน้ากับสิ่งที่หลงใหล คือ ความท้าทายที่น่าเกรงขาม<br>สิ่งที่เป็นมากกว่าตัวเลขและการตัดสินแพ้ชนะ<br>การขับเคลื่อนเพื่อสร้างการเปลี่ยนแปลงครั้งนี้ เราไม่ได้อยู่ตัวคนเดียว<br>พวกเรามาเพื่อกำหนดมาตรฐานและปฏิวัติอุตสาหกรรม<br>นำความรู้และเทคโนโลยีใหม่ๆมาปรับใช้เป็นเครื่องมือ<br>สานต่อความตั้งใจแรกที่ยิ่งใหญ่ให้บรรลุสู่ความสำเร็จ",
	},
	"fcb-page3": {
		"text1": "ไม่มีใครไม่รู้จักสโมสฟุตบอลระดับตำนานนี้",
		"text2": "ไม่มีใครไม่รู้จักสโมสฟุตบอลระดับตำนานนี้",
		"text3": "สโมสรฟุตบอลบาร์เซโลนา มีประวัติยาวนานกว่า 120 ปี มีนักเตะชื่อดังและโค้ชระดับโลกมากมายที่ได้ผ่านการร่วมงานกับสโมสรนี้ เช่น มาราโดนา, เมสซี, โรนัลดินโญ่ และ กวาร์ดิโอล่า ทั้งหมดล้วนมีเอกลักษณ์เฉพาะในวงการฟุตบอลและด้วยสไตล์การเล่นที่งดงามจนทำให้บาร์เซโลนาเป็น \"มากกว่าแค่สโมสร\"",
		"text4": "EBC เป็นมากกว่าการลงทุน เราตั้งใจสร้างระบบนิเวศทางการเงินระดับสากล ส่งเสริมการพัฒนาเศรษฐกิจโลกอย่าจริงจัง",
	},
	"fcb-page4": {
		"text1": "ตั้งเกณฑ์มาตรฐาน",
		"text2": "สโมสรฟุตบอลที่เต็มไปด้วยดาวรุ่งที่พร้อมเฉิดฉาย ระบบการฝึกซ้อมที่ดูแลด้วยความรัก เปิดพื้นที่ให้เด็กที่ด้อยโอกาสได้แสดงศักยภาพ บาร์เซโลนาเดินหน้าทำกิจกรรมช่วยเหลือสังคมด้วยการลงมือทำอย่างจริงจัง การันตีความสำเร็จจากถ้วยรางวัลมากมายถือเป็นตัวแทนความสำเร็จที่น่าภาคภูมิใจ ทำให้บาร์เซโลนากลายเป็นมาตรฐานองค์กรตัวอย่างที่ดีให้แก่องค์กรในอุตสาหกรรมเดียวกัน",
		"text3": "ความร่วมมือระหว่างสององค์กรของ EBC และบาร์เซโลนาแสดงถึงการยอมรับความแข็งแกร่งของกันและกัน EBC ยึดมั่นในความตั้งใจแรกในการปฏิบัติต่อผู้ที่ชื่นชอบการลงทุนทุกคนอย่างจริงจังและจริงใจ ตั้งใจสร้างมาตรฐานอุตสาหกรรมด้วยการดำเนินงานภายใต้หน่วยงานกำกับดูแลระดับแนวหน้าของโลก พร้อมระบบการรับประกันที่ครอบคลุม และการร่วมมือกับองค์กรระดับสากลที่ได้รับการยกย่องอย่างกว้างขวาง ",
	},
	"fcb-page5": {
		"text1": "นวัตกรรมเทคโนโลยีสร้าง 'ทีมในฝัน'",
		"text2": "สโมสรฟุตบอลบาร์เซโลนามีชื่อเสียงระดับโลกในด้านกลยุทธ์การควบคุมลูกบอลที่ไหลลื่นและส่งต่อลูกบอลที่แม่นยำ การทำงานเป็นทีมที่พิสูจน์ให้เห็นว่าฟุตบอลสามารถชนะได้ด้วยทางเทคโนโลยีชั้นสูงที่นำมาประยุคใช้กับฟุตบอลผสมผสานกันอย่างลงตัว นำไปสู่การสร้างอิทธิพลต่อวิวัฒนาการของกลยุทธ์ฟุตบอลสมัยใหม่",
		"text3": "EBC มีความก้าวหน้าทางเทคโนโลยีและเป็นแนวหน้าของตลาดการเงินในต่างประเทศมาโดยตลอดเป้าหมายเพื่อสร้างระบบนิเวศการลงทุนระดับโลกที่ปลอดภัย เป็นมืออาชีพ มีประสิทธิภาพและมีเสถียรภาพให้แก่นักลงทุนทุกคน"
	},
	"fcb-page6": {
		"text1": "มุ่งมั่นสู่ความสำเร็จ สร้างโลกใบใหม่ที่ดีกว่า",
		"text2": "ให้ความเคารพ มุ่งมั่นในการทำงาน ความทะเยอทะยาน ทำงานเป็นทีม และความอ่อนน้อมถ่อมตน คือค่านิยม 5 ประการของสโมสรฟุตบอลบาร์เซโลน่าเน้นการสนับสนุนค่านิยมของการทำงานหนัก ความทะเยอทะยานและจิตวิญญาณในการผลักดันผู้เล่นให้กลายเป็นผู้เล่นที่ดีที่สุดซึ่งสอดคล้องกับคุณค่าของ EBC เช่นกัน",
		"text3": "EBC ยึดมั่นในหลักการของความซื่อสัตย์และการให้ความเคารพมาโดยตลอด เราชื่นชมความรักและความคาดหวังของผู้ที่ชื่นชอบในการลงทุนทุกคน เรามุ่งมั่นที่จะทำให้ข้อมูลอุตสาหกรรมมีความโปร่งใสที่สุด ส่งเสริมการพัฒนาอุตสาหกรรม และสร้างสังคมที่เท่าเทียมกัน ",
		"text4": "Together, Let's Build The Future.",
	},
	"fcb-page7": {
		"text1": "ข่าวสารที่เกี่ยวข้อง",
	}
}