export default {
  "screen0": {
    "title": "Exceptional Brilliant Care for Every Committed Trader.",
    "enTitle": "Trust & Respect",
    "btn": "Watch Full Video"
  },
  "screen1": {
    "line1": "Your Faithful Partner",
    "line2": "In The World",
    "line3": "Trade The Most Popular Assets",
    "line4": "`Risk Warning: CFDs are complex financial instruments and come with a high risk of losing money rapidly due to leverage. 75% of retail investor accounts lose money when trading CFDs.Please ensure you fully understand the risks and take appropriate care to manage your risk。",
    "list": [{
      "enTitle": "Derivatives",
      "title": "",
      "desc": "Trade 36 most popular currency pairs, including the majors, minors and exotics, with ultra-low spreads from 0.0pips. ",
      "more": "Learn More"
    }, {
      "enTitle": "Index CFDs",
      "title": "",
      "desc": "Meeting the world’s major stock indices. Actively seeking countless opportunities.",
      "more": "Learn More"
    }, {
      "enTitle": "Commodities",
      "title": "",
      "desc": "If you’re considering diversifying your portfolio, then safe-haven assets (such as precious metals) and energy markets (such as crude oil and natural gas) are your ideal choices.",
      "more": "Learn More"
    }, {
      "enTitle": "Share CFDs",
      "title": "",
      "desc": "Access to 36 stock exchanges, both in mature as well as emerging markets.When you trade share CFDs with EBC, you trade at the real market price.",
      "more": "Learn More"
    }]
  },
  "screen2": {
    "title1": "EBC is regulated by",
    "title2": "<span>two world’s leading financial jurisdictions</span>",
    "desc": "You can enjoy an institutional level of trading environment featuring integrity, security, <br />and high standard performance. ",
    "list": [{
      "title": "FCA in UK",
      "desc": "EBC Financial Group (UK) Ltd is authorised and regulated by the  Financial Conduct Authority.<br />Reference Number: 927552<br/>website：<a href='https://www.ebcfin.co.uk'>www.ebcfin.co.uk</a>"
    }, {
      "title": "ASIC in Australia",
      "desc": "EBC Global Pty Ltd is a licensed and regulated by the Australian Securities and Investments Commission<br /> AFSL:500991"
    }]
  },
  "screen3": {
    "line1": "Your Faithful Partner",
    "line2": "Driving you",
    "line3": "to succeed",
    "line4": "Professional and leading financial technology makes trading with infinite possibilities",
    "line5": "Located in<br /> London LD5; New York NY4; Singapore SG1; Tokyo TY3; Hong Kong HK2",
    "line6": "Cross-regional Equinix servers connected with dedicated lines",
    "list": [{
        "num": "1000",
        "text": "ORDERS PROCESSED PER SECOND"
      },
      {
        "num": "5",
        "unit": "×24",
        "text": "VIP SERVICE"
      },
      {
        "num": "50",
        "unit": "+",
        "text": "TRADING INSTRUMENTS"
      },
      {
        "num": "20",
        "unit": "+",
        "text": "ULTRA-HIGH PERFORMANCE DATA CENTER (DC) WORLDWIDE"
      },
      {
        "num": "100",
        "unit": "+",
        "text": "GLOBAL MEMBERS"
      },
      {
        "num": "98.75",
        "unit": "%",
        "text": "UPTIME"
      },
      {
        "num": "0.0",
        "unit": "PIPS",
        "text": "INTERBANK LEVEL OF RAW ECN SPREAD"
      },
      {
        "btn": "Explore EBC Tech"
      }
    ]
  },
  "screen4": {
    "enTitle": "Discover Prices & Transfer Orders",
    "title": "",
    "desc": "EBC uses FIX (Financial Information Exchange Protocol) to aggregate interbank currency quotes to provide customers with direct access to the markets with optimum liquidity. The quotes aggregated through dedicated physical HUB hardware bring lower spreads and sustainable trading opportunities.",
    "line1": "EBC Trading Black-Box",
    "line2": "With Optimum, Accurate",
    "line3": "Sell-order Algorithm",
    "line4": "Enabling more than 85% of orders filled at a better price",
    "list": ["Trading Black-Box", "Trading Distribution Map", "This experience confirmed that EBC trading black-box is far superior to the traditional trading system in terms of price optimization rate, order transaction rate and algorithm stability."],
    "rightDesc": "EBC uses FIX (Financial Information Exchange Protocol) to aggregate interbank currency quotes to provide customers with direct access to the markets with optimum liquidity. The quotes aggregated through dedicated physical HUB hardware bring lower spreads and sustainable trading opportunities.",
    "more": "Learn More",
    "btn": "Start Trading"
  },
  "screen5": {
    "btn": "Start Trading",
    "title": "EBC Private Room",
    "enTitle": "&nbsp; ",
    "desc": "<span> “No uniform criteria for liquidation, and as such</span> all possibilities must be considered”",
    "textList": ["Private Room can hide part of your transaction information, which means that your “large order” may be filled at a better price without serious slippage or rejection due to Last Look. ",
      "If you are a “big player” and don’t want to disclose your “large order” to others, EBC Private Room will be your best choice."
    ],
    "ftTitle": "EBC THE FUTURE",
    "ftDesc": "<i> <span> EBC </span> provides a truly top-tier global liquidity </i>",
    "ftDesc1": "For Institutional Investors",
    "ftBtn": "Partner With Us",
    "ftList": [{
      "title": "Safety",
      "desc": "Connecting to Equinix centers worldwide through the optical fiber dedicated line of peer-to-peer networking to achieve an extremely fast order execution as low as 20 milliseconds. "
    }, {
      "title": "Compliance",
      "desc": "All client funds are held in segregated independent trust accounts with top-tier banks and subject to strict regulation."
    }, {
      "title": "Liquidity",
      "desc": "EBC has established long-term and stable cooperative relationships with world’s leading  international banks, allowing you to make transactions easily at ultra-low costs. "
    }, {
      "title": "Partners"
    }]
  },
  "urlLan":"en"
}
