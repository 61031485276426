export default {
  lang:'th',
  指数差价合约: "ดัชนี CFD",
  无需持有基础资产: "เทรดได้อย่างยืดหยุ่นโดยไม่ต้องถือสินทรัพย์พื้นฐาน – คว้าโอกาสในตลาดทั่วโลก",
  掌控全球机遇: "",
  即刻开户: "เปิดบัญชีฟรี",
  美股指数差价合约: "ดัชนี CFD หุ้นสหรัฐ",
  点差大幅度降低: "เริ่มต้นธุรกรรมด้วย สเปรดต่ำ, สภาพคล่องสูง และการเทรดที่มีต้นทุนต่ำครอบคลุมทุกตลาดทั่วโลก",
  美国道琼斯工业平均指数: "US Dow Jones Industrial Average Index Spot CFD",
  美国标准普尔500指数: "US S&P 500 Index Spot CFD",
  美国纳斯达克100指数: "US NASDAQ-100 Index Spot CFD",
  香港恒生指数: "Hong Kong Hang Sheng Index Spot CFD",
  中国A50指数:"China A50 Index Spot CFD",
  降幅: "-Reduction Percentage (%)",
  亚洲时间: "Asian Session",
  欧洲时间: "European Session",
  美国时间: "US Session",
  品种: "Symbol",
  指数描述: "Description",
  指数点差: "Spreads in Index Points",
  开设模拟账户: "เปิดบัญชีทดลอง",
  开设真实账户: "เปิดบัญชีจริง",
  产品优势: "ข้อได้เปรียบ",
  无需持有基础资产2:
    "เพลิดเพลินไปกับค่าเลเวอเรจต้นต่ำที่ยืดหยุ่นได้ ช่วยเพิ่มโอกาสการลงทุนในตลาดทั้งขาขึ้นและขาลงให้กับคุณ",
  灵活高杠杆: "เลเวอเรจที่ยืดหยุ่น",
  杠杆高达: "เลเวอเรจสูงสุดถึง 100:1 ช่วยเพิ่มผลตอบแทนในการเทรดและสามารถจัดการความเสี่ยงได้อย่างมีประสิทธิภาพ",
  放大每笔交易收益: "",
  实时弹性定价: "ตั้งราคาด้วยระบบไดนามิกแบบเรียลไทม์",
  根据全球市场波动实时调整: "ราคาของเราปรับตัวตามความผันผวนของตลาดโลก เพื่อให้คุณได้รับเงื่อนไขการเทรดที่ดีที่สุด",
  确保您每笔交易享受最优交易条件: "",
  极致的交易速度: "ทำธุรกรรมด้วยความเร็วสูงสุด",
  平均执行速度20毫秒: "ความเร็วคำสั่งซื้อ 20ms ช่วยให้คุณคว้าโอกาสในตลาดได้ก่อนใคร",
  助您抢占市场先机: "",
  完全透明的交易: "การเทรดที่โปร่งใส",
  无隐藏费用或额外成本: "ไร้ค่าธรรมเนียมแอบแฝงหรือไม่มีค่าใช้จ่ายเพิ่มเติม เทรดอย่างมั่นใจเพราะทุกการทำธุรกรรมโปร่งใสและเป็นธรรม ",
  确保您每笔交易的透明和公平: "",
  全球指数交易深度: "ความลึกของตลาดทั่วโลก",
  查看所有产品: "ผลิตภัณฑ์ทั้งหมด",
  指数差价合约相关问题: "คำถามเกี่ยวกับดัชนี CFD",
  list: [
    {
      title: "1. ดัชนี CFD คืออะไร？",
      content: `ดัชนี CFD (Contract for Difference) หมายถึง
                        สัญญาซื้อขายส่วนต่างบนดัชนีหุ้น
                        ซึ่งสามารถสะท้อนการเปลี่ยนแปลงของราคาดัชนีหุ้นและให้ผลกำไรหรือขาดทุนจากการเคลื่อนไหวของราคา<br />

                        เมื่อเปรียบเทียบกับการลงทุนในหุ้นตัวเดียว
                        ดัชนีหุ้นจะสะท้อนแนวโน้มของกลุ่มหุ้นที่มีผลการดำเนินงานดี
                        โดยทำหน้าที่เป็นมาตรวัดสำหรับอุตสาหกรรมนั้นๆ
                        หรือแม้กระทั่งเศรษฐกิจโดยรวมของประเทศ เช่น ดัชนี DAX 30
                        ของเยอรมนี ซึ่งประกอบด้วยหุ้นบลูชิพ 30
                        ตัวแรกที่มีผลประกอบการดี
                        สะท้อนถึงสถานการณ์โดยรวมของตลาดหุ้นเยอรมัน<br />

                        การเทรดดัชนี CFD
                        ช่วยให้สามารถลดความซับซ้อนในการประเมินหุ้นตัวเดียวได้
                        โดยการวิเคราะห์อารมณ์ตลาดโดยรวม
                        ทำให้สามารถกระจายความเสี่ยงและหลีกเลี่ยงความผันผวนของราคาในหุ้นตัวเดียว
                        ซึ่งจะทำให้มีความปลอดภัยมากขึ้นในภาพรวม`,
    },
    {
      title:"2. ผลิตภัณฑ์ใดที่สามารถซื้อขายได้ในดัชนี CFD?",
      content:` ดัชนี CFD สามารถใช้ในการเทรดดัชนีหุ้นทั่วโลกได้<br />

                        EBC คัดเลือกดัชนีหลักๆ จากทั่วโลก เช่น ดัชนีดาวโจนส์,
                        ดัชนี DAX 30, ดัชนี STOXX 50, ดัชนี S&P 500 และดัชนี
                        Nikkei 225 เป็นต้น เพียงแค่มีบัญชี EBC บัญชีเดียว
                        คุณก็สามารถเลือกเทรด ดัชนีได้ทั่วโลก นอกจากนี้ EBC
                        พร้อมมอบการเทรดด้วยสเปรดต่ำและเลเวอเรจสูง
                        เพื่อให้คุณประสบความสำเร็จในโลกการเทรด`
    },
    {
      title:"3. เทรดดัชนี CFD สามารถใช้เลเวอเรจได้เท่าไร？",
      content: `ดัชนี CFD สามารถทำการซื้อขายดัชนีหุ้นทั่วโลก<br />

                        EBC ให้เลเวอเรจสูงสุดถึง 100 เท่าสำหรับการเทรด ซึ่งดัชนี
                        CFD
                        มีอัตราเลเวอเรจสูงสุดที่อนุญาตให้ใช้ภายใต้ข้อกำหนดและข้อบังคับ
                        โดยคำนึงถึงความปลอดภัยและประสิทธิภาพในการใช้เงินทุน`
    },
    {
      title:"4. วิธีคำนวนขนาดสัญญาดัชนี CFD ?",
      content:`ดัชนีที่แตกต่างกันมีขนาดสัญญาที่แตกต่างกัน<br />

                        EBC มี CFD สำหรับดัชนีหุ้นหลักทั่วโลก โดยมีขนาดสัญญาคือ
                        10 เช่น 100GBP (ดัชนี FTSE 100 ของสหราชอาณาจักร), 200AUD
                        (ดัชนี S&P/ASX 200 ของออสเตรเลีย), CNIUSD (ดัชนี A50
                        ของจีน), D30EUR (ดัชนี DAX 30 ของเยอรมนี), E50EUR (ดัชนี
                        STOXX 50 ของยุโรป), F40EUR (ดัชนี CAC 40 ของฝรั่งเศส),
                        HSIUSD (ดัชนี 50 ของฮ่องกง), NASUSD (ดัชนี Nasdaq 100
                        ของสหรัฐฯ), และ U30USD (ดัชนีหุ้น 30 ของสหรัฐฯ) สำหรับ
                        225JPY (ดัชนี Nikkei 225) และ SPXUSD (ดัชนี S&P 500)
                        มีขนาดสัญญาคือ 100<br />

                        ขนาดสัญญาขั้นต่ำสำหรับ ดัชนี CFD คือ 0.1 lots`
    },
    {
      title:"5. ข้อดีของการเทรด ดัชนี CFD มีอะไรบ้าง?",
      content: ` เพิ่มประสิทธิภาพในการใช้เงินทุน:
                        โดยทั่วไปการเทรดดัชนีมักใช้เลเวอเรจที่ต่ำ แต่ ดัชนี CFD
                        จะใช้เลเวอเรจที่ค่อนข้างสูง อย่างไรก็ตาม
                        การใช้เลเวอเรจควรคำนึงความเสี่ยงเป็นหลัก<br />

                        การซื้อขายได้ทั้งขาขึ้นขาลง:
                        ดัชนีหุ้นสะท้อนทิศทางภาพรวมตลาดหุ้นผ่านดัชนี CFD
                        และนักลงทุนสามารถทำการซื้อขายได้ทั้งขาขึ้นและขาลง
                        เพื่อเปิดโอกาสในการทำกำไรที่มากขึ้น<br />

                        การกระจายความเสี่ยง:
                        ดัชนีหุ้นมักประกอบด้วยหุ้นที่มีความมั่นคงในตลาด
                        การเคลื่อนไหวของหุ้นเพียงตัวเดียวอาจไม่ส่งผลกระทบโดยตรงต่อ
                        ราคาดัชนี CFD
                        ทำให้สามารถกระจายความเสี่ยงในตลาดได้อย่างมีประสิทธิภาพ`
    },
    {
      title:" 6.สิ่งที่ควรเรียนรู้ก่อนเริ่มเทรดดัชนี CFD ?",
      content:`เนื่องจาก ดัชนี CFD สะท้อนถึงแนวโน้มของกลุ่มหุ้น
                        เราจึงควรให้ความสำคัญกับตลาดโดยรวมมากกว่าที่จะมองที่หุ้นรายตัว<br />

                        โดยทั่วไปแล้ว
                        ดัชนีหุ้นมีความสัมพันธ์โดยตรงกับนโยบายการเงินของธนาคารกลางและสถานการณ์ทางเศรษฐกิจของประเทศ
                        หากธนาคารกลางของประเทศหนึ่งใช้มาตรการนโยบายการเงินที่เข้มงวด
                        ผู้คนจะเริ่มกังวลเกี่ยวกับการขาดสภาพคล่องในตลาด
                        ซึ่งอาจส่งผลให้ตลาดหุ้นลดลง เช่นเดียวกัน
                        หากข้อมูลเศรษฐกิจของประเทศไม่ดี
                        จะส่งผลให้การลงทุนในตลาดลดลง
                        และส่งผลกระทบต่อตลาดหุ้นเช่นกัน`
    }
  ],
  每个认真交易的人:"เทรดเดอร์ที่จริงจังควรได้รับบริการที่จริงใจจากเรา"
};
