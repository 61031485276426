export default {
  "box-class-name": "en-box",
  "lang": "ko",
  "MT4是最受欢迎的交易平台之一": "MetaTrader 4(MT4)는 오늘날 세계에서 가장 인기 있는 거래 플랫폼 중 하나이며, 첨단 기술, 접근성 및 보안으로 전 세계 거래자들에게 보편적으로 인정받고 있습니다.",
	"内核稳定流畅": "트레이더가 정확한 거래 전략을 개발할 수 있도록 다양한 차트 분석 기능을 제공합니다.",
	"支持EA交易": "자동화된 트레이딩 운영을 위한 EA 지능형 트레이딩 시스템을 지원합니다.",
	"活跃用户社区": "다양한 트레이더의 요구를 충족시키기 위한 다양한 기술 지표와 분석 도구가 내장되어 있습니다.",
	"立即注册": "지금 등록",
  "MetaTrader 4下载": "MetaTrader 4 다운로드",
  "MT4移动版": "MT4 모바일 버전",
  "随时随地进行交易": "언제 어디서나 거래하고 시장 트렌드를 따라가며 계정을 쉽게 관리할 수 있습니다.",
  "下载Android版": "Android 버전 다운로드",
  "下载IOS版": "IOS 버전 다운로드",
  "下载iPad版": "iPad 버전 다운로드",
  "MT4桌面版": "MT4 데스크톱 버전",
  "全面的交易工具和高级图表分析": "전문 트레이더의 고급 요구 사항을 충족하는 포괄적인 거래 도구와 고급 차트 분석입니다.",
  "下载Windows版": "Windows 버전 다운로드",
  "下载MacOS版": "MacOS 버전 다운로드",
  "MT4网页版": "MT4 웹트레이더",
  "无需下载": "다운로드 없이도 빠른 속도로 글로벌 시장에 접근할 수 있어 온라인 트랜잭션 관리를 쉽게 할 수 있습니다.",
  "打开网页版": "웹트레이더 열기"
}