export default {
	"box-class-name": "cn-no1-box",
	"lang": "zh",
	"button-text": "發起挑戰",
	"no1-page2": {
		"text1": "每秒最高聚合訂單",
		"text2": "極速訂單執行",
		"text3": "資料傳輸穩定高達",
		"text4": "銀行級RAW ECN點差",
	},
	"no1-page3": {
		"text1": "英國FCA監管",
		"text2": "EBC Financial Group（UK）Ltd 由英國金融行為監管局（FCA）授權和監管。<br><span>監管號：927552</span>",
		"text3": "澳大利亞ASIC監管",
		"text4": "EBC Financial Group（Australia）Pty Ltd由澳大利亞證券和投資委員會（ASIC）授權和監管。<br><span>監管號：500991</span>",
	},
	"no1-page4": {
		"text1": "最强交易環境<br>不是EBC的極限，而是邀您挑戰的標準線<br>只要挑戰成功<br>EBC承諾獎勵150%利潤差額，上不封頂<br>您，只管發起挑戰<br>剩下的，讓EBC的實力來證明",
		"text2": "利潤差額獎賞",
	},
	"no1-page5": {
		"text1": "讓 <span>85<i>%</i></span><br>以上的訂單成交於更優的價格",
		"text2": "精准優化拋單算灋，在價格優化率、訂單成交率以及算灋穩定性方面，EBC交易黑盒遠遠優於傳統交易系統。",
	},
	"no1-page6": {
		"text1": "挑戰規則",
		"text2": "以誠信與尊重為則，為挑戰最高標準而來",
		"text3": "入金要求",
		"text4": "平臺選擇",
		"text5": "相同策略",
		"text6": "挑戰成功",
		"text7": "無入金要求",
		"text8": "英國FCA監管",
		"text9": "澳大利亞ASIC監管",
		"text10": "所選交易商具備英國FCA或澳大利亞ASIC監管資質",
		"text11": "在EBC和所選交易商使用相同交易資金和策略",
		"text12": "在EBC的盈利若低於其他平臺，獎勵150%的利潤差額",
		"text13": "所有的挑戰者均可免費申請EBC VIP帳號，EBC免費提供跨平臺跟單軟件",
	},
	"no1-page7": {
		"text1": "挑戰須知",
		"text2": " 1.挑戰帳戶<br>a）新用戶：在官網新開帳戶後，聯系線上客服開通VIP帳戶參與挑戰。<br> b）現有用戶：聯系線上客服，開通VIP帳戶後參加挑戰。",
		"text3": "2.所選平臺須持有英國FCA或澳大利亞ASIC正規監管。 不含AR，EEA注册或Trading Name帳戶。",
		"text4": "3.僅限以下主流貨幣對- AUD/USD、USD/CAD、EUR/USD、USD/CHF、GBP/USD、USD/JPY、NZD/USD。",
		"text5": "4.在EBC和所選交易商使用相同的交易資金和策略，所有策略需屬於正常交易範疇。",
		"text6": "5.挑戰者可以在任意挑戰時間內選擇結算，每個身份證僅可參與一次。",
		"text7": "6.挑戰結束後，提交平倉的交割單。 獎勵將在稽核後的兩個工作日內發放至交易帳戶，可用於交易或提現。",
		"text8": "7.若帳戶有返傭情况，需客戶配合提供相應證明。",
	},
	"no1-page8": {
		"text1": "距離挑戰結束時間還剩",
		"text2": "本次活動時間為2024年1月18日00:00:00至2024年3月1日23:59:59（GMT+2）",
		"text2-style": "",
	},
	"button1-style": "",
	"button2-style": "",
	"button3-style": "",
	"button4-style": "",
}