export default {
	"srceen0": {
		"title": "杠杆与保证金",
		"subTitle": "界定<span>交易方式</span>的关键",
		"desc": "鉴于金融市场的不确定性和潜在的巨幅波动，出于保护客户交易安全的考量，EBC目前提供的最大杠杆为 500:1 。",
		"rateTitle": "杠杆率高达",
		"rateNum": "500:1",
		"rateDesc": "保证金比例0.20%"
	},
	"srceen1": {
		"pageEnTitle": "Secure Transaction",
		"pageTitle": "安全交易",
		"subTitle": "选择适合的杠杆比例",
		"desc1": "请谨慎考虑您的投资目标及风险承受能力，选择适合的杠杆比例。",
		"desc2": "为了更好地实现风险管控，请参考下表，了解每项资产的保证金要求和最大杠杆。",
		"btn": "开始投资",
		"title":""
	}
}

