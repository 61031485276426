export default {
	"box-class-name": "cn-box",
	"lang": "cn",
	"MT4是最受欢迎的交易平台之一": "MT4是最受欢迎的交易平台之一，因技术先进，易于使用，安全系数高，受到全球交易者的亲睐。",
	"内核稳定流畅": "内核稳定流畅，界面简洁易用",
	"支持EA交易": "支持EA交易，高效自定义执行",
	"活跃用户社区": "活跃用户社区，海量指标资源",
	"立即注册": "立即注册",
	"MetaTrader 4下载": "MetaTrader 4下载",
	"MT4移动版": "MT4移动版",
	"随时随地进行交易": "随时随地进行交易，掌握市场动态，轻松管理账户。",
	"下载Android版": "下载Android版",
	"下载IOS版": "下载IOS版",
	"下载iPad版": "下载iPad版",
	"MT4桌面版": "MT4桌面版",
	"全面的交易工具和高级图表分析": "全面的交易工具和高级图表分析，满足专业交易者的高阶需求。",
	"下载Windows版": "下载Windows版",
	"下载MacOS版": "下载MacOS版",
	"MT4网页版": "MT4网页版",
	"无需下载": "无需下载，极速访问全球市场，轻松实现全方位的在线交易管理。",
	"打开网页版": "打开网页版"
}