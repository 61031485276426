export default {
	"malaria-class-name": "en-ubm-box",
	"malaria-page1": {
		"text1": "EBC گۇرۇھى ب د ت فوندى جەمئىيىتىنىڭ بەزگەكنى مەغلۇپ قىلىش دۇنياۋى ھەرىكىتىنى قوللايدۇ 	بەزگەكنى مەغلۇپ قىلغان ئەۋلادلاردىن بولۇپ، باراۋەر يېڭى دەۋرنى ئورتاق يارىتايلى",
		"text2": "",
	},
	"malaria-page2": {
		"text1": "بەزگەك كېسىلى:بالىلارنىڭ ساغلاملىقىغا ئەڭ چوڭ تەھدىدلەرنىڭ بىرى",
		"text2": "2022-يىلى دۇنيادىكى 85 دۆلەتنىڭ مۆلچەرلىشىچە، بەزگەك كېسىلى ۋە 608 مىڭ بەزگەك بەزگەك بىلەن ئۆلگەن، بۇنىڭ ٪76تىن كۆپرەكى بەش ياشتىن تۆۋەن بالىلار ئىكەن	سىز بۇ تېكىستنى ئوقۇپ بىر مىنۇتقا يېقىن ۋاقىت ئىچىدە، بىر بالا بەزگەك كېسىلى بىلەن قازا قىلىدۇ",
		"text3": "249<i> مىليون يۈەن</i>",
		"text4": "يۇقۇملىنىش",
		"text5": "608<i> مىڭ </i>",
		"text6": "قازا قىلىش",
		"text7": "76%<i>+</i>",
		"text8": "بالىلار ",
		"text9": "سانلىق مەلۇمات مەنبەسى: دۇنيا سەھىيە تەشكىلاتى «2023-يىللىق دۇنيا بەزگەك دوكلاتى»",
	},
	"malaria-page3": {
		"text1": "بىرلىشىپ بەزگەك كېسىلىگە تاقابىل تۇرۇش",
		"text2": "بىرلىشىپ بەزگەكنى مەغلۇپ قىلىش ب د ت فوندى قوزغىغان يەر شارى خاراكتېرلىك ھەرىكەت بولۇپ، دۇنيانىڭ ھەرقايسى جايلىرىدىكى قوللىغۇچىلارنى يىغىپ، بەزگەككە ئورتاق تاقابىل تۇرۇشEBC كارخانا مەسئۇلىيىتىنى پائال ئەمەلىيەتتىن ئۆتكۈزۈپ، ب د ت فوندى جەمئىيىتىنىڭ بەزگەكنى مەغلۇپ قىلىشىنى پۈتۈن كۈچ بىلەن قوللايدۇ",
		"text3": "60",
		"text4": "تەسىر كۆرسەتكەن دۆلەت",
		"text5": "40<i> مىليون </i>",
		"text6": " قوغدىلىدىغان كىشىلەر توپى",
		"text7": "183,450",
		"text8": " قۇرۇت قوغلاش چېدىرى",
	},
	"malaria-page4": {
		"text1": "«بەزگەكنى مەغلۇپ قىلىش » جامائەت پاراۋانلىقى ئۇزۇنغا يۈگۈرۈشكە قوشۇلدى",
		"text2": "2024-يىلى 4-ئاينىڭ 25-كۈنىدىن 5-ئاينىڭ 5-كۈنىگىچە بولغان مەزگىلدە، دۇنيانىڭ ھەرقايسى جايلىرىدىكى قوللىغۇچىلار بىلەن بىرلىكتە بەزگەكنى مەغلۇپ قىلىش جامائەت پاراۋانلىقى ئۇزۇنغا يۈگۈرۈشكە قاتنىشىپ، كىشىلەرنىڭ بەزگەكنى داۋالاش تۈرى ۋە ھاياتلىق قۇتقۇزۇش قورالىنى قوللىدىEBC گۇرۇھى ب د ت فوندى بىلەن قول تۇتۇشۇپ، ئوخشاش ئىرادىدىكىلەرنى تەكلىپ قىلىمىز، بىز بىرلىكتە بەزگەكسىز دۇنيانى ئورتاق قۇرۇشقا تىرىشايلى",
		"text3": "دەرھال قوشىلىش",
	},
	"malaria-page5": {
		"text1": "بىز ھەرىكەتلىنىۋاتىمىز",
	},
}