export default {
	"screen0": {
		"title": "Pure Trading Pure Power",
		"subTitle": "交易之路很难走，也许就差最后1毫秒<br style='display:block!important;'>我们以超低延迟的IT基础框架，让你获得无与伦比的市场准入水平"
	},
	"screen1": {
		"line1": "交易执行速度低至",
		"line2": "数据传输稳定性高达",
		"line3": "金融专线光纤带宽不低于",
		"line4": "起步，与世界同步"
	},
	"screen2": {
		"title": "科技赋能交易",
		"desc": "EBC专有服务器设立于Equinix LD4、HK3、TY3、NY4（待设） ，构建覆盖全球的金融生态系统",
		"title1": "科技赋能交易，EBC<br>交易执行速度低至20毫秒",
		"desc1": "在软件定义计算、贮存容量和网络基础上，超融合基础架构带来高达98.75%的系统稳定可用性",
		"desc2": "<p>在当今的金融市场中，速度等于利润，敏捷性对于交易获利至关重要；</p> <p>我们将交易服务器设于世界领先的数据中心Equinix LD4、HK3、TY3、NY4（待设），全球超高速专线连接最大程度地减少了区域间延迟，客户可以享受到稳定的交易；</p><p>由于防火墙带来的限制以及拥挤的国际带宽，造成了低质量的交易体验。我们配有近30个DC（含灾备服务器），欧亚服务器之间全部实现对等网络金融专线连接(Peer-to-peernetworking)；</p><p>相对于传统服务器的欧亚线路，EBC从伦敦到亚洲的延迟控制在100ms以内，而传统经纪商从伦敦到亚洲的传输时间在270ms以上，这无疑最有益于亚洲的客户。</p>"
	},
	"screen3": {
		"title": "我们深知：成功，<br>关键不限于软件技术，而是整体",
		"desc": "EBC确保提供交易市场中最好的流动性，您在获得多资产流动性的同时将拥有更大的灵活性",
		"plant": "想更了解交易平台？",
		"btn": "Lab 学习中心 / 交易平台板块"
	},
	"screen4": {
		"enTitle": "Driving You to Succeed",
		"title": "专业领先的金融科技，让交易无限可能"
	},
	"screen5": {
		"plant": "了解交易产品",
		"btn": "Lab 学习中心 / 交易产品板块",
		"title": "最小化风险 最大化交易",
		"desc": "EBC的超低延迟聚合、智能订单路由和报价优化引擎，为您提供更高的可靠性和最佳的订单执行",
		"list": [
			"交易服务器",
			"聚合报价",
			"订单传输",
			"上游流动性资金池"
		],
		"link1": "展示交易时段",
		"link2": "查看点差与全部商品"
	},
	"screen6": {
		"title": "专注于交易的您，不必担心IT基础架构，操作系统，网络和所有硬件方面的问题。因为您所在乎的，我们都已考虑在内——最佳流动性获取和高性能订单执行",
		"desc": "从前5档深度的订单执行完成情况看，EBC仅需75-125ms就完成了该交易"
	},
	"screen7": {
		"pageTitle": "价格的发现和订单的传输",
		"pageEnTitle": "Discover Prices & Transfer Orders",
		"title": "EBC通过fix（金融信息交换协议）将接入的银行间报价进行聚合，为客户提供一级流动性场所的直接市场准入，通过专用物理HUB硬件聚合后的报价具有更低的点差和可持续交易的深度",
		"desc": "fix聚合报价的核心包括超低延迟的定单发送和价格发现引擎",
		"imgUrl": "zh"
	},
	"screen8": {
		"title": "通过EBC bridge聚合后的<br>产品点差表，深度表",
		"desc": "快速了解",
		"link1": "4 种资产类别",
		"link2": "100+ 交易品种",
		"link3": "查看全部",
		"trList": [
			"层级",
			"买入价",
			"买入的交易量",
			"点差",
			"卖出价",
			"卖出价交易量"
		],
		"smallTrList": [
			"层级",
			"买入价",
			"点差",
			"卖出价"
		]
	},
	"screen9": {
		"title": "交易黑盒",
		"smallTitle": "精准优化抛单算法，让85%以上的订单成交于更优的价格",
		"desc": "EBC交易黑盒，基于数千万次的历史交易数据、交易者的交易习惯、用户属性等画像、当前的市场行情和交易网络环境等，使用大数据统计分析、概率模型、神经网络模型、系统控制理论模型和人工智能算法等进行综合研判，最终匹配更适合每位交易者属性的LP，达成最优的成交价格",
		"link1": "展示交易时段",
		"link2": "查看点差与全部商品",
		"imgUrl": "zh"
	},
	"screen10": {
		"desc": "我们对EBC交易黑盒与传统交易系统进行对比实验（其中至少包含100个订单），可得如下分布图：",
		"list": [
			"每个数据点代表一天的交易",
			"横轴表示订单的成交率",
			"点的大小代表订单量的相对大小",
			"纵轴表示交易价格的优化率"
		],
		"btn": "开始投资",
		"title": "经过实验，我们可以发现：在价格优化率、订单成交率以及算法稳定性方面，EBC交易黑盒远远优于传统交易系统"
	}
}

