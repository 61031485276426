export default {
  "box-class-name": "cn-box",
  "lang": "zt",
  "MT4是最受欢迎的交易平台之一": "MetaTrader 4 (MT4)是當今世界最受歡迎的交易平台之一，該平台的技術先進，易於使用，安全係數高，並受到了全球交易者的普遍認可。",
	"内核稳定流畅": "提供豐富的圖表分析功能，幫助交易者制定精準的交易策略。",
	"支持EA交易": "支援EA智慧交易系統，實現自動化交易操作。",
	"活跃用户社区": "內建多種技術指標和分析工具，滿足不同交易者的需求。",
	"立即注册": "立即註冊",
  "MetaTrader 4下载": "MetaTrader 4下載",
  "MT4移动版": "MT4 行動版",
  "随时随地进行交易": "隨時隨地進行交易，掌握市場動態，輕鬆管理帳戶。",
  "下载Android版": "下載Android版",
  "下载IOS版": "下載IOS版",
  "下载iPad版": "下載iPad版",
  "MT4桌面版": "MT4桌面版",
  "全面的交易工具和高级图表分析": "全面的交易工具和進階圖表分析，滿足專業交易者的高階需求。",
  "下载Windows版": "下載Windows版",
  "下载MacOS版": "下載MacOS版",
  "MT4网页版": "MT4網頁版",
  "无需下载": "無需下載，極速訪問全球市場，輕鬆實現全方位的線上交易管理。",
  "打开网页版": "開啟網頁版"
}