export default {
  "screen0": {
    "title": "Pure Trading Pure Power",
    "subTitle": "힘든 거래의 길에서 마지막 1ms의 추진력이 필요하다면 탁월한 시장 <br>진입 수준과 초저지연의 IT 기초 프레임을 경험해 보세요"
  },
  "screen1": {
    "line1": "초저 거래 실행 속도",
    "line2": "업타임",
    "line3": "광섬유 기반 네트워크",
    "line4": "세계와 발맞춰 출발하세요"
  },
  "screen2": {
    "title": "기술을 활용한 거래",
    "desc": "EBC 전용서버는 Equinix LD4、HK3、TY3、NY4에 구축되어 국제 금융 생태계를 조성합니다.",
    "title1": "IT 기반을 통한 거래, EBC 20ms이하의 신속한 거래 집행속도",
    "desc1": "소프트웨어 독립서버, 저장용량과 네트워크의 기초에서 초융합 기초 프레임이 98.75%에 달하는 시스템 안정성과 가용성을 가져옵니다",
    "desc2": "<p>지금의 금융시장에서 속도는 곧 이윤으로, 거래에서 수익을 보는데 있어 민첩성은 매우 중요합니다.</p> <p>저희는 전세계적으로 앞장선 데이터 센터인 Equinix LD4, HK3, TY3, NY4에 거래 서버를 설치해 전세계 초고속 전용선 연결로 구역간 지연을 최대한 감소하고 고객들의 안정적인 거래를 확보하였습니다.</p> <p>방화벽 제한 및 좁은 국제 네트워크는 저품질 거래 체험을 초래하였습니다. 저희는 30여개의 DC(백업 서버)를 보유하고 있으며 유라시아 서버 간에 피어 투 피어 네트워킹(Peer-to-peer networking)을 전부 실현하였습니다.</p><p>전통 서버의 유라시아 회로에 비해 EBC는 런던에서 아시아까지의 지연을 100ms 이내로 통제하였습니다. 전통적인 브로커과 비교시 런던에서 아시아까지의 전송시간은 270ms 이상이기에, 이는 아시아 고객에게 가장 큰 장점인것이 틀림없습니다.</p>"
  },
  "screen3": {
    "title": "저희는 성공의 핵심은 소프트웨어 기술 뿐만 아니라 전반적인 것이라는 것을 알고 있습니다",
    "desc": "EBC는 거래 시장에서 최고의 유동성을 제공하며, 더 많은 다중 자산 유동성과 더 큰 유연성을 보유할 수 있게 해드립니다.",
    "plant": "더 많은 제품을 알고 싶어요",
    "btn": "거래생성물"
  },
  "screen4": {
    "enTitle": "Driving You to Succeed",
    "title": "전문적이고 선두적인 <br>IT로 거래에 무한 가능성 부여"
  },
  "screen5": {
    "plant": "더 많은 제품을 알고 싶어요",
    "btn": "거래생성물",
    "title": "리스크 최소화 거래기회 최대화",
    "desc": "EBC의 초저지연 집계와 스마트 오더 라우팅 및 시세 최적화 엔진은 고객님에게 더 높은 신뢰성과 최상의 주문 실행을 제공합니다.",
    "list": [
      "거래 서버",
      "시세 취합",
      "주문 전송",
      "유동성 풀"
    ],
    "link1": "거래 시간 표시",
    "link2": "포인트 와 모든 상품 보기"
  },
  "screen6": {
    "title": "고객님은 거래에만 집중하고 IT 기초 프레임, 프로세서 시스템, 네트워크와 모든 하드웨어 측면의 문제를 걱정 안하셔도 됩니다.저희가 고객님의 관심사인 최적의 유동성 취득과 고성능 오더 집행 을 이미 고려했기 때문입니다.",
    "desc": "처음 5 개 수준의 깊이를 실행 한 것으로 판단하면 EBC는 트랜잭션을 완료하는 데 75-125ms 만 필요합니다."
  },
  "screen7": {
    "pageTitle": "가격의 발견과 오더의 전송",
    "pageEnTitle": "Discover Prices & Transfer Orders",
    "title": "EBC는 fix (금융정보 교환 프로토콜)를 통해 은행간 시세를 집계하여 고객님에게 최적의 유동성으로 직접적인 시장접근을 제공하며, 전용 물리적 HUB 하드웨어를 통해 집계된 시세는 낮은 스프레드와 지속 가능한 거래 기회를 제공합니다.",
    "desc": "",
    "imgUrl": "ko"
  },
  "screen8": {
    "title": "EBC bridge 를 통 해서 모 은 거 예요.<br>제품 시차 표,깊이 표",
    "desc": "快速了解",
    "link1": "4종자산종류",
    "link2": "100+거래품종",
    "link3": "모두 보기",
    "trList": [
      "层级",
      "买入价",
      "买入的交易量",
      "点差",
      "卖出价",
      "卖出价交易量"
    ],
    "smallTrList": [
      "层级",
      "买入价",
      "点差",
      "卖出价"
    ]
  },
  "screen9": {
    "title": "거래 블랙박스",
    "smallTitle": "매도 알고리즘을 최적화하여, 85% 이상의 주문이 더 좋은 가격에 거래될 수 있도록 합니다. ",
    "desc": "EBC거래 블랙박스는 수천만회의 과거 거래 데이터, 거래자의 거래습관, 사용자 속성 및 현재 시장시세와 거래 네트워크 환경 등을 기반으로 빅데이터 통계분석, 확률 모형, 신경망 모형, 시스템 컨트롤 이론 모형 및 인공지능 알고리즘 등을 활용하여 종합적으로 연구하고 판단해 최종적으로 거래자 별 속성에 더욱 적합한 LP를 매칭해 최적의 거래가격을 달성합니다.",
    "link1": "거래 시간 표시",
    "link2": "포인트 와 모든 상품 보기",
    "imgUrl": "ko"
  },
  "screen10": {
    "desc": "저희는 EBC 거래 블랙박스와 전통 거래 시스템에 대해 비교 실험(그중 최소 100개 오더 포함)을 진행해 아래와 같은 분포도를 얻었습니다：",
    "list": [
      "각 데이터 포인트는 하루의 거래를 나타냄",
      "가로축은 주문의 거래율을 나타냄",
      "점의 크기는 주문 수량의 상대적인 크기를 나타냄",
      "세로축은 거래가격의 최적화율을 나타냄"
    ],
    "btn": "투자 시작",
    "title": "실험을 통해 저희는 가격 최적화율, 주문 거래율, 알고리즘 안정성 측면에서 EBC 거래 블랙박스가 기존 거래 시스템보다 훨씬 우수하다는 것을 알 수 있었습니다."
  }
}


