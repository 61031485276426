export default {
  "srceen0": {
    "title": "پىشاڭ ۋە كاپالەت پۇلى",
    "subTitle": "سودا ئۇسۇلىنى بەلگىلەشنىڭ ئاچقۇچى",
    "desc": " پۇل مۇئامىلە بازىرىنىڭ ئېنىقسىزلىقى ۋە يوشۇرۇن زور داۋالغۇشىنى كۆزدە تۇتۇپ، خېرىدارلارنىڭ سودا بىخەتەرلىكىنى قوغداشنى ئويلىشىش كېرەك.  نۆۋەتتە تەمىنلىگەن ئەڭ چوڭ پىشاڭ 500: 1 ",
    "rateTitle": "پىشاڭ نىسبىتى يۇقىرى بولۇشى",
    "rateNum": "500:1",
    "rateDesc": "كاپالەت پۇلى نىسبىتى 0.20% . "
  },
  "srceen1": {
    "pageEnTitle": "Secure Transaction",
    "pageTitle": "بىخەتەر سودا  قىلالايسىز",
    "subTitle": "مۇۋاپىق پىشاڭ نىسبىتىنى تاللاش",
    "desc1": "مەبلەغ سېلىش نىشانى ۋە خەتەرگە بەرداشلىق بېرىش ئىقتىدارىنى ئېھتىياتچانلىق بىلەن ئويلىشىپ، مۇۋاپىق پىشاڭ نىسبىتىنى تاللاڭ. ",
    "desc2": "خەۋپ-خەتەرنى باشقۇرۇش، كونترول قىلىشنى تېخىمۇ ياخشى ئىشقا ئاشۇرۇش ئۈچۈن، تۆۋەندىكى جەدۋەلدىن پايدىلىنىپ، ھەر بىر تۈرلۈك مۈلۈكنىڭ كاپالەت پۇلى تەلىپى ۋە ئەڭ چوڭ پىشاڭىنى ئىگىلەش كېرەك. ",
    "btn": "مەبلەغ سېلىشنى باشلاش "
  }
}
