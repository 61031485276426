export default {
  "box-name": "",
  "public-btn-text1": "即刻交易",
  "public-btn-text2": "查看點差和全部產品",
  screen0: {
    title: "CFD互換率/股息",
    desc: "請注意：CFD股息收取會在您的MT4帳戶上的餘額裏以存款或者取款形式反映出來，注釋為“Dividend”",
  },
  screen1: {
    title: "安全交易",
  },
  screen2: {
    title: "金融世界 觸手可及",
  },
  指数差价合约与股息调整: "指數差價合約與股息調整",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "了解您在股票和指數差價合約交易中的股息調整和分配機制",
  了解指数差价合约: "了解指數差價合約",
  指数差价合约: "指數差價合約",
  指数差价合约允许投资者通过股票市场指数的价格波动进行交易:
    "指數差價合約允許投資者透過股票市場指數的價格波動進行交易，而無需持有實際資產。它適用於全球各類股指，如標普500、道瓊指數、德國DAX等，提供彈性的交易選擇。",
  股息调整: "股息調整",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "股息是上市公司從利潤或保留資金中定期分配給股東的資金，當股票派發股息時，相關股票和指數差價合約產品也會進行相應的股息調整。",
  除息日与交易优化: "除息日與交易優化",
  指数差价合约的除息日是由指数提供商根据成分股的除息日设定的:
    "指數差價合約的除息日是由指數提供者根據成分股的除息日設定的，持多頭將獲得股息，持空頭則需支付股息。提前了解這些日期有助於優化收益管理。",
  股息调整明细: "股息調整明細",
  差价合约交易中相关产品的股息数据:
    "差價合約交易中相關產品的股息數據，以多頭（買入）股息和空頭（賣出）股息分開顯示",
  即刻交易: "即時交易",
  股息的常见问题: "股息的常見問題",
  什么是股息和除息日: "1、什麼是股息和除息日？",
  股息是上市公司从利润或保留资金中定期分配给股东的资金answer:
    "股息是上市公司從利潤或保留資金中定期分配給股東的資金，當股票派發股息時，相關股票和指數差價合約產品也會進行相應的股息調整。<br /><br />指數差價合約的除息日是由指數提供者根據成分股的除息日設定的，也是股息發放日。",
  股息调整的时间: "2、股息調整的時間",
  指数差价合约股息调整通常会在除息日的answer:
    "指數差價合約股息調整通常會在除息日的05:00-06:00（GMT+3）反映在您的MT4/MT5交易帳號中，並標註為「Dividend」。",
  股息对指数交易的影响: "3、股息對指數交易的影響",
  持有多头仓位answer:
    "持有多頭倉位（買入）：股息調整將存入您的MT4/MT5帳號。<br /><br />持有空頭倉位（賣出）：股息調整將從您的MT4/MT5帳號扣除。",

  股息调整的计算公式: "4、股息調整的計算公式",
  多头仓位answer:
    "多頭部位（買入）計算公式：手數 × 合約規格 × 多頭股息金額（以指數計價貨幣計算）<br /><br />空頭部位（賣出）計算公式：手數 × 合約規格 × 空頭股息金額（以指數計價貨幣計算）",
  股息调整计算示例: "5、股息調整計算範例",
  以SPXUSD为例answer: `以SPXUSD為例，假設其除息日為2024年10月11日，多頭股息金額為 0.050美元，空頭股息金額為-0.050美元。<br /><br />
如果您在除息日前一天(10月10日)收盤時持有1標準手的SPXUSD多頭部位（買入），則股息調整的計算如下：<br /><br />

股息調整 = 1（標準手） × 100（合約規格） × 0.050美元（多頭股息金額） = 5美元<br /><br />
在除息日的05:00-06:00（GMT+3），5美元將存入您的MT4/MT5帳號，備註為「Dividend」。<br /><br />

如果您持有空頭部位（賣出），5美元將從您的MT4/MT5帳號中扣除，備註為「Dividend」。 `,
  注意事项: "6、注意事項",
  股息调整仅适用于指数和股票差价合约交易answer: `股息調整僅適用於指數和股票差價合約交易，並會根據持有的倉位類型（多頭或空頭）相應調整帳號餘額，股息金額及其影響可能因具體指數和市場條件而異。<br /><br />

如有任何疑問，請聯絡您的客戶經理或查看我們的官方網站以獲取更多資訊。`,
};
