export default {
	"lang": "vi",
	"screen0": {
		"title": "金融世界<br />触手可及",
		"desc": "There is no Trading Secret!<br>Only to find what works best for you and applying it with strick risk management",
		"quick": "快速了解",
		"quick1": "4种资产类别",
		"quick2": "100+交易品种",
		"trList": [
			"层级",
			"买入价",
			"买入的交易量",
			"点差",
			"卖出价",
			"卖出价交易量"
		],
		"smallTrList": [
			"层级",
			"买入价",
			"点差",
			"卖出价"
		],
		"btn": "开始投资"
	},
	"screen1": {
		"pageEnTitle": "MT4 Trading Platform",
		"pageTitle": "MT4 交易平台",
		"desc": "长久以来，MetaTrader 4被广泛视为全球零售衍生品交易平台的行业基准，迄今为止最受欢迎的在线交易平台。",
		"title": "满足各种需求的交易平台 Metatrader 4",
		"desc1": "EBC MetaTrader4平台提供了直观的、高度定制化的用户界面，以及强化的图表功能和精细的订单管理、分析和警报工具，是您随时随地实时与市场保持同步的理想方式。"
	},
	"screen2": {
		"num1": "3种",
		"text1": "不同的图表类型",
		"num2": "9种",
		"text2": "时间范围",
		"num3": "50种",
		"text3": "预安装的技术指标",
		"title": "交易者可以通过访问3种不同的图表类型，9个时间范围，超过50种预安装的技术指标以及完全可定制的界面，这意味着无论您使用哪种交易风格或系统，都能轻松实现高效地头寸管理和精确地市场监控。",
		"desc": "无论何时何地，您都可以通过我们 Web 浏览器, 或PC端/移动设备快速 下载 MetaTrader 4，直通全球资本市场。",
		"btnText": "想更了解更多交易平台？",
		"btn": "Lab 学习中心 / 交易平台板块",
		"smallBtn": "Lab 学习中心"
	},
	"screen3": {
		"title": "有机会驾驭的人生，即刻启程。",
		"desc": " EBC 也与 MetaQuotes 一起努力调整我们的光纤网络和服务器，给交易者可大幅提高执行速度和所有资产类别的透明报价",
		"link1": "展示交易时段",
		"link2": "查看点差与全部商品",
		"list": [{
			"title": "全面的市场覆盖",
			"desc": "访问 100+资本市场产品，包括衍生品、股票、指数、商品差价合约等"
		}, {
			"title": "极致的灵活性",
			"desc": "登录一次即可从任何地方访问您的MT4账户。移动版本提供的功能包括交互式报价图表，全套交易订单以及流行的分析工具"
		}, {
			"title": "实时透明的报价",
			"desc": "在交易中，每一毫秒都很重要。由银行同业及交易买卖系统提供实时报价，高效透明"
		}, {
			"title": "先进的图表工具",
			"desc": "先进的图表功能使您能够通过价格判断更快更准地做出投资决策"
		}, {
			"title": "即时新闻",
			"desc": "全球各个资本市场的即时新闻让您掌握第一手市场资讯"
		}, {
			"title": "快速执行订单",
			"desc": "高速的买卖和即时成交交易指令。无论您以市价或挂单交易，均可利用止损以控制亏损或是止盈以保护利润。支持所有类型的交易订单，包括市场订单、挂单、止损订单以及追踪止损"
		}, {
			"title": "在线查阅账户",
			"desc": "您可以随时查阅自己的持仓、余额及盈亏等信息及状况，当前资产状况一目了然"
		}, {
			"title": "安全可靠",
			"desc": "平台与服务器之间的所有数据均采用高达256位数据加密，EBC为客户营造一个安全可靠的交易环境"
		}],
		"btn": "马上下载"
	},
	"screen4": {
		"pageEnTitle": "MT4 Trading Platform",
		"pageTitle": "MT4 交易平台",
		"title": "从单一账户交易几乎所有资本市场产品",
		"desc": "探索MT4平台的优势",
		"list": [{
				"title": "Tải xuống phiên bản MT4 dành cho điện thoại di động",
				"desc": "Kiểm soát giao dịch của bạn thông qua ứng dụng di động MT4 giúp bạn không bỏ lỡ bất cứ cơ hội nào."
			},
			{
				"title": "Tải xuống phiên bản MT4 dành cho máy tính để bàn",
				"desc": "Trải nghiệm tốc độ khớp lệnh cực nhanh và các chức năng giao dịch mạnh mẽ của EBC trên nền tảng MT4."
			},
			{
				"title": "Phiên bản web của MT4",
				"desc": "Bạn có thể đăng nhập vào MT4 của chúng tôi từ mọi nơi trên thế giới thông qua trình duyệt web."
			}
		],
		"text1": "下载我们",
		"text2": "MT4用户指南",
		"text3": "，更深入地了解MT4所有功能",
		"text4": "指导您轻松安装全球最受欢迎的交易终端",
		"btn": "Bắt đầu đầu tư"
	},
	"section1": {
		"title": "Đặt lệnh cực nhanh &nbsp;Giao dịch toàn cầu",
	},
	"section2": {
		"title": "Nền tảng giao dịch để đáp ứng các nhu cầu khác nhau",
		"desc": "EBC MetaTrader4 cung cấp giao diện người dùng đơn giản, với các chức năng biểu đồ toàn diện kèm theo công cụ phân tích mạnh mẽ, giúp bạn theo sát thị trường mọi lúc mọi nơi và đạt được khả năng quản lý đơn hàng cực tinh tế. Có mặt ở hơn 30 quốc gia, là sự lựa chọn của 70% nền tảng giao dịch, chiếm 90% khối lượng giao dịch phái sinh bán lẻ toàn cầu, MetaTrader4 cho tới nay vẫn là phần mềm giao dịch trực tuyến phổ biến nhất.",
	},
	"section3": {
		"title": "MetaTrader 4 là gì?",
		"desc1": "MetaTrader 4 (MT4) là một nền tảng giao dịch rất được ưa chuộng trên thế giới hiện nay, phần mềm được phát triển bởi công ty MetaQuotes Software. Nhờ công nghệ tiên tiến, sự tiện lợi dễ sử dụng và tính an toàn cao mà nó đã được các nhà giao dịch trên toàn thế giới công nhận. Chức năng chính của MT4 là giúp người dùng dễ dàng phân tích thị trường, có thể tham gia và rời khỏi thị trường, cắt lỗ và giới hạn giá một cách kịp thời và hiệu quả.",
		"desc2": "Lựa chọn nền tảng MT4 của EBC không chỉ có nghĩa là bạn có thể tùy chỉnh các chỉ báo kỹ thuật và EA, mà còn có thể chọn một loạt sản phẩm giao dịch bao gồm ngoại hối, kim loại quý, dầu thô, cổ phiếu và chỉ số."
	},
	"section4": {
		"title": "Những vấn đề thường gặp của MT4",
		"list": [{
			"title": "Tôi đã tải MT4, làm cách nào để tôi đăng nhập được tài khoản thực của mình?",
			"desc": "Sau khi nạp tiền thành công, tài khoản sẽ được kích hoạt, hệ thống sẽ cung cấp tài khoản giao dịch, mật khẩu và dữ liệu máy chủ cho bạn. Bạn chỉ cần nhập tài khoản kèm mật khẩu được nhận, chọn đúng máy chủ, sau đó đăng nhập vào tài khoản thực của mình."
		},
		{
			"title": "Làm thế nào để đổi mật khẩu trên MT4?",
			"desc": "Sau khi đăng nhập vào phần mềm MT4, bạn cần click vào mục \"Tool\" - \"Options\", sau đó thay đổi mật khẩu của bạn ở khu vực Sever."
		},
		{
			"title": "Làm thế nào để hiện thị biểu đồ giá trên MT4?",
			"desc": "Vui lòng thực hiện theo các bước sau:<br>1. Lựa chọn mục \"Insert\" - \"Technical indicators\" nằm ở trên cùng của Menu;<br>2. Hoặc bạn cũng có thể lựa chọn mục \"Technical indicators\" thông qua khu vực \"Navigation\"."
		},
		{
			"title": "Làm thế nào để tôi vẽ đường xu hướng hoặc các đường nghiên cứu giá khác?",
			"desc": "Vui lòng thực hiện theo các bước dưới đây:<br>1. Ở Menu trên cùng lựa chọn \"Insert\";<br>2. Lựa chọn đường xu hướng mà bạn muốn;<br>3. Nhả nhấp chuột và biểu tượng chữ thập sẽ xuất hiện cùng với đường xu hướng đã chọn. Để di chuyển các chữ thập trên bản đồ đã chọn, nhấp và giữ chuột, di chuyển chuột để thay đổi kích thước đường xu hướng. Bạn cũng có thể dùng phím tắt bằng cách nhấp vào \"Browse\" - \"Toolbar\" - \"Line Research\", và đường biểu đồ sẽ xuất hiện ở cuối thanh Menu. Bạn có thể lựa chọn các tùy chọn khác ở đầu màn hình bằng cách nhấp vào đường xu hướng, biểu tượng chữ thập hoặc đường thoái lui Fibonacci."
		},
		{
			"title": "Làm thế nào để thay đổi ngôn ngữ của MT4?",
			"desc": "Trên thanh công cụ ở trên cùng, lựa chọn \"View\" - \"Languge\" -> Ví dụ, Chinese (Simplified), sau đó khởi động lại phần mềm, ngôn ngữ sẽ được thay đổi thành Tiếng Trung giản thể."
		}
		],
	},
	"btn-text": "Tải xuống MT4",
	"download-btn1": "Download for Android",
	"download-btn2": "Download for iOS",
	"download-btn3": "Download for iPad",
	"download-btn4": "Download on Windows",
	"download-btn5": "Download for MacOS",
	"download-btn6": "WebTrader",
}