export default {
	"box-class-name": "cn-no1-box",
	"lang": "cn",
	"button-text": "发起挑战",
	"no1-page2": {
		"text1": "每秒最高聚合订单",
		"text2": "极速订单执行",
		"text3": "数据传输稳定高达",
		"text4": "银行级RAW ECN点差",
	},
	"no1-page3": {
		"text1": "英国FCA监管",
		"text2": "EBC Financial Group (UK) Ltd 由英国金融行为监管局(FCA)授权和监管。<br><span>监管号:927552</span>",
		"text3": "澳洲ASIC监管",
		"text4": "EBC Financial Group (Australia) Pty Ltd由澳大利亚证券和投资委员会(ASIC)授权和监管。<br><span>监管号:500991</span>",
	},
	"no1-page4": {
		"text1": "最强交易环境<br>不是EBC的极限，而是邀您挑战的标准线<br>只要挑战成功<br>EBC承诺奖励150%利润差额，上不封顶<br>您，只管发起挑战<br>剩下的，让EBC的实力来证明",
		"text2": "利润差额奖励",
	},
	"no1-page5": {
		"text1": "让 <span>85<i>%</i></span><br>以上的订单成交于更优的价格",
		"text2": "精准优化抛单算法，在价格优化率、订单成交率以及算法稳定性方面， EBC交易黑盒远远优于传统交易系统。",
	},
	"no1-page6": {
		"text1": "挑战规则",
		"text2": "以诚信与尊重为则，为挑战最高标准而来",
		"text3": "入金要求",
		"text4": "平台选择",
		"text5": "相同策略",
		"text6": "挑战成功",
		"text7": "无入金要求",
		"text8": "英国FCA监管",
		"text9": "澳洲ASIC监管",
		"text10": "所选交易商具备英国FCA或澳洲ASIC监管资质",
		"text11": "在EBC和所选交易商使用相同交易资金和策略",
		"text12": "在EBC的盈利若低于其他平台，奖励150%的利润差额",
		"text13": "所有的挑战者均可免费申请EBC VIP账号，EBC免费提供跨平台跟单软件",
	},
	"no1-page7": {
		"text1": "挑战须知",
		"text2": " 1.挑战账户<br>a)新用户：在官网新开账户后，联系在线客服开通VIP账户参与挑战。<br>b)现有用户：联系在线客服，开通VIP账户后参加挑战。",
		"text3": "2. 所选平台须持有英国FCA或澳洲ASIC正规监管。不含AR, EEA注册或Trading Name账户。",
		"text4": "3.仅限以下主流货币对 - AUD/USD、USD/CAD、EUR/USD、USD/CHF、GBP/USD、USD/JPY、NZD/USD。",
		"text5": "4.在EBC和所选交易商使用相同的交易资金和策略，所有策略需属于正常交易范畴。",
		"text6": "5.挑战者可以在任意挑战时间内选择结算，每个身份证仅可参与一次。",
		"text7": "6.挑战结束后，提交平仓的交割单。奖励将在审核后的两个工作日内发放至交易账户，可用于交易或提现。",
		"text8": "7.若账户有返佣情况，需客户配合提供相应证明。",
	},
	"no1-page8": {
		"text1": "距离挑战结束时间还剩",
		"text2": "本次活动时间为2024年1月18日00:00:00至2024年3月1日23:59:59（GMT+2）",
		"text2-style": "",
	},
	"button1-style": "",
	"button2-style": "",
	"button3-style": "",
	"button4-style": "",
}