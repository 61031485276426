export default {
  "screen0": {
    "enTitle1": "Together",
    "enTitle2": "Let's <text style='color#800'>Build The Future.</text>",
    "title1": "EBC 파트너가 되어 꿈을 실 <br style='display: none;'>",
    "title2": "현하세요",
    "list": ["국제 권위 이중 감독", "글로벌 협력 개방", "전반적 기술 솔루션"],
    "desc": "EBC는 깊은 협력을 위해 노력하는 모든 파트너를 소중히 여깁니다.업계를 선도하는 다층 보상 체계로 여러분과 무한한 가능성을 개척합니다.우리는 다양한 서비스 지원 프로그램을 제공하여 협력을 완전히 보호합니다.",
    "links": [{
      "url": "/about-us",
      "text": "Why EBC?"
    }, {
      "url": "",
      "text": "추가 정보"
    }],
		"more": "추가 정보"
  },
  "screen1": {
    "pageEnTitle": "Customization",
    "pageTitle": "맞춤형 서비스",
    "title": "소개중개인 (IB)<span> 백만달러</span>지원계획",
    "desc": "EBC 의 발전은 소개중개인 (IB) 의 지원을 떠날수 없습니다. 저희는 실력있는 소개중개인 (IB) 에게 훌륭한 발전공간을 제공해 드립니다.",
    "list": ["커미션 구조 ", "혁신적인 마케팅 및 거래 자료", "효율적인 정산 및 지급 절차", "전속 고객 담당 매니저", "다중 등급 커미션 시스템 ", "다중 언어 고객 지원팀", "완벽한 백그라운드와 보고서 도구", "업무발전계획"],
    "text": "전면적인 맞춤형 발전계획",
    "more": "더 많은 서비스 보기"
  },
  "screen2": {
    "pageEnTitle": "Trading  service",
    "pageTitle": "거래 커뮤니티 서비스",
    "title": "완벽한 업무발전 제휴 모델",
    "desc": "귀하의 요구 사항을 알려주시면 EBC는 실제 경험과 제안을 제공해 드릴 뿐만 아니라 다양한 비즈니스 개발 단계에서 요구 사항을 충족할 수 있도록 귀하의 요구 사항에 따라 맞춤형 기술 솔루션을 제공할 것입니다.",
    "list": ["거래 커뮤니티", "매매 신호", "리스크 제어관리"],
    "btn": "거래 커뮤니티"
  },
  "screen3": {
    "pageEnTitle": "Liquidity service",
    "pageTitle": "유동성 접입 서비스",
    "title": "EBC는 일괄처리프로세스(STP)를 지원합니다.",
    "desc": "다수의 국제 최고 유동성 공급업체에 액세스하여 중개인의 헤지 비용을 절감하고, 신속하게 주문을 실행할 수 있도록 합니다. 또한 전담 기술팀이 24*7시간 기술지원 서비스를 제공합니다.",
    "remark": "오직 시간만이 고객님으로 하여금 저희를 더 잘 알게 합니다."
  },
  "screen4": {
    "pageEnTitle": "Liquidity service",
    "pageTitle": "신흥 경영 방식",
    "title": "EBC는 항상 변화를 포옹하는 열정과 능력을 갖추고 있습니다.",
    "desc": "업계의 새로운 발전 형식을 위해 끊임없이 변화를 추구하며, 저희는 시장 발전의 추세에 부합해야만 안정적으로 발전할 수 있다고 믿습니다. 귀하께 혁신적인 제휴방안이 있으시다면 언제든지 연락해 주시기 바랍니다.",
    "list": ["<span>4</span>종 자산종류", "<span>100</span><sup>+</sup>거래 품종"]
  },
	"box-class": "partner-box-en"
}
