export default {
  "box-name": "en-style2",
  "public-btn-text": "Register Now",
  "screen0": {
    "title": "EBC provides a truly top-tier<br>global liquidity for institutional investors",
    "enTitle": "<text style='color:#6c100f;font-family: 'Source Serif Pro', Georgia, 'Times New Roman', serif;'>EBC THE FUTURE</text>",
    "list": [{
        "title": "Safety",
        "desc": "Connecting to Equinix centers worldwide through the optical fiber dedicated line of peer-to-peer networking to achieve an extremely fast order execution as low as 20 milliseconds. "
      },
      {
        "title": "Compliance",
        "desc": "All client funds are held in segregated independent trust accounts with top-tier banks and subject to strict regulation. "
      },
      {
        "title": "Liquidity",
        "desc": "EBC has established long-term and stable cooperative relationships with world’s leading  international banks, allowing you to make transactions easily at ultra-low costs. "
      }
    ]
  },
  "screen1": {
    title: 'Liquidity Depth',
    "trList": [
      "hierarchy",
      "buying price",
      "trading volume bought ",
      "point difference",
      "selling price ",
      "selling price volume "
    ],
    "mobileTrList": [
      "hierarchy",
      "buying price",
      "point difference",
      "selling price "
    ]
  },
  "screen2": {
    "pageEnTitle": "Clearing System",
    "pageTitle": "Clearing System",
    "title": "EBC has a safe, transparent and credible clearing ecosystem"
  },
  "screen3": {
    "pageEnTitle": "EBC Priviate Room",
    "pageTitle": "EBC Priviate Room",
    "title": "No uniform criteria for liquidation, and as such, all possibilities must be considered. ",
    "desc": "If you are a “big player” and don’t want to disclose your “large order” to others, EBC Private Room will be your best choice."
  },
  "screen4": {
    "title": "Take control of your exclusive liquidity and easily grasp global markets",
    "desc": "Private Room can hide part of your transaction information, which means that your “large order” may be filled at a better price without serious slippage or rejection due to Last Look. As shown in the figure above, the large orders traded through Private Room are filled at more favorable prices than anticipated."
  },
  "screen5": {
    "it": "Only time, let you know me better",
    "ct": "* there may be slippage, delay and various uncertain factors in the transaction. Maybe you will not notice these details from senior traders, but if you have any inconvenience, please give us feedback in time. EBC advocates high quality and geek spirit, and actively embraces the uncertain future. Strive to bring customers a minimalist, pure and comfortable new trading experience.",
    "title": "Meeting and exceeding your expectations",
    "desc": "We will actively listen to your feedback and attach great importance to enhancing your personalized trading experience. EBC development team has always been committed to the R&D and improvement of new functions and technologies to further optimize our customers’ online trading experience. ",
    "btn": "Explore our advantages"
  }
}
