export default {
	"faq-class-name": "",
	"faq-title":"FAQ",
	"faq-desc": "자주묻는질문이나 고객센터로 직접 문의해주세요.EBC 고객지원팀은 언제나 여러분의 문의를 기다리고 있습니다.",
	"faq-search": "검색 키워드를 입력하세요",
	"faq-tabs":[
		"등록 및 계좌 개설",
		"입금 및 출금",
		"거래 관련",
		"무역 터미널",
		"지식",
	],
	"faq-data":{
		0: [
			{
				"Q": " EBC에서 거래를 시작하는 방법은 무엇입니까?",
				"A": " EBC에서 거래를 시작하려면 다음 단계를 완료하세요. 1. EBC 계정 등록 2. 개인 정보 확인 3. 거래 계좌 개설 및 입금 4. 거래를 위해 MT4 거래 터미널에 로그인"
			},
			{
				"Q": " 동일한 이메일 주소를 사용하여 여러 EBC 계정을 등록할 수 있습니까?",
				"A": " 안타깝게도 이메일 주소는 하나의 EBC 계정에만 등록할 수 있습니다. 그러나 하나의 EBC 계정으로 여러 거래 계정을 만들 수 있습니다."
			},
			{
				"Q": " KYC 인증을 완료하는 목적은 무엇입니까?",
				"A": " 규정을 준수하고 규제를 받는 회사로서 당사는 관할 규제 당국이 정한 규정에 따라 KYC 검증을 수행해야 합니다. 여기에는 유효한 주민등록증, 지난 6개월 이내에 발급된 주소지 증명서 등 KYC 확인 정보 수집이 수반됩니다."
			},
			{
				"Q": " EBC 계좌 확인을 위해 어떤 서류가 필요합니까?",
				"A": "KYC 인증을 완료하려면 유효한 ID, 휴대폰 번호, 이메일 주소를 제공해야 합니다."
			},
			{
				"Q": " 확인 서류를 어떻게 다시 제출하나요?",
				"A": " KYC 문서를 다시 제출하는 절차는 다음과 같습니다. 1. 클라이언트 포털에 로그인하고 내 계정으로 이동합니다. 2. 개인정보에서 요청에 따라 정보를 다시 입력하세요. 3. 이전에 제출한 문서 옆에 있는 삭제 버튼을 클릭한 후 다시 업로드하세요. 4. 완료되면 KYC 문서가 곧 확인되므로 편안히 앉아 휴식을 취하세요."
			},
			{
				"Q": " 내 EBC 계정에서 개인 정보를 어떻게 업데이트할 수 있나요?",
				"A": " 등록된 이름은 수정할 수 없습니다. 그러나 등록된 휴대폰 번호, 이메일 주소 및 은행 정보는 계속 업데이트할 수 있습니다."
			},
			{
				"Q": " 내 자금과 개인정보는 안전한가요?",
				"A": "귀하의 자금 보안은 우리에게 가장 중요합니다. 우리는 전 세계 여러 주요 규제 기관의 면밀한 감독을 받고 있으며 관련 법률 및 규정을 엄격히 준수합니다. 고객 자금은 해외 유명 은행의 개인 계좌에 별도로 보관되어 귀하의 자금과 투자 수입을 이중으로 보호합니다. 또한 당사는 귀하의 개인정보를 보호하기 위해 필요한 모든 보안 조치를 구현하는 데 최선을 다하고 있습니다."
			},
			{
				"Q": " 어떤 계정 유형을 사용할 수 있나요?",
				"A": " 우리는 표준 계정과 전문가 계정의 두 가지 계정 유형을 제공합니다. <a href=\"/TradingAccount\">계정 비교표를 보려면 여기를 클릭하세요</a>."
			},
			{
				"Q": " 내 거래 계좌 번호를 찾는 방법은 무엇입니까?",
				"A": " 거래 계좌 번호를 찾으려면 다음 단계를 따르십시오. 1. EBC 계좌에 로그인합니다. 2. \"홈\" - \"실제 계좌\"를 클릭합니다. 3. 실제 계좌 아래에 거래 계좌 번호가 표시됩니다."
			},
			{
				"Q": " a 사이에 차이가 있나요? 살다 계좌와 데모 거래 계좌가 있나요?",
				"A": "두 회사의 거래프로세스, 거래상품, K라인 동향이 일관됩니다. 실계좌를 운영하려면 실제적인 재정적 위험이 필요합니다. 데모 계정은 사용자가 실제 자금을 사용하지 않고도 시장 역학을 배우고 이해하는 데 도움이 됩니다."
			},
			{
				"Q": " EBC 사무실은 어디에 있나요?",
				"A": " 우리는 런던, 도쿄, 시드니, 싱가포르, 홍콩과 같은 주요 금융 허브에 사무실을 두고 있습니다. 필요한 경우 자세한 내용은 당사 웹사이트를 참조하세요."
			},
			{
				"Q": " 계좌 개설에 연령 제한이 있나요?",
				"A": " 계좌를 개설하려면 18세 이상 65세 미만이어야 합니다. 단, 일본에 거주하는 고객의 경우 최대 연령 제한은 70세입니다."
			},
			{
				"Q": " EBC가 고객을 받아들이지 않는 국가는 어디입니까?",
				"A": "미국, 캐나다, 말레이시아 등 특정 지역에 거주하는 고객에게는 당사 제품 및 서비스가 제공되지 않습니다. 제한 국가 목록은 정기적으로 업데이트됩니다. 자세한 내용은 LiveChat을 통해 고객 지원팀에 문의하세요."
			},
			{
				"Q": " 내 계정을 삭제하는 방법은 무엇입니까?",
				"A": " 귀하의 계정 삭제를 원하신다니 안타깝습니다. 더 나은 지원을 드릴 수 있도록 등록된 이메일 주소를 사용하여 cs@ebc.com으로 요청해 주세요. 귀하의 요청을 받은 후 고객 지원 부서에서 처리해 드립니다."
			},
			{
				"Q": " 내 거래 계좌 레버리지를 어떻게 업데이트할 수 있나요?",
				"A": " 레버리지를 업데이트하려면 다음 단계를 따르십시오. 1. 고객 포털에 로그인하고 홈을 클릭합니다. 2. 내 계정에서 계정 오른쪽에 있는 \"설정\"을 탭하고 \"레버리지 수정\"을 탭합니다. 3. 선택 적절한 레버리지를 선택하고 \"예\"를 클릭하여 레버리지를 업데이트하세요."
			},
			{
				"Q": "고객 서비스에 문의하면 어떻게 되나요? 근무시간은 어떻게 되나요?",
				"A": " 등록, 플랫폼 다운로드 또는 기타 관련 주제에 대해 문의사항이 있는 경우 당사 고객 지원팀이 24시간 내내 도움을 드립니다. cs@ebc.com으로 이메일을 보내시거나 온라인 클라이언트 서비스를 이용해 연락하실 수 있습니다."
			},
			{
				"Q": " 필요한 경우 불만을 제기하는 절차는 무엇입니까?",
				"A": " 불편을 끼쳐드려 죄송합니다. 귀하의 만족을 보장하는 것이 우리의 최우선 과제이며 귀하가 경험한 모든 문제를 해결하기 위해 최선을 다하고 있습니다. 불만사항이 있으시면 언제든지 cs@ebc.com으로 이메일을 보내주세요."
			}
		],
		1: [
			{
				"Q": " 입금하기 전에 알아야 할 사항이 있나요?",
				"A": "EBC는 연중무휴 24시간 입금 방법을 제공합니다. 입금 시 지정된 기한 내에 결제를 완료하는 것이 중요합니다. 그렇지 않으면 입금 주문이 자동으로 취소됩니다. 입금 방법에 따라 입금 한도가 다를 수 있으므로 입금 전 관련 내용을 확인하는 것이 좋습니다."
			},
			{
				"Q": " 출금 요청을 제출하기 전에 알아야 할 사항이 있나요?",
				"A": " 국내 은행 송금, 암호화폐, 국제 은행 송금 등 다양한 출금 방법을 제공합니다. 국내 은행 송금 및 국제 은행 송금의 경우 최소 출금 금액은 USD 100, 암호화폐 출금의 경우 USD 50입니다."
			},
			{
				"Q": " 예금 방법?",
				"A": "입금하려면 다음 단계를 따르십시오. 1. 클라이언트 포털에 로그인하고 자금을 탭합니다. 2. 메뉴 오른쪽에 있는 입금을 찾습니다. 3. 선호하는 입금 방법을 선택하고 거래 계좌를 선택합니다. 자금을 받고 싶습니다. 4. 금액을 입력한 후 결제 확인을 클릭하세요. 5. 그러면 공급업체 페이지로 리디렉션되며 지침에 따라 입금을 완료하세요."
			},
			{
				"Q": " 타인의 은행카드를 이용하여 자금을 입출금할 수 있나요?",
				"A": "EBC는 제3자 결제를 허용하지 않습니다. 모든 입금은 귀하의 이름으로 된 계좌에서 이루어져야 합니다. (모든 예금은 반드시 귀하의 은행 계좌에서 이루어져야 합니다.) 자금이 제3자로부터 온 것으로 의심되는 경우, 당사는 자금을 송금인에게 반환하고 신원 및 자금 출처가 확인될 때까지 귀하의 계좌 잔액을 일시적으로 동결할 권리를 보유합니다. 완전한. 확인이 이루어지지 않을 경우 당사는 귀하의 계정 잔액을 동결할 권리가 있으며, 현재 귀하는 계정 잔액을 인출할 수 없습니다. EBC는 제3자에 대한 인출을 처리하지 않습니다. 특별한 상황이 있는 경우 당사에 문의하시기 바랍니다."
			},
			{
				"Q": " 입금 한도는 얼마인가요?",
				"A": " 결제 방법에 따라 입금 한도가 다릅니다. 자세한 내용은 입금 페이지를 참조하세요."
			},
			{
				"Q": " EBC는 입금 또는 출금 수수료를 부과합니까?",
				"A": " EBC는 입출금 처리 수수료를 부과하지 않습니다. 일부 암호화폐 서비스 제공업체는 특정 처리 수수료를 부과할 수 있습니다."
			},
			{
				"Q": "근무시간 외에 입금 및 출금이 가능한가요?",
				"A": " 주말 및 공휴일에도 평소와 같이 입출금 요청을 제출하실 수 있습니다. 다만, 주말 휴무일로 인해 접수 처리가 지연될 수 있으며, 늦어도 다음 영업일(월요일)에 처리될 예정이오니, 이용에 참고하여 주시기 바랍니다."
			},
			{
				"Q": " 입출금을 처리하는 데 시간이 얼마나 걸리나요?",
				"A": " 일반적인 상황에서 영업일 기준 입금 처리 시간은 약 1~15분입니다. 영업일 16:00 UTC+8 이전에 제출된 출금 신청서는 당일 즉시 계좌에 적립될 수 있습니다."
			},
			{
				"Q": " 여러 개의 은행 카드를 통해 자금을 입금하고 인출할 수 있나요?",
				"A": " 네, 본인 명의의 은행 카드를 모두 사용할 수 있습니다."
			},
			{
				"Q": " 아직 미결 주문이 있는 거래 계좌에서 자금을 인출할 수 있나요?",
				"A": "예, 그러나 인출 작업으로 인해 미결 주문이 청산되는 것을 방지하려면 계좌에 충분한 자금이 있어야 합니다. 위험관리부서에서 출금작업이 계좌청산으로 이어질 수 있다고 판단하는 경우 출금 신청을 거부합니다. 출금작업은 청산작업이 완료된 후에만 수행할 수 있습니다."
			},
			{
				"Q": " 출금시 입금금액이 아닌 다른 결제수단으로 결제할 수 있나요?",
				"A": " 입금액과 동일한 방법으로 초기 입금액을 인출할 수 있습니다. 그러나 선호하는 인출 방법을 사용하여 수익을 인출할 수 있는 유연성이 있습니다."
			},
			{
				"Q": " 출금/입금이 실패한 이유는 무엇인가요?",
				"A": "입출금 실패의 원인은 다음과 같습니다. 1. 본인 명의가 아닌 카드로 입금하는 경우 2. 사용한 은행카드가 비활성화되었거나 인식되지 않는 경우 3. 이체 시 해당 은행에서 정보 또는 출금 계좌 정보가 잘못 입력되었습니다. 위와 같은 상황이 발생하면 LiveChat을 통해 고객 지원팀에 문의하거나 cs@ebc.com으로 이메일을 보내 도움을 요청하세요."
			},
			{
				"Q": " 내 거래 계좌 간에 자금을 이체할 수 있나요?",
				"A": " 예. 귀하는 귀하의 거래 계좌 간에 자금 이체 요청을 제출할 수 있습니다."
			},
			{
				"Q": " 한 번의 출금 요청을 제출한 후 다른 출금 요청을 제출할 수 없는 이유는 무엇입니까?",
				"A": " 두 번째 인출을 제출하기 전에 첫 번째 인출이 검토 승인될 때까지 기다려야 합니다."
			},
			{
				"Q": " 출금을 취소한 후 자금이 거래 계좌로 즉시 반환되지 않는 이유는 무엇입니까?",
				"A": "출금을 취소하면 자금은 자동으로 주 지갑으로 반환되며, 이후 주 지갑에서 거래 계좌로 자금을 이체할 수 있습니다."
			}
		],
		2: [
			{
				"Q": " EBC에서는 어떤 상품을 거래할 수 있나요?",
				"A": " EBC는 다양한 제품을 제공하며 다양한 포트폴리오를 생성하려면 하나의 거래 계좌만 있으면 됩니다. <a href=\"/tradingproduct\">전체 제품 목록을 보려면 여기를 클릭하세요</a> ."
			},
			{
				"Q": " 각 계좌 유형의 최대 및 최소 거래량은 얼마입니까?",
				"A": " STD 계좌와 PRO 계좌의 최대 거래량은 40랏이며, 최소 거래량은 거래 종류에 따라 다릅니다. 더 자세한 내용을 알고 싶으시면 <a href=\"/TradingAccount\">연락주세요 계정 규칙을 보려면 여기를 클릭하세요</a> ."
			},
			{
				"Q": " 시장가 실행이란 무엇이며 보류 주문이란 무엇입니까?",
				"A": "시장 실행(Market Execution)이란 주문이 즉시 실행되고, 투자자가 현재 시장에서 가능한 최상의 가격에 따라 매수 또는 매도하는 것을 의미합니다. 보류 중인 주문 거래는 투자자가 예상되는 매수 및 매도 가격을 결정하는 예정된 실행입니다. 시장 가격이 예상 가격에 도달하거나 초과하면 거래 시스템이 자동으로 거래를 실행합니다."
			},
			{
				"Q": " 매수 제한, 매수 정지, 매도 제한, 매도 정지란 무엇입니까?",
				"A": " 구매 한도: 가격이 미리 정해진 낮은 수준까지 하락할 때 매수하세요. 저가 매수 전략에 적합합니다. 예를 들어, 금 가격이 2000년에서 1980년까지 하락했다가 다시 상승할 것으로 예상되는 경우 구매 한도를 1980으로 설정하고 더 낮은 가격에 구매할 수 있습니다. 판매 한도:가격이 미리 정해진 수준 이상으로 상승하면 매도하므로 고가 매도 전략에 적합합니다. 예를 들어, 2000년부터 2020년까지 금 가격이 상승했다가 다시 하락할 것으로 예상된다면 2020년에 판매 제한을 설정하고 높은 가격에 판매될 때까지 기다릴 수 있습니다. 구매 정지 : 가격이 미리 결정된 높은 수준까지 상승하면 매수하며 추격 전략에 적합합니다. 예를 들어, 상승 추세는 금 가격이 2020년을 넘어야 확인할 수 있다고 생각한다면 2020년에 Buy Stop을 설정하고 상승 돌파를 기다린 후 매수할 수 있습니다. 매도 중지: 가격이 미리 결정된 낮은 수준으로 떨어지면 판매하며 추격 전략에 적합합니다. 예를 들어, 금 가격이 1980 이하로 하락해야만 하락세를 확인할 수 있다고 생각한다면 매도 중지를 1980으로 설정하고 금 가격이 1980 이하로 떨어질 때까지 기다렸다가 매도할 수 있습니다."
			},
			{
				"Q": " Stop Out 레벨은 무엇입니까?",
				"A": " 스톱아웃 수준은 30%입니다."
			},
			{
				"Q": "내 주문에 '이익실현'과 '손절매'를 어떻게 설정하나요?",
				"A": " MT4: PC , 손절매 설정 및 이익 실현 단계는 다음과 같습니다. 1. 관리하려는 거래 주문을 선택합니다. 2. 주문을 마우스 오른쪽 버튼으로 클릭하고 \"주문 수정 또는 삭제\"를 선택합니다. 3. 나타나는 창에서 \"손절매 가격\"과 \"이익 가격\"을 설정합니다. MT4: 모바일 , Stop Loss 및 Take Profit을 설정하는 단계는 다음과 같습니다. 1. \"Trading\" 페이지에서 수정하려는 포지션 주문을 찾습니다. 2. 주문을 클릭하거나 길게 누르고 팝업 메뉴에서 \"Modify\"를 선택합니다. 3. 나타나는 창에서 \"손절매 가격\"과 \"이익 가격\"을 설정하세요"
			},
			{
				"Q": " 헤징이란 무엇입니까?",
				"A": " 헤징은 동일한 품종, 동일한 로트 크기이지만 반대 방향으로 개시 주문을 하는 것입니다."
			},
			{
				"Q": " EBC는 거래 신호를 제공합니까?",
				"A": "우리는 거래 신호를 직접 제공하지는 않지만 거래 커뮤니티에 대한 액세스를 제공합니다. 이 커뮤니티 내에서 다른 커뮤니티 사용자가 제공하는 다양한 거래 신호 중에서 선택할 수 있습니다."
			},
			{
				"Q": " EBC는 EA(Expert Advisor)를 지원하나요?",
				"A": " 예. 원하는 EA를 사용할 수 있습니다."
			}
		],
		3: [
			{
				"Q": " 어떤 거래 터미널을 사용할 수 있나요?",
				"A": " 현재 EBC는 MT4 트레이딩 터미널 사용만 지원하며, <a href=\"/MT4\">지금 다운로드하려면 여기를 클릭하세요</a> ."
			},
			{
				"Q": " MT4 플랫폼에는 어떤 서버를 사용해야 합니까?",
				"A": " EBC 실계정의 경우 서버 EBCFinancialGroupKY-Live를 사용하고, 데모 계정의 경우 서버 EBCFinancialGroupKY-Demo를 사용합니다."
			},
			{
				"Q": " 왜 트레이딩 터미널에 로그인할 수 없나요?",
				"A": " 로그인 실패 거래 플랫폼은 다음으로 인해 발생할 수 있습니다. 사용자가 비밀번호를 잘못 입력함 또는 그것은 다음과 관련이 있을 수 있습니다네트워크 연결 실패. 로그인 문제가 발생하면 먼저 비밀번호를 다시 입력하거나 네트워크 연결을 확인하는 것이 좋습니다."
			},
			{
				"Q": " MT4에 로그인할 수 없습니다. \"잘못된 계정\" 오류가 나타나는 이유는 무엇입니까?",
				"A": " \"잘못된 계정\" 메시지가 나타나는 경우 잘못된 서버 정보를 입력했거나 비밀번호가 올바르지 않기 때문일 수 있습니다. 이 문제는 올바른 서버를 선택하고 비밀번호를 올바르게 입력하면 해결될 수 있습니다."
			},
			{
				"Q": " \"연결 없음\" 프롬프트가 표시되는 이유는 무엇입니까?",
				"A": " '연결 없음' 오류가 발생하는 경우 서버 연결이 끊어졌거나 로컬 네트워크 연결에 문제가 있기 때문일 수 있습니다. 먼저 로컬 네트워크에서 웹을 정상적으로 검색할 수 있는지 확인하세요. 네트워크가 정상이라면 다른 데이터센터(DC) 서버로 전환 후 재접속해 보세요."
			},
			{
				"Q": " \"새 주문\" 버튼이 회색으로 표시되는 이유는 무엇입니까?",
				"A": " 새 주문을 생성할 수 없는 경우1. 계정이 성공적으로 연결되지 않았거나 연결이 끊어졌습니다. 2. 읽기 전용 비밀번호를 사용하여 로그인하세요. 이 유형의 비밀번호는 계정 보기만 가능하며 거래 작업을 수행할 수 없습니다. 3. STD 계정은 접미사 \".s\"가 붙은 기호만 거래할 수 있으며, 회색 영역에 있는 품종은 거래할 수 없습니다."
			},
			{
				"Q": " 기호를 추가하거나 제거하는 방법은 무엇입니까?",
				"A": " 다음을 통해 MT4에서 특정 기호를 추가하거나 숨기려면 데스크탑 터미널 1. 거래 터미널에 로그인하고 아무 곳이나 마우스 오른쪽 버튼으로 클릭하세요. 2. \"거래 유형\"을 선택합니다. 3. 거래 기호 그룹을 선택합니다. 4. \"시세\"에 추가하려면 더블클릭하세요. 5. 숨기려면 \"숨기기\"를 선택하세요. 모바일 단말기1. 로그인 후 오른쪽 상단의 \"+\"를 클릭하세요. 2. 거래 기호 그룹을 선택합니다. 3. \"+\"를 클릭하여 추가하세요. 모바일 단말기에서 품종을 제거하려면: 1. 왼쪽 상단에 있는 펜 모양의 버튼을 클릭합니다. 2. 카테고리를 삭제하려면 확인란을 선택하고 삭제를 클릭하세요."
			},
			{
				"Q": " 입찰 라인을 설정하는 방법은 무엇입니까?",
				"A": " 데스크톱 1. 차트의 아무 곳이나 마우스 오른쪽 버튼으로 클릭합니다. 2. \"속성\"을 선택하여 디스플레이 설정으로 들어갑니다. 3. \"가격선 구매\" 옵션을 선택하세요. iOS: 1. 차트를 엽니다. 2. 아무 곳이나 클릭하고 \"설정\"을 선택합니다. 3. \"가격선 구매\" 옵션을 선택하세요. 안드로이드: 1. 설정 메뉴로 들어갑니다. 2. '차트'를 선택하세요. 3. 입찰 라인 옵션을 선택하여 활성화합니다."
			},
			{
				"Q": " MT4에서 시간대를 어떻게 업데이트하나요?",
				"A": " 안타깝게도 MT4 터미널에는 시간대를 업데이트할 수 있는 옵션이 없습니다."
			},
			{
				"Q": " 내 보류 주문이 실행되지 않는 이유는 무엇입니까?",
				"A": "다음과 같은 이유로 보류 주문이 실행되지 않을 수 있습니다. 1. 계정의 사용 가능한 여유 증거금이 새 포지션을 개설하기에 부족합니다. 2. 지정가 주문의 가격이 정확하지 않으며 지정가 주문의 실행 기준을 충족하지 않습니다. 3. 주요 금융 이벤트가 발표되는 경우 시장은 변동될 수 있으며, 실제 주문 가격은 지정가 주문 가격과 다를 수 있습니다. 보류 중인 주문의 실행 실패는 위의 이유에만 국한되지 않으며 당시의 특정 상황에 따라 결정되어야 한다는 점에 유의하시기 바랍니다."
			},
			{
				"Q": " 모든 기호의 거래 시간을 확인하는 가장 좋은 방법은 무엇입니까?",
				"A": " MT4 트레이딩 터미널에 로그인하고, 보려는 트레이딩 기호를 마우스 오른쪽 버튼으로 클릭한 후 \"사양\"을 클릭하여 확인하세요."
			},
			{
				"Q": " \"잘못된 SL/TP\" 오류 메시지가 나타나는 이유는 무엇입니까?",
				"A": "\"잘못된 SL/TP\" 오류는 다음과 같은 이유로 발생할 수 있습니다. 1. 현재 가격이 설정된 이익 실현 및 손절매 지점을 초과하여 거래를 완료할 수 없습니다. 2. STD 계좌의 주문가격은 최근 가격으로부터 50 포인트 이상이어야 합니다."
			},
			{
				"Q": " \"시장 폐쇄\" 메시지가 나타나는 이유는 무엇입니까?",
				"A": " 마켓이 마감되면 \"마켓 마감\"이 표시됩니다."
			},
			{
				"Q": " 포지션을 부분적으로 청산할 수 있나요(오픈 포지션 수 감소)?",
				"A": " 귀하의 포지션이 부분적으로 폐쇄될 수 있습니다. 현재 해당 상품에 대한 최소 거래 로트 요구 사항을 초과하는 경우 포지션을 부분적으로 청산하도록 선택할 수 있습니다."
			},
			{
				"Q": " 장 마감 후에도 거래가 가능한가요?",
				"A": " 시장이 닫히면 더 이상 거래할 수 없습니다. 시장이 열려 있을 때만 거래(주문 열기, 닫기, 실행 및 수정)를 할 수 있습니다."
			},
			{
				"Q": " 내 MT4가 오프라인이 되면 내가 열었던 모든 포지션을 닫아야 합니까?",
				"A": "MT4 거래 시스템을 닫으면 이익 실현, 손실 중지 및 보류 주문이 계속 유지됩니다. 그러나 MT4 거래 시스템을 종료하면 추적 손절매 및 EA 거래 시스템이 무효화됩니다."
			},
			{
				"Q": " 내 거래 내역을 어떻게 확인하나요?",
				"A": " 1. MT4 거래 터미널에 로그인합니다. 2. \"터미널\" - \"계좌 내역\" 탐색을 클릭합니다. 3. 종료된 거래 내역을 봅니다."
			},
			{
				"Q": " 거래 내역을 어떻게 내보내나요?",
				"A": " 1. MT4 트레이딩 터미널에 로그인하고 \"계좌 내역\"을 클릭합니다. 2. 마우스 오른쪽 버튼을 클릭하고 \"상세 계좌 명세서로 저장\"을 선택합니다."
			},
			{
				"Q": " MT4에서 자본 곡선을 보는 방법은 무엇입니까?",
				"A": " 1. MT4에서 계좌 내역을 엽니다. 2. 마우스 오른쪽 버튼을 클릭한 후 \"상세 계좌 명세서로 저장\"을 선택합니다. 3. 저장된 그래프를 다시 클릭하여 자본 곡선을 확인합니다."
			},
			{
				"Q": " PAMM이란 무엇이며 어떻게 사용하나요?",
				"A": "PAMM은 Percent Allocation Management Model의 약어로, 관리를 위한 집단 트레이딩 자금의 한 형태입니다. 투자자는 자격을 갖춘 거래자 또는 펀드 매니저를 선택하여 자신의 자금과 공동 자금을 사용하여 거래를 수행하여 이익을 얻고 합의된 비율에 따라 이익을 분배할 수 있습니다."
			}
		],
		4: [
			{
				"Q": " 파생상품 거래란 무엇인가요?",
				"A": " 파생상품 거래시장은 현재 일평균 거래량이 약 4조 달러에 달하는 세계 최대의 금융거래 시장입니다. 세계의 다양한 통화의 환율은 유동적이며 유로/USD(EUR/USD), USD/JPY(USD/JPY) 등 거래 시 통화쌍 형태로 나타납니다. 하나의 통화를 매수하고 다른 통화를 매도하는 거래 방식을 통화쌍으로 나타냅니다."
			},
			{
				"Q": " 확산이란 무엇입니까?",
				"A": " 스프레드는 특정 거래 상품의 매수가와 매도가의 차이로, 매 거래마다 지불해야 하는 거래비용이기도 합니다."
			},
			{
				"Q": "레버리지란 무엇입니까?",
				"A": " 레버리지란 투자자가 전체 거래 없이도 적은 비율의 자금을 사용하여 더 큰 가치의 외환 목표에 투자할 수 있음을 의미합니다. 레버리지 수준에 따라 점유되는 마진 금액이 결정됩니다."
			},
			{
				"Q": " 마진이란 무엇입니까?",
				"A": "선불금이라고도 불리는 마진을 통해 거래자는 자금의 일부를 활용하여 레버리지 투자 원칙에 따라 포지션을 구축할 수 있습니다. 거래 포지션을 유지하기 위한 담보 역할을 합니다. 시장에서 레버리지는 일반적으로 20배에서 500배 사이이므로 투자자는 높은 레버리지로 활발하게 거래할 수 있습니다. 그러나 마진 시스템은 수익을 증폭시킬 수 있지만 거래 위험도 증가한다는 점에 유의하는 것이 중요합니다. 예를 들어, 오늘 US$100,000 상당의 거래를 실행하려는 Mr. Zhao를 생각해 보겠습니다. 마진 거래와 500:1의 레버리지 비율을 가정할 때 Zhao 씨는 이 거래를 수행하는 데 US$200만 있으면 됩니다(100,000/500 = 200 US 달러). 즉, 자본금을 500배로 늘리면 10,000달러를 투자하면 500만 달러의 거래가 가능해집니다."
			},
			{
				"Q": " 주문을 개시하는 데 필요한 마진을 어떻게 계산하나요?",
				"A": "증거금 = 계약 규모 * 현재 가격 / 레버리지 * 랏 수. 이 공식은 주문을 시작하는 데 필요한 마진을 결정합니다. 계약 규모(포지션 규모), 자산의 현재 가격, 브로커가 제공하는 레버리지, 거래된 로트 수 등의 요소를 고려합니다."
			},
			{
				"Q": " 거래에서 잔액, 자본 및 사용 가능한 마진 간의 관계는 무엇입니까?",
				"A": " 자산 = 잔액 – 점유 마진 ± 변동 손익. 각 용어가 나타내는 내용은 다음과 같습니다. 균형 : 거래계좌에 있는 자금의 총액입니다. 점유 마진: 현재 오픈 포지션에 묶여 있는 마진입니다. 유동손익 오픈 포지션의 미실현 손익으로, 시장 변화에 따라 변동됩니다. 자본은 본질적으로 오픈 포지션에 묶인 마진과 변동 이익 또는 손실을 고려한 거래 계좌의 순 가치입니다. 이는 계좌의 실시간 가치를 반영하며 추가 거래 활동에 사용 가능한 마진을 결정하는 핵심 요소입니다."
			},
			{
				"Q": " 왜 마이너스 잔액이 발생합니까?",
				"A": " 시장이 합리적인 거래 가격을 제공할 수 없는 극단적인 시장 상황에서는 마이너스 잔액이 발생할 수 있습니다. 이러한 상황은 계좌 잔액과 증거금을 초과하는 최종 거래 손실로 이어질 수 있으며, 이로 인해 계좌 잔액이 마이너스가 될 수 있습니다."
			},
			{
				"Q": " 증거금(계약금)과 사용 가능한 증거금(계약금 사용 가능)의 차이는 무엇입니까?",
				"A": " 마진은 공개 주문을 유지하기 위해 브로커가 보유하는 자금을 의미합니다. 계정에 남아있는 자산을 사용 가능한 마진이라고 합니다."
			},
			{
				"Q": " 시장 가격 하락은 어떻게 발생하나요?",
				"A": "시장에서 현재 가격으로 주문을 이행하기에 사용 가능한 볼륨이 부족한 경우 나머지 부분은 다음 사용 가능한 가격으로 실행됩니다. 이러한 가격 차이를 슬리피지(Slippage)라고 합니다. 슬리피지는 실행 가격이 예상보다 유리한지 또는 덜 유리한지 여부에 따라 투자자에게 유리하거나(양성 슬리피지) 해로울 수 있습니다(음성 슬리피지)."
			},
			{
				"Q": " 레버리지는 Stop Out에 어떤 영향을 미치나요?",
				"A": " 레버리지는 투자 수익을 증폭시킬 수도 있지만 위험도 높일 수 있습니다. 레버리지가 높을수록 필요한 마진이 적어지고 자금 활용도가 높아지며 거래 가능한 포지션이 더 커집니다. 그러나 증거금이 부족하고 시장 변동성이 높은 기간에는 Stop Out이 발생할 수 있습니다."
			},
			{
				"Q": " 익일이자란 무엇입니까?",
				"A": "스왑이라고도 알려진 익일 이자는 거래자가 익일 포지션을 보유할 때 얻거나 지불하는 이자를 의미합니다. 이 이자는 런던 시간 오후 22시에 계산됩니다. 익일 금리는 거래자의 포지션 방향과 해당 통화 간의 금리 차이에 따라 달라집니다. 예를 들어, 파운드 이자율이 일본 엔화 이자율보다 훨씬 높을 경우 GBP/JPY를 매수 포지션에 보유한 트레이더는 이자를 받을 수 있는 반면, 숏 포지션에 GBP/JPY를 보유한 트레이더에게는 이자가 부과됩니다."
			},
			{
				"Q": " 익일 금리를 확인하는 방법은 무엇입니까?",
				"A": "계약 스왑을 조회하는 방법은 다음과 같습니다. 1. MT4 거래 터미널에 로그인합니다. 2. 메인 메뉴 \"표시\" - \"거래 상품 목록\"을 클릭하세요. 3. 스왑을 확인하려는 거래 상품을 마우스 오른쪽 버튼으로 클릭하고 \"사양\"을 클릭합니다. 4. \"단일기간 재고판매/구매수수료\"는 익일이자입니다. (ps: 트레이더는 고객에게 플러스 익일 이자를 제공합니다.)"
			},
			{
				"Q": " 배당금이란 무엇이며 거래에 어떤 영향을 미치나요?",
				"A": " CFD(차액거래계약) 시장에서 배당금은 CFD 매수 포지션을 보유한 투자자에게 지급되는 이자 지급을 의미합니다. 반대로, 매도 포지션을 보유한 투자자는 일반적으로 해당 수수료를 지불해야 합니다."
			},
			{
				"Q": " 금과 은 현물 거래의 거래 시간은 어떻게 되나요?",
				"A": " 금은 현물 거래 시간은 런던 시간으로 일요일 23:00부터 금요일 22:00까지입니다. 매일 22:00부터 23:00까지 마켓이 잠시 문을 닫습니다."
			},
			{
				"Q": "금과 은 현물 계약은 몇 온스에 해당합니까?",
				"A": " 금 현물 계약: 1랏 = 100온스. 은 현물 계약: 1랏 = 5,000온스."
			},
			{
				"Q": " 금, 은 현물 거래에 필요한 증거금(선지급)은 어떻게 계산하나요?",
				"A": " 현재 금 가격이 2,000달러/온스이고 레버리지가 500배이고 거래가 0.1랏이라면 금에 필요한 마진은 2000*100/500*0.1=40USD입니다. 현재 은 가격이 23달러/온스라면 레버리지는 500배, 거래량은 0.1랏이 됩니다. , 금에 필요한 마진 = 23*5000/500*0.1=23USD"
			},
			{
				"Q": " 금은 현물 계약에는 유효 기간이 있나요?",
				"A": " 아니요. 귀하의 계좌에 충분한 마진을 유지하는 한 귀하의 포지션은 귀하가 폐쇄할 때까지 유효합니다."
			},
			{
				"Q": " 브렌트 원유(XBRUSD)와 서부 텍사스 원유(XTIUSD)의 차이점은 무엇입니까?",
				"A": "원유는 API 비중에 따라 경질유와 중질유로 나눌 수 있고, 황 함량에 따라 황 함유 원유와 저유황 원유로 나눌 수도 있습니다. 브렌트유(XBRUSD)는 북대서양의 여러 석유 지역에서 생산되는 가볍고 달콤한 원유입니다. 상대적으로 가볍고 황 함량이 낮은 서부 텍사스 중질유(XTIUSD)는 브렌트유보다 할인된 가격으로 호가되는 경우가 많습니다."
			}
		]
	}
}