export default {
	"faq-class-name": "en-faq-new",
	"faq-title":"FAQ",
	"faq-desc": "よくあるご質問についてお問い合わせいただくか、カスタマー サービスに直接お問い合わせください。EBC カスタマー サポート チームはいつでもお問い合わせをお待ちしております。",
	"faq-search": "検索キーワードを入力してください",
	"faq-tabs":[
		"登録と口座開設",
		"入金と出金",
		"取引関連",
		"取引ターミナル",
		"知識",
	],
	"faq-data":{
		0: [
			{
				"Q": "EBC で取引を始めるにはどうすればよいですか?",
				"A": " EBC で取引を開始するには、次の手順を完了してください。 1. EBC アカウントの登録 2. 個人情報の確認 3. 取引口座の開設と入金 4. 取引のために MT4 取引ターミナルにログイン"
			},
			{
				"Q": "同じメールアドレスを使用して複数の EBC アカウントを登録することはできますか?",
				"A": "残念ながら、電子メール アドレスは 1 つの EBC アカウントにのみ登録できます。ただし、1 つの EBC アカウントで複数の取引アカウントを作成できます。"
			},
			{
				"Q": " KYC検証を完了する目的は何ですか?",
				"A": "準拠し規制を遵守している企業として、当社は所轄の規制当局が定めた規制に従って KYC 検証を実施する必要があります。これには、有効な国民 ID や過去 6 か月以内に発行された住所証明などの KYC 検証情報を収集する必要があります。"
			},
			{
				"Q": " EBC アカウントの認証にはどのような書類が必要ですか?",
				"A": "KYC 検証を完了するには、有効な ID、携帯電話番号、および電子メール アドレスを提供する必要があります。"
			},
			{
				"Q": "確認書類を再提出するにはどうすればいいですか?",
				"A": " KYC 文書を再送信する手順は次のとおりです。1. クライアント ポータルにログインし、マイ アカウントに移動します。2. 個人情報の下で、要求されたとおりに情報を再度入力します。3. 以前に送信した文書の横にある [削除] ボタンをクリックし、再度アップロードします。4. 完了したら、KYC 文書はすぐに確認されるので、ゆっくり座ってリラックスしてください。"
			},
			{
				"Q": " EBC アカウントの個人情報を更新するにはどうすればよいですか?",
				"A": "登録名の変更はできませんのでご注意ください。ただし、登録した携帯電話番号、メールアドレス、銀行口座情報を更新することは可能です。"
			},
			{
				"Q": "私の資金と個人データは安全ですか?",
				"A": "お客様の資金のセキュリティは当社にとって最も重要です。当社は世界中の複数のトップ規制機関から厳重に監視されており、関連する法律や規制を厳守しています。お客様の資金は、信頼できる海外銀行の個別の口座に個別に保管され、資金と投資収益の両方が二重に保護されます。さらに、当社はお客様の個人情報を保護するために必要なすべてのセキュリティ対策を実施することに尽力しています。"
			},
			{
				"Q": "どのようなアカウントタイプが利用可能ですか?",
				"A": "当社では、スタンダード アカウントとプロフェッショナル アカウントの 2 種類のアカウントを提供しています。<a href=\"/TradingAccount\">アカウント比較表を見るにはここをクリックしてください</a>。"
			},
			{
				"Q": "取引口座番号を見つけるにはどうすればいいですか?",
				"A": "取引口座番号を見つけるには、以下の手順に従ってください。1. EBCアカウントにログインします。2. 「ホーム」-「リアルアカウント」をクリックします。3. リアルアカウントの下に、取引口座番号が表示されます。"
			},
			{
				"Q": "何か違いはありますか？ライブ口座とデモ取引口座はありますか？",
				"A": "両者の取引プロセス、取引商品、K ラインのトレンドは一貫しています。ライブ アカウントの操作には実際の金融リスクが伴います。デモ アカウントは、ユーザーが実際の資金を投入することなく市場の動向を学習し理解するのに役立ちます。"
			},
			{
				"Q": " EBC オフィスはどこにありますか?",
				"A": "当社はロンドン、東京、シドニー、シンガポール、香港などの主要な金融ハブにオフィスを構えています。詳細については、当社の Web サイトをご覧ください。"
			},
			{
				"Q": "口座開設に年齢制限はありますか？",
				"A": "口座を開設するには、18歳以上65歳以下である必要があります。ただし、日本在住の顧客の場合は、最高年齢は70歳です。"
			},
			{
				"Q": " EBC が顧客を受け入れない国はどこですか?",
				"A": "当社の製品およびサービスは、米国、カナダ、マレーシアなどの特定の地域にお住まいのお客様にはご利用いただけませんので、ご了承ください。制限されている国のリストは定期的に更新されることがあります。詳細については、ライブチャット経由でクライアント サポート チームにお問い合わせください。"
			},
			{
				"Q": "アカウントを削除するにはどうすればよいですか?",
				"A": "アカウントの削除をご希望とのこと、大変申し訳ございません。より適切なサポートを提供できるよう、登録済みの電子メール アドレスを使用して cs@ebc.com にリクエストを送信してください。ご要望をいただきましたら、カスタマーサポート部門にて対応させていただきます。"
			},
			{
				"Q": "取引口座のレバレッジを更新するにはどうすればよいですか?",
				"A": "レバレッジを更新するには、以下の手順に従ってください: 1. クライアント ポータルにログインし、[ホーム] をクリックします。 2. [マイ アカウント] で、アカウントの右側にある [設定] をタップし、[レバレッジの変更] をタップします。 3. を選択します。適切なレバレッジを選択し、「はい」をクリックしてレバレッジを更新します"
			},
			{
				"Q": "カスタマーサービスに連絡すると何ができますか?勤務時間は何時ですか?",
				"A": "登録、プラットフォームのダウンロード、その他の関連トピックについてご質問がある場合は、当社のクライアント サポート チームが 24 時間対応いたします。 cs@ebc.com に電子メールを送信するか、オンライン クライアント サービスを使用してご連絡ください。"
			},
			{
				"Q": "苦情を申し立てる必要がある場合、どのような手順で苦情を申し立てることができますか?",
				"A": "ご迷惑をおかけして申し訳ございませんでした。お客様にご満足いただくことが当社の最優先事項であり、お客様が経験した可能性のある問題の解決に全力で取り組んでいます。苦情がございましたら、お気軽に cs@ebc.com までメールでお問い合わせください。"
			}
		],
		1: [
			{
				"Q": "入金する前に知っておくべきことはありますか?",
				"A": "EBC は 24 時間年中無休の入金方法を提供しています。入金の際は指定された期限内に支払いを完了することが重要です。完了しない場合、入金注文は自動的にキャンセルされます。入金方法によって入金限度額が異なる場合があるため、資金を入金する前に関連詳細を確認することをお勧めします。"
			},
			{
				"Q": "出金リクエストを送信する前に知っておくべきことはありますか?",
				"A": "当社では、国内銀行振込、仮想通貨、国際銀行電信送金など、いくつかの出金方法を提供しています。最低出金金額は、国内銀行振込および国際銀行電信送金の場合は 100 米ドル、暗号通貨の出金の場合は 50 米ドルであることに注意してください。"
			},
			{
				"Q": "入金方法は？",
				"A": "入金するには、以下の手順に従ってください。1. クライアント ポータルにログインし、[資金] をタップします。2. メニューの右側にある [入金] を探します。3. 希望する入金方法を選択し、資金を受け取る取引口座を選択します。4. 金額を入力して、[支払いの確認] をクリックします。5. その後、ベンダー ページにリダイレクトされるので、指示に従って入金を完了してください。"
			},
			{
				"Q": "他人の銀行カードを使用して入金/出金できますか?",
				"A": "EBC は第三者による支払いは受け付けておりません。すべての入金は、お客様名義の口座から行う必要があります。(すべての入金は、お客様自身の銀行口座から行う必要があります。) 資金が第三者からのものと思われる場合、当社は送金者に資金を返金し、本人確認と資金源の確認が完了するまでお客様の口座残高を一時的に凍結する権利を留保します。確認が取れない場合、当社はお客様の口座残高を凍結する権利も留保し、お客様はこの時点で口座残高を引き出すことはできません。EBC は第三者への引き出しは行っておりません。特別な状況の場合は、当社までご連絡ください。"
			},
			{
				"Q": "入金限度額はいくらですか？",
				"A": "支払い方法によって入金限度額が異なりますので、詳しくは入金ページをご覧ください。"
			},
			{
				"Q": " EBC では入金手数料や出金手数料がかかりますか?",
				"A": " EBC では入金および出金に手数料はかかりません。ただし、一部の暗号通貨サービスプロバイダーでは一定の手数料を請求する場合がありますのでご了承ください。"
			},
			{
				"Q": "営業時間外でも入金・出金はできますか？",
				"A": "週末や祝日でも通常通り入出金依頼をお受けできます。ただし、週末は休業日のため、ご依頼の処理が遅れる場合があり、遅くとも翌営業日（月曜日）に処理されますのでご了承ください。"
			},
			{
				"Q": "入金と出金の処理にはどれくらい時間がかかりますか?",
				"A": "通常、営業日の入金処理時間は約 1 ～ 15 分です。営業日の 16:00 UTC+8 までに提出された出金申請は、最短で当日中に口座に入金されます。"
			},
			{
				"Q": "複数の銀行カードで入金や出金を行うことはできますか?",
				"A": "はい、ご自分の名前の銀行カードであればどれでもご利用いただけます。"
			},
			{
				"Q": "まだ注文が残っている取引口座から資金を引き出すことはできますか?",
				"A": "はい。ただし、引き出し操作による未決済の注文の清算を避けるために、口座に十分な資金が必要であることに注意してください。出金操作が口座清算につながる可能性があるとリスク管理部門が判断した場合、出金申請は拒否されます。出金操作は清算操作が完了した後にのみ実行できます。"
			},
			{
				"Q": "出金する際に入金とは別の支払い方法を使用できますか?",
				"A": "初回入金額は入金額と同じ方法で出金できます。ただし、お好みの出金方法を使用して利益を出金することができます。"
			},
			{
				"Q": "出金/入金が失敗したのはなぜですか?",
				"A": "入金/出金の失敗は、次の理由によって発生する可能性があります。 1. 自分の名前ではない銀行カードを使用して入金している 2. 使用した銀行カードが無効であるか、認識されない 3. 送金を行う際、銀行が情報または引き落とし口座情報が正しく入力されていない場合。上記の状況が発生した場合は、ライブチャット経由でクライアント サポートに連絡するか、cs@ebc.com に電子メールを送信して支援を求めてください。"
			},
			{
				"Q": "自分の取引口座間で資金を移動することはできますか?",
				"A": "はい。取引口座間で資金移動リクエストを送信できます。"
			},
			{
				"Q": " 1 つの引き出しリクエストを送信した後、次の引き出しリクエストを送信できないのはなぜですか?",
				"A": " 2 回目の出金を提出する前に、1 回目の出金が審査のために承認されるまで待つ必要があります。"
			},
			{
				"Q": "出金をキャンセルした後、資金がすぐに取引口座に返されないのはなぜですか?",
				"A": "出金をキャンセルすると、資金は自動的にメインウォレットに返され、メインウォレットから取引口座に資金を移すことができます。"
			}
		],
		2: [
			{
				"Q": "EBC ではどのような商品を取引できますか?",
				"A": " EBC は幅広い商品を提供しており、多様なポートフォリオを作成するために必要な取引口座は 1 つだけです。<a href=\"/tradingproduct\">完全な製品リストを表示するにはここをクリックしてください</a>。"
			},
			{
				"Q": "各口座タイプの最大取引量と最小取引量はいくらですか?",
				"A": " STD口座およびPRO口座の最大取引量は40ロットで、最小取引量は取引種類によって異なります。もっと詳しく知りたい方は<a href=\"/TradingAccount\">どうぞアカウントルールを表示するにはここをクリックしてください</a>。"
			},
			{
				"Q": "成行執行とは何ですか?未決注文とは何ですか?",
				"A": "市場執行とは、注文が即座に執行され、投資家が現在の市場で利用可能な最良の価格に従って売買することを意味します。未決注文トランザクションは、投資家が予想売買価格を決定するスケジュールされた実行です。市場価格が予想価格に達するか超えると、取引システムが自動的に取引を実行します。"
			},
			{
				"Q": "買い指値、買いストップ、売り指値、売りストップとは何ですか?",
				"A": "購入制限:価格があらかじめ設定した下限まで下がったときに購入するため、安値購入戦略に適しています。たとえば、金の価格が 2000 年から 1980 年に下落し、その後再び上昇すると予想される場合、購入限度額を 1980 年に設定し、より低い価格で購入するのを待つことができます。売り指値:あらかじめ設定した高値まで価格が上昇した場合に売却するため、高値販売戦略に適しています。たとえば、金の価格が 2000 年から 2020 年にかけて上昇し、その後再び下落すると予想される場合、2020 年の売り指値を設定し、高価格で売却できるまで待つことができます。買いストップ：価格があらかじめ設定された高水準まで上昇したときに購入するため、追撃戦略に適しています。たとえば、金の価格が 2020 年を超えた場合にのみ上昇傾向が確認できると考えている場合は、2020 年に買いストップを設定し、上昇ブレイクスルーを待ってから購入することができます。売りストップ:価格があらかじめ決められた低い水準まで下がったら売り、追撃戦略に適しています。たとえば、金価格が 1980 年を下回った場合にのみ下降トレンドを確認できると考える場合は、売りストップを 1980 年に設定し、価格が 1980 年を下回るのを待ってから売却できます。"
			},
			{
				"Q": "ストップアウトレベルとは何ですか?",
				"A": "ストップアウトレベルは30%です"
			},
			{
				"Q": "注文の「テイクプロフィット」と「ストップロス」を設定するにはどうすればよいですか?",
				"A": " MT4：パソコン、ストップロスと利食いを設定する手順は次のとおりです。 1. 管理する取引注文を選択します。 2. 注文を右クリックし、「注文の変更または削除」を選択します。 3. 表示されるウィンドウで「ストップロス価格」と「利益価格」を設定します。 MT4: モバイルストップロスとテイクプロフィットを設定する手順は次のとおりです。 1. 「取引」ページで変更したいポジション注文を見つけます。 2.注文をクリックまたは長押しして、ポップアップメニューで「変更」を選択します。 3. 表示されるウィンドウで「ストップロス価格」と「利益価格」を設定します。"
			},
			{
				"Q": "ヘッジとは何ですか?",
				"A": "ヘッジは、同じ品種、同じロットサイズの逆方向の開始注文です。"
			},
			{
				"Q": " EBC は取引シグナルを提供しますか?",
				"A": "当社は取引シグナルを直接提供していませんが、取引コミュニティへのアクセスを提供しています。このコミュニティ内では、他のコミュニティ ユーザーが提供するさまざまな取引シグナルから選択できます。"
			},
			{
				"Q": " EBC は EA (Expert Advisor) をサポートしていますか?",
				"A": "はい。お好みの EA を使用できます。"
			}
		],
		3: [
			{
				"Q": "どの取引ターミナルを使用できますか?",
				"A": "現在、EBC は MT4 取引ターミナルの使用のみをサポートしています。<a href=\"/MT4\">ここをクリックして今すぐダウンロードしてください</a>。"
			},
			{
				"Q": " MT4 プラットフォームにはどのサーバーを使用すればよいですか?",
				"A": " EBCのライブアカウントにはサーバーEBCFinancialGroupKY-Liveを使用し、デモアカウントにはサーバーEBCFinancialGroupKY-Demoを使用します。"
			},
			{
				"Q": "取引ターミナルにログインできないのはなぜですか?",
				"A": "へのログインの失敗取引プラットフォームの原因として考えられるのは、ユーザーがパスワードを間違って入力したまたはそれはに関連している可能性がありますネットワーク接続に失敗しました。ログインの問題が発生した場合は、まずパスワードを再入力するか、ネットワーク接続を確認することをお勧めします。"
			},
			{
				"Q": " MT4 にログインできません。なぜ「無効なアカウント」エラーが表示されるのですか?",
				"A": " 「無効なアカウント」というプロンプトが表示された場合は、間違ったサーバー情報を入力したか、パスワードが間違っている可能性があります。この問題は、正しいサーバーを選択し、正しいパスワードを入力することで解決できます。"
			},
			{
				"Q": " 「接続なし」というプロンプトが表示されるのはなぜですか?",
				"A": " 「接続なし」エラーが発生した場合サーバーが切断されているか、ローカル ネットワーク接続に問題がある可能性があります。まず、ローカル ネットワークで Web を正常に閲覧できるかどうかを確認してください。ネットワークが正常であれば、別のデータ センター (DC) サーバーに切り替えて再接続してみてください。"
			},
			{
				"Q": " 「新規注文」ボタンがグレー表示されているのはなぜですか?",
				"A": "新しい注文を作成できない場合は、次の理由が考えられます。 1. アカウントが正常に接続されていないか、切断されています。 2. 読み取り専用パスワードを使用してログインします。このタイプのパスワードではアカウントの表示のみが可能で、トランザクション操作は実行できません。 3. STD アカウントは「.s」接尾辞が付いたシンボルのみを取引できますが、灰色の領域の品種は取引できません。"
			},
			{
				"Q": "シンボルを追加または削除するにはどうすればよいですか?",
				"A": " MT4で特定のシンボルを追加または非表示にするにはデスクトップ端末1. 取引ターミナルにログインし、任意の場所を右クリックします。 2.「取引タイプ」を選択します。 3. 取引シンボルグループを選択します。 4. ダブルクリックして「相場」に追加します。 5. 非表示にする場合は、「非表示」を選択します。モバイル端末1. ログインし、右上隅の「+」をクリックします。 2. 取引シンボル グループを選択します。 3.「+」をクリックして追加します。モバイル端末で品種を削除するには: 1. 左上隅にあるペン型のボタンをクリックします。 2. カテゴリを削除するにはチェックボックスをオンにし、[削除] をクリックします。"
			},
			{
				"Q": "入札ラインを設定するにはどうすればよいですか?",
				"A": "デスクトップ 1. チャート上の任意の場所を右クリックします。 2. [プロパティ] を選択して、表示設定を入力します。 3. 「購入価格ライン」オプションをチェックします。 iOS: 1. チャートを開きます。 2. 任意の場所をクリックして「設定」を選択します。 3. 「購入価格ライン」オプションをチェックします。 Android: 1. 設定メニューに入ります。 2.「グラフ」を選択します。 3. [入札ライン] オプションをチェックして有効にします。"
			},
			{
				"Q": " MT4でタイムゾーンを更新するにはどうすればよいですか?",
				"A": "残念ながら、MT4ターミナルにはタイムゾーンを更新するオプションがありません"
			},
			{
				"Q": "未決注文が執行されないのはなぜですか?",
				"A": "未決注文の実行を妨げる理由は次のとおりです。 1. 口座内の利用可能な空き証拠金が新しいポジションを開くには不十分です。 2. 指値注文の価格が正しくなく、指値注文の約定基準を満たしていません。 3. 主要な金融イベントが発生すると、市場が変動する可能性があり、実際の注文価格は未決注文価格と異なる場合があります。未決注文が約定されない場合は上記の理由に限定されず、その時点の特定の状況に基づいて判断される必要があることに注意してください。"
			},
			{
				"Q": "すべてのシンボルの取引時間を確認する最良の方法は何ですか?",
				"A": " MT4取引端末にログインし、閲覧したい取引銘柄を右クリックし、「仕様」をクリックすると表示されます。"
			},
			{
				"Q": " 「無効な SL/TP」エラーが表示されるのはなぜですか?",
				"A": "「無効な SL/TP」エラーは、次の理由により発生する可能性があります: 1. 現在の価格が設定された利益確定ポイントと損切りポイントを超えており、取引を完了できません。 2. STD アカウントの注文価格は、最新の価格から 50 ポイント以上である必要があります。"
			},
			{
				"Q": " 「マーケットクローズ」プロンプトが表示されるのはなぜですか?",
				"A": "マーケットが閉まっている場合は、「Market Closed」と表示されます。"
			},
			{
				"Q": "ポジションを部分的にクローズ（オープンポジションの数を減らす）することはできますか？",
				"A": "ポジションは部分的に決済することができます。現在、商品の最小取引ロット要件を超えている場合は、ポジションを部分的に決済することを選択できます。"
			},
			{
				"Q": "市場が閉まった後でも取引できますか？",
				"A": "市場が閉まっているときは取引できなくなります。市場が開いているときのみ、取引（注文のオープン、クローズ、実行、変更）が可能です。"
			},
			{
				"Q": " MT4 がオフラインになった場合、開いたポジションをすべて閉じる必要がありますか?",
				"A": "MT4取引システムを閉じても、利食い、ストップロス、未決注文はそのまま残ります。ただし、MT4取引システムを終了すると、トレーリングストップロスおよびEA取引システムは無効になります。"
			},
			{
				"Q": "取引履歴を確認するにはどうすればよいですか?",
				"A": " 1. MT4取引ターミナルにログインします。 2. ナビゲーション「ターミナル」-「口座履歴」をクリックします。 3. 完了した取引履歴を表示します。"
			},
			{
				"Q": "取引履歴をエクスポートするにはどうすればよいですか?",
				"A": " 1. MT4取引端末にログインし、「口座履歴」をクリックします。 2. マウスを右クリックし、「口座明細として保存」を選択します。"
			},
			{
				"Q": " MT4で資本曲線を表示するにはどうすればよいですか?",
				"A": " 1. MT4 で口座履歴を開きます。 2. マウスを右クリックし、「詳細な口座明細として保存」を選択します。 3. 保存したグラフをもう一度クリックして、資本曲線を確認します。"
			},
			{
				"Q": " PAMM とは何ですか?またその使用方法は何ですか?",
				"A": "PAMM はパーセント配分管理モデルの略で、管理のための共同取引資金の一形態です。投資家は資格のあるトレーダーまたはファンドマネージャーを選択し、自分の資金とプールされた資金を使用して取引を行い、利益を獲得し、合意された比率に従って利益を分配することができます。"
			}
		],
		4: [
			{
				"Q": "デリバティブ取引とは何ですか?",
				"A": "デリバティブ取引市場は現在、世界最大の金融取引市場であり、1日平均の取引量は約4兆米ドルに上ります。世界のさまざまな通貨の為替レートは変動しており、取引時にはユーロ/米ドル（EUR/USD）や米ドル/日本円（USD/JPY）などの通貨ペアの形で現れます。通貨ペアで一方の通貨を買い、もう一方の通貨を売る取引方法を表します。"
			},
			{
				"Q": "スプレッドとは何ですか?",
				"A": "スプレッドとは、ある取引商品の買値と売値の差であり、取引ごとに支払う必要のある取引コストでもあります。"
			},
			{
				"Q": "レバレッジとは何ですか?",
				"A": "レバレッジとは、投資家が全額取引を行うことなく、資金の小額を使用して、より価値の高い外国為替対象に投資できることを意味します。レバレッジのレベルによって、占有されるマージンの量が決まります。"
			},
			{
				"Q": "マージンとは何ですか?",
				"A": "証拠金は前払金とも呼ばれ、トレーダーが資金の一部を使用してレバレッジ投資の原則に基づいてポジションを確立できるようにします。これは、取引ポジションを維持するための担保として機能します。市場では、レバレッジは通常20〜500倍の範囲で、投資家は高いレバレッジで積極的に取引できます。ただし、証拠金システムは利益を増幅できる一方で、取引リスクも増大させることに注意することが重要です。たとえば、今日10万ドル相当の取引を実行したい趙さんを考えてみましょう。証拠金取引でレバレッジ比率が500：1であると仮定すると、趙さんはこの取引を実行するために200ドルしか必要としません（100,000 / 500 = 200ドル）。言い換えれば、資本が500倍に拡大され、1万ドルの投資で500万ドルの取引が可能になります。"
			},
			{
				"Q": "注文を開くために必要な証拠金を計算するにはどうすればいいですか?",
				"A": "証拠金 = 契約サイズ * 現在の価格 / レバレッジ * ロット数。この計算式は、注文を開始するために必要な証拠金を決定します。契約サイズ (ポジションのサイズ)、資産の現在の価格、ブローカーが提供するレバレッジ、取引されるロット数などの要素が考慮されます。"
			},
			{
				"Q": "取引における残高、資本、利用可能な証拠金の間にはどのような関係がありますか?",
				"A": "資本 = 残高 – 占有マージン ± 変動損益。各用語が表すものは次のとおりです。バランス これは取引口座内の資金の合計額です。占有マージン:現在オープンポジションに拘束されている証拠金。変動損益 オープンポジションの含み損益。市場の変化に応じて変動します。資本とは、基本的に、オープンポジションに拘束されている証拠金と変動損益を考慮した後の取引口座の正味価値です。これは口座のリアルタイムの価値を反映しており、今後の取引活動に利用できる証拠金を決定する重要な要素となります。"
			},
			{
				"Q": "なぜマイナス残高が発生するのでしょうか？",
				"A": "市場が合理的な取引価格を提示できない極端な市場状況では、マイナス残高が発生する可能性があります。この状況では、最終的な取引損失が口座残高と証拠金を超え、口座残高がマイナスになる可能性があります。"
			},
			{
				"Q": "証拠金（頭金）と利用可能な証拠金（利用可能な頭金）の違いは何ですか?",
				"A": "証拠金とは、未処理の注文を維持するためにブローカーが保有する資金を指します。口座内の残りの資本は利用可能証拠金と呼ばれます"
			},
			{
				"Q": "市場価格のスリッページはどのようにして起こるのでしょうか？",
				"A": "市場では、現在の価格で注文を履行するのに利用可能な数量が不十分な場合、残りの部分は次の利用可能な価格で実行されます。この価格差はスリッページと呼ばれます。実行された価格が予想よりも有利か不利かによって、スリッページは投資家にとって有利 (プラスのスリッページ) にも不利 (マイナスのスリッページ) にもなります。"
			},
			{
				"Q": "レバレッジはストップアウトにどのような影響を与えますか?",
				"A": "レバレッジは投資収益を増大させますが、リスクも増大させます。レバレッジが高いほど、必要な証拠金が少なくなり、資金の利用度が高まり、より大きな取引ポジションが可能になります。ただし、証拠金が不十分な状態で市場のボラティリティが高い期間には、ストップ アウトが発生する可能性があります。"
			},
			{
				"Q": "オーバーナイト金利とは何ですか?",
				"A": "オーバーナイト金利（スワップとも呼ばれる）は、トレーダーがポジションを一晩保有する際に得られる、または支払われる金利を指します。この金利はロンドン時間午後 22:00 に計算されます。オーバーナイト金利の利率は、トレーダーのポジションの方向と関連通貨間の金利差によって異なります。たとえば、ポンドの金利が日本円の金利よりも大幅に高い場合、GBP/JPY をロング ポジションで保有しているトレーダーは金利を得ることができますが、GBP/JPY をショート ポジションで保有しているトレーダーは金利を負担することになります。"
			},
			{
				"Q": "翌日物金利を確認するにはどうすればいいですか?",
				"A": "約定のスワップを照会する方法は次のとおりです。 1. MT4 取引端末にログインします。 2. メインメニューの「表示」→「取引商品一覧」をクリックします。 3. スワップを表示したい取引商品を右クリックし、「仕様」をクリックします。 4. 「単一期間の在庫の売り/買い手数料」は、翌日の金利です。 (追記: トレーダーはプラスのオーバーナイト金利を顧客に与えます)"
			},
			{
				"Q": "配当とは何ですか?また、配当は取引にどのような影響を与えますか?",
				"A": "差金取引 (CFD) 市場では、配当とは、CFD のロングポジションを保有する投資家に支払われる利息を指します。逆に、ショートポジションを保有している投資家は通常、対応する手数料を支払う必要があります。"
			},
			{
				"Q": "金と銀の現物取引の取引時間は何時ですか?",
				"A": "当社のスポット金と銀の取引時間は、ロンドン時間の日曜日 23:00 から金曜日 22:00 までです。マーケットは毎日22:00から23:00までの間、短時間閉まりますのでご注意ください。"
			},
			{
				"Q": "金と銀のスポット契約は何オンスに相当しますか?",
				"A": "金スポット契約: 1 ロット = 100 オンス。銀スポット契約: 1 ロット = 5,000 オンス。"
			},
			{
				"Q": "金・銀の現物取引に必要な証拠金（前払金）はどのように計算するのでしょうか？",
				"A": "現在の金価格が1オンスあたり2,000米ドル、レバレッジが500倍、取引が0.1ロットの場合、金に必要な証拠金は2000*100/500*0.1=40米ドルです。現在の銀価格が1オンスあたり23米ドル、レバレッジが500倍、取引が0.1ロットの場合、金に必要な証拠金は23*5000/500*0.1=23米ドルです。"
			},
			{
				"Q": "金と銀のスポット契約には有効期限がありますか?",
				"A": "いいえ。口座に十分な証拠金を維持している限り、ポジションは決済するまで有効のままです。"
			},
			{
				"Q": "ブレント原油（XBRUSD）とウェスト・テキサス原油（XTIUSD）の違いは何ですか？",
				"A": "原油はAPI比重に基づいて軽質原油と重質原油に分類でき、また硫黄含有量に基づいて含硫黄原油とスイート原油に分類できます。ブレント原油 (XBRUSD) は、北大西洋のいくつかの油田から産出される軽質で甘い原油です。ウエスト・テキサス・インターミディエイト（XTIUSD）は比較的軽く、硫黄含有量が低いため、ブレントよりも割引価格で取引されることが多い。"
			}
		]
	}
}