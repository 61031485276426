export default {
	"box-name": "DiNapoli-page-en",
	"section1": {
		"text1": "Joe<br> DiNapoli",
		"text2": "<li>فىبوناچچىنىڭ نايىبى</li><li>DiNapoli دەرىجىلىرىنىڭ قۇرغۇچىسى</li><li>ئالتۇن نىسبەت ساودا ئۇسۇلى قوللىنىشتىكى ئاخىر تەرتىپلىك شەخسى دەپ ئىتىراپ قىلىنغان</li>  ",
		"text3": "Joe 55 يىلىمدىن ئارتۇق نەقىل ساودا تەجىربىسىگە ئىگى بولغان بەرىگەن قۇرېڭلىق ساوداچى. ئۇ ھەر كۈنى ھەمما يەرگە ساودا قىلىدۇ. ئۇنىڭ تارىخى ئۆتمىشتىكى نيۇپتورق شەھەر مەۋسۇمىدا Nifty 50 نى ساودا قىلىپ باقان، 1987 يىلى بازارقا قاتناشقان تەقدىرنى خەلە ئەستايلقان، ۋە 9/11 دن كېيىن كېلەشەك تائىلىگە قاتنىشقاننى ئۆز ئىچىگە ئالىدۇ. Joe 1982 يىلدىن باشلاپ، قوللىمىسى ئاچىلغاندا، S&P كەلەجەكنى ساودا قىلغان. ئايرىش يۈرۈشكۈچى ئارىقى، ئومۇمىي فىبۇناچچى ئۇسۇلى، ئوسىللا تورى يېڭىلىق ئېنىقلىغۇچېكە پېچەتنىڭ MACD تەخمىنى دا ئازھاردا تۇرىدۇ. 25 يىلىمدىن ئارتۇق ۋاكالەت ناماڭئارلىق CTA سىنىڭ شەخسى، ئۇ دۇنياۋىي ئوقۇتۇش بىلەن ئېيغىلىك كەسمەك، ۋە 13 تىلغە تەرجىمە قىلىنغان «DiNapoli دەرىجىلىرى بىلەن ساودا قىلماق» بىلەن مەشھۇر كىتىپنىڭ ئاپتورى.",
		"text4": "DiNapoli دەرىجىلىرى بىلەن ساودا",
		"text5": "«DiNapoli دەرىجىلىرى بىلەن ساودا» فىبۇناچچى ئانالىزىنىڭ باھا يوچۇقۇشغا ئادا بىردا باشلانغان ئەڭ تەپسىلى كۆ ھەل بۇلۇپ، ساودا بىلەن ئەتىمگە ئاجايىپ سىنىقى بىلەن ئالاھىدە باتىرلىق بەرگەن كېرمە يول سالغۇچى يۆلەشتى. بۇ نەفىدار دەسلەپكى قەلەم ساوداچىلارغا پەرقلىدىغان سوقچىلىك تېز قوشۇپ باقرۇق سىستېمىلىرى جاقا ئېھنقىلا چرەنبى پۇخرا ئەل قايتىردىقىنى كىچەنى مەپ აიلىغاندا.",
		"text6": "<li>دۇنياۋى ئالتۇن نىسبەت قىلىمەن ساودا كىلىملاردىكى ئاخىرقى بىرىنچى ھەققە مۇۋاپىيقى</li><li>ئەڭ ئەقلى بىرى ۋاكالەت شەهرىنى پىچەت ئىيتىشتىكى ئىلىكىگە مۇۋاپىيقى قىلغۇ يېنىڭ «DiNapoli دەرىجىلىرى بىلەن ساودا» بۇ كىتىپنى ئۇنىڭ چوڭلارنىڭ يەڭمۇ كىتىپى دەپ تۇرتىنىپ كېزىقۇرۇق بۇلغۇ</li><li>گئۇرۇبۇز 13 تىلغا تەرجىمە قىلىنغان</li>  ",
		"text7": "ۋىجىدىن ئىلىڭ",
		"text8": "DiNapoli MT4/MT5 كۆرسەتكۈچلىرى",
		"text9": "DiNapoliنىڭ باشلىسىق كۆرسەتكۈچلىرى كەپقۆڭگۈن ئۆطبقكىلىرىگە يېتىپ، ئەنپۇقۇمىزغا ئۇيقۇق ۋە تاسىرانچىلىق سېلىشتۇرۇش، مىساندا ۋە چېكىشنۇق تورسىركۇنچى. ئۇلار تەپسىلى ياسالغان، تەخىنى باشلىغان ساودا يول كەڭگىكىگە سۆيىلىپ. ئۇۋاڭ سايەككۈچلۈك كەينىدىن تەڭك درۇسوم چىقىرىشىنى يوتۇڭلىرى شۆبىشى ساۋلۇق دوكkl مەلرە پەرۋازىنى باغلاپ قىيىن.",
		"text10": "بېلگىلەنگەن ئىزلەش",
		"text11": "DMA سىستېمسى پوىېغ كۈننى تەخمىن، قۇلۋە تنفيذۇش ئىشتىكى گايدبولىغا تەخىنىلا كەمتېرىپ بولدۇ.",
		"text12": "قوشۇندۇرا ۋە داۋاس نىسبەت شەددى ئارتىقى بىلەن",
		"text13": "ئوسىللا تورى ياخشى رەمىز توغرا بەلگىلىنى پەرقلىدى.",
		"text14": "بۇنچى دېغىقتە يۈپۈچەك ۋە تاردىرسين رەھبەرى",
		"text15": "فىبۇناچچى رەزىلى بەكتىلكەنىڭ نىسبىتى دەسلىنەر قىساس ئارۇش بەرگەن.",
		"text16": "مەھسۇلاتلار غەيرەتلەنگەن كەسپىي سېرىقچى",
		"text17": "بۇ ئىقتىسادنى ئالغا سېلىپ، يېقىنقې باسقۇزنىڭ ئالدىنقى بىر ۋاقىتتا بۇنداق ئەخىلقى ساۋداچىلاردىن ئۇلارنىڭ بەچەلىك مەڭا بەكس قارىلىققا كۈرەشكە كىرىپ تۇرىدۇ.  ",
		"text18": "واتسېكا ئىللىنوي رەھبەر ۋە سۇستوخاستىك ئوسىلىلاتور مۇھكىمى",
		"text19": "تەجرىبەنى سېتىۋالمايسىز، ئەمما جونىڭ كىتابى قىممەتلىك دەرسلەرنى ساقلىروالدۇر.",
		"text20": "30 يىلدىن ئارتۇق كەسىپ تېتىكلىگۈنچە تىجارەت مۇنەۋبىز<br>Bressert تىجارەت گۇرۇپپىسى",
		"text21": "«DiNapoli دەرىجىلىرى بىلەن ساودا» باۋى تۈرەرسۈرلۈكى تاققا ئۆرتە ھەقىقەتلىك، دۇنىيە ۋە يامغۇر بازىتىدا ئەكسكۆڭۈشلۈك كەم بىلەن شەشە. يېڭى ساوداچىلار ئۇلارنىڭ ئوۋسەر مۇرىدىنى بىر كىيۇ ئىلىپ قېتىپ، قەدەر ساوداچىلەر يېڭى ھەسەت ئالار. ",
		"text22": "LBR گۇرۇپپىسى خەنەر تىجارەتچى، مۇەللىپ، تەرگىلمەن",
		"text23": "DINAPOLI دەرىجىلىرى ھەققىدە تېخىمۇ كۆپ ئۇچۇرغا ئىگە بولۇڭ",
		"text24": "DiNapoli دەرىجىلىرى ۋە DiNapoli مۇتەخەسسىسى ھەققىدە تېخىمۇ كۆپ بىلگۈچنىڭ بەردىغانى",
		"text25": "تېخىمۇ كۆپ بىلگىنىڭ",
	},
}