export default {
    "h1-title": "เครื่องมือการซื้อขาย",
    "imgUrlLang": "-en",
    "public-btn-text": "ประสบการณ์ทันที",
    screen0: {
        "title": "สร้างคลังเครื่องมือเพื่อตอบสนองความต้องการในการเทรดของคุณ",
    },
    screen1: {
        "title": "เครื่องมือซ้อนทับสินค้าที่มีรูปภาพเดียวกัน",
        "desc": "เครื่องมือนี้ช่วยให้นักลงทุนเข้าใจความแข็งแกร่งของ K-line ในช่วงเวลาใดๆ ของสินค้าโภคภัณฑ์ 2 ชนิดได้อย่างชัดเจน และยังสามารถระบุประสิทธิภาพของการทะลุทะลวง",
    },
    screen2: {
        "title": "ตัวบ่งชี้ระดับมืออาชีพของ MACD (เส้นคู่)",
        "desc": "นี่เป็นเครื่องมือบ่งชี้ MACD ที่ใช้กันทั่วไปสำหรับหุ้นและฟิวเจอร์ส เมื่อเทียบกับ MACD ที่มาพร้อมกับ MT4 จะสอดคล้องกับพฤติกรรมของผู้ใช้ชาวจีนมากกว่า",
    },
    screen3: {
        "title": "ตัวบ่งชี้ทางสถิติกำไรและขาดทุนของ MTDriver",
        "desc": "เครื่องมือนี้สามารถช่วยให้นักลงทุนระบุกำไรและขาดทุนสำหรับช่วงเวลาต่างๆ",
    },
    screen4: {
        "title": "การแจ้งเตือนการเปลี่ยนแปลงราคามือถือ",
        "desc": "นักลงทุนส่วนใหญ่มีเวลาจำกัดในการเฝ้าดูตลาด และการแจ้งเตือนการเปลี่ยนแปลงราคาทางโทรศัพท์มือถือสามารถช่วยให้พวกเขาเข้าใจแนวโน้มของตลาดในขณะที่หลีกเลี่ยงเสียงรบกวนจากตลาด",
    },
    screen5: {
        "title": "คลิกเดียวปิด EA",
        "desc": "อินเทอร์เฟซการดำเนินการอยู่ในอินเทอร์เฟซแผนภูมิ ซึ่งสะดวกสำหรับนักลงทุนในการหยุดการขาดทุนได้รวดเร็วและสะดวกยิ่งขึ้นเมื่อเกิดสภาวะตลาดที่รุนแรง",
    },
    screen6: {
        "title": "ลำดับเวลา EA",
        "desc": "วางไว้ในโฟลเดอร์ \MQL4\Experts เป็น EA ที่ออกคำสั่งตามเวลาที่กำหนด การใช้งานง่ายมาก ผู้ใช้ลองสำรวจด้วยตัวเอง",
    },
    screen7: {
        "title": "การตรวจสอบตราสารทุน EA",
        "desc": "วางไว้ในโฟลเดอร์ \MQL4\Experts ตรวจสอบ EA ของการเปลี่ยนแปลงมูลค่าสุทธิ ใส่ไฟล์เสียงที่คุณต้องการให้แสดงภายใต้ \Sounds จากนั้นกรอกชื่อไฟล์เพลงที่เกี่ยวข้องในชื่อเพลงของ EA",
    }
}