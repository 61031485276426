export default {
  "lang": "cn",
  "screen0": {
    "title": "پۇل مۇئامىلە دۇنياسى",
    "desc": "There is no Trading Secret!<br>Only to find what works best for you and applying it with strick risk management",
    "quick": "تېز چۈشىنىش",
    "quick1": "تۆت خىل مۈلۈك تۈرى",
    "quick2": "سودا تۈرى+100",
    "trList": [
      "层级",
      "买入价",
      "买入的交易量",
      "点差",
      "卖出价",
      "卖出价交易量"
    ],
    "smallTrList": [
      "层级",
      "买入价",
      "点差",
      "卖出价"
    ],
    "btn": "مەبلەغ سېلىشنى باشلاش "
  },
  "screen1": {
    "pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "mt4 -سودا سۇپىسى ",
    "desc": "ئۇزاقتىن بۇيان، Meta Trader 4 يەر شارى بويىچە پارچە سېتىش تاشقى پېرېۋوت سودا سۇپىسىنىڭ كەسىپ ئۆلچىمى دەپ قارىلىپ، ھازىرغا قەدەر ئەڭ ئالقىشقا ئېرىشكەن تور سودا سۇپىسى بولۇپ كەلدى. ",
    "title": "ھەر خىل ئېھتىياجنى قاندۇرىدىغان سودا سۇپىسى",
    "desc1": "EBC MetaTrader4 سۇپىسى بىۋاسىتە كۆرگىلى بولىدىغان، يۈكسەك دەرىجىدە زاكاز قىلغىلى بولىدىغان ئابونىتلار كۆرۈنمە يۈزى، شۇنداقلا كۈچەيتىلگەن گرافىك ئىقتىدارى ۋە ئىنچىكە زاكاز باشقۇرۇش، ئانالىز قىلىش ۋە سىگنال بېرىش قورالى بىلەن تەمىنلەيدۇ، ئۇ سىزنىڭ ھەر ۋاقىت، ھەر جايدا بازار بىلەن ماس قەدەملىكنى ساقلىشىڭىزدىكى كۆڭۈلدىكىدەك ئۇسۇل"
  },
  "screen2": {
    "num1": "ئۈچ خىل",
    "text1": "ئوخشاش بولمىغان دىئاگرامما تىپى",
    "num2": "توققۇز",
    "text2": "خىل ۋاقىت رامكىسى",
    "num3": "ئەللىك",
    "text3": "خىل ئالدىن قۇراشتۇرۇشنىڭ تېخنىكىلىق كۆرسەتكۈچى",
    "title": "سودا قىلغۇچىلار ئۈچ خىل ئوخشاش بولمىغان دىئاگرامما تىپىنى زىيارەت قىلسا بولىدۇ، توققۇز ۋاقىت دائىرىسى، 50 خىل ئالدىن قۇراشتۇرۇلغان تېخنىكا كۆرسەتكۈچى ۋە پۈتۈنلەي زاكاز قىلغىلى بولىدىغان كۆرۈنمە يۈزىدىن ئېشىپ كېتىدۇ، بۇ مەيلى سىز قايسى خىل سودا ئۇسلۇبى ياكى سىستېمىسىنى ئىشلەتمەڭ، يۇقىرى ئۈنۈملۈك باشقۇرۇش ۋە توغرا بازارنى نازارەت قىلىشنى ئاسانلا ئىشقا ئاشۇرالايدىغانلىقىڭىزدىن دېرەك بېرىدۇ. ",
    "desc": "مەيلى قايسى ۋاقىت، قايسى جايدا بولسۇن، سىز بىزنىڭ Web تور كۆرگۈچىمىز ياكى PC تېرمىنالى / كۆچمە ئۈسكۈنىمىز ئارقىلىق تېز سۈرئەتتە MetaTrader 4 نى چۈشۈرۈپ، پۈتۈن يەر شارى كاپىتال بازىرىغا بىۋاسىتە ئۇلىيالايسىز. ",
    "btnText": "تېخىمۇ كۆپ سودا قىلىش سۇپىلىرىنى چۈشەنگىڭىز بارمۇ",
    "btn": "ئۆگىنىش مەركىزى /سودا سۇپىسى بۆلىكى ",
    "smallBtn": "ئۆگىنىش مەركىزى lab"
  },
  "screen3": {
    "title": "دەرھال يولغا چىقايلى",
    "desc": " EBCمۇ Meta Quotes بىلەن بىرلىكتە ئوپتىك تالا تورى ۋە مۇلازىمېتىرىمىزنى تىرىشىپ تەڭشەپ، سودىلاشقۇچىغا ئىجرا قىلىش سۈرئىتى ۋە بارلىق مۈلۈك تۈرىنىڭ سۈزۈك باھاسىنى زور دەرىجىدە يۇقىرى كۆتۈرەلەيدۇ ",
    "link1": "كۆرگەزمە سودا ۋاقتى",
    "link2": "بارلىق مەھسۇلاتلار ۋە نۇقتا پەرقىنى تەكشۈرۈش",
    "list": [{
      "title": "ئومۇميۈزلۈك بازار بىلەن قاپلاش",
      "desc": "100 + كاپىتال بازىرى مەھسۇلاتلىرىنى زىيارەت قىلىش، تاشقى پېرېۋوت، پاي چېكى، كۆرسەتكۈچ سان، تاۋار باھا پەرقى توختامى قاتارلىقلارنى ئۆز ئىچىگە ئالىدۇ. "
    }, {
      "title": "مېخانىكىلىق جانلىقلىق",
      "desc": "بىر قېتىم تىزىملاتسىڭىز خالىغان يەردىن MT4 ھېساباتىڭىزنى زىيارەت قىلالايسىز. كۆچمە نۇسخىسى تەمىنلىگەن ئىقتىدار دىئالوگلۇق باھا مەلۇم قىلىش ئىسخېمىسى، پۈتۈن يۈرۈشلۈك سودا زاكاز تالونى ۋە مودا بولغان ئانالىز قوراللىرىنى ئۆز ئىچىگە ئالىدۇ"
    }, {
      "title": "رېئال ۋاقىتلىق سۈزۈك باھا مەلۇم قىلىش ",
      "desc": "سودىدا ھەر بىر دەقىقە ئىنتايىن مۇھىم. بانكىنىڭ كەسىپداشلار ۋە سودا سېتىش-سېتىۋېلىش سىستېمىسى ئەمەلىي باھا بىلەن تەمىنلەيدۇ، يۇقىرى ئۈنۈملۈك، ئاشكارا بولىدۇ. "
    }, {
      "title": "ئىلغار سىنبەلگە قورالى",
      "desc": "ئىلغار بولغان دىئاگرامما ئىقتىدارى سىزنى باھاغا ھۆكۈم قىلىش ئارقىلىق مەبلەغ سېلىش تەدبىرىنى تېخىمۇ تېز، تېخىمۇ توغرا چىقىرىشقا ئۈندەيدۇ. "
    }, {
      "title": "ئالدىن ۋاقتىدا خەۋەر بېرىش",
      "desc": "دۇنيادىكى ھەرقايسى كاپىتال بازارلىرىدىكى ۋە بىرىنچى قول بازارلىرىنىڭ ئۇچۇرلىرىنى ھەرۋاقىت سىزگە،بىرىنچى بولۇپ يەتكۈزۈلىدۇ"
    }, {
      "title": "زاكاز تالونىنى تېز ئىجرا قىلىش",
      "desc": "يۇقىرى سۈرئەتلىك سودا ۋە دەرھال پۈتۈشۈش سودا بۇيرۇقى. مەيلى بازار باھاسى ياكى تالون ئېسىپ سودا قىلىڭ، زىياننى تىزگىنلەش ياكى پايدىنى توختىتىش ئارقىلىق پايدىنى قوغدىغىلى بولىدۇ. بارلىق تۈردىكى سودا زاكاز تالونىنى قوللايدۇ، بۇ بازار زاكاز تالونى، زاكاز تالونى، زىياننى توختىتىش زاكاز تالونى ۋە ئىز قوغلاپ زىياننى توختىتىش قاتارلىقلارنى ئۆز ئىچىگە ئالىدۇ "
    }, {
      "title": "ھېساباتنى تەكشۈرۈش",
      "desc": "سىز خالىغان ۋاقىتتا ئۆزىڭىزنىڭ ھېساباتىدىكى قالدۇق سومما ۋە پايدا-زىيان قاتارلىق ئۇچۇر ۋە ئەھۋاللارنى كۆرۈپ باقسىڭىز بولىدۇ، نۆۋەتتىكى مۈلۈك ئەھۋالىنى بىر قاراپلا بىلگىلى بولىدۇ. "
    }, {
      "title": "بىخەتەر ئىشەنچلىك",
      "desc": "سۇپا بىلەن مۇلازىمېتىر ئارىسىدىكى بارلىق سانلىق مەلۇماتلار 256 خانىلىق سانلىق مەلۇماتلار ئارقىلىق شىفىرلاشتۇرۇلىدۇ، EBC خېرىدارلارغا بىخەتەر، ئىشەنچلىك سودا مۇھىتى يارىتىپ بېرىدۇ "
    }],
    "btn": "دەرھال چۈشۈرۈش "
  },
  "screen4": {
    "pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "  MT4سودا سۇپىسى ",
    "title": "يەككە ھېسابات سودىسىدىن بارلىق كاپىتال بازىرى مەھسۇلاتلىرى. ",
    "desc": " نىڭ ئەۋزەللىكى ئۈستىدە ئىزدىنىش MT4",
    "list": [{
        "title": "MT4 كۆچمە چۈشۈرۈش",
        "desc": "MT4 كۆچمە قوللىنىشچان پروگراممىسى ئارقىلىق سودىڭىزنى كونترول قىلىڭ ، سودا پۇرسىتىنى ھەرگىز قولدىن بېرىپ قويماڭ."
      },
      {
        "title": "MT4 ئۈستەل يۈزى نۇسخىسىنى چۈشۈرۈش",
        "desc": "EBC نىڭ دەرىجىدىن تاشقىرى تېز ئىجرا قىلىش سۈرئىتى ۋە مۇكاپاتقا ئېرىشكەن MT4 سۇپىسىدىكى كۈچلۈك سودا ئىقتىدارلىرىنى باشتىن كەچۈرۈڭ."
      },
      {
        "title": "MT4 تور تېرمىنالى",
        "desc": "سىز توركۆرگۈ ئارقىلىق دۇنيانىڭ ھەر قانداق يېرىدىن MT4 غا كىرەلەيسىز."
      }
    ],
    "text1": "تېخىمۇ چوڭقۇر ئىگىلەيمىز",
    "text2": "بىز MT4 ئىشلەتكۈچىلەر قوللانمىسىنى چۈشۈرۈپ",
    "text3": "，MT4 نىڭ بارلىق ئىقتىدارلىرىنى ",
    "text4": "سىزنىڭ يەر شارى بويىچە ئەڭ قارشى ئېلىنىدىغان سودا تېرمىنالىنى ئاسانلا ئورنىتىشىڭىزغا يېتەكچىلىك قىلىدۇ. ",
    "btn": "مەبلەغ سېلىشنى باشلاش "
  },
	"section1": {
		"title": "دەرھال ئىجرا قىلىش بىلەن دۇنيا مىقياسىدا سودا قىلىڭ",
	},
	"section2": {
		"title": " ھەر بىر ئېھتىياج ئۈچۈن سودا سۇپىسى",
		"desc": "EBC MetaTrader4 ئاددىي ۋە خاسلاشتۇرۇلغان ئىشلەتكۈچى كۆرۈنمە يۈزى بىلەن تەمىنلەيدۇ ، ئەتراپلىق دىئاگرامما ئىقتىدارى ۋە كۈچلۈك ئانالىز قوراللىرى بىلەن تەمىنلەيدۇ ، سىزنىڭ ھەر ۋاقىت ۋە ھەر قانداق جايدا بازار بىلەن ماس قەدەمدە مېڭىشىڭىزغا ياردەم بېرىدۇ ھەمدە تەرتىپلىك باشقۇرۇشنى ئەمەلگە ئاشۇرىدۇ.دۇنيادىكى 30 نەچچە دۆلەت ،% 70 سودا سۇپىسىنىڭ تاللىنىشى ، دۇنيادىكى پارچە سېتىش مەھسۇلاتلىرىنىڭ% 90 ىنى توشۇيدۇ ، Metatrader 4 ھازىرغا قەدەر ئەڭ ئالقىشقا ئېرىشكەن تور سودا يۇمشاق دېتالى.",
	},
	"section3": {
		"title": "MetaTrader 4 دېگەن نېمە؟",
		"desc1": "MetaTrader 4 (MT4) بۈگۈنكى كۈندە MetaQuotes يۇمشاق دېتال شىركىتى تەتقىق قىلىپ ياساپ چىققان دۇنيادىكى ئەڭ ئالقىشقا ئېرىشكەن سودا سۇپىلىرىنىڭ بىرى. بۇ سۇپىنىڭ ئىلغار تېخنىكىسى ، ئىشلىتىش قولايلىقلىقى ۋە يۇقىرى بىخەتەرلىك ئامىلى بولغاچقا ، دۇنيانىڭ ھەرقايسى جايلىرىدىكى سودىگەرلەر تەرىپىدىن ئېتىراپ قىلىندى. MT4 نىڭ ئاساسلىق ئىقتىدارى ئابونتلارنىڭ بازارنى بايقاشنى ئاسانلا ھېس قىلىشىغا شارائىت ھازىرلاپ ، ئابونتلار بازارغا كىرىپ-چىقالايدۇ ، باھانى چەكلەيدۇ ۋە زىيان تەرتىپىنى ۋاقتىدا ۋە ئۈنۈملۈك توختىتالايدۇ.",
		"desc2": "EBC نىڭ MT4 سۇپىسىنى تاللاش سىزنىڭ تېخنىكىلىق كۆرسەتكۈچ ۋە EA نى خاسلاشتۇرالايدىغانلىقىڭىزدىن باشقا ، يەنە تاشقى پېرېۋوت ، قىممەتلىك مېتال ، خام نېفىت ، پاي چېكى ۋە كۆرسەتكۈچ قاتارلىق بىر يۈرۈش سودا مەھسۇلاتلىرىنى تاللىيالايسىز."
	},
	"section4": {
		"title": "MT4 سوئال",
		"list": [{
			"title": "مەن MT4 نى چۈشۈردۈم ، ھەقىقىي ھېساباتىمغا قانداق كىرىمەن؟",
			"desc": "مەبلەغنى مۇۋەپپەقىيەتلىك ئامانەت قويغاندىن كېيىن ، ھېسابات ئاكتىپلىنىدۇ ۋە سىزگە سودا ھېساباتى ، پارول ۋە مۇلازىمېتىر سانلىق مەلۇماتلىرى تەمىنلىنىدۇ. سىز پەقەت بېرىلگەن ھېسابات ۋە پارولنى كىرگۈزۈشىڭىز ، توغرا مۇلازىمېتىرنى تاللىشىڭىز ، ئاندىن ھەقىقىي ھېساباتىڭىزغا كىرىشىڭىز كېرەك."
		},
		{
			"title": "MT4 دىكى پارولنى قانداق ئۆزگەرتىش كېرەك؟",
			"desc": "MT4 سۇپىسىغا كىرگەندىن كېيىن قوراللار - تاللانمىلارنى چېكىڭ ، ئاندىن مۇلازىمېتىر ستونىدىكى پارولنى ئۆزگەرتەلەيسىز."
		},
		{
			"title": "MT4 غا دىئاگرامما كۆرسەتكۈچىنى قانداق قىستۇرۇش كېرەك؟",
			"desc": "تۆۋەندىكى باسقۇچلارغا ئەگىشىڭ：<br>1. ئۈستى تىزىملىكتىكى «قىستۇرۇش» -> «تېخنىكىلىق كۆرسەتكۈچ» نى تاللاڭ ؛ <br>2. ياكى «يول باشلاش» ئارقىلىق «تېخنىكىلىق كۆرسەتكۈچ» قىستۇرالايسىز."
		},
		{
			"title": "                                            يۈزلىنىش ياكى باشقا ھەرىكەتلەندۈرگۈچ كۈچ تەتقىقاتىنى قانداق يارىتىش كېرەك؟",
			"desc": "بۇ باسقۇچلارغا ئەگىشىڭ:<br>1. ئۈستى تىزىملىكتىكى «قىستۇرۇش» نى تاللاڭ ؛ <br>2. ئۆزىڭىز خالىغان يۈزلىنىشنى تاللاڭ ؛ <br>3. چېكىشنى قويۇپ بېرىڭ ، ھالقىما بەلگە تاللانغان يۈزلىنىشنى كۆرسىتىدۇ. تاللانغان جەدۋەلدىكى ئۆتكەلنى يۆتكەش ئۈچۈن چاشقىنەكنى چېكىڭ ۋە چېكىشنى بېسىپ تۇرۇڭ ، مائۇسنى يۆتكەپ يۆنىلىشنىڭ چوڭ-كىچىكلىكىنى ئۆزگەرتىڭ. سىز يەنە «كۆرۈش» -> «قورالبالدىقى» -> «قۇر تىپى تەتقىقاتى» نى چېكىپ يۈزلىنىش تەتقىقاتى تېزلەتمە كۇنۇپكىسىنى تېز ئىشلىتەلەيسىز ، بۇ تىزىملىك ​​تىزىملىك ​​بالدىقىنىڭ ئاستىدا كۆرۈنىدۇ. يۈزلىنىش لىنىيىسى ، دوجىس ياكى فىبوناچچى قايتىش ئورنىنى چېكىش ئارقىلىق ئېكراننىڭ ئۈستىدىكى باشقا تاللاشلارنى تاللىيالايسىز."
		},
		{
			"title": "MT4 دىكى ئىشلەتكۈچى تىلىنى قانداق تاللاش كېرەك؟",
			"desc": "ئۈستى تىزىملىككە كىرىپ كۆرۈش -> تىل -> مەسىلەن ، ئاددىيلاشتۇرۇلغان خەنزۇچە ، ئاندىن يۇمشاق دېتالنى قايتا قوزغىتىڭ ، ئىشلەتكۈچى تىلى ئاددىيلاشتۇرۇلغان خەنزۇچىغا ئۆزگىرىدۇ."
		}
		],
	},
	"btn-text": "MT4 نى چۈشۈرۈڭ",
  "download-btn1": "Download for Android",
	"download-btn2": "Download for iOS",
	"download-btn3": "Download for iPad",
	"download-btn4": "Download on Windows",
	"download-btn5": "Download for MacOS",
	"download-btn6": "WebTrader",
}
