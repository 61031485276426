export default {
	"box-name": "partner2-page-en",
	"section1": {
		"text1": "Trader Empowerment Network",
		"text2": "We foster a dynamic ecosystem uniting content creators, marketing specialists, and agents to empower traders, expand global opportunities, and unlock their full potential.",
		"text3": "Operating in over 20 countries",
		"text4": "Harnessing the expertise of top industry professionals",
		"text5": "Offering diverse partnership models",
		"text6": "Providing comprehensive resource support",
	},
	"section2": {
		"text1": "Partnership Models",
		"text2": "Introducing Broker (IB)",
		"text3": "Educators, Professional Traders, Independent Financial Advisors and Agencies",
		"text4": "<li>Business Development Planning</li><li>Brand Collab and Exposure Opportunities</li><li>Trading Resources and Marketing Support</li>",
		"text5": "Become an IB",
		"text6": "CPA Marketing Partner",
		"text7": "Bloggers, KOLs <br>and Online Marketers",
		"text8": "<li>Reward Packages</li><li>Customised Partnership Plans</li><li>Multimedia Marketing Support</li>",
		"text9": "Become a Marketing Partner",
		"text10": "Strategic Partner",
		"text11": "Professional Analysts, Veteran Traders and<br> Industry Experts",
		"text12": "<li>Tailored Gifts</li><li>Online and  Offline Marketing Integration</li><li>Trading Resource and Marketing Support</li>",
		"text13": "Become a Strategic Partner",
	},
	"section3": {
		"text1": "Marketing Support",
		"text2": "Media Exposure",
		"text3": "Providing a platform to showcase expertise, enhancing brand recognition for strategic partners.",
		"text4": "Expand Industry Connections",
		"text5": "Connect with top industry experts to share experiences and explore market trends.",
		"text6": "Marketing Resources Support",
		"text7": "Providing marketing materials and campaign support, with tailored promotion strategies for our partners.",
		"text8": "Senior Analyst Training Programs",
		"text9": "Senior Analyst Training Programs designed to elevate skills and deepen trading expertise.",
		"text10": "Order Flow Tools",
		"text11": "Essential tools for professional traders to identify major capital flows and improve trading success rates.",
		"text12": "Expanding Thought Leadership",
		"text13": "A knowledge bank that empowers skills, broadens influence, and enhances the ability to make a positive impact.",
	},
	"section4": {
		"text1": "Strategic Partners",
		"text2": "Joe DiNapoli",
		"text3": "<li>Godfather of Fibonacci</li><li>Founder of DiNapoli Levels</li><li>Recognised as the authoritative figure in the application of the Golden Ratio trading method</li>",
		"text4": "Joe DiNapoli boasts over 55 years of trading experience, including predicting the 1987 market crash and trading S&P futures since 1982. Renowned for his research on Displaced Moving Averages, Advanced Fibonacci techniques, and the MACD Predictor, he authored the acclaimed “Trading with DiNapoli Levels,” translated into 13 languages. A registered CTA for over 25 years, Joe lectures globally and continues to trade daily.",
		"text5": "Learn more",
		"text6": "<li>Lawrence, with a background in commercial construction, transitioned to the financial markets for a more fulfilling career. Discovering Joe DiNapoli's \"DiNapoli Levels\" transformed his trading approach. Since attending Joe's seminar in Bangkok 17 years ago, Lawrence accurately predicts market movements, including navigating the Covid Market Crash. Now a DiNapoli Expert, he mentors new traders to avoid pitfalls and accelerate learning.</li>",
		"text7": "<li>Pieter has been interested in trading since high school. As a Cum Laude qualified economist, he started full time trading at a local South African firm, intensely trading a broad spectrum of markets. He has been a student of DiNapoli studies since 2003 and teaching the DiNapoli method across the world since 2009. Today, he manages his own account, is part of a South African hedge fund and advisor to South African commodity firms. He is also the author of the D-Levels Financial Forecast newsletter. He attributes much of his success to Joe DiNapoli’s mentorship.</li>",
		"text8": "<li>Monchai Kongthanapakdi is founder of Technical Analysts Association in Thailand and Co-chair of CMT Association Thailand Chapters as well as president of Efuturesthai Trading Academy, an organization established for teaching and training technical analysis for Thai investors.<br><br>He is a full-time trader, technical analyst, and investor who specializes in Fibonacci (DiNapoli Levels) and Algorithmic trading. Monchai has been working with Mr. Joe DiNapoli since 2011 to conduct several public and private seminars in Thailand including a recent translation of Trading with DiNapoli Levels (Thai Edition) in 2022.</li>",
		"text9": "<li>President of Fibonacci Investment Consulting, LLC, USA; Guest Lecturer at the New York Institute of Finance Beijing Branch and the Chicago Board of Trade; Gold Medal Mentor at the National Real-Money Trading Competition of China Futures Daily; Translator of the Chinese edition of \"Trading with DiNapoli Levels\". As a senior expert of the DiNapoli-Levels, he has successfully predicted nearly all the turning points of the Shanghai Composite Index since 2008, as well as several intermediate tops and bottoms of the Dow Jones Index, the US Dollar Index, US crude oil futures, and US gold futures.</li>",
	},
	"section5": {
		"text1": "More Insights",
		"text2": "On July 12, 2024, EBC Financial Group and veteran trader Joe DiNapoli held a global signing ceremony in Bangkok to discuss trading strategies and strengthen our collaborative presence in the global market.",
		"text3": "Sharing Trading Strategies",
		"text4": "DiNapoli and his team discussed advanced trading strategies at this landmark event, drawing significant attention from international and local media, key opinion leaders, VIP clients, industry specialists, and business leaders.",
		"text5": "Handing out autographed books at the event",
		"text6": "At the event, DiNapoli handed out limited copies of his autographed books, providing a unique memento for attendees.",
		"text7": "Building Connections and Growth Together",
		"text8": "This partnership fosters professional development in trading, enhancing industry recognition, and creating shared benefits for all involved.",
	},
}