export default {
  "screen0": {
    "title": "Streamlined <br>PAMM Solutions",
    "content": "EBC offers a customized Percentage Allocation Management Module (PAMM) for fund managers and investors in search of professional account management services. Through the web version back office, investors and fund managers can access comprehensive functions such as trading capital management, profit distribution, detailed formulation, and trading record downloads. The web version interface is user-friendly, with a clear layout and comprehensive features.",
    "tips":"EBC provides fund managers and investors with a percentage allocation management model (PAMM) with clear rights and responsibilities and comprehensive functions; the background of the web version is concise and clear, simplifying complexity."
  },
  "screen1": {
    "pageEnTitle": "Clear Responsibilities",
    "pageTitle": "Clear Responsibilities",
    "c1Title": "Investors",
		"c1Desc": "Enjoy a seamless trading experience and benefit from the expertise of fund managers. Investors have the freedom to select fund managers based on their risk tolerance and profit objectives. The inclusion of high watermark clauses serves as an incentive for money managers while safeguarding their rights.",
		"c2Title": "Fund manager",
		"c2Desc": "Traders with extensive trading experience can qualify as PAMM account fund managers. Fund managers have the ability to manage multiple trading accounts simultaneously, utilizing their own funds as well as the pooled funds from investors to trade and generate profits. A portion of the trading profits or costs serves as remuneration for the account fund managers' services.",
    "title": "PAMM accounts can meet the requirements of many participants",
    "desc": "Investors: Enjoy one-stop trading services and share the trading results of fund managers. <br>Investors can freely choose fund managers that meet their risk appetite and profit goals. <br>High water mark clauses incentivize money managers and protect their rights. <br><br>Fund managers: Traders with rich trading experience can play the role of PAMM account fund managers.<br>Fund managers can manage multiple trading accounts at the same time, using their own funds and investors' pooled funds Trade for profit. A portion of the transaction benefits or costs as labor compensation for account fund managers."
  },
  "screen2": {
    "pageEnTitle": "Simplified",
    "pageTitle": "Simplified",
    "title": "The background of the web version is concise and clear, simplifying the complex",
    "desc": "We offer comprehensive solutions that encompass daily information management, trading lot/profit distribution, and record downloads. The web version back office is designed to be concise and clear, effectively simplifying complexity."
  },
  "screen3": {
    "pageEnTitle": "FEATURES AND FUNCTIONS",
    "pageTitle": "ADVANTAGES",
    "title": "Suitable for any type of trading and money management",
    "text1":"Up to 9 languages",
    "text2":"Enhanced fund security and seamless transactions",
    "text3":"2 Mainstream profit distribution methods.(distribution by net value and distribution by balance)",
    "text4":"High water mark incentives for fund managers Protection of investors",
    "text5":"The web version is designed to be concise clear and user friendly",
    "text6":"Provide 5 profit methods for fund managers(profit share/management fee/annual management fee/ per lot commission/per order commission)"
  },
	"imgUrl": "en",
	"styleName": "en-style en-style2",
	"btn-text": "Apply For PAMM"
}
