export default {
  "box-name": "",
  "public-btn-text1": "즉석 거래",
  "public-btn-text2": "스프레드 및 모든 제품 보기",
  screen0: {
    title: "CFD 배당금",
    desc: '알림: CFD 배당금의 수령은 귀하의 MT4 계좌의 잔액에 예금 또는 출금 형식으로 반영되며, "Dividend"이라고 기록됩니다. ',
  },
  screen1: {
    title: "안전한 거래",
  },
  screen2: {
    title: "재계가 여러분의 손 안에 있습니다",
  },
  指数差价合约与股息调整: "지수 CDF 및 배당금 조정",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "주식 및 지수 CFD 거래의 배당금 조정 및 분배 메커니즘에 관하여",
  了解指数差价合约: "지수 CDF 알아보기",
  指数差价合约: "지수 CDF",
  指数差价合约允许投资者通过股票市场指数的价格波动进行交易:
    "지수 CFD는 실물 자산을 보유하지 않고도 주식 시장 지수의 가격 변동을 통해 거래할 수 있습니다. S&P 500, 다우존스, 독일 DAX 등 모든 유형의 글로벌 주가지수에 적용 가능하여 유연한 거래 옵션을 제공합니다.",
  股息调整: "배당금 조정",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "배당금은 상장회사가 이익 또는 유보 자금에서 주주에게 정기적으로 분배하는 자금으로, 주식이 배당금을 지급할 때 관련 주식과 지수 차액 거래 계약 상품도 배당금 조정이 이루어집니다.",
  除息日与交易优化: "제외일 및 거래 최적화",
  指数差价合约的除息日是由指数提供商根据成分股的除息日设定的:
    "지수 CDF의 제외일은 지수 제공자가 구성 주식의 제외일에 따라 설정합니다. 매수 포지션을 가진 경우 배당금을 받을 수 있으며, 매도 포지션을 가진 경우 배당금을 지급해야 합니다. 이러한 날짜를 미리 아는 것은 수익 관리 최적화에 도움이 됩니다.",
  股息调整明细: "배당금 조정 내역",
  差价合约交易中相关产品的股息数据:
    "CFD 거래에서 해당 상품의 배당금 데이터는 매수(롱) 배당금과 매도(숏) 배당금으로 구분하여 표시합니다.",
  即刻交易: "거래 시작",
  股息的常见问题: "배당금 관련 자주 묻는 질문",
  什么是股息和除息日: "1. 배당금과 제외일이란?",
  股息是上市公司从利润或保留资金中定期分配给股东的资金answer:
    "배당금은 상장회사가 이익 또는 유보 자금에서 주주에게 정기적으로 분배하는 자금입니다. 주식이 배당금을 지급할 때, 관련 주식 및 지수CDF 제품도 배당금 조정을 받습니다. <br /><br />지수 CDF의 제외일은 지수 제공자가 구성 주식의 제외일에 따라 설정되며, 이는 배당금 지급일이기도 합니다.",
  股息调整的时间: "2. 배당금 조정 시간은?",
  指数差价合约股息调整通常会在除息日的answer: `지수 CFD의 배당금 조정은 일반적으로 제외일의 05:00-06:00(GMT+3)에 귀하의 MT4/MT5 거래 계좌에 반영되며, "Dividend(배당금)"으로 표시됩니다.`,
  股息对指数交易的影响: "3. 배당금이 지수 거래에 미치는 영향은?",
  持有多头仓位answer:
    "매수(롱) 포지션일 경우, 배당금 조정이 귀하의 MT4/MT5 계좌에 입금됩니다.<br /><br />매도(숏) 포지션일 경우, 배당금 조정이 귀하의 MT4/MT5 계좌에서 차감됩니다.",

  股息调整的计算公式: "4. 배당금 조정은 어떻게 계산하는지?",
  多头仓位answer:
    "매수 포지션(롱) 계산 공식: 손수 × 계약 규격 × 매수 배당금 금액 (지수 기준 통화로 계산)<br /><br />매도 포지션(숏) 계산 공식: 손수 × 계약 규격 × 매도 배당금 금액 (지수 기준 통화로 계산)",
  股息调整计算示例: "5. 배당금 조정 계산 예시",
  以SPXUSD为例answer: `SPXUSD를 예로 들면, 제외일이 2024년 10월 11일이고 매수 배당금 금액이 0.050달러, 매도 배당금 금액이 -0.050달러라고 가정합니다.<br /><br />

만약 제외일 전날(10월 10일) 장 마감 시 1 표준 손수의 SPXUSD 매수 포지션을 보유하고 있다면, 배당금 조정의 계산은 다음과 같습니다:<br /><br />

배당금 조정 = 1(표준 손수) × 100(계약 규격) × 0.050달러(매수 배당금 금액) = 5달러<br /><br />

제외일의 05:00-06:00(GMT+3)에 5달러가 귀하의 MT4/MT5 계좌에 입금되며, ""Dividend(배당금)""으로 표기됩니다.<br /><br />

매도 포지션의 경우  5달러가 귀하의 MT4/MT5 계좌에서 차감되며, ""Dividend(배당금)""으로 표기됩니다.`,
  注意事项: "6. 주의사항",
  股息调整仅适用于指数和股票差价合约交易answer: `배당금 조정은 지수 및 주식 CFD 거래에만 적용되며, 보유한 포지션 유형(매수 또는 매도)에 따라 계좌 잔액이 조정됩니다. 배당금 금액 및 그 영향은 특정 지수와 시장 조건에 따라 달라질 수 있습니다.<br /><br />

궁금한 점이 있으시면 고객 관리자에게 문의하시거나 저희 공식 웹사이트를 참조해 주시기 바랍니다.`,
list:['Trading Products', 'Long Dividend', 'Short Dividend', 'EX-Dividend Day']
};
