export default {
	"fca1": "FCA 流动性清算账户开设流程",
	"fca1-m": "FCA 流动性清算账户",
	"fca2": "EBC Financial Group (UK) Ltd 是受英国金融市场行为管理局（FCA）授权并监管的综合金融券商，所有交易订单直通国际清算池。 监管编号：927552 <br /><br />在EBC Financial Group (UK) Ltd开设FCA流动性清算账户，有权根据FSCS赔偿计划要求最高85000英镑的赔偿。",
	"fca3": "欧盟《金融工具市场法规（MiFID）》及其后修订版MiFID II根据客户的知识水平、经验和专长将投资者分为“零售投资者”或“专业投资者”等。<br><br>根据FCA法规，EBC Financial Group (UK) Ltd 仅接受专业投资者和机构投资者，可开设杠杆为100倍的FCA流动性清算账户，投资者资金将独立托管于巴克莱银行。",
    "fca4":{
		"title":"专业投资者须满足以下三个标准中的其中两个",
		"title-m":"个人投资者须满足三个标准的其中两个，即可申请为专业投资者",
		"desc":"过去一年平均交易频率达到<br>10次/季度",
		"desc1":"持有超过50万欧元的投资组合，<br>投资组合包括现金储蓄和金融工具",
		"desc2":"在金融行业工作或至少工作过一年，<br>了解差价合约或衍生品交易"
	},
	"fca5":{
		"title":"FCA流动性清算账户开设流程",
		"desc":"申请表<br>下载并填写",
		"desc1":"材料准备<br>和认证",
		"desc2":"材料递交<br>并邮件申请",
		"desc3":"合规审核<br>和文件签署",
		"desc4":"FCA开户成功<br>账户设置",
		"btn":"专业投资者申请表下载",
		"btn1":"机构投资者申请表下载"
	},
	"fca6":{
		"title":"开设FCA流动性清算账户<br>最权威的监管保护和最安全的资金保障",
		"title-m":"FCA账户，最权威的监管保护和最安全的资金保障",
		"desc":"英国金融市场行为管理局（FCA）",
		"desc1":"FCA流动性清算账户咨询邮箱：",
		"desc2":""
	},
	"lang":"cn"
}

