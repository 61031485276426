export default {
  "box-name": "",
  "public-btn-text1": "即刻交易",
  "public-btn-text2": "查看点差和全部产品",
  screen0: {
    title: "CFD 股息",
    desc: "请注意：CFD 股息收取会在您的MT4账户上的余额里以存款或者取款形式反映出来，注释为 “Dividend”",
  },
  screen1: {
    title: "安全交易",
  },
  screen2: {
    title: "金融世界 触手可及",
  },
  指数差价合约与股息调整: "指数差价合约与股息调整",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "了解您在股票和指数差价合约交易中的股息调整和分配机制",
  了解指数差价合约: "了解指数差价合约",
  指数差价合约: "指数差价合约",
  指数差价合约允许投资者通过股票市场指数的价格波动进行交易:
    "指数差价合约允许投资者通过股票市场指数的价格波动进行交易，而无需持有实际资产。它适用于全球各类股指，如标普500、道琼斯指数、德国DAX等，提供灵活的交易选择。",
  股息调整: "股息调整",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "股息是上市公司从利润或保留资金中定期分配给股东的资金，当股票派发股息时，相关股票和指数差价合约产品也会进行相应的股息调整。",
  除息日与交易优化: "除息日与交易优化",
  指数差价合约的除息日是由指数提供商根据成分股的除息日设定的:
    "指数差价合约的除息日是由指数提供商根据成分股的除息日设定的，持多头将获得股息，持空头则需支付股息。提前了解这些日期有助于优化收益管理。",
  股息调整明细: "股息调整明细",
  差价合约交易中相关产品的股息数据:
    "差价合约交易中相关产品的股息数据，按多头（买入）股息和空头（卖出）股息分开显示",
  即刻交易: "即刻交易",
  股息的常见问题: "股息的常见问题",
  什么是股息和除息日: "1、什么是股息和除息日？",
  股息是上市公司从利润或保留资金中定期分配给股东的资金answer:
    "股息是上市公司从利润或保留资金中定期分配给股东的资金，当股票派发股息时，相关股票和指数差价合约产品也会进行相应的股息调整。<br /><br />指数差价合约的除息日是由指数提供商根据成分股的除息日设定的，也是股息发放日。<br /><br />",
  股息调整的时间: "2. 股息调整的时间",
  指数差价合约股息调整通常会在除息日的answer:
    "指数差价合约股息调整通常会在除息日的05:00-06:00（GMT+3）反映在您的MT4/MT5交易账号中，并标注为“Dividend”。",
  股息对指数交易的影响: "3. 股息对指数交易的影响",
  持有多头仓位answer:
    " 持有多头仓位（买入）：股息调整将存入您的MT4/MT5账号。<br /><br />持有空头仓位（卖出）：股息调整将从您的MT4/MT5账号扣除。",

  股息调整的计算公式: "4. 股息调整的计算公式",
  多头仓位answer:
    "多头仓位（买入）计算公式：手数 × 合约规格 × 多头股息金额（按指数计价货币计算） <br /><br />空头仓位（卖出）计算公式：手数 × 合约规格 × 空头股息金额（按指数计价货币计算）",
  股息调整计算示例: "5. 股息调整计算示例",
  以SPXUSD为例answer: `以SPXUSD为例，假设其除息日为2024年10月11日，多头股息金额为 0.050美元，空头股息金额为-0.050美元。<br /><br /> 如果您在除息日前一天(10月10日)收盘时持有1标准手的SPXUSD多头仓位（买入），则股息调整的计算如下：<br /><br /> 股息调整 = 1（标准手） × 100（合约规格） × 0.050美元（多头股息金额） = 5美元<br /><br /> 在除息日的05:00-06:00（GMT+3），5美元将存入您的MT4/MT5账号，备注为“Dividend”。<br /><br /> 如果您持有空头仓位（卖出），5美元将从您的MT4/MT5账号中扣除，备注为“Dividend”。`,
  注意事项: "6. 注意事项",
  股息调整仅适用于指数和股票差价合约交易answer:
    "股息调整仅适用于指数和股票差价合约交易，并会根据持有的仓位类型（多头或空头）相应调整账号余额，股息金额及其影响可能因具体指数和市场条件而异。<br /><br />如有任何疑问，请联系您的客户经理或查看我们的官方网站获取更多信息。",
  list:['交易产品', '多头股息', '空头股息', '除息日']
};
