export default {
	"pages1":{
		"title":"EBC intelligent copy-trading community",
		"tips":"Every Trader Has A Hero Dream In His Heart",
		"text":"community advantages",
		"text1":"signal display",
		"text2":"trade rewards plan ",
		"text3":"grand prize",
		"text4":"Sign in"
	},
	"pages2":{
		"title":"community advantages",
		"list":[{
			"tit":"flexible copy-trading pattern",
			"tips":"initiation and flexible customisation of copy-trading without EA plugin "
		},{
			"tit":"millisecond response",
			"tips":"trades in synch with signals through connection-oriented MT protocol"
		},{
			"tit":"professional six-dimensional analysis",
			"tips":"six-dimensional analysis enhances signal rating sytem in pursuit of quality signals"
		},{
			"tit":"intelligent AI-assisted tools",
			"tips":"one-click signal screening based on your preference to explore top trading strategies"
		},{
			"tit":"transparent trading disclosures",
			"tips":"proven track record of trading in details for observational learning"
		}],
		"btn":"sign in"
	},
	"pages3":{
		"title":"signal display",
		"text":"rate of return",
		"text1":"maximum drawdown ",
		"text2":"Sharp ratio",
		"text3":"P/L ratio",
		"text4":"free",
		"text5":"subscribe now",
		"btn":"more signals"
	},
	"pages4":{
		"title":"trade rewards plan ",
		"num":"$100 per month",
		"num1":"$300 per month",
		"num2":"$1,000 per month",
		"num3":"$5,000 per month",
		"num4":"$10,000 per month",
		"text1":"AUM",
		"text2":"Follwing Traders",
		"name":"beginning",
		"name1":"good",
		"name2":"excellent",
		"name3":"star",
		"name4":"legend",
		"tips":"1% AUM per annum (paid monthly)",
		"btn":"Join Now"
	},
	"pages5":{
		"title":"grand prize",
		"text":"7x24 customer service",
		"text1":"EBC marketing campaign involvement",
		"text2":"VPS sponsorship",
		"text3":"investing books sponsorship ",
		"text4":"one-year subscription of order flow trading software",
		"text5":"customised rewards package",
		"text6":"signal displayed at the top",
		"text7":"a visit to EBC overseas offices",
		"text8":"shared media resoruce",
		"text9":"co-branding",
		"text10":"offshore fund launch advisory service ",
		"btn":"Sign In "
	}
}