export default {
  "box-class-name": "oxford-box-en",
  "button-text": "더 찾아 봐",
  "oxford-page1": {
    text1: "WHAT<br> ECONOMISTS<br> REALLY<br> DO",
    text2: "탈세의 경제학",
    text3: "Sarah Clifford & David Barrett",
    text4: "지금 예약",
    text5: "2024년 3월 6일 오후 12시(그리니치 표준시)",
  },
  "oxford-page2": {
    text1: "교육을 통한 번영하는 경제",
    text2:
      'EBC 금융 그룹은 다가오는 웨비나 "경제학자들이 실제로 하는 일"을 통해 주목받는 옥스퍼드 경제학부의 CSR 이니셔티브를 지원합니다.',
    text3:
      "기업의 사회적 책임(CSR)에 대한 지속적인 노력으로 EBC Financial Group은 사회적 발전을 주도하는 이니셔티브를 자랑스럽게 지원합니다. 교육 지원, 인재 개발 육성, 환경 지속 가능성 촉진 등 CSR에 대한 우리의 헌신은 우리가 의미 있는 변화를 만들 수 있도록 안내합니다. 우리는 우리의 자원과 전문 지식을 활용하여 긍정적인 변화를 만들고 모두를 위한 더 나은 미래에 기여한다고 믿습니다.",
    text4:
      "우리의 최근 협력은 이러한 약속을 잘 보여줍니다. 우리는 학문적 우수성과 사회 발전에 대한 헌신으로 유명한 기관인 옥스퍼드 대학교 경제학과와의 파트너십을 발표하게 된 것을 자랑스럽게 생각합니다. 이러한 협력을 통해 우리는 교육의 힘을 활용하여 지속적인 영향을 미치고 있습니다. 우리는 함께 개인이 전 세계 지역 사회에서 혁신하고 긍정적인 변화를 주도하도록 장려합니다. 우리는 다음 세대를 위해 더 밝고 공평한 미래를 만들기 위한 이 여정에 함께 해주세요.",
    text5: "이벤트 소개",
    text6:
      '2024년 3월 6일 오후 12시(GMT)에 열릴 예정인 "경제학자들이 실제로 하는 일"에 대한 웨비나는 "탈세의 경제학"이라는 주제를 탐구합니다. 이 토론에서는 오늘날 금융 산업이 직면한 문제와 경제 및 공교육을 활용하여 정책을 개선하고 경제 번영을 촉진하는 방법을 조명합니다.',
  },
  "oxford-page3": {
    text1: "관련 인물",
    text2: "옥스퍼드대학교 경제학과 부교수",
    text3:
      "Sarah는 과세, 특히 집행과 관련된 경제적 행동에 초점을 맞춘 공공경제학을 전문으로 합니다. 그녀의 연구 분야는 국제 법인세, 다국적 조세 회피, 가계의 개인 소득세 탈세 등입니다. 그녀의 전문 분야는 공공경제학과 응용미시경제학입니다.",
    text4: "경제학 교수, 너필드 칼리지, 부대표(외부 참여)",
    text5:
      "Abi의 응용경제학 연구에서 그녀는 종종 소비자와 가족 선택의 계량경제학에 중점을 두었습니다. 그녀의 연구 주요 주제는 데이터 의사결정에 새로운 모드를 제공하는 실증적 방법 개발, 노동 시장을 더 잘 이해하기 위해 대규모 데이터 세트 활용, 영국 법률 시스템에서 사법에 대한 접근을 정량화하기 위한 프레임워크 개발입니다.",
    text6: "CEO, EBC Financial Group (UK) Ltd.",
    text7:
      "Barrett 씨는 Tier 1 기관에서 소규모 사업 벤처에 이르기까지 외환, 채권 및 파생상품 분야에서 35년 이상의 전문 지식을 보유하고 있습니다. 그는 경제 주기와 규제 환경에 대한 포괄적인 이해를 바탕으로 글로벌 금융 위기와 규제 변화를 헤쳐나가며 위험 관리 및 비즈니스 전략에 대한 귀중한 통찰력을 제공합니다.",
  },
  "oxford-page4": {
    text1: "에 대한 EBC Financial Group",
    text2:
      "런던에 설립된 EBC Financial Group은 무결성과 고객 중심 초점을 바탕으로 외환, 원자재, 지수 부문에서 혁신적인 거래 서비스를 제공합니다. 여러 상을 수상한 것으로 인정받은 당사의 전용 서비스는 고급 인프라, 독점 도구 및 유동성 액세스 신속한 주문 실행 및 연중무휴 고객 지원을 통해 고객의 성장을 주도합니다.<br><br>광범위한 국제적 입지를 바탕으로 우리는 정직성과 윤리적 관행을 유지하면서 현지 시장을 예리하게 탐색합니다. 고객 우선 철학을 지닌 헌신적인 파트너를 찾는 사람들에게 EBC Financial Group은 역동적인 금융 세계에서 확실한 선택으로 떠오릅니다.<br><br>모든 헌신적인 거래자를 위한 탁월한 광채 관리.",
    text3: "옥스퍼드대학교 경제학과 소개",
    text4:
      "옥스포드 대학의 경제학과는 영향력 있는 연구, 영향력 있는 정책 기여, 활발한 초기 경력 커뮤니티, 존경받는 학부 및 대학원 프로그램으로 세계적으로 유명하여 가장 크고 다양한 학술 경제학자 커뮤니티 중 하나입니다.",
  },
  lang: "ko",
  经济学家都干了什么: "경제학자들이 실제로 하는 일",
  如何利用气候经济学: "기후 경제학으로 투자 기회를 탐색하다.",
  挖掘投资机遇: "",
  "2024年11月14日": "2024년 11월 14일 14:30(GMT)",
  "牛津大学Michael Dummett讲座厅": "옥스포드 대학교 Michael Dummett 강당",
  预约报名: "신청하기",
  EBC邀您游览牛津大学:
    "옥스포드와 함께하는 글로벌 세미나에 여러분을 초대합니다",
  EBC金融集团再次携手牛津大学: `EBC 금융 그룹이 다시 한 번 옥스포드 대학과 함께 글로벌 세미나를 개최합니다. 다가오는 2024년 11월 14일, "경제학자들이 실제로 하는 일" 시리즈의 첫 하이브리드(온·오프라인 동시 진행) 세미나를 진행합니다. 이번 세미나에서는 처음으로 패널 토론과 Q&A 세션이 추가될 예정입니다.

EBC 그룹과 옥스포드 대학교 경제학과 간의 협력은 새로운 단계로 나아가며, 그 이정표로서의 이번 세미나는 기후 경제학과 투자 전망의 도전에 대해 논의하며, 투자자들이 새로운 기회를 발견하는 데 기여할 것입니다.`,
  活动介绍: "세미나 소개",
  "本次线下研讨会将于11月14日14:30": `이번 오프라인 세미나는 2024년 11월 14일 14:30 (GMT)에 옥스포드 크라이스트처치 칼리지에서 개최될 예정이며, 전 세계 투자자들을 위해 온라인으로 동시 생중계됩니다.`,
  EBC金融专家还将与您一同漫步在新学院的回廊:
    "EBC 금융 전문가들과 함께 뉴 칼리지의 회랑을 거닐며 해리포터 '불의 잔' 영화 속처럼 마법과 같은 순간을 경험해 보시겠어요?  전 세계 학자들이 동경하는 학문의 성지인 보들리 도서관에서 수백 년에 걸친 옥스퍼드의 문화적 유산을 만끽하는 건 어떤가요? 옥스퍼드의 매력을 한껏 느낄 수 있답니다.",
  与会嘉宾: "게스트",
  牛津大学环境复原力及马丁系统韧性负责人:
    "옥스퍼드 대학교 환경 회복력 및 마틴 시스템 회복성 책임자",
  "Nicola Ranger是牛津大学环境复原力及马丁系统韧性负责人": `니콜라 레인저(Nicola Ranger)는 옥스퍼드 대학교 환경 회복력 및 마틴 시스템 회복성 책임자로, 옥스퍼드 대학교 환경 변화 연구소의 글로벌 금융 및 경제 그룹을 창립하였다. 그녀는 금융 기관, 중앙은행 및 정부와 긴밀히 협력하여 금융, 기후, 자연 및 분석 분야의 선도적인 연구를 추진하고 있다. 

레인저는 국제통화기금(IMF), 세계은행, NGFS 및 영국 기후 금융 위험 포럼과 협력하여 스트레스 테스트 및 시나리오 분석 방법 개발에 참여해왔다. 

또한, 그녀는 유럽연합(EU) 집행위원회 신흥시장 및 개발도상국(EMDE) 지속 가능한 투자 고위급 전문가 그룹, 영국 녹색 기술(분류법) 자문 그룹/LNAS, 영국 외무부(FCDO), 런던정경대학(LSE) 자문 역할을 맡고 있다. `,
  查看更多: "자세히",
  牛津大学经济学系教授: "옥스퍼드 대학교 경제학과 교수",
  牛津大学经济学系教授2: "옥스퍼드 대학교 경제학과 교수",
  "Andrea Chiavari 是牛津大学经济学系教授": `안드레아 키아바리(Andrea Chiavari)는 옥스퍼드 대학교 경제학과 교수로, 거시경제학을 전문으로 연구하며 특히 기업 역학과 기술 변화에 초점을 맞추고 있다. 

그는 경제 모델을 활용해 지속 가능한 발전을 지원하는 방법 중, 기후 변화가 기업의 생산성과 자본 배분에 미치는 영향을 연구하고 있다.

 또한, 그는 불확실한 환경에서 경제 효율성과 회복력을 높이는 방법, 그리고 경제 성장을 촉진하는 데 있어 기술의 역할에 특히 주목하고 있다.`,
  "Banu Demir Pakel 是牛津大学经济学系教授": `바누 데미르 파켈(Banu Demir Pakel)은 옥스퍼드 대학교 경제학과 교수로, 국제 무역 및 개발 경제학을 전문으로 연구하고 있다. 

그녀는 주로 기업들이 무역 관련 충격에 어떻게 대응하는지, 그리고 이러한 조정이 경제 성장과 소득 수준과 같은 전체 경제 결과에 어떻게 영향을 미치는지를 연구 중에 있다.

 또한, 그녀는 유럽 경제정책연구센터(CEPR)와 뮌헨 CESifo 연구 네트워크의 연구원으로 활동하고 있다.`,
  "EBC金融集团（英国）首席执行官": "EBC  금융 그룹 영국 본사 CEO",
  "David Barrett是EBC 金融集团 (英国) CEO": `데이비드 바렛(David Barrett)은 EBC 금융 그룹 의 CEO로, 국제 자산 다각화 및 구조적 위험 방지 분야에서 35년 이상의 경력을 보유하고 있다.

그는 여러 시장 주기를 경험하며, 세계 최대 투자 그룹 중 하나인 미국 AIG의 실행 및 관리 업무를 주도하고, 이를 통해 월스트리트의 제품 구조와 규정 준수, 리스크 관리 등 여러 분야에서 혁신을 이끌어냈다.

또한 금융 위기 이후 리스크 회피와 유동성 관리에 대한 실현 가능한 프레임워크를 구축하는 데 기여한 바 있다.`,
  往期回顾: "지난 세미나",
  EBC金融集团联合牛津大学经济学系第一期: `EBC 금융 그룹 -- 옥스포드 대학교 경제학과 제 1회 세미나: "경제학자들이 실제로 하는 일"`,
  "2024年3月6日EBC与牛津大学联合举办主题为": `2024년 3월 6일, EBC와 옥스퍼드 대학교는 "경제학자들은 실제로 무엇을 할까"라는 주제로 세미나를 공동 개최했다. 

해당 세미나에서는 경제학을 활용해 정책 개선을 촉진하고 경제 번영을 이끌어내는 방법에 대해 논의를 나누었고, 특히 "조세 회피 경제학"을 심도 있게 다루며, 다양한 세금 환경이 초래하는 경제적 트렌드와 경제학자가 글로벌 경제 안전에서 수행하는 역할을 종합적으로 해석하였다.`,
  关于EBC金融集团: "EBC 금융 그룹 소개",
  "EBC Financial Group 成立于英国伦敦": `EBC 금융 그룹은 영국 런던에 설립된 종합 금융 그룹으로, 금융 중개, 자산 관리, 이민 투자 등 다양한 서비스를 제공한다.

EBC는 전 세계 여러 주요 금융 관할권에서 허가를 받고 운영한다. 영국 금융행위감독청(FCA), 호주 증권투자위원회(ASIC), 케이맨 제도 금융관리국(CIMA)의 전체 감독 라이센스를 보유하고 있다. 

현제 런던, 홍콩, 도쿄, 시드니, 케이맨 등 여러 지역에 지사를 두고 있다.EBC 그룹의 핵심에는 30년 이상의 깊은 경험을 가진 숙련된 전문가들이 있으며, 이들은 플라자 합의부터 2015년 스위스 프랑 위기까지 주요 경제 주기를 헤쳐나온 경력이 있다.EBC는 FC 바르셀로나의 공식 외환 파트너이며, 유엔 재단의 캠페인인 "United to Beat Malaria"에 가입해  전 세계 건강 결과 개선에 적극적으로 기여하고 있다.`,
  关于牛津大学经济学系: "옥스퍼드 대학교 경제학과 소개",
  牛津大学是享誉全球的顶尖高等学府: `옥스퍼드 대학교 경제학과는 세계적으로 유명한 기관이자 영국에서 가장 큰 연구 센터 중 하나이다. 거의 천 년에 걸친 역사를 가진 옥스퍼드는 항상 개방적이고 포용적인 학문 환경을 조성하는 데 중점을 두어 왔다. 해서 혁신적인 경제 연구를 진행하며 국제적인 협력 촉진, 사회 발전에 기여에 힘 쓰고 있다. `,
  牛津大学经济学系以其专业权威的学术研究: `옥스퍼드 경제학과는 전문 연구와 글로벌 경제 및 공공 정책에 크게 기여하였으며, 세계에서 가장 다양한 경제학자 커뮤니티 중 하나로, 포용적인 학문적 분위기를 조성하고 혁신적인 연구를 통해 경제 발전을 이끌기 위해 헌신하고 있다.`,
};
