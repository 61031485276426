export default {
	"box-class-name": "cn-no1-box",
	"lang": "kr",
	"button-text": "도전을 시작하세요",
	"no1-page2": {
		"text1": "초당 최대 1000회 주문 체결이 가능합니다",
		"text2": "밀리초의 초고속 주문 처리",
		"text3": "데이터 전송 안정성이 최대 98.75%까지 유지됩니다",
		"text4": "은행 수준의 RAW ECN 스프레드로, 0.0 픽스의 투자 수준을 제공합니다. ",
	},
	"no1-page3": {
		"text1": "영국 FCA 감독",
		"text2": "EBC Financial Group (UK) Ltd는 영국 금융행동감독기관(FCA)의 승인과 감독을 받고 있습니다<br><span>감독 번호: 927552</span>",
		"text3": "호주 ASIC 감독",
		"text4": "EBC Financial Group (Australia) Pty Ltd는 호주증권및투자위원회 (ASIC)의 승인 및 감독을 받고 있습니다<br><span>감독 번호: 500991</span>",
	},
	"no1-page4": {
		"text1": "최강의 거래 환경<br>EBC는 한계가 아닌, 도전의 기준을 제시합니다<br>도전에 성공하면<br>EBC는 이익 차액의 150% 추가 보너스를 제공하며, 한도 없이 지급됩니다<br>우선, 도전을 시작하세요<br>EBC의 강력한 성능이 증명하도록 하겠습니다",
		"text2": "이익 차액의 150% 보너스 혜택",
	},
	"no1-page5": {
		"text1": "<span>85<i>%</i></span><br>이상의 주문이 최상의 가격으로 체결됩니다",
		"text2": "EBC의 블랙박스는 정확하게 최적화된 알고리즘으로, 가격 최적화, 주문 체결률과 알고리즘의 안정성 측면에서 전통적인 거래 시스템을 크게 능가합니다",
	},
	"no1-page6": {
		"text1": "챌린지 규칙",
		"text2": "진실과 존중을 원칙으로, 최상의 기준을 도전합니다",
		"text3": "예치금 요건",
		"text4": "플랫폼 선택",
		"text5": "동일한 전략",
		"text6": "도전 성공 시",
		"text7": "입금 요건이 없습니다",
		"text8": "영국 FCA 감독",
		"text9": "호주 ASIC 감독",
		"text10": "선택된 트레이더는 영국 FCA 또는 호주 ASIC 규제 자격을 갖추고 있습니다.",
		"text11": "EBC와 선택한 브로커에서 동일한 거래 자본과 전략을 사용해야 합니다.",
		"text12": "EBC에서 다른 플랫폼보다 수익이 낮을 경우 수익 차액의 150%를 보상해 드립니다.",
		"text13": "모든 참가자는 무료로 EBC VIP 계정을 신청할 수 있습니다. EBC는 무료로 제공되는 크로스 플랫폼 복사 거래 앱을 제공합니다",
	},
	"no1-page7": {
		"text1": "챌린지 노트",
		"text2": " 1. 챌린지 계좌 개설<br>a) 신규 사용자: 공식 웹사이트에서 새 계좌를 개설한 후 온라인 고객 서비스에 연락해 VIP 계좌를 개설하면 챌린지에 참여할 수 있습니다.<br>b) 기존 사용자: 온라인 고객 서비스에 연락하여 VIP 계정을 개설하고 챌린지에 참여하세요.",
		"text3": "2. 선택한 플랫폼은 영국 FCA 또는 호주 ASIC의 공식 감독을 받아야 합니다. AR, EEA 등록 또는 트레이딩 네임 계좌가 없어야 합니다.",
		"text4": "3. 다음과 같은 주요 통화쌍만 가능 - AUD/USD, USD/CAD, EUR/USD, USD/CHF, GBP/USD, USD/JPY, NZD/USD.",
		"text5": "4. EBC와 선택한 트레이더에서 동일한 거래 자본과 전략이 사용되며 모든 전략은 일반 거래 범주에 속해야 합니다.",
		"text6": "5. 도전자는 원하는 챌린지 시간에 결제할 수 있으며, 각 ID 카드는 한 번만 참여할 수 있습니다.",
		"text7": "6. 챌린지가 끝나면 청산된 포지션의 정산을 제출합니다. 보상은 심사 후 영업일 기준 2일 이내에 거래 계좌로 지급되며, 거래 또는 현금 출금에 사용할 수 있습니다.",
		"text8": "7. 계좌에 리베이트 상황이 있는 경우 고객은 적절한 증빙자료를 제출해야 합니다.",
	},
	"no1-page8": {
		"text1": "캠페인은 ... 내에 만료됩니다",
		"text2": "시작: 1월 18일 00:00 GMT+2 종료: 3월 1일 23:59 GMT+2",
		"text2-style": "font-size: 12px;",
	},
	"button1-style": "",
	"button2-style": "",
	"button3-style": "",
	"button4-style": "",
}