export default {
	"screen0": {
		"title": "Legal Document",
		"tit": "法律文件",
		"list": [
			"电邮联系",
			"线上专人"
		]
	},
	"screen1": {
		"list": [{
			"title": "1. Website Terms and Conditions 条例和条款",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Website-Terms-and-Conditions.pdf"
		}, {
			"title": "2. Risk Acknowledgement And Disclosure Notice 风险提示通知",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Risk-Acknowledgement-And-Disclosure-Notice.pdf"
		}, {
			"title": "3. Privacy Policy 隐私条款",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Privacy-Policy.pdf"
		}, {
			"title": "4. Execution Policy 执行条款",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Execution-Policy.pdf"
		}],
		"tit": "在找什么吗？",
		"name": "您可以搜索站内",
		"placeholder": "全站搜索",
		"btn": "搜索",
		"text": "或是洽询我们的专人服务",
		"btn1": "联系专人服务"
	}
}

