export default {
	"official-class-name": "official-cn-box",
	"lang": "ja",
	"official-page1": {
		"text1": "公式チャンネルの認証",
		"text2": "EBCの名称を悪用してお客様の利益を騙し取ることを防ぐため、当ページを通じてサイトの安全性を認証してください。",
		"text3": "該当ページのURLを入力してください",
		"text4": "認証する",
	},
	"official-page2": {
		"text1": "EBC公式声明:",
		"text2": "1. 現在EBCでご利用いただける取引ツールはMT4およびMT5のみとなっております。ご利用の際に弊社公式ウェブサイトからダウンロードをお願いします。",
		"text3": "2. 弊社は仮想通貨、バーチャル通貨またはデジタル通貨などのCFD取引は取り扱っておりません。",
		"text4": "3. 弊社は投資助言や投資利益を確約することはいたしません。",
		"text5": "4. 弊社は弊社の名称を無断使用する個人および法人に対し、法的措置を講じます。",
		"text6": "5. 弊社は弊社の名称を用いた詐欺の被害に遭われた方に法的支援をいたします。また弊社の営業担当やカスタマーサポートを名乗る不審なメッセージやコンタクトにはご注意いただき、万が一そのようなメッセージを受診された場合は直ちに<a href=\"mailto:cs@ebc.com\" style=\"color: #4D0101;\">cs@ebc.com</a>までご連絡ください。",
	},
	"official-page3": {
		"text1": "当社の公式サイトです。ご安心ください。",
		"text2": "最初に戻る",
		"text3": "当社の公式サイトではありません。入金・取引等はご遠慮ください。",
		"text4": "通報",
		"text5": "この情報が虚偽または悪用されていると思われる場合は、当社に報告を提出してください。",
		"text6": "レポート情報",
		"text7": "URL",
		"text8": "**法的支援をご希望の方は、",
		"text8-1": "こちら",
		"text8-2": "をクリックしてメッセージをお送りください。",
		"text9": "メッセージを閉じる",
		"text10": "氏名 / ニックネーム",
		"text11": "姓/ニックネームを入力してください",
		"text12": "メールアドレス",
		"text13": "メールアドレスを入力してください",
		"text14": "電話番号",
		"text15": "電話番号を入力してください",
		"text16": "通報を送信",
		"text17": "情報フィードバックをご記入ください",
		"text18": "認証コード",
		"text19": "入力してください",
		"text20": "キャンセル",
		"text21": "通報を送信",
		"text22": "メッセージは送信されました。ありがとうございました。",
	},
}