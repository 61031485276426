export default {
    "box-class-name": "cn-no1-box",
    "lang": "ct",
    "挑战最强交易环境": "挑戰最強交易環境",
    "利润差额奖金": "利潤差額獎金",
    "EBC的最强交易环境等您挑战": "EBC最強的交易環境等您挑戰！挑戰成功即可獲得150%利潤差額獎金，沒有上限",
    "距挑战结束剩余": "距離挑戰結束剩餘",
    "天": "天",
    "时": "時",
    "分": "分",
    "秒": "秒",
    "本次活动时间为2024年1月1日": "本次活動時間為: 1/1/2024 00:00:00 - 30/9/2024 23:59:59（GMT+2）",
    "发起挑战": "發起挑戰",
    "挑战规则": "挑戰規則",
    "EBC致力于为投资者提供极低交易成本": "EBC致力於為投資者提供極低交易成本、優質市場深度和高效訂單執行。為檢驗及完善我們的交易環境，特推出挑戰最強交易環境活動。",
    "开设账户": "開設帳戶",
    "在EBC平台开设一个交易账户": "在EBC平台開設交易帳戶",
    "选择平台": "選擇平台",
    "选择一家具备FCA或ASIC监管资质的交易商平台": "選擇具備FCA或ASIC監管資格的交易商平台",
    "相同策略交易": "相同策略交易",
    "使用相同的交易策略在EBC和所选平台进行交易": "使用相同的交易策略在EBC和所選平台進行交易",
    "收益比较": "收益比較",
    "在EBC的盈利低于或亏损高于所选择交易商": "在EBC的獲利低於或虧損高於所選交易商，即為挑戰成功",
    "卓越的交易环境": "卓越的交易環境",
    "EBC以一流的交易执行速度和先进的技术支持": "EBC以一流的交易執行速度和先進的技術支持，打造卓越的交易體驗，滿足投資者的高標準需求。",
    "科技赋能交易": "科技賦能交易",
    "部署于伦敦LD4": "部署於倫敦LD4、紐約NY4、新加坡SG1、東京TY3、香港HK2的超高速專線連接的獨立伺服器，提供超低延遲聚合、智慧訂單路由和報價優化引擎，建構全球超高速金融生態體。",
    "让85%以上的订单成交于更优的价格": "讓85%以上的訂單成交於更優的價格",
    "EBC交易黑盒": "EBC交易黑盒，精準優化拋單演算法，在價格優化率、訂單成交率以及演算法穩定性方面， EBC交易黑盒遠優於傳統交易系統。",
    "EBC交易空间": "EBC交易空間",
    "无论您是高频交易者": "無論您是高頻交易者，還是大單交易家，EBC 的Private Room 都將在流動性匹配基礎上，搭建您的專屬的交易通道，甚至隱藏您的部分交易資訊，保護您的「底牌」，這意味著您的訂單不僅能獲得更好的聚合報價，也可以在成交過程中免於狙擊。",
    "国际顶级流动性": "國際頂尖流動性",
    "EBC与多家国际顶级银行建立了长期稳定的合作关系": "EBC與多家國際頂尖銀行建立了長期穩定的合作關係，整合多家優質流動性報價，提供極具競爭力的市場點差。",
    "活动须知": "活動須知",
    "参与账户": "參與帳戶",
    "新用户": "新用戶：在官網新開帳戶後，聯絡線上客服開通VIP帳戶參與挑戰。",
    "现有用户": "現有用戶：聯絡線上客服，開通VIP帳戶後參加挑戰。",
    "对比交易商平台要求": "交易商平台要求",
    "选择持有英国FCA或澳洲ASIC监管的交易商平台": "選擇持有英國FCA或澳洲ASIC監管的交易商平台。不包括AR、EEA註冊或Trading Name帳號。",
    "交易策略": "交易策略",
    "仅限以下主流货币对": "僅限以下主流貨幣對：AUD/USD、USD/CAD、EUR/USD、USD/CHF、GBP/USD、USD/JPY、NZD/USD。",
    "在EBC和所选交易商使用相同的交易资金和交易策略": "在EBC和所選交易商使用相同的交易資金和交易策略，所有策略需為正常交易範疇。",
    "奖金结算": "獎金結算",
    "挑战者在其他平台的交易返佣可计入盈利": "挑戰者在其他平台的交易返傭可計入盈利，但需提供相應證明資訊。",
    "挑战者可在任意挑战时间内选择结算": "挑戰者可在任意挑戰時間內選擇結算，每個身分證僅可參與一次。",
    "挑战结束后": "挑戰結束後，提交平倉的交割單。獎勵將在審核後的兩個工作天內發放至交易帳戶，可用於交易或提領。",
    "重要声明": "重要聲明",
    "参赛者应知晓交易风险": "參賽者應得知交易風險、規則、權益及平台交易產品的交易規則，因不可抗力造成的交易問題及帳戶虧損，EBC不予處理。",
    "任何不遵守比赛规则或篡改数据的行为": "任何不遵守比賽規則或篡改數據的行為，EBC有權取消參賽資格，並保留追究法律責任的權利。",
    "EBC有权要求参赛者提供有争议的订单或账户的补充材料": "EBC有權要求參賽者提供有爭議的訂單或帳戶的補充資料。",
    "本次挑战赛旨在提供公平": "本次挑戰賽旨在提供公平、公正的環境，EBC保留修訂、豁免或解釋比賽規則的權利。",
    "EBC有权根据法律法规": "EBC有權依據法律法規、司法或行政命令取消或終止活動。",
    "本活动最终解释权归EBC所有":"本活動最終解釋權歸EBC所有。"

}