export default {
  "screen0": {
    "title": "法的文書",
    "tit": "法的文書",
    "list": [
      "メールでのお問い合わせ",
      "チャットでのお問い合わせ"
    ]
  },
  "screen1": {
    "list": [{
      "title": "1. ウェブサイト利用規約",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Website-Terms-and-Conditions.pdf"
    }, {
      "title": "2. リスク開示書",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Risk-Acknowledgement-And-Disclosure-Notice.pdf"
    }, {
      "title": "3. 個人情報保護方針",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Privacy-Policy.pdf"
    }, {
      "title": "4. 注文執行方針",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Execution-Policy.pdf"
    }],
    "tit": "何をお探しですか？",
    "name": "サイト内検索",
    "placeholder": "サイト内検索",
    "btn": "検索",
    "text": "専用サービスによるお問い合わせ",
    "btn1": "メールやチャットで問い合わせる"
  }
}
