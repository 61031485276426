export default{
  "screen0": {
    "title": "Pure Trading Pure Power",
    "subTitle": "交易之路很難走,也許就差最後1毫秒的推進無與倫比的市場准入水准 <br style='display:block!important;'>超低延遲的IT基礎架構"
  },
  "screen1": {
    "line1": "交易執行速度低至",
    "line2": "資料傳輸穩定性高達",
    "line3": "金融專線光纖頻寬不低於",
    "line4": "起步,與世界同步"
  },
  "screen2": {
    "title": "科技賦能交易",
    "desc": "EBC專有服務器設立於Equinix LD4、HK3、TY3、NY4(待設),構建覆蓋全球的金融生態系統",
    "title1": "科技賦能交易,EBC<br>交易執行速度低至20毫秒",
    "desc1": "在軟件定義計算、貯存容量和網路基礎上,超融合基礎架構帶來高達98.75%的系統穩定可用性",
    "desc2": "<p>在當今的金融市場中,速度等於利潤,敏捷性對於交易獲利至關重要；</p><p>我們將交易服務器設於世界領先的資料中心Equinix LD4、HK3、TY3、NY4(待設),全球超高速專線連接最大程度地减少了區域間延遲,客戶可以享受到穩定的交易；</p><p>由於防火牆帶來的限制以及擁擠的國際頻寬,造成了低質量的交易體驗。我們配有近30個DC(含災備服務器),歐亞服務器之間全部實現對等網絡金融專線連接(Peer-to-peernetworking)；</p><p>相對於傳統服務器的歐亞線路,EBC從倫敦到亞洲的延遲控制在100ms以內,而傳統經紀商從倫敦到亞洲的傳輸時間在270ms以上,這無疑最有益於亞洲的客戶。</p>"
  },
  "screen3": {
    "title": "我們深知：成功,<br>關鍵不限於軟體技術,而是整體",
    "desc": "EBC確保提供交易市場中最好的流動性,您在獲得多資產流動性的同時將擁有更大的靈活性",
    "plant": "想更瞭解交易平臺？",
    "btn": "Lab學習中心/交易平臺板塊"
  },
  "screen4": {
    "enTitle": "Driving You to Succeed",
    "title": "專業領先的金融科技,讓交易無限可能"
  },
  "screen5": {
    "plant": "瞭解交易產品",
    "btn": "Lab學習中心/交易產品板塊",
    "title": "最小化風險 最大化交易",
    "desc": "EBC的超低延遲聚合、智慧訂單路由和報價優化引擎,為您提供更高的可靠性和最佳的訂單執行",
    "list": [
      "交易服務器",
      "聚合報價",
      "訂單傳輸",
      "上游流動性資金池"
    ],
    "link1": "展示交易時段",
    "link2": "查看點差與全部商品"
  },
  "screen6": {
    "title": "專注於交易的您,不必擔心IT基礎架構,作業系統,網絡和所有硬體方面的問題。因為您所在乎的,我們都已考慮在內——最佳流動性獲取和高性能訂單執行",
    "desc": "從前5檔深度的訂單執行完成情况看,EBC僅需75-125ms就完成了該交易"
  },
  "screen7": {
    "pageTitle": "價格的發現和訂單的傳輸",
    "pageEnTitle": "Discover Prices & Transfer Orders",
    "title": "EBC通過fix(金融資訊交換協定)將接入的銀行間報價進行聚合,為客戶提供一級流動性場所的直接市場准入,通過專用物理HUB硬體聚合後的報價具有更低的點差和永續交易的深度",
    "desc": "fix聚合報價的覈心包括超低延遲的定單發送和價格發現引擎",
    "imgUrl": "ct"
  },
  "screen8": {
    "title": "通過EBC bridge聚合後的<br>產品點差錶,深度錶",
    "desc": "快速瞭解",
    "link1": "4種資產類別",
    "link2": "100+交易品種",
    "link3": "全部產品",
    "trList": [
      "層級",
      "買入價",
      "買入的交易量",
      "點差",
      "賣出價",
      "賣出價交易量"
    ],
    "smallTrList": [
      "層級",
      "買入價",
      "點差",
      "賣出價"
    ]
  },
  "screen9": {
    "title": "交易黑盒",
    "smallTitle": "精准優化拋單算灋,讓85%以上的訂單成交於更優的價格",
    "desc": "EBC交易黑盒,基於數千萬次的歷史交易數據、交易者的交易習慣、用戶内容等畫像、當前的市場行情和交易網路環境等,使用大數據統計分析、概率模型、神經網路模型、系統控制理論模型和人工智慧算灋等進行綜合研判,最終匹配更適合每位交易者内容的LP,達成最優的成交價格",
    "link1": "展示交易時段",
    "link2": "查看點差與全部商品",
    "imgUrl": "ct"
  },
  "screen10": {
    "desc": "我們對EBC交易黑盒與傳統交易系統進行對比實驗(其中至少包含100個訂單),可得如下分佈圖：",
    "list": [
      "每個數據點代表一天的交易",
      "橫軸表示訂單的成交率",
      "點的大小代表訂單量的相對大小",
      "縱軸表示交易價格的優化率"
    ],
    "btn": "開始投資",
    "title": "經過實驗,我們可以發現：在價格優化率、訂單成交率以及算灋穩定性方面,EBC交易黑盒遠遠優於傳統交易系統"
  }
}
