export default {
	"box-class-name": "oxford-box-en",
	"button-text": "Find out more",
	"oxford-page1": {
		"text1": "WHAT<br> ECONOMISTS<br> REALLY<br> DO",
		"text2": "The Economics of Tax Evasion",
		"text3": "Sarah Clifford & David Barrett",
		"text4": "Reserve Now",
		"text5": "6 MARCH 2024 12PM GMT",
	},
	"oxford-page2": {
		"text1": "A Thriving Economy Through Education",
		"text2": "EBC Financial Group Supports Oxford’s Department of Economics CSR Initiatives Spotlighted through the upcoming webinar “What Economists Really Do”",
		"text3": "In our ongoing commitment to corporate social responsibility (CSR), EBC Financial Group proudly supports initiatives that drive societal progress. Whether it’s supporting education, fostering talent development or promoting environmental sustainability, our commitment to CSR guides us to make a meaningful difference. We believe in leveraging our resources and expertise to create positive change and contribute to a better future for all.",
		"text4": "Our latest collaboration exemplifies this commitment. We’re proud to announce our partnership with the University of Oxford’s Department of Economics, an institution renowned for its academic excellence and dedication to societal advancement. Through this collaboration, we’re harnessing the power of education to make a lasting impact. Together, we encourage individuals to innovate and drive positive change in communities around the world. Join us on this journey as we aim to create a brighter, more equitable future for generations to come.",
		"text5": "EVENT INTRODUCTION",
		"text6": "Set to take place on 6 March 2024 at 12pm GMT, the webinar on “What Economists Really Do” will delve into the topic of “The Economics of Tax Evasion.” The discussion sheds light on issues the financial industry faces today and how economics and public education can be used to improve policies and facilitate a thriving economy.",
	},
	"oxford-page3": {
		"text1": "RELATED PEOPLE",
		"text2": "Associate Professor of Economics, Department of Economics, the University of Oxford",
		"text3": "Sarah specializes in public economics with a focus on economic behaviour related to taxation, particularly enforcement. Her research encompasses international corporate taxation, multinational tax avoidance, and personal income tax evasion by households. Her areas of expertise include public economics and applied microeconomics.",
		"text4": "Professor of Economics，Nuffield College，Associate Head (External Engagement)",
		"text5": "In Abi’s research on Applied Economics, she often focused on the econmetrics of consumer and family choices. Her research main themes: the development of empirical methods to bring new modes to decision-making to data, expoit large-scale datasets to better understand labour markets, and developing frameworks for quantifying access to justice in the UK legal system.",
		"text6": "CEO, EBC Financial Group (UK) Ltd.",
		"text7": "Mr. Barrett has over 35 years of expertise in foreign exchange, fixed income, and derivatives, gained from Tier One Institutions to smaller business ventures. With a comprehensive understanding of economic cycles and regulatory environments, he offers invaluable insights into risk management and business strategy, having navigated through global financial crises and regulatory changes.",
	},
	"oxford-page4": {
		"text1": "About EBC Financial Group",
		"text2": "Founded in London, EBC Financial Group offers innovative trading services in forex, commodities and indices with integrity and a client-centric focus. Recognised with multiple awards, our dedicated services drive client’s growth through advanced infrastructure, proprietary tools and liquidity access swift order execution and 24/7 customer support.<br><br>With an extensive international footprint, we navigate local markets keenly whilst upholding integrity and ethical practices. For those seeking a dedicated partner with a client-first philosophy, EBC Financial Group emerges as a clear choice in the dynamic world of finance.<br><br>Exceptional Brilliance Care for Every Committed Trader.",
		"text3": "About Department of Economics, the University of Oxford",
		"text4": "The University of Oxford’s Department of Economics is globally renowned for its impactful research, influential policy contributions, vibrant early-career community, and esteemed undergraduate and graduate programmes, making it one of the largest and most diverse academic economist communities.",
	},
	
}