export default {
	"faq-class-name": "en-faq-new",
	"faq-title":"FAQ",
	"faq-desc": "Search common questions or contact customer service directly;<br> The EBC Customer Support Team is always at your service to answer your inquiries.",
	"faq-search": "Search",
	"faq-tabs":[
		"Registration and account opening",
		"Deposit type",
		"Transaction",
		"Trading Terminal",
		"Knowledge",
	],
	"faq-data":{
		0: [
			{
				"Q": "How to start trading on EBC?",
				"A": "Complete the following steps to start trading on EBC: \n1. Register for an EBC account \n2. Verify personal information \n3. Open a trading account and make a deposit \n4. Log in to the MT4 trading terminal to trade"
			},
			{
				"Q": "Is it possible to register multiple EBC Accounts using the same email address?",
				"A": "Unfortunately, an email address can only be registered to one EBC account. However, one EBC account can create multiple trading accounts."
			},
			{
				"Q": "What is the purpose of completing KYC verification?",
				"A": "As a compliant and regulated company, we are required to conduct KYC verification in accordance with the regulations set by competent regulatory authorities. This entails collecting your KYC verification information, such as a valid National ID and proof of address issued within the last six months."
			},
			{
				"Q": "What documents are required for the verification of EBC accounts?",
				"A": "You are required to provide us with a valid ID, mobile number, and email address to complete the KYC verification."
			},
			{
				"Q": "How do I resubmit my verification documentation?",
				"A": "Here's the process to resubmit your KYC document:\n\n1. Log in to your client portal and go to My Account.\n2. Under Personal Information, fill out your information again as requested.\n3. Click on the Delete button next to the previously submitted document, then reupload it.\n4. Once done, sit back and relax as your KYC document will be checked soon."
			},
			{
				"Q": "How can I update my personal information in my EBC account?",
				"A": "Please note that modifying your registered name is not allowed. However, you can still update your registered mobile number, email address and banking information. "
			},
			{
				"Q": "Are my funds and personal data safe?",
				"A": "The security of your funds is of utmost importance to us. We are closely supervised by multiple top regulatory agencies worldwide and strictly adhere to relevant laws and regulations. Client funds are stored separately in individual accounts at reputable overseas banks, ensuring double protection for both your funds and investment income. Additionally, we are dedicated to implementing all necessary security measures to safeguard your personal information."
			},
			{
				"Q": "What account types are available?",
				"A": "We offers 2 account types: standard account and professional account. <a href=\"/TradingAccount\">Click here to view the account comparison table</a>."
			},
			{
				"Q": "How to find my trading account number?",
				"A": "To locate the trading account number, follow the below steps: \n1. Log in to your EBC account\n2. Click \"Home\" - \"Real Account\" \n3. Under Real Account, you will be seeing your Trading Account Number"
			},
			{
				"Q": "Is there any difference between a live account and a demo trading account? ",
				"A": "The trading processes, trading products and K-line trends of the two are consistent. The operation of live accounts requires actual financial risks. Demo accounts help users learn and understand market dynamics without involving real funds."
			},
			{
				"Q": "Where are the EBC offices located?",
				"A": "We have offices in major financial hubs like London, Tokyo, Sydney, Singapore, and Hong Kong. Please refer to our website for further information if you require it.\n"
			},
			{
				"Q": "Is there an age limit for opening an account?",
				"A": "To open an account, you must be at least 18 years old and not older than 65, except for clients residing in Japan, where the maximum age limit is 70 years old"
			},
			{
				"Q": "From which countries does EBC not accept clients?",
				"A": "\nKindly note that our products and services are not available to clients residing in certain regions, including the United States, Canada, Malaysia, and others. The list of restricted countries is subject to periodic updates. For further details, please reach out to our client support team via LiveChat."
			},
			{
				"Q": "How to delete my account? ",
				"A": "We are sorry to hear that you wish to delete your account. Please make your request to cs@ebc.com using your registered email address so that we can better assist you. After receiving your request, the customer support division will handle it."
			},
			{
				"Q": "How can I update my trading account leverage? ",
				"A": "Certainly, to update your leverage, please follow the below steps: \n\n1. Log in to your client portal and click Home\n2. Under My Account - tap on \"Settings\" on the right side of the account - \"Modify Leverage\" \n3. Select the appropriate leverage and click \"Yes\" to update your leverage"
			},
			{
				"Q": "What can I do by contacting customer service? What are the working hours?",
				"A": "If you have any queries about registration, platform downloads, or other relevant topics, our client support team is here to help you around-the-clock. You can email us at cs@ebc.com or use our online client service to get in touch with us."
			},
			{
				"Q": "What is the procedure for lodging a complaint if I need to do so?",
				"A": "We are sorry for any inconvenience we may have have experienced. Ensuring your satisfaction is our top priority, and we're committed to resolving any issues you may have experienced. If you have any complaints, please don't hesitate to email us at cs@ebc.com."
			}
		],
		1: [
			{
				"Q": "Anything I need to know before making deposit?",
				"A": "\nEBC offers 24/7 deposit methods. \n\nIn order to successfully make a deposit you need to complete the payment transaction within the specified timeframe, or the deposit order will be automatically cancelled. Please ensure you have checked the relevant payment method details before you deposit funds into your account. \n\nDifferent deposit methods may have varying deposit limits, so it's advisable to check the relevant details before depositing funds."
			},
			{
				"Q": "Anything I need to know before submitting a withdrawal request? ",
				"A": "We provide several withdrawal methods, including local bank transfer, cryptocurrency, and international bank wire transfers. Please note that the minimum withdrawal amounts are USD 100 for local bank transfers and international bank wire transfers, and USD 50 for cryptocurrency withdrawals."
			},
			{
				"Q": "How to deposit? ",
				"A": "To make a deposit, please follow the below steps: \n\n1. Login to your client portal and tap on Fund\n2. Look for Deposit that is located at the right side of the menu\n3. Select your preferred deposit method and select which trading account you wish to receive the funds\n4. Enter the amount and then click on Confirm Payment\n5. You will be then redirected to the vendor page and please follow the instructions to complete your deposit. "
			},
			{
				"Q": "Can I deposit/withdraw funds using someone else's bank card?",
				"A": "EBC does not accept third party payments. All deposits must come from an account in your name. (All deposits must come from your own bank account.) If we suspect that the funds come from a third party, we reserve the right to return the funds to the remitter and temporarily freeze your account balance until verification of identity and source of funds is completed. In the absence of verification, we also reserve the right to freeze your account balance, and you will not be able to withdraw your account balance at this time. EBC does not handle withdrawals to third parties. In case of special circumstances, please contact us."
			},
			{
				"Q": "What is the deposit limit?",
				"A": "Different payment methods have different deposit limits, please refer to the deposit page for more information"
			},
			{
				"Q": "Does EBC charge any deposit or withdrawal fees?",
				"A": "EBC does not charge any handling fees for deposits and withdrawals. Please note that some cryptocurrency service providers may charge certain handling fees."
			},
			{
				"Q": "Can I deposit and withdraw money outside of working hours?",
				"A": "You may submit your deposit and withdrawal request as usual even on weekends and holidays. However, please note that due to non-working days on weekends, the processing of your request may be delayed and will be processed on the next working day (Monday) at the latest."
			},
			{
				"Q": "How long does it take to process deposits and withdrawals?",
				"A": "Under normal circumstances, the deposit processing time on working days is approximately 1 to 15 minutes. Withdrawal applications submitted before 16:00 UTC+8 on working days can be credited to the account as soon as the same day."
			},
			{
				"Q": "Can I deposit and withdraw funds through multiple bank cards?",
				"A": "Yes, you may use any bank card that is under your own name. "
			},
			{
				"Q": "Can I withdraw funds from a trading account that still has open orders?",
				"A": "Yes, however please note that there must be sufficient funds in the account to avoid liquidation of open orders due to withdrawal operations. If the risk control department determines that the withdrawal operation may lead to account liquidation, the withdrawal application will be refused. The withdrawal operation can only be carried out after the liquidation operation is completed."
			},
			{
				"Q": "Can I use a different payment method than the deposit when withdrawing money?",
				"A": "You can withdraw your initial deposit using the same method as your deposit. However, you have the flexibility to withdraw your profits using your preferred withdrawal method."
			},
			{
				"Q": "Why did the withdrawal/deposit fail?",
				"A": "Deposit/withdrawal failure may be caused by the following reasons: \n\n1. You are depositing using a bank card that doesn't belong to your name\n2. The bank card you used is inactive or not recognised \n3. When making a transfer, the bank information or withdrawal account information is filled in incorrectly. If you encounter the above situation, please contact our client support via LiveChat or send an email to cs@ebc.com for assistance. "
			},
			{
				"Q": "Can I transfer funds between my trading accounts?",
				"A": "Yes. You may submit a fund transfer request between your trading accounts."
			},
			{
				"Q": "Why is it that after submitting one withdrawal request, I am unable to submit another?",
				"A": "Before submitting the second withdrawal, you must wait for the first to be approved for review."
			},
			{
				"Q": "Why, after after cancelling my withdrawal, are the funds not immediately returned to my trading account?",
				"A": "After canceling the withdrawal, the funds will be automatically be returned to your main wallet, and then the funds can be transferred from the main wallet to the trading account."
			}
		],
		2: [
			{
				"Q": "What products can I trade on EBC?",
				"A": "EBC offers a wide range of products and you only need one trading account to create a diversified portfolio. <a href=\"/tradingproduct\">Click here to view the complete product list</a>."
			},
			{
				"Q": "What are the maximum and minimum trading volumes for each account type?",
				"A": "The maximum trading volume for STD accounts and PRO accounts is 40 lots, and the minimum trading volume varies depending on the trading variety. If you would like to know more details please <a href=\"/TradingAccount\">Click here to view account rules</a>."
			},
			{
				"Q": "What is Market Execution and what is a Pending Order?",
				"A": "Market Execution means that the order is executed immediately, and the investor buys or sells according to the best price available in the current market; a pending order transaction is a scheduled execution, where the investor determines the expected buying and selling price. When the market price reaches or exceeds the expected price, the trading system will automatically execute the transaction."
			},
			{
				"Q": "What is buy limit, buy stop, sell limit, sell stop?",
				"A": "Buy Limit: Buy when the price falls to a predetermined lower level, suitable for low-price buying strategies. For example, if the price of gold is expected to fall from 2000 to 1980 and then rise again, you can set a Buy Limit of 1980 and wait to buy at a lower price. \nSell Limit: Sell when the price rises to a predetermined higher level, suitable for high-price selling strategies. For example, if the price of gold is expected to rise from 2000 to 2020 and then fall again, you can set a Sell Limit for 2020 and wait to sell at a high price.\nBuy Stop: Buy when the price rises to a predetermined higher level, suitable for a chasing strategy. For example, if you believe that the upward trend can only be confirmed when the price of gold exceeds 2020, you can set a Buy Stop for 2020 and wait for an upward breakthrough before buying. \nSell Stop: Sell when the price falls to a predetermined lower level, suitable for a chasing strategy. For example, if you believe that the downtrend can only be confirmed if the gold price falls below 1980, you can set a Sell Stop of 1980 and wait for the price to fall below 1980 before selling."
			},
			{
				"Q": "What is the Stop Out level?",
				"A": "The stop out level is 30%"
			},
			{
				"Q": "How to set the 'take profit' and 'stop loss' on my orders?",
				"A": "MT4: PC, the steps to set stop loss and take profit are as follows: \n1. Select the trading order you want to manage. \n2. Right-click the order and select \"Modify or Delete Order\". \n3. Set the \"Stop Loss Price\" and \"Profit Price\" in the window that appears. \n\nMT4: Mobile, the steps to set Stop Loss and Take Profit are as follows: \n\n1. Find the position order you want to modify on the \"Trading\" page \n2 .Click or long-press the order and select \"Modify\" in the pop-up menu \n3. Set the \"Stop Loss Price\" and \"Profit Price\" in the window that appears"
			},
			{
				"Q": "What is hedging?",
				"A": "Hedging is an opening order of the same variety, same lot size, but in the opposite direction."
			},
			{
				"Q": "Does EBC provide trading signals?",
				"A": "While we don't offer trading signals directly, we provide access to our trading community. Within this community, you can choose from a diverse selection of trading signals provided by other community users."
			},
			{
				"Q": "Does EBC support EA (Expert Advisor)? ",
				"A": "Yes. You may use any EA that you prefer."
			}
		],
		3: [
			{
				"Q": "Which trading terminal can I use?",
				"A": "Currently EBC only supports the use of MT4 trading terminal, <a href=\"/MT4\">Click here to download now</a>."
			},
			{
				"Q": "Which server should I use for the MT4 platform?",
				"A": "For EBC live accounts we use the server EBCFinancialGroupKY-Live, and for the demo accounts we use the server EBCFinancialGroupKY-Demo"
			},
			{
				"Q": "Why cant i log in to the trading terminal?",
				"A": "Login failures into the trading platform may be caused by the user incorrectly entering their password or it could be related to a network connection failure. When encountering login issues, it is recommended to re-enter your password or check your network connection first."
			},
			{
				"Q": "Unable to log in to MT4, why does an \"invalid account\" error appear?",
				"A": "When the \"Invalid Account\" prompt appears, it may be because you entered incorrect server information or the password is incorrect. This problem can be solved by selecting the correct server and entering the password correctly."
			},
			{
				"Q": "Why does it display a \"No connection\" prompt?",
				"A": "If you are encountering 'No Connection' error It could be because the server is disconnected or there is a problem with the local network connection. \n\nFirst confirm whether the local network can browse the web normally. If the network is normal, try switching to a different data center (DC) server and reconnecting."
			},
			{
				"Q": "Why is the \"New Order\" button greyed out?",
				"A": "If you are unable to create new orders it may be due to the following reasons: \n\n1. The account is not successfully connected or has been disconnected. \n2. Use the read-only password to log in. This type of password only allows you to view the account and cannot perform transaction operations. \n3. STD accounts can only trade symbols with the \".s\" suffix, while varieties in the grey area cannot be traded."
			},
			{
				"Q": "How to add or remove symbols?",
				"A": "To Add or Hide a certain symbol in MT4 via  \nDesktop Terminal\n1. Log in to the trading terminal and right-click anywhere. \n2. Select \"Trading Type\". \n3. Select the Trading symbol group. \n4. Double-click to add to  \"Market Quote\". \n5. To hide, Select \"Hide\". \n\nMobile Terminal\n1. Log in and click \"+\" in the upper right corner. \n2. Select a Trading symbol group. \n3. Click \"+\" to add. To remove varieties on the mobile terminal: \n1. Click the pen-shaped button in the upper left corner. \n2. Check the box to remove the category and click Delete."
			},
			{
				"Q": "How to set up bid line?",
				"A": "Desktop \n1. Right-click anywhere on the chart. \n2. Select \"Properties\" to enter display settings. \n3. Check the \"Buy Price Line\" option. \n\niOS: \n1. Open the chart. \n2. Click anywhere and select \"Settings\". \n3. Check the \"Buy Price Line\" option. \n\nAndroid: \n1. Enter the settings menu. \n2. Select \"Chart\". \n3. Check the Bid Line option to enable it."
			},
			{
				"Q": "How can I update timezone in MT4? ",
				"A": "Unfortunately that there is no option to update the timezone in MT4 Terminal"
			},
			{
				"Q": "Why is my pending order not executed?",
				"A": "The following reasons could be preventing the pending order from being executed:\n\n1. The available free margin in the account, is insufficient to open a new position.\n2. The price of the pending order is incorrect and does not meet the execution standards of the pending order.\n3. When major financial events are released, the market may fluctuate, and the actual price of the order may be different from the pending order price. \n\nPlease note that the failure of pending orders to be executed is not limited to the above reasons and must be determined based on specific circumstances at the time."
			},
			{
				"Q": "What's the best way to verify the trading hours for all symbols?",
				"A": "Log in to the MT4 trading terminal, right-click on the trading symbol you want to view, and click \"Specifications\" to view it"
			},
			{
				"Q": "Why does it prompt \"Invalid SL/TP\" error?",
				"A": "The \"Invalid SL/TP\" error may be due to the following reasons: \n1. The current price has exceeded the set take-profit and stop-loss points, and the transaction cannot be completed. \n2. The order price of the STD account must be ≥ 50 points from the latest price."
			},
			{
				"Q": "Why does the \"Market Closed\" prompt appear?",
				"A": "When the market is closed, \"Market Closed\" will be displayed."
			},
			{
				"Q": "Can I partially close my position (reduce the number of open positions)?",
				"A": "Your position can be partially closed. You may choose to partially close a position if it currently exceeds the minimum trading lot requirement for the commodity."
			},
			{
				"Q": "Can I still trade after the market closes?",
				"A": "You may no longer trade when the market is closed. You can only trade (open, close, execute and modify orders) when the market is open."
			},
			{
				"Q": "If my MT4 goes offline, do I need to close all positions I opened?",
				"A": "If you close the MT4 trading system, take profit, stop loss and pending orders will still remain. But when you close the MT4 trading system, your trailing stop loss and EA trading system will become invalid."
			},
			{
				"Q": "How to view my transaction history?",
				"A": "1. Log in to the MT4 trading terminal \n2. Click on the navigation \"Terminal\" - \"Account History\" \n3. View the closed transaction history"
			},
			{
				"Q": "How do I export transaction history?",
				"A": "1. Log in to the MT4 trading terminal and click \"Account History\" \n2. Right-click the mouse and select \"Save as Detailed Account Statement\""
			},
			{
				"Q": "How to view the capital curve in MT4?",
				"A": "1. Open the account history in MT4 \n2. Right-click the mouse and select \"Save as Detailed Account Statement\" \n3. Click the saved graph again to see the capital curve"
			},
			{
				"Q": "What is PAMM and how to use it?",
				"A": "PAMM stands for Percent Allocation Management Model, which is a form of collective trading funds for management. Investors can choose a qualified trader or fund manager to use their own funds and pooled funds to conduct transactions to obtain profits, and distribute the profits according to the agreed ratio."
			}
		],
		4: [
			{
				"Q": "What is derivatives trading?",
				"A": "The derivatives trading market is currently the world's largest financial trading market, with an average daily trading volume of approximately US$4 trillion. The exchange rates of various currencies in the world are floating and appear in the form of currency pairs when trading, such as Euro/USD (EUR/USD) and USD/JPY (USD/JPY). It represents the trading method of buying one currency and selling the other currency in a currency pair."
			},
			{
				"Q": "What is spread?",
				"A": "The spread is the difference between the buying price and selling price of a certain trading product, and it is also the transaction cost that needs to be paid for each transaction."
			},
			{
				"Q": "What is leverage?",
				"A": "Leverage means that investors can use a small proportion of funds to invest in foreign exchange targets of greater value without the need for full transactions. The level of leverage determines the amount of margin occupied.."
			},
			{
				"Q": "What is margin?",
				"A": "Margin, also referred to as an advance payment, enables traders to use a portion of their funds to establish positions based on the principle of leveraged investment. It serves as collateral to maintain a trading position. In the market, leverage typically ranges from 20 to 500 times, allowing investors to actively trade with high leverage. However, it's important to note that while the margin system can amplify profits, it also increases transaction risks.\n\nFor instance, let's consider Mr. Zhao who wants to execute a transaction worth US$100,000 today. With margin trading and assuming a leverage ratio of 500:1, Mr. Zhao only needs to have US$200 to conduct this transaction (100,000/500 = 200 US dollars). In other words, with capital magnified by 500 times, an investment of US$10,000 can facilitate a transaction of US$5 million."
			},
			{
				"Q": "How to calculate the margin required to open an order?",
				"A": "Margin = contract size * current price / leverage * number of lots.\n\nThis formula determines the margin needed to initiate an order. It considers factors such as the contract size (the position's size), the current price of the asset, the leverage provided by the broker, and the number of lots traded."
			},
			{
				"Q": "What is the relationship between balance, equity and available margin in a trade?",
				"A": "Equity = Balance – Occupied margin ± floating profit and loss.\n\nHere's what each term represents:\n\nBalance: This is the total amount of funds in the trading account.\nOccupied Margin: The margin that is currently tied up in open positions.\nFloating Profit and Loss: The unrealized profit or loss on open positions, which fluctuates with changes in the market.\n\nThe equity is essentially the net value of the trading account after accounting for the margin tied up in open positions and any floating profit or loss. It reflects the real-time value of the account and is a key factor in determining available margin for further trading activities."
			},
			{
				"Q": "Why does a negative balance occur?",
				"A": "A negative balance may occur during extreme market conditions when the market cannot provide a reasonable transaction price. This situation can lead to the final transaction loss exceeding the account balance and margin, resulting in a negative balance in the account."
			},
			{
				"Q": "What is the difference between margin (down payment) and available margin (available down payment)?",
				"A": "Margin refers to the funds held by a broker to maintain open orders. The remaining equity in the account is called available margin"
			},
			{
				"Q": "How market price slippage occurs?",
				"A": "In the market, if the available volume is insufficient to fulfill the order at the current price, the remaining portion will be executed at the next available price. This difference in price is referred to as slippage. Slippage can either be beneficial (positive slippage) or detrimental (negative slippage) for investors, depending on whether the executed price is more favorable or less favorable than anticipated."
			},
			{
				"Q": "What impact does leverage have on Stop Out?",
				"A": " Leverage can amplify investment returns but also raise risks. Higher leverage means less margin is needed, increasing fund utilization and allowing for larger tradable positions. However, during periods of high market volatility with insufficient margin, a Stop Out may occur."
			},
			{
				"Q": "What is overnight interest?",
				"A": "Overnight interest, also known as swap, refers to the interest earned or paid by traders when they hold a position overnight. This interest is calculated at 22:00 pm London time. The rate of overnight interest depends on the direction of the trader's position and the interest rate differential between the relevant currencies. For instance, if the interest rate for the pound is significantly higher than that of the Japanese yen, a trader holding GBP/JPY in a long position can earn interest, while a trader holding GBP/JPY in a short position will incur interest charges"
			},
			{
				"Q": "How to check overnight interest rate?",
				"A": "The method for querying swap of a contract is as follows: \n1. Log in to the MT4 trading terminal; \n2. Click on the main menu \"Display\" - \"Trading Product List\"; \n3. Right-click the trading product that you want to view the swap, and click \"Specifications\"; \n4. \"Sell/Buy Single Period Inventory Fee\" is the overnight interest. (ps: Positive overnight interest will be given to customers by traders)"
			},
			{
				"Q": "What are dividends and what impact do they have on trading?",
				"A": "In the Contract for Difference (CFD) market, dividends refer to interest payments made to investors holding long CFD positions. Conversely, investors holding short positions are typically required to pay corresponding fees."
			},
			{
				"Q": "What are the trading hours for spot trading of gold and silver?",
				"A": "Our spot gold and silver trading hours are from Sunday 23:00 to Friday 22:00 London time. Please note that the market will be briefly closed from 22:00 to 23:00 every day"
			},
			{
				"Q": "How many ounces are gold and silver spot contracts equal to?",
				"A": "Gold spot contract: 1 lot = 100 Ounces. \nSilver spot contract: 1 lot  = 5,000 Ounces."
			},
			{
				"Q": "How to calculate the margin (advance payment) required for gold and silver spot transactions?",
				"A": "If the current price of gold is 2,000 US dollars/ounce, the leverage is 500 times, and the transaction is 0.1 lots, then the margin required for gold = 2000*100/500*0.1=40USD. \n\nIf the current price of silver is 23 US dollars/ounce, the leverage is 500 times, and the transaction is 0.1 lots. , then the required margin for gold = 23*5000/500*0.1=23USD"
			},
			{
				"Q": "Do gold and silver spot contracts have validity periods?",
				"A": "No. As long as you maintain sufficient margin in your account, your position will remain valid until you close it."
			},
			{
				"Q": "What is the difference between Brent Crude Oil (XBRUSD) and West Texas Crude Oil (XTIUSD)?",
				"A": "Crude oil can be divided into light crude oil and heavy crude oil based on API gravity, and can also be divided into sulfur-containing crude oil and sweet crude oil based on sulfur content. Brent crude oil (XBRUSD) is a light, sweet crude oil produced from several oil regions in the North Atlantic. West Texas Intermediate (XTIUSD), which is relatively lighter and has lower sulfur content, is often quoted at a discount to Brent."
			}
		]
	}
}