export default {
	"headlist": [{
		"text": "Home",
		"url": "/"
	}, {
		"text": "Trading",
		"tips": "",
		"img": "11",
		"menu": [{
			"tit": "Account & Conditions",
			"list": [ {
				"text": "Trading Accounts",
				"url": "/TradingAccount"
			}, {
				"text": "Trading Products",
				"url": "/tradingproduct"
			}, {
				"text": "Leverage & Margin",
				"url": "/leverage-margin"
			},{
				"text": "Deposit & Withdrawals",
				"url": "/funding"
			}, {
				"text": "Dividend",
				"url": "/dividend"
			}]
		},  {
			"tit": "Trading Tools",
			"list": [{
				"text": "MT4 Download",
				"url": "/MT4"
			},{
				"text": "MT5 Download",
				"url": "/MT5"
			},{
				"text": "MT4 Trading Tools",
				"url": "/tools"
			}, {
				"text": "PAMM",
				"url": "/PAMM"
			},{
				"text": "Market Analysis",
				"url": "/market-analysis"
			}]
		}]
	}, {
		"text": "EBC Institute",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "Learning Center",
				"url": "/LearningCenter"
			}, {
				"text": "Data Center",
				"url": "/lab"
			},]
		}]
	}, {
		"text": "Activities",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "Trading Contest",
				"url": "/win"
			},
			// {
			// 	"text": "Best Trading",
			// 	"url": "/no1"
			// },
			{
				"text": "Creator Incentive Programme",
				"url": "/cfa"
			},{
				"text": "Salary Scheme",
				"url": "/pay"
			}]
		}]
	}, {
		"text": "About EBC",
		"tips": "",
		"img": "55",
		"menu": [ {
			"tit": "About EBC",
			"list": [{
				"text": "Why EBC",
				"url": "/about-us"
			},{
				"text": "Sustainability & Impact",
				"url": "/ESG"
			}, {
				"text": "FAQ",
				"url": "https://support.ebchelp.com/hc/en-001"
			}]
		},{
			"tit": "Partners",
			"list": [{
				"text": "Affiliate Programmes",
				"url": "/affiliate-programmes"
			},{
				"text": "Institutional Services",
				"url": "/service"
			},{
				"text": "Liquidity Solutions",
				"url": "/technology"
			},
			]
		}]
	}, {
		"text": "Copy Trading",
		"url": "https://ebcfinsns.co/"
	}],
	"FCA": "FCA Account ",
	"login": "Login",
	"zhuce": "Open An Account ",
	"from0tohero_text": "Copy Trading",
	"from0tohero_url": "/from0tohero",
	"faq_url": "https://support.ebchelp.com/hc/en-001",
}

