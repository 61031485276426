export default{
	"screen0": {
		"title": "Legal Document",
		"tit": "法律文件",
		"list": [
			"電郵聯繫",
			"線上專人"
		]
	},
	"screen1": {
		"list": [{
			"title": "1. Website Terms and Conditions條例和條款",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Website-Terms-and-Conditions.pdf"
		},{
			"title": "2. Risk Acknowledgement And Disclosure Notice風險提示通知",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Risk-Acknowledgement-And-Disclosure-Notice.pdf"
		},{
			"title": "3. Privacy Policy隱私條款",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Privacy-Policy.pdf"
		},{
			"title": "4. Execution Policy執行條款",
			"pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Execution-Policy.pdf"
		}],
		"tit": "在找什麼嗎？",
		"name": "您可以蒐索站內",
		"placeholder": "全站搜索",
		"btn": "蒐索",
		"text": "或是洽詢我們的專人服務",
		"btn1": "聯系專人服務"
	}
}
