export default {
	"malaria-class-name": "en-ubm-box",
	"malaria-page1": {
		"text1": "EBC Financial Group ร่วมกับ มูลนิธิสหประชาชาติเพื่อการยับยั้งโรคมาลาเรีย, โครงการของสหประชาชาติ เพื่อยับยั้งโรคมาลาเรียสำหรับอนาคตที่สดใสของทุกคน ",
		"text2": "",
	},
	"malaria-page2": {
		"text1": "มาลาเรียถือเป็นหนึ่งในภัยคุกคามที่เป็นอันตรายต่อสุขภาพของเด็กทั่วโลก",
		"text2": "ในปี 2022 มีผู้ป่วยโรคมาลาเรียประมาณ 249 ล้านรายและมีผู้เสียชีวิต 608,000 รายใน 85 ประเทศทั่วโลก ที่น่าตกใจกว่า คือ 76% ของการเสียชีวิตเหล่านี้อยู่ในกลุ่มเด็กอายุต่ำกว่า 5 ปี<br>ในขณะที่คุณอ่านข้อความนี้ จะมีเด็กหนึ่งคนเสียชีวิตจากโรคมาลาเรียทุกๆ นาที",
		"text3": "249<i>ล้าน</i>",
		"text4": "ติดเชื้อ",
		"text5": "608,000",
		"text6": "เสียชีวิต",
		"text7": "76%<i>+</i>",
		"text8": "เด็ก",
		"text9": "*แหล่งข้อมูล: องค์การอนามัยโลก \"รายงานโรคมาลาเรียโลกปี 2023\"",
	},
	"malaria-page3": {
		"text1": "มาร่วมกันช่วยยับยั้งมาลาเรีย",
		"text2": "โครงการ United to Beat Malaria ของสหประชาชาติ  เป็นโครงการระดับโลกที่รวบรวมผู้สนับสนุนทั่วโลกเพื่อต่อสู้กับโรคมาลาเรีย EBC ร่วมสร้างความรับผิดชอบต่อสังคมอย่างแข็งขัน ด้วยการผนึกกำลังกับโครงการ United to Beat Malaria เพื่อเผชิญหน้ากับปัญหาเร่งด่วนนี้",
		"text3": "60",
		"text4": "ประเทศที่ได้รับผลกระทบ",
		"text5": "40<i>ล้าน</i>",
		"text6": "ช่วยปกป้องประชาชน",
		"text7": "183,450",
		"text8": "การบริจาคมุ้งเคลือบยากันยุง",
	},
	"malaria-page4": {
		"text1": "ร่วมวิ่ง 5 กม. Virtual Run ต้านมาลาเรีย 2024 เพื่อร่วมประชาคมโลกต่อสู้กับมาลาเรีย",
		"text2": "ระหว่างวันที่ 25 เมษายน ถึง 5 พฤษภาคม 2024 เข้าร่วมงานวิ่งการกุศล Move Against Malaria Charity Run เพื่อสร้างความตระหนักรู้และระดมทุนสำหรับเครื่องมือและโปรแกรมในการรักษาโรคมาลาเรีย<br>EBC Financial Group และมูลนิธิสหประชาชาติขอเชิญชวนทุกท่านที่มีวิสัยทัศน์เดียวกันกับเรามาร่วมมือกันเพื่อสร้างโลกสุขภาพดีสำหรับทุกคน",
		"text3": "เข้าร่วมตอนนี้",
	},
	"malaria-page5": {
		"text1": "ความพยายามของเรา",
	},
}