export default {
	"cfa1": {
		"title": "每个认真交易的人，都值得被认真对待",
		"h1": "创作者激励计划",
		"h1wap": "EBC创作者激励计划",
		"text": "EBC鼓励原创，支持金融观点分享",
		"text1": "为推动专业内容创作惠及交易爱好者，特推出创作者激励计划",
		"text2": "创作者专享额外奖励，让专业不负热爱",
		"list": ["全球顶级监管", "百万美金交易大赛", "极具竞争力的交易环境", "订单流专业投资者工具", "深度专属流动性", "交易社区", "毫秒级订单处理", "提供媒体扶持", "限时免息"],
		"list1":["专业技术指导","实时行情解读","热门指标分析","优质课程学习"]
	},
	"cfa2":"诚邀您加入",
	"cfa3":{
		"title":"奖励等级",
		"tips":"奖励(RMB)",
		"tips1":"均值估算仅供参考",
		"reward_list": [2550, 6300, 13000, 22000, 38000, 64000, '78000+'],
		"reward_list_m": ['78000+', 64000, 38000, 22000, 13000, 6300, 2550],
	},
	"cfa4":"EBC研究院",
	"cfa5":"*创作者参与本次活动须具备个人的内容专栏，持续输出具有价值的市场分析和交易知识等内容，形式包括但不限于文章、报告、课程等；<br />*创作者所发布内容须保证原创，若发现转载或抄袭他人原创内容，有权取消其参与资格；<br />*参加优质原创内容的创作者，在内容满足一定规模后，可申请额外奖励;<br />*创作者奖励以月度为发放周期；<br />*报名请垂询官方客户经理或联系mkt@ebc.com<br />*参加创作者在活动期间若违反公司的合规要求，公司有权终止合作，并取消其在活动中的奖励资格;<br />*最终解释权归EBC Group所有。",
	"cfa6":{
		"title":"每个认真交易的人，都值得被认真对待",
		"titlewap":"报名请咨询客服或联系mkt@ebc.com",
		"desc":"EBC Financial Group (UK) Ltd <br />英国金融行为监管局 (FCA) 授权和监管。<br />监管号: 927552",
		"desc1":"EBC Financial Group (Australia) Pty Ltd<br />澳大利亚证券和投资委员会 (ASIC) 授权和监管。<br />监管号: 500991"
	},
	"lang": "cn"
}