export default {
  "srceen0": {
    "title": "Leverage & Margin",
    "subTitle": "Two key conditions that define <span style='color:#800'>how you trade</span>",
    "desc": "Owing to the high uncertainty and volatility in the international financial markets, EBC attaches high importance to the safety of our clients'assets.We offer every client the option to choose the preferred leverage from 1:1 (no leverage) to a maximum 500:1.",
    "rateTitle": "Leverage ratios up to",
    "rateNum": "500:1",
    "rateDesc": "Margin Level  0.20%"
  },
  "srceen1": {
    "pageEnTitle": "Secure Transaction",
    "pageTitle": "",
    "subTitle": "Selecting the optimal leverage ratio",
    "desc1": "Please carefully consider which leverage rate is appropriate for your needs. ",
    "desc2": "Please refer to the table to check out our Margin Requirements and Maximum Leverage per asset.",
    "btn": "Start Trading",
    "title":""
  }
}
