export default {
	"lang": "en",
	"screen0": {
		"title": "Financial markets at your fingertips. ",
		"desc": "There is no Trading Secret!<br>Only to find what works best for you and applying it with strict risk management",
		"quick": "Quick Overview",
		"quick1": "4 asset classes",
		"quick2": "100+ trading instruments",
		"trList": [
			"hierarchy",
			"buying price",
			"trading volume bought ",
			"point difference",
			"selling price ",
			"selling price volume "
		],
		"smallTrList": [
			"hierarchy",
			"buying price",
			"point difference",
			"selling price "
		],
		"btn": "Start Trading"
	},
	"screen1": {
		"pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "",
		"desc": "MetaTrader 4 (MT4) is the most widely used trading platform and has been considered the financial market standard for a long time, and it is also the most popular online trading platform so far.",
		"title": "Trading platform to meet various needs",
		"desc1": "EBC MetaTrader4 platform, providing an intuitive and highly customized user interface, enhanced charting functionality, and detailed order management, as well as analysis and alert tools, is an ideal choice for you to keep pace with market changes in a real time,anytime anywhere."
	},
	"screen2": {
		"num1": "3",
		"text1": "Different charts",
		"num2": "9",
		"text2": "Different time frames",
		"num3": "50",
		"text3": "Pre-installed technical indicators",
		"title": "Traders can access to 3 different charts types, 9 different time frames, and 50+ pre-installed technical indicators and a fully customizable interface, which means that highly efficient position management and accurate market monitoring can be easily achieved, regardless of which trading style or system you are using.",
		"desc": "You can download MetaTrader 4 directly on your smartphone or tablet and access capital markets anywhere anytime in the world.",
		"btnText": "",
		"btn": "lab Learning Center",
		"smallBtn": "lab Learning Center"
	},
	"screen3": {
		"title": "if you have a chance to control your life, you can start right away.",
		"desc": "EBC will continue to join efforts with MetaQuotes to optimize our fiber network and servers to provide traders with a significantly improved execution speed and transparent quotations for all asset classes. ",
		"link1": "View our full range of instruments",
		"link2": "view spread and all products",
		"list": [{
			"title": "Full Market Coverage",
			"desc": "Access to 100 + trading instruments, including Derivatives, Stocks, Indices, and Commodities"
		}, {
			"title": "Extreme Flexibility",
			"desc": "You can access your MT4 account everywhere after logging in for only once. Mobile version provides functions including interactive quotation charts, a full set of trading orders, and popular analysis tools. "
		}, {
			"title": "Real-Time and Transparent Pricing",
			"desc": "Every millisecond counts during trading sessions. Real-time pricing is offered by banks in trading system, with efficiency and transparency."
		}, {
			"title": "Advanced Chart Tools",
			"desc": "Advanced chart tools enable you to make investment decisions in a faster and more accurate way after determining price."
		}, {
			"title": "Real-Time News",
			"desc": "The real-time news from various capital markets worldwide enables you to master the first-hand market information. "
		}, {
			"title": "Fast Order Execution",
			"desc": "It provides high-speed trading and real-time dealing orders. You can use S/L function to control loss or T/P to protect profits, no matter whether you are trading at market price or pending orders. It supports all types of trading orders, including market orders, pending orders, stop loss orders and tracking stop losses. "
		}, {
			"title": "Online Access to Accounts",
			"desc": "You can check your position, balance, profits and losses, and other information and status at any time, and your current asset status is clear."
		}, {
			"title": "Safe and Reliable",
			"desc": "All data between the platform and server are encrypted by using 256-bit data. EBC creates a secure and reliable trading environment for customers. "
		}],
		"btn": "Download now"
	},
	"screen4": {
		"pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "",
		"title": "Trading Around the World from a Single Account ",
		"desc": "Explore the advantages of MT4",
		"list": [{
				"title": "MT4 Mobile APP",
				"desc": "Unlock limitless trading possibilities with the MT4 mobile app, allowing you to seize every lucrative opportunity, no matter where you are. Don't miss out on profits while on the go – trade with confidence anytime, anywhere."
			},
			{
				"title": "MT4 Desktop Version",
				"desc": "Take your trading to a whole new level with the exceptional MT4 app, recognized for its outstanding performance. Whether you're using Windows or OS X, you can effortlessly access this award-winning app and enjoy an unparalleled trading experience."
			},
			{
				"title": "MT4 Webtrader",
				"desc": "Experience instant trading on any device without the need for downloads through our user-friendly web-based app. Enjoy seamless compatibility with all popular browsers."
			}
		],
		"text1": "Download",
		"text2": "MT4 user guide",
		"text3": "learn more about all functions of MT4",
		"text4": "guide you to easily install the world's most popular trading terminal",
		"btn": "Start Trading"
	},
	"section1": {
		"title": "Trade Globally With Instant Execution",
	},
	"section2": {
		"title": "Trading platform to cater diverse needs",
		"desc": "The EBC MetaTrader4 platform offers an intuitive and customized UI, along with enhanced charting functionality and analysis tools, enabling you to stay updated with market changes and execute sophisticated order management.",
	},
	"section3": {
		"title": "What is MetaTrader 4？",
		"desc1": "MetaTrader 4 (MT4) is one of the most popular trading platforms in the world developed by MetaQuotes Software Corporation. The platform is recognized and trusted by traders around the world, thanks to its cutting-edge technology, ease of use and robust security. The main feature of MT4 is its ability to facilitate convenient market analysis, empowering users to make timely and effective entries and exits, as well as place limit and stop orders.",
		"desc2": "By selecting EBC's MT4 platform,  you not only gain the power to customize technical indicators and EAs to suit your trading preferences, but you also have the flexibility to select from a diverse range of trading products. Whether you're interested in Forex, precious metals, crude oil, stocks, indices, or other options, our platform offers a comprehensive selection to cater to your specific trading needs."
	},
	"section4": {
		"title": "MT4 Frequently Asked Questions",
		"list": [{
			"title": "How to log into my live account after downloading MT4？",
			"desc": "After a successful deposit, your account will be activated, and the system will provide you with your trading account, password, and server data. All you need to do is enter the provided account and password, select the correct server, and log in to your live account."
		},
		{
			"title": "How to change my password on MT4？",
			"desc": "After logging in to the MT4 platform, click Tools - Options, then change your password on the Server bar"
		},
		{
			"title": "How to insert chart indicators on MT4？",
			"desc": "Please follow these steps:<br>1. Choose \"Insert\" -> \"Technical Indicators\" from the top menu.<br>2. Or, you can insert \"Technical Indicators\" through the \"Navigation\" menu."
		},
		{
			"title": "How to create a study of trendlines or other indicators？",
			"desc": "Please follow these steps:<br>1. Click \"Insert\" from the top menu.<br>2. Choose the desired trendline.<br>3. Release the click to see the crosshair icon along with the selected trendline. To adjust the crosshairs on the chart, click and hold the mouse button, then move the mouse accordingly. You can also access the Trendline Research quickly by clicking \"Browse\" -> \"Toolbar\" -> \"Line Research\", and the chart will appear beneath the menu bar. You can further select different options at the top of the screen by clicking on the trendline, crosshairs, or Fibonacci retracement line."
		},
		{
			"title": "How do I select the user language on MT4？",
			"desc": "Navigate to the top menu and chooseView -> Language -> e.g. Simplified Chinese. Afterward, restart the app and the user language will switch Simplified Chinese."
		}
		],
	},
	"btn-text": "Download MT4",
  	"download-btn1": "Download for Android",
	"download-btn2": "Download for iOS",
	"download-btn3": "Download for iPad",
	"download-btn4": "Download on Windows",
	"download-btn5": "Download for MacOS",
	"download-btn6": "WebTrader",
}
