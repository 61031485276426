export default {
	"box-name": "partner2-page-en",
	"section1": {
		"text1": "임팩트 얼라이언스",
		"text2": "우리는 콘텐츠 제작자, 마케팅 전문가, 에이전트들이 함께 연결되는 생태계를 구축하여, 무역업자들이 글로벌 기회를 잡을 수 있도록 돕고 있습니다.",
		"text3": "20개국 이상에서 운영합니다",
		"text4": "업계 최고 전문가들의 지혜",
		"text5": "다양한 파트너십 계획",
		"text6": "광범위한 지원 시스템",
	},
	"section2": {
		"text1": "파트너십 계획",
		"text2": "(브로커 소개인)IB",
		"text3": "업계 교육자, 전문 거래원, 아마추어 거래자 및 기관",
		"text4": "<li>사업 개발 계획</li><li>브랜드 파트너십</li><li>거래 자원 및 마케팅 지원</li>",
		"text5": "IB 파트너 되기",
		"text6": "CPA 마케팅 파트너",
		"text7": "블로거, 미디어 KOL, 온라인 마케터",
		"text8": "<li>보상 패키지</li><li>맞춤형 파트너십 계획</li><li>멀티미디어 마케팅 지원</li>",
		"text9": "마케팅 파트너 되기",
		"text10": "전략 지원자",
		"text11": "전문 분석가, 아마추어 거래자 및 전략가",
		"text12": "<li>맞춤형 선물</li><li>온라인과 오프라인 마케팅 통합</li><li>거래 자원 및 마케팅 지원</li>",
		"text13": "전략 지원자 되기",
	},
	"section3": {
		"text1": "마케팅 지원",
		"text2": "미디어 노출",
		"text3": "전문 지식을 공유할 수 있는 플랫폼을 제공하여, 제휴 파트너의 브랜드 인지도를 널리 알리는 데 기여합니다.",
		"text4": "네트워킹 강화",
		"text5": "업계 최고 전문가들과 업무 경험을 공유하고 업계 동향을 함께 탐색합니다",
		"text6": "마케팅 지원",
		"text7": "마케팅 자료 제공,캠페인 지원,맞춤형 프로모션 전략",
		"text8": "전문 분석가 교육",
		"text9": "전문 분석가 교육, 기술 및 전문 지식 향상",
		"text10": "주문 흐름",
		"text11": "주요 자금을 식별하고 거래 승률을 높이는 데 필수적인 전문 거래 도구",
		"text12": "전문 지식의 확장",
		"text13": "전문 지식의 콘텐츠를 통해, 업계 영향력을 확대하며, 전문적인 이미지를 강화하는 데 도움을 줍니다",
	},
	"section4": {
		"text1": "협력사",
		"text2": "Joe DiNapoli",
		"text3": "<li>피보나치의 대부</li><li>DiNapoli Levels 의 창립자</li><li>FibNodes 소프트웨어의 창시자</li>",
		"text4": "Joe DiNapoli는 55년 동안의 시장 경력을 자랑하며, 1987년 다우 지수 폭락과 2002년 시장 바닥 등 중요한 전환점을 정확히 예측한 것으로 유명합니다. 25년 이상의 CTA 경력을 가진 그는, 13개 언어로 번역된 인기 저서 'DiNapoli Levels'로 잘 알려져 있습니다.",
		"text5": "더 알아보기",
		"text6": "<li>Lawrence, with a background in commercial construction, transitioned to the financial markets for a more fulfilling career. Discovering Joe DiNapoli's \"DiNapoli Levels\" transformed his trading approach. Since attending Joe's seminar in Bangkok 17 years ago, Lawrence accurately predicts market movements, including navigating the Covid Market Crash. Now a DiNapoli Expert, he mentors new traders to avoid pitfalls and accelerate learning.</li>",
		"text7": "<li>Pieter has been interested in trading since high school. As a Cum Laude qualified economist, he started full time trading at a local South African firm, intensely trading a broad spectrum of markets. He has been a student of DiNapoli studies since 2003 and teaching the DiNapoli method across the world since 2009. Today, he manages his own account, is part of a South African hedge fund and advisor to South African commodity firms. He is also the author of the D-Levels Financial Forecast newsletter. He attributes much of his success to Joe DiNapoli’s mentorship.</li>",
		"text8": "<li>Monchai Kongthanapakdi is founder of Technical Analysts Association in Thailand and Co-chair of CMT Association Thailand Chapters as well as president of Efuturesthai Trading Academy, an organization established for teaching and training technical analysis for Thai investors.<br><br>He is a full-time trader, technical analyst, and investor who specializes in Fibonacci (DiNapoli Levels) and Algorithmic trading. Monchai has been working with Mr. Joe DiNapoli since 2011 to conduct several public and private seminars in Thailand including a recent translation of Trading with DiNapoli Levels (Thai Edition) in 2022.</li>",
		"text9": "<li>President of Fibonacci Investment Consulting, LLC, USA; Guest Lecturer at the New York Institute of Finance Beijing Branch and the Chicago Board of Trade; Gold Medal Mentor at the National Real-Money Trading Competition of China Futures Daily; Translator of the Chinese edition of \"Trading with DiNapoli Levels\". As a senior expert of the DiNapoli-Levels, he has successfully predicted nearly all the turning points of the Shanghai Composite Index since 2008, as well as several intermediate tops and bottoms of the Dow Jones Index, the US Dollar Index, US crude oil futures, and US gold futures.</li>",
	},
	"section5": {
		"text1": "더 공유하기",
		"text2": "2024년 7월 12일, EBC Group과 기술 전문가인 Di Napoli는 태국 방콕에서 공식 파트너십 발표식을 열고 글로벌 시장의 리더가 되기 위한 거래 전략과 협력을 논의했습니다.",
		"text3": "거래 전략 공유",
		"text4": "DiNapoli와 그의 팀은 선진적인 트레이딩 전략을 공유하며 글로벌 언론과 투자자들의 주목을 받고 있습니다.",
		"text5": "친필 사인 책은 현장에서 드립니다",
		"text6": "이번 행사에서는 개인 브랜드 인지도를 높이기 위해 DiNapoli의 친필 사인이 담긴 한정판 책을 증정했습니다.",
		"text7": "양측의 영향력 향상",
		"text8": "협업 거래는 양측의 인지도와 영향력을 높이기 위해 거래 전문성을 발전시켰습니다.",
	},
}