export default {
	"screen0": {
		"enTitle1": "Together",
		"enTitle2": "Let's <text style='color:#800'>Build The Future.</text>",
		"title1": "成为EBC合作伙伴 <br style='display: none;'>",
		"title2": "解锁更多市场机遇",
		"list": ["国际权威监管", "全球开放合作", "全套技术解决方案"],
		"desc": "EBC认真对待每一位与我们展开深度合作的伙伴，如果您有更创新多样的合作需求，欢迎随时咨询，我们积极为您开辟更广阔的投资视野，并提供多元化的支持方案，与您展拓全球机遇，解锁无限可能。",
		"links": [{
			"url": "/about-us",
			"text": "Why EBC?"
		}, {
			"url": "",
			"text": "了解更多"
		}],
		"more": "了解更多"
	},
	"screen1": {
		"pageEnTitle": "Customization",
		"pageTitle": "定制化",
		"title": "介绍经纪人（IB）百万美金扶持计划",
		"desc": "EBC的发展离不开介绍经纪人（IB）的支持，我们为有实力的介绍经纪人(IB)提供良好的发展空间。",
		"list": ["佣金结构", "业务创新营销&交易资源", "高效结算和支付流程", "专属客户经理", "多层级佣金系统", "多语言客户支持团队", "完善的后台和报表工具", "业务发展规划"],
		"text": "全方位<span>定制</span>发展规划",
		"more": "查看更多服务"
	},
	"screen2": {
		"pageEnTitle": "Trading  service",
		"pageTitle": "交易社区",
		"title": "一整套完整的业务发展合作模式",
		"desc": "告诉我们您的需求，EBC不仅可为您提供实践经验和建议，更可根据您的要求提供个性化定制的技术解决方案，以配合不同业务发展阶段的需求。",
		"list": ["交易社区", "交易信号", "风控管理"],
		"btn": "进入交易社区"
	},
	"screen3": {
		"pageEnTitle": "Liquidity service",
		"pageTitle": "流动性接入",
		"title": "EBC支持直通式处理系统",
		"desc": "对接25+国际顶级银行和对冲基金，可降低经纪商的成本及确保订单快速执行，并有专属技术团队提供24*7小时技术支援服务。",
		"remark": "唯有时间，<span>让您</span>，更了解我。"
	},
	"screen4": {
		"pageEnTitle": "Liquidity service",
		"pageTitle": "新兴业态",
		"title": "EBC时刻具备拥抱变化的热情和能力",
		"desc": "对于行业新的发展业态，不断创新求变，我们相信只有顺应市场发展的趋势，才能稳步向前。如果您有创新的合作方案，敬请垂询。",
		"list": ["<span>4</span> 种资产类别", "<span>100</span><sup>+</sup> 交易品种"]
	},
	"box-class": ""
}

