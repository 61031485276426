export default {
	"esg-class-name": "",
	"esg-banner": {
		"btn": "了解更多",
		"text1": "EBC與聯合國基金會合力打敗瘧疾<br>成為打敗瘧疾的一代 共創平等新一代",
		"text2": "賦能兒童成長<br> EBC在全球行動",
		"text3": "EBC聯合牛津大學研討會",
		"text3-2": "“經濟學家都幹什麼”",
		"text4": "EBC攜手世界女童軍協會<br>賦能女性成長 培養明日領袖",
		"text5": "Together , Let's Build The Future",
		"text6": "EBC積極實踐ESG理念，不僅持續致力於兒童慈善與專業教育、人才培養，同時肩負起永續發展的責任。"
	},
	"esg-nav": {
		"text1": "我們的願景",
		"text2": "我們的措施",
		"text3": "我們的建設",
		"text4": "我們在行動",
	},
	"esg-page1": {
		"text1": "我們的願景",
		"text2": "教育引領未來",
		"text3": "聚焦兒童教育，改善教育條件；與高等交育機構合作，建構開放交流空間",
		"text4": "共建平等社會",
		"text5": "支持慈善組織，賦予更多人平等的衛生權利，傾力推動社會平等",
		"text6": "讓員工引以為傲",
		"text7": "打造讓員工感到驕傲與充滿價值感的公司，推動建立開放包容的世界",
		"text8": "建設綠色家園",
		"text9": "將永續發展納入公司的長期規劃中，倡導節約資源，建立理想家園",
	},
	"esg-page2": {
		"text1": "我們的措施",
		"text2": "環境",
		"text3": "<span>可持續資源使用：</span><br>採用永續的包裝材料，減少垃圾排放，促進循環經濟。",
		"text4": "<span>碳足跡減少政策：</span><br>參與改變全球暖化任務，與使用再生能源的公司建立合作。",
		"text5": "社會",
		"text6": "<span>聯結慈善組織：</span><br>透過慈善捐贈或志願服務等方式，推動搭建平等包容的社會。",
		"text7": "<span>聯合同頻合作商：</span><br>確保合作夥伴遵守環境保護和社會責任的標準，並全力推動社會發展。",
		"text8": "公司治理",
		"text9": "<span>多元與包容：</span><br>提供多元化和包容性的工作場合，反對歧視，實現機會均等。",
		"text10": "<span>員工關懷：</span><br>建立全面的員工福利計畫和職涯發展路徑，支持員工教育和技能提升。",
	},
	"esg-page3": {
		"text1": "我們的建設",
		"text2": "教育基金",
		"text3": "EBC深信教育是社會進步的基石",
		"text4": "與慈善機構及各大專院校建立合作，推動教育革新。內設專款，資助資源貧乏地區的兒童及公共教育。",
		"text5": "醫療基金",
		"text6": "一顆善意之心，讓美好發生",
		"text7": "內設醫療基金，資助福利院等，為失獨老人提供慰藉之手，為患病兒童送去希望之光。",
		"text8": "關懷基金",
		"text9": "個人家庭，支撐公司大“家”",
		"text10": "我們推崇愛家文化，內設員工關懷資金，用於協助員工孝敬父母，友愛兄弟姊妹。",
		"text11": "捐贈基金",
		"text12": "災難之後，每個人都是重建者",
		"text13": "在全球性的流行病爆發時，EBC透過內部資金，為疫區人民及家庭送去關懷",
	},
	"esg-page4": {
		"text1": "我們在行動",
	}
}