export default {
  "screen0": {
    "title": "บัญชี MAM",
    "content": "EBC Multi Account Manager (MAM) เป็นบัญชีที่ออกแบบสำหรับผู้จัดการกองทุนแบบส่วนตัวเพื่อเพิ่มผลตอบแทนจากการลงทุน ระบบที่มีความน่าเชื่อถือ เหมาะสำหรับลูกค้าที่มองหาผู้เชี่ยวชาญในการจัดการกองทุน",
    "list": [
      "วิธีการจัดสรรที่ยืดหยุ่น",
      "การรายงานแบบเรียลไทม์",
      "อินเตอร์เฟซที่ใช้งานง่าย"
    ],
    "btn": "สมัครบัญชี MAM",
    "rightTitle": "เครื่องมือซอฟต์แวร์ MAM ผสมผสานวิธีการจัดสรรที่ยืดหยุ่นเข้ากับการรายงานการดำเนินการและค่าคอมมิชชั่นแบบเรียลไทม์ ผ่านอินเตอร์เฟซที่ใช้ง่านง่าย"
  },
  "screen1": {
    "pageEnTitle": "การจัดการสินทรัพย์",
    "pageTitle":"",
    "title": "ออกแบบมาสำหรับ MetaTrader 4",
    "text": "It can trade for multiple <span> investors </span> quickly, effectively and accurately",
    "desc": "นี่คือระบบที่เหมาะอย่างยิ่งสำหรับเทรดเดอร์มืออาชีพ ที่ต้องการจัดการหลายบัญชีโดยใช้ที่ปรึกษาอัจฉริยะ (EA) MAM ขยายฟังก์ชันการทำงานของแพลตฟอร์ม MetaTrader 4 ช่วยให้ผู้จัดการสินทรัพย์สามารถซื้อขายและจัดการบัญชีหลายบัญชีได้อย่างมีประสิทธิภาพ ด้วยเครื่องมือซอฟต์แวร์ที่ผสานรวมจากอินเทอร์เฟซ MT4 เดียว",
    "btn": "เรียนรู้เพิ่มเติม",
    "imgUrl": "en"
  },
  "screen2": {
    "pageEnTitle": "ธุรกรรมการเงินอัจฉริยะ",
    "pageTitle": "",
    "title": "บัญชีซื้อขายหลายบัญชีสามารถซื้อขายได้อย่างมีประสิทธิภาพจากบัญชีเดียว",
    "desc": "ซอฟต์แวร์ MT4 MAM ช่วยให้นักลงทุนเชื่อมต่อบัญชี MT4 ของตนกับ Master Trader และคัดลอกการเทรดด้วยวิธีการจัดสรรตามต้องการ และจัดสรรธุรกรรมให้กับนักลงทุนแต่ละรายโดยอัตโนมัติ ผู้จัดการบัญชีสามารถทำธุรกรรมสำหรับนักลงทุนหลายรายได้อย่างรวดเร็ว มีประสิทธิภาพ และแม่นยำจากบัญชีธุรกรรมเดียว"
  },
  "screen3": {
    "pageEnTitle": "ข้อดีและฟังก์ชั่นการใข้งาน",
    "pageTitle": "ฟังก์ชัน MAM",
		"pageTitle2": "ข้อดีของ MAM",
    "title": "<span style='color:#800'>สำหรับการซื้อขายและการจัดการสินทรัพย์ทุกประเภท</span>",
    "content": [
      "สำหรับการซื้อขายและการจัดการสินทรัพย์ทุกประเภท",
      "สิบวิธีการจัดสรร ได้แก่ การจัดสรรกำไรขาดทุนและการจัดสรรผู้คัดลอกการเทรด",
      "บัญชีการซื้อขายไม่จำกัด",
      "การซื้อขาย – บัญชีแบบเต็ม แบบมินิ และไมโครล็อต",
      "ความแม่นยำสูงสุดของการจัดสรรกำไรขาดทุนสูงสุด 16 หลัก",
      "ประเภทการสั่งซื้อรูปแบบต่างๆ การตั้งจุดตัดขาดทุน จุดทำกำไร และฟังก์ชั่นการสั่งซื้อที่รอดำเนินการ",
      "การปิดคำสั่งบางส่วนโดย Master Account Execution หรือ Individual Sub-Orders ",
      "อนุญาตให้ใช้งาน EA ซื้อขายผ่านบัญชีที่มีการจัดการจากฝั่งลูกค้า"
    ],
    "desc": "Apply for a MAM Account",
    "btn": "apply for mam fund manager account number",
    "line1": "การเปลี่ยนแปลงการจัดสรรพารามีเตอร์ทันที",
    "line2": "ควบคุมคำสั่งซื้อที่เปิดอยู่ในบัญชีที่จัดการทั้งหมดแบบเรียลไทม์",
    "line3": "ควบคุมบัญชีที่มีการจัดการทั้งหมดจากอินเทอร์เฟซเดียว",
    "line4": "การดำเนินการ ' คำสั่ งซื้ อขายกลุ่ ม '",
    "line5": "ตรวจสอบอิควิตี้เรียลไทม์และตรวจสอบกำไรขาดทุนของบัญชีที่มีการจัดการ",
    "line6": "รับรายงานย้อนหลังและคำนวณค่าคอมมิชชั่น",
    "btn1": "เปิดบัญชี MAM วันนี้"
  },
	"styleName": "en-style"
}
