export default {
	"box-name": "DiNapoli-page-cn",
	"section1": {
		"text1": "Joe<br> DiNapoli",
		"text2": "<li>斐波那契技術傳奇教父</li><li>帝納波利點位法創始人</li><li>黃金比率應用頭號權威</li>",
		"text3": "帝納波利是一位資深交易員，擁有超過55年的實戰交易經驗，至今仍堅持每日交易。他的交易生涯亮點紛呈，包括上世紀60年代在紐交所交易Nifty 50指數，成功預測1987年的市場崩盤，以及在9/11事件後立即投入期貨交易。自1982年合約開盤以來，他一直專注於標普指數期貨的交易。帝納波利在置換移動平均線、高級斐波那契技術、擺動指標預測器和MACD預測器方面的研究享有盛譽，而他創造的領先指標更使他成為備受追捧的專家。作為一名擁有超過25年經驗的註冊交易顧問（CTA），他曾在全球範圍內舉辦講座，並撰寫了廣受好評的著作《帝納波利點位法》。該書已被翻譯成13種語言，在全球發行，未來將有更多版本問世。",
		"text4": "帝納波利點位交易法",
		"text5": "帝納波利點位法，是迄今出版的斐波那契分析在價格樞軸應用上最全面的書籍。這本權威性的指南為交易者提供了寶貴的見解和技巧，使他們能夠以精確和自信的方式增強自己的交易策略。",
		"text6": "<li>全球黃金率投資交易最權威的著作</li><li>《帝納波利點位法》在實際交易中的卓越表現，被認為是最偉大的交易書籍之一</li><li>在全球被翻譯成超過13種語言</li>",
		"text7": "免費領取",
		"text8": "帝納波利MT4/MT5指標",
		"text9": "帝納波利的領先指標，以其高效的趨勢識別和支撐阻力價位的精準度量，受到全球交易者的青睞。透過融合多種技術分析方法，其為交易者提供強大的決策支持，並顯著提高交易成功率。",
		"text10": "精準趨勢捕捉",
		"text11": "置換移動均線（DMA）系統能夠快速辨識市場趨勢，及時抓住機會",
		"text12": "有效的超買/超賣訊號",
		"text13": "擺盪指標能夠發出市場轉折的可靠訊號",
		"text14": "精準的支撐位和阻力位",
		"text15": "基於斐波那契分位水平優化交易策略",
		"text16": "深受專業投資人好評",
		"text17": "這麼多年來，我所認識的所有真正賺了大錢的優秀交易員，都上過你的課",
		"text18": "伊利諾州Watseka總裁，隨機指標（RSI）發明人",
		"text19": "您無法買到經驗，但喬的書能讓您避免昂貴的教訓。",
		"text20": "一位擁有超過30年行業經驗的市場專家和交易專家，創立了Bressert Trading Group，致力於提供專業的交易策略和服務。",
		"text21": "他在《帝納波利點位法》中介紹的方法非常實用，在牛熊市以及股票和期貨交易中都能同樣有效的發揮作用。新手交易者可以縮短幾年的學習曲線，而經驗豐富的交易者也能學會新的技巧。",
		"text22": "LBR Group的傑出交易者、作家、教育家",
		"text23": "了解更多關於帝納波利點位法",
		"text24": "了解更多關於帝納波利點位法和帝納波利專家",
		"text25": "了解更多",
	},
}