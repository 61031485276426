export default{
  "box-name": "",
  "screen0": {
    "title": "Where Your Goals Begin",
    "desc": "無論您的交易經驗或交易風格如何，<br/>我們都有適合您需求的交易帳戶"
  },
  "screen1": {
    "btn": "瞭解更多",
    "title1": "STD 標準帳戶",
    "title2": "PRO 專業帳戶",
    "line11": "低至 1.1",
    "line13": "低至 0.0",
    "line99": "無限制",
    "line100": "110USD",
    "list": [
      "標準點差",
      "手續費",
      "最大杠杆",
      "最小單筆交易量",
      "最大單筆交易量",
      "最低存款金額",
      "爆倉比例",
      "掛單距離限制",
      "允許鎖倉",
      "EA 相容性",
      "24/5 線上支持",
      "專屬客戶經理",
      "帳戶適用於",
      "開設帳戶",
    ]
  },
  "screen2": {
    "title": "極速安全開戶",
    "desc": "3步輕鬆開戶",
    "list": [{
      "title": "注册帳戶",
      "desc": "提交基本信息，簡單安全"
    }, {
      "title": "提交個人資訊",
      "desc": "只需1分鐘輕鬆完成開戶填寫"
    }, {
      "title": "入金啟動",
      "desc": "最低入金200美金實时啟動"
    }],
    "btn1": "開設類比帳戶→",
    "btn2": "開設真實帳戶→",
    "yuyan": "zh"
  },
  "screen3": {
    "pay": "支付方式"
  },
  "screen4": {
    "enTitle": "Your Trading Solution Has Been Never <span style='color: #800000;'>Easier",
    "title": "交易管理新體驗",
    "desc": "至繁歸於至簡，我們深信簡單有序是任何金融市場長期盈利最重要的一個因素。EBC為客戶提供專屬CRM管理系統，讓您的交易井井有條。",
    "imgUrl": "ct"
  },
	"screen5": {
		"title": "交易帳戶",
		"register-btn": "即時開戶",
		"text1": "選擇適合您的交易帳戶",
		"text2": "EBC提供多種帳戶類型，適合不同類型交易者，確保交易體驗靈活高效",
		"text3": "STD標準帳戶",
		"text4": "適合全部投資者和輕資本交易者",
		"text5": "起始點差低至 <i>1.1</i> 讓您的交易更具成本效益",
		"text6": "享 <i>0</i> 交易優惠手續費優惠，成本更加透明",
		"text7": "僅需 <i>$100</i> 起始資金即可啟動交易帳戶",
		"text8": "PRO專業帳戶",
		"text9": "適合專業投資者和進階交易者",
		"text10": "提供 <i>0</i> 點差交易環境，適合尋求最小交易成本的專業交易者",
		"text11": "僅需 <i>$6</i>/手，提供比肩機構帳戶的成本及深度優勢",
		"text12": "需 <i>$5000</i> 起始資金啟動交易帳戶",
		"text13": "帳戶詳細規則比較",
		"text14": "各帳戶類型的主要差異，幫助您選擇適合您交易策略的帳戶",
		"text15": "類別",
		"text16": "帳戶適用於",
		"text17": "全部投資者",
		"text18": "專業投資者",
		"text19": "開設STD標準帳戶",
		"text20": "開設PRO專業帳戶",
		"text21": "EBC優勢",
		"text22": "無論您選擇STD標準帳戶或PRO專業帳戶，均可享有平台核心優勢",
		"text23": "3步驟輕鬆開戶",
		"text24": "3步驟輕鬆帳戶，客服全程支持，快速開啟您的交易旅程",
		"text25": "下載交易平台",
		"text26": "MT4是全球頂尖的衍生品交易平台，以其卓越的圖表工具、豐富的技術指標和自動交易功能著稱，無論您使用PC端深入分析、手機端即時交易，還是網頁端即時監控，MT4都能確保您享受到流暢靈活的交易體驗",
		"text27": "MT4下載",
	}
}
