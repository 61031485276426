export default{
  "screen0": {
    "title": "化繁為簡的<br>PAMM解決方案",
    "content": "EBC為尋找專業人士管理賬戶的投資者以及資金管理者定制了耳目一新的百分比分配管理模式PAMM（Percentage Allocation Management Module），投資者和資金管理者均可登錄網頁版後台界面實現交易資金管理，利潤分配，細節制定，記錄下載等全面的功能；網頁版界面簡潔明了，功能全面，條理清晰。",
    "tips": "EBC為資金管理者，投資者提供了權責清晰，功能全面的百分比分配管理模式（PAMM）；網頁版後台簡潔清晰，化繁為簡。"
  },
  "screen1": {
    "pageEnTitle": "CLEAR DUTIES",
    "pageTitle": "權責清晰",
    "c1Title": "投資者",
		"c1Desc": "享受一站式交易服務，並分享資金管理者的交易成果。 投資者可以自由選擇符合自身風險偏好和盈利目標的資金管理者。 高水位（high water mark）條款激勵資金管理者並保護自身權益。",
		"c2Title": "資金管理者",
		"c2Desc": "擁有豐富交易經驗的交易員能夠扮演PAMM賬戶資金管理者的角色， 資金管理者可以同時管理多個交易賬戶，使用自己資金和投資者的集合資金進行交易以獲取利潤。交易收益或成本的一部分作為賬戶資金管理者的勞動薪酬。",
    "title": "PAMM賬戶能夠滿足多方面參與者要求",
    "desc": "投資者：享受一站式交易服務，並分享資金管理者的交易成果。<br>投資者可以自由選擇符合自身風險偏好和盈利目標的資金管理者。<br>高水位（high water mark）條款激勵資金管理者並保護自身權益。<br><br>資金管理者：擁有豐富交易經驗的交易員能夠扮演PAMM賬戶資金管理者的角色，<br>資金管理者可以同時管理多個交易賬戶，使用自己資金和投資者的集合資金進行交易以獲取利潤。交易收益或成本的一部分作為賬戶資金管理者的勞動薪酬。"
  },
  "screen2": {
    "pageEnTitle": "SIMPLE IS THE BEST",
    "pageTitle": "化繁為簡",
    "title": "網頁版後台簡潔清晰，化繁為簡",
    "desc": "提供涵蓋日常信息管理，交易手數/利潤分配，記錄下載等全面的解決方案，網頁版後台簡潔清晰，化繁為簡。"
  },
  "screen3": {
    "pageEnTitle": "FEATURES AND FUNCTIONS",
    "pageTitle": "優勢",
    "title": "適用於任何類型的交易和資金管理",
    "text1": "多達9種語言",
    "text2": "資金安全交易順暢",
    "text3": "2種主流利潤分配方式（按淨值分配及按餘額分配）",
    "text4": "高水位條款（high water mark）激勵資金管理者保護投資者",
    "text5": "網頁版操作界面友好清晰明了",
    "text6": "為資金管理者提供5種回報方式（盈利分成/管理費/管理年費/每手佣金/每單佣金)"
  },
	"imgUrl": "zh",
	"styleName": "en-style",
	"btn-text": "即刻申請PAMM帳戶"
}
