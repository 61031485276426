<template>
	<div class="c-header">
		<nav class="navbar fixed-top navbar-expand-lg">
			<div class="container-fluid c-header-bd">
				<router-link class="navbar-brand" to="/" :class="$t('CFooters.className')"><img src="../../assets/logo_1.png"
						alt="" width="145" height="61">
				</router-link>
				<button class="navbar-toggler" @click="showHeadMenu()">
					<img src="../../assets/image/Home/head-icon.png">
				</button>
				<div class="collapse navbar-collapse justify-content-end" :class="{'headState':headMenu}">
					<ul class="navbar-nav">
						<template v-for="(item, index) in $t('CFooters.headlist')">
							<li :key="index" v-if="item.menu && isMobil" class="nav-item dropdown"
								@click="dropdownshow(index)">
								<a :class="$t('CFooters.className')" class="nav-link dropdown-toggle" href="#"
									:id="'navbarDropdown' + index" role="button" data-bs-toggle="dropdown"
									aria-expanded="false" v-html="item.text">
								</a>
								<ul class="dropdown-menu" :aria-labelledby="'navbarDropdown' + index"
									:class="{'show':menuIndex === index}">
									<li v-for="(me, cIndex) in item.menu" :key="cIndex"
										@click.stop="dropdownshow1(cIndex)">
										<router-link v-if="me.url" class="dropdown-item"
											:to="!$store.state.suffix ? me.url : ('/' + $store.state.suffix + me.url)" v-html="me.text">
										</router-link>
										<div v-else>
											<a class="nav-link dropdown-toggle three-link" href="#"
												:id="'navbarDropdown1' + cIndex" role="button" data-bs-toggle="dropdown"
												aria-expanded="false" v-html="me.text">
											</a>
											<ul class="dropdown-menu" :aria-labelledby="'navbarDropdown1' + cIndex"
												:class="{'show':threeIndex === cIndex}">
												<li v-for="(tm, tIndex) in me.menu" :key="tIndex">
													<router-link class="dropdown-item"
														:to="!$store.state.suffix ? tm.url : ('/' + $store.state.suffix + tm.url)" v-html="tm.text">
													</router-link>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</li>
							<li :key="index" v-if="item.menu && !isMobil" class="nav-item dropdown"
								@mouseenter="dropdownshow(index)" @mouseleave='dropdownhied()'>
								<a class="nav-link dropdown-toggle" href="#" :id="'navbarDropdown' + index"
									role="button" data-bs-toggle="dropdown" aria-expanded="false" v-html="item.text">
								</a>
								<ul class="dropdown-menu" :aria-labelledby="'navbarDropdown' + index"
									:class="{'show':menuIndex === index}">
									<li v-for="(me, cIndex) in item.menu" :key="cIndex"
										@mouseenter="dropdownshow1(cIndex)" @mouseleave='dropdownhied1()'>
										<router-link v-if="me.url" class="dropdown-item"
											:to="!$store.state.suffix ? me.url : ('/' + $store.state.suffix + me.url)" v-html="me.text">
										</router-link>
										<div v-else>
											<a class="nav-link dropdown-toggle three-link" href="#"
												:id="'navbarDropdown1' + cIndex" role="button" data-bs-toggle="dropdown"
												aria-expanded="false" v-html="me.text">
											</a>
											<ul class="dropdown-menu" :aria-labelledby="'navbarDropdown1' + cIndex"
												:class="{'show':threeIndex === cIndex}">
												<li v-for="(tm, tIndex) in me.menu" :key="tIndex">
													<router-link class="dropdown-item"
														:to="!$store.state.suffix ? tm.url : ('/' + $store.state.suffix + tm.url)" v-html="tm.text">
													</router-link>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</li>
							<li :key="index" class="nav-item" v-if="item.url">
								<a :href="item.url" class="nav-link"
									v-if="item.url == 'https://ebcfinsns.co/'">{{item.text}}</a>
								<router-link class="nav-link" :class="{'c-008aed': item.url === '/Login'}"
									:to="!$store.state.suffix ? item.url : ('/' + $store.state.suffix + item.url)"
									v-else-if="item.url !== 'https://client.ebccrm.com/signup'">
									{{item.text}}
								</router-link>
								<a class="nav-link" :class="{'c-008aed': item.url === '/Login'}"
									:href="$store.state.khUrl" v-else>{{item.text}}</a>
							</li>
						</template>
						<li class="nav-item">
							<a :href="$store.state.loginUrl" class="nav-link">{{$t('CFooters.login')}}
							</a>
						</li>
					</ul>
					<language :bgColor="backgroundColor" :textStyle="{fontSize: '16px',color: '#333'}" />
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				headMenu: false,
				menuIndex: -1,
				threeIndex: -1,
				languageList: [],
				searchActive: false,
				isMobil: '',
				backgroundColor: 'rgba(61, 1, 1, 1)',
				languageList: [{
						text: '简体中文',
						lang: 'zh_CN',
						id: 'cn'
					},
					{
						text: 'English',
						lang: 'en_US',
						id: 'en'
					},
					{
						text: '繁體中文',
						lang: 'zh_TW',
						id: 'tw'
					},
					{
						text: '한 국 어',
						lang: 'ko_KR',
						id: 'ko'
					},
					{
						text: '日本語',
						lang: 'ja_JP',
						id: 'jp'
					},
					{
						text: 'ภาษาไทย',
						lang: 'th_TH',
						id: 'th'
					},
					{
						text: 'ئۇيغۇر تىلى',
						lang: 'weiwuer',
						id: 'ug'
					}
				],
				showMenu: false
			}
		},
		created() {

		},
		mounted() {
			this.isMobil = this._isMobile()
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			showHeadMenu() {
				this.headMenu = !this.headMenu
			},
			handleChangeLamguage(item) {
				this.$store.commit('setPosition', item.lang)
				this.$store.commit('setTitle', item.text)
				if (this.$store.state.suffix != '') {
					this.$store.commit('setSuffix', '/' + item.id)
					let data = this.$route
					let url = data.fullPath.replace(data.params.lang, item.id)
					this.$router.push({
						path: url
					})
				} else {
					this.showMenu = false
					this.$parent.refresh();
				}
			},
			showLangMenu() {
				this.showMenu = !this.showMenu
			},
			dropdownshow(idx) {
				this.menuIndex = idx
			},
			dropdownhied() {
				this.menuIndex = -1
			},
			toggleSearchActive() {
				this.searchActive = !this.searchActive
			},
			dropdownshow1(idx) {
				if (this.threeIndex === -1) {
					this.threeIndex = idx
				} else {
					this.threeIndex = -1
				}
			},
			dropdownhied1() {
				this.threeIndex = -1
			}
		}
	}
</script>

<style lang="scss">
	.c-header {
		.language-wap {}

		.headState {
			display: block !important;
		}

		.head-lang {
			position: relative;

			&__title {
				color: #333;
				font-size: 16px;
				cursor: pointer;
				line-height: 1.75;
				letter-spacing: .5px;
				padding: 6px 20px;
				display: flex;
				align-items: center;

				img {
					width: 20px;
					height: 20px;
					margin-right: 2px;
				}

				&::after {
					display: inline-block;
					margin-left: 0.255em;
					vertical-align: 0.255em;
					content: "";
					border-top: 0.3em solid;
					border-right: 0.3em solid transparent;
					border-bottom: 0;
					border-left: 0.3em solid transparent;
				}
			}

			&__menu {
				position: absolute;
				top: 120%;
				right: 0;
				left: 0;
				min-width: 100px;
				border-top: 4px solid #6c100f;

				&.showClass {
					position: relative;
				}

				&__item {
					text-align: center;
					display: inline-block;
					padding: 15px;
					cursor: pointer;
					width: 100%;
					font-weight: 400;
					font-size: 1rem;
					color: #fff;

					&:hover {
						background-color: #fff;
						color: #6c100f;
					}
				}
			}
		}

		.navbar {
			background-color: #fff;
			flex-direction: column;
			padding: 0;
		}

		&-clearfix {
			height: 114px;
		}

		.c-008aed {
			color: #008aed !important;
		}

		&-hd {
			background-color: rgba(227, 227, 227, 0.2);
			width: 100%;
			padding: 0 20px 0 34px;

			&__item {
				display: inline-block;
			}

			&__text {
				padding: 8px 14px;
				font-family: NotoSerifSC;
				font-size: 12px;
				font-weight: 600;
				display: inline-block;
				color: #8b8b8b;
				text-decoration: underline;
			}
		}

		&-bd {
			padding: 0;
			width: 100%;
			box-shadow: 0 0 0 1px #f5f5f5;

			.navbar-brand {
				padding-left: 55px;
			}


			.dropdown-menu {
				min-width: 140px;

				li {
					position: relative;
				}

				.dropdown-menu {
					position: absolute;
					left: 100%;
					top: 0;
				}
			}

			.nav-link {
				padding: 6px 20px !important;
				font-size: 16px;
				line-height: 1.75;
				letter-spacing: 0.5px;
				color: #333333;
			}

			.three-link::after {
				transform: rotate(-90deg);
			}
		}

		.nav-item {
			position: relative;
		}

		.nav-search,
		.nav-msg {
			width: 92px;
			height: 86px;
			background-color: #7d1614;
			position: fixed;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			color: #fff;
		}

		.nav-search-box {
			position: absolute;
			bottom: 0;
			right: 0;
			transform: translateY(100%);
			width: 100px;
			opacity: 0;
			white-space: nowrap;
			background-color: #fff;
			box-shadow: 0 0 5px #eee;
			border-radius: 5px;
			padding: 15px 0;

			&.active {
				width: 400px;
				padding: 15px 20px;
				opacity: 1;
			}
		}

		.nav-msg {
			background-color: #6c100f;
		}
	}

	@media (max-height: 375px) {
		.justify-content-around {
			height: 200px;
			overflow-y: auto;
		}
	}

	@media screen and (max-width: 1024px) and (min-width:769px) {
		.c-header-bd {
			.nav-item {
				.nav-link {
					padding: 6px 12px !important;
					white-space: nowrap;

					&.en-box {
						padding: 6px 0px !important;
					}

					&.th-box {
						padding: 6px 0px !important;
					}

					&.vi-box {
						padding: 6px 0px !important;
					}

					&.ja-box {
						padding: 6px 5px !important;
					}
				}
			}

			.navbar-brand {
				padding-left: 15px !important;
				margin-right: 10px;

				&.en-box {
					padding-left: 10px !important;
				}

				&.th-box {
					padding-left: 10px !important;
				}

				&.vi-box {
					padding-left: 10px !important;
				}
			}
		}
	}

	@media (width: 768px) {
		.dropdown-menu {
			.dropdown-menu {
				position: initial !important;
			}
		}
	}

	@media (max-width: 767px) {
		.c-header {
			.language-wap {
				display: block;
			}

			&-clearfix {
				height: 66px;
			}

			&-bd {
				box-shadow: 0 0 0 1px #7d1614;
			}

			.navbar-brand {
				padding-left: 20px;
			}

			.justify-content-end {
				max-height: 500px;
				overflow-y: auto;
			}

			.dropdown-menu {
				position: relative !important;
				left: 0 !important;
			}

			.navbar-toggler {
				&:focus {
					box-shadow: none;
				}

				.icon {
					width: 30px;
					height: 30px;
					background-color: #000;
				}
			}

			.fixed-menu {
				position: fixed;
				bottom: 150px;
				right: 0;
				transform: scale(.75);
				transform-origin: 100% 50%;
				z-index: 100;
			}
		}
	}
</style>