export default {
	"malaria-class-name": "cn-ubm-box",
	"malaria-page1": {
		"text1": "EBC金融集團攜手聯合國基金會",
		"text2": "成為打敗瘧疾的一代 共創平等新一代",
	},
	"malaria-page2": {
		"text1": "瘧疾：兒童健康最大威脅之一",
		"text2": "2022年，全球85個國家預估有2.49億例瘧疾病例和60.8萬例瘧疾死亡，其中76%以上是5歲以下的兒童。幾乎在您閱讀這段文字的1分鐘裡，就有一名兒童死於瘧疾。",
		"text3": "2.49<i>億</i>",
		"text4": "感染",
		"text5": "60.8<i>萬</i>",
		"text6": "死亡",
		"text7": "76%<i>+</i>",
		"text8": "兒童",
		"text9": "*資料來源：世界衛生組織《2023年世界瘧疾報告》",
	},
	"malaria-page3": {
		"text1": "聯合打敗瘧疾",
		"text2": "聯合打敗瘧疾是聯合國基金會發起的全球運動，匯集世界各地的支持者，共同對抗瘧疾。<br>EBC積極實踐企業責任，聯手聯合國基金會，全力支持聯合打敗瘧疾運動",
		"text3": "60<i>個</i>",
		"text4": "影響的國家",
		"text5": "4000<i>萬</i>",
		"text6": "保護的人群",
		"text7": "183450<i>個</i>",
		"text8": "驅蟲帳篷",
	},
	"malaria-page4": {
		"text1": "加入「打敗瘧疾5K」公益長跑",
		"text2": "2024 年 4 月 25 日至 5 月 5 日期間，與世界各地的支持者一起參加「打敗瘧疾」公益長跑提高人們對瘧疾的認識並籌集資金，以支持瘧疾救治計畫和生命救援工具。EBC集團與聯合國基金會攜手，向志同道合者發出邀請，與我們一起共築無瘧疾世界。",
		"text3": "立即加入",
	},
	"malaria-page5": {
		"text1": "我們在行動",
	},
}