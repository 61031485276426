export default {
  "lang": "ko",
  "screen0": {
    "title": "금융 세계가<br />가까이 있습니다",
    "desc": "There is no Trading Secret!<br>Only to find what works best for you and applying it with strick risk management",
    "quick": "빨리 이해",
    "quick1": "4종자산종류",
    "quick2": "100+거래품종",
    "trList": [
      "层级",
      "买入价",
      "买入的交易量",
      "点差",
      "卖出价",
      "卖出价交易量"
    ],
    "smallTrList": [
      "层级",
      "买入价",
      "点差",
      "卖出价"
    ],
    "btn": "투자 시작"
  },
  "screen1": {
    "pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "MT4 거래 플랫폼",
    "desc": "오랜 시간동안 MetaTrader 4는 전세계 소매 외환 거래 플랫폼의 업계 기준으로 간주되었으며, 지금까지 가장 인기높은 온라인 거래 플랫폼입니다.",
    "title": "다양한 요구를 충족시키는 MT4 플랫폼",
    "desc1": "EBC MetaTrader4 플랫폼은 직관적이고 고도 맞춤형의 사용자 화면 및 강화된 도표 기능과 정세한 오더관리, 분석 및 경보 도구를 제공해 언제 어디서나 시장과 보조를 맞추는 이상적인 방식을 실현하였습니다."
  },
  "screen2": {
    "num1": "3종",
    "text1": "유형의 아이콘",
    "num2": "9종",
    "text2": "개의 시간 프레임",
    "num3": "50종",
    "text3": "사전 설치된 기술 지표",
    "title": "거래자는 3종의 각자 다른 도표 유형, 9개의 시간범위, 50종 이상의 사전 설치한 기술지표 및 완전 맞춤형 화면 방문을 할 수 있습니다. 이는 고객님이 어떠한 거래 풍격 또는 시스템을 사용하든 모두 효과적인 포지션 관리와 정확한 시장 모니터링을 손쉽게 이룰수 있음을 의미합니다.",
    "desc": "고객님은 언제 어디서나 저희의 Web 브라우저 또는 PC/모바일 설비를 통해 MetaTrader 4를 신속하게 다운로드하여 전세계 자본시장과 연결할 수 있습니다.",
    "btnText": "더 많은 제품을 알고 싶어요？",
    "btn": "Lab 학습 센터 / 생성물",
    "smallBtn": "Lab 학습 센터"
  },
  "screen3": {
    "title": "기회 가 있 는 인생 은 즉각 출발한다.",
    "desc": " EBC도 MetaQuotes와 함께 저희 광섬유 네트워크와 서버를 조정해 거래자에게 집행속도와 모든 자산 종류를 대폭 제고하는 투명 견적을 제공하기 위해 노력하고 있습니다.",
    "link1": "거래 시간 표시",
    "link2": "포인트 와 모든 상품 보기",
    "list": [{
      "title": "전면적인 시장 커버",
      "desc": "외환, 주식, 지수, 상품 차액결제거래 등 100+ 자본시장 제품 방문"
    }, {
      "title": "최고의 영활성",
      "desc": "한번 로그인 하면 어디서나 고객님의 MT4계정을 방문할 수 있습니다. 모바일 버전에 제공하는 기능에는 인터랙션형 견적 도표, 풀 세트의 거래 오더 및 유행하는 분석도구가 포함됩니다."
    }, {
      "title": "실시간 투명한 호가",
      "desc": "거래에서 1ms도 아주 중요합니다. 은행 및 거래 매매 시스템이 실시간 견적을 제공해 효율적이고 투명합니다."
    }, {
      "title": "선진적인 도표 도구",
      "desc": "선진적인 도표 기능을 통한 가격판단을 통해 더욱 빠르고, 더욱 정확학 투자 의사결정을 내릴수 있습니다."
    }, {
      "title": "실시간 뉴스",
      "desc": "전세계 각 자본시장의 실시간 뉴스를 통해 1차 시장정보를 장악할 수 있습니다."
    }, {
      "title": "신속한 주문집행",
      "desc": "고속의 매매와 즉시성사 거래지시 시가 또는 위탁거래 여부를 막론하고 모두 손절을 통해 손해를 통제하거나 또는 익절을 통해 수익을 보호할 수 있습니다. 시장주문, 위탁, 손절오더 및 추적손절을 포함한 모든 유형의 거래주문을 지원합니다."
    }, {
      "title": "계정의 온라인 조회",
      "desc": "자신의 포지션, 잔액 및 손익 등 정보와 상황을 수시로 조회할 수 있어 현재 자산상황을 바로 확인할수 있습니다."
    }, {
      "title": "안전성 신뢰성",
      "desc": "访플랫폼과 서버 간의 모든 데이터는 전부 256자리 데이터 암호화를 적용해 EBC는 고객에게 안전하고 신뢰성 있는 거래환경을 마련해 드ㄼ니다."
    }],
    "btn": "지금 다운로드"
  },
  "screen4": {
    "pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "MT4 거래 플랫폼",
    "title": "한개 계정에서 모든 자본시장 제품 거래",
    "desc": "MT4 플랫폼 의 장점 탐색",
    "list": [{
        "title": "MT4 모바일 버전 다운로드",
        "desc": "MT4 모바일 애플리케이션을 통해 거래를 제어하고 거래 기회를 놓치지 마십시오."
      },
      {
        "title": "MT4 데스크톱 버전 다운로드",
        "desc": "수상 경력에 빛나는 MT4 플랫폼에서 EBC의 초고속 실행 속도와 강력한 거래 기능을 경험하십시오."
      },
      {
        "title": "MT4 웹트레이더",
        "desc": "웹 브라우저를 통해 전 세계 어디에서나 MT4에 로그인할 수 있습니다."
      }
    ],
    "text1": "저희의",
    "text2": "MT4 사용자 지침",
    "text3": "을 다운로드하여 MT4의 모든 기능을 더욱 깊이 파악하시기 바랍니다.",
    "text4": "전세계에서 최고 인기를 누리는 거래 플랫폼을 손쉽게 설치할 수 있도록 지도해 드립니다.",
    "btn": "투자 시작하기"
  },
	"section1": {
		"title": "밀리초에 도달해야 합니다. &nbsp;전 세계적으로 무역",
	},
	"section2": {
		"title": "다양한 요구를 충족시키는 MT4 플랫폼",
		"desc": "EBC MetaTrader4는 포괄적인 차트 기능과 강력한 분석 도구를 갖춘 맞춤형 사용자 인터페이스를 제공하여 언제 어디서나 시장과 동기화하여 정교한 주문 관리를 달성할 수 있도록 도와줍니다.전 세계 30개 이상의 국가에서 거래 플랫폼의 70%가 선택하고 세계 소매 파생 상품 거래량의 90%를 차지하는 Metatrader 4는 지금까지 가장 인기 있는 온라인 거래 소프트웨어입니다.",
	},
	"section3": {
		"title": "메타트레이더 4란?",
		"desc1": "메타트레이더 4 (MT4)는 MetaQuotes Software Corporation에서 개발한 오늘날 세계에서 가장 인기 있는 거래 플랫폼 중 하나입니다. 첨단 기술, 사용 편의성 및 높은 수준의 보안으로 전 세계 트레이더에게 보편적으로 인정받고 있으며, MT4의 가장 중요한 특징은 사용자가 적시에 효과적인 매수 및 매도, 가격 제한 및 가격지정주문을 하여 시장 감지를 편리하게 구현할 수 있다는 것입니다.",
		"desc2": "EBC의 MT4 플랫폼을 선택하면 기술적 지표와 EA를 맞춤 설정할 수 있을 뿐만 아니라 외환, 귀금속, 원유, 주식, 지수 등 다양한 거래 상품을 선택하실 수 있습니다."
	},
	"section4": {
		"title": "MT4 자주 묻는 질문",
		"list": [{
			"title": "MT4를 다운로드 했는데, 내 실제계좌로 로그인하려면 어떻게 해야 하나요?",
			"desc": "입금에 성공하면 계좌가 활성화되고 시스템에서 거래 계좌, 비밀번호 및 서버 데이터를 제공합니다. 주어진 계좌와 비밀번호를 입력하고 올바른 서버를 선택한 후 실계좌에 로그인하기만 하면 됩니다."
		},
		{
			"title": "MT4에서 비밀번호는 어떻게 변경하나요?",
			"desc": "MT4 플랫폼에 로그인한 후 도구 - 옵션을 클릭한 다음 서버 란에서 비밀번호를 변경할 수 있습니다."
		},
		{
			"title": "MT4에서 차트 보조지표는 어떻게 삽입하나요?",
			"desc": "다음 절차를 따라 진행하세요:<br>1. 상단 메뉴에서 '삽입' -> '기술 지표'를 선택합니다;<br>2. 또는 '탐색'을 통해 '기술 지표'를 삽입할 수 있습니다"
		},
		{
			"title": "추세선 또는 기타 역학 연구를 만들려면 어떻게 해야 하나요?",
			"desc": "다음 단계를 수행하세요:<br>1. 상단 메뉴에서 '삽입'을 선택합니다;<br>2. 원하는 추세선을 선택합니다;<br>3. 클릭에서 손을 떼면 선택한 추세선과 함께 십자선 아이콘이 나타납니다. 선택한 차트에서 십자선을 이동하려면 마우스 클릭을 길게 누른 상태에서 마우스를 움직여 추세선 크기를 조정합니다. \"찾아보기\" -> \"도구 모음\" -> \"선 연구\"를 클릭하면 메뉴 모음 아래에 차트가 표시되어 추세선 연구 바로 가기를 빠르게 사용할 수도 있습니다. 화면 상단에서 추세선, 십자선 또는 피보나치 되돌림선을 클릭하여 다른 옵션을 선택할 수 있습니다."
		},
		{
			"title": "MT4에서 사용자 언어는 어떻게 선택하나요?",
			"desc": "상단 메뉴로 이동하여 보기-> 언어-> 한국어를 선택한 다음 소프트웨어를 다시 시작하면 사용자 언어가 한국어로 변경됩니다."
		}
		],
	},
	"btn-text": "MT4 다운로드",
  "download-btn1": "Download for Android",
	"download-btn2": "Download for iOS",
	"download-btn3": "Download for iPad",
	"download-btn4": "Download on Windows",
	"download-btn5": "Download for MacOS",
	"download-btn6": "WebTrader",
}
