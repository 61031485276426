export default {
	"box-name": "DiNapoli-page-en",
	"section1": {
		"text1": "Joe<br> DiNapoli",
		"text2": "<li>Cha đẻ của Fibonacci</li><li>Nhà sáng lập DiNapoli Levels</li><li>Chuyên gia hàng đầu phương pháp giao dịch Tỷ lệ vàng</li>",
		"text3": "DiNapoli là một nhà giao dịch kỳ cựu với hơn 55 năm kinh nghiệm thực chiến. Đến nay, ông vẫn giữ thói quen giao dịch hàng ngày. Sự nghiệp giao dịch của ông nổi bật với nhiều điểm sáng, bao gồm việc giao dịch chỉ số Nifty 50 trên Sở Giao dịch Chứng khoán New York vào những năm 60, dự đoán thành công cuộc sụp đổ thị trường năm 1987 và tham gia giao dịch hợp đồng tương lai ngay sau sự kiện 9/11. Từ khi hợp đồng tương lai S&P mở cửa vào năm 1982, Joe đã chuyên tâm giao dịch loại hợp đồng này. DiNapoli nổi tiếng với nghiên cứu về các Đường DMA, kỹ thuật Fibonacci Cao Cấp, Oscillator Predictor và MACD Predictor. Những chỉ báo hàng đầu do ông tạo ra đã khiến ông trở thành một chuyên gia được săn đón. Là một nhà tư vấn giao dịch (CTA) với hơn 25 năm kinh nghiệm, ông đã tổ chức các buổi diễn thuyết trên toàn cầu và là tác giả của cuốn sách được đánh giá cao \"Trading with DiNapoli Levels\". Cuốn sách này đã được dịch sang 13 ngôn ngữ và phát hành trên toàn thế giới, và sẽ còn nhiều phiên bản khác trong tương lai.",
		"text4": "Trading with DiNapoli Levels",
		"text5": "\"Trading with DiNapoli Levels\" là cuốn sách toàn diện nhất từng được xuất bản về việc áp dụng thực tiễn Phân tích Fibonacci vào trục giá. Hướng dẫn uy tín này cung cấp những hiểu biết và kỹ thuật vô giá cho các nhà giao dịch, giúp họ nâng cao chiến lược giao dịch với độ chính xác và tự tin.",
		"text6": "<li>Tác phẩm uy tín hàng đầu về giao dịch đầu tư tỷ lệ vàng toàn cầu</li><li>Nhờ vào hiệu quả được công nhận trong giao dịch thực tiễn, cuốn sách \"Trading with DiNapoli Levels\" được xem là một trong những cuốn sách giao dịch vĩ đại nhất mọi thời đại.</li><li>Được dịch sang hơn 13 ngôn ngữ trên toàn thế giới.</li>",
		"text7": "Nhận miễn phí",
		"text8": "Chỉ Báo DiNapoli MT4/MT5",
		"text9": "Các chỉ báo hàng đầu của DiNapoli được các nhà giao dịch trên toàn thế giới ưa chuộng nhờ khả năng xác định xu hướng hiệu quả và đo lường chính xác các mức hỗ trợ và kháng cự. Bằng cách kết hợp các phương pháp phân tích kỹ thuật khác nhau, chúng cung cấp chính sách cho các nhà giao dịch, nâng cao đáng kể tỷ lệ thành công trong giao dịch",
		"text10": "Nhận diện xu hướng chính xác",
		"text11": "Hệ thống DMA nhanh chóng nhận diện các xu hướng thị trường để nắm bắt cơ hội kịp thời",
		"text12": "Tín hiệu Overbought/Oversold hiệu quả",
		"text13": "Oscillator đưa ra tín hiệu đáng tin cậy về các điểm xoay chiều của thị trường",
		"text14": "Hỗ trợ và kháng cực chính xác",
		"text15": "Fibonacci-based levels tối ưu hóa các chiến lược giao dịch",
		"text16": "Nhận được đánh giá cao từ các nhà đầu tư",
		"text17": "Tất cả những nhà giao dịch vĩ đại mà tôi biết trong nhiều năm qua và thực sự kiếm được tiền đều đã từng tham gia khóa học của bạn",
		"text18": "Chủ tịch của Watseka, Illinois và Người sáng lập Stochastic Oscillator",
		"text19": "Bạn không thể mua được kinh nghiệm, nhưng cuốn sách của Joe có thể giúp bạn tránh những bài học đắt giá.",
		"text20": "Chuyên gia thị trường và chuyên viên giao dịch với hơn 30 năm kinh nghiệm trong ngành Bressert Trading Group",
		"text21": "Các phương pháp của ông trong \"Trading with DiNapoli Levels\" thực tế đến mức chúng hoạt động hiệu quả trong cả thị trường tăng và giảm - cổ phiếu và hợp đồng tương lai. Những nhà giao dịch mới có thể rút ngắn vài năm quá trình học hỏi của mình và những nhà giao dịch lâu năm CÓ THỂ học được những điều mới mẻ. ",
		"text22": "Nhà giao dịch, tác giả và nhà giáo dục xuất sắc của LBR Group",
		"text23": "TÌM HIỂU THÊM VỀ DINAPOLI LEVELS",
		"text24": "Tìm hiểu thêm về DiNapoli Levels và chuyên gia DiNapoli",
		"text25": "Tìm hiểu thêm",
	},
}