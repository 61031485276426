export default {
  type: "en",
  lang: "en",
  lang2: "en",
  "Trading Central": "トレーディングセントラルの",
  市场分析: "市場分析ツール",
  EBC与TradingCentral携手合作:
    "トレーディングセントラルの市場分析ツールはEBCのMT4/5に搭載されており、すべてのアクティブなお客様に提供しています。ぜひお試しいただき、取引機会を見極め、取引戦略を最適化するのに役立ちましょう。",
  分析师观点: "アナリストの視点",
  财经日历解析: "経済カレンダー分析 ",
  "Alpha-MT4/MT5技术指标": "Alpha-MT4/MT5テクニカル指標",
  "Martin Pring教学视频": "マーティン・プリングによる学習動画",
  解锁完整版: "口座開設はこちら",
  "*本页面的信息不构成EBC":
    "*本ページは、EBC、Trading Central、または関連するコンテンツプロバイダーによる金融商品への投資に関するアドバイスや推奨を示すものではありません。また、当該情報を使用したことで生じるいかなる損失または損害についても責任を負いません。",
  收起: "閉じる",
  外汇: "Forex",
  指数: "Indices ",
  商品: "Commodities ",
  星号数代表支撑位和阻力位的强度: "Star Ratings indicate the strength of the support and resistance level",
  分析师观点: "アナリストの視点",
  通过清晰的趋势线展示简明易行的交易计划:
    "明確なトレンドラインを用いて取引プランを分かりやすく視覚化。取引を行っている間も継続的に更新することで、新たな取引機会を見出せます。",
  财经日历解析: "経済カレンダー分析",
  提供38个国家:
    "38カ国/地域のマクロ経済データにリアルタイムでアクセス可能。これにより、トレーダーは市場の潜在的な変動を監視、予測し、対応することが可能になります。",
  "Alpha-MT4/MT5 技术指标": "Alpha-MT4/MT5テクニカル指標",
  通过三个简明的创新指标展示市场情绪:
   "シンプルかつ革新的な3つの指標が市場のセンチメントを明らかにすることで、トレーダーは十分な情報に基づいた意思決定を行いながら、潜在的なエントリーポイントとイグジットポイントを特定できるようになります。",
  技术观点: "テクニカル分析：",
  通过技术分析结果: "テクニカル分析の結果に基づき、各チャートから読み取れる推奨方向と目標水準を示します。",
  自适应K线图: "Kラインチャート：",
  支持16种与决策相关的K线图形模式: "16種類のKラインのパターンをサポートし、意思決定をより円滑にします。",
  "自适应趋异指标(ADC)": "ADC (Adaptive Divergence Convergence) インジケーター ：",
  "长短进/出信号": "ロング/ショートのエントリーとイグジットシグナル、ファスト/スローのプライスインジケーター、イニシャル/スムーズのシグナルライン、ファスト/スローのオシレーターを含みます。",
  下载指标: "インジケーターをダウンロードする",
  "Martin Pring 教学视频": "マーティン・プリングによる学習動画",
  "通过Martin Pring的视频课程":
    "マーチン・プリングの動画コースを視聴することで、トレンドラインや移動平均線などのテクニカル分析の基礎を学び、取引スキルをさらに向上させることができます。",
  灵活的学习模式: "フレキシブルな学習モード ：",
  可以自由安排学习进度:
    "投資テクニックを簡単にマスターできる豊富なコンテンツとツールで、自分のペースで学習スケジュールを立てましょう。",
  广泛的内容覆盖: "幅広いコンテンツ ：",
  从初级到高级:
    "初心者から上級者まで、様々なユーザーに対応した総合的なコース内容で、投資スキルを体系的に向上させます。",
  互动学习体验: "インタラクティブな学習体験：",
  包含术语表和自我评估等互动工具:
    "ただ単に読むだけでなく、単語帳やセルフアセスメントテストなどを用いることで習熟度を確認。より深い理解をすることで知識の定着を図ります。",
  了解更多: "口座開設はこちら",
};
