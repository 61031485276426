export default {
    screen0: {
        title: '以史为鉴 放眼未来',
        desc: 'EBC多维数据中心，涵盖全球主流资产类别10余种',
        list: [
            '100年经通胀调整后的主要市场指数',
            '超过20年的主要货币市场风云变幻',
            '历经半个多世纪贵金属的惊涛骇浪',
            '1946年起原油的历史涨跌全貌',
            '近23年北美天然气市场的跌宕起伏'
        ],
        text: '过去与未来<br />一切尽在您的掌控'
    },
    screen1: {
        pageEnTitle: 'Dow Jones - DJIA - 100 Year Historical Chart',
        pageTitle: '道琼斯指数(DJIA)100年历史图表',
        title: '过去100年以来的<text style="color:#6c100f">道琼斯工业平均(DJIA)指数</text>历史图表',
        list: [
            '历史数据使用经通胀调整后的CPI数据，每个数据点代表月末收盘价',
            '当前月份价格以最新的小时收盘价为准',
            '截至2020年10月20日，道琼斯工业平均指数的当前价格为28,308.79'
        ],
    },
    screen2: {
        pageEnTitle: 'NASDAQ Composite - 45 Year Historical Chart',
        pageTitle: '纳斯达克综合指数过去45年的历史图表',
        title: '自1971年以来<text style="color:#6c100f">纳斯达克综合指数</text>的历史图表',
        list: [
            '历史数据使用经通胀调整后的CPI数据，每个数据点代表月末收盘价',
            '当前月份价格以最新的小时收盘价为准',
            '截至2020年10月20日，纳斯达克综合指数的当前价格为11,516.49'
        ],
		text: '了解股票价差合约',
		btn: 'Lab 学习中心 / 股票价差合约'
    },
    screen3: {
        pageEnTitle: 'EUR USD- Historical Chart',
        pageTitle: '欧元兑美元汇率历史图表',
        title: '自1999年以来<text style="color:#6c100f">欧元兑美元(EUR USD)的日线图</text>价格走势',
    },
    screen4: {
        pageEnTitle: 'GBP USD - Historical Chart',
        pageTitle: '英磅兑美元汇率历史图表',
        title: '自1971年以来<text style="color:#6c100f">英磅兑美元日线图</text>价格走势',
        text: '了解衍生品交易',
        btn: 'Lab 学习中心 / 衍生品板块'
    },
    screen5: {
        pageEnTitle: 'Gold Price - Last 10 Years',
        pageTitle: '过去10年黄金价格',
        title: '过去10年以<text style="color:#6c100f">美元计价的黄金</text>价格走势',
        list: [
            '截至2020年10月19日，目前的价格是1,911.70美元'
        ]
    },
    screen6: {
        pageEnTitle: 'WTI Crude Oil Prices - 10 Year Daily Chart',
        pageTitle: 'WIT原油价格10年日线图走势',
        title: '过去10年<text style="color:#6c100f">里西德克萨斯中质原油</text>的每日收盘价',
        list: [
            '所示价格以美元计价',
            '截至2020年10月20日，WTI原油的当前价格为每桶41.65美元'
        ]
    },
    screen7: {
        pageEnTitle: 'Natural Gas Prices - Historical Chart',
        pageTitle: '天然气价格历史图表',
        title: '<text style="color:#6c100f">亨利枢纽天然气</text>的历史价格',
        list: [
            '所示价格以美元计价',
            '截至2020年10月14日，天然气的当前价格为2.02美元'
        ],
		text: '了解大宗商品',
		btn: 'Lab 学习中心 / 大宗商品'
    },
}