<template>
	<div class="c-footer">
		<div class="c-footer-hd d-none d-md-block">
			<div class="container-xxl">
				<div class="row">
					<div class="col-md-4">
						<img width="200" height="83" src="../../assets/image/footer/footer-logo.png" />
						<a class="customer-login" :href="$store.state.loginUrl">{{$t('CFooters.column1.login')}}</a>
						<div class="language-box">
							<p class="language-box__text">{{$t('CFooters.column1.langText')}}</p>
							<ul class="language-list">
								<li :key="index" v-for="(item, index) in languageList"
									@click="handleChangeLamguage(item)" v-if="item.lang != $store.state.Lan">
									<span>{{item.text}}</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-2" :key="colIndex" v-for="(col, colIndex) in $t('CFooters.linkList')">
						<p class="list-title">{{col.title}}</p>
						<ul class="menu-list">
							<li :key="linkIndex" v-for="(link, linkIndex) in col.list">
								<router-link v-if="link.link"
									:to="!$store.state.suffix ? link.link :('/' + $store.state.suffix + link.link)">{{link.label}}</router-link>
								<template v-else>{{link.label}}</template>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="c-footer-ft">
			<div class="container-xxl">
				<div class="bottom-text d-none d-md-block" v-html="$t('CFooters.text')"></div>
				<div class="bottom-text d-md-none d-block" v-html="$t('CFooters.waptext')"></div>
			</div>
		</div>
		<div class="c-footer-bq">
			Copyright © {{year}} EBC Financial Group (SVG) LLC. All Rights Reserved.
		</div>
		<img src="https://d21y75miwcfqoq.cloudfront.net/fc97176a" alt="" style="display: none;">
		<noscript>
			<a href="https://www.livechatinc.com/chat-with/12340599/" rel="nofollow"> Chat with us </a>, powered by
			<a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow " target="_blank ">LiveChat</a>
		</noscript>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				year: '',
				languageList: [{
						text: '简体中文',
						lang: 'zh_CN',
						id: 'cn'
					},
					{
						text: 'English',
						lang: 'en_US',
						id: 'en'
					},
					{
						text: '繁體中文',
						lang: 'zh_TW',
						id: 'ct'
					},
					{
						text: '한 국 어',
						lang: 'ko_KR',
						id: 'ko'
					},
					{
						text: '日本語',
						lang: 'ja_JP',
						id: 'jp'
					},
					{
						text: 'ภาษาไทย',
						lang: 'th_TH',
						id: 'th'
					},
					{
						text: 'ئۇيغۇر تىلى',
						lang: 'weiwuer',
						id: 'vi'
					}
				],
			}
		},
		mounted() {
			window.dataLayer = window.dataLayer || [];

			// function gtag() {
			// 	dataLayer.push(arguments);
			// }
			// gtag('js', new Date());
			// gtag('config', 'G-TJ431PDHZX');
			window.__lc = window.__lc || {};
			window.__lc.license = 12340599;
			(function(n, t, c) {
				function i(n) {
					return e._h ? e._h.apply(null, n) : e._q.push(n)
				}
				var e = {
					_q: [],
					_h: null,
					_v: "2.0",
					on: function() {
						i(["on", c.call(arguments)])
					},
					once: function() {
						i(["once", c.call(arguments)])
					},
					off: function() {
						i(["off", c.call(arguments)])
					},
					get: function() {
						if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
						return i(["get", c.call(arguments)])
					},
					call: function() {
						i(["call", c.call(arguments)])
					},
					init: function() {
						var n = t.createElement("script");
						n.async = !0, n.type = "text/javascript", n.src =
							"https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
					}
				};
				!n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
			}(window, document, [].slice))
			let nowDate = new Date();
			this.year = nowDate.getFullYear()
		},
		methods: {
			handleChangeLamguage(language) {
				console.log(language.id);
				this.$i18n.locale = language.id
				localStorage.setItem('language', language.id)
			}
		}
	}

</script>

<style scoped="scoped" lang="scss">
	.c-footer {

		.c-footer-ft {
			background-color: #6c100f;
			padding-top: 20px;
			padding-bottom: 50px;

			.bottom-text {
				font-size: 12px;
				line-height: 1.67;
				color: #f7f7f7;
			}
		}

		@media (max-width: 767px) {
			.c-footer-ft {
				padding: 20px 0;
			}
		}

		.c-footer-bq {
			background-color: #000;
			text-align: center;
			font-size: 12px;
			padding: 20px;
			color: #f7f7f7
		}

		.c-footer-bd {
			padding: 20px 0;

			.ad-text {
				font-size: 12px;
				line-height: 1.67;
				color: #333333;
				font-weight: bold;

				.more {
					color: #008aed;
					margin-left: 50px;
				}

				.btn {
					height: 44px;
					line-height: 42px;
					padding: 0 43px;
					width: 100%;
					border-radius: 4px;
					border: solid 1px #d1d1d1;
					font-size: 16px;
					font-weight: bold;
					text-align: center;
					color: #646464;
				}
			}
		}

		.c-footer-hd {
			background-color: #333333;
			padding-top: 76px;
			padding-bottom: 52px;

			.other-text {
				margin-top: 40px;
				font-size: 12px;
				line-height: 2;
				color: #f7f7f7;
				width: 235px;
			}

			.language-box {
				margin-top: 34px;

				&__text {
					font-size: 12px;
					line-height: 1.67;
					color: #f7f7f7;
				}

				.language-list {
					margin-top: 12px;

					li {
						display: inline-block;
						font-size: 12px;
						font-weight: 600;
						line-height: 1.67;
						color: #f7f7f7;
						margin-right: 12px;
						cursor: pointer;

						span {
							text-decoration: underline;
						}

						img {
							width: 16px;
							height: 11px;
							vertical-align: middle;
							margin-right: 5px;
						}
					}
				}
			}

			.customer-login {
				margin-top: 20px;
				font-size: 16px;
				font-weight: 600;
				line-height: 1.75;
				color: #f7f7f7;
				text-decoration: underline;
			}

			.search-box {
				margin-top: 38px;
				position: relative;

				img {
					width: 22px;
					height: 22px;
					transform: translateY(-50%);
				}

				.form-control {
					background-color: transparent;
					width: 235px;
					height: 32px;
					line-height: 30px;
					margin: 0 0 19px;
					padding: 5px 10px 5px 42px;
					border-radius: 2px;
					border: solid 1px #979797;
					font-size: 12px;

					&:-moz-placeholder {
						color: #f7f7f7;
					}

					&:focus {
						box-shadow: none;
					}
				}
			}

			.list-title {
				font-size: 16px;
				font-weight: bold;
				line-height: 1.75;
				color: #f7f7f7;
			}

			.menu-list {
				font-size: 14px;
				line-height: 2.5;
				color: #f7f7f7;
				margin-top: 48px;

				a {
					color: #f7f7f7;
				}
			}
		}

		@media (width: 768px) {
			.en-box {
				.row {
					.col-md-2 {
						width: 18.666667%;
					}

					div {
						&:nth-child(2) {
							width: 42%;
						}
					}

					.col-md-4 {
						width: 100%;
						margin-bottom: 40px;
					}
				}
			}

			.th-box {
				.row {
					.col-md-2 {
						width: 18.666667%;
					}

					div {
						&:nth-child(2) {
							width: 42%;
						}
					}

					.col-md-4 {
						width: 100%;
						margin-bottom: 40px;
					}
				}
			}

			.vi-box {
				.row {
					.col-md-2 {
						width: 18.666667%;
					}

					div {
						&:nth-child(2) {
							width: 42%;
						}
					}

					.col-md-4 {
						width: 100%;
						margin-bottom: 40px;
					}
				}
			}
		}
	}

</style>

