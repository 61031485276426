export default {
	"malaria-class-name": "cn-ubm-box",
	"malaria-page1": {
		"text1": "EBC集团支持联合国基<br>金会打败疟疾全球运动",
		"text2": "成为打败疟疾的一代 共创平等新世代",
	},
	"malaria-page2": {
		"text1": "疟疾：儿童健康最大威胁之一",
		"text2": "2022年，全球85个国家预估有2.49亿例疟疾病例和60.8万例疟疾死亡，其中76%以上是5岁以下的儿童。<br>几乎在您阅读这段文字的1分钟里，就有一名儿童死于疟疾。",
		"text3": "2.49<i>亿</i>",
		"text4": "感染",
		"text5": "60.8<i>万</i>",
		"text6": "死亡",
		"text7": "76%<i>+</i>",
		"text8": "儿童",
		"text9": "*数据来源：世界卫生组织《2023年世界疟疾报告》",
	},
	"malaria-page3": {
		"text1": "联合打败疟疾",
		"text2": "联合打败疟疾 是联合国基金会发起的全球性运动，汇聚世界各地的支持者，共同对抗疟疾。<br>EBC积极践行企业责任，全力支持联合国基金会打败疟疾全球运动。",
		"text3": "60<i>个</i>",
		"text4": "影响的国家",
		"text5": "4000<i>万</i>",
		"text6": "保护的人群",
		"text7": "183450<i>个</i>",
		"text8": "驱虫帐篷",
	},
	"malaria-page4": {
		"text1": "加入“打败疟疾5K”公益长跑",
		"text2": "2024 年 4 月 25 日至 5 月 5 日期间，与世界各地的支持者一起参加“打败疟疾”公益长跑提高人们对疟疾的认识并筹集资金，以支持疟疾救治项目和生命救援工具。EBC集团与联合国基金会携手，向志同道合者发出邀请，与我们一起共筑无疟疾世界。",
		"text3": "即刻加入",
	},
	"malaria-page5": {
		"text1": "我们在行动",
	},
}