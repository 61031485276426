export default {
    "box-name": "en-style2",
    "public-btn-text1": "Register Now",
    "public-btn-text2": "All Products",
    screen0: {
        "title": "CFD Dividend",
        "desc": "Please note'ollection of CFD dividend will be reflected in the balance on your MT4 account as a deposit or withdrawal, annotated as \"Dividend\".",
    },
    screen1: {
        "title": "Secure Transaction",
    },
    screen2: {
        "title": "The financial world is at your fingertips",
    }
}