export default {
	"security-class-name": "security-cn-box",
	"security-page1": {
		"text1": "يەر شارى بويىچە ئەڭ يۇقىرى دەرىجىلىك نازارەت قىلىپ باشقۇرۇش",
		"text2": "نومۇر تاختىسىنى تەكشۈرۈش",
	},
	"security-page2": {
		"text1": "ئومۇميۈزلۈك كاپالەت",
		"text2": "FSCS نىڭ ئەڭ يۇقىرى بولغاندا 85 مىڭ فوندستېرلىڭ تۆلەم كاپالىتىدىن بەھرىمەن بولىسىز",
		"text3": "FCA  EBC نىڭ ئەڭ يۇقىرى دەرىجىلىك تولۇق نازارەت قىلىپ باشقۇرۇش نومۇر تاختىسىغا ئىگە بولۇپ، پۈتۈن يەر شارىدا FCA كۆچمە ھېسابات ئىسچوتى ئاچالايدىغان ئىنتايىن ئاز ساندىكى ئاكسىيە سودىگىرىدىن بىرى بولۇپ، ھەر بىر FCA ئابۇنىچىسىنى ئەڭ يۇقىرى بولغاندا 85 مىڭ فوندستېرلىڭلىق تۆلەم كاپالىتى بىلەن تەمىنلەيدۇ. قۇرۇلغاندىن تارتىپ ھازىرغىچە، FSCS تۆلەم ئاساسىي سانىنى ئۈزلۈكسىز ئۆستۈرۈپ، ھەر يىلى 500-600 مىليون فوندستېرلىڭ تۆلەم تۆلەشنى مۆلچەرلەپ، 5 مىليارد 500 مىليون فوندستېرلىڭدىن ئارتۇق تۆلەم تۆلىدى.",
		"text4": "باركلاي بانكىسىنىڭ ئەڭ يۇقىرى دەرىجىلىك شىركەت بانكا ھېساباتى Corporate Banking Account غا ئىگە",
		"text5": "EBC باركلېي بانكىسىنىڭ ئەڭ يۇقىرى دەرىجىلىك كارخانا ئىسچوتىغا ئىگە، بۇ ئىسچوت كارخانىنىڭ تىجارەت سوممىسى ۋە ئامانەت پۇلىنىڭ 6 مىليون 500 مىڭ فوندستېرلىڭدىن يۇقىرى بولۇشىنى تەلەپ قىلىدۇ، ئۇنىڭ ئۈستىگە چوقۇم باركلېي بىر قاتار قاتتىق مالىيە ئىقتىسادىي تەپتىشلىكى ۋە ئارقا كۆرۈنۈش تەكشۈرۈشىدىن ئۆتۈشى كېرەك. EBC ئەنگلىيە CASS بەلگىلىمىسىنى قاتتىق ئىجرا قىلىپ، ئالاھىدە ھاۋالە خېتى ئارقىلىق مەبلەغ سالغۇچىلارنىڭ مەبلىغىنى مۇستەقىل ۋاكالىتەن باشقۇرۇپ، مەبلەغ بىخەتەرلىكى ۋە مۇستەقىللىقىغا كاپالەتلىك قىلغان.",
		"text6": "20 مىڭ ياۋروغىچە قوشۇمچە تۆلەم كاپالىتى بىلەن تەمىنلەيدۇ",
		"text7": "The Financial Commission دۇنيادىكى تۇنجى مۇستەقىل تاشقى تالاش-تارتىشنى ھەل قىلىش ئورگىنى بولۇش سۈپىتى بىلەن، ئۇ قاتتىق، ئوچۇق ۋە ئوچۇق سوت مېخانىزمى ئارقىلىق سودا تالاش-تارتىشلىرىنىڭ تېز ۋە ئادىل ھەل قىلىنىشىغا كاپالەتلىك قىلىدۇ. ھازىرغا قەدەر 51 مىليون دوللاردىن ئارتۇق تۆلەم تۆلەندى ۋە 10 مىڭغا يېقىن تالاش-تارتىش بىر تەرەپ قىلىندى. EBC تۆلەم فوندىغا قوشۇلۇپ، ھەر بىر ھەمكارلاشقۇچىنى ئەڭ يۇقىرى بولغاندا 20 مىڭ ياۋرولۇق قوشۇمچە تۆلەم كاپالىتى بىلەن تەمىنلەيدۇ.",
		"text8": "ئون مىليون دوللارلىق كەسپىي جاۋابكارلىق سۇغۇرتىسى",
		"text9": "EBC ھەريىلى ئەنگىليە لويىدوف لوندون (Lloyds of London) بىلەن ئامرىكا ئەيئەن گۇروھى (AON) دىن سوممىسى 10 مىليون ئامرىكا دوللىرىدىن ئاشىدىغان كەسپى مەسئۇلىيەت سۇغۇرتىسىنى سېتىۋالىدۇ. سۇغۇرتىلانغۇچى مۇلازىمەت مەزگىلىدە سەل قاراش ياكى خاتالىق سەۋەبىدىن كېلىپ چىققان خېرىدارلارنىڭ زىيىنى ۋە تەلەپلىرى سۇغۇرتا شىركىتى تەرىپىدىن تولۇق تۆلىنىدۇ. شۇنىڭ بىلەن بىر ۋاقىتتا، سېتىپ بېرىش مەزگىلىدە، بارلىق خېرىدارلار سۇغۇرتا مۇتەخەسسىسلىرى تەمىنلىگەن ئۈزلۈكسىز ۋە كەسپىي پروگراممىلار تەرىپىدىن قوغدىلىدۇ.",
		"text10": "كۆپرەك ئۆگىنىڭ",
	},
}