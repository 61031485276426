export default {
    "box-class-name": "en-no1-box kr",
    "lang": "ko",
    "挑战最强交易环境": "최강 교역 환경에 도전",
    "利润差额奖金": "프로핏차액상여금",
    "EBC的最强交易环境等您挑战": "EBC의 최강 거래환경이 당신을 기다리고 있습니다! 도전 성공 시 150%보너스차액금 획득하실수 있으며 상한가는 없습니다.",
    "距挑战结束剩余": "남은 도전 시간",
    "天": "일",
    "时": "시",
    "分": "분",
    "秒": "초",
    "本次活动时间为2024年1月1日": "이벤트 시간: 1/1/2024 00:00:00 - 30/9/2024 23:59:59（GMT+2）",
    "发起挑战": "바로 도전",
    "挑战规则": "챌린지 규칙",
    "EBC致力于为投资者提供极低交易成本": "EBC는 전문 트레이더에게 매우 저렴한 거래 비용, 고품질 시장 깊이 및 효율적인 주문 실행을 제공하기 위해 최선을 다하고 있으며, 거래 환경을 테스트하고 개선하기 위해 Ultimate Trading Challenge를 시작합니다.",
    "开设账户": "계좌 개설",
    "在EBC平台开设一个交易账户": "EBC Trading Platform에서 계좌 개설하기.",
    "选择平台": "플랫폼 선택",
    "选择一家具备FCA或ASIC监管资质的交易商平台": "FCA 또는 ASIC에서 규제하는 거래 플랫폼 선택",
    "相同策略交易": "동일전략거래",
    "使用相同的交易策略在EBC和所选平台进行交易": "EBC와 선택한 플랫폼 모두에서 동일한 교역전략으로 거래하세요.",
    "收益比较": "프로핏 비교",
    "在EBC的盈利低于或亏损高于所选择交易商": "선택한 브로커에 비해 EBC에서 수익이 낮거나 손실이 큰 경우 도전은 성공적인 것으로 간주됩니다.",
    "卓越的交易环境": "탁월한 거래 환경",
    "EBC以一流的交易执行速度和先进的技术支持": "EBC는 최고 수준의 실행 속도와 첨단 기술로 투자자들의 높은 기준을 충족시켜 탁월한 거래 경험을 제공합니다.",
    "科技赋能交易": "기술력이 향상된 거래",
    "部署于伦敦LD4": "런던 LD4, 뉴욕 NY4, 싱가포르 SG1, 도쿄 TY3 및 홍콩 HK2에 위치한 독립 서버는 초고속 전용 회선을 통해 연결되어 있습니다. 초저지연 집계, 스마트 주문 라우팅 및 견적 최적화 엔진을 제공하여 글로벌 초고속 금융 생태계를 조성합니다.",
    "让85%以上的订单成交于更优的价格": "85%이상의 주문서를 더 좋은 가격에 거래할 수 있도록 합니다.",
    "EBC交易黑盒": "EBC 거래 블랙박스, 가격 최적화율, 주문 거래율, 알고리즘 안정성 측면에서 EBC 거래 블랙박스 거래 시스템보다 훨씬 우수합니다.",
    "EBC交易空间": "EBC거래공간",
    "无论您是高频交易者": "당신이 고주파 거래자이든 대량 거래자이든 상관없이, EBC의 Private Room은 유동성 매칭을 기반으로 당신만의 거래 채널을 구축하고 심지어 당신의 거래 정보를 일부 숨겨서 당신의 \"카드\"를 보호합니다. 이것은 당신의 주문이 더 나은 집계 견적을 얻을 수 있을 뿐만 아니라 거래 과정에서도 저격을 피할 수 있음을 의미합니다.",
    "国际顶级流动性": "국제 최고 유동성",
    "EBC与多家国际顶级银行建立了长期稳定的合作关系": "EBC는 여러 국제 최고 은행과 장기적이고 안정적인 협력 관계를 구축하고 여러 고품질 유동성 제안을 통합하며 경쟁력 있는 시장 격차를 제공합니다.",
    "活动须知": "활동 주의 사항",
    "参与账户": "참여계좌",
    "新用户": "신규 사용자: 공식 웹사이트에서 계정을 새로 만든 후 온라인 고객 서비스에 연락하여 VIP 계정을 개설하여 도전에 참여합니다.",
    "现有用户": "기존 이용자 : 온라인 고객센터에 연락하여 VIP 계정 개설 후 도전 참여합니다.",
    "对比交易商平台要求": "딜러 플랫폼 요구사항",
    "选择持有英国FCA或澳洲ASIC监管的交易商平台": "영국 FCA 또는 호주 ASIC의 감독을 받는 딜러 플랫폼을 선택합니다. AR, EEA 등록 또는 Trading Name 계정은 포함되지 않습니다.",
    "交易策略": "거래전략",
    "仅限以下主流货币对": "다음 주류 화폐는 AUD/USD, USB/CAD, EUR/USD/USD/USD/USD/PY, NZPY, NZ/JPY, USB",
    "在EBC和所选交易商使用相同的交易资金和交易策略": "EBC와 함께 거래상자금과 거래 전략을 사용하여 모든 전략은 정상 거래 범위에 있어야 합니다.",
    "奖金结算": "보너스결산",
    "挑战者在其他平台的交易返佣可计入盈利": "다른 플랫폼에서 도전자의 거래 수수료는 수익에 포함될 수 있지만 해당 인증 정보를 제공해야 합니다.",
    "挑战者可在任意挑战时间内选择结算": "도전자는 임의의 도전 시간 내에 결제를 선택할 수 있으며, 각 신분증은 한 번만 참여할 수 있습니다.",
    "挑战结束后": "도전이 끝나면 청산된 인도장을 제출합니다. 인센티브는 심사 후 영업일 기준 2일 이내에 거래 계좌로 지급되며 거래 또는 현금 인출에 사용할 수 있습니다.",
    "重要声明": "중요 참고 사항",
    "参赛者应知晓交易风险": "참가자는 거래 위험, 규칙, 권익 및 플랫폼에서 거래되는 제품의 거래 규칙을 알고 있어야 하며, 불가항력으로 인한 거래 문제 및 계정 손실은 EBC에서 처리하지 않습니다.",
    "任何不遵守比赛规则或篡改数据的行为": "EBC는 경기 규칙을 준수하지 않거나 데이터를 조작하는 모든 행위에 대해 참가 자격을 취소하고 법적 책임을 물을 권리를 보유합니다.",
    "EBC有权要求参赛者提供有争议的订单或账户的补充材料": "EBC는 참가자에게 분쟁 중인 주문서 또는 계정에 대한 보충 자료를 요청할 권리가 있습니다.",
    "本次挑战赛旨在提供公平": "이번 챌린지는 공정하고 공정한 환경을 제공하기 위한 것으로 EBC는 경기 규칙을 개정, 면제 또는 해석할 권리를 보유합니다.",
    "EBC有权根据法律法规": "EBC는 법률 및 규정, 사법적 또는 행정적 명령에 따라 활동을 취소하거나 종료할 권리가 있습니다.",
    "本活动最终解释权归EBC所有":"본 행사의 최종 해석권은 EBC에게 있습니다."

}