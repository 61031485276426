export default {
    "box-class-name": "en-no1-box ug",
    "lang": "vi",
    "挑战最强交易环境": "ئەڭ ياخشى سودا مۇھىتىغا جەڭ ئېلان قىلىش",
    "利润差额奖金": "پايدا نىسبىتى رىغبەتلەندۈرۈش",
    "EBC的最强交易环境等您挑战": `EBC نىڭ ئەڭ يۇقىرى سودا مۇھىتىدا ئۆزىڭىزگە جەڭ ئېلان قىلىڭ! % 150 پايدا نىسبىتىگە
						ئېرىشىش ئۈچۈن رىقابەتنى مۇۋەپپەقىيەتلىك تاماملاڭ ، چەكلىمىسى يوق.`,
    "距挑战结束剩余": "رىقابەتنىڭ ۋاقتى",
    "天": "D",
    "时": "H",
    "分": "M",
    "秒": "S",
    "本次活动时间为2024年1月1日": `وقت الحدث هو: 1/1/2024 00:00:00 - 30/9/2024 23:59:59（GMT+2）`,
    "发起挑战": "بىر خىرىسنى باشلاش",
    "挑战规则": "رىقابەت قائىدىسى",
    "EBC致力于为投资者提供极低交易成本": `EBC سودىگەرلەرنى ئىنتايىن تۆۋەن سودا تەننەرخى ، ئەلا سۈپەتلىك بازار چوڭقۇرلۇقى ۋە ئۈنۈملۈك زاكاز ئىجرا قىلىش بىلەن تەمىنلەيدۇ ، سودا مۇھىتىمىزنى سىناش ۋە ياخشىلاش ئۈچۈن ، بىز ئاخىرقى سودا رىقابىتىنى يولغا قويدۇق.`,
    "开设账户": "ھېسابات تىزىملىتىڭ",
    "在EBC平台开设一个交易账户": "EBC سودا سۇپىسىدا ھېسابات تىزىملىتىڭ",
    "选择平台": "سۇپىڭىزنى تاللاڭ",
    "选择一家具备FCA或ASIC监管资质的交易商平台": "FCA ياكى ASIC تەرىپىدىن تەڭشەلگەن سودا سۇپىسىنى تاللاڭ",
    "相同策略交易": "ئوخشاش ئىستراتېگىيىلىك سودا",
    "使用相同的交易策略在EBC和所选平台进行交易": `EBC ۋە سىز تاللىغان سۇپىدا ئوخشاش ئىستراتېگىيىدە سودا قىلىڭ.`,
    "收益比较": "پايدا سېلىشتۇرۇش",
    "在EBC的盈利低于或亏损高于所选择交易商": `ئەگەر تاللانغان ۋاسىتىچىگە سېلىشتۇرغاندا ، EBC دىكى پايدىڭىز تۆۋەن بولسا ياكى زىيىنىڭىز يۇقىرى بولسا ، رىقابەت مۇۋەپپەقىيەتلىك دەپ قارىلىدۇ.`,
    "卓越的交易环境": "ئالاھىدە سودا مۇھىتى",
    "EBC以一流的交易执行速度和先进的技术支持": `EBC يۇقىرى دەرىجىلىك ئىجرا قىلىش سۈرئىتى ۋە ئىلغار تېخنىكا بىلەن ئالاھىدە سودا تەجرىبىسى بىلەن تەمىنلەيدۇ ، مەبلەغ سالغۇچىلارنىڭ يۇقىرى ئۆلچىمىگە يېتىدۇ.`,
    "科技赋能交易": "تېخنىكا كۈچەيتىلگەن سودا",
    "部署于伦敦LD4": "لوندون LD4 ، نيۇ-يورك NY4 ، سىنگاپور SG1 ، توكيو TY3 ۋە شياڭگاڭ HK2 غا جايلاشقان مۇستەقىل مۇلازىمېتىرلىرىمىز دەرىجىدىن تاشقىرى تېز سۈرئەتلىك توپلاش ، ئەقلىي ئىقتىدارلىق زاكاز لىنىيىسى ۋە نەقىل ئەلالاشتۇرۇش ماتورلىرى بىلەن تەمىنلەيدۇ. دۇنياۋى يۇقىرى سۈرئەتلىك پۇل-مۇئامىلە ئېكولوگىيە سىستېمىسى بەرپا قىلىش.",
    "让85%以上的订单成交于更优的价格": "% 85 تىن ئارتۇق زاكازنىڭ ياخشى باھاسىغا ئېرىشىڭ",
    "EBC交易黑盒": "EBC نىڭ سودا قارا قۇتىسى زاكاز ھېسابلاش ئۇسۇلىنى ئېنىق ئەلالاشتۇرۇپ ، باھانى ئەلالاشتۇرۇش ، زاكاز ئىجرا قىلىش نىسبىتى ۋە ھېسابلاش ئۇسۇلىنىڭ مۇقىملىقىدىكى ئەنئەنىۋى سىستېمىدىن ئېشىپ كەتتى.",
    "EBC交易空间": "EBC سودا بوشلۇقى",
    "无论您是高频交易者": `مەيلى سىز يۇقىرى چاستوتىلىق سودىگەر بولۇڭ ياكى چوڭ زاكازلارنى بىر تەرەپ قىلىڭ ، EBC نىڭ شەخسىي ئۆيى ئوبوروت ماسلاشتۇرۇلغان مەخسۇس سودا قانىلى بىلەن تەمىنلەيدۇ ، ھەتتا سىزنىڭ ئىستراتېگىيىڭىزنى قوغداش ئۈچۈن سودىڭىزنىڭ بىر قىسمىنى يوشۇرالايسىز ئىجرا جەريانىدا يوشۇرۇن نىشاندىن ساقلىنىدۇ.`,
    "国际顶级流动性": "يەرشارىدىكى ئەڭ يۇقىرى دەرىجىدىكى سۇيۇقلۇق",
    "EBC与多家国际顶级银行建立了长期稳定的合作关系": "EBC نۇرغۇنلىغان ئالدىنقى قاتاردىكى خەلقئارالىق بانكىلار بىلەن ئۇزۇن مۇددەتلىك ، مۇقىم ھەمكارلىق ئورنىتىپ ، ئەلا سۈپەتلىك ئوبوروت باھاسىنى بىرلەشتۈرۈپ ، رىقابەت كۈچى كۈچلۈك بازارنىڭ تارقىلىشىنى تەمىنلەيدۇ.",
    "活动须知": "پائالىيەت قوللانمىسى",
    "参与账户": "لاياقەتلىك ھېسابات",
    "新用户": "يېڭى ئابونتلار: ئورگان تورىدا يېڭى ھېسابات ئېچىڭ ، ئاندىن توردىكى قوللاش بىلەن ئالاقىلىشىپ ، VIP ھېساباتىنى قوزغىتىپ رىقابەتكە قاتنىشىڭ.",
    "现有用户": "مەۋجۇت ئىشلەتكۈچىلەر: VIP ھېساباتىڭىزنى ئاكتىپلاش ۋە رىقابەتكە قاتنىشىش ئۈچۈن EBC قوللىشى بىلەن ئالاقىلىشىڭ.",
    "对比交易商平台要求": "سۇپا تەلىپى",
    "选择持有英国FCA或澳洲ASIC监管的交易商平台": "ئەنگىلىيە FCA ياكى ئاۋىستىرالىيە ASIC تەرىپىدىن باشقۇرۇلىدىغان سودا سۇپىسىنى تاللاڭ ، AR ، EEA ياكى سودا نامىدا تىزىملاتقان ھېساباتلار لاياقەتلىك ئەمەس.",
    "交易策略": "سودا ئىستراتېگىيىسى",
    "仅限以下主流货币对": "پەقەت تۆۋەندىكى ئاساسلىق پۇل جۈپلىرىگە رۇخسەت قىلىنىدۇ: AUD / USD ، USD / CAD ، EUR / USD ، USD / CHF ، GBP / USD ، USD / JPY ۋە NZD / USD.",
    "在EBC和所选交易商使用相同的交易资金和交易策略": "EBC ۋە تاللانغان ۋاسىتىچىگە ئوخشاش سودا مەبلىغى ۋە ئىستراتېگىيىسىنى ئىشلىتىڭ بارلىق ئىستراتېگىيەلەر چوقۇم نورمال سودا ئادىتى دائىرىسىگە كىرىشى كېرەك.",
    "奖金结算": "مۇكاپات سوممىسى",
    "挑战者在其他平台的交易返佣可计入盈利": "باشقا سۇپىلاردىكى ئېتىبارنى پايدا دەپ قاراشقا بولىدۇ ، ئەمما رىقابەتچىلەر چوقۇم مۇناسىپ ئىسپات بىلەن تەمىنلىشى كېرەك.",
    "挑战者可在任意挑战时间内选择结算": "رىقابەتچىلەر رىقابەت جەريانىدا خالىغان ۋاقىتتا ھەل قىلىشنى تاللىيالايدۇ ، ھەر بىر كىملىك ​​پەقەت بىرلا قېتىم قاتنىشىدۇ.",
    "挑战结束后": "رىقابەت ئاخىرلاشقاندىن كېيىن ، يېپىلىش راسچوتىنى تاپشۇرۇڭ. مۇكاپات تەكشۈرۈلگەندىن كېيىنكى ئىككى خىزمەت كۈنى ئىچىدە سودا ھېساباتىڭىزغا كىرىدۇ ، سودا ياكى چېكىنىشكە ئىشلىتىشكە بولىدۇ.",
    "重要声明": "مۇھىم خاتىرىلەر",
    "参赛者应知晓交易风险": "قاتناشقۇچىلار سۇپىدىكى سودا خەۋىپى ، قائىدىسى ، ھوقۇقى ۋە مەھسۇلات سودا قائىدىسىنى بىلىشى كېرەك. EBC ھېچقانداق تاقابىل تۇرغىلى بولمايدىغان سودا مەسىلىسى ياكى ھېسابات زىيىنىغا مەسئۇل ئەمەس.",
    "任何不遵守比赛规则或篡改数据的行为": "EBC رىقابەت قائىدىسىگە خىلاپلىق قىلغان ياكى سانلىق مەلۇماتقا دەخلى-تەرۇز قىلغانلار قاتناشقۇچىلارنى لاياقەتسىز قىلىش ھوقۇقىغا ئىگە ھەمدە قانۇنىي ھەرىكەت قوللانسا بولىدۇ.",
    "EBC有权要求参赛者提供有争议的订单或账户的补充材料": "EBC تالاش-تارتىشتىكى زاكاز ياكى ھېسابات ئۈچۈن قوشۇمچە ماتېرىيال تەلەپ قىلسا بولىدۇ.",
    "本次挑战赛旨在提供公平": "بۇ رىقابەت ئادىل ۋە ئادىل مۇھىت بىلەن تەمىنلەشنى مەقسەت قىلىدۇ. EBC رىقابەت قائىدىسىگە تۈزىتىش كىرگۈزۈش ، كەچۈرۈم قىلىش ياكى چۈشەندۈرۈش ھوقۇقىغا ئىگە.",
    "EBC有权根据法律法规": "EBC قانۇن ، نىزام ، ئەدلىيە ياكى مەمۇرىي بۇيرۇقلارغا ئاساسەن پائالىيەتنى بىكار قىلىش ياكى ئاخىرلاشتۇرۇشقا ھوقۇقلۇق.",
    "本活动最终解释权归EBC所有": "بۇ پائالىيەتنىڭ ئاخىرقى چۈشەندۈرۈشى EBC غا تەۋە."

}