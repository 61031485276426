export default {
  "screen0": {
    "title": "Pure Trading Pure Power",
    "subTitle": "Trading is proving exceedingly hard, perhaps a final push is needed.Unparalleled <br/>market access level.Ultra-low latency IT infrastructure"
  },
  "screen1": {
    "line1": "Order Execution Time as low as",
    "line2": "Reliable Data Transmission Success Rate",
    "line3": "Dedicated Fiber Optic Network At Least",
    "line4": "Keep pace with the world"
  },
  "screen2": {
    "title": "Technology empowers trading",
    "desc": "EBC dedicated servers are set up in Equinix LD4, HK3, TY3, NY4 to build worldwide coverage for the financial ecosystem.",
    "title1": "Technology empowers trading, EBC within 20ms of each transaction",
    "desc1": "Hyper-converged infrastructure brings up to 98.75% system stability and availability based on software-defined computing, storage capacity and network.",
    "desc2": "<p> In today's financial market, speed means profits, and agility is essential for the profitable transactions. </p><p> We established transaction servers in the world-leading data center Equinix LD4, HK3, TY3, NY4. Our global ultra-high-speed dedicated connection minimizes interregional delays, so that our customers would enjoy a more stable trading environment.</ p><p> Firewall restriction and congested international network result in a low-quality trading experience. We equipped nearly 30 DCs (including disaster recovery servers) ,and all the servers in Europe and Asia are connected through a dedicated financial line with peer-to-peer networking. </p><p> Compared with traditional servers in Europe and Asia, the delay in EBC from London to Asia is controlled within 100ms, while the transmission time from London to Asia offered by traditional brokers is over 270ms, which is undoubtedly most beneficial to the customers in Asia. </p>"
  },
  "screen3": {
    "title": "We are acutely aware that the key to success is the entire situation, not limited to software technology. ",
    "desc": "We ensure the best liquidity in the trading market, so that you may achieve more flexibility, while obtaining more Multi–Asset Liquidity .",
    "plant": "",
    "btn": "lab Learning Center / trading platform"
  },
  "screen4": {
    "enTitle": "Driving You to Succeed",
    "title": "Professional and leading financial technology makes trading with infinite possibilities"
  },
  "screen5": {
    "plant": "",
    "btn": "lab Learning Center / trading products",
    "title": "Minimizing risks and maximizing trading opportunities ",
    "desc": "EBC's ultra-low delay aggregation, intelligent order routing and quotation optimization engine provide you with higher reliability and optimum order execution.  ",
    "list": [
      "Trading server",
      "Aggregated quotation",
      "Order transmission",
      "Liquidity pool"
    ],
    "link1": "View Trading Hours",
    "link2": "view spread and all products"
  },
  "screen6": {
    "title": "Focus on trading, and don't worry about IT infrastructure, operating system, network and hardware. We have considered what you concern about — optimum liquidity and excellent order execution . ",
    "desc": "According to the filled orders of the first 5 levels of depth, EBC only needs 75-125ms to complete the transaction"
  },
  "screen7": {
    "pageTitle": "Discover Prices & Transfer Orders",
    "pageEnTitle": "Discover Prices & Transfer Orders",
    "title": "EBC uses FIX (Financial Information Exchange Protocol) to aggregate interbank currency quotes to provide customers with direct access to the markets with optimum liquidity. The quotes aggregated through dedicated physical HUB hardware bring lower spreads and sustainable trading opportunities.",
    "desc": "",
    "imgUrl": "en"
  },
  "screen8": {
    "title": "Product spread and depth charts after aggregation by EBC bridge",
    "desc": "Quick Overview",
    "link1": "4 asset classes",
    "link2": "100 + trading varieties",
    "link3": "All products",
    "trList": [
      "hierarchy",
      "buying price",
      "trading volume bought ",
      "point difference",
      "selling price ",
      "selling price volume "
    ],
    "smallTrList": [
      "hierarchy",
      "buying price",
      "point difference",
      "selling price "
    ]
  },
  "screen9": {
    "title": "Trading Black-Box",
    "smallTitle": "Enabling more than 85% of orders filled at a better price",
    "desc": "EBC trading black box, based on tens of millions of historical trading data, traders'trading habits,user attributes and other portraits,the current market situation and trading network environment,uses large number statistical analysis,probability model,neural network model,system control theory model and artificial intelligence algorithm to conduct comprehensive research and judgment,and finally matches the LP that is more suitable for each trader's attributes, Achieve the best transaction price ",
    "link1": "View Trading hour",
    "link2": "view spread and all products",
    "imgUrl": "en"
  },
  "screen10": {
    "desc": "The following distribution diagram demonstrates the comparing results between EBC trading black box and traditional trading system (containing at least 100 orders): ",
    "list": [
      "each data point represents a day's transaction",
      "the horizontal axis indicates the transaction rate of the order",
      "the size of the point represents the relative size of the order quantity",
      "the vertical axis indicates the optimization rate of the transaction price"
    ],
    "btn": "Start Trading",
    "title": "This experience confirmed that EBC trading black-box is far superior to the traditional trading system in terms of price optimization rate, order transaction rate and algorithm stability."
  }
}


