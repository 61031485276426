export default {
	"fcb-class-name": "cn-fcb-box",
	"video-btn": "观看全视频",
	"fcb-page1": {
		"text1": "OFFICIAL PARTNER OF FC BARCELONA",
		"text2": "EBC成為巴塞隆納足球俱樂部金融領域獨家合作夥伴，合作範圍為外匯、交易、經紀（包括差價合約）和諮詢服務。",
		"text3": "",
	},
	"fcb-page2": {
		"text1": "為熱愛 全力以赴",
		"text2": "所有關乎熱愛的較量，絕非易事。<br>超乎數字、超越輸贏。<br>在推動改變的路上，我們並非孤軍奮戰。<br>以定義產業的標準，顛覆而來。<br>我們希望用知識、用技術<br>在目標開始的地方不忘初衷，全力以赴。",
	},
	"fcb-page3": {
		"text1": "不僅僅是一個俱樂部",
		"text2": "無論您是否為球迷，都知道，它是個傳奇。",
		"text3": "巴塞隆納足球俱樂部擁有120多年歷史,是世界上最受歡迎的足球俱樂部之一，見證了馬拉度納、梅西、羅納迪諾、瓜迪歐拉等多位世界級偉大球星以及教練，以華麗球風獨步足壇，成為「不僅僅是一個俱樂部」。",
		"text4": "而EBC，不止於交易，致力於建構全球金融生態綜合體，推動全球經濟發展。 ",
	},
	"fcb-page4": {
		"text1": "定義標竿",
		"text2": "群星閃耀的球隊、珍惜熱愛與夢想的訓練系統、見證傲人成就的獎盃及對社會的正面貢獻，讓巴塞隆納足球俱樂部樹立了標竿的行業地位",
		"text3": "EBC與巴薩的合作，見證雙方對彼此實力的認可，EBC堅守認真對待每一位交易愛好者的初衷，以全球頂級監管，全方位的保障體系，及廣受讚譽的國際影響力，定義行業標桿。",
	},
	"fcb-page5": {
		"text1": "以技術 創造“夢幻隊”",
		"text2": "巴薩以其獨特的傳控流聞名全球，基於控球、短傳和團隊協作的戰術證明了足球能以高技術和戰術智慧取勝，而非僅僅依賴身體對抗,影響了現代足球戰術演進。",
		"text3": "EBC在對科技的突破上亦有自身的執著，始終走在國際前沿，為投資人建構安全、專業、高效穩定的全球交易生態。"
	},
	"fcb-page6": {
		"text1": "成為最好的 讓世界變更好",
		"text2": "尊重、努力、雄心壯志、團隊合作和謙遜是巴薩五大價值觀,其始終倡導努力與宏遠的價值觀和推動球員成為最好的精神，與EBC的價值觀不謀而合。",
		"text3": "EBC始終秉持誠信與尊重的原則，珍惜每位交易愛好者的熱愛與期待，傾力透明產業資訊，推動產業發展，共建平等社會。 ",
		"text4": "Together, Let's Build The Future.",
	},
	"fcb-page7": {
		"text1": "相關動態",
	}
}