export default {
	"headlist": [{
		"text": "หน้าหลัก",
		"url": "/"
	}, {
		"text": "การซื้อขาย",
		"tips": "",
		"img": "11",
		"menu": [{
			"tit": "บัญชีและเงื่อนไข",
			"list": [ {
				"text": "บัญชีธุรกรรม",
				"url": "/TradingAccount"
			}, {
				"text": "ซื้อขายสินค้า",
				"url": "/tradingproduct"
			}, {
				"text": "เลเวอเรจมาร์จิ้น",
				"url": "/leverage-margin"
			},{
				"text": "ฝากและถอนเงิน",
				"url": "/funding"
			}, {
				"text": "เงินปันผล",
				"url": "/dividend"
			}]
		},  {
			"tit": "เครื่องมือการซื้อขาย",
			"list": [{
				"text": "ดาวน์โหลด MT4",
				"url": "/MT4"
			},{
				"text": "ดาวน์โหลด MT5",
				"url": "/MT5"
			},{
				"text": "เครื่องมือการซื้อขาย MT4",
				"url": "/tools"
			}, {
				"text": "PAMM",
				"url": "/PAMM"
			}, {
				"text": "การวิเคราะห์ตลาด",
				"url": "/market-analysis"
			}]
		}]
	}, {
		"text": "EBCสถาบัน",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "ศูนย์การเรียนรู้",
				"url": "/LearningCenter"
			}, {
				"text": "ศูนย์ข้อมูล",
				"url": "/lab"
			},]
		}]
	}, {
		"text": "เหตุการณ์ล่าสุด",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "การแข่งขันการซื้อขาย",
				"url": "/win"
			},
			// {
			// 	"text": "ท้าทายกับสภาพแวดล้อมการเทรดที่ดีที่สุด",
			// 	"url": "/no1"
			// },
			{
				"text": "Creative Program",
				"url": "/cfa"
			}]
		}]
	}, {
		"text": "เกี่ยวกับ EBC",
		"tips": "",
		"img": "55",
		"menu": [ {
			"tit": "เกี่ยวกับ EBC",
			"list": [{
				"text": "WHY EBC",
				"url": "/about-us"
			},{
				"text": "Sustainability & Impact",
				"url": "/ESG"
			}, {
				"text": "FAQ",
				"url": "/FAQ"
			}]
		},{
			"tit": "โหมดความร่วมมือ",
			"list": [{
				"text": "อิทธิพลที่มีต่อพันธมิตร",
				"url": "/affiliate-programmes"
			},{
				"text": "บริการสถาบัน",
				"url": "/service"
			},{
				"text": "เทคโนโลยีพื้นฐาน",
				"url": "/technology"
			},
			]
		}]
	}],
	"FCA": "บัญชี FCA",
	"login": "เข้าสู่ระบบ",
	"zhuce": "เปิดบัญชีฟรี ",
	"from0tohero_text": "Copy Trade",
	"from0tohero_url": "/from0tohero",
	"faq_url": "/FAQ",
}

