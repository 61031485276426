export default {
    "box-name": "",
    "public-btn-text": "即刻开户",
    screen0: {
        title: 'EBC为机构投资者<br>提供全球顶级流动性',
        enTitle: 'EBC THE FUTURE',
        list: [
            {
                title: '安全性',
                desc: '通过对等网络的光纤专线链接全球Equinix，实现20毫秒的极速订单执行。'
            },
            {
                title: '合规性',
                desc: '所有客户的资金托管在世界顶级银行，受到严格监管。'
            },
            {
                title: '流动性',
                desc: 'EBC与多家国际顶级银行建立了长期稳定的合作关系，让您轻松享有超低交易成本。'
            }
        ]
    },
    screen1: {
        title: '流动性深度',
        trList: [
            '层级',
            '买入价',
            '买入的交易量',
            '点差',
            '卖出价',
            '卖出价交易量'
        ],
        mobileTrList: [
            '层级',
            '买入价',
            '点差',
            '卖出价'
        ]
    },
    screen2: {
        pageEnTitle: 'Clearing System',
        pageTitle: '清算系统',
        title: 'EBC 安全、透明、可信的清算生态系统'
    },
    screen3: {
        pageEnTitle: 'EBC Priviate Room',
        pageTitle: 'EBC交易空间',
        title: '“清算不是统一的标准，而是适合所有情况的所有答案”',
        desc: '如果您是一位“大交易家”，您不想在市场上让别人知道您的“底牌”，EBC的 Private Room 计划将会是您最优之选。'
    },
    screen4: {
        title: '掌舵你的专属流动性<span>轻松驾驭全球市场</span>',
        desc: 'Private Room 可以隐藏您的部分交易信息，意味着您下的“大单”可以在一个更好的价格成交，而不存在严重的滑点或是因最后观望价格（LastLook）而遭到订单拒绝。如图所示，通过Private Room 交易的订单成交价格更优。'
    },
    screen5: {
        it: '唯有时间，让您，更了解我',
        ct: '*交易中可能会有滑点、有延迟有各种不确定性因素，或许资深交易人士的您不会注意到这些细微之处，但如有任何对您产生的不便，请及时反馈我们。EBC倡导高品质与极客精神，积极拥抱不确定性的未来。力求为客户带来极简、纯净、舒适的交易新体验。',
        title: '持续创造超预期的用户体验',
        desc: '我们积极倾听您的意见，并且非常重视增强您的个性化交易体验。EBC开发团队始终致力于研发和改进新功能新技术，以进一步优化客户的在线交易体验。',
        btn: '了解EBC优势',
    }
}