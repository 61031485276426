export default {
	"box-class-name": "en-no1-box",
	"lang": "jp",
	"button-text": "チャレンジを始めましょう",
	"no1-page2": {
		"text1": "毎秒最大1000回の注文締結が可能です",
		"text2": "20ミリ秒の超高速注文処理",
		"text3": "データ転送の安定性が最大98.75%まで維持されます",
		"text4": "銀行レベルのRAW ECNスプレッドで、0.0ピックの投資レベルを提供します",
	},
	"no1-page3": {
		"text1": "英国FCA監督",
		"text2": "EBC Financial Group (UK) Ltdは、英国の金融行動監督機関（FCA）の承認と監督を受けています<br><span>監督番号: 927552</span>",
		"text3": "オーストラリアASIC監督",
		"text4": "EBC Financial Group (Australia) Pty Ltdは、オーストラリア証券投資委員会（ASIC）の認可と監督を受けています<br><span>監督番号: 500991</span>",
	},
	"no1-page4": {
		"text1": "最強の取引環境<br>EBCは限界ではなく、挑戦の基準を提示します<br>チャレンジに成功したら<br>EBCは利益差額の150%の追加ボーナスを提供し、制限なく支給されます<br>まずは、チャレンジを始めましょう<br>EBCの強力な実力を証明させていただきます",
		"text2": "賞品:最大限の制限なしでリターンギャップの",
	},
	"no1-page5": {
		"text1": "<span>85<i>%</i></span><br>以上の注文が最良の価格で成約します",
		"text2": "EBCのブラックボックスは正確に最適化されたアルゴリズムであり、価格最適化、注文執行率とアルゴリズムの安定性の面で従来の取引システムより遥かに優れています",
	},
	"no1-page6": {
		"text1": "チャレンジのルール",
		"text2": "誠実さと尊重を原則として、最高基準に挑戦します",
		"text3": "入金要件",
		"text4": "プラットフォ<br>ームの選択",
		"text5": "同じ戦略",
		"text6": "チャレンジ成功時",
		"text7": "最低入金額なし",
		"text8": "英国FCA監督",
		"text9": "オーストラリアASIC監督",
		"text10": "選択された取引業者は、英国FCAまたはオーストラリアASICの監督認定を取得しています",
		"text11": "EBCおよび選択された取引業者で同じ取引資金および戦略を使用する必要があります",
		"text12": "EBCで他のプラットフォームより収益が低い場合、収益差額の150%を補償します。",
		"text13": "すべての参加者は無料でEBC VIPアカウントを申請することができます，EBCは無料で提供されるクロスプラットフォームのコピー取引アプリを提供します",
	},
	"no1-page7": {
		"text1": "挑戦の注意事項",
		"text2": "1.挑戦アカウント<br>a) 新規ユーザー：公式ウェブサイトで新規アカウントを開設し、オンラインカスタマーサービスに連絡してVIPアカウントを開設すると、チャレンジに参加できます。<br> b) 既存のユーザー：オンラインカスタマーサービスに連絡してVIPアカウントを開設し、チャレンジに参加してください。",
		"text3": "2.選択したプラットフォームは、英国FCAまたはオーストラリアASICの公式監督を受けている必要があります。AR、EEA登録またはトレーディングネーム口座は含まれません。",
		"text4": "3.以下の主要通貨ペアのみ - AUD/USD, USD/CAD, EUR/USD, USD/CHF, GBP/USD, USD/JPY, NZD/USD.",
		"text5": "4.EBCと選択したトレーダーで同じ取引資本と戦略を使用し、すべての戦略は正規の取引範囲内である必要があります",
		"text6": "5.挑戦者は任意の挑戦時間に決済することができ、、各IDカードは1回だけ参加することができます",
		"text7": "6. チャレンジ終了後、決済の納品書を提出してください。報酬は審査後、2営業日以内に取引口座に入金され、取引または出金に使用することができます。",
		"text8": "7.アカウントにリベートがある場合、お客様は適切な証拠資料を提出する必要があります。",
	},
	"no1-page8": {
		"text1": "キャンペーンは、後で終了します…",
		"text2": "チャレンジイベントは2024年1月18日00:00に正式に登録を開始し、締め切りは2024年3月1日23:59、GMT+2です",
		"text2-style": "",
	},
	"button1-style": "left: 2px;",
	"button2-style": "",
	"button3-style": "",
	"button4-style": "right: -7px;",
}