export default {
  "screen0": {
    "pageTitle": "Together",
    "enTitle1": "Together",
    "enTitle2": "Let's <text style='color:#800'>Build The Future.</text>",
    "title1": "Partner With Us To Unlock <br>",
    "title2": "More Opportunities In Markets ",
    "list": ["Trusted and regulated", "Global openness and cooperation", " A full suite of technical solutions "],
    "desc": "EBC is dedicated to developing an in-depth business relationship with partners. New ideas are welcome as we help open you up to new horizons with diversified support solution. We are here to unlock infinite possibilities in global business.",
    "links": [{
      "url": "/about-us",
      "text": "Why EBC?"
    }, {
      "url": "",
      "text": "learn more"
    }],
		"more": "Learn More"
  },
  "screen1": {
    "pageTitle": "Customization",
    "pageEnTitle": "Customization",
    "title": "Introducing Broker (IB) <span>Million Dollars</span> Support Program ",
    "desc": "As EBC cannot achieve long-term development without the support of IB，we provide a sound development prospects for powerful IB. ",
    "list": ["Commission structure", "Innovation marketing & trading resources ",
      "Efficient settlement and payment procedures", "Exclusive customer manager",
      "Multi-level commission system", "Multilingual customer support team ",
      "Perfect support and report tools", "Business development plan"
    ],
    "text": "All-round<span> customized </span> development plans",
    "more": "see more services"
  },
  "screen2": {
    "pageTitle": "Trading Service",
    "pageEnTitle": "Trading Service",
    "title": "A complete set of business development cooperation mode",
    "desc": "Please tell us your needs, and EBC will not only provide you with practical experience and suggestions but also provide customized technical solutions based on your demands to meet the requirements in different business development stages. ",
    "list": ["Trading community", "Trading signal", "Risk control management"],
    "btn": "Enter the Trading Community"
  },
  "screen3": {
    "pageTitle": "Liquidity Service",
    "pageEnTitle": "Liquidity service",
    "title": "EBC adopts Straight Through Processing (STP) model",
    "desc": "Get direct access to top-tier international liquidity providers to reduce broker hedging costs and ensure the quick execution of orders, and we also has a dedicated technical team to provide 24*5 technical support services.",
    "remark": "Only time enables you to know me better."
  },
  "screen4": {
    "pageTitle": "Emerging Business Models",
    "pageEnTitle": "Cooperation in emerging business models",
    "title": "Cooperation in emerging business models",
    "desc": "EBC always has the passion and ability to embrace changes.Facing the new development models in the industry, we are continuing to make innovations and changes since we believe that only by following the development trend in markets can we move forward steadily. If you have any innovative cooperation plans, please contact us. ",
    "list": ["<span>4</span> asset classes  ", "<span>100</span><sup>+</sup> trading instruments"]
  },
	"box-class": "partner-box-en"
}
