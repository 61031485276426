export default {
	"box-class-name": "oxford-box-en",
	"button-text": "كۆپرەك ئۆگىنىڭ",
	"oxford-page1": {
		"text1": "WHAT<br> ECONOMISTS<br> REALLY<br> DO",
		"text2": "»باجدىن قېچىش ئىقتىسادشۇناسلىقى «",
		"text3": "Sarah Clifford & David Barrett",
		"text4": "دەرھال ئالدىن كېلىشىش ",
		"text5": "2024يىلى -3ئاينىڭ -6كۈنى سائەت 12:00 دە . ",
	},
	"oxford-page2": {
		"text1": "كەلگۈسىگە يۈزلەنگەن مائارىپ ئارقىلىق ، يەر شارىۋى ئىقتىسادنىڭ تەرەققىياتىغا تۈرتكە بولۇش  .  ",
		"text2": "EBCپۇل مۇئامىلە گۇرۇھى ئوكسفورد داشۆسى ئىقتىسادشۇناسلىق فاكۇلتېتى بىلەن بىرلىشىپ «ئىقتىسادشۇناسلار نېمە ئىشلارنى قىلدى»تېمىسىدىكى مۇھاكىمە يىغىنى ئۆتكۈزۈپ،  ESG ئىدىيەسىنى پائال ئەمەلىيەتتە كۆرسىتىپ، بىلىمنى تۈرتكە قىلغان جەمئىيەتنىڭ ئالغا بېسىپ تەرەققىي قىلىشىغا تۈرتكە قىلىش .  ",
		"text3": "EBCپۇل مۇئامىلە گۇرۇھى ئوكسفورد داشۆسى بىلەن ھەمكار قىلىپ، 2024-يىلى 3-ئاينىڭ 6-كۈنى بىرلىشىپ «ئىقتىسادشۇناسلار نېمە ئىشلارنى قىلدى»دېگەن تېمىدا مۇھاكىمە يىغىنى ئۆتكۈزدى. ئوكسفورد داشۆسى نوپۇزلۇق ئىلمىي تەتقىقات، يەر شارىۋى سىياسەتكە قوشقان كۆرۈنەرلىك تۆھپىسى قاتارلىقلار بىلەن دۇنياغا تونۇلغان. ئۇنىڭ ئىقتىسادشۇناسلىق شىى يېڭىلىق يارىتىلغان ئىقتىساد تەتقىقاتى ئارقىلىق، ئاممىۋى سىياسەتنىڭ ياخشىلىنىشى ۋە يەر شارىۋى ئىقتىسادنىڭ تەرەققىياتىغا تۈرتكە بولۇشقا كۈچ چىقىرىدۇ. ئوكسفورد داشۆسى EBC نىڭ ESG ئىدىيەسىنى ئەمەللەشتۈرىشتىكى گۇۋاھچىسىدۇر.  EBCكارخانا مەسئۇلىيىتىنى پائال ئۈستىگە ئېلىپ، ئۇزاق مۇددەت مائارىپ ۋە ئىختىساسلىقلارنى يېتىشتۈرۈشكە كۈچ چىقىرىپ، ئىنسانىيەت جەمئىيىتىنىڭ تەرەققىياتى ۋە تەرەققىياتىغا تۈرتكە بولدى. بىزگە قوشۇلۇڭ، تېخىمۇ گۈزەل، باراۋەر كەلگۈسىنى ئورتاق يارىتىمىز. ",
		"text4": "",
		"text5": "مۇھاكىمە يىغىنىنى تونۇشتۇرۇش  ",
		"text6": "«ئىقتىسادشۇناسلار زادى نېمە ئىش قىلىۋاتىدۇ»دېگەن تېمىغا بىرلەشتۈرۈپ، قانداق قىلغاندا ئىقتىسادشۇناسلىقتىن پايدىلىنىپ سىياسەتنىڭ ياخشىلىنىشىغا تۈرتكە بولۇپ، ئىقتىسادنىڭ گۈللىنىشىنى ئىلگىرى سۈرگىلى بولىدىغانلىقى ئۈستىدە ئىزدىنىلدى. شۇ چاغدا «باجدىن قېچىش ئىقتىسادشۇناسلىقى»نى چوڭقۇر مۇھاكىمە قىلىپ، ئوخشاش بولمىغان باج مۇھىتى كەلتۈرۈپ چىقارغان ئىقتىسادىي يۈزلىنىش ۋە ئىقتىسادشۇناسلارنىڭ يەر شارىۋى ئىقتىسادىي بىخەتەرلىكتە ئوينىغان رولى قاتارلىقلارنى ئومۇميۈزلۈك يېشىپ چۈشەندۈرىمىز. ",
	},
	"oxford-page3": {
		"text1": "قاتناشقان ئەزىز مېھمانلار  ",
		"text2": "ئوكسفورد داشۆسى ئىقتىسادشۇناسلىق  پىروفېسسورى  ",
		"text3": "كەسپىي ساھە ئاممىۋى ئىقتىسادشۇناسلىق ۋە ئەمەلىي مىكرو ئىقتىسادشۇناسلىقنى ئۆز ئىچىگە ئالىدۇ. ئىلمىي تەتقىقات دىققەتى باجغا مۇناسىۋەتلىك ئىقتىسادىي ھەرىكەتلەرگە، بولۇپمۇ قانۇن ئىجرا قىلىش سالمىقىغا مەركەزلەشتۈرگەن. نۆۋەتتىكى خىزمەتى ھەم خەلقئارا شىركەتلەرنىڭ باج كىرىمى ۋە دۆلەت ھالقىپ باجدىن قېچىش قاتارلىقلارغا چېتىلىدۇ، ھەم ئائىلىلەرنىڭ شەخسىي تاپاۋەت بېجىدىن قېچىشنىمۇ ئۆز ئىچىگە ئالىدۇ. ",
		"text4": "ئىقتىساد پروفېسسورى uff Nuffield College ， مۇئاۋىن باشلىق (تاشقى مۇناسىۋەت)",
		"text5": "ابىنىڭ قوللىنىشچان ئىقتىساد تەتقىقاتىدا ، ئۇ دائىم ئىستېمالچىلار ۋە ئائىلە تاللاشلىرىنىڭ ئىقتىسادشۇناسلىقىغا ئەھمىيەت بەردى. ئۇنىڭ تەتقىقاتىنىڭ ئاساسلىق تېمىلىرى: تەجرىبە ئۇسۇللىرىنى تەرەققىي قىلدۇرۇش سانلىق مەلۇماتقا تەدبىر بەلگىلەشنىڭ يېڭى ئەندىزىسىنى بارلىققا كەلتۈرۈش ، چوڭ سانلىق مەلۇمات سانلىق مەلۇماتلىرىنى ئاشكارىلاپ ، ئەمگەك كۈچى بازىرىنى تېخىمۇ ياخشى چۈشىنىش ۋە ئەنگىلىيە قانۇن سىستېمىسىدىكى ئادالەتكە ئېرىشىشنى مىقدارلاشتۇرۇش رامكىسىنى تەرەققىي قىلدۇرۇش.",
		"text6": "CEO, EBC Financial Group (UK) Ltd.",
		"text7": "  «ئىقتىسادشۇناسلار زادى نېمە ئىش قىلىۋاتىدۇ»دېگەن تېمىغا بىرلەشتۈرۈپ، قانداق قىلغاندا ئىقتىسادشۇناسلىقتىن پايدىلىنىپ سىياسەتنىڭ ياخشىلىنىشىغا تۈرتكە بولۇپ، ئىقتىسادنىڭ گۈللىنىشىنى ئىلگىرى سۈرگىلى بولىدىغانلىقى ئۈستىدە ئىزدىنىلدى. شۇ چاغدا «باجدىن قېچىش ئىقتىسادشۇناسلىقى»نى چوڭقۇر مۇھاكىمە قىلىپ، ئوخشاش بولمىغان باج مۇھىتى كەلتۈرۈپ چىقارغان ئىقتىسادىي يۈزلىنىش ۋە ئىقتىسادشۇناسلارنىڭ يەر شارىۋى ئىقتىسادىي بىخەتەرلىكتە ئوينىغان رولى قاتارلىقلارنى ئومۇميۈزلۈك يېشىپ چۈشەندۈرىمىز. ",
	},
	"oxford-page4": {
		"text1": "EBC پۇل مۇئامىلە گۇرۇھى توغرىسىدا  ",
		"text2": "EBC Financial Groupئەنگلىيەنىڭ لوندوندا قۇرۇلغان بولۇپ، پۇل مۇئامىلە ئاكسىيە سودىگىرى، مۈلۈك باشقۇرۇش، كۆچمەنلەرگە مەبلەغ سېلىش قاتارلىق مۇلازىمەتلەر بىر گەۋدە قىلىنغان ئۇنىۋېرسال پۇل مۇئامىلە گۇرۇھى .  EBCكەسپى پۈتۈن يەر شارىغا تارقالغان بولۇپ، پۈتۈن يەر شارىدىكى ئەڭ يۇقىرى دەرىجىلىك نازارەت قىلىپ باشقۇرۇش، قىلچە سېكۇنت دەرىجىلىك زاكازنى ئىجرا قىلىش ۋە ئاپپارات دەرىجىلىك ھېسابات ئېنىقلاش كۆلچىكى، ھەر تەرەپلىمە كاپالەت سىستېمىسى ئارقىلىق، ھەربىر خېرىدارنى «بىخەتەر، كەسپىي، يۇقىرى ئۈنۈملۈك، مۇقىم » سودا مۇھىتى بىلەن تەمىنلەشكە كۈچ چىقىرىدۇ EBC . ئىشىنىدۇكى، ئەستايىدىل سودا قىلغان ھەربىر ئادەمنىڭ ئەستايىدىل مۇئامىلە قىلىشقا ئەرزىيدۇ.  ",
		"text3": "ئوكسفورد ئۇنىۋېرسىتېتى ئىقتىساد فاكۇلتېتى ھەققىدە",
		"text4": "ئوكسفورد ئۇنىۋېرسىتېتىنىڭ ئىقتىساد فاكۇلتېتى تەسىرلىك تەتقىقات ، تەسىرلىك سىياسەت تۆھپىسى ، ھاياتىي كۈچكە تولغان دەسلەپكى كەسپىي جەمئىيەت ۋە تولۇق كۇرس ۋە ئاسپىرانتلىق پروگراممىلىرىنى ھۆرمەتلەپ ، دۇنيا بويىچە داڭلىق ، ئۇ ئەڭ چوڭ ۋە كۆپ خىللاشقان ئىلىم-پەن ئىقتىسادشۇناسلىرىنىڭ بىرى.",
	},
}