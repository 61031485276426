export default {
	"esg-class-name": "en-esg",
	"esg-banner": {
		"btn": "Tìm hiểu thêm",
		"text1": "EBC hỗ trợ chiến dịch đánh bại bệnh sốt rét của Quỹ Liên Hợp Quốc<br>Cùng với nhau, chúng ta là thế hệ đánh bại bệnh sốt rét",
		"text2": "EBC trao quyền phát triển cho trẻ em trên toàn cầu",
		"text3": "Chuỗi hội thảo của EBC và Đại học Oxford",
		"text3-2": "\"Điều các nhà kinh tế thực sự làm\"",
		"text4": "EBC hợp tác với Hội Nữ Hướng đạo Thế giới<br>Trao quyền phát triển cho phụ nữ, bồi dưỡng nhân tài tương lai",
		"text5": "Together , Let's Build The Future",
		"text6": "EBC tích cực thực thi ESG, thể hiện cam kết vững chắc không chỉ trong các hoạt động từ thiện, hỗ trợ giáo dục và phát triển tài năng cho trẻ em mà còn thúc đẩy sự phát triển bền vững."
	},
	"esg-nav": {
		"text1": "Tầm nhìn của chúng tôi",
		"text2": "Giải pháp của chúng tôi",
		"text3": "Dự án của chúng tôi",
		"text4": "Chúng tôi đang thực hiện",
	},
	"esg-page1": {
		"text1": "Tầm nhìn của chúng tôi",
		"text2": "Nuôi dưỡng tương lai thông qua giáo dục",
		"text3": "Chú trọng giáo dục trẻ em và cải thiện điều kiện giáo dục; hợp tác với các cơ sở giáo dục đại học để xây dựng không gian giao tiếp cởi mở",
		"text4": "Cùng nhau xây dựng một xã hội bình đẳng",
		"text5": "Hỗ trợ các tổ chức từ thiện, trao quyền bình đẳng sức khỏe cho nhiều người hơn và nỗ lực thúc đẩy công bằng xã hội",
		"text6": "Làm cho nhân viên tự hào",
		"text7": "Chúng tôi nỗ lực tạo ra một môi trường làm việc nơi nhân viên cảm thấy được trân trọng và tự hào. Văn hóa công ty tôn vinh sự cởi mở, hòa nhập, truyền cảm hứng đóng góp tích cực cho một cộng đồng toàn cầu đa dạng và bao dung",
		"text8": "Xây dựng ngôi nhà xanh",
		"text9": "Kết hợp phát triển bền vững vào kế hoạch dài hạn của công ty, ủng hộ việc bảo tồn tài nguyên và xây dựng một tương lai bền vững",
	},
	"esg-page2": {
		"text1": "Giải pháp của chúng tôi",
		"text2": "Môi Trường",
		"text3": "<span>Sử dụng tài nguyên bền vững:</span><br>Sử dụng vật liệu đóng gói bền vững để giảm lượng khí thải và thúc đẩy nền kinh tế tuần hoàn.",
		"text4": "<span>Chính sách giảm lượng khí thải carbon: </span><br>Tham gia vào sứ mệnh toàn cầu nhằm chống biến đổi khí hậu bằng cách sử dụng các nguồn năng lượng tái tạo.",
		"text5": "Xã Hội",
		"text6": "<span>Kết nối với các tổ chức từ thiện: </span><br>Thúc đẩy xã hội bình đẳng và hòa nhập thông qua các tổ chức từ thiện",
		"text7": "<span>Đối tác chung mục tiêu:</span><br>Đảm bảo mọi đối tác đều tuân thủ các quy định về môi trường và chính sách trách nhiệm xã hội",
		"text8": "Quản trị doanh nghiệp",
		"text9": "<span>Đa dạng và bao dung:</span><br>Cung cấp môi trường làm việc đa dạng và hòa nhập, phản đối phân biệt đối xử và tạo cơ hội bình đẳng cho mọi người.",
		"text10": "<span>Chăm sóc nhân viên:</span><br>Ưu tiên phúc lợi toàn diện và lộ trình phát triển, hỗ trợ đào tạo và nâng cao kỹ năng cho nhân viên.",
	},
	"esg-page3": {
		"text1": "Dự án của chúng tôi",
		"text2": "Quỹ giáo dục",
		"text3": "EBC tin tưởng rằng giáo dục là nền tảng cho sự tiến bộ xã hội.",
		"text4": "Thiết lập hợp tác với các tổ chức từ thiện và các trường đại học lớn để thúc đẩy đổi mới giáo dục. Các quỹ đặc biệt được thành lập để hỗ trợ trẻ em và giáo dục công cộng ở những khu vực nghèo tài nguyên.",
		"text5": "Quỹ y tế",
		"text6": "Một trái tim nhân hậu sẽ tạo nên những điều tốt đẹp",
		"text7": "Quỹ mở rộng viện trợ cho các cơ sở chăm sóc, mang lại niềm an ủi cho người già không có người thân và mang lại hy vọng cho trẻ em đang phải đối mặt với những thách thức về sức khỏe, nhấn mạnh cam kết của chúng tôi đối với sức khỏe và phúc lợi xã hội.",
		"text8": "Quỹ nhân viên",
		"text9": "Nuôi dưỡng cá nhân để bồi dưỡng sức mạnh đội ngũ chúng ta.",
		"text10": "Chúng tôi ủng hộ văn hóa yêu thương gia đình và có quỹ chăm sóc nhân viên nội bộ để hỗ trợ các đồng nghiệp của chúng tôi chăm sóc gia đình họ",
		"text11": "Quỹ viện trợ",
		"text12": "Sau thảm hoả, mỗi chúng ta đều cần góp phần tái thiết",
		"text13": "Khi dịch bệnh toàn cầu bùng phát, EBC sử dụng quỹ nội bộ để chăm sóc cho người dân và gia đình ở những vùng bị ảnh hưởng.",
	},
	"esg-page4": {
		"text1": "Chúng tôi đang thực hiện",
	}
}