export default {
  "screen0": {
    "enTitle1": "Together",
    "enTitle2": "Let's <text style='color:#800'>Build The Future.</text>",
    "title1": "سۇپا ھەمكارلىق ھەمراھى بولۇپ قالدى   <br>",
    "title2": "تېخىمۇ كۆپ بازار پۇرسىتىنى ئېچىش",
    "list": ["خەلقئارا نوپۇزلۇق قوش نازارەت قىلىپ باشقۇرۇش", "دۇنياۋى ئېچىۋېتىش ھەمكارلىقى",
      "پۈتۈن يۈرۈشلۈك تېخنىكىلىق ھەل قىلىش لايىھەسى"
    ],
    "desc": "EBC ھەمىشە شېرىكلىرى بىلەن چوڭقۇر سودا ئالاقىسىنى مۇستەھكەملەش يولىدا تىرىشىدۇ. بىز ساڭا تۈرلۈك ياردەم چارىلىرى بىلەن يېڭى ۋاسىتىلەردە ئېچىلىشىڭغا ياردەم قىلىپ يېڭى پىكىرләрни قارشى ئىلىمىز. بىز بۈگۈن دۇنياۋى سودا ساھەسىدىكى نەچچە-نەچچە ئىمكانىيەتلەرنى ئوچۇش ئۈچۈن باردۇق.",
    "links": [{
      "url": "/about-us",
      "text": "Why EBC?"
    }, {
      "url": "",
      "text": "تېخىمۇ كۆپ چۈشىنىش "
    }],
		"more": "تېخىمۇ كۆپ چۈشىنىش "
  },
  "screen1": {
    "pageEnTitle": "Customization",
    "pageTitle": "زاكازلىق مۇلازىمەت ",
    "title": "ۋاسىتىچى (IB) مىليون ئامېرىكا دوللىرىلىق يۆلەش پىلانىنى تونۇشتۇردى.",
    "desc": "سۇپنىڭ تەرەققىياتى ۋاستىچىنى تونۇشتۇرۇش (IB) نىڭ قوللىشىدىن ئايرىلالمايدۇ، بىز ئەمەلىي كۈچى بار ۋاستىچىنى تونۇشتۇرۇش (IB) ئۈچۈن ياخشى بولغان تەرەققىيات بوشلۇقى بىلەن تەمىنلەيمىز. ",
    "list": ["شېرىنكانە قۇرۇلمىسى", "كەسىپتە يېڭىلىق يارىتىش تىجارىتى &سودا بايلىقى",
      "يۇقىرى ئۈنۈملۈك راسچوت قىلىش ۋە چىقىم قىلىش تەرتىپى", "مەخسۇس خېرىدار دىرېكتورى",
      "كۆپ قاتلاملىق شېرىنكانە سىستېمىسى", "كۆپ تىللىق خېرىدارلار قوللاش ئەترىتى",
      "مۇكەممەل ئارقا سۇپا ۋە مەلۇمات جەدۋىلى قورالى", "كەسپىي تەرەققىيات پىلانى"
    ],
    "text": "ھەر تەرەپلىمە بېكىتىلگەن تەرەققىيات يىرىك پىلانى",
    "more": "تېخىمۇ كۆپ مۇلازىمەتنى تەكشۈرۈش"
  },
  "screen2": {
    " pageEnTitle": "Trading  service",
    "pageTitle": "سودا مەھەللە مۇلازىمىتى",
    "title": "بىر يۈرۈش مۇكەممەل كەسپىي تەرەققىيات ھەمكارلىق ئەندىزىسى",
    "desc": "بىزگە ئېھتىياجىڭىزنى ئېيتىپ بېرىڭ، EBC سىزنى ئەمەلىي تەجرىبە ۋە تەكلىپ بىلەن تەمىنلەپلا قالماستىن، يەنە سىزنىڭ تەلىپىڭىزگە ئاساسەن، خاسلاشتۇرۇلغان تېخنىكىلىق ھەل قىلىش لايىھەسى بىلەن تەمىنلەپ، ئوخشاش بولمىغان كەسىپ تەرەققىيات باسقۇچىدىكى ئېھتىياجغا ماسلاشتۇرىدۇ. ",
    "list": ["سودا مەھەللىسى", "سودا سىگنالى", "شامال كونتروللىغۇچى لامپا"],
    "btn": "سودا مەھەللىسىگە كىرىش"
  },
  "screen3": {
    "pageEnTitle": "Liquidity service",
    "pageTitle": "كۆچمە ئۇلاش مۇلازىمىتى",
    "title": "EBC بىۋاسىتە بىر تەرەپ قىلىش سىستېمىسىنى قوللايدۇ ",
    "desc": "25 + خەلقئارادىكى ئەڭ يۇقىرى دەرىجىلىك بانكا ۋە يۈزلەنمە فوندنى تۇتاشتۇرغاندا، بېدىك سودىگەرلەرنىڭ يۈزلەنمە تەننەرخىنى تۆۋەنلەتكىلى ۋە زاكاسنىڭ تېز ئىجرا قىلىنىشىغا كاپالەتلىك قىلغىلى ھەمدە مەخسۇس تېخنىكا ئەترىتىنى 7* 24 سائەت تېخنىكىلىق ياردەم بېرىش مۇلازىمىتى بىلەن تەمىنلىگىلى بولىدۇ.",
    "remark": "پەقەت ۋاقىتلا بار، سىز، مېنى تېخىمۇ چۈشىنىسىز"
  },
  "screen4": {
    "pageEnTitle": "Liquidity service",
    "pageTitle": "گۈللەنمە كەسىپ ھالىتى ھەمكارلىقى",
    "title": "EBC ھەر ۋاقىت ئۆزگىرىش قىزغىنلىقى ۋە ئىقتىدارىنى ھازىرلايدۇ",
    "desc": "كەسىپنىڭ يېڭى تەرەققىيات ھالىتىدە ئۈزلۈكسىز يېڭىلىق يارىتىپ ئۆزگىرىش ھاسىل قىلىشقا قارىتا، بىز بازارنىڭ تەرەققىيات يۈزلىنىشىگە ماسلاشقاندىلا، ئاندىن پۇختا قەدەم بىلەن ئالغا ئىلگىرىلىگىلى بولىدىغانلىقىغا ئىشىنىمىز. ئەگەر سىزدە يېڭىلىق يارىتىش ھەمكارلىق لايىھىسى بولسا، مەسلىھەت سورىغايسىز.",
    "list": ["تۆت خىل مۈلۈك تۈرى ", "100 + سودا تۈرى"]
  },
	"box-class": "partner-box-en"
}
