export default {
  "screen0": {
    "title": "Draw wisdom from the past to shape a bright future.",
    "desc": "EBC Multidimensional Data Center , <text style='color:#6c100f'>covering 10+ major asset classes</text>",
    "list": [
      "100+years of inflation-adjusted data for major market indices",
      "20+years of the ever-changing currency markets data",
      "More than half a century of ongoing volatility in precious metals data",
      "Ups and downs of crude oil market date since 1946",
      "Upheavals of the North America natural gas markets in the last 23 years"
    ],
    "text": "The Past and the Future <br/> Right at your fingertips"
  },
  "screen1": {
    "pageEnTitle": "Dow Jones – DJIA – 100 Year Historical Chart",
    "pageTitle": "",
    "title": "Interactive chart of the <text style='color:#6c100f'>Dow Jones Industrial Average (DJIA) stock market </text>index for the last 100 years.",
    "list": [
      "Historical data is inflation-adjusted using the headline CPI and each data point represents the month-end closing value.",
      "The current month is updated on an hourly basis with today’s latest value. ",
      "The current price of the Dow Jones Industrial Average as of October 20, 2020 is 28,308.79."
    ]
  },
  "screen2": {
    "pageEnTitle": "NASDAQ Composite – 45 Year Historical Chart",
    "pageTitle": "",
    "title": "Interactive chart of the <text style='color:#6c100f'>NASDAQ Composite stock </text>market index since 1971.",
    "list": [
      "Historical data is inflation-adjusted using the headline CPI and each data point represents the month-end closing value. ",
      "The current month is updated on an hourly basis with today’s latest value.",
      "The current price of the NASDAQ Composite Index as of October 20, 2020 is 11,516.49."
    ],
    "text": "Understand stock spread contracts ",
    "btn": "Lab Learning Center / stock spread contract "
  },
  "screen3": {
    "pageTitle": "",
    "pageEnTitle": "Euro Dollar Exchange Rate (EUR USD) – Historical Chart",
    "title": "Interactive historical chart <text style='color:#6c100f'>showing the daily Euro – U.S. Dollar (EURUSD)</text> exchange rate back to 1999."
  },
  "screen4": {
    "pageEnTitle": "Pound Dollar Exchange Rate (GBP USD) – Historical Chart",
    "pagetitle": "Interactive historical chart <text style='color:#6c100f'>showing the daily British Pound – U.S. Dollar (GBPUSD) </text>exchange rate back to 1971",
    "title": "Interactive historical chart <text style='color:#6c100f'>showing the daily British Pound – U.S. Dollar (GBPUSD) </text>exchange rate back to 1971",
    "text": "",
    "btn": "lab Learning Center / foreign exchange"
  },
  "screen5": {
    "pageEnTitle": "Gold Price – Last 10 Years",
    "pageTitle": "",
    "title": "This chart tracks <text style='color:#6c100f'>the price of gold in U.S.</text> dollars over the last 10 years.",
    "list": [
      "The current as of October 19, 2020 is $1,911.70."
    ]
  },
  "screen6": {
    "pageEnTitle": "WTI Crude Oil Prices – 10 Year Daily Chart",
    "pageTitle": "",
    "title": "Interactive chart <text style='color:#6c100f'>showing the daily closing price for West Texas Intermediate (NYMEX) Crude Oil </text>over the last 10 years. ",
    "list": [
      "The prices shown are in U.S. dollars.",
      "The current price of WTI crude oil as of October 20, 2020 is $41.65 per barrel."
    ]
  },
  "screen7": {
    "pageEnTitle": "Natural Gas Prices – Historical Chart",
    "pageTitle": "",
    "title": "Interactive chart illustrating the history of <text style='color:#6c100f'>Henry Hub natural gas</text> prices.",
    "list": [
      "The prices shown are in U.S. dollars. The current price of natural gas as of October 14, 2020 is $2.02.",
      "Unlocking the history data you are interested in."
    ],
    "text": "Understand commodities",
    "btn": "Lab Learning Center / commodities"
  }
}
