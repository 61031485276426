export default {
  "screen0": {
    "enTitle": "<span style='color: #800000;font-family: 'Source Serif Pro', Georgia, 'Times New Roman', serif;'>Your Trust, Our Protection<span>",
    "title": "We regard online security as our top priority. ",
    "text": "At EBC, we provide a range of safe, instant and easy-to-use deposit and withdrawal options.You can withdraw all your profits or initial investment at any time. To ensure full protection of our clients’ assets, we implement a high-level encryption technology based on industry best practices for online payment system, and take extra verification measures to ensure the security of all your funds at EBC. ",
    "rightText": "Get your future started."
  },
  "screen1": {
    "smallEnTitle": "Multiple Deposit Methods",
    "smallTitle": "",
    "title": "Multiple deposit methods available: <span style='color: #800000;'>We support UnionPay debit card, T/T, PayPal and other options for deposits.</span>",
    "desc": "To help ensure that you have more funds remained for trading, <span style='color: #800000;'>EBC will not charge any fee</span> on deposit in or withdraw from the trading account.",
    "list": ["deposit method ", " minimum deposit ", " deposit arrival time ", " minimum deposit ", "payment currency ",
      " service charge ", " deposit processing time "
    ],
    "btn": "Fund Now",
    "summary": "Notes: ",
    "imgUrl": "en",
    "summaryDesc": "<p>1. Specific accounting time depends on the situation of banks and intermediary institutions.</p><p>2. Specific limit amount may vary from the stipulations of client’s issuing bank</p><p>3. Please be noted that if you hold a position in your account upon submission of withdrawal application, please pay attention to the amount of your withdrawal to avoid any impact on your position. </ p>"
  },
  "screen2": {
    "title": "Become one of millions around the world",
    "desc": "<span style='color: #800000;'> Only 3 Steps away</span>",
    "list": [{
      "title": "ACCOUNT REGISTRATION",
      "desc": "Submit basic information on the safe and straight-forward online application page."
    }, {
      "title": "SUBMISSION OF PERSONAL INFORMATION",
      "desc": "Just takes 1 minute to complete."
    }, {
      "title": "DEPOSIT AND ACTIVATION",
      "desc": "Place a minimum deposit of USD 200 to activate your account instantly."
    }],
    "btn1": "Try a Free Demo",
    "btn2": "Open a Live Account",
    "yuyan": "en"
  },
  "screen3": {
    "title": "Deposit and Withdrawal Conditions",
    "faqList": [{
      "title": "How do I deposit money into my account?",
      "desc": "After creating an account, you may access to your payment preference through our Client Area. <br/><br/>Specific procedures: <br/><br/>Simply login to your Customer Area, and click on the green “Deposit” button on the left of the interface. <br/>Step 1: Select the account you’d like to deposit in as system prompts. <br/><br/>Step 2: Then select the payment method by using the button on the right of the payment method table. <br/><br/>Step 3: State how much you’d like to deposit into your account. <br/><br/>The system may prompt you to enter the currency of the deposit you’d like to use or other options.  EBC account provides you with various options for base currencies, including the most commonly used RMB, USD, GBP and EUR. <br/><br/>Please carefully read all of the instructions, terms and conditions and if you agree, please make sure that you check the box that says “I have read all instructions and agree with terms and conditions of payments operations.”<br/><br/>【Click Submit】"
    }, {
      "title": "Why was my debit deposit unsuccessful?",
      "desc": "If you fail to deposit by using your debit card, please try to deposit again, while checking if: <br/><br/>– Whether you have entered your card information correctly.<br/><br/>– Whether you are using a vaild (not expired) card. <br/><br/>– Whether you have sufficient funds on your card.<br/><br/>– If there’s no above-mentioned problem,  you still fail to deposit, it may mean that your card issuing bank does not authorize payment from your card.<br/><br/>In that case, please use another card or any other payment method available in your trading account. "
    }, {
      "title": "How do I withdraw money from my account?",
      "desc": "You can request for withdrawal at any time. Our account department processes requests every day. Withdrawal will be transferred into your bank account. If this option is not available, we will remit the funds into customer’s bank account. <br/><br/>In addition, please be noted that, <br/><br/>Withdrawal by T/T shall be subject to the actual processing time of different banks. The accounting time of each bank is different. Please consult your bank for details. <br/>If you withdraw any amount when you hold a position, please make sure that the prepayment ratio in the trading account is still higher than 200% after deducting the withdrawal amount, otherwise, the platform will fail to processyour withdrawal request."
    }, {
      "title": "Does EBC charge any transfer fees?",
      "desc": "EBC do Not charge any fees for using our deposit and withdrawal methods, but some costs may occur in payment you make to or from certain international banking institutions, which is beyond our control. Besides, pleased be noted that your bank may consider the payment to EBC as cash advance, and may bill you according to your cash prepayment rules. "
    }, {
      "title": "Can I use someone else's bank card to deposit/withdraw?",
      "desc": "No. EBC does not accept third-party payments. Please make sure that all deposits into your trading account are from your own account. If the holder of a trading account is a party to a joint account, payment from the joint account will be accepted. <br/><br/>If we suspect that the funds are provided by any third party, we will reserve the right to refund the remitter’s money, freeze your account balance, and suspend your application after the identity and fund source are verified. We will reserve the right to freeze, and you will not be allowed to withdraw funds from your account balance before the verification. <br/><br/>EBC do Not accept fund withdrawal to a third party bank account. If there’s any special situation, please contact us."
    }]
  },
  "funding1":{
		"text1": "Deposit and Withdrawal",
		"text2": "We regard online security as our top priority.",
		"btn": "Deposit Now",
	},
	"funding2":{
		"text1": "Global Multicurrency Funds Solution",
		"text2": "Instant fund deposit and withdrawal in  major global currencies. Enable 24/7 convenient fund transfer by simplifying the international multi-currency transaction",
	},
	"funding3":{
		"text1": "Fund Isolation and Independent Keeping",
		"text2": "Corporate Banking Account",
		"text3": "EBC has Barclays Corporate Banking Account which  requires a minimum turnover of £6.5m, strict financial audits and background check. Adhering to CASS, EBC group (UK) has customer funds held at a custody account through Trust Letter to ensure safety.",
		"btn": "Learn More",
	},
	"funding4":{
		"text1": "Professional Indemnity Insurance",
		"text2": "Enable all-rounded security of client funds. Minimize the loss of funds with multiple professional indemnity insurances.",
	},
	"funding5":{
		"text1": "Deposit and Withdrawal Methods",
		"text2": "We support local bank transfer, international wire transfer, electronic wallet and crypto currencies etc.",
		"text3": "Deposit Mothods",
		"text4": "Supported Currencies",
		"text5": "SIngle Deposit Limit",
		"text6": "Accounting Time",
		"text7": "Withdrawal Processing Time",
		"text8": "Local Bank",
		"text9": "Real-time",
		"text10": "Within 1 working day",
		"text11": "International Wire Transfer",
		"text12": "Unlimited",
		"text13": "Within 2-5 working day",
		"text14": "E-wallet",
		"text15": "Crypto",
	}
}
