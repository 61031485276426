export default {
	"screen0": {
		"title": "<span>We Value Your Safety<br>ได้รับการควบคุม ปลอดภัย และมั่นคง</span>",
	},
	"screen1": {
		"link1": "ภาพรวม",
		"link2": "ระเบียบ FCA และ ASIC",
		"link3": "ให้เกียรติ",
		"link4": "ข้อได้เปรียบ",
		"link5": "วัฒนธรรม",
		"link6": "พันธมิตร",
		"link7": "ติดต่อเรา",

	},
	"screen2": {
		"title": "ภาพรวม",
		"desc1": "EBC Financial Group เป็นผู้ให้บริการทางการเงินที่ก่อตั้งขึ้นในลอนดอนและนำโดยผู้คร่ำหวอดในอุตสาหกรรมที่มีประสบการณ์มากกว่าสามทศวรรษในการดำเนินงานตลาดการเงิน เชี่ยวชาญด้านการซื้อขายออนไลน์ การจัดการสินทรัพย์ และการให้คำปรึกษาด้านการลงทุนของผู้อพยพกับ สำนักงานปฏิบัติการในศูนย์กลางทางการเงินที่สำคัญ ได้แก่ โตเกียว ซิดนีย์ สิงคโปร์ และฮ่องกง",
		"desc2": "ความปลอดภัยของทรัพย์สินเป็นสิ่งสำคัญที่สุดของ EBC นอกเหนือจากกฎระเบียบที่เข้มงวดที่สุดในโลกแล้ว EBC ยังมีบัญชีกับ Barclays จำนวนเงินเอาประกันภัยมากกว่า 10,000,000 ดอลลาร์ และปฏิบัติตาม CASS ดังนั้น ปัจจัยเสี่ยงต่างๆ จะถูกชดเชยสำหรับลูกค้าด้วยความแข็งแกร่งทางการเงินและการบริหารความเสี่ยง",
		"desc3": "เทรดเดอร์ที่จริงจังทุกคนสมควรได้รับการปฏิบัติอย่างจริงจังจากเรา"
	},
	"screen3": {
		"title": "ระเบียบ FCA และ ASIC",
		"desc": "บริษัทในเครือของ EBC Financial Group ได้รับการควบคุมและอนุญาตในเขตอำนาจศาลท้องถิ่นของตน",
		"desc-l1": "EBC Financial Group (UK) Ltd",
		"desc-l2": "ได้รับอนุญาตและควบคุมโดย FCA",
		"desc-l3": "หมายเลขอ้างอิง : 927552",
		"desc-r1": "EBC Financial Group (Australia) Pty Ltd",
		"desc-r2": "ได้รับอนุญาตและควบคุมโดย ASIC",
		"desc-r3": "หมายเลขอ้างอิง : 500991",
		"desc-rr1": "EBC Financial Group (Cayman) Ltd",
		"desc-rr2": "ได้รับอนุญาตและควบคุมโดยหน่วยงานด้านการเงินของหมู่เกาะเคย์แมน (CIMA).",
		"desc-rr3": "หมายเลขอ้างอิง : 2038223",
	},
	"screen4": {
		"title": "ให้เกียรติ",
		"desc1": "โบรกเกอร์ FX ที่น่าเชื่อถือที่สุด",
		"desc2": "โบรกเกอร์ CFD ที่ดีที่สุด",
		"desc3": "โบรกเกอร์ยอดนิยม 10 อันดับแรก",
		"desc4": "โบรกเกอร์ ECN ที่ดีที่สุดในโลก",
		"desc5": "โบรกเกอร์หน้าใหม่ระดับโลก",
		"desc6": "Most Transparent Broker",
		"desc7": "Best - Rated Broker",
		"desc8": "โบรกเกอร์ที่มีประสิทธิภาพมากที่สุด",
		"desc9": "รางวัลประสบการณ์การซื้อขายที่ดีที่สุด",
	},
	"screen5": {
		"title": "ข้อได้เปรียบ",
		"list": [
			{
				"title": "1000",
				"desc": "คำสั่งดำเนินการต่อวินาที"
			},
			{
				"title": "<20<i>MS</i>",
				"desc": "ความเร็วการดำเนินการโดยเฉลี่ย"
			},
			{
				"title": "98<i>.75%</i>",
				"desc": "สถานะการออนไลน์ที่มีเสถียรภาพในการรับส่งข้อมูล"
			},
			{
				"title": "25<i>+</i>",
				"desc": "เข้าถึงธนาคารเพื่อการลงทุนชั้นนำและกองทุนเฮดจ์ฟันด์"
			},
			{
				"title": "7*24",
				"desc": "บริการวีไอพี"
			},
			{
				"title": "0.0<i>PIPS",
				"desc": "สเปรด RAW ECN ระดับระหว่างธนาคาร"
			},
		]
	},
	"screen6": {
		"title": "วัฒนธรรม",
		"desc": "ค่านิยมหลักของเราคือการสร้าง \"มูลค่าที่แท้จริง\" ในเชิงรุกสำหรับลูกค้าของเรา",
		"list": [
			{
				"title": "ปลอดภัย",
				"desc": "ข้อบังคับ FCA และ ASIC การรับประกันความปลอดภัยของกองทุนของ Barclays"
			},
			{
				"title": "ความเป็นธรรม",
				"desc": "ให้ความสำคัญกับลูกค้าทุกคนอย่างจริงจังในหลักการของความซื่อสัตย์และความเคารพ"
			},
			{
				"title": "ความทุ่มเท",
				"desc": "บริการที่เป็นเลิศผ่านรายละเอียดที่สอดคล้องกับค่านิยมหลักของ EBC"
			},
			{
				"title": "มูลค่า",
				"desc": "การสร้างความมั่งคั่งที่ปลอดภัย เป็นมืออาชีพ และมั่นคง"
			},
			{
				"title": "ความซื่อสัตย์",
				"desc": "ประสบการณ์การซื้อขายที่ยอดเยี่ยมซึ่งขับเคลื่อนโดยความซื่อสัตย์"
			},
			{
				"title": "ความรับผิดชอบ",
				"desc": "ทำให้โลกดีขึ้นด้วยการแบ่งปัน "
			},
		]
	},
	"screen7": {
		"title": "พันธมิตร",
	},
	"screen8": {
		"title": "ติดต่อเรา",
		"desc1": "ความร่วมมือ",
		"desc2": "เข้าร่วมกับเรา",
		"desc3": "ข้อเสนอแนะและข้อร้องเรียน",
	},
}

