export default {
	"headlist": [{
		"text":  "باش بەت ",
		"url": "/"
	}, {
		"text": "سودا",
		"tips": "",
		"img": "11",
		"menu": [{
			"tit": "ھېسابات ۋە شەرتلەر",
			"list": [ {
				"text": "سودا ھېساباتى",
				"url": "/TradingAccount"
			}, {
				"text": "سودا مەھسۇلاتلىرى",
				"url": "/tradingproduct"
			}, {
				"text": "پىشاڭ پەرقى",
				"url": "/leverage-margin"
			},{
				"text": "پۇل ئامانەت قويۇش ۋە ئېلىش",
				"url": "/funding"
			}, {
				"text": "پاي ئۆسۈمى",
				"url": "/dividend"
			}]
		},  {
			"tit": "سودا قوراللىرى",
			"list": [{
				"text": "MT4 چۈشۈرۈش",
				"url": "/MT4"
			},{
				"text": "MT5 چۈشۈرۈش",
				"url": "/MT5"
			},{
				"text": "MT4 سودا قوراللىرى",
				"url": "/tools"
			}, {
				"text": "PAMM",
				"url": "/PAMM"
			}]
		}]
	}, {
		"text": "ئالىي مەكتەپ",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "ئۆگىنىش مەركىزى ",
				"url": "/LearningCenter"
			}, {
				"text": "سانلىق مەلۇمات مەركىزى ",
				"url": "/lab"
			},]
		}]
	}, {
		"text": "ئەڭ يېڭى ۋەقەلەر",
		"tips": "",
		"img": "33",
		"menu": [{
			"tit": "",
			"list": [{
				"text": "سودا مۇسابىقىسى",
				"url": "/win"
			},
			// {
			// 	"text": "ئەڭ كۈچلۈك سودا مۇھىتىغا جەڭ ئېلان قىلىش",
			// 	"url": "/no1"
			// },
			{
				"text": " مائاش پىلانى",
				"url": "/pay"
			}]
		}]
	}, {
		"text": "    ھەققىدە EBC    ",
		"tips": "",
		"img": "55",
		"menu": [ {
			"tit": "ھەققىدە EBC ",
			"list": [{
				"text": "WHY EBC",
				"url": "/about-us"
			},{
				"text": "Sustainability & Impact",
				"url": "/ESG"
			}, {
				"text": "FAQ",
				"url": "/FAQ"
			}]
		},{
			"tit": "ھەمكارلىق ھالىتى",
			"list": [{
				"text": "ساودا قىلغۇچىلارنى كۈچەيتىش تۆرى",
				"url": "/affiliate-programmes"
			},{
				"text": "ئورگان مۇلازىمىتى",
				"url": "/service"
			},{
				"text": "ئاساسى تېخنىكا",
				"url": "/technology"
			},
			]
		}]
	}, {
		"text": "سودا مەھەللىسى",
		"url": "/from0tohero"
	}],
	"FCA": "ھېسابات FCA",
	"login": "ھەقسىز",
	"zhuce": "ھېساب ئېچىش",
	"from0tohero_text": "سودا مەھەللىسى",
	"from0tohero_url": "/from0tohero",
	"faq_url": "/FAQ",
}

