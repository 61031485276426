export default{
  "lang": "th",
  "screen0": {
    "title": "เข้าสู่ตลาดการเงินได้ด้วยปลายนิ้ว",
    "desc": "ไม่มีความลับในการเทรด!<br>เพียงค้นหาสิ่งที่ดีที่สุดสำหรับคุณและลงทุนภายใต้การจัดการความเสี่ยง",
    "quick": "ภาพรวมโดยสังเขป",
    "quick1": "สินทรัพย์ 4 ระดับ",
    "quick2": "เครื่องมือการเทรดมากกว่า 100 ชนิด",
    "trList": [
      "hierarchy",
      "buying price",
      "trading volume bought ",
      "point difference",
      "selling price ",
      "selling price volume "
    ],
    "smallTrList": [
      "hierarchy",
      "buying price",
      "point difference",
      "selling price "
    ],
    "btn": "เริ่มต้นการเทรด"
  },
  "screen1": {
    "pageEnTitle": "แพลตฟอร์มการเทรด MT4",
    "pageTitle": "",
    "desc": "MetaTrader 4 (MT4) เป็นแพลตฟอร์มการซื้อขายที่ใช้กันอย่างแพร่หลายมากที่สุดและถือเป็นมาตรฐานตลาดการเงินมาเป็นเวลานาน และยังเป็นแพลตฟอร์มการซื้อขายออนไลน์ที่ได้รับความนิยมมากที่สุดด้วย",
    "title": "แพลตฟอร์มการซื้อขายเพื่อตอบสนองความต้องการที่หลากหลาย",
    "desc1": "แพลตฟอร์ม EBC MetaTrader4 ถูกสร้างขึ้นมาเพื่อให้ปรับแต่งเองได้ง่าย ฟังก์ชั่นการปรับชาร์ตที่เพิ่มขึ้น และการจัดการรายละเอียดของคำสั่ง รวมไปถึงเครื่องมือเตือนและวิเคราะห์ ซึ่งเป็นตัวเลือกที่เหมาะสำหรับคุณเพื่อให้ทันกับการเปลี่ยนแปลงของตลาดแบบเรียลไทม์ ทุกที่ทุกเวลา"
  },
  "screen2": {
    "num1": "3",
    "text1": "แผนภูมิที่แตกต่างกัน",
    "num2": "9",
    "text2": "กรอบเวลาที่แตกต่างกัน",
    "num3": "50",
    "text3": "ตัวบ่งชี้ทางเทคนิคที่ติดตั้งไว้ล่วงหน้า",
    "title": "เทรดเดอร์สามารถเข้าถึงแผนภูมิที่แตกต่างกัน  3 ประเภท กรอบเวลาที่แตกต่างกัน 9 กรอบ และตัวบ่งชี้ทางเทคนิคที่ติดตั้งล่วงหน้า และอินเทอร์เฟซที่ปรับแต่งได้อย่างเต็มที่ 50+ ชนิดซึ่งหมายความว่าสามารถจัดการตำแหน่งที่มีประสิทธิภาพสูงและการติดตามตลาดที่แม่นยำได้อย่างง่ายดาย ไม่ว่าคุณจะใช้รูปแบบการซื้อขายหรือระบบแบบใดอยู่",
    "desc": "คุณสามารถดาวน์โหลด MetaTrader 4 โดยตรงบนสมาร์ทโฟนหรือแท็บเล็ตของคุณและเข้าถึงตลาดทุนทุกที่ทุกเวลาในโลก",
    "btnText": "",
    "btn": "ศูนย์เรียนรู้",
    "smallBtn": "ศูนย์เรียนรู้"
  },
  "screen3": {
    "title": "หากคุณมีโอกาสกำหนดชีวิตของคุณ คุณสามารถเริ่มได้ทันที",
    "desc": "EBC จะยังคงร่วมมือกับ MetaQuotes เพื่อเพิ่มประสิทธิภาพเครือข่ายไฟเบอร์และเซิร์ฟเวอร์ของเรา เพื่อให้ผู้ค้าได้รับความเร็วในการดำเนินการที่ดีขึ้นอย่างมากและเสนอราคาที่โปร่งใสสำหรับสินทรัพย์ทุกประเภท",
    "link1": "ดูเครื่องมือเต็มรูปแบบของเรา",
    "link2": "ดูสเปรดและผลิตภัณฑ์ทั้งหมด",
    "list": [{
      "title": "ครอบคลุมตลาดเต็มรูปแบบ",
      "desc": "เข้าถึงเครื่องมือการซื้อขายมากกว่า 100 รายการ รวมถึง ฟอเร็กซ์ หุ้น ดัชนี และสินค้าโภคภัณฑ์"
    }, {
      "title": "ความยืดหยุ่นสูงสุด",
      "desc": "คุณสามารถเข้าถึงบัญชี MT4 ของคุณได้ทุกที่หลังจากเข้าสู่ระบบเพียงครั้งเดียว เวอร์ชันมือถือมีฟังก์ชันต่างๆ เช่น แผนภูมิใบเสนอราคาแบบโต้ตอบ ชุดการซื้อขายเต็มรูปแบบและเครื่องมือวิเคราะห์ยอดนิยม"
    }, {
      "title": "การกำหนดราคาแบบเรียลไทม์และโปร่งใส",
      "desc": "ทุกมิลลิวินาทีในระหว่างการเทรดนั้นมีค่า ธนาคารเสนอราคาแบบเรียลไทม์ในระบบการซื้อขายอย่างมีประสิทธิภาพและโปร่งใส"
    }, {
      "title": "เครื่องมือแผนภูมิขั้นสูง",
      "desc": "เครื่องมือแผนภูมิขั้นสูงช่วยให้คุณตัดสินใจลงทุนได้เร็วขึ้นและแม่นยำยิ่งขึ้นหลังจากกำหนดราคา"
    }, {
      "title": "ข่าวเรียลไทม์",
      "desc": "ข่าวเรียลไทม์จากตลาดทุนต่าง ๆ ทั่วโลกช่วยให้คุณสามารถควบคุมข้อมูลการตลาดมือแรกได้"
    }, {
      "title": "การดำเนินการส่งคำสั่งซื้อขายไปยังตลาดอย่างรวดเร็ว",
      "desc": "ะบบนี้จะช่วยให้ส่งคำสั่งซื้อขายไปยังตลาดด้วยความเร็วสูงและเป็นการซื้อขายแบบเรียลไทม์ คุณสามารถใช้ตั้งจุดตัดขาดทุน (Stop-Loss) เพื่อควบคุมการสูญเสียหรือตั้งจุดทำกำไร (Take Profit) เพื่อปกป้องผลกำไรไม่ว่าคุณจะซื้อขายในราคาตลาดหรือคำสั่งซื้อที่ค้างอยู่ รองรับคำสั่งซื้อขายทุกประเภทไม่ว่าจะเป็นคำสั่งซื้อในตลาด คำสั่งซื้อที่อยู่ระหว่างรอดำเนินการ คำสั่งซื้อที่มีจุดตัดขาดทุน และคำสั่งซื้อที่ตั้งจุดตัดขาดทุนไล่ตามระดับราคา"
    }, {
      "title": "การเข้าถึงบัญชีออนไลน์",
      "desc": "คุณสามารถตรวจสอบตำแหน่ง ยอดคงเหลือ กำไรขาดทุน และข้อมูลและสถานะอื่นๆ ได้ตลอดเวลา รวมไปถึงสถานะสินทรัพย์ปัจจุบันของคุณจะแสดงอย่างชัดเจน"
    }, {
      "title": "ปลอดภัยและเชื่อถือได้",
      "desc": "ข้อมูลทั้งหมดระหว่างแพลตฟอร์มและเซิร์ฟเวอร์ถูกเข้ารหัสโดยใช้ข้อมูล 256 บิต EBC สร้างสภาพแวดล้อมการซื้อขายที่ปลอดภัยและเชื่อถือได้สำหรับลูกค้า "
    }],
    "btn": "ดาวน์โหลดเดี๋ยวนี้"
  },
  "screen4": {
    "pageEnTitle": "แพลตฟอร์มการซื้อขาย MT4",
    "pageTitle": "",
    "title": "ลงทุนทั่วโลกได้จากบัญชีเดียว ",
    "desc": "สำรวจข้อดีของ MT4",
    "list": [{
        "title": "แอพมือถือ MT4",
        "desc": "ปลดล็อกความเป็นไปได้ในการซื้อขายที่ไร้ขีด จํากัด ด้วยแอพมือถือ MT4 ช่วยให้คุณคว้าทุกโอกาสที่ร่ํารวยไม่ว่าคุณจะอยู่ที่ไหน อย่าพลาดผลกําไรขณะเดินทาง - ซื้อขายด้วยความมั่นใจทุกที่ทุกเวลา"
      },
      {
        "title": "MT4 เวอร์ชั่นเดสก์ท็อป",
        "desc": "ยกระดับการซื้อขายของคุณไปอีกขั้นด้วยแอพ MT4 ที่ยอดเยี่ยมซึ่งได้รับการยอมรับจากประสิทธิภาพที่โดดเด่น ไม่ว่าคุณจะใช้ Windows หรือ OS X คุณสามารถเข้าถึงแอปที่ได้รับรางวัลนี้ได้อย่างง่ายดายและเพลิดเพลินไปกับประสบการณ์การซื้อขายที่ไม่มีใครเทียบได้"
      },
      {
        "title": "MT4 เว็บเทรดเดอร์",
        "desc": "สัมผัสประสบการณ์การซื้อขายทันทีบนอุปกรณ์ใด ๆ โดยไม่จําเป็นต้องดาวน์โหลดผ่านแอพบนเว็บที่ใช้งานง่ายของเรา เพลิดเพลินกับความเข้ากันได้อย่างราบรื่นกับเบราว์เซอร์ยอดนิยมทั้งหมด"
      }
    ],
    "text1": "ดาวน์โหลดเดี๋ยวนี้",
    "text2": "คู่มือผู้ใช้",
    "text3": "ดาวน์โหลด (คู่มือการใช้งาน MT4) in blue with link เรียนรู้ฟังก์ชั่นทั้งหมดของ MT4",
    "text4": "แนะนำให้คุณติดตั้งศูนย์กลางการลงทุนที่ได้รับความนิยมมากที่สุดในโลกได้อย่างง่ายดาย",
    "btn": "เริ่มต้นการเทรด"
  },
	"section1": {
		"title": "เทรดทั่วโลกด้วยการดำเนินการทันที",
	},
	"section2": {
		"title": "แพลตฟอร์มการซื้อขายเพื่อตอบสนองความต้องการที่หลากหลาย",
		"desc": "แพลตฟอร์ม EBC MetaTrader4 นําเสนอ UI ที่ใช้งานง่ายและปรับแต่งได้พร้อมกับฟังก์ชันการสร้างแผนภูมิและเครื่องมือวิเคราะห์ที่ได้รับการปรับปรุงช่วยให้คุณสามารถอัปเดตการเปลี่ยนแปลงของตลาดและดําเนินการจัดการคําสั่งซื้อที่ซับซ้อนได้",
	},
	"section3": {
		"title": "MetaTrader 4 คืออะไร?",
		"desc1": "MetaTrader 4 (MT4) เป็นหนึ่งในแพลตฟอร์มการซื้อขายที่ได้รับความนิยมมากที่สุดในโลกที่พัฒนาโดย MetaQuotes Software Corporation แพลตฟอร์มนี้ได้รับการยอมรับและไว้วางใจจากผู้ค้าทั่วโลกด้วยเทคโนโลยีที่ทันสมัยใช้งานง่ายและความปลอดภัยที่แข็งแกร่ง คุณสมบัติหลักของ MT4 คือความสามารถในการอํานวยความสะดวกในการวิเคราะห์ตลาดที่สะดวกช่วยให้ผู้ใช้สามารถเข้าและออกได้อย่างทันท่วงทีและมีประสิทธิภาพรวมถึงวางขีด จํากัด และคําสั่งหยุด",
		"desc2": "ด้วยการเลือกแพลตฟอร์ม MT4 ของ EBC คุณไม่เพียง แต่ได้รับพลังในการปรับแต่งตัวบ่งชี้ทางเทคนิคและ EA ให้เหมาะกับความต้องการในการซื้อขายของคุณ แต่คุณยังมีความยืดหยุ่นในการเลือกจากผลิตภัณฑ์การซื้อขายที่หลากหลาย ไม่ว่าคุณจะสนใจฟอเร็กซ์โลหะมีค่าน้ํามันดิบหุ้นดัชนีหรือตัวเลือกอื่น ๆ แพลตฟอร์มของเรามีตัวเลือกที่ครอบคลุมเพื่อตอบสนองความต้องการในการซื้อขายเฉพาะของคุณ"
	},
	"section4": {
		"title": "คําถามที่พบบ่อยเกี่ยวกับ MT4",
		"list": [{
			"title": "ฉันดาวน์โหลด MT4 แล้ว จะลงชื่อเข้าใช้บัญชีจริงของฉันได้อย่างไร?",
			"desc": "หลังจากฝากเงินสําเร็จบัญชีของคุณจะเปิดใช้งานและระบบจะให้บัญชีซื้อขายรหัสผ่านและข้อมูลเซิร์ฟเวอร์แก่คุณ สิ่งที่คุณต้องทําคือป้อนบัญชีและรหัสผ่านที่ให้ไว้เลือกเซิร์ฟเวอร์ที่ถูกต้องและลงชื่อเข้าใช้บัญชีจริงของคุณ"
		},
		{
			"title": "จะเปลี่ยนรหัสผ่านของฉันบน MT4 ได้อย่างไร?",
			"desc": "หลังจากล็อกอินเข้าสู่แพลตฟอร์ม MT4 แล้ว ให้คลิก เครื่องมือ - ตัวเลือก จากนั้นเปลี่ยนรหัสผ่านของคุณบนแถบเซิร์ฟเวอร์"
		},
		{
			"title": "จะแทรกอินดิเคเตอร์แผนภูมิบน MT4 ได้อย่างไร?",
			"desc": "โปรดทําตามขั้นตอนเหล่านี้:<br>1. เลือก \"แทรก\" -> \"ตัวชี้วัดทางเทคนิค\" จากเมนูด้านบน<br>2. หรือคุณสามารถแทรก \"ตัวชี้วัดทางเทคนิค\" ผ่านเมนู \"การนําทาง\""
		},
		{
			"title": "วิธีสร้างการศึกษาเส้นแนวโน้มหรือตัวชี้วัดอื่น ๆ",
			"desc": "โปรดทําตามขั้นตอนเหล่านี้:<br> 1. คลิก \"แทรก\" จากเมนูด้านบน<br>2. เลือกเส้นแนวโน้มที่ต้องการ<br>3. ปล่อยคลิกเพื่อดูไอคอนเป้าเล็งพร้อมกับเส้นแนวโน้มที่เลือก ในการปรับเครื่องหมายกากบาทบนแผนภูมิ ให้คลิกปุ่มเมาส์ค้างไว้ จากนั้นเลื่อนเมาส์ตามนั้น คุณยังสามารถเข้าถึงการวิจัยเส้นแนวโน้มได้อย่างรวดเร็วโดยคลิก \"เรียกดู\" -> แถบเครื่องมือ\"\" -> \"\"การวิจัยกราฟ\"\" และแผนภูมิจะปรากฏใต้แถบเมนู คุณสามารถเลือกตัวเลือกต่าง ๆ เพิ่มเติมที่ด้านบนของหน้าจอโดยคลิกที่เส้นแนวโน้ม เส้นเล็ง หรือเส้น Fibonacci retracement"
		},
		{
			"title": "จะเลือกภาษาผู้ใช้บน MT4 ได้อย่างไร?",
			"desc": "ไปที่เมนูด้านบนและเลือกดู -> ภาษา - > เช่นจีนตัวย่อ หลังจากนั้นให้รีสตาร์ทแอปและภาษาของผู้ใช้จะเปลี่ยนภาษาจีนตัวย่อ"
		}
		],
	},
	"btn-text": "ดาวน์โหลด MT4",
  "download-btn1": "Download for Android",
	"download-btn2": "Download for iOS",
	"download-btn3": "Download for iPad",
	"download-btn4": "Download on Windows",
	"download-btn5": "Download for MacOS",
	"download-btn6": "WebTrader",
}
