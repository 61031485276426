export default {
	"esg-class-name": "en-esg",
	"esg-banner": {
		"btn": "รายละเอียดเพิ่มเติม",
		"text1": "EBC Financial Group ร่วมกับ มูลนิธิยับยั้งโรคมาลาเรีย ซึ่งเป็นโครงการของสหประชาชาติเพื่อยับยั้งโรคมาลาเรียสำหรับอนาคตที่สดใสของทุกคน ",
		"text2": "ส่งเสริมการเติมโตของเด็กๆ โครงการระดับโลกที่ EBC สนับสนุน",
		"text3": "EBC Fonancial Group สนับสนุน มหาวิทยาลัย Oxford สาขาวิชาเศรษฐศาสตร์  จัดสัมนาออนไลน์ หัวข้อ \"นักเศรษฐศาสตร์ ทำหน้าที่อะไร?\"",
		"text3-2": "",
		"text4": "EBC ร่วมกับ องค์การผู้บำเพ็ญประโยชน์โลก (The World Association of Girl Guides and Girl Scouts) : เสริมสร้างศักยภาพของผู้หญิงสร้างความเป็นผู้นำในสำหรับผู้หญิงในอนาคต",
		"text5": "มาสร้างอนาคตด้วยกันกับเรา",
		"text6": "EBC Financial Group มุ่งมั่นที่จะปฏิบัติเพื่อช่วย สภาพแวดล้อม สังคม และการบริหาร (ESG). เราไปได้ไกลกว่าการแสดงศักยภาพทางการเงิน และ ให้ความสำคัญต่อการสร้างผลกระทบในเชิงบวกแก่ สภาพแวดล้อม สังคม และอนาคตร่วมกัน"
	},
	"esg-nav": {
		"text1": "วิสัยทัศน์ของเรา",
		"text2": "มาตรการของเรา",
		"text3": "โครงการริเริ่มของเรา",
		"text4": "ความพยายามของเรา",
	},
	"esg-page1": {
		"text1": "วิสัยทัศน์ของเรา",
		"text2": "สร้างอนาคตผ่านการเรียนรู้",
		"text3": "เรามุ่งมั่นให้การศึกษา เน้นการเสริมสร้างพัฒนาการสำหรับเด็กๆและร่วมมือกับสถาบันการศึกษาระดับสูงเป้าหมายของเราคือสร้างแพลตฟอร์มเพื่อเปิดโอกาสในการแลกเปลี่ยนความรู้ ส่งเสริมนวัตกรรมและการเรียนรู้",
		"text4": "การส่งเสริมความเสมอภาคทางสังคม",
		"text5": "เราสนับสนุนโครงการกุศล เพื่อสนับสนุนสิทธิสุขภาพเท่าเทียมและความเสมอภาคทางสังคม เราพยายามเพื่อการสงเสริมชุมชน  สร้างความยุติธรรม และให้ทุกคนเข้าถึงความเจริญ",
		"text6": "การสนับสนุนพนักงาน",
		"text7": "เราทุ่มเทเพื่อสร้างพื้นที่ที่ทำงานที่พนักงานรู้สึกมีคุณค่า ภาคภูมิใจ สร้างวัฒนธรรมที่เปิดกว้าง เคารพกันและเท่าเทียม ซึ่งจะเป็นแรงบันดาลใจให้ทีมในเชิงบวก ต่อชุมชนและโลกที่ยอมรับความหลากหลาย",
		"text8": "ปลูกฝังการอนุรักษ์สิ่งแวดล้อม",
		"text9": "วิสัยทัศน์เชิงกลยุทธ์เพื่อความยั่งยืนเป็นส่วนสำคัญของเรา เราสนับสนุนการใช้ทรัพยากรที่สร้างอนาคตที่ยั่งยืน แสดงให้เห็นถึงความทุ่มเทของเราในการดูแลสิ่งแวดล้อม และสร้างโลกที่ดีกว่าสำหรับวันพรุ่งนี้",
	},
	"esg-page2": {
		"text1": "มาตรการของเรา",
		"text2": "โครงการด้านสิ่งแวดล้อม",
		"text3": "<span>การจัดการทรัพยากรอย่างยั่งยืน:</span><br>เรามุ่งมั่นที่จะใช้วัสดุบรรจุภัณฑ์ที่เป็นมิตรกับสิ่งแวดล้อมเพื่อลดของเสียและส่งเสริมเศรษฐกิจหมุนเวียน เพื่อการปล่อยมลพิษ",
		"text4": "<span>การลดคาร์บอน: </span><br>เรามีส่วนร่วมและพยายามที่จะต่อสู้กับภาวะโลกร้อน โดยร่วมมือกับพันธมิตและหน่วยงานที่ให้ความสำคัญด้านพลังงานหมุนเวียน ซึ่งจะช่วยส่งเสริมความยั่งยืนด้านสิ่งแวดล้อม",
		"text5": "ความมุ่งมั่นทางสังคม",
		"text6": "<span>กิจกรรมทางการกุศล: </span><br>เราทุ่มเทเพื่อส่งเสริมสังคมที่เท่าเทียมและครอบคลุมผ่านการสนับสนุนมูลนิธิการกุศล ผ่านการบริจาคและอาสาสมัคร",
		"text7": "<span>พันธมิตรด้านจริยธรรม:</span><br>พันธมิตรของเรารักษามาตรฐานในการดูแลสิ่งแวดล้อมและรับผิดชอบต่อสังคมอย่างเข้มงวด ซึ่งตอกย้ำความมุ่งมั่นของเราในการพัฒนาสังคม",
		"text8": "การบริหารจัดการ",
		"text9": "<span>ความหลากหลายและการยอมรับความแตกต่าง:</span><br>เรามุ่งมั่นที่จะสร้างสถานที่ทำงานที่สนับสนุนความหลากหลายและการยอมรับความแตกต่าง ต่อต้านการเลือกปฏิบัติ และสนับสนุนโอกาสที่เท่าเทียมกันสำหรับทุกคน",
		"text10": "<span>สวัสดิการที่ดีของพนักงาน:</span><br>เราให้ความสำคัญกับสวัสดิการที่ครอบคลุมและพัฒนาอาชีพของพนักงาน เพื่อสนับสนุนให้พวกเขาเรียนรู้และพัฒนาทักษะอย่างต่อเนื่อง เพื่อให้เติบโตและประสบความสำเร็จภายในองค์กร",
	},
	"esg-page3": {
		"text1": "โครงการริเริ่มของเรา",
		"text2": "กองทุนการศึกษา",
		"text3": "EBC เชื่อมั่นว่าการศึกษาเป็นรากฐานที่สำคัญของความก้าวหน้าทางสังคม",
		"text4": "ความร่วมมือของเรากับองค์กรการกุศล และสถาบันการศึกษาตอกย้ำถึงแรงผลักดันของเราในการขับเคลื่อนการปฏิรูปการศึกษา กองทุนการศึกษาของ EBC เป็นช่องทางเพื่อปลูกฝังจิตใจของเยาวชนและเสริมสร้างโครงสร้างพื้นฐานด้านการศึกษาของชุมชนที่ขาดแคลน และแสดงให้เห็นถึงความมุ่งมั่นของเราในการเสริมสร้างศักยภาพทางการศึกษา",
		"text5": "กองทุนสุขภาพ",
		"text6": "ความเมตตาสร้างการเปลี่ยนแปลงที่ดี",
		"text7": "กองทุนนี้ขยายความช่วยเหลือไปยังสถานดูแลผู้สูงอายุที่ไม่มีครอบครัว และสร้างความหวังให้กับเด็กที่มีปัญหาสุขภาพ ตอกย้ำความมุ่งมั่นของเราที่มีต่อสังคมสุขภาพและสวัสดิการ",
		"text8": "กองทุนสนับสนุนพนักงาน",
		"text9": "การดูแลความเป็นอยู่ที่ดีส่วนบุคคลเพื่อเพิ่มส่งเสริมศักยภาพให้กับองค์กร",
		"text10": "กองทุนสนับสนุนพนักงานก่อตั้งขึ้นเพื่อช่วยให้พนักงานสามารถดูแลครอบครัวของเขา ส่งเสริมความรู้สึกสามัคคีและการสนับสนุนภายในองค์กร และเพื่อความเป็นอยู่ที่ดีของพนักงาน ซึ่งเป็นความมุ่งมั่นของเรา",
		"text11": "กองทุนช่วยเหลือมนุษยชน",
		"text12": "การฟื้นฟูหลังเกิดภัยพิบัติ",
		"text13": "กองทุนช่วยเหลือมนุษยชนของเราสนับสนุนการฟื้นฟูและให้ความช่วยเหลือสำหรับชุมชนที่ได้รับผลกระทบจากวิกฤตการณ์ทั่วโลก ที่เรามอบความเห็นอกเห็นใจและร่วมช่วยเหลือในยามจำเป็น",
	},
	"esg-page4": {
		"text1": "ความพยายามของเรา",
	}
}