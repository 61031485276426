export default {
  "box-name": "en-style2",
  "public-btn-text1": "Đăng ký ngay",
  "public-btn-text2": "Tất cả sản phẩm",
  screen0: {
    title: "Cổ tức CFD",
    desc: 'Xin lưu ý\'việc tổng hợp cổ tức CFD sẽ được phản ánh trong số dư trên tài khoản MT4 của bạn dưới dạng tiền gửi hoặc rút tiền, được chú thích là "Cổ tức".',
  },
  screen1: {
    title: "Giao dịch an toàn",
  },
  screen2: {
    title: "Thế giới tài chính nằm trong tầm tay bạn",
  },
  指数差价合约与股息调整: "CFD chỉ số và điều chỉnh cổ tức",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "Hiểu về cơ chế điều chỉnh và phân bổ cổ tức trong giao dịch CFD cổ phiếu và chỉ số",
  了解指数差价合约: "Hiểu về CFD chỉ số",
  指数差价合约: "CFD chỉ số",
  指数差价合约允许投资者通过股票市场指数的价格波动进行交易:
    "CFD chỉ số cho phép nhà đầu tư giao dịch dựa trên biến động giá của các chỉ số thị trường chứng khoán mà không cần nắm giữ tài sản thực tế. Chúng áp dụng cho nhiều chỉ số toàn cầu, như S&P 500, Dow Jones, và DAX của Đức, mang lại các lựa chọn giao dịch linh hoạt.",
  股息调整: "Điều chỉnh Cổ tức",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "Cổ tức là khoản tiền được phân phối định kỳ từ lợi nhuận hoặc lợi nhuận giữ lại của các công ty niêm yết công khai cho cổ đông. Khi cổ phiếu trả cổ tức, các sản phẩm CFD cổ phiếu và chỉ số liên quan cũng sẽ có điều chỉnh cổ tức tương ứng.",
  除息日与交易优化: "Ngày giao dịch lhông hưởng Cổ tức và tối ưu hóa giao dịch",
  指数差价合约的除息日是由指数提供商根据成分股的除息日设定的:
    "Ngày giao dịch không hưởng cổ tức cho CFD chỉ số được xác định bởi nhà cung cấp chỉ số dựa trên thời điểm các cổ phiếu cơ sở không còn hưởng cổ tức. Nếu bạn giữ vị thế mua, bạn sẽ nhận được cổ tức; nếu bạn giữ vị thế bán, bạn sẽ phải trả cổ tức. Nắm bắt trước các ngày này có thể giúp bạn quản lý lợi tức một cách hiệu quả.",
  股息调整明细: "Chi tiết điều chỉnh Cổ tức",
  差价合约交易中相关产品的股息数据:
    "Dữ liệu cổ tức trong giao dịch CFD được hiển thị riêng biệt cho cổ tức từ vị thế mua (long) và bán (short)",
  即刻交易: "GIAO DỊCH NGAY",
  股息的常见问题: "Câu hỏi thường gặp của Cổ tức",
  什么是股息和除息日: "1. Cổ tức và ngày giao dịch không hưởng cổ tức là gì?",
  股息是上市公司从利润或保留资金中定期分配给股东的资金answer:
    "Cổ tức là khoản tiền được phân phối định kỳ cho cổ đông từ lợi nhuận hoặc lợi nhuận giữ lại của các công ty niêm yết công khai. Khi cổ phiếu trả cổ tức, các sản phẩm CFD cổ phiếu và chỉ số liên quan cũng sẽ có điều chỉnh cổ tức tương ứng.<br /><br />Ngày giao dịch không hưởng cổ tức cho CFD chỉ số được nhà cung cấp chỉ số xác định dựa trên ngày không hưởng cổ tức của các cổ phiếu thành phần, đây cũng là ngày thanh toán cổ tức.",
  股息调整的时间: "2. Thời gian điều chỉnh cổ tức",
  指数差价合约股息调整通常会在除息日的answer: `Điều chỉnh cổ tức cho CFD chỉ số thường sẽ hiển thị trên tài khoản giao dịch MT4/MT5 của bạn trong khoảng từ 05:00 đến 06:00 (GMT+3) vào ngày giao dịch không hưởng cổ tức, được ghi nhận dưới dạng "Dividend".`,
  股息对指数交易的影响: "3. Ảnh hưởng của cổ tức đến giao dịch chỉ số",
  持有多头仓位answer:
    "Nắm giữ vị thế mua: Điều chỉnh cổ tức sẽ được cộng vào tài khoản MT4/MT5 của bạn.<br /><br />Nắm giữ vị thế bán: Điều chỉnh cổ tức sẽ bị trừ vào tài khoản MT4/MT5 của bạn",

  股息调整的计算公式: "4. Công thức tính điều chỉnh cổ tức",
  多头仓位answer:
    "Vị thế mua: Lots × Khối lượng Hợp đồng × Số tiền cổ tức vị thế Mua (tính theo đơn vị tiền tệ của chỉ số).<br /><br />Vị thế mua: Lots × Khối lượng Hợp đồng × Số tiền cổ tức vị thế Mua (tính theo đơn vị tiền tệ của chỉ số).",
  股息调整计算示例: "5. Ví dụ về cách tính cổ tức",
  以SPXUSD为例answer: `Ví dụ: Với chỉ số SPXUSD, giả sử ngày giao dịch không hưởng cổ tức là 11 tháng 10 năm 2024, số tiền cổ tức cho vị thế mua là $0.050 và cho vị thế bán là -$0.050.
<br /><br />
Nếu bạn nắm giữ vị thế mua (mua) 1 lot tiêu chuẩn của SPXUSD vào cuối ngày trước ngày giao dịch không hưởng cổ tức (10 tháng 10), công thức tính điều chỉnh cổ tức sẽ như sau:
<br /><br />
Điều chỉnh Cổ tức = 1 (lot tiêu chuẩn) × 100 (kích thước hợp đồng) × $0.050 (số tiền cổ tức vị thế mua) = $5.
<br /><br />
Vào ngày giao dịch không hưởng cổ tức, trong khoảng 05:00 đến 06:00 (GMT+3), $5 sẽ được cộng vào tài khoản MT4/MT5 của bạn, với ghi chú là "Dividend".
<br /><br />
Nếu bạn nắm giữ vị thế bán (bán), $5 sẽ bị trừ khỏi tài khoản MT4/MT5 của bạn, cũng với ghi chú là "Dividend".`,
  注意事项: "6. Thông tin quan trọng",
  股息调整仅适用于指数和股票差价合约交易answer: `Điều chỉnh cổ tức chỉ áp dụng cho giao dịch CFD cổ phiếu và chỉ số, và sẽ điều chỉnh số dư tài khoản theo loại vị thế (mua hoặc bán). Số tiền cổ tức và mức ảnh hưởng có thể thay đổi tùy thuộc vào các chỉ số cụ thể và điều kiện thị trường.<br /><br />

Nếu có thắc mắc, vui lòng liên hệ quản lý tài khoản của bạn hoặc truy cập trang web chính thức của chúng tôi để biết thêm chi tiết.`,
  list: [
    "Trading Products",
    "Long Dividend",
    "Short Dividend",
    "EX-Dividend Day",
  ],
};
