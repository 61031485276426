export default {
  "screen0": {
    "title": "เอกสารทางกฎหมาย",
    "tit": "เอกสารทางกฎหมาย",
    "list": [
      "อีเมลถึงเรา",
      "ไลฟ์แชทถึงเรา"
    ]
  },
  "screen1": {
    "list": [{
      "title": "1. ข้อกำหนดและเงื่อนไขของเว็บไซต์",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Website-Terms-and-Conditions.pdf"
    }, {
      "title": "2. ประกาศรับทราบความเสี่ยงและการเปิดเผยข้อมูล",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Risk-Acknowledgement-And-Disclosure-Notice.pdf"
    }, {
      "title": "3. นโยบายความเป็นส่วนตัว",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Privacy-Policy.pdf"
    }, {
      "title": "4. นโยบายการดำเนินการ",
      "pdfUrl": "https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/Execution-Policy.pdf"
    }],
    "tit": "คุณกำลังมองหาอะไร?",
    "name": "ค้นหาเว็บไซต์",
    "placeholder": "พิมพ์คำถามของคุณที่นี่…",
    "btn": "ค้นหา",
    "text": "หรือขอบริการพิเศษของเรา",
    "btn1": "ติดต่อเรา"
  }
}
