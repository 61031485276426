export default {
  "screen0": {
    "title": "ตัวย่อ <br>PAMM Solutions",
    "content": "EBC ได้ปรับแต่งรูปแบบการจัดการการจัดสรรเปอร์เซ็นต์แบบใหม่ PAMM (Percentage Allocation Management Module) สำหรับนักลงทุนและผู้จัดการกองทุนที่กำลังมองหาการจัดการบัญชีแบบมืออาชีพ การกำหนดรายละเอียด การดาวน์โหลดบันทึก และฟังก์ชันที่ครอบคลุมอื่นๆ อินเทอร์เฟซเวอร์ชันเว็บกระชับและชัดเจน หน้าที่มีความครอบคลุมและองค์กรมีความชัดเจน",
    "tips":"EBC มอบรูปแบบการจัดการการจัดสรรตามเปอร์เซ็นต์ (PAMM) ให้กับผู้จัดการกองทุนและนักลงทุนด้วยสิทธิ์และความรับผิดชอบที่ชัดเจน พร้อมฟังก์ชันที่ครอบคลุม ภูมิหลังของเวอร์ชันเว็บมีความกระชับและชัดเจน ความซับซ้อนที่ลดความซับซ้อน"
  },
  "screen1": {
    "pageEnTitle": "สิทธิและความรับผิดชอบที่ชัดเจน",
    "pageTitle": "สิทธิและความรับผิดชอบที่ชัดเจน",
    "c1Title": "นักลงทุน",
		"c1Desc": "เพลิดเพลินกับบริการซื้อขายแบบครบวงจรและแบ่งปันผลการซื้อขายของผู้จัดการกองทุน นักลงทุนสามารถเลือกผู้จัดการกองทุนที่ยอมรับความเสี่ยงและเป้าหมายกำไรได้อย่างอิสระ มาตราเครื่องหมายน้ำสูงจูงใจผู้จัดการเงินและปกป้องสิทธิ์ของพวกเขา",
		"c2Title": "ผู้จัดการกองทุน",
		"c2Desc": "ผู้ซื้อขายที่มีประสบการณ์ในการซื้อขายมากมายสามารถเล่นบทบาทของผู้จัดการกองทุนบัญชี PAMM ได้ ผู้จัดการกองทุนสามารถจัดการบัญชีซื้อขายได้หลายบัญชีพร้อมกัน โดยใช้เงินทุนของตนเองและกองทุนรวมของนักลงทุน ซื้อขายสำหรับ กำไร. ผลประโยชน์ส่วนหนึ่งของการทำธุรกรรมหรือค่าใช้จ่ายเป็นค่าตอบแทนแรงงานสำหรับผู้จัดการกองทุนบัญชี",
    "title": "บัญชี PAMM สามารถตอบสนองความต้องการของผู้เข้าร่วมจำนวนมาก",
    "desc": "นักลงทุน: เพลิดเพลินกับบริการซื้อขายแบบครบวงจรและแบ่งปันผลการซื้อขายของผู้จัดการกองทุน <br>นักลงทุนสามารถเลือกผู้จัดการกองทุนที่ยอมรับความเสี่ยงและเป้าหมายกำไรได้อย่างอิสระ <br>มาตราเครื่องหมายน้ำสูงจูงใจผู้จัดการเงินและปกป้องสิทธิ์ของพวกเขา <br><br>ผู้จัดการกองทุน: ผู้ซื้อขายที่มีประสบการณ์ในการซื้อขายมากมายสามารถเล่นบทบาทของผู้จัดการกองทุนบัญชี PAMM ได้<br>ผู้จัดการกองทุนสามารถจัดการบัญชีซื้อขายได้หลายบัญชีพร้อมกัน โดยใช้เงินทุนของตนเองและกองทุนรวมของนักลงทุน ซื้อขายสำหรับ กำไร. ผลประโยชน์ส่วนหนึ่งของการทำธุรกรรมหรือค่าใช้จ่ายเป็นค่าตอบแทนแรงงานสำหรับผู้จัดการกองทุนบัญชี"
  },
  "screen2": {
    "pageEnTitle": "SIMPLE IS THE BEST",
    "pageTitle": "ลดความซับซ้อนเป็นความซับซ้อน",
    "title": "พื้นหลังของเวอร์ชันเว็บมีความกระชับและชัดเจน ทำให้ความซับซ้อนง่ายขึ้น",
    "desc": "ให้บริการโซลูชั่นที่ครอบคลุมซึ่งครอบคลุมการจัดการข้อมูลรายวัน การซื้อขายล็อต/การกระจายกำไร การดาวน์โหลดบันทึก ฯลฯพื้นหลังของเวอร์ชันเว็บมีความกระชับและชัดเจน ความซับซ้อนที่ลดความซับซ้อน"
  },
  "screen3": {
    "pageEnTitle": "FEATURES AND FUNCTIONS",
    "pageTitle": "ข้อดีและคุณสมบัติ",
    "title": "เหมาะสำหรับการซื้อขายและการจัดการเงินทุกประเภท",
    "text1":"มากถึง9 ภาษา",
    "text2":"ความปลอดภัยของกองทุนธุรกรรมที่ราบรื่น",
    "text3":"2วิธีการกระจายกำไรหลัก (การกระจายตามมูลค่าสุทธิและการกระจายตามยอดคงเหลือ)",
    "text4":"เครื่องหมายน้ำสูงสิ่งจูงใจสำหรับผู้จัดการเงินการคุ้มครองนักลงทุน",
    "text5":"เวอร์ชันเว็บมีอินเทอร์เฟซที่เป็นมิตรชัดเจน",
    "text6":"ให้ผลตอบแทน 5 วิธีสำหรับผู้จัดการกองทุน(ส่วนแบ่งกำไร/ค่าธรรมเนียมการจัดการ/ค่าธรรมเนียมการจัดการรายปี/ค่าคอมมิชชันต่อล็อต/ค่าคอมมิชชันต่อคำสั่ง)"
  },
	"imgUrl": "en",
	"styleName": "en-style en-style2",
	"btn-text": "สมัครบัญชี PAMM ทันที"
}
