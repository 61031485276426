export default {
  "box-name": "en-style",
  lang: "en",
  了解指数差价合约: "Learn Index CFDs",
  "public-btn-text1": "View trading hours",
  "public-btn-text2": "All Products",
  screen0: {
    title: "Only time enables you to know me better.",
    desc: "Access the world's most liquid markets and enjoy competitive 24/5 pricing on over 60 instruments with EBC.",
    btn: "Learn More",
  },
  screen1: {
    link1: "Derivatives",
    link2: "Commodities",
    link3: "Index CFDs",
    link4: "Share CFDs",
  },
  screen2: {
    title: "Derivatives",
    text1: "Flexible leverage up to 500:1",
    text2: "Average execution speed 20ms",
    imgUrl: "trade-01",
    title2: "Trade as little as 0 pips<br>36 currency pairs",
    desc2:
      "Trading in the global derivatives market has jumped to the highest-ever level at $6.6 trillion per day in Sep. 2019, according to the Bank for International Settlements (BIS) Triennial Central Bank Survey.",
  },
  screen3: {
    title: "Commodities",
    text1: "Flexible leverage",
    text2: "Ultra-competitive spreads",
    text3: "Flexible open position transaction",
    title2:
      "If you want to diversify your investment portfolio, commodities will be your ideal choice",
    desc2: "Precious metals, crude oil, natural gas, etc.",
  },
  screen4: {
    title: "Index CFDs",
    text1: "Flexible pricing",
    text2: "20ms Average execution speed",
    text3: "Flexible leverage up to 100:1",
    text4: "No hidden commission or transaction costs",
    title2:
      "Meet the world's major stock indices Actively seeking countless opportunities",
  },
  screen5: {
    title: "Share CFDs",
    text1: "Ultra-competitive spreads from as low as $3 for US Stocks trading",
    text2:
      "Access to 36 stock exchanges, both in mature as well as emerging markets",
    text3: "Unparalleled liquidity",
    text4: "Flexible leverage up to 20:1（Margin Level 5%）",
    title2: "Get involved in some of the world's leading brands",
  },
};
