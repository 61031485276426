export default {
	"fcb-class-name": "en-fcb-box",
	"video-btn": "동영상 보기",
	"fcb-page1": {
		"text1": "OFFICIAL PARTNER OF FC BARCELONA",
		"text2": "EBC는 FC 바르셀로나와 금융 분야 독점 파트너십을 체결했으며,협력 범위는 외환, 거래, 중개(차액 계약 포함) 및 컨설팅 서비스를 포함합니다.",
		"text3": "",
	},
	"fcb-page2": {
		"text1": "열정을 향해 최선을 다하다.",
		"text2": "모든 열정적인 경쟁은 이기는 것이 쉽지만은 않습니다.<br>숫자와 승패 판단을 뛰어서다.<br>변화를 이끌어가는 길, 우리는 결코 혼자가 아닙니다.<br>산업의 표준을 정의하고, 혁명을 이루기 위해 노력합니다.<br>우리는 지식과 기술의 활용을 기대합니다.<br>목표를 향한 초심을 잊지 않고, 최선을 다하겠습니다.",
	},
	"fcb-page3": {
		"text1": "하나의 축구 클럽에 불과하지 않습니다.",
		"text2": "이 전설적인 축구 클럽을 모르는 사람은 없습니다.",
		"text3": "바르셀로나 축구 클럽은 120년 넘는 역사를 자랑하며, 세계에서 가장 인기 있는 축구 클럽 중 하나입니다. 마라도나, 메시, 호나우지뉴, 그리고 과르디올라와 같은 세계적인 슈퍼스타들과 감독들의 활약과 화려한 풋볼 스타일로 축구계를 주도해 왔습니다. 이는 '하나의 축구 클럽에 불과하지 않다'는 것을 증명합니다.",
		"text4": "EBC는 '거래'에서 그치지 않고, 글로벌 금융 생태계를 구축하며, 세계 경제 발전을 촉진하는 것을 목표로 하고 있습니다.",
	},
	"fcb-page4": {
		"text1": "기준 설정",
		"text2": "빛나는 스타들로 가득 찬 축구 클럽, 사랑으로 관리되는 훈련 시스템, 뛰어난 성과를 증명하는 트로피, 그리고 사회에 기여하는 활동을 통해 바르셀로나는 업계의 기준을 세웠습니다.",
		"text3": "EBC와 바르셀로나 간의 협력은 서로의 강점을 받아들이는 것을 보여줍니다. EBC는 거래를 사랑하는 모든 트레이더의 초심을 응원합니다. 전세계 최고 수준의 감독, 전방위적인 안전 보장 시스템, 그리고 광범위한 국제적 영향력을 통해 산업의 기준을 정의합니다.",
	},
	"fcb-page5": {
		"text1": "기술 혁신은 '꿈의 팀'을 만들어냅니다.",
		"text2": "바르셀로나는 독특한 패스 조절 스타일로 전 세계적으로 유명하며, 볼 컨트롤, 단거리 패스 및 팀워크에 기반한 전술은 축구가 체력 대결이 아닌 기술과 전술 지혜로 승리할 수 있다는 것을 입증했으며, 현대 축구 전술 발전에 영향을 미쳤습니다.",
		"text3": "EBC는 기술적인 혁신에서도 자신의 열정을 보여주며, 항상 국제적 선두에서 투자자들을 위한 안전하고 전문적이며 효율적이고 안정적인 글로벌 거래 생태계를 구축하고 있습니다."
	},
	"fcb-page6": {
		"text1": "성공을 향한 열정으로 더 나은 세계를 만들어 갑시다",
		"text2": "존중, 노력, 야망, 팀워크 및 겸손은 바르셀로나의 다섯 가지 가치관으로, 노력과 야망을 통해 선수들을 최고로 발전시키는 것을 목표로 하며, 이는 EBC의 가치관과 일치합니다.",
		"text3": "EBC는 항상 신뢰와 존중을 원칙으로, 거래를 사랑하는 모든 트레이더에게 투명한 산업 정보를 제공하여 산업 발전을 촉진하고, 평등한 사회를 구축하기 위해 노력합니다.",
		"text4": "Together, Let's Build The Future.",
	},
	"fcb-page7": {
		"text1": "관련 동향",
	}
}