export default {
  "box-name": "en-style2 ja-style",
  "screen0": {
    "title": "取引口座 | EBC Financial Group",
    "desc": "EBCは、初心者からプロのお客様のトレードスタイルに応じてSTD口座とPRO口座など多様な取引口座タイプを用意しています。取引手数料無料で少額からトレードしたい方はSTD口座をおすすめします、最低スプレッド0pipsからの潤沢な流動性を享受したいお客様はPRO口座をおすすめします。お客様のトレードスタイルに合った口座タイプを見つけて、ぜひ、EBCでのお取引をお楽しみください。"
  },
  "screen1": {
    "btn": "詳しく見る",
    "title1": "STD スタンダートアカウント",
    "title2": "PRO プロフェッショナルアカウン",
    "line11": "1.1pips～",
    "line13": "0.0pips～",
    "line99": "無制限",
    "line100": "50USD",
    "list": [
      "標準スプレッド",
      "手数料",
      "最大レバレッジ",
      "最小注文ロット数",
      "最大注文ロット数",
      "最低入金額",
      "ロスカット比率",
      "指値注文の距離制限",
      "両建て取引",
      "EAの使用",
      "24時間日本語サポート",
      "お客様専属コンサルタント",
      "おすすめ",
      "口座開設"
    ]
  },
  "screen2": {
    "title": "世界に100万人いるトレーダーの仲間入りをしよう ",
    "desc": "たったの3ステップで、安全に口座開設が完了します",
    "list": [{
      "title": "アカウント登録",
      "desc": "オンラインで基本情報を登録してください"
    }, {
      "title": "ログインして個人情報を入力",
      "desc": "最短1分で口座が開設できます"
    }, {
      "title": "入金",
      "desc": "50米ドル以上の入金をしてお取引を開始いただけます。"
    }],
    "btn1": "デモ口座を開設する→",
    "btn2": "ライブ口座を開設する→",
    "yuyan": "ja"
  },
  "screen3": {
    "pay": "支払方法"
  },
  "screen4": {
    "enTitle": "Your Trading Solution Has Been Never <span style='color: #800000;'>Easier",
    "title": "いままでになかった新たな取引体験",
    "desc": "私達はどのような金融市場においても、長期的に収益をもたらすためには、分かりやすいことと統制が取れていることが最も重要なことであると信じています。EBCは、お客様のあらゆる取引情報を一元管理する専用CRMシステムを提供しています。取引の管理がわかりやすく簡単になります。",
    "imgUrl": "ja"
  },
	"screen5": {
		"title": "取引口座",
		"register-btn": "今すぐ登録",
		"text1": "取引口座タイプを選ぶ",
		"text2": "お取引スタイルに合った口座タイプをお選びいただけます。",
		"text3": "STD口座",
		"text4": "少額からFX取引を気軽に始めたい方におすすめ",
		"text5": "最低スプレッドは <i>1.1</i> pips、取引コストが抑えられる",
		"text6": "取引手数料無料",
		"text7": "最低入金額は <i>50</i> ドル",
		"text8": "PRO口座",
		"text9": "高速取引や大口取引におすすめ",
		"text10": "最低スプレッドは <i>0</i> pips、取引コストが極限まで抑えられる",
		"text11": "取引手数料は <i>6</i> ドル/lot、さらに潤沢な流動性を提供",
		"text12": "最低入金額は <i>$5000</i> ドル",
		"text13": "取引口座タイプ比較",
		"text14": "",
		"text15": "タイプ",
		"text16": "おすすめ",
		"text17": "初心者から上級者まで幅広い投資家",
		"text18": "プロトレーダーやスカルピング取引",
		"text19": "STD口座を開設",
		"text20": "PRO口座を開設",
		"text21": "選ばれる理由",
		"text22": "STD口座とPRO口座のいずれも、以下は共通です。",
		"text23": "口座開設はわずか3ステップで完了！",
		"text24": "",
		"text25": "今すぐMT4をダウンロード",
		"text26": "MT4は、多彩なチャート分析機能、豊富なテクニカル指標、EAを利用した自動売買システム機能を備えた、世界中の投資家に愛用されているFX/CFD取引ツールです。 また、MT4はPCやスマートフォンなど多様なデバイスにインストールするだけで、どこでもいつでも手軽に利用することができます。",
		"text27": "今すぐダウンロード",
	}
}
