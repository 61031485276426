export default {
  "box-name": "en-style2",
  "public-btn-text1": "Register Now",
  "public-btn-text2": "All Products",
  screen0: {
    title: "CFD Dividend",
    desc: 'Please note\'ollection of CFD dividend will be reflected in the balance on your MT4 account as a deposit or withdrawal, annotated as "Dividend".',
  },
  screen1: {
    title: "Secure Transaction",
  },
  screen2: {
    title: "The financial world is at your fingertips",
  },
  指数差价合约与股息调整: "Index CFDs and Dividend Adjustments",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "Understand the dividend adjustment and allocation mechanism in your stock and index CFD trading",
  了解指数差价合约: "Understand Index CFDs",
  指数差价合约: "Index CFDs",
  指数差价合约允许投资者通过股票市场指数的价格波动进行交易:
    "Index CFDs allow traders to trade based on the price fluctuations of stock market indices without holding the actual assets. They apply to various global indices, such as the S&P 500, Dow Jones, and Germany's DAX, offering flexible trading options.",
  股息调整: "Dividend Adjustment",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "Dividends are funds regularly distributed to shareholders from publicly listed companies' profits or retained earnings. When stocks pay dividends, the relevant stock and index CFD products also undergo corresponding dividend adjustments.",
  除息日与交易优化: "Ex-Dividend Date and Trading Optimization",
  指数差价合约的除息日是由指数提供商根据成分股的除息日设定的:
    "The ex-dividend date for index CFDs is set by the index provider based on when the underlying stocks go ex-dividend. If you hold a long position, you'll receive dividends; if you're short, you'll have to pay them. Knowing these dates ahead of time can help you manage your yields effectively.",
  股息调整明细: "Dividend Adjustment Details",
  差价合约交易中相关产品的股息数据:
    "The dividend data for related products in CFD trading is displayed separately for long (buy) and short (sell) dividends.",
  即刻交易: "Trade Now",
  股息的常见问题: "Dividend FAQ",
  什么是股息和除息日: "1. What are Dividends and Ex-Dividend Dates?",
  股息是上市公司从利润或保留资金中定期分配给股东的资金answer:
    "Dividends are funds regularly distributed to shareholders from the profits or retained earnings of publicly listed companies. When stocks pay dividends, the relevant stock and index CFD products will also undergo corresponding dividend adjustments.<br /><br />The ex-dividend date for index CFDs is set by the index provider based on the ex-dividend dates of the component stocks, which is also the dividend payment date.",
  股息调整的时间: "2. Timing of Dividend Adjustments",
  指数差价合约股息调整通常会在除息日的answer:
    "Dividend adjustments for index CFDs typically reflect in your MT4/MT5 trading account between 05:00 and 06:00 (GMT+3) on the ex-dividend date, labeled as 'Dividend.'",
  股息对指数交易的影响: "3. Impact of Dividends on Index Trading",
  持有多头仓位answer:
    "Holding a long position (buy): Dividend adjustments will be credited to your MT4/MT5 account.<br /><br />Holding a short position (sell): Dividend adjustments will be deducted from your MT4/MT5 account.",

  股息调整的计算公式: "4. Calculation Formula for Dividend Adjustments",
  多头仓位answer:
    "Long position (buy) calculation formula: Lots × Contract Size × Long Dividend Amount (calculated in index currency).<br /><br />Short position (sell) calculation formula: Lots × Contract Size × Short Dividend Amount (calculated in index currency).",
  股息调整计算示例: "5. Example of Dividend Adjustment Calculation",
  以SPXUSD为例answer: `For example, with SPXUSD, assuming the ex-dividend date is October 11, 2024, the long dividend amount is $0.050, and the short dividend amount is -$0.050.<br /><br />
If you hold a long position (buy) of 1 standard lot of SPXUSD at the close on the day before the ex-dividend date (October 10), the calculation for the dividend adjustment is as follows:<br /><br />

Dividend Adjustment = 1 (standard lot) × 100 (contract size) × $0.050 (long dividend amount) = $5.<br /><br />

On the ex-dividend date between 05:00 and 06:00 (GMT+3), $5 will be credited to your MT4/MT5 account, noted as 'Dividend.'<br /><br />

If you hold a short position (sell), $5 will be deducted from your MT4/MT5 account, noted as 'Dividend.'`,
  注意事项: "6. Important Details ",
  股息调整仅适用于指数和股票差价合约交易answer: `Dividend adjustments apply only to index and stock CFD trading and will adjust the account balance according to the position type (long or short). The dividend amounts and their impacts may vary based on specific indices and market conditions.<br /><br />
For any inquiries, please contact your account manager or visit our official website for more information.`,
list:['Trading Products', 'Long Dividend', 'Short Dividend', 'EX-Dividend Day']
};
