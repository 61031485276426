export default {
	"security-class-name": "security-en-box",
	"security-page1": {
		"text1": "전세계 최고 수준의 감독 기관",
		"text2": "라이선스 확인하기",
	},
	"security-page2": {
		"text1": "전면 보장",
		"text2": "FSCS 최대 8.5만 파운드의 보상 보장",
		"text3": "EBC는 FCA의 최고 수준의 전면 감독 라이선스를 보유하고 있으며, 전 세계에서 FCA 유동성 정산 계좌를 개설할 수 있는 몇 안 되는 브로커 중 하나입니다. 모든 FCA 사용자에게 최대 8.5만 파운드의 보상 보장을 제공합니다. 설립 이후로 FSCS는 보상 기준을 지속적으로 높여왔으며, 연간 약 5-6억 파운드를 보상할 것으로 예상되며, 지금까지 55억 파운드 이상을 보상해 왔습니다.",
		"text4": "바클레이 은행 최고 수준의 Corporate Banking Account",
		"text5": "EBC는 바클레이 은행 최고 수준의 계좌를 보유하고 있으며, 계좌에는 기업 매출액 및 예금이 650만 파운드 이상이어야 하고, 바클레이의 엄격한 재무 감사와 배경 조사를 통과해야 합니다. EBC는 영국에서 CASS 규정을 엄격히 시행하며, 신탁 자료를 통해 투자자 자금을 독립적으로 위탁하여 자금의 안전과 독립을 보장합니다.",
		"text6": "최대 20,000 유로의 추가 보상 보장 제공",
		"text7": "The Financial Commission는 전 세계 최초의 독립적인 외부분쟁해결(EDR) 기구로서, 엄격하고 투명하며 공개적인 심사 체계를 통해 거래 분쟁을 신속하고 공정하게 해결합니다. 지금까지 총 5100만 달러 이상의 보상을 지급하고 거의 만 건의 분쟁을 처리했습니다. EBC는 이 보상 기금에 가입하여 모든 파트너에게 최대 20,000 유로의 추가 보상 보장을 제공합니다.",
		"text8": "1,000만 달러의 전문 책임 보험",
		"text9": "EBC는 매년 영국 로이즈 오브 런던(Lloyds of London)과 미국 에이온 그룹(AON)에서 1,000만 달러 이상의 전문 책임 보험을 구입합니다. 보험은 서비스 기간 중 발생하는 고객 손실과 클레임에 대해 보험사가 전액 보상합니다. 또한 보험 기간 동안 모든 고객에게 보험 전문가가 제공하는 지속적이고 전문적인 솔루션을 제공해 드립니다.",
		"text10": "더 알아보기",
	},
}