export default {
	"security-class-name": "security-en-box",
	"security-page1": {
		"text1": "Giám sát hàng đầu",
		"text2": "Tìm hiểu giấy phép",
	},
	"security-page2": {
		"text1": "Bảo vệ toàn diện",
		"text2": "Tận hưởng mức bảo vệ bồi thường FSCS lên tới £85.000",
		"text3": "EBC có giấy phép quản lý toàn diện ở mức cao nhất từ ​​FCA, là một trong số ít nhà môi giới trên thế giới có thể mở tài khoản thanh toán bù trừ thanh khoản FCA, cung cấp cho mỗi người dùng FCA khoản bảo đảm bồi thường lên tới 85.000 bảng Anh. Kể từ khi thành lập, FSCS đã không ngừng tăng cường cơ sở bồi thường, ước tính số tiền bồi thường hàng năm sẽ là 500-600 triệu bảng, và hơn 5,5 tỷ bảng đã được chi trả.",
		"text4": "Tài khoản ngân hàng doanh nghiệp cấp cao nhất của Ngân hàng Barclays",
		"text5": "EBC có tài khoản doanh nghiệp cấp cao nhất của Ngân hàng Barclays, yêu cầu doanh thu và tiền gửi của công ty phải đạt hơn 6,5 triệu bảng Anh, đồng thời phải vượt qua một loạt kiểm toán tài chính và kiểm tra lý lịch nghiêm ngặt của Barclays. EBC UK thực hiện nghiêm ngặt các quy định của CASS và quản lý độc lập quỹ của nhà đầu tư thông qua các thư ủy thác cụ thể để đảm bảo sự an toàn và độc lập của quỹ.",
		"text6": "Cung cấp bảo vệ bồi thường bổ sung lên tới 20.000 euro",
		"text7": "Là tổ chức giải quyết tranh chấp bên ngoài (EDR) độc lập đầu tiên trên thế giới, Ủy ban Tài chính đảm bảo giải quyết nhanh chóng và công bằng các tranh chấp giao dịch thông qua cơ chế xét xử nghiêm ngặt, minh bạch và công khai. Đến nay, họ đã chi trả tổng số tiền bồi thường hơn 51 triệu USD và giải quyết gần 10.000 vụ tranh chấp. EBC tham gia quỹ bồi thường của mình để cung cấp khoản bảo vệ bồi thường bổ sung lên tới € 20.000 cho mỗi đối tác.",
		"text8": "Bảo hiểm trách nhiệm nghề nghiệp mười triệu đô la",
		"text9": "EBC mua bảo hiểm trách nhiệm nghề nghiệp với số tiền bảo hiểm hơn 10 triệu đô la Mỹ mỗi năm từ Lloyds of London và AON. Công ty bảo hiểm sẽ bồi thường đầy đủ cho những tổn thất và khiếu nại của khách hàng do sự sơ suất hoặc sai sót của bên được bảo hiểm trong suốt khoảng thời gian phục vụ. Đồng thời, trong thời gian bảo hiểm, mọi khách hàng sẽ được bảo vệ bằng các giải pháp liên tục và chuyên nghiệp do các chuyên gia bảo hiểm cung cấp.",
		"text10": "Tìm hiểu thêm",
	},
}