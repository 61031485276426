export default {
	"screen0": {
		"title": "<span>We Value Your Safety<br>تەڭشەلگەن ، بىخەتەر ۋە مۇقىم</span>",
	},
	"screen1": {
		"link1": "ئومۇمىي چۈشەنچە",
		"link2": "FCA ۋە ASIC نىزامى",
		"link3": "شەرەپ",
		"link4": "ئەۋزەللىكى",
		"link5": "مەدەنىيەت",
		"link6": "ھەمكارلاشقۇچى",
		"link7": "بىز بىلەن ئالاقىلىشىڭ",

	},
	"screen2": {
		"title": "ئومۇمىي چۈشەنچە",
		"desc1": "EBC پۇل-مۇئامىلە گورۇھى پۇل-مۇئامىلە مۇلازىمىتى بىلەن تەمىنلىگۈچى بولۇپ ، لوندوندا قۇرۇلغان ۋە پۇل-مۇئامىلە بازىرى تىجارىتىدە 30 نەچچە يىللىق تەجرىبىسى بار كەسىپ پېشقەدەم ئەسكەرلىرىنىڭ يېتەكچىلىكىدە ، تور سودىسى ، مۈلۈك باشقۇرۇش ۋە كۆچمەنلەر مەبلەغ سېلىش مەسلىھەتچىلىكى بىلەن شۇغۇللىنىدۇ. توكيو ، سىدنېي ، سىنگاپور ۋە شياڭگاڭ قاتارلىق ئاساسلىق پۇل-مۇئامىلە مەركەزلىرىدىكى تىجارەت ئورۇنلىرى ",
		"desc2": " مۈلۈك بىخەتەرلىكى EBC نىڭ ئەڭ مۇھىم ئىشى. EBC دۇنيادىكى ئەڭ قاتتىق بەلگىلىمىدىن باشقا ، Barclays بىلەن ھېسابات قىلىدۇ ، سۇغۇرتا سوممىسى 10،000،000 دوللار ۋە CASS غا ماس كېلىدۇ. شۇڭا خەتەر ئامىلى خېرىدارلارغا ئىقتىسادىي كۈچ ۋە خەتەرنى باشقۇرۇش ئارقىلىق تولۇقلىنىدۇ. ",
		"desc3": " ھەر بىر ئېغىر سودىگەر بىزنىڭ ئەستايىدىل داۋالىنىشىمىزغا ئەرزىيدۇ "
	},
	"screen3": {
		"title": "FCA ۋە ASIC نىزامى",
		"desc": "EBC Financial Group ئەۋلادلىرىنىڭ ئورۇنلۇق جايلاشقان ئورۇنلىرىدا تۈزۈمگە ماسلاشتۇرۇش ۋە رۇخسەتنامە بار.",
		"desc-l1": "EBC Financial Group (UK) Ltd",
		"desc-l2": "ھوقۇق بېرىلگەن ۋە FCA تەرىپىدىن تەڭشەلگەن",
		"desc-l3": "پايدىلىنىش نومۇرى : 927552",
		"desc-r1": "EBC Financial Group (Australia) Pty Ltd",
		"desc-r2": "ھوقۇق بېرىلگەن ۋە ASIC تەرىپىدىن باشقۇرۇلىدۇ",
		"desc-r3": "پايدىلىنىش نومۇرى : 500991",
		"desc-rr1": "EBC Financial Group (Cayman) Ltd",
		"desc-rr2": "كايمان تاقىم ئارىلى پۇل باشقۇرۇش ئورگىنى ھوقۇق بەرگەن ۋە نازارەت قىلغان (CIMA)",
		"desc-rr3": "نايدىلىنىش نومۇرى : 2038223	",
	},
	"screen4": {
		"title": "شەرەپ",
		"desc1": "ئەڭ ئىشەنچلىك FX ۋاسىتىچىسى",
		"desc2": "ئەڭ ياخشى CFD ۋاسىتىچىسى",
		"desc3": "TOP 10 داڭلىق ۋاسىتىچى",
		"desc4": "يەر شارى بويىچە ئەڭ ياخشى ECN ئاكسىيە سودىگىرى",
		"desc5": "يەر شارى بويىچە يۇلتۇز دەرىجىك ئاكسىيە سودىگىرى",
		"desc6": "كۆپىنچە سۈزۈك ۋاسىتىچى",
		"desc7": "ئەڭ ياخشى - باھالانغان ۋاسىتىچى",
		"desc8": "ئەڭ ئۈنۈملۈك ۋاسىتىچى مۇكاپاتى",
	},
	"screen5": {
		"title": "ئەۋزەللىكى",
		"list": [
			{
				"title": "1000",
				"desc": "زاكاز سېكۇنتتا بىر تەرەپ قىلىندى"
			},
			{
				"title": "<20<i>MS</i>",
				"desc": "ئوتتۇرىچە ئىجرا سۈرئىتى"
			},
			{
				"title": "98<i>.75%</i>",
				"desc": "انلىق مەلۇمات يوللاش مۇقىملىقى"
			},
			{
				"title": "25<i>+</i>",
				"desc": "يۇقىرى دەرىجىلىك مەبلەغ سېلىش بانكىسى ۋە خەتەردىن ساقلىنىش مەبلىغىگە ئېرىشىش"
			},
			{
				"title": "7*24",
				"desc": "VIP مۇلازىمىتى"
			},
			{
				"title": "0.0<i>PIPS",
				"desc": "بانكىلار ئارا RAW ECN تارقىلىدۇ"
			},
		]
	},
	"screen6": {
		"title": "مەدەنىيەت",
		"desc": "بىزنىڭ يادرولۇق قىممىتىمىز تەشەببۇسكارلىق بىلەن خېرىدارلىرىمىز ئۈچۈن «ھەقىقىي قىممەت» نىڭ تولۇق دائىرىسىنى يارىتىش.",
		"list": [
			{
				"title": "بىخەتەر",
				"desc": "FCA ۋە ASIC نىزامى. باركلېينىڭ فوند بىخەتەرلىكىگە كاپالەتلىك قىلىشى."
			},
			{
				"title": "ئادىللىق",
				"desc": "سەمىمىيەت ۋە ھۆرمەت پرىنسىپىدا ھەر بىر خېرىدارغا ئەستايىدىل مۇئامىلە قىلىڭ "
			},
			{
				"title": "بېغىشلاش",
				"desc": "EBC نىڭ يادرولۇق قىممىتىگە ماس كېلىدىغان تەپسىلاتلار ئارقىلىق مۇلازىمەت ئەۋزەللىكى"
			},
			{
				"title": "قىممەت",
				"desc": "بىخەتەر ، كەسپىي ۋە مۇقىم بايلىق بەرپا قىلىش"
			},
			{
				"title": "سەمىمىيەت",
				"desc": "سەمىمىيەتنىڭ تۈرتكىسىدە ئالاھىدە سودا تەجرىبىسى"
			},
			{
				"title": "مەسئۇلىيەت",
				"desc": "ئورتاقلىشىش ئارقىلىق دۇنيانى تېخىمۇ ياخشى قىلغىن "
			},
		]
	},
	"screen7": {
		"title": "ھەمكارلاشقۇچى",
	},
	"screen8": {
		"title": "بىز بىلەن ئالاقىلىشىڭ",
		"desc1": "ھەمكارلىق：",
		"desc2": "ىزگە قوشۇلۇڭ：",
		"desc3": "ىكىر ۋە ئەرز：",
	},
}

