export default {
	"leftNav": [{ "id": "#Trading", "name": "交易" },
		{ "id": "#Regulation", "name": "顶级监管" },
		{ "id": "#Security", "name": "安全保障" },
		{ "id": "#Fintech", "name": "金融科技" },
		{ "id": "#Black-Box", "name": "交易黑盒" },
		{ "id": "#Private-Room", "name": "交易空间" },
		{ "id": "#Liquidity", "name": "流动性" },
		{ "id": "#Honor", "name": "国际荣誉" },
		//{ "id": "#News", "name": "新闻" }
	],
	"pages1": {
		"h1":"Trust & Respect",
		"title": "每个认真交易的人，都值得被认真对待",
		"btn": " 观看全视频 "
	},
	"pages2": {
		"title": "连接全球热门交易产品",
		"title1": "探索一个充满机遇和竞争优势的世界，掌握最真实和稳定的市场价格",
		"btn": " 了解交易产品 ",
		"text": " 货币交易 ",
		"text1": " 大宗商品 ",
		"text2": " 现货指数差价合约 ",
		"text3": " 股票差价合约 "
	},
	"pages3": {
		"title": "全球顶级监管",
		"title1": "EBC金融集团旗下各公司，在对应司法管辖区域内，均受到严格监管并获得顶级资质许可。",
		"btn": " 了解EBC监管 ",
		"text": " 英国 FCA 监管 ",
		"text1": " EBC Financial Group (UK) Ltd<br class='regulation-br'> 由英国金融行为监管局 (FCA) 授权和监管。<br class='regulation-br'> 监管号: 927552 ",
		"text2": " 澳洲 ASIC 监管 ",
		"text3": "EBC Financial Group (Australia) Pty Ltd <br class='regulation-br'>由澳大利亚证券和投资委员会 (ASIC) 授权和监管。<br class='regulation-br'>监管号: 500991",
		"text4": " 开曼 CIMA 监管 ",
		"text5": "EBC Financial Group (Cayman) Ltd<br class='regulation-br'>由开曼群岛金融管理局(CIMA)授权和监管。<br class='regulation-br'>监管号: 2038223"
	},
	"pages4": {
		"title": "科技赋能交易",
		"title1": "部署于伦敦LD4、纽约NY4、新加坡SG1、东京TY3、香港HK2的超高速专线连接的独立服务器，提供超低延迟聚合、智能订单路由和报价优化引擎，构建全球超高速金融生态体。",
		"btn": " 探索底层科技",
		"text": " 每秒最高聚合订单 ",
		"text1": " 极速订单执行 ",
		"text2": " 数据传输稳定高达 ",
		"text3": " 同业银行级别的RAW ECN点差",
	},
	"pages5": {
		"title": "让85%以上的订单成交于更优的价格",
		"title1": "EBC交易黑盒，精准优化抛单算法，在价格优化率、订单成交率以及算法稳定性方面， EBC交易黑盒远远优于传统交易系统。",
		"btn": " 了解交易黑盒"
	},
	"pages6": {
		"title": "EBC交易空间",
		"title1": "无论您是高频交易者，还是大单交易家，EBC 的 Private Room 都将在流动性匹配基础上，搭建您的专属的交易通道，甚至隐藏您的部分交易信息，保护您的“底牌”，这意味着您的订单不仅能获得更好的聚合报价，也可以在成交过程中免于狙击。",
		"btn": " 了解交易空间"
	},
	"pages7": {
		"title": "国际顶级流动性",
		"title1": "EBC与多家国际顶级银行建立了长期稳定的合作关系，整合多家优质流动性报价，提供极具竞争力的市场点差，让您轻松享有超低交易成本。",
		"btn": " 了解EBC流动性"
	},
	"pages8": {
		"btn": "浏览更多新闻"
	},
	"pages9": {
		"title": "全面保障 安心以致",
		"title1": "EBC将资金安全视为首要原则，客户资金独立存放于巴克莱银行的托管账户中，并通过与FCA、Financial Commission、Lloyds of London、AON合作，建立了全面、多层次的安全保障机制。",
		"btn": "了解安全保障"
	},
	"lang": "cn"
}

