export default {
    "h1-title": "交易工具",
    "imgUrlLang": "",
    "public-btn-text": "即刻體驗",
    screen0: {
        "title": "創建滿足您交易需求的工具庫",
    },
    screen1: {
        "title": "同圖商品疊加工具",
        "desc": "該工具可以讓投資者清晰地了解到兩種商品任意週期任意K線的相對強弱，此外還可識別突破的有效性",
    },
    screen2: {
        "title": "MACD專業指標(雙線)",
        "desc": "這是一款股票和期貨常用的MACD指標工具，與MT4自帶的MACD相比，更加符合中國用戶的看盤習慣",
    },
    screen3: {
        "title": "MTDriver盈虧統計指標",
        "desc": "該工具可以幫助投資者精確地定位不同周期的盈虧",
    },
    screen4: {
        "title": "價格變動手機推送通知",
        "desc": "大部分投資者的盯盤時間較為有限,價格變動手機推送可以幫助他們在規避部分市場噪音的情況下把握市場的動向",
    },
    screen5: {
        "title": "一鍵平倉EA",
        "desc": "操作界面位於圖表界面中，方便投資者在極端行情發生的時候更迅速便捷地進行止損",
    },
    screen6: {
        "title": "定時下單EA",
        "desc": "放在\MQL4\Experts文件夾下，一款指定時間下單的EA，用法很簡單，用戶請自行摸索",
    },
    screen7: {
        "title": "淨值監控EA",
        "desc": "放在\MQL4\Experts文件夾下，監控淨值變動的EA，把想要提示的聲音文件放在\Sounds下，然後在EA的音樂名稱填對應音樂文件的名字",
    }
}