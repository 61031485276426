export default {
    hd: {
        list: [{
            url: '/Login',
            text: '客户登录'
        }]
    },
    bd: {
        list: [{
            text: '交易',
            menu: [{
                text: '交易产品',
                url: '/tradingproduct'
            }, {
                text: '交易账户',
                url: '/funding'
            }, {
                text: '出入金',
                url: '/funding'
            }, {
                text: '杠杆与保证金',
                url: '/leverage-margin'
            }, {
                text: 'MetaTrader 4',
                url: '/MT4'
            }, {
                text: 'MAM',
                url: '/MAM'
            }]
        }, {
            text: 'Lab与工具',
            menu: [{
                text: '学习中心',
                url: '/LearningCenter'
            }, {
                text: '数据中心',
                url: '/lab'
            }, {
                text: '交易工具',
                url: '/tools'
            }]
        }, {
            text: '机构业务',
            menu: [{
                text: '底层科技',
                url: '/technology'
            }, {
                text: '机构服务',
                url: '/service'
            }]
        }, {
            text: '合作伙伴',
            url: '/partner'
        }, {
            text: '最新活动',
            url: '/No1'
        }, {
            text: '交易',
            menu: [{
                text: 'Why EBC',
                url: '/about-us'
            }, {
                text: 'FAQ',
                url: '/FAQ'
            }]
        }, {
            text: '免费开户',
            url: '/Login'
        }]
    }
}