export default {
	"box-name": "partner2-page-cn",
	"section1": {
		"text1": "影響力聯盟",
		"text2": "一個匯聚內容創作者、市場推廣專家和經紀人的創新生態，讓優秀交易者們攜手並進，展拓全球機遇，解鎖無限可能。",
		"text3": "業務遍及20多個國家",
		"text4": "匯聚多位業界頂尖專家",
		"text5": "多元化的合作支持方案",
		"text6": "全方位的資源支援體系",
	},
	"section2": {
		"text1": "合作方案",
		"text2": "介紹經紀人（IB）",
		"text3": "業內學者、專業交易員、交易愛好者和公司",
		"text4": "<li>業務發展規劃</li><li>品牌聯名曝光機會</li><li>交易資源&行銷活動支持</li>",
		"text5": "成為介紹經紀人",
		"text6": "CPA行銷夥伴",
		"text7": "網站/部落客、媒體KOL、網路媒體",
		"text8": "<li>組合獎勵模式</li><li>客製化合作方案</li><li>多媒體行銷素材支持</li>",
		"text9": "成為行銷夥伴",
		"text10": "策略支持者",
		"text11": "專業分析師、交易愛好者、策略提供者",
		"text12": "<li>客製化專屬實體禮品</li><li>線上線下聯名活動</li><li>交易資源&行銷活動支持</li>",
		"text13": "成為策略支持者",
	},
	"section3": {
		"text1": "資源支持",
		"text2": "品牌曝光機會",
		"text3": "提供平台展現專業知識，助力聯盟夥伴的品牌廣受認可。",
		"text4": "擴展業界人脈",
		"text5": "與業界頂尖專家建立聯繫，交流經驗，共探產業趨勢。",
		"text6": "行銷資源支持",
		"text7": "提供行銷素材和活動支持，為聯盟夥伴客製化推廣策略。",
		"text8": "高階分析師培訓",
		"text9": "專業分析師培訓，提升技能和專業知識。",
		"text10": "訂單流工具",
		"text11": "辨識主力資金、提高交易勝率，專業交易者必備工具。",
		"text12": "知識影響力擴展",
		"text13": "協助知識型創作者內容整合，擴大影響力，提升專業形象。",
	},
	"section4": {
		"text1": "聯盟成員",
		"text2": "Joe DiNapoli",
		"text3": "<li>斐波那契技術傳奇教父</li><li>帝納波利點位法創始人</li><li>黃金比率應用頭號權威</li>",
		"text4": "Joe DiNapoli擁有55年市場經驗，曾預測1987年道瓊指數大跌和2002年市場底層等週期性轉折點，在全球享有盛名。作為一名註冊商品交易顧問（CTA）超過25年，撰寫了《帝納波利點位交易法（DiNapoli Levels）》，該書已被翻譯成13種語言，風靡全球。",
		"text5": "了解更多",
		"text6": "<li>Lawrence擁有商業建築產業背景，之後轉型進入金融市場，以追求一個更有成就感的職涯發展。 在發現DiNapoli Levels后，他的交易方式發生了轉變。 自從17年前於泰國曼谷參加帝納波利研討會以來，Lawrence多次準確預測市場走勢，包括應對新冠疫情引發的市場崩盤。 目前，作為一名帝納波利交易法專家，他指導新交易者規避陷阱，加快他們的學習進度。</li>",
		"text7": "<li>Pieter對於交易濃厚的興趣，始於高中時期。作為一名榮獲優等生榮譽的經濟學家，他在一家南非本土公司開啟了全職交易的生涯，並廣泛涉足各類市場。自2003年起，他開始深入學習帝納波利理論，並於2009年開始在全球傳授帝納波利點位法。目前，他管理自己的帳戶，擔任南非一家對沖基金的成員，並為南非的商品公司提供諮詢服務。 Pieter也是《D-Levels金融預測》新聞資訊的作者，他將自己的大部分成就歸功於喬爾·帝納波利的悉心指導。 </li>",
		"text8": "<li>Monchai Kongthanapakdi是泰國技術分析師協會的創辦人，也是CMT協會泰國分會的聯合主席，並擔任Efuturesthai交易學院院長，該學院旨在為泰國投資者提供技術分析和培訓。 Monchai是一名全職交易員、技術分析師和投資者，擅長斐波那契分析（DiNapoli Levels）和演算法交易。自2011年起，Monchai與帝納波利合作，在泰國舉辦了多場公開和私人研討會，並參與了2022年《帝納波利點位交易法》（泰文版）的翻譯工作。</li>",
		"text9": "<li>黃金比例交易大師，美國布萊特期貨基金合夥人，美國黃金比例投資顧問有限公司總經理，美國紐約金融學院北京分院和芝加哥期貨交易所特約講師，期貨日報全國實盤大賽金牌導師，中文版«帝納波利點位交易法»一書譯者。身為全球帝納波利點位交易法資深專家，自2008年以來成功預測上證指數幾乎所有的轉折點，以及美國道瓊斯指數、美元指數、美國原油和黃金期貨的多個階段性頂部和底部。</li>",
	},
	"section5": {
		"text1": "更多分享",
		"text2": "2024年7月12日，EBC集團與交易大師帝納波利在曼谷舉行全球簽約儀式，探討交易策略，進一步提升雙方在全球市場的影響力。",
		"text3": "分享交易策略",
		"text4": "帝納波利及其團隊分享了先進交易策略，吸引全球媒體和投資者關注。",
		"text5": "現場發送簽名書",
		"text6": "活動現場贈送帝納波利限量版親筆簽名書，提升個人品牌知名度。",
		"text7": "提升雙方影響力",
		"text8": "此次合作推動交易專業化發展，為雙方帶來產業認可與影響力提升。",
	},
}