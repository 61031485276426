export default {
	"box-name": "DiNapoli-page-en",
	"section1": {
		"text1": "Joe<br> DiNapoli",
		"text2": "<li>피보나치의 대부</li><li>DiNapoli Levels 의 창립자</li><li>FibNodes 소프트웨어의 창시자</li>",
		"text3": "Joe는 55년 이상의 실제 무역 경험을 가진 베테랑 무역업자입니다. 그는 여전히 매일 거래합니다. 그의 이력에는 60년대 뉴욕증권거래소(NYSE)에서 Nifty 50을 거래한 것과 1987년 시장 붕괴를 예측한 것, 9/11 직후 선물 거래에 관여한 것이 포함됩니다. 계약이 시작된 1982년부터 joe는 S&P 선물을 거래했습니다. 이동평균선, 첨단 피보나치 기법, 발진기 예측기 및 MACD 예측기에 대한 연구로 유명한 조의 선도적인 지표 개발은 그를 원하는 전문가로 만들었습니다. 25년 이상 등록된 CTA로서 그는 전 세계적으로 강의했으며 호평을 받고 있는 책 \"DiNapoli Levels와의 거래\"를 저술했으며, 이 책은 앞으로 더 많은 언어로 전 세계 13개 언어로 번역되었습니다.",
		"text4": "DiNapoli Levels거래법",
		"text5": "\"Trading with DiNapoli Levels\"는 가격 축에 대한 피보나치 애널리시스의 실제 적용에 대해 지금까지 출판된 가장 포괄적인 책입니다. 이 권위 있는 가이드는 거래자들에게 매우 귀중한 통찰력과 기술을 제공하여 거래 전략을 정확하고 자신 있게 향상시킬 수 있도록 합니다.",
		"text6": "<li>글로벌 황금비율 투자거래에 대한 최우선적인 권위있는 작업</li><li>\"Trading with DiNapoli Levels\" 라는 책은 실용적인 거래에서 인정받는 성과로 인해 역대 최고의 거래 책 중 하나로 여겨집니다.</li><li>전 세계적으로 13개 이상의 언어로 번역되었습니다.</li>",
		"text7": "무료로 받기",
		"text8": "DiNapoli MT4/MT5 지표",
		"text9": "DiNapoli의 주요 지표는 효율적인 추세 파악과 지지 및 저항 수준의 정확한 측정으로 전 세계 트레이더들이 매우 선호합니다. 다양한 기술 분석 방법을 결합하여 트레이더에게 강력한 의사 결정 지원을 제공하여 거래 성공률을 크게 향상시킵니다.",
		"text10": "정밀한 트렌드 캡처",
		"text11": "DMA 시스템은 시의적절한 기회를 위해 시장 동향을 신속하게 파악합니다.",
		"text12": "유효 과매수/과매도 신호",
		"text13": "oscillator는 시장 전환점을 안정적으로 알려줍니다.",
		"text14": "정확한 지지 및 저항",
		"text15": "피보나치 기반 수준은 거래 전략을 최적화합니다.",
		"text16": "프로 투자자들로부터 높은 평가를 받고 있습니다.",
		"text17": "제가 지난 몇 년 동안 알고 지내던 정말 돈을 벌었던 위대한 무역업자들은 모두 당신의 길을 택했습니다.",
		"text18": "일리노이주 Watseka 사장, 확률형 발진기 설립자",
		"text19": "경험을 살 수는 없지만 joe의 책은 비싼 수업을 절약할 수 있습니다.",
		"text20": "30년 이상의 업계 경험을 가진 시장 전문가이자 무역 전문가인 Bressert Trading Group",
		"text21": "\"Trading with DiNapoli Levels\" 에서 그의 방법은 매우 실용적이어서 주식과 선물과 같은 강세장과 약세장에서도 똑같이 잘 작동합니다. 새로운 거래자는 몇 년 동안 학습 곡선을 벗어날 수 있고 오래된 거래자는 새로운 기술을 배울 수 있습니다.",
		"text22": "LBR 그룹의 위대한 거래자, 저자, 교육자",
		"text23": "DINAPOLI LEVELS에 관해 더 알아보기",
		"text24": "DiNapoli Levels 와 DiNapoli Experts에 관해 더 알아보기",
		"text25": "더 알아보기",
	},
}