export default {
  lang: "",
  交易领薪计划: "交易领薪计划",
  为了鼓励交易者提升交易水平:
    "为了鼓励交易者提升交易水平，我们推出了交易领薪计划。",
  加入交易社区: "加入交易社区，享丰厚奖励和专属福利，助力交易者成就交易传奇。",
  即刻发起交易信号: "即刻发起交易信号",
  无限奖励月月拿: "无限奖励月月拿",
  优享12个专属大礼: "优享12个专属大礼",
  即刻加入: "即刻加入",
  交易员等级奖励机制: `交易员等级奖励机制`,
  EBC基于跟随人数和资金提供五档奖励:
    "EBC基于跟随人数和资金提供五档奖励，不限制交易手数，仅以市场认可度为衡量标准。",
  月: "月",
  新锐交易员: "新锐交易员",
  精英交易员: "精英交易员",
  冠军交易员: "冠军交易员",
  明星交易员: "明星交易员",
  传奇交易员: "传奇交易员",
  管理资金: "+1%管理资金/年(月付）",
  管理资金量: "管理资金量",
  跟随用户数: "跟随用户数",
  多层次的增值服务: "多层次的增值服务",
  EBC提供多达11项增值服务:
    "EBC提供多达11项增值服务，整合流量和媒体资源，全面提升信号影响力。",
  "7×24小时优先客服": "7×24小时优先客服",
  参与EBC品牌营销互动: "参与EBC品牌营销互动",
  VPS赞助: "VPS赞助",
  金融投资类书籍赞助: "金融投资类书籍赞助",
  订单流软件一年使用权: "订单流软件一年使用权",
  EBC定制大礼包: "EBC定制大礼包",
  信号置顶展示: "信号置顶展示",
  受邀参观海外办公室: "受邀参观海外办公室",
  媒体资源推送: "媒体资源推送",
  品牌战略合作: "品牌战略合作",
  海外基金产品发行顾问服务: "海外基金产品发行顾问服务",
  六维指标评价系统: "六维指标评价系统",
  为交易员信号提供公开透明的评估:
    "为交易员信号提供公开透明的评估，展示交易风格，提升曝光率，从而吸引更多跟随者。",
  "最大回撤6.39%": "最大回撤6.39%",
  衡量风控能力: "衡量风控能力，越低风险越小。",
  "标准差1.59": "标准差1.59",
  收益波动率: "收益波动率，越低越稳定。",
  "盈利交易(胜率)75.82%": "盈利交易(胜率)75.82%",
  盈利订单占比: "盈利订单占比，越高获利概率越高。",
  "收益率406.06%": "收益率406.06%",
  历史收益率: "历史收益率，值越高越好。",
  "获利系数6.19": "获利系数6.19",
  利润因子: "利润因子，越高盈利能力越强",
  "夏普比率44.46": "夏普比率44.46",
  每份风险的收益: "每份风险的收益，越高性价比越高。",
  收益率: "收益率",
  最大回撤: "最大回撤",
  标准差: "标准差",
  获利系数: "获利系数",
  夏普比率: "夏普比率",
  "盈利交易(胜率)": "盈利交易(胜率)",
  以上数据仅供参考: "*以上数据仅供参考。",
  活动须知: "活动须知",
  交易员等级计算: "1.交易员等级计算",
  管理资金量跟随资金量:
    "管理资金量=跟随资金量+交易员（交易信号）账号自有资金量，资金量均以净值计算。",
  等级计算以自然月为周期:
    "等级计算以自然月为周期，跟管理资金量和跟随客户数连续一个月达标即达到相应等级。",
  有效跟随用户: "2.有效跟随用户",
  起始跟随金额: "起始跟随金额≥500USD计为1个有效跟随用户。",
  每个MT4账号最多跟随10个交易员: "每个MT4账号最多跟随10个交易员（交易信号）。",
  每个MT4账号跟随金额: "每个MT4账号跟随金额=跟随账户资金/信号数。",
  奖励结算: "3.奖励结算",
  次月第一周计算并发放上月奖励:
    "次月第一周计算并发放上月奖励，按上月达标的最低等级计算奖励。",
  所有奖励发到EBC后台钱包:
    "所有奖励发到EBC后台钱包，获奖者自行提取，请参赛者提前注册。",
  活动最终解释权归主办方EBC所有: "活动最终解释权归主办方EBC所有。",
};
