export default{
  "screen0": {
    "title": "以史為鑒放眼未來",
    "desc": "EBC多元資料中心,涵蓋全球主流資產類別10餘種",
    "list": [
      "100年經通脹調整後的主要市場指數",
      "超過20年的主要貨幣市場風雲變幻",
      "歷經半個多世紀貴金屬的驚濤駭浪",
      "1946年起原油的歷史漲跌全貌",
      "近23年北美瓦斯市場的跌宕起伏"
    ],
    "text": "過去與未來<br />一切盡在您的掌控"
  },
  "screen1": {
    "pageEnTitle": "Dow Jones - DJIA - 100 Year Historical Chart",
    "pageTitle": "道瓊指數（DJIA）100年歷史圖表",
    "title": "過去100年以來的<text style=“color:#6c100f”>道鐘斯工業平均（DJIA）指數</text>歷史圖表",
    "list": [
      "歷史資料使用經通脹調整後的CPI數據,每個數據點代表月末收盤價",
      "當前月份價格以最新的小時收盤價為准",
      "截至2020年10月20日,道鐘斯工業平均指數的當前價格為28308.79"
    ]
  },
  "screen2": {
    "pageEnTitle": "NASDAQ Composite - 45 Year Historical Chart",
    "pageTitle": "納斯達克綜合指數過去45年的歷史圖表",
    "title": "自1971年以來<text style=“color:#6c100f”>納斯達克綜合指數</text>的歷史圖表",
    "list": [
      "歷史資料使用經通脹調整後的CPI數據,每個數據點代表月末收盤價",
      "當前月份價格以最新的小時收盤價為准",
      "截至2020年10月20日,納斯達克綜合指數的當前價格為11516.49"
    ],
    "text": "了解股票价差合约",
    "btn": "Lab 学习中心 / 股票价差合约"
  },
  "screen3": {
    "pageEnTitle": "EUR USD- Historical Chart",
    "pageTitle": "歐元兌美元匯率歷史圖表",
    "title": "自1999年以來<text style=“color:#6c100f”>歐元兌美元（EUR USD）的日線圖</text>價格走勢"
  },
  "screen4": {
    "pageEnTitle": "GBP USD - Historical Chart",
    "pageTitle": "英磅兌美元匯率歷史圖表",
    "title": "自1971年以來<text style=“color:#6c100f”>英磅兌美元日線圖</text>價格走勢",
    "text": "瞭解衍生品交易",
    "btn": "Lab學習中心/衍生品板塊"
  },
  "screen5": {
    "pageEnTitle": "Gold Price - Last 10 Years",
    "pageTitle": "過去10年黃金價格",
    "title": "過去10年以<text style=“color:#6c100f”>美元計價的黃金</text>價格走勢",
    "list": [
      "截至2020年10月19日,現時的價格是1911.70美元"
    ]
  },
  "screen6": {
    "pageEnTitle": "WTI Crude Oil Prices - 10 Year Daily Chart",
    "pageTitle": "WIT原油價格10年日線圖走勢",
    "title": "過去10年<text style=“color:#6c100f”>裏西德克薩斯中質原油</text>的每日收盤價",
    "list": [
      "所示價格以美元計價",
      "截至2020年10月20日,WTI原油的當前價格為每桶41.65美元"
    ]
  },
  "screen7": {
    "pageEnTitle": "Natural Gas Prices - Historical Chart",
    "pageTitle": "瓦斯價格歷史圖表",
    "title": "<text style=“color:#6c100f”>亨利樞紐瓦斯</text>的歷史價格",
    "list": [
      "所示價格以美元計價",
      "截至2020年10月14日,瓦斯的當前價格為2.02美元"
    ],
    "text": "了解大宗商品",
    "btn": "Lab 学习中心 / 大宗商品"
  }
}
