export default {
    "box-class-name": "en-no1-box",
    "lang": "ja",
    "挑战最强交易环境": "最高の取引環境キャンペーン",
    "利润差额奖金": "差額の150％をキャッシュバック",
    "EBC的最强交易环境等您挑战": "最高レベルの取引環境を提供することに挑戦中！対象者様に差額の150％をキャッシュバック（上限なし）",
    "距挑战结束剩余": "終了まであと",
    "天": "日",
    "时": "時",
    "分": "分",
    "秒": "秒",
    "本次活动时间为2024年1月1日": "開催期間：1/1/2024 00:00:00 - 30/9/2024 23:59:59（GMT+2）",
    "发起挑战": "参加はこちら",
    "挑战规则": "参加の流れ",
    "EBC致力于为投资者提供极低交易成本": "同じ取引戦略でも、取引コストや市場深度、注文執行速度などで利益に差が出てくるはずです。当社のキャンペーンに参加して、最高の取引環境を体験してみよう！",
    "开设账户": "口座開設",
    "在EBC平台开设一个交易账户": "EBCで口座登録して取引口座を開設する ",
    "选择平台": "他社の取引口座を用意",
    "选择一家具备FCA或ASIC监管资质的交易商平台": "FCAまたはASICライセンスを保有する他社ブローカーが対象",
    "相同策略交易": "両口座で同条件で取引",
    "使用相同的交易策略在EBC和所选平台进行交易": "EBCと他社口座の両方で同じ条件で取引する",
    "收益比较": "結果比較",
    "在EBC的盈利低于或亏损高于所选择交易商": "EBCでの利益が他社より少ない、またはEBCでの損失が他社より多い場合、その差額の150％を還元します！",
    "卓越的交易环境": "最高の取引環境",
    "EBC以一流的交易执行速度和先进的技术支持": "EBCは、トップクラスの約定速度と高度なディーリングテクノロジーで、投資家の皆様の高い水準に見合う、卓越した取引体験を提供します。",
    "科技赋能交易": "テクノロジーを駆使した取引",
    "部署于伦敦LD4": "ロンドン（LD4）、ニューヨーク（NY4）、シンガポール（SG1）、東京（TY3）、香港（HK2）に設置された独立サーバーは、超高速専用回線で接続されています。超低遅延アグリゲーション、スマート・オーダー・ルーティング、気配値最適化エンジンを構築することで、グローバルからの取引を迅速に対応しています。",
    "让85%以上的订单成交于更优的价格": "85%以上の注文がより良い価格で約定",
    "EBC交易黑盒": "大口注文は小口注文に比べて、スリッページが起こりやすく、約定率も多少低くなる傾向にありますが、EBCのブラックボックスは独自の注文アルゴリズムを駆使し、価格の最適化、注文執行率、アルゴリズムの安定性において従来のシステムを凌駕しています。",
    "EBC交易空间": "プライベートルーム",
    "无论您是高频交易者": "EBCは、スキャルピングや大口注文に向いています。その理由は、当社のプライベート・ルームと呼ばれるディーリング機能の一つで、高頻度注文や大口注文に対して専用の流動性マッチングを行い、お客様の取引手法をLPから守りながら約定を行っているからです。",
    "国际顶级流动性": "世界トップクラスの流動性 ",
    "EBC与多家国际顶级银行建立了长期稳定的合作关系": "EBCは、複数の大手国際銀行と長期にわたる安定したパートナーシップを確立し、高品質の流動性相場を統合して、競争力の高いスプレッドを提供しています。",
    "活动须知": "キャンペーン概要",
    "参与账户": "EBC取引口座について",
    "新用户": "EBCのVIPアカウントのみが参加対象となります。VIPアカウントは、bd.jp@ebc.com にメールしていただき申請する必要があります。",
    "现有用户": "メール本文に、本キャンペーンに参加する旨と、参加アカウントの電話番号（またはメールアドレスやアカウント番号）をご送付ください。",
    "对比交易商平台要求": "他社ブローカーの取引口座について",
    "选择持有英国FCA或澳洲ASIC监管的交易商平台": "取引口座自体はFCAやASIC下で開設する必要がありませんが、ブローカーは英国FCAまたはオーストラリアASICライセンスを保有する必要があります。AR、EEAまたはTrading name口座は対象外です。",
    "交易策略": "取引について",
    "仅限以下主流货币对": "対象通貨ペア： AUD/USD、USD/CAD、EUR/USD、USD/CHF、GBP/USD、USD/JPY、NZD/USD",
    "在EBC和所选交易商使用相同的交易资金和交易策略": "なお、EBCと他社口座の両方は同一条件で取引することが必要です。具体的に資金、取引手法・タイミング・レバレッジ等すべてが対象です。また取引手法は、通常の取引慣行の範囲内でなければなりません。",
    "奖金结算": "キャッシュバックについて",
    "挑战者在其他平台的交易返佣可计入盈利": "キャンペーン期間中いつでもご任意のタイミングで参加終了することができますが、各IDで1回のみの参加になります。",
    "挑战者可在任意挑战时间内选择结算": "参加終了後、取引履歴をbd.jp@ebc.com へ提出してください。",
    "挑战结束后": "※取引履歴のダウンロード方法は、MT4のターミナル＞「口座履歴」＞「詳細レポートの保存」をクリックし、HTMLファイルまたはPDFをご提出ください。",
    "重要声明": "注意事項",
    "参赛者应知晓交易风险": "ご参加の際に、各社の取引リスク、利用規約および取引規則等を事前に確認してください。EBCは不可抗力に起因する取引上の問題や口座損失について責任を負いません。",
    "任何不遵守比赛规则或篡改数据的行为": "当社は、参加者様に注文または口座について追加情報を提出していただく場合があります。",
    "EBC有权要求参赛者提供有争议的订单或账户的补充材料": "本キャンペーンは予告なく変更、中止または終了させていただく場合がございます。",
    "本次挑战赛旨在提供公平": "本キャンペーンは、公正かつ公平な取引環境を提供することを目的としています。当社サービス利用において不正行為と合理的に判断された場合は、キャンペーン対象外とさせていただきます。また、法的措置を講ずる場合もあります。",
    "EBC有权根据法律法规": "本キャンペーンの最終的な解釈はEBCに帰属するものとします。",
    "本活动最终解释权归EBC所有":""

}