export default {
  "screen0": {
    "title": "เรียนรู้จากอดีตเพื่อสร้างอนาคตที่สดใส",
    "desc": "ศูนย์ข้อมูลหลายมิติของ EBC ,<text style='color:#6c100f'>ครอบคลุมสินทรัพย์หลักมากกว่า 10 ประเภท</text>",
    "list": [
      "ข้อมูลมากกว่า 100 ปี ของการปรับอัตราเงินเฟ้อสำหรับดัชนีตลาดหลัก",
      "ข้อมูลมากกว่า 20 ปีของตลาดสกุลเงินที่เปลี่ยนแปลงตลอดเวลา",
      "ความผันผวนอย่างต่อเนื่องของข้อมูลโลหะมีค่าที่กินเวลากว่าครึ่งศตวรรษ",
      "ขาขึ้นและลงของตลาดน้ำมันดิบตั้งแต่ปี 1946",
      "ความวุ่นวายของตลาดก๊าซธรรมชาติในอเมริกาเหนือในช่วง 23 ปีที่ผ่านมา"
    ],
    "text": "อดีตและอนาคต<br/>ปลายนิ้วของคุณ"
  },
  "screen1": {
    "pageEnTitle": "กราฟดัชนีดาวโจนส์ย้อนหลัง 100 ปี",
    "pageTitle": "",
    "title": "กราฟดัชนีดาวโจนส์ของตล<text style='color:#6c100f'>าดหุ้นสหรัฐฯ ในช่วง</text>100 ปีที่ผ่านมา",
    "list": [
      "ข้อมูลในอดีตคือการปรับเงินเฟ้อโดยอ้างอิงจากดัชนีราคาผู้บริโภค(CPI)จุดข้อมูลแต่ละจุดแสดงถึงระดับจุดปิดของราคาตอนสิ้นเดือน",
      "เดือนปัจจุบันมีการอัปเดตเป็นรายชั่วโมงด้วยค่าล่าสุดของวันนี้",
      "ราคาปัจจุบันของดัชนีดาวฌจนส์ ณ วันที่ 20 ตุลาคม 2020 คือ 28,308.79 จุด"
    ]
  },
  "screen2": {
    "pageEnTitle": "กราฟดัชนีแนสแด็กย้อนหลัง 45 ปี",
    "pageTitle": "",
    "title": "กราฟดัชนีแนสแด็กของตล<text style='color:#6c100f'>าดหุ้นสหรัฐฯ</text>ตั้งแต่ปี 1971",
    "list": [
      "ข้อมูลในอดีตคือการปรับเงินเฟ้อโดยอ้างอิงจากดัชนีราคาผู้บริโภค(CPI)จุดข้อมูลแต่ละจุดแสดงถึงระดับจุดปิดของราคาตอนสิ้นเดือน",
      "เดือนปัจจุบันมีการอัปเดตเป็นรายชั่วโมงด้วยค่าล่าสุดของวันนี้",
      "ราคาปัจจุบันของดัชนีดาวฌจนส์ ณ วันที่ 20 ตุลาคม 2020 คือ 28,308.79 จุด"
    ],
    "text": "เข้าใจความแตกต่างของราคาหุ้น",
    "btn": "ศูนย์การเรียนรู้ทางห้องปฏิบัติการ"
  },
  "screen3": {
    "pageEnTitle": "กราฟย้อนหลังอัตราแลกเปลี่ยนดอลลาร์ยูโร(EURUSD)",
    "pageTitle": "",
    "title": "กราฟรายวันของอัตราแลกเปลี่ยน<text style='color:#6c100f'>ยูโรเทียบดอลลาร์สหรัฐ(EURUSD)</text>ที่สามารถดูย้อนหลังไปจนถึงปี 1999"
  },
  "screen4": {
    "pageEnTitle": "กราฟย้อนหลังอัตราแลกเปลี่ยนปอนด์เทียบดอลลาร์สหรัฐ(GBPUSD)",
    "pagetitle": "กราฟรายวันของอัตราแลกเปลี่ยน<text style='color:#6c100f'>ปอนด์เทียบดอลลาร์สหรัฐ(GBPUSD)</text>ที่สามารถดูย้อนหลังไปจนถึงปี 1971",
    "title": "กราฟรายวันของอัตราแลกเปลี่ยน<text style='color:#6c100f'>ปอนด์เทียบดอลลาร์สหรัฐ(GBPUSD)</text>ที่สามารถดูย้อนหลังไปจนถึงปี 1971",
    "text": "",
    "btn": "ศูนย์เรียนรู้"
  },
  "screen5": {
    "pageEnTitle": "ราคาทองคำ - 10 ปีที่ผ่านมา",
    "pageTitle": "",
    "title": "กราฟราค<text style='color:#6c100f'>าทองคำเทียบดอลลาร์สหรัฐ</text>ในช่วง 10 ปีที่ผ่านมา",
    "list": [
      "ปัจจุบัน ณ วันที่ 19 ตุลาคม 2020 คือ $ 1,911.70"
    ]
  },
  "screen6": {
    "pageEnTitle": "ราคาน้ำมันดิบ WTI - กราฟรายวัน 10 ปี",
    "pageTitle": "",
    "title": "กราฟราค<text style='color:#6c100f'>าน้ำมันดิบ WTI บนตลาด NYMEX</text>ในช่วง 10 ปีที่ผ่านมา ",
    "list": [
      "ราคาที่แสดงอยู่ในยูโรดอลลาร์สหรัฐ",
      "ราคาปัจจุบันของน้ำมันดิบ WTI ณ วันที่ 20 ตุลาคม 2020 คือ $ 41.65 ต่อบาร์เรล"
    ]
  },
  "screen7": {
    "pageEnTitle": "กราฟราคาก๊าซธรรมชาติย้อนหลัง",
    "pageTitle": "",
    "title": "กราฟราค<text style='color:#6c100f'>าก๊๊าซธรรมชาติที่ส่งมอบจากท่า</text> เรือเฮนรี่ย้อนหลัง",
    "list": [
      "ราคาที่แสดงอยู่ในยูโร ดอลลาร์สหรัฐ ราคาปัจจุบันของก๊าซธรรมชาติเมื่อวันที่ 14 ตุลาคม 2020 คือ $ 2.02",
      "ปลดล็อกข้อมูลประวัติการเทรดที่คุณสนใจ"
    ],
    "text": "เรียนรู้เกี่ยวกับสินค้า",
    "btn": "ศูนย์เรียนรู้"
  }
}
