export default {
	"screen0": {
		"title": "<span>We Value Your Safety</span><br>Được cấp phép, an toàn và ổn định",
	},
	"screen1": {
		"link1": "Giới thiệu EBC",
		"link2": "Giám sát hàng đầu",
		"link3": "EBC vinh hạnh",
		"link4": "Ưu điểm của EBC",
		"link5": "Văn hóa công ty",
		"link6": "Hợp tác",
		"link7": "Liên hệ với chúng tôi",

	},
	"screen2": {
		"title": "Giới thiệu EBC",
		"desc1": "EBC Financial Group được thành lập tại London, Anh, là một tập đoàn tài chính toàn diện tích hợp môi giới tài chính, quản lý tài sản, đầu tư định cư và các dịch vụ khác. Chi nhánh của EBC nằm ở rất nhiều nước trên thế giới, có văn phòng tại Tokyo, Sydney, Singapore, Hong Kong và các trung tâm tài chính quốc tế khác. Đội ngũ quản lý có hơn 30 năm kinh nghiệm hoạt động trong thị trường tài chính toàn cầu.",
		"desc2": "EBC luôn coi việc duy trì sự an toàn của các quỹ là trách nhiệm chính của mình. Ngoài việc có sự giám sát chặt chẽ nhất trên thế giới, EBC còn cung cấp tài khoản ký quỹ độc lập của Ngân hàng Barclays cho các đối tác, mua bảo hiểm với số tiền bảo hiểm hơn 10 triệu đô la Mỹ mỗi năm và thực hiện nghiêm túc các quy định của CASS, với tiềm lực vốn vững mạnh và các biện pháp quản lý rủi ro ở mức cao nhất, nhằm giúp khách hàng tránh được những yếu tố rủi ro không chắc chắn.",
		"desc3": "Chúng tôi tin rằng mỗi một nhà giao dịch nghiêm túc đều xứng đáng được coi trọng."
	},
	"screen3": {
		"title": "Giám sát hàng đầu",
		"desc": "Các công ty con thuộc EBC Financial Group đều được quản lý chặt chẽ và nhận giấy phép hàng đầu trong các khu vực pháp lý tương ứng.",
		"desc-l1": "EBC Financial Group (UK) Ltd",
		"desc-l2": "được ủy quyền và quản lý bởi Cơ quan Quản lý Tài Chính (FCA).",
		"desc-l3": "Số giấy phép: 927552",
		"desc-r1": "EBC Financial Group (Australia) Pty Ltd",
		"desc-r2": "được ủy quyền và quản lý bởi Ủy ban Chứng khoán và Đầu tư (ASIC).",
		"desc-r3": "Số giấy phép: 500991",
		"desc-rr1": "EBC Financial Group (Cayman) Ltd",
		"desc-rr2": "được cấp phép và quản lý bởi Cơ quan tiền tệ Quần đảo Cayman (CIMA).",
		"desc-rr3": "Số giấy phép: 2038223",
	},
	"screen4": {
		"title": "EBC vinh hạnh",
		"desc1": "Nhà môi giới Forex đáng tin cậy nhất",
		"desc2": "Nhà môi giới CFD tốt nhất",
		"desc3": "Nhà môi giới ECN tốt nhất thế giới",
		"desc4": "Nhà môi giới mới của năm",
		"desc5": "TOP 10 Broker được hoan nghênh nhất",
		"desc6": "Nhà môi giới minh bạch nhất",
		"desc7": "Nhà môi giới được xếp hạng tốt nhất",
		"desc8": "Nhà môi giới hiệu quả nhất",
		"desc9": "Giải thưởng Trải nghiệm Giao dịch Tốt nhất",
	},
	"screen5": {
		"title": "Ưu điểm của EBC",
		"list": [
			{
				"title": "1000<i>đơn</i>",
				"desc": "Số đơn cao nhất trên mỗi giây"
			},
			{
				"title": "<20<i>MS</i>",
				"desc": "Tốc độ xử lý trung bình"
			},
			{
				"title": "98<i>.75%</i>",
				"desc": "Độ ổn định của dữ liệu đạt tới"
			},
			{
				"title": "25<i>+</i>",
				"desc": "Kết nối với các ngân hàng hàng đầu và quỹ bảo hộ"
			},
			{
				"title": "24/7",
				"desc": "Dịch vụ dành riêng cho VIP"
			},
			{
				"title": "0.0<i>PIPS",
				"desc": "RAW ECN Spreads cấp liên ngân hàng"
			},
		]
	},
	"screen6": {
		"title": "Văn hóa công ty",
		"desc": "Giá trị cốt lõi của chúng tôi là tạo ra \"Real Value\" cho khách hàng",
		"list": [
			{
				"title": "An toàn",
				"desc": "Giám sát kép hàng đầu FCA và ASIC, ngân hàng Barclays quản lý Đảm bảo an toàn mức thanh khoản"
			},
			{
				"title": "Công bằng",
				"desc": "EBC duy trì các nguyên tắc liêm chính và tôn trọng Đối xử nghiêm túc với mỗi một nhà giao dịch"
			},
			{
				"title": "Chú trọng",
				"desc": "Cảm nhận sự phục vụ của EBC trong từng chi tiết nhỏ Cung cấp trải nghiệm vượt trội cho khách hàng"
			},
			{
				"title": "Giá trị",
				"desc": "\"An toàn, chuyên nghiệp, ổn định\" Nâng cao giá trị tài sản cá nhân một cách toàn diện"
			},
			{
				"title": "Chân thành",
				"desc": "EBC coi sự trung thực và sự tin cậy là nền tảng Mang đến trải nghiệm giao dịch hoàn hảo bằng cả trái tim"
			},
			{
				"title": "Trách nhiệm",
				"desc": "Mỗi một đơn hàng đều làm cho thế giới trở nên tốt đẹp hơn"
			},
		]
	},
	"screen7": {
		"title": "Hợp tác",
	},
	"screen8": {
		"title": "Liên hệ với chúng tôi",
		"desc1": "Hợp tác thị trường",
		"desc2": "Gia nhập với chúng tôi",
		"desc3": "Tố cáo và kiến nghị",
	},
}

