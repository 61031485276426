export default {
	"screen0": {
		"title": "值得信赖的<br>MAM解决方案",
		"content": "EBC 特别为专业资金管理者量身打造了一款Multi Account多账户资金管理系统，允许您使用定制的分配方法，集合式下单软件，实时报告性能和实时更新佣金报告来实现多个账户的统筹管理。为基金经理和寻找专业人士管理资金的客户提供了可靠的合作途径。",
		"list": [
			"弹性分配",
			"即时报告",
			"简易操作界面"
		],
		"btn": "即刻申请MAM账户",
		"rightTitle": "EBC 多账户管理者系统（MAM）整合了弹性分配、<br>即时报告，以及简易化操作界面。"
	},
	"screen1": {
		"pageEnTitle": "Asset Management",
		"pageTitle": "资金管理",
		"title": "专为 MetaTrader 4 设计",
		"desc": "对于利用智能全自动系统（EA）管理多账户的交易者而言，是理想之选。资金管理程序实现了单一MT4界面有效交易并管理多账户。",
		"text": "便可快捷、有效、准确的，为多个<span>投资者</span>进行交易。",
		"btn": "了解更多",
		"imgUrl": "zh"
	},
	"screen2": {
		"pageEnTitle": "Intelligent Transaction",
		"pageTitle": "智能分配交易",
		"title": "通过单一交易账户，便可快捷的为多个投资者进行交易",
		"desc": "MAM账户相当于一个主账户，下面有若干个跟随账户，他们之间是相互独立的，是一种跟单形式。投资者只需输入交易总手数，系统便会按照投资者选择的分配方式执行交易，把交易手数自动分配给不同的个人投资者。账户管理者通过单一交易账户，便可快捷、有效和准确的为多个投资者进行交易。"
	},
	"screen3": {
		"pageEnTitle": "Advantages & Functions",
		"pageTitle": "MAM功能",
		"pageTitle2": "MAM优势",
		"title": "适用于任何类型的交易和资金管理",
		"content": [
			"十种分配方式可选，包括损益分配、交易复制分配等",
			"适用于任何类型的交易和资金管理",
			"交易账号数不限",
			"交易—完整账号，迷你账号，最小份额账号",
			"损益分配点精确到16位",
			"任意订单类型，止损/止盈，待办订单功能",
			"主账号执行订单或个人子订单部分关闭",
			"支持客户端管理账号智能全自动系统交易"
		],
		"desc": "Apply for a MAM Account",
		"btn": "申请MAM资金管理者账号",
		"line1": "分配参数即时变更",
		"line2": "所有管理账号公开实时位置控制",
		"line3": "简单一个界面控制所有管理账号",
		"line4": "“群交易”执行",
		"line5": "管理账户实时资产和损益监控",
		"line6": "获取历史报告并计算分成利润",
		"btn1": "申请MAM资金管理者账号"
	},
	"styleName": ""
}

