export default {
	"box-class-name": "oxford-box-en",
	"button-text": "もっと詳しく知る",
	"oxford-page1": {
		"text1": "WHAT<br> ECONOMISTS<br> REALLY<br> DO",
		"text2": "租税回避に関する経済学",
		"text3": "Sarah Clifford, with David Barrett",
		"text4": "今すぐ予約する",
		"text5": "2024年3月6日（水）21：00～22：00　（日本時間）",
	},
	"oxford-page2": {
		"text1": "教育を通じた経済の繁栄",
		"text2": "EBC FINANCIAL GROUPがオックスフォード大学とのウェビナー「経済学者が実際に何をしているか」を共催",
		"text3": "企業の社会的責任（CSR）への継続的な取り組みとして、 EBC Financial Groups は社会進歩を促進する先駆者を積極的に支援しています。教育や人材育成への意義ある貢献を目指し、オックスフォード大学などの機関と協力しています。",
		"text4": "",
		"text5": "イベント紹介",
		"text6": "「経済学者が実際に何をしているか」というウェビナーシリーズは、2024年3月6日（水）21時～22時（日本時間）に予定されており、「租税回避に関する経済学」というテーマで行われます。このウェビナーは、金融業界が直面している問題と、経済学および公共教育が政策の改善や経済の繁栄を促進するためにどのように発揮できるかを明らかにします。",
	},
	"oxford-page3": {
		"text1": "関係者",
		"text2": "オックスフォード大学経済学部、経済学準教授",
		"text3": "Sarah氏は公共経済学を専門とし、特に税金に関連する経済行動、国際企業税、多国籍企業の租税回避、家計による個人所得税回避に焦点を当てています。専門分野には公共経済学と応用ミクロ経済学が含まれます。",
		"text4": "ナフィールド大学経済学教授、副学長（外部連携）",
		"text5": "Abi氏の応用経済学に関する研究では、消費者と家族の選択の計量分析に焦点を当てました。 研究の主なテーマは、データに対する意思決定に新しいモードを導入する実証的手法の開発、労働市場をより深く理解するために大規模なデータセットを活用すること、英国の法制度における司法へのアクセスを定量化するためのフレームワークの開発などです。",
		"text6": "CEO, EBC Financial Group (UK) Ltd.",
		"text7": "Barrett氏は、大手金融機関からベンチャー企業に至るまで、外国為替、債券、デリバティブ等の金融商品分野において35年以上従事し、専門知識と経験が豊富な金融プロフェッショナルです。 世界的な金融危機と規制変革を乗り越えられた彼は、経済サイクルと金融規制環境に対して深く理解し、リスク管理とビジネス戦略について鋭いインサイトを提供できます。",
	},
	"oxford-page4": {
		"text1": "EBC Financial Group について",
		"text2": "ロンドンに設立された EBC Financial Group は、東京、シドニー、シンガポール、香港などの主要な国際金融都市に拠点を擁しており、外国為替、コモディティ、株価指数において革新的な取引サービスを提供しています。英国FCAライセンスや豪州ASICライセンス等複数の金融規制当局の認可を受けております。<br><br>EBCは誠実さと顧客第一の経営理念を大切にし、グローバルでは複数の賞を受賞しました。EBCは高度なインフラ、独自の受発注ツール、高い流動性に加え、迅速な注文執行及び24時間365日のカスタマーサポートを提供しています。<br><br>すべてのトレーダーに対して、誠実に対応いたします。",
		"text3": "オックスフォード大学経済学部について",
		"text4": "オックスフォード大学の経済学部は、影響力のある研究成果と政策への貢献、活発な若手研究者コミュニティ、評判の高い教育プログラム等の面で世界的に有名であり、そして世界で最大かつ最も多様な経済学術コミュニティの一つとして誇ります。",
	},
	lang:"ja",
	经济学家都干了什么: "経済学者の研究公開",
	如何利用气候经济学: "持続可能性の維持：経済成長と気候レジリエンスのバランス",
	挖掘投资机遇: "",
	"2024年11月14日": "2024年11月14日（木）23:30（日本時間）",
	"牛津大学Michael Dummett讲座厅": "オックスフォード大学Michael Dummett レクチャーホール  ",
	预约报名: "今すぐ登録する",
	EBC邀您游览牛津大学: "当社がオックスフォード大学と共催するセミナーにぜひご参加ください",
	EBC金融集团再次携手牛津大学: `EBC Financial Groupはオックスフォード大学と提携し、2024年11月14日（木）にグローバルセミナーを開催いたします。同セミナーは、EBCとオックスフォード大学のパートナーシップにおける初のハイブリッド・イベント（オフラインとオンラインで実施）であり、オックスフォード大学と共催する「経済学者の研究公開」シリーズで座談会形式の質疑応答を行う初めてのイベントとなります。今回のセミナーは、経経済成長と気候レジリエンスのバランスをテーマとし、気候経済学と投資見通しの課題について検討します。`,
	活动介绍: "イベント詳細",
	"本次线下研讨会将于11月14日14:30": `オフライン・セミナーは11月14日（木）23時30分（日本時間）にオックスフォード大学のクライストチャーチ・カレッジで開催され、世界中の投資家が参加できるオンライン・セミナーとして配信されます。`,
	EBC金融专家还将与您一同漫步在新学院的回廊:
	  "また、『ハリー・ポッターと炎のゴブレット』でおなじみのニューカレッジの回廊を散策したり、世界中の学者たちの学問の聖地であるオックスフォード大学のボドリアン図書館で、何世紀にもわたって受け継がれてきたオックスフォードの文化遺産に浸り、オックスフォードならではの文化的な魅力を体験していただくこともできます。",
	与会嘉宾: "ゲストスピーカー",
	牛津大学环境复原力及马丁系统韧性负责人:
	  "オックスフォード大学環境レジリエンスとマーティン・システムズ・レジリエンスの責任者",
	"Nicola Ranger是牛津大学环境复原力及马丁系统韧性负责人": `オックスフォード大学環境レジリエンスおよびマーティン・システムズ・レジリエンス所長。オックスフォード大学環境変動研究所にグローバル・ファイナンス＆エコノミクス・グループを設立し、金融機関、中央銀行、各国政府と緊密に連携しながら、金融、気候、自然、分析が交差する最先端の研究を推進している。Ranger氏は、国際通貨基金（IMF）、世界銀行、NGFS、英国気候金融リスクフォーラムと協力し、ストレステストやシナリオ分析手法の開発に携わってきた。また、欧州委員会の持続可能な投資に関するハイレベル専門家グループ、英国のグリーンテック（分類法）諮問グループ、FCDO、ロンドン・スクール・オブ・エコノミクスのアドバイザーも務めています。`,
	查看更多: "詳しくはこちら",
	牛津大学经济学系教授: "オックスフォード大学経済学部教授",
	牛津大学经济学系教授2: "オックスフォード大学経済学部教授",
	"Andrea Chiavari 是牛津大学经济学系教授": `専門はマクロ経済学、特に企業力学と技術革新。経済モデルがいかに持続可能な開発を支援できるかを探求しているほか、気候変動が企業の生産性や資本に与える影響についても研究している。気候変動が企業の生産性と資本配分に与える影響について研究しており、特に不確実な環境における経済効率とレジリエンスの強化、経済成長の推進におけるテクノロジーの役割に焦点を当てて研究を進めている。`,
	"Banu Demir Pakel 是牛津大学经济学系教授": `国際貿易と開発経済学の接点で、企業が貿易関連のショックにどのように適応し、それが成長や所得といった経済全体の成果をどのように形成するかに焦点を当てて研究しています。CEPR（国際貿易・地域経済プログラム）研究員、ミュンヘンのCESifo Research Network研究員。`,
	"EBC金融集团（英国）首席执行官": "EBC Financial Group 英国法人CEO",
	"David Barrett是EBC 金融集团 (英国) CEO": `Barrett氏は、大手金融機関からベンチャー企業に至るまで、外国為替、債券、デリバティブ等の金融商品分野において35年以上従事し、専門知識と経験豊富な金融プロフェッショナルです。世界的な金融危機と規制変革を乗り越え、経済サイクルと金融規制環境に対する深い理解と、リスク管理とビジネス戦略について知見を有しています。`,
	往期回顾: "過去のイベント",
	EBC金融集团联合牛津大学经济学系第一期:
	  "EBC Financial Groupがオックスフォード大学とのウェビナー「経済学者が実際に何をしているか」を共催",
	"2024年3月6日EBC与牛津大学联合举办主题为": `2024年3月6日、EBCはオックスフォード大学と「経済学者の研究公開」をテーマにセミナーを共催しました。同セミナーでは、政策の改善と経済の繁栄を推進するために経済学をどのように活用できるかを探求し、「租税回避に関する経済学」をテーマに、さまざまな租税環境における経済動向と、世界経済の安全保障における経済学者の役割について議論を行いました。`,
	关于EBC金融集团: "EBC Financial Group について",
	"EBC Financial Group 成立于英国伦敦": `EBC Financial Groupは、ロンドンで設立され、シドニー・香港・シンガポール・バンコク・キプロスなど主要な金融センターにオフィスを構えております。世界中のトレーダーや機関投資家を対象に、FX取引を含む金融仲介・資産運用・投資ソリューションなど、包括的な金融サービスを提供しています。当社の経営陣は大手金融機関で30年以上の経験を持ち、1985年のプラザ合意、1997年のアジア金融危機、2008年の世界金融危機、2015年のスイスフランのブラック・スワン・イベントなど、複数の経済サイクルを経験してきました。当社はFCバルセロナの金融分野におけるオフィシャル・パートナーであり、マラリア撲滅と世界の保健システム改善のために国連財団と協力しています。`,
	关于牛津大学经济学系: "オックスフォード大学経済学部について",
	牛津大学是享誉全球的顶尖高等学府: `オックスフォード大学は、世界的に有名な高等教育機関であり、英国最大の研究機関です。約1,000年の歴史を通じて、オックスフォード大学は、オープンで包括的な学術環境の創造、革新的な経済研究の創出、幅広い分野にわたる国際協力と交流の構築、人類社会の発展と進歩の促進に尽力してきました。`,
	牛津大学经济学系以其专业权威的学术研究: `オックスフォード大学の経済学部は、影響力のある研究成果と政策への貢献、活発な若手研究者コミュニティ、評判の高い教育プログラム等の面で世界的に有名であり、そして世界で最大かつ最も多様な経済学術コミュニティの一つとして誇ります。    `,
}