export default {
  "screen0": {
    "pageTitle": "ร่วมสร้าง",
    "enTitle1": "ร่วมสร้าง",
    "enTitle2": "<text style='color:#800'>อนาคตไปด้วยกัน</text>",
    "title1": "เป็นพาร์ทเนอร์กับเราเพื่อปลดล็อค",
    "title2": "โอกาสในตลาดที่มากขึ้น ",
    "list": ["ได้รับการรับรองอย่างถูกต้องและสามารถเชื่อถือได้", "การเปิดกว้างและความร่วมมือจากทั่วโลก"," ชุดโซลูชันทางเทคนิคเต็มรูปแบบ "],
    "desc": "EBC ให้ความสำคัญกับพันธมิตรทุกรายที่ร่วมมือกับเราอย่างจริงจัง หากคุณรู้สึกมีความสนใจ//ด้านความร่วมมือที่เป็นนวัตกรรมใหม่สร้างความหลากหลายที่มากขึ้น สามารถปรึกษาเราได้//เราพร้อมเปิดขอบเขตการลงทุนให้กว้างขึ้นมอบโซลูชันการสนับสนุนที่หลากหลายเปิดโอกาส//ในการพัฒนาไปทั่วโลก ปลดล็อคการร่วมมือแบบไร้ขีดจำกัด",
    "links": [{
      "url": "/about-us",
      "text": "ทำไมต้อง EBC?"
    }, {
      "url": "",
      "text": "เรียนรู้เพิ่มเติม"
    }],
		"more": "เรียนรู้เพิ่มเติม"
  },
  "screen1": {
    "pageTitle": "การปรับแต่ง",
    "pageEnTitle":"การปรับแต่ง",
    "title": "ผู้แนะนำโบรกเกอร์ (IB) <span> โครงการที่มีเงินหมุนเวียน</span> มากกว่าหนึ่งล้านดอลลาร์ ",
    "desc": "เพราะ EBC ไม่สามารถบรรลุการพัฒนาในระยะยาวได้หากไม่ได้รับการสนับสนุนจาก IB ดังนั้นเราจึงมอบโอกาสในการพัฒนาให้กับ IB ให้ได้แสดงศักยภาพจนถึงที่สุด",
    "list": ["โครงสร้างค่าคอมมิชชั่น", "นวัตกรรมการตลาดและทรัพยากรการซื้อขาย ", "ขั้นตอนการชำระเงินและการชำระเงินที่มีประสิทธิภาพ", "ผู้จัดการลูกค้าคนพิเศษ", "ระบบคอมมิชชั่นหลายระดับ", "ทีมสนับสนุนลูกค้าที่พูดได้หลายภาษา ",
      "เครื่องมือการสนับสนุนและการทำรายงานที่สมบูรณ์แบบ", "แผนพัฒนาธุรกิจ"],
    "text": "<span> แผนการพัฒนาที่กำหนดเองทั้งหมด </span>",
    "more": "ดูบริการเพิ่มเติม"
  },
  "screen2": {
    "pageTitle": "บริการการซื้อขาย",
   "pageEnTitle": "บริการการซื้อขาย",
    "title": "ชุดความร่วมมือการพัฒนาธุรกิจที่สมบูรณ์แบบ",
   "desc": "โปรดแจ้งความต้องการของคุณให้เราทราบ และ EBC ไม่เพียงแต่มอบประสบการณ์และข้อเสนอแนะเชิงปฏิบัติให้กับคุณเท่านั้น แต่ยังมอบโซลูชันทางเทคนิคที่ปรับแต่งตามความต้องการของคุณเพื่อให้ตรงตามข้อกำหนดในขั้นตอนการพัฒนาธุรกิจต่างๆ",
    "list": ["ชุมชนการเทรด", "สัญญาณการเทรด", "การจัดการการควบคุมความเสี่ยง"],
    "btn": "เข้าสู่ชุมชนการเทรด"
  },
  "screen3": {
    "pageTitle": "บริการสภาพคล่อง",
    "pageEnTitle": "บริการสภาพคล่อง",
    "title": "EBC ใช้โมเดลการประมวลผลโดยตรงแบบ STP",
    "desc": "รับการเข้าถึงโดยตรงไปยังผู้ให้บริการสภาพคล่องระหว่างประเทศระดับแนวหน้า เพื่อลดต้นทุนการป้องกันความเสี่ยงของนายหน้าและดำเนินการตามคำสั่งอย่างรวดเร็ว และเรายังมีทีมเทคนิคเฉพาะเพื่อให้บริการสนับสนุนด้านเทคนิคตลอด 24 ชั่วโมงทุกวัน",
    "remark": "เวลา จะทำให้เรารู้จักกันมากยิ่งขึ้น "
  },
  "screen4": {
    "pageTitle": "ความร่วมมือในรูปแบบธุรกิจเกิดใหม่",
    "pageEnTitle":  "ความร่วมมือในรูปแบบธุรกิจเกิดใหม่",
    "title": "ความร่วมมือในรูปแบบธุรกิจเกิดใหม่",
    "desc": "EBC มีพลังและความสามารถที่จะรับมือกับความเปลี่ยนแปลง พร้อมเผชิญหน้ากับโมเดลการพัฒนารูปแบบใหม่ๆ ในอตสาหกรรม เราลงมือทำนวัตกรรมใหม่ๆอย่างต่อเนื่องและการเปลี่ยนแปลงตั้งแต่เราเชื่อว่าไม่เพียงแค่ติดตามแนวโน้มการพัฒนาในตลาดแต่เราสามารถก้าวไปข้างหน้าอย่างต่อเนื่อง หากคุณมีแผนความร่วมมือที่เป็นนวัตกรรมใด ๆ โปรดติดต่อเราได้",
    "list": ["สินทรัพย์ 4 ระดับ  ", "เครื่องมือการซื้อขายมากกว่า 100 แบบ"]
  },
	"box-class": "partner-box-en"
}
