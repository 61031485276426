export default {
	"box-name": "",
	"screen0": {
		"title": "交易账户",
		"desc": "无论您的交易经验或交易风格如何，<br/>我们都有适合您需求的交易账户"
	},
	"screen1": {
		"btn": "了解更多",
		"title1": "STD 标准账户",
		"title2": "PRO 专业账户",
		"line11": "低至 1.1",
		"line13": "低至 0.0",
		"line99": "无限制",
		"line100": "110USD",
		"list": [
			"标准点差",
			"手续费",
			"最大杠杆",
			"最小单笔交易量",
			"最大单笔交易量",
			"最低存款金额",
			"爆仓比例",
			"挂单距离限制",
			"允许锁仓",
			"EA 兼容性",
			"24/5 在线支持",
			"专属客户经理",
			"账户适用于",
			"开设账户",
		]
	},
	"screen2": {
		"title": "成为全球百万交易者之一",
		"desc": "3步轻松开户",
		"list": [{
			"title": "注册账户",
			"desc": "提交基本信息，简单安全"
		}, {
			"title": "提交个人信息",
			"desc": "只需1分钟轻松完成开户填写"
		}, {
			"title": "入金激活",
			"desc": "最低入金200美金实时激活"
		}],
		"btn1": "开设模拟账户",
		"btn2": "开设真实账户",
		"yuyan": "zh"
	},
	"screen3": {
		"pay": "支付方式"
	},
	"screen4": {
		"enTitle": "Your Trading Solution Has Been Never <span style='color: #800000;'>Easier",
		"title": "交易管理新体验",
		"desc": "至繁归于至简，我们深信简单有序是任何金融市场长期盈利最重要的一个因素。EBC为客户提供专属CRM管理系统，让您的交易井井有条。",
		"imgUrl": "zh"
	},
	"screen5": {
		"title": "交易账户",
		"register-btn": "即刻开户",
		"text1": "选择合适您的交易账户",
		"text2": "EBC提供多种账户类型，适合不同类型交易者，确保交易体验灵活高效。",
		"text3": "STD标准账户",
		"text4": "适合全部投资者和轻资本交易者",
		"text5": "起始点差低至 <i>1.1</i>让您的交易更具成本效益",
		"text6": "享受 <i>0</i> 交易手续费优惠，成本更加透明",
		"text7": "仅需 <i>$110</i> 起始资金即可激活交易账户",
		"text8": "PRO专业账户",
		"text9": "适合专业投资者和进阶交易者",
		"text10": "提供 <i>0</i> 点差交易环境，适合寻求最小交易成本的专业交易者",
		"text11": "仅需 <i>6</i> USD/lot，提供比肩机构账户的成本及深度优势",
		"text12": "需 <i>$5000</i> 起始资金激活交易账户",
		"text13": "账户详细规则对比",
		"text14": "各账户类型的主要差异，助您选择适合您交易策略的账户。",
		"text15": "类型",
		"text16": "账户适用于",
		"text17": "全部投资者",
		"text18": "专业投资者",
		"text19": "开设STD标准账户",
		"text20": "开设PRO专业账户",
		"text21": "EBC优势",
		"text22": "无论您选择STD标准账户还是PRO专业账户，均可享受到平台核心优势。",
		"text23": "3步轻松开户",
		"text24": "3步轻松账户，客服全程支持，快速开启您的交易旅程。",
		"text25": "下载交易平台",
		"text26": "MT4是全球顶尖的衍生品交易平台，以其卓越的图表工具、丰富的技术指标和自动交易功能著称。无论您使用PC端深入分析、手机端即时交易，还是网页端实时监控，MT4都能确保您享受到流畅灵活的交易体验。",
		"text27": "MT4下载",
	}
}