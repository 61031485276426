export default {
	"security-class-name": "security-cn-box",
	"security-page1": {
		"text1": "全球頂級監管",
		"text2": "查看牌照",
	},
	"security-page2": {
		"text1": "全面保障",
		"text2": "享FSCS最高8.5萬英鎊的賠付保障",
		"text3": "EBC擁有FCA最高等級的全監管牌照，是全球極少數可以開設FCA流動性清算帳戶的券商，為每一位FCA用戶，提供最高8.5萬英鎊的賠付保障。 自成立至今， FSCS 持續提高賠償基數，預估每年賠償5-6億英鎊，已賠付55億英鎊以上。",
		"text4": "巴克萊銀行最高等級Corporate Banking Account",
		"text5": "EBC擁有巴克萊銀行最高級別企業帳戶，該帳戶要求企業營業額和存款達到650萬英鎊以上，且須要通過巴克萊一系列嚴格的財務審計和背景調查。 EBC英國嚴格執行CASS規定，透過特定信託函獨立託管投資人資金，保障資金安全與獨立。",
		"text6": "提供高達20,000歐元的額外賠償",
		"text7": "The Financial Commission作為全球首個獨立的外部爭議解決（EDR）機構，透過其嚴格、透明和公開的審理機制，保證交易爭議的快速公正解決。 迄今已累計賠付超過5,100萬美元，處理了近萬起爭端。 EBC加入其賠償基金，為每位合作夥伴提供最高20,000歐元的額外賠償保障。",
		"text8": "千萬美元專業責任保險",
		"text9": "EBC每年在英國勞合社(Lloyds of London)和美國怡安集團(AON)購買保額超過千萬美元的專業責任險。 投保方在服務期間由疏忽或失誤造成的客戶損失和索賠，保險公司將全額賠付。 同時，承保期間，所有客戶都會獲得保險專家提供的持續性、專業化方案的保障。",
		"text10": "了解更多",
	},
}