export default {
	"pages1":{
		"title":"EBC 지능형 청구 커뮤니티",
		"tips":"모든 거래원의 마음속에는 영웅의 꿈이 있다.",
		"text":"주문 커뮤니티의 이점",
		"text1":"신호원 전시",
		"text2":"거래원 보상 계획",
		"text3":"부가 가치 호례",
		"text4":"계좌 개설"
	},
	"pages2":{
		"title":"주문 커뮤니티의 이점",
		"list":[{
			"tit":"보다 유연한 주문 모드",
			"tips":"EA 플러그인을 사용하여 양방향 연결 필요 없음<br />유연한 주문 방식 조정<br />맞춤형 거래를 하다."
		},{
			"tit":"밀리초 수준 주문 속도",
			"tips":"기본 MT 통신 프로토콜 기반<br />거래 계좌와 신호자를 효과적으로 연결하다<br />즉각적인 응답"
		},{
			"tit":"더 전문적인 6차원 데이터 분석",
			"tips":"독점 6차원도 지표 도입<br />펀드 등급 평가 체계가 새로운 고도로 나아가다<br />양질의 신호가 곧 나올 것 같다."
		},{
			"tit":"더 스마트한AI 선택 도우미",
			"tips":"주문 선호도에 따라<br />원클릭으로 대상 신호 필터링<br />세계 최고 수준의 거래 전략이 주머니에 가득 차 있다"
		},{
			"tit":"더 투명한 거래 전시",
			"tips":"신호원 내역 주문 추적 가능<br />조작 세부 사항을 한눈에 볼 수 있다.<br />교역 대가의 수필을 배우다."
		}],
		"btn":"주문 커뮤니티 로그인"
	},
	"pages3":{
		"title":"신호원 전시",
		"text":"수익률",
		"text1":"최대 복귀",
		"text2":"샤프 비율",
		"text3":"거래 승률",
		"text4":"무료",
		"text5":"지금 구독",
		"btn":"추가 신호 보기"
	},
	"pages4":{
		"title":"거래원 보상 계획",
		"num":"$100/월 보상",
		"num1":"$300/월 보상",
		"num2":"$1,000/월 보상",
		"num3":"$5,000/월 보상",
		"num4":"$10,000/월 보상",
		"text1":"자금을 관리하다",
		"text2":"고객량 추종",
		"name":"신예 거래원",
		"name1":"엘리트 트레이더",
		"name2":"챔피언 트레이더",
		"name3":"스타 트레이더",
		"name4":"전설적인 거래원",
		"tips":"+1% 관리 펀드/년(매월 지급)",
		"btn":"즉시 계획에 가입하다."
	},
	"pages5":{
		"title":"부가 가치 호례",
		"text":"7*24 고객 서비스",
		"text1":"EBC 마케팅 상호 작용",
		"text2":"VPS 후원",
		"text3":"거래 서적 협찬",
		"text4":"주문 흐름 사용권",
		"text5":"EBC 선물",
		"text6":"신호 고정 디스플레이",
		"text7":"해외 사무실 견학",
		"text8":"미디어 지원",
		"text9":"전략적 협력",
		"text10":"해외 펀드 상품 발행 컨설턴트 서비스",
		"btn":"주문 커뮤니티 로그인"
	}
}