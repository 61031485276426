export default {
	"pages1":{
		"title":"Cộng đồng Copy Trading thông minh EBC",
		"tips":"Trong trái tim mỗi người giao dịch đều có một giấc mơ làm anh hùng",
		"text":"Lợi thế cộng đồng Copy Trading",
		"text1":"Hiển thị nguồn tín hiệu",
		"text2":"Chương trình trả lương",
		"text3":"Phần thưởng thêm",
		"text4":"Đăng nhập cộng đồng Copy Trading"
	},
	"pages2":{
		"title":"Lợi thế cộng đồng Copy Trading",
		"list":[{
			"tit":"Chế Độ Sao Chép \"Linh Hoạt\" Hơn",
			"tips":"Không Cần Sử Dụng Plug-In EA Để Đạt Được Theo Dõi Hai Chiều, Điều Chỉnh Linh Hoạt Phương Thức Sao Chép Và Tận Hưởng Các Giao Dịch Tuỳ Chỉnh."
		},{
			"tit":"Tốc độ Copy Trading \"cấp mili giây\"",
			"tips":"Dựa Trên Giao Thức Truyền Thông Cơ Bản Của MT, Nó Kết Nối Hiệu Quả Các Tài Khoản Giao Dịch Và Các Bên Gửi Tín Hiệu, Đồng Thời Phản Hồi Ngay Lập Tức Các Lệnh Mili Giây."
		},{
			"tit":"Phân Tích Dữ Liệu Sáu Chiều \"Chuyên Nghiệp\" Hơn",
			"tips":"Giới Thiệu Các Chỉ Số Biểu Đồ Sáu Chiều Độc Quyền, Hệ Thống Xếp Hạng Quỹ Đạt Đến Một Tầm Cao Mới Và Tín Hiệu Chất Lượng Cao Có Sẵn Chỉ Bằng Một Cú Chạm."
		},{
			"tit":"Trợ Lý AI \"Thông Minh\" Hơn",
			"tips":"Lọc Tín Hiệu Mục Tiêu Chỉ Bằng Một Cú Nhấp Chuột Dựa Trên Tùy Chọn Đặt Hàng Và Thu Thập Tất Cả Các Chiến Lược Giao Dịch Hàng Đầu Thế Giới."
		},{
			"tit":"Hiển Thị Giao Dịch \"Minh Bạch\" Hơn",
			"tips":"Có Thể Truy Tìm Lịch Sử Lệnh Của Các Nguồn Tín Hiệu. Tất Cả Các Chi Tiết Giao Dịch Đều Được Trình Bày Trong Nháy Mắt Và Bạn Có Thể Đánh Giá Và Học Hỏi Từ Các Bậc Thầy Giao Dịch."
		}],
		"btn":"Đăng nhập cộng đồng Copy Trading"
	},
	"pages3":{
		"title":"Hiển thị nguồn tín hiệu",
		"text":"Tỉ lệ lợi nhuận",
		"text1":"Mức rút tối đa",
		"text2":"Hệ số Sharpe",
		"text3":"Tỉ lệ Lãi/Lỗ",
		"text4":"Miễn phí",
		"text5":"Đặt mua",
		"btn":"Xem thêm các tín hiệu"
	},
	"pages4":{
		"title":"Chương trình trả lương",
		"num":"Thưởng 100$/tháng",
		"num1":"Thưởng 300$/tháng",
		"num2":"Thưởng 1000$/tháng",
		"num3":"Thưởng 5000$/tháng",
		"num4":"Thưởng 10000$/tháng",
		"text1":"Quản lý quỹ",
		"text2":"Lượng tài khoản sao chép",
		"name":"Tân binh",
		"name1":"Ưu tú",
		"name2":"Quán quân",
		"name3":"Star",
		"name4":"Huyền thoại",
		"tips":"1% Quản lý quỹ mỗi năm (Trả tiền hàng tháng)",
		"btn":"Tham gia ngay"
	},
	"pages5":{
		"title":"Tặng thêm giải thưởng",
		"text":"Phục Vụ 24*7",
		"text1":"Tham Gia Chiến Lược Tiếp Thị EBC",
		"text2":"Tài Trợ VPS",
		"text3":"Tài Trợ Sách Giao Dịch",
		"text4":"Phần Mềm Giao Dịch Dòng Lệnh",
		"text5":"Gói Quà Tặng EBC",
		"text6":"Hiển Thị Tín Hiệu Hàng Đầu",
		"text7":"Tham Quan Văn Phòng Tại Nước Ngoài",
		"text8":"Hỗ Trợ Truyền Thông",
		"text9":"Hợp Tác Chiến Lược",
		"text10":"Dịch Vụ Tư Vấn Phát Hành Sản Phẩm Quỹ Nước Ngoài",
		"btn":"Đăng nhập cộng đồng Copy Trading"
	}
}