export default {
  lang:'cn',
  指数差价合约: "指数差价合约",
  无需持有基础资产: "无需持有基础资产，灵活交易，",
  掌控全球机遇: "掌控全球机遇",
  即刻开户: "即刻开户",
  美股指数差价合约: "美股指数差价合约",
  点差大幅度降低: "点差大幅度降低，流动性增加，享受覆盖全球市场的低成本交易",
  美国道琼斯工业平均指数: "美国道琼斯工业平均指数",
  美国标准普尔500指数: "美国标准普尔500指数",
  美国纳斯达克100指数: "美国纳斯达克100指数",
  香港恒生指数: "香港恒生指数",
  中国A50指数: "中国A50指数",
  降幅: "降幅",
  亚洲时间: "亚洲时间",
  欧洲时间: "欧洲时间",
  美国时间: "美国时间",
  品种: "品种",
  指数描述: "指数描述",
  指数点差: "指数点差",
  开设模拟账户: "开设模拟账户",
  开设真实账户: "开设真实账户",
  产品优势: "产品优势",
  无需持有基础资产2:
    "无需持有基础资产，低成本，灵活杠杆，让您在市场涨跌中抓住每个机会",
  灵活高杠杆: "灵活高杠杆",
  杠杆高达: "杠杆高达100:1",
  放大每笔交易收益: "放大每笔交易收益，灵活管理风险",
  实时弹性定价: "实时弹性定价",
  根据全球市场波动实时调整: "根据全球市场波动实时调整",
  确保您每笔交易享受最优交易条件: "确保您每笔交易享受最优交易条件",
  极致的交易速度: "极致的交易速度",
  平均执行速度20毫秒: "平均执行速度20毫秒",
  助您抢占市场先机: "助您抢占市场先机",
  完全透明的交易: "完全透明的交易",
  无隐藏费用或额外成本: "无隐藏费用或额外成本",
  确保您每笔交易的透明和公平: "确保您每笔交易的透明和公平",
  全球指数交易深度: "全球指数交易深度",
  查看所有产品: "查看所有产品",
  指数差价合约相关问题: "指数差价合约相关问题",
  list: [
    {
      title: "1、什么是指数CFD？",
      content: `指数CFD，一般指的是股票指数CFD，可以反映股票指数的价格变化并提供价格变动带来的盈利或亏损。
                                            <br /><br />

                                            相比于单一的股票投资，股票指数反映了一组具有代表性股票的走势，是一国某个行业甚至是整体经济的晴雨表。比如德国DAX
                                            30指数，是由该国30支最具代表性的蓝筹股组成，反映了德国股市的整体状况。
                                            <br /><br />

                                            通过指数CFD，可以将对单只股票复杂的判断简化为分析整体市场情绪，从而分散风险，并规避个股极端的价格波动，整体的安全性更高。`,
    },
    {
      title: "2、指数CFD可以交易什么？",
      content: ` 指数CFD可以交易全球股票指数。 <br /><br />

                                            EBC甄选全球主流指数，包括道琼斯指数、DAX 30指数、STOXX
                                            50指数、标普500指数和日经225指数等。只需要一个EBC账户，就可以在全球众多市场中进行选择，并通过EBC极具竞争力的点差和灵活的杠杆比率，在全球众多极其活跃的市场中取得成功。`,
    },
    {
      title: " 3、指数CFD交易杠杆？",
      content: `
      EBC可以为指数
                                            CFD交易提供最高100倍的杠杆，这是在合规和监管范围内所允许的最大杠杆比例，充分考虑了安全性和资金利用率的平衡。`,
    },
    {
      title: " 4、指数CFD合约量如何规定？",
      content: `
      不同指数对应的合约量也是不同的。 <br /><br />

                                            EBC提供全球最主流的股指CFD，其中100GBP（英国富时100）、200AUD（澳洲标普200）、CNIUSD（中国A50指数）、D30EUR（德国DAX30）、E50EUR（欧洲STOXX50）、F40EUR（法国CAC40）、HSIUSD（香港50指数）、NASUSD（美国纳斯达克100指数）、U30USD（美国股票30指数）一手合约量是10。
                                            <br /><br />

                                            225JPY（日经225指数）、SPXUSD（标普500指数）一手合约量是100。
                                            <br /><br />

                                            指数CFD的最小手数均为0.1手。`,
    },
    {
      title: "5、指数CFD交易有哪些优势？",
      content: `
      提高资金利用率<br />

                                            指数交易的杠杆通常比较低，但是通过指数CFD，可以占用更少的资金参与交易。但记住，杠杆和风险是相伴而行的，在进行杠杆交易时一定要保持仓位控制。
                                            <br /><br />

                                            双向交易 <br />

                                            股票指数反映了股市的整体走向。通过指数CFD，可以多空双向交易，获取更大盈利空间。<br /><br />

                                            分散风险 <br />

                                            股票指数往往是选出最具代表性的一揽子股票编制的，是某一个市场中最绩优股票的集合。单一股票的走势往往无法对指数产生直接的影响，有效分散了市场风险。`,
    },
    {
      title: "6、如何学习参与指数CFD交易？",
      content: `由于指数CFD反映的是一揽子股票的走势，所以我们需要关注的是市场整体，而非局部。
                                            <br /><br />

                                            一般来说，股票指数和央行的货币政策、一国的经济数据直接相关。比如，如果一国央行实施的是紧缩性货币政策，市场会担心市场上缺乏资金，进而打压股市；同样的，如果一国经济数据不佳，会压低市场的投资预期，进而打压股市。`,
    },
  ],
  每个认真交易的人: "每个认真交易的人，都值得被认真对待",
};
