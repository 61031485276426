export default {
	"leftNav": [{ "id": "#Trading", "name": "거래" },
		{ "id": "#Regulation", "name": "이중 감독" },
		{ "id": "#Security", "name": "보안" },
		{ "id": "#Fintech", "name": "금융 기술" },
		{ "id": "#Black-Box", "name": "블랙 박스" },
		{ "id": "#Private-Room", "name": "거래 공간" },
		{ "id": "#Liquidity", "name": "유동성" },
		{ "id": "#Honor", "name": "명예" },
		//{ "id": "#News", "name": "소식" }
	],
	"pages1": {
		"h1":"Trust & Respect",
		"title": "거래를 열심히 하는 사람은 모두 열심을 다한 대우를 받을 가치가 있다",
		"btn": " 동영상 보기 "
	},
	"pages2": {
		"title": "고객 맞춤형 글로벌 인기 거래 상품",
		"title1": "기회와 경쟁우위의 세계를 탐색하며 가장 진실되고 안정적인 시가를 파악해 보세요",
		"btn": " 더 알아보기 ",
		"text": "외환 거래",
		"text1": " 원자재 상품 ",
		"text2": " 지수 CFDs ",
		"text3": " 셰어 CFDs "
	},
	"pages3": {
		"title": "전세계 최고 수준의 감독 기관",
		"title1": "EBC 금융 그룹의 자회사는 해당 지역 관할 구역에서 규제 및 라이센스를 받습니다.",
		"btn": "더 알아보기",
		"text": "영국 금융감독청 FCA",
		"text1": "EBC Financial Group (UK) Ltd 는 영국FCA 금융감독청의 승인 및 규제를 받고 있습니다. 레퍼런스 번호: 927552",
		"text2": "호주 증권 투자 위원회 ASIC",
		"text3": "EBC Financial Group (Australia) Pty Ltd는 호주 증권 투자 위원회의 승인 및 규제를 받고 있습니다. 레퍼런스 번호: 500991",
		"text4": "CIMA (CAYMAN)",
		"text5": "EBC Financial Group (Cayman) Ltd 는 케이맨 제도 통화당국(CIMA)의 승인 및 규제를 받고 있습니다. 레퍼런스 번호: 2038223"
	},
	"pages4": {
		"title": "Driving You To Succeed",
		"title1": "런던 LD5, 뉴욕 NY4, 싱가포르 SG1, 도쿄 TY3, 홍콩 HK2의 초고속 전용회선이 연결된 독립 서버에 배치되어 있으며, 스마트 오더 라우팅 및 시세 최적화 엔진을 제공하여 글로벌 초고속 금융 생태계를 구축합니다.",
		"btn": " 더 알아보기",
		"text": " 초 당 최고 주문 체결량 ",
		"text1": " 초고속 추문 체결 ",
		"text2": " 업타임 ",
		"text3": "인터뱅크 레벨의 RAW ECN 스프레드",
	},
	"pages5": {
		"title": "85% 이상의 주문을 보다 우수한 단가로 체결",
		"title1": "EBC트레이딩 블랙박스，더 정확한 최적의 매도 주문 알고리즘",
		"btn": " 더 알아보기"
	},
	"pages6": {
		"title": "EBC 거래공간",
		"title1": "EBC의 Private Room은 유동적인 매칭을 기반으로 귀하의 독점적인 거래 채널을 구축하고, 심지어 귀하의 거래 정보의 일부를 숨김으로써 '히든카드'를 보호할 수 있습니다. 이는 귀하의 주문이 더 나은 시세를 취합할 수 있을 뿐만 아니라 거래 과정에서 사이버 공격으로부터 피할 수 있음을 의미합니다.",
		"btn": " 더 알아보기"
	},
	"pages7": {
		"title": "유동성",
		"title1": "EBC는 다수의 국제 최고 은행과 장기적이고 안정적인 제휴관계를 구축하고, 여러 고품질 유동성 시세를 통합하며 매우 경쟁력 있는 시장 스프레드를 제공하여 초저 거래 비용을 쉽게 누릴 수 있도록 합니다.",
		"btn": "더 알아보기"
	},
	"pages8": {
		"btn": "더 알아보기"
	},
	"pages9": {
		"title": "안심하고 이용할 수 있는 전면적 보장 체계",
		"title1": "EBC는 자금의 안전을 최우선으로 생각합니다. 고객 자금은 Barclays Bank의 분리된 관리 계좌에 보관됩니다. EBC는 FCA, Financial Commission, Lloyds of London 및 AON과 협력하여 포괄적이고 다층적인 보안 메커니즘을 구축했습니다.",
		"btn": "보안"
	},
	"lang": "en"
}

