export default {
	"malaria-class-name": "en-ubm-box",
	"malaria-page1": {
		"text1": "EBC Financial Group hỗ trợ chiến dịch của Quỹ Liên Hợp Quốc",
		"text2": "Trở thành thế hệ đánh bại bệnh sốt rét và tạo ra kỷ nguyên bình đẳng mới",
	},
	"malaria-page2": {
		"text1": "Sốt rét: Một trong những mối đe dọa lớn nhất đối với sức khỏe của trẻ em",
		"text2": "Năm 2022, ước tính có 249 triệu ca lây nhiễm và 608.000 ca tử vong do sốt rét ở tại 85 quốc gia trên thế giới, hơn 76% trong số đó là trẻ em dưới 5 tuổi.Khi bạn đọc điều này, cứ mỗi phút lại có một đứa trẻ chết vì bệnh sốt rét.",
		"text3": "2.49<i>triệu</i>",
		"text4": "ca lây nhiễm",
		"text5": "608,000",
		"text6": "ca tử vong",
		"text7": "76%<i>+</i>",
		"text8": "trẻ em",
		"text9": "*Nguồn dữ liệu: \"Báo cáo Sốt rét Thế giới 2023\" từ Tổ chức Y tế Thế giới",
	},
	"malaria-page3": {
		"text1": "Đoàn kết đánh bại bệnh sốt rét",
		"text2": "Đoàn kết đánh bại bệnh sốt rét là một chiến dịch toàn cầu do Quỹ Liên Hợp Quốc phát động nhằm tập hợp những người ủng hộ trên khắp thế giới để chống lại bệnh sốt rét.EBC tích cực thực hiện trách nhiệm doanh nghiệp và chung tay với Quỹ Liên Hợp Quốc để hỗ trợ đầy đủ cho chiến dịch đánh bại bệnh sốt rét.",
		"text3": "60",
		"text4": "quốc gia chịu ảnh hưởng",
		"text5": "40<i>triệu</i>",
		"text6": "người được bảo hộ",
		"text7": "183,450",
		"text8": "lều chống muỗi",
	},
	"malaria-page4": {
		"text1": "Tham gia giải chạy gây quỹ từ thiện “Move Against Malaria 5K”",
		"text2": "Tham gia cùng những người ủng hộ từ khắp nơi trên thế giới trong giải chạy gây quỹ từ thiện Move Against Malaria 5K để nâng cao nhận thức, tài trợ cho các dự án điều trị bệnh sốt rét và các công cụ cứu sống từ ngày 25 tháng 4 đến ngày 5 tháng 5 năm 2024. EBC Financial Group chung tay với Quỹ Liên Hợp Quốc để mời những người có cùng chí hướng tham gia cùng chúng tôi xây dựng một thế giới không có bệnh sốt rét.",
		"text3": "Tham gia ngay",
	},
	"malaria-page5": {
		"text1": "Chúng tôi đang thực hiện",
	},
}