<template>
	<div class="c-footer">
		<div class="fp-overflow" tabindex="-1">
			<div class="c-footer-hd d-none d-md-block">
				<div class="container-xxl">
					<div style="height: 1px;
						width: 100%;
						background-color: #e9e9e9;
						margin-bottom: 40px;">
					</div>
					<div class="row">
						<div class="col-md-4">
							<img width="360" src="@/assets/image/nav/ebc_logo.svg" data-v-73eb7d57="" alt="EBC"
								title="EBC" />
							<!-- <img width="380"
								:src="require(`../../assets/image/index/footer-logo-${$t('CFooters.bannerLang')}.png`)"
								alt=""> -->
						</div>
					</div>
					<div class="row" style="margin-top: 50px;">
						<div class="col-md-2" :key="colIndex" v-for="(col, colIndex) in $t('CFooters.linkList')">
							<p class="list-title">{{ col.title }}</p>
							<ul class="menu-list">
								<li :key="linkIndex" v-for="(link, linkIndex) in col.list">
									<router-link
										v-if="link.link && link.link != 'PointsMall' && !link.link.includes('support.ebchelp.com')"
										:to="!$store.state.suffix ? link.link : ('/' + $store.state.suffix + link.link)">{{
											link.label }}</router-link>
									<a v-else-if="link.link.includes('support.ebchelp.com')" :href="link.link"
										target="_blank">{{ link.label }}</a>
									<a v-else-if="link.link == 'PointsMall'"
										href="https://ebcpoints.com/html/ebc/index.html" target="_blank">{{ link.label
										}}</a>
									<template v-else>{{ link.label }}</template>

								</li>
							</ul>
						</div>
					</div>
					<div style="
						height: 1px;
						width: 100%;
						background-color: #e9e9e9;
						margin-top: 80px;">
					</div>
				</div>

			</div>

			<div class="c-footer-ft">
				<div class="container-xxl">
					<div class="bottom-text d-none d-md-block" v-html="$t('CFooters.text')"></div>
					<div class="bottom-text d-md-none d-block" v-html="$t('CFooters.waptext')"></div>
				</div>
			</div>
		</div>
		<img src="https://d21y75miwcfqoq.cloudfront.net/fc97176a" alt="" style="display: none;">
	</div>
</template>

<script>
export default {
	data() {
		return {
			languageList: [{
				text: '简体中文',
				lang: 'zh_CN',
				id: 'cn'
			},
			{
				text: 'English',
				lang: 'en_US',
				id: 'en'
			},
			{
				text: '繁體中文',
				lang: 'zh_TW',
				id: 'ct'
			},
			{
				text: '한 국 어',
				lang: 'ko_KR',
				id: 'ko'
			},
			{
				text: '日本語',
				lang: 'ja_JP',
				id: 'jp'
			},
			{
				text: 'ภาษาไทย',
				lang: 'th_TH',
				id: 'th'
			},
			{
				text: 'ئۇيغۇر تىلى',
				lang: 'weiwuer',
				id: 'vi'
			}
			],
		}
	},
	methods: {
		handleChangeLamguage(language) {
			this.$i18n.locale = language.id
			localStorage.setItem('language', language.id)
		}
	},
	mounted() {
		window.dataLayer = window.dataLayer || [];

		// function gtag() {
		// 	dataLayer.push(arguments);
		// }
		// gtag('js', new Date());
		// gtag('config', 'G-TJ431PDHZX');
		window.__lc = window.__lc || {};
		window.__lc.license = 12340599;
		(function (n, t, c) {
			function i(n) {
				return e._h ? e._h.apply(null, n) : e._q.push(n)
			}
			var e = {
				_q: [],
				_h: null,
				_v: "2.0",
				on: function () {
					i(["on", c.call(arguments)])
				},
				once: function () {
					i(["once", c.call(arguments)])
				},
				off: function () {
					i(["off", c.call(arguments)])
				},
				get: function () {
					if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
					return i(["get", c.call(arguments)])
				},
				call: function () {
					i(["call", c.call(arguments)])
				},
				init: function () {
					var n = t.createElement("script");
					n.async = !0, n.type = "text/javascript", n.src =
						"https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
				}
			};
			!n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
		}(window, document, [].slice))
	}
}
</script>

<style lang="scss" scoped>
.c-footer {
	background-color: #260505;
	height: 100%;

	.fp-overflow {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
	}
}

.index-footer {
	.c-footer {
		height: auto !important;
		background-color: #000000;

		.fp-overflow {
			height: auto !important;
			max-height: initial !important;
		}

		.c-footer-ft {
			padding-bottom: 105px;
		}
	}
}


.c-footer .c-footer-ft {
	background-color: #fff;
	padding-top: 44px;
	padding-bottom: 50px;
	width: 100%;
	height: 100%;
}

.c-footer .c-footer-ft .bottom-text {
	font-size: 12px;
	line-height: 1.67;
	color: #999;
	/* font-family: auto; */
	// opacity: 0.5;
}

@media(max-width:767px) {
	.c-footer .c-footer-ft {
		padding: 20px 0
	}

	.index-footer {
		.c-footer {
			.c-footer-ft {
				padding: 20px 0
			}
		}
	}
}

.c-footer .c-footer-bq {
	background-color: #080E1C;
	text-align: center;
	font-size: 12px;
	padding: 20px;
	color: #f7f7f7;
	font-family: auto;
}

.c-footer .c-footer-bd {
	padding: 20px 0
}

.c-footer .c-footer-bd .ad-text {
	font-size: 12px;
	line-height: 1.67;
	color: #333;
	font-weight: 700
}

.c-footer .c-footer-bd .ad-text .more {
	color: #008aed;
	margin-left: 50px
}

.c-footer .c-footer-bd .ad-text .btn {
	height: 44px;
	line-height: 42px;
	padding: 0 43px;
	width: 100%;
	border-radius: 4px;
	border: 1px solid #d1d1d1;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	color: #646464
}

.c-footer .c-footer-hd {
	background-color: #fff;
	padding-top: 126px;
	padding-bottom: 60px;
	width: 100%;
}

.c-footer .c-footer-hd .other-text {
	margin-top: 40px;
	font-size: 12px;
	line-height: 2;
	color: #f7f7f7;
	width: 235px
}

.c-footer .c-footer-hd .language-box {
	margin-top: 34px
}

.c-footer .c-footer-hd .language-box__text {
	font-size: 12px;
	line-height: 1.67;
	color: #f7f7f7;
	font-family: auto;
}

.c-footer .c-footer-hd .language-box .language-list {
	margin-top: 12px
}

.c-footer .c-footer-hd .language-box .language-list li {
	display: inline-block;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.67;
	color: #f7f7f7;
	margin-right: 12px;
	cursor: pointer
}

.c-footer .c-footer-hd .language-box .language-list li span {
	text-decoration: underline;
	font-family: auto;
}

.c-footer .c-footer-hd .language-box .language-list li img {
	width: 16px;
	height: 11px;
	vertical-align: middle;
	margin-right: 5px
}

.c-footer .c-footer-hd .customer-login {
	margin-top: 20px;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.75;
	color: #f7f7f7;
	text-decoration: underline;
	font-family: auto;
}

.c-footer .c-footer-hd .search-box {
	margin-top: 38px;
	position: relative
}

.c-footer .c-footer-hd .search-box img {
	width: 22px;
	height: 22px;
	transform: translateY(-50%)
}

.c-footer .c-footer-hd .search-box .form-control {
	background-color: transparent;
	width: 235px;
	height: 32px;
	line-height: 30px;
	margin: 0 0 19px;
	padding: 5px 10px 5px 42px;
	border-radius: 2px;
	border: 1px solid #979797;
	font-size: 12px
}

.c-footer .c-footer-hd .search-box .form-control:-moz-placeholder {
	color: #f7f7f7
}

.c-footer .c-footer-hd .search-box .form-control:focus {
	box-shadow: none
}

.c-footer .c-footer-hd .list-title {
	font-size: 16px;
	line-height: 1.75;
	color: #333;
}

.c-footer .c-footer-hd .menu-list {
	font-size: 14px;
	line-height: 2.5;
	color: #f7f7f7;
	margin-top: 20px;
}

.c-footer .c-footer-hd .menu-list a {
	color: #666;
	/* font-family: auto; */
	// opacity: 0.5;
}

@media(min-width:2000px) {
	.c-footer {
		background-color: #260505;
	}

	.c-footer .c-footer-ft .bottom-text {
		font-size: 14px;
		line-height: 2.2;
	}

	.c-footer .c-footer-hd .list-title {
		font-size: 18px;
	}

	.c-footer .c-footer-hd .menu-list a {
		font-size: 16px;
	}

	.c-footer .c-footer-hd {
		padding-bottom: 100px;
	}
}
</style>
