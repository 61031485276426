export default {
	"fcb-class-name": "en-fcb-box",
	"video-btn": "مۇكەممەل ۋىدىئو كۆرۈش",
	"fcb-page1": {
		"text1": "OFFICIAL PARTNER OF FC BARCELONA",
		"text2": "EBC بارسېلونا پۇتبول كۇلۇبىنىڭ پۇل مۇئامىلە ساھەسىدىكى ئوڭچە ھەمكارلىق ھەمراھىغا ئايلاندى، ھەمكارلىق دائىرىسى تاشقى پېرېۋوت، سودا، ۋاسىتىچىلىك ( باھا پەرقى كېلىشىمىنى ئۆز ئىچىگە ئالىدۇ ) ۋە مەسلىھەتچىلىك مۇلازىمىتىدىن ئىبارەت.		",
		"text3": "",
	},
	"fcb-page2": {
		"text1": "قىزغىن سۆيگەنلىك ئۈچۈن پۈتۈن كۈچ بىلەن ئاتلىنىش ",
		"text2": "قىزغىنلىققا مۇناسىۋەتلىك بارلىق كۈچ سىنىشىش ھەرگىزمۇ ئاسان ئىش ئەمەس <br>	ساندىن ھالقىش، يېڭىش-يېڭىلىشتىن ھالقىش<br>	ئۆزگىرىشنى ئىلگىرى سۈرۈش يولىدا، بىز ھەرگىزمۇ يالغۇز جەڭ قىلمايمىز<br>	كەسىپكە ئېنىقلىما بېرىش ئۆلچىمى بويىچە، گۈللەپ ياشناش<br>	بىز بىلىم، تېخنىكا ئارقىلىق <br>	نىشان باشلانغان جايدا ئەسلىي مەقسەتنى ئۇنتۇماي، پۈتۈن كۈچىمىز بىلەن ئاتلىنىمىز",
	},
	"fcb-page3": {
		"text1": "ئۇ پەقەت بىر كۇلۇبلا ئەمەس",
		"text2": "مەيلى سىز توپ مەستانىسى بولۇڭ ياكى بولماڭ، ئۇنىڭ بىر رىۋايەت ئىكەنلىكىنى بىلىسىز. ",
		"text3": "بارسېلونا پۇتبول كۇلۇبى 120 يىلدىن ئارتۇق تارىخقا ئىگە بولۇپ، دۇنيادىكى ئەڭ ئالقىشقا ئېرىشكەن پۇتبول كۇلۇبلىرىنىڭ بىرى، ئۇ مارادونا، مىسسى، روندىنىئو، گۇئاردىئولا قاتارلىق دۇنيا دەرىجىلىك كۆپلىگەن ئۇلۇغ توپ چولپانلىرى ھەمدە تىرېنېرلارنىڭ قالتىس توپ ئۇسلۇبى بىلەن يالغۇز قەدەملىك پۇتبول مۇنبىرىگە گۇۋاھ بولۇپپەقەت بىر كۇلۇبلا ئەمەس»كە ئايلاندى. ",
		"text4": "EBC بولسا سودا بىلەنلا چەكلىنىپ قالماي، يەر شارىۋى پۇل مۇئامىلە ئېكولوگىيە ئۇنىۋېرسال گەۋدىسى بەرپا قىلىشقا كۈچ چىقىرىپ، يەر شارىۋى ئىقتىسادنىڭ تەرەققىياتىغا تۈرتكە بولىدۇ. ",
	},
	"fcb-page4": {
		"text1": "ئېنىقلىما خادىسى ",
		"text2": " چاقناپ تۇرغان كوماندىلار، قىزغىنلىق ۋە ئارزۇنى قەدىرلەيدىغان مەشىق سىستېمىسى، كىشىنى پەخىرلەندۈرىدىغان مۇۋەپپەقىيەتلەرگە گۇۋاھ بولىدىغان مۇكاپات لوڭقىسى ۋە جەمئىيەتكە قوشقان پائال تۆھپىسى بارسېلونا كوماندىسىنى  كەسپتىكى خادا ئورۇنغا ئىگە قىلدى. ",
		"text3": "EBC بىلەن بىرازىلىيەنىڭ ھەمكارلىقى ئىككى تەرەپنىڭ بىر-بىرىنىڭ ئەمەلىي كۈچىنى ئېتىراپ قىلىشىغا گۇۋاھ بولدى، EBC ھەر بىر سودا ھەۋەسكارىغا ئەستايىدىل مۇئامىلە قىلىشتىن ئىبارەت ئەسلىي مەقسىتىدە چىڭ تۇرۇپ، يەر شارى بويىچە يۇقىرى دەرىجىلىك نازارەت قىلىپ باشقۇرۇش، ھەر تەرەپلىمە كاپالەت سىستېمىسى ۋە كەڭ تەرىپلەشكە ئېرىشكەن خەلقئارا تەسىر كۈچى ئارقىلىق كەسىپ ئۈلگىسى بولۇش.  ",
	},
	"fcb-page5": {
		"text1": "تېخنىكا ئارقىلىق «ئالەم كوماندىسى»نى بارلىققا كەلتۈرۈش ",
		"text2": "بارسېلونا ئۆزىگە خاس بولغان پاس بېرىش، كونترول قىلىش ئۇسۇلى بىلەن پۈتۈن دۇنياغا داڭلىق، توپنى كونترول قىلىش، قىسقا پاس بېرىش ۋە كوللېكتىپ ھەمكارلىشىش تاكتىكىسى ئارقىلىق پۇتبولنىڭ جىسمانىي قارشىلىشىشقىلا تايىنىپ ئەمەس، بەلكى يۇقىرى تېخنىكا ۋە تاكتىكا، ئەقىل-پاراسەت بىلەن غەلىبە قىلالايدىغانلىقىنى ئىسپاتلاپ، ھازىرقى زامان پۇتبول تاكتىكىسىنىڭ ئۆزگىرىشىگە تەسىر كۆرسەتتى. ",
		"text3": "EBC نىڭ تېخنىكىدا بۆسۈش ھاسىل قىلىش جەھەتتە ئۆزىنىڭ قەتئىيلىكى بار، باشتىن-ئاخىر خەلقئارادا ئالدىنقى قاتاردا مېڭىپ، مەبلەغ سالغۇچىلار ئۈچۈن بىخەتەر، كەسپىي، يۇقىرى ئۈنۈملۈك، مۇقىم بولغان يەر شارىۋى سودا ئېكولوگىيەسى بەرپا قىلىپ بەردى. "
	},
	"fcb-page6": {
		"text1": "ئەڭ ياخشىغا  ئايلىنىپ، دۇنيانى تېخىمۇ گۈزەل قىلىش ",
		"text2": "ھۆرمەت قىلىش، تىرىشىش، يۈكسەك ئىرادە، كوللېكتىپ ھەمكارلىشىش ۋە كەمتەرلىك بارسېلونا بەش چوڭ قىممەت قارىشى، ئۇ باشتىن-ئاخىر تىرىشىش ۋە يۈكسەك ئىرادە قىممەت قارىشى ھەم توپچىلارنى ئەڭ ياخشى روھقا ئايلاندۇرۇشقا تۈرتكە بولۇشنى تەشەببۇس قىلىپ كەلدى، بۇ EBC نىڭ قىممەت قارىشى بىلەن تەبىئىيلا بىر يەردىن چىقتى. ",
		"text3": " EBC باشتىن-ئاخىر سەمىمىيەت ۋە ھۆرمەت پىرىنسىپىغا ئەمەل قىلىپ كەلدى 	ھەر بىر سودا ھەۋەسكارىنىڭ قىزغىنلىقى ۋە ئۈمىدىنى قەدىرلەپ، پۈتۈن كۈچى بىلەن كەسىپ ئۇچۇرىنى ئاشكارىلاپ، كەسىپ تەرەققىياتىغا تۈرتكە بولۇپ، باراۋەر جەمئىيەتنى ئورتاق قۇرۇپ چىقىش كېرەك.",
		"text4": "Together, Let's Build The Future.",
	},
	"fcb-page7": {
		"text1": "ئالاقىدار مەزمۇنلار ",
	}
}