export default {
    "box-class-name": "cn-no1-box",
    "lang": "cn",
    "挑战最强交易环境": "挑战最强交易环境",
    "利润差额奖金": "利润差额奖金",
    "EBC的最强交易环境等您挑战": "EBC的最强交易环境等您挑战！挑战成功即可获得150%利润差额奖金，上不封顶",
    "距挑战结束剩余": "距挑战结束剩余",
    "天": "天",
    "时": "时",
    "分": "分",
    "秒": "秒",
    "本次活动时间为2024年1月1日": "本次活动时间为: 1/1/2024 00:00:00 - 30/9/2024 23:59:59（GMT+2）",
    "发起挑战": "发起挑战",
    "挑战规则": "挑战规则",
    "EBC致力于为投资者提供极低交易成本": "EBC致力于为投资者提供极低交易成本、优质市场深度和高效订单执行。为检验和完善我们的交易环境，特推出挑战最强交易环境活动。",
    "开设账户": "开设账户",
    "在EBC平台开设一个交易账户": "在EBC平台开设一个交易账户",
    "选择平台": "选择平台",
    "选择一家具备FCA或ASIC监管资质的交易商平台": "选择一家具备FCA或ASIC监管资质的交易商平台",
    "相同策略交易": "相同策略交易",
    "使用相同的交易策略在EBC和所选平台进行交易": "使用相同的交易策略在EBC和所选平台进行交易",
    "收益比较": "收益比较",
    "在EBC的盈利低于或亏损高于所选择交易商": "在EBC的盈利低于或亏损高于所选择交易商，即为挑战成功",
    "卓越的交易环境": "卓越的交易环境",
    "EBC以一流的交易执行速度和先进的技术支持": "EBC以一流的交易执行速度和先进的技术支持，打造卓越的交易体验，满足投资者的高标准需求。",
    "科技赋能交易": "科技赋能交易",
    "部署于伦敦LD4": "部署于伦敦LD4、纽约NY4、新加坡SG1、东京TY3、香港HK2的超高速专线连接的独立服务器，提供超低延迟聚合、智能订单路由和报价优化引擎，构建全球超高速金融生态体。",
    "让85%以上的订单成交于更优的价格": "让85%以上的订单成交于更优的价格",
    "EBC交易黑盒": "EBC交易黑盒，精准优化抛单算法，在价格优化率、订单成交率以及算法稳定性方面， EBC交易黑盒远远优于传统交易系统。",
    "EBC交易空间": "EBC交易空间",
    "无论您是高频交易者": "无论您是高频交易者，还是大单交易家，EBC 的 Private Room 都将在流动性匹配基础上，搭建您的专属的交易通道，甚至隐藏您的部分交易信息，保护您的“底牌”，这意味着您的订单不仅能获得更好的聚合报价，也可以在成交过程中免于狙击。",
    "国际顶级流动性": "国际顶级流动性",
    "EBC与多家国际顶级银行建立了长期稳定的合作关系": "EBC与多家国际顶级银行建立了长期稳定的合作关系，整合多家优质流动性报价，提供极具竞争力的市场点差。",
    "活动须知": "活动须知",
    "参与账户": "参与账户",
    "新用户": "新用户：在官网新开账户后，联系在线客服开通VIP账户参与挑战。",
    "现有用户": "现有用户：联系在线客服，开通VIP账户后参加挑战。",
    "对比交易商平台要求": "对比交易商平台要求",
    "选择持有英国FCA或澳洲ASIC监管的交易商平台": "选择持有英国FCA或澳洲ASIC监管的交易商平台。不包括AR、EEA注册或Trading Name账户。",
    "交易策略": "交易策略",
    "仅限以下主流货币对": "仅限以下主流货币对：AUD/USD、USD/CAD、EUR/USD、USD/CHF、GBP/USD、USD/JPY、NZD/USD。",
    "在EBC和所选交易商使用相同的交易资金和交易策略": "在EBC和所选交易商使用相同的交易资金和交易策略，所有策略需为正常交易范畴。",
    "奖金结算": "奖金结算",
    "挑战者在其他平台的交易返佣可计入盈利": "挑战者在其他平台的交易返佣可计入盈利，但需提供相应证明信息。",
    "挑战者可在任意挑战时间内选择结算": "挑战者可在任意挑战时间内选择结算，每个身份证仅可参与一次。",
    "挑战结束后": "挑战结束后，提交平仓的交割单。奖励将在审核后的两个工作日内发放至交易账户，可用于交易或提现。",
    "重要声明": "重要声明",
    "参赛者应知晓交易风险": "参赛者应知晓交易风险、规则、权益及平台交易产品的交易规则，因不可抗力造成的交易问题及账户亏损，EBC不予处理。",
    "任何不遵守比赛规则或篡改数据的行为": "任何不遵守比赛规则或篡改数据的行为，EBC有权取消参赛资格，并保留追究法律责任的权利。",
    "EBC有权要求参赛者提供有争议的订单或账户的补充材料": "EBC有权要求参赛者提供有争议的订单或账户的补充材料。",
    "本次挑战赛旨在提供公平": "本次挑战赛旨在提供公平、公正的环境，EBC保留修订、豁免或解释比赛规则的权利。",
    "EBC有权根据法律法规": "EBC有权根据法律法规、司法或行政命令取消或终止活动。",
    "本活动最终解释权归EBC所有":"本活动最终解释权归EBC所有。"

}