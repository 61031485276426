export default {
  "box-class-name": "oxford-box-cn",
  "button-text": "查看更多",
  "oxford-page1": {
    text1: "EBC<br> 联合牛津大学<br>研讨会",
    text2: "“经济学家都做了什么”",
    text3: "Sarah Clifford & David Barrett",
    text4: "即刻预约",
    text5: "2024年3月6日12:00 pm GMT（北京时间：20:00）",
  },
  "oxford-page2": {
    text1: "以面向未来的教育, 推动全球经济发展",
    text2:
      "EBC金融集团联合牛津大学经济学系举办“经济学家都做了什么”研讨会，积极践行ESG理念，推动以知识为驱的社会进步发展",
    text3:
      "EBC与牛津大学达成合作，于2024年3月6日联合举办主题为“经济学家都做了什么” 的研讨会。牛津大学以权威学术研究、对全球政策的杰出贡献等享誉世界。其经济学系致力于通过创新的经济研究，推动公共政策的改进及全球经济发展。与牛津大学携手，是EBC践行ESG理念的见证。EBC积极承担企业责任，长期致力于教育与人才培养，推动人类社会进步发展。我们相信：通过发挥EBC集团和牛津大学在跨学科领域的双向专业优势，不仅能推动构建更专业安全、透明高效的投资环境，更有助于在全球范围内建立开放包容的公共交流空间，产生变革性及创新的经济研究，以更好地促进公共政策的改进，全球经济发展。加入我们，共同创造更加美好、平等的未来。",
    text4: "",
    text5: "研讨会介绍",
    text6:
      " “经济学家究竟在做什么”结合时下热议话题，探讨如何利用经济学推动政策的改进，促进经济繁荣。届时将深入探讨“避税经济学”，全面解读不同税收环境引起的经济趋势及经济学家在全球经济安全中所起的作用等。",
  },
  "oxford-page3": {
    text1: "与会嘉宾",
    text2: "牛津大学经济学副教授",
    text3:
      "Sarah Clifford博士作为牛津大学“避税经济学”领域的学术巨擘，致力于探索避税经济学背后的经济学原理，以及税收相关的经济行为模型。Sarah Clifford博士是最早利用跨国面板数据研究MNE ETR的学者之一，主导并提出了经合组织OECD有关税基侵蚀的“GloBE”法案，极大推动了BEPS包容性框架的构建。在其最新论文《避税的全球影响及其对政策的启示》中，她深入分析了避税经济学如何在全球范围内对经济产生影响，以及政府如何通过有效的政策干预来减少这一现象。",
    text4: "牛津大学经济学教授",
    text5:
      "Abi Adams-Prassl教授主攻应用微观经济学，擅长通过实证方法将数据引入到决策模型中，辅助微观个体和家庭做出决策。作为英国政府科学办公室的专家组成员，Abi Adams-Prassl教授主导了英国政府的风险管理和弹性规划，并将其从短期扩展为长期目标；同时，作为跨学术和政策研究领域的权威，Abi Adams-Prassl教授还制定了英国法律体系中有关司法的量化框架，确定了大数据环境中平衡诉诸司法和开放司法原则。",
    text6: "EBC金融集团（英国）首席执行官",
    text7:
      "David Barrett在国际资产多元化配置、结构性风险防范领域有超过35年经验，亲历多个市场周期，具有丰富的金融监管运营经验。擅长国际金融服务、商业战略咨询、金融产品架构等，洞悉行业趋势及FCA监管要求的移迁。David曾主导全球最大投资集团美国AIG的执行和管理工作，推动了华尔街产品架构和合规风控等领域的一系列变革，为后金融危机时代的风险规避和流动性管理搭建了可行性框架。",
  },
  "oxford-page4": {
    text1: "关于EBC金融集团",
    text2:
      "EBC Financial Group成立于英国伦敦，是一家集金融券商、资产管理、移民投资等服务为一体的综合性金融集团。EBC业务遍布全球，以全球顶级监管、毫秒级的订单执行和机构级的清算池、全方位的保障体系，致力于为每位客户提供“安全、专业、高效稳定”的交易环境。EBC集团核心人员拥有逾30年的大型金融机构的工作经验，亲历多个经济周期，时间跨越85年《广场协议》、97年亚洲金融危机、08年全球金融危机和15年瑞郎黑天鹅事件等。他们对突发事件及金融危机有出色的应对经验。基于实战锻炼出的专业素养，促使EBC对自我要求更为严格。EBC相信，每个认真交易的人，都值得被认真对待。",
    text3: "关于牛津大学经济学系",
    text4:
      "牛津大学是享誉全球的顶尖高等学府，同时也是英国最具规模的研究机构。在其近千年的建校史上，牛津致力于创建开放包容的学术环境，产生创新的经济研究，构建跨领域的国际合作与交流，推动人类社会发展与进步。<br>牛津大学经济学系以其专业权威的学术研究、为全球的经济和公共政策做出的杰出贡献而闻名于世，是世界上最多元化的学术经济学家社区之一。长期以来，致力于构建开放包容的学术环境，产生变创新的经济研究，推动全球经济发展。 ",
  },
  lang:"",
  经济学家都干了什么: "经济学家都干了什么",
  如何利用气候经济学: "如何利用气候经济学，",
  挖掘投资机遇: "挖掘投资机遇？ ",
  "2024年11月14日": "2024年11月14日  14:30 (GMT)",
  "牛津大学Michael Dummett讲座厅": "牛津大学Michael Dummett讲座厅",
  预约报名: "预约报名",
  EBC邀您游览牛津大学: "EBC邀您游览牛津大学 参与全球研讨会",
  EBC金融集团再次携手牛津大学: ` EBC金融集团再次携手牛津大学，将于2024年11月14日举办全球研讨会，这将是我们合作的第一次Hybrid
            event（线下与线上结合研讨会），同时，也是牛津 “经济学家都干了什么”
            首次以问答环节为特色进行圆桌讨论，标志着EBC与牛津大学深化合作伙伴关系的里程碑。此次研讨会以经济增长与气候韧性的平衡为主题，探讨气候经济学与投资前景的挑战，助力投资者挖掘投资先机。`,
  活动介绍: "活动介绍",
  "本次线下研讨会将于11月14日14:30": `本次线下研讨会将于11月14日14:30
            (GMT)在牛津大学基督教堂学院展开，线上同步直播，向全球投资者开放。`,
  EBC金融专家还将与您一同漫步在新学院的回廊:
    "EBC金融专家还将与您一同漫步在新学院的回廊，重温《哈利·波特与火焰杯》的魔法瞬间；或在博德利图书馆，这座全球学者心驰神往的学术圣地，沉浸于牛津几百年的文化底蕴，体验牛津独有的文化魅力。",
  与会嘉宾: "与会嘉宾",
  牛津大学环境复原力及马丁系统韧性负责人:
    "牛津大学环境复原力及马丁系统韧性负责人",
  "Nicola Ranger是牛津大学环境复原力及马丁系统韧性负责人": ` Nicola
                Ranger是牛津大学环境复原力及马丁系统韧性负责人，创立了牛津大学环境变化研究所的全球金融与经济组，与金融机构、中央银行和政府密切合作，推动金融、气候、自然和分析交叉领域的领先研究。Ranger
                一直参与开发压力测试和情景分析方法，包括与国际货币基金组织、世界银行、NGFS
                和英国气候金融风险论坛合作。此外，她还在欧盟委员会
                EMDE可持续投资高级别专家组、英国绿色技术（分类法）咨询小组/LNAS、FCDO
                和伦敦政治经济学院担任顾问职务。 `,
  查看更多: "查看更多",
  牛津大学经济学系教授: "牛津大学经济学系教授",
  牛津大学经济学系教授2: "牛津大学经济学系教授",
  "Andrea Chiavari 是牛津大学经济学系教授": `Andrea Chiavari
                是牛津大学经济学系教授，专注于宏观经济学，尤其是企业动态和技术变革，探索如何利用经济模型支持可持续发展。研究涉及气候变化对企业生产力和资本配置的影响，特别关注如何在不确定环境中提高经济效率和韧性，以及科技在推动经济增长中的作用。`,
  "Banu Demir Pakel 是牛津大学经济学系教授": ` Banu Demir Pakel
                是牛津大学经济学系教授，专注于国际贸易和发展经济学，主要探讨企业如何应对与贸易相关的冲击，以及这种调整如何影响整体经济结果，例如经济增长和收入水平。她同时也是欧洲经济政策研究中心（CEPR）和慕尼黑CESifo研究网络的研究成员。 `,
  "EBC金融集团（英国）首席执行官": "EBC金融集团（英国）首席执行官",
  "David Barrett是EBC 金融集团 (英国) CEO": ` David Barrett是EBC 金融集团 (英国) CEO
                ，在国际资产多元化配置、结构性风险防范领域有超过35年经验，亲历多个市场周期，曾主导全球最大投资集团美国AIG的执行和管理工作，推动了华尔街产品架构和合规风控等领域的一系列变革，为后金融危机时代的风险规避和流动性管理搭建了可行性框架。`,
  往期回顾: "往期回顾",
  EBC金融集团联合牛津大学经济学系第一期:
    "EBC金融集团联合牛津大学经济学系第一期：“经济学家都干了什么 ”研讨会",
  "2024年3月6日EBC与牛津大学联合举办主题为": `2024年3月6日EBC与牛津大学联合举办主题为“经济学家都干了什么 ”
          的研讨会。探讨如何利用经济学推动政策的改进，促进经济繁荣。深入探讨“避税经济学”，全面解读不同税收环境引起的经济趋势及经济学家在全球经济安全中所起的作用等。`,
  关于EBC金融集团: "关于EBC金融集团",
  "EBC Financial Group 成立于英国伦敦": ` EBC Financial Group
          成立于英国伦敦，是一家集金融券商、资产管理、移民投资等服务为一体的综合性金融集团。EBC在全球多个重要金融司法辖区获得授权并受其监管，拥有英国FCA、澳大利亚ASIC、开曼CIMA全监管牌照，在伦敦、香港、东京、悉尼、开曼等多地设有分公司和办事处。
          EBC集团核心人员拥有逾30年的大型金融机构工作经验，亲历多个经济周期，时间跨越85年《广场协议》、97年亚洲金融危机、08年全球金融危机和15年瑞郎黑天鹅事件等。
          EBC是巴塞罗那足球俱乐部在金融领域的官方合作伙伴，同时，携手联合国基金会共同打败疟疾，改善全球卫生系统。`,
  关于牛津大学经济学系: "关于牛津大学经济学系",
  牛津大学是享誉全球的顶尖高等学府: `牛津大学是享誉全球的顶尖高等学府，同时也是英国最具规模的研究机构。在其近千年的建校史上，牛津致力于创建开放包容的学术环境，产生创新的经济研究，构建跨领域的国际合作与交流，推动人类社会发展与进步。`,
  牛津大学经济学系以其专业权威的学术研究: `牛津大学经济学系以其专业权威的学术研究、为全球的经济和公共政策做出的杰出贡献而闻名于世，是世界上最多元化的学术经济学家社区之一。长期以来，致力于构建开放包容的学术环境，产生变创新的经济研究，推动全球经济发展。`,
};
