export default {
  type: "en",
  lang: "th",
  lang2: "th",
  "Trading Central": "วิเคราะห์ตลาดด้วย",
  市场分析: "Trading Central",
  EBC与TradingCentral携手合作:
    "EBC ร่วมงานกับ Trading Central มอบเครื่องมือวิเคราะห์ตลาดระดับมืออาชีพให้กับผู้ใช้งาประจำ เพื่อช่วยเพิ่มประสิทธิภาพกลยุทธ์ เข้าใจแนวโน้มตลาดอย่างลึกซึ้ง ค้นพบโอกาสในการลงทุน ",
  分析师观点: "มุมมองของนักวิเคราะห์",
  财经日历解析: "วิเคราะห์ปฏิทินเศรษฐกิจ",
  "Alpha-MT4/MT5技术指标": "ตัวชี้วัดทางเทคนิค Alpha-MT4/MT5",
  "Martin Pring教学视频": "คลิปสอนวิธีใช้งานโดย Martin Pring",
  解锁完整版: "ปลดล็อคเวอร์ชันเต็ม",
  "*本页面的信息不构成EBC":
    "*ข้อมูลในหน้านี้ไม่ถือเป็นคำแนะนำหรือข้อเสนอในการลงทุนในเครื่องมือทางการเงินของ EBC, Trading Central หรือผู้ให้บริการเนื้อหา EBC, Trading Central และผู้ให้บริการเนื้อหาไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายใด ๆ ที่เกิดจากการใช้ข้อมูลนี้.",
  收起: "จัดเก็บ",
  外汇: "Forex",
  指数: "Indices ",
  商品: "Commodities ",
  星号数代表支撑位和阻力位的强度: "จำนวนดาวแสดงถึงความแข็งแกร่งของระดับการสนับสนุนและแนวต้าน",
  分析师观点: "มุมมองนักวิเคราะห์",
  通过清晰的趋势线展示简明易行的交易计划:
    "แผนการเทรดที่กระชับและปฏิบัติตามได้ง่าย นำเสนอด้วยเส้นแนวโน้มที่ชัดเจน โพสต์และอัปเดตตลอดช่วงการเทรด ช่วยให้คุณค้นพบโอกาสใหม่ๆในการทำกำไร",
  财经日历解析: "วิเคราะห์ปฏิทินเศรษฐกิจ",
  提供38个国家:
    "ให้ข้อมูลเศรษฐกิจมหภาคแบบเรียลไทม์กว่า 38 ประเทศ/ภูมิภาค ช่วยให้นักเทรดได้ติดตาม คาดการณ์ และตอบสนองต่อความผันผวนของตลาดที่จะเกิดขึ้นได้ทันที",
  "Alpha-MT4/MT5 技术指标": "ตัวชี้วัดทางเทคนิค Alpha-MT4/MT5",
  通过三个简明的创新指标展示市场情绪:
    "ประโยชน์ 3 อย่างจากตัวชี้วัดนวัตกรรมใหม่ ได้แก่แสดงถึงความเชื่อมั่นของตลาด 2.ช่วยให้ผู้ใช้งานมีข้อมูลประกอบการตัดสินใจ 3.ระบุจุดเข้า-ออกที่เหมาะสม",
  技术观点: "มุมมองทางเทคนิค：",
  通过技术分析结果: "ผลการวิเคราะห์ทางเทคนิคได้จากการระบุทิศทางและจุดเป้าหมายที่ต้องการบนแต่ละแผนภูมิ",
  自适应K线图: "แผนภูมิ K-Line แบบปรับได้：",
  支持16种与决策相关的K线图形模式: "รองรับรูปแบบแผนภูมิ K-line 16 แบบที่จะเป็นตัวช่วยในการตัดสินใจ",
  "自适应趋异指标(ADC)": "ตัวชี้วัด Adaptive Divergence Convergence (ADC)：",
  "长短进/出信号": "สัญญาณเข้า-ออกแบบยาวและสั้น ตัวบ่งชี้ราคาแบบเร็วและช้า เส้นสัญญาณเริ่มต้นและราบรื่น และเครื่องมือออสซิลเลเตอร์แบบเร็วและช้า",
  下载指标: "ดาวน์โหลดตัวชี้วัด",
  "Martin Pring 教学视频": "คลิปสอนวิธีใช้งานโดย Martin Pring",
  "通过Martin Pring的视频课程":
    "เรียนรู้พื้นฐานการวิเคราะห์ทางเทคนิค หลักการของเทคนิค เช่น เส้นแนวโน้มและค่าเฉลี่ยเคลื่อนที่ ผ่านคลิปวิดีโอของ Martin Pring ร่วมพัฒนาทักษะการเทรดด้วยตัวคุณ",
  灵活的学习模式: "การเรียนรู้แบบยืดหยุ่น：",
  可以自由安排学习进度:
    "คุณพัฒนาศักยภาพด้วยเรียนรู้อย่างอิสระ ฝึกฝนทักษะการลงทุนได้อย่างง่ายดายผ่านเนื้อหาและเครื่องมือที่หลากหลาย",
  广泛的内容覆盖: "เนื้อหาหลักสูตรครอบคลุม：",
  从初级到高级:
    "จากมือใหม่จนถึงมืออาชีพ เนื้อหาครอบคลุมตรงตามความต้องการของผู้ใช้งานทุกระดับและปรับปรุงทักษะและปูพื้นฐานการลงทุนอย่างเป็นระบบ",
  互动学习体验: "เรียนรู้แบบตอบโต้และประเมิณผล：",
  包含术语表和自我评估等互动工具:
    "ประกอบด้วยการทดสอบความรู้และประเมินตนเองด้วยอุปกรณ์แบบโต้ตอบ, ประเมิณผลวัดพัฒนาการ, เพิ่มความเข้าใจส่งเสริมการจดจำแบบระยะยาว",
  了解更多: "เรียนรู้เพิ่มเติม",
};
