export default {
  "screen0": {
    "title": "ئاددىيلاشتۇرۇلغان <br> PAMM ھەل قىلىش چارىسى",
    "content": "EBC كەسپىي ھېسابات باشقۇرۇشنى ئىزدەۋاتقان مەبلەغ سالغۇچىلار ۋە فوند دېرىكتورلىرى ئۈچۈن يېڭىلاش نىسبىتىنى تەقسىملەش باشقۇرۇش ئەندىزىسى PAMM (پىرسەنت تەقسىمات باشقۇرۇش مودۇلى) نى خاسلاشتۇردى. ئىقتىدارلىرى ئەتراپلىق ، تەشكىل ئېنىق.",
    "tips":"EBC فوند دېرىكتورلىرى ۋە مەبلەغ سالغۇچىلارنى پىرسەنت تەقسىمات باشقۇرۇش ئەندىزىسى (PAMM) بىلەن ئېنىق ھوقۇق ۋە مەسئۇلىيەت ۋە ئەتراپلىق ئىقتىدارلار بىلەن تەمىنلەيدۇ ؛ تور نۇسخىسىنىڭ ئارقا كۆرۈنۈشى ئىخچام ۋە ئېنىق ، مۇرەككەپلىكىنى ئاددىيلاشتۇرىدۇ."
  },
  "screen1": {
    "pageEnTitle": "CLEAR DUTIES",
    "pageTitle": "ئېنىق ھوقۇق ۋە مەسئۇلىيەت",
    "c1Title": "مەبلەغ سالغۇچى",
		"c1Desc": "بىر بېكەتلىك سودا مۇلازىمىتىدىن ھۇزۇرلىنىڭ ۋە پۇل باشقۇرغۇچىلارنىڭ سودا نەتىجىسىنى ئورتاقلىشىڭ. مەبلەغ سالغۇچىلار ئۆزىنىڭ خەتەر ئەۋزەللىكى ۋە پايدا نىشانىغا ماس كېلىدىغان فوند دېرىكتورلىرىنى ئەركىن تاللىيالايدۇ. يۇقىرى سۇ بەلگىسى ماددىلىرى فوند باشقۇرغۇچىلارنى قوزغىتىپ ، ئۆزىنىڭ ھوقۇقىنى قوغدايدۇ.    ",
		"c2Title": "پۇل باشقۇرغۇچى    ",
		"c2Desc": "مول سودا تەجرىبىسى بار سودىگەرلەر PAMM ھېسابات فوندى باشقۇرغۇچىنىڭ رولىنى جارى قىلدۇرالايدۇ ، فوند دېرىكتورلىرى بىرلا ۋاقىتتا كۆپ خىل سودا ھېساباتىنى باشقۇرالايدۇ ، ھەمدە ئۆزىنىڭ مەبلىغى ۋە مەبلەغ سالغۇچىلارنىڭ كوللىكتىپ مەبلىغىدىن پايدىلىنىپ سودا قىلالايدۇ. سودا كىرىمى ياكى تەننەرخىنىڭ بىر قىسمى ھېسابات فوندى دېرىكتورىنىڭ ئەمگەك ھەققى سۈپىتىدە ئىشلىتىلىدۇ.    ",
    "title": "PAMM ھېساباتى نۇرغۇن قاتناشقۇچىلارنىڭ تەلىپىنى قاندۇرالايدۇ",
    "desc": "مەبلەغ سالغۇچىلار: بىر قېتىملىق سودا مۇلازىمىتىدىن ھۇزۇرلىنىڭ ۋە فوند باشقۇرغۇچىلارنىڭ سودا نەتىجىسىنى ئورتاقلىشىڭ. <br> مەبلەغ سالغۇچىلار خەتەرگە بولغان ئىشتىھاسى ۋە پايدا نىشانىغا ماس كېلىدىغان فوند دېرىكتورلىرىنى ئەركىن تاللىيالايدۇ. <br> يۇقىرى سۇ بەلگىسى ماددىلىرى پۇل باشقۇرغۇچىلارنى ئىلھاملاندۇرىدۇ ۋە ئۇلارنىڭ ھوقۇقىنى قوغدايدۇ. <br> <br> فوند دېرىكتورلىرى: مول سودا تەجرىبىسى بار سودىگەرلەر PAMM ھېسابات فوندى باشقۇرغۇچىنىڭ رولىنى جارى قىلدۇرالايدۇ. پايدا. سودا پايدىسى ياكى خىراجەتنىڭ بىر قىسمى ھېسابات فوندى دېرىكتورلىرىنىڭ ئەمگەك تولۇقلىمىسى سۈپىتىدە."
  },
  "screen2": {
    "pageEnTitle": "SIMPLE IS THE BEST",
    "pageTitle": "مۇرەككەپلەشتۈرۈشنى ئاددىيلاشتۇرۇڭ",
    "title": "تور نۇسخىسىنىڭ ئارقا كۆرۈنۈشى ئىخچام ۋە ئېنىق بولۇپ ، مۇرەككەپلىكىنى ئاددىيلاشتۇرىدۇ",
    "desc": "كۈندىلىك ئۇچۇر باشقۇرۇش ، سودا ئورنى / پايدا تەقسىملەش ، خاتىرە چۈشۈرۈش قاتارلىق مەزمۇنلارنى ئۆز ئىچىگە ئالغان ئەتراپلىق ھەل قىلىش چارىسى بىلەن تەمىنلەڭ ،  تور نەشرىنىڭ ئارقا كۆرۈنۈشى ئىخچام ۋە ئېنىق ، مۇرەككەپلىكىنى ئاددىيلاشتۇرىدۇ."
  },
  "screen3": {
    "pageEnTitle": "FEATURES AND FUNCTIONS",
    "pageTitle": "ئارتۇقچىلىقى ۋە ئالاھىدىلىكى",
    "title": "ھەر خىل سودا ۋە پۇل باشقۇرۇشقا ماس كېلىدۇ",
    "text1":"9 تىلغىچە",
    "text2":"مەبلەغ بىخەتەرلىكى  راۋان سودا",
    "text3":"2  ئاساسىي ئېقىمدىكى پايدا تەقسىملەش ئۇسۇللىرى  (ساپ قىممەت بويىچە تەقسىملەش ۋە تەڭپۇڭلۇق بويىچە تەقسىملەش)",
    "text4":"يۇقىرى سۇ بەلگىسى  پۇل باشقۇرغۇچىلارغا ئىلھام بېرىش  مەبلەغ سالغۇچىلارنى قوغداش",
    "text5":"تور نۇسخىسىنىڭ دوستانە كۆرۈنمە يۈزى بار  ئېنىق ۋە ئېنىق",
    "text6":"فوند باشقۇرغۇچىلارغا 5 پايدا قايتۇرۇش ئۇسۇلى بىلەن تەمىنلەڭ (پايدا نىسبىتى / باشقۇرۇش ھەققى / يىللىق باشقۇرۇش ھەققى / ھەر بىر كومىسسىيە / زاكاز كومىتېتى)"
  },
	"imgUrl": "zh",
	"styleName": "en-style en-style2",
	"btn-text": "PAMM ھېساباتىغا ئىلتىماس قىلىڭ"
}
