export default {
    "box-class-name": "en-no1-box",
    "lang": "th",
    "挑战最强交易环境": "ท้าทายสภาพแวดล้อมการเทรดที่ดีที่สุด",
    "利润差额奖金": "รางวัลส่วนต่างกำไร",
    "EBC的最强交易环境等您挑战": "ท้าทายกับสภาพแวดล้อมชั้นนำของ EBC ด้วยตัวคุณ! พร้อมรับโบนัสส่วนต่างกำไร 150% ไม่มีขีดจำกัด ",
    "距挑战结束剩余": "เหลือเวลาสำหรับการแข่งขัน",
    "天": "D",
    "时": "H",
    "分": "M",
    "秒": "S",
    "本次活动时间为2024年1月1日": "ระยะเวลาจัดกิจกรรม: 1/1/2024 00:00:00 - 30/9/2024 23:59:59（GMT+2）",
    "发起挑战": "เข้าร่วมเลย",
    "挑战规则": "กฎการเข้าร่วม",
    "EBC致力于为投资者提供极低交易成本": "EBC มุ่งมั่นมอบประสบการณ์การทำธุรกรรมด้วยต้นทุนที่ต่ำที่สุด ความเสถียรคุณภาพสูง การดำเนินการตามคำสั่งที่มีประสิทธิภาพให้กับทุกคน และเพื่อทดสอบปรับปรุงสภาพแวดล้อมการซื้อขายให้มีประสิทธิภาพตลอดเวลา เราได้จัดกิจกรรมเพื่อให้ทุกคนได้ท้าทายสภาพแวดล้อมการเทรดที่แข็งแกร่งที่สุด",
    "开设账户": "สมัครบัญชี",
    "在EBC平台开设一个交易账户": "สมัครบัญชีกับแพลตฟอร์ม EBC",
    "选择平台": "เลือกเเพลตฟอร์ม",
    "选择一家具备FCA或ASIC监管资质的交易商平台": "เลือกแพลตฟอร์มปฏิบัติตามกฎระเบียบ FCA หรือ ASIC",
    "相同策略交易": "เทรดด้วยกลยุทธ์เดียวกัน",
    "使用相同的交易策略在EBC和所选平台进行交易": "สามารถใช้กลยุทธ์การเทรดเดียวกันในแพลตฟอร์ม EBC หรือแพลตฟอร์มที่เลือก",
    "收益比较": "การเปรียบเทียบผลกำไร",
    "在EBC的盈利低于或亏损高于所选择交易商": "ถ้าหากมูลค่าผลกำไรของแพลตฟอร์ม EBC นั้นต่ำกว่าหรือขาดทุนมากกว่าแพลตฟอร์มที่เลือกถือว่าการแข่งขันของคุณประสบความสำเร็จ",
    "卓越的交易环境": "สภาพแวดล้อมการเทรดที่ยอดเยี่ยม",
    "EBC以一流的交易执行速度和先进的技术支持": "EBC สร้างประสบการณ์ที่ยอดเยี่ยมการทำธุรกรรมด้วยความเร็วระดับเฟิร์สคลาสและสนับสนุนด้านเทคนิค เพื่อให้นักลงทุนได้รับบริการที่ได้มาตรฐานระดับสูง",
    "科技赋能交易": "เทรดด้วยเทคโนโลยีขั้นสูง",
    "部署于伦敦LD4": "เซิร์ฟเวอร์อิสระของเราตั้งอยู่ในลอนดอน (LD4), นิวยอร์ก (NY4), สิงคโปร์ (SG1), โตเกียว (TY3) และฮ่องกง (HK2) เชื่อมต่อกันผ่านสายสัญญาณที่แยกกันทำให้เชื่อมต่อได้อย่างรวดเร็ว พวกเขานำเสนอการรวมเวลาแฝงที่ต่ำมาก การกำหนดเส้นทางคำสั่งซื้อแบบอัจฉริยะ และกลไกการปรับราคานำเสนอให้เหมาะสม เพื่อสร้างระบบนิเวศทางการเงินที่มีความเร็วสูงในอุตสาหกรรม",
    "让85%以上的订单成交于更优的价格": "ออเดอร์คำสั่งซื้อขายจะได้รับราคาที่ดีกว่าถึง 85%",
    "EBC交易黑盒": "กล่องเก็บข้อมูลธุรกรรม EBC ใช้อัลกอริทึมในการคำนวณหาราคาที่เหมาะสมและเพิ่มความสำเร็จในคำสั่งซื้อ ระบบกล่องเก็บข้อมูลธุรกรรมยังมีประสิทธิภาพเหนือกว่าระบบการซื้อขายแบบดั้งเดิม",
    "EBC交易空间": "ระบบห้องธุรกรรมส่วนตัว",
    "无论您是高频交易者": "ห้องธุรกรรมส่วนตัว EBC นำเสนอความสามารถในการสร้างช่องทางการซื้อขายแบบกำหนดเองที่ตอบสนองความต้องการสภาพคล่องเฉพาะของลูกค้า ไม่ว่าคุณจะเป็นเทรดเดอร์ที่เทรดบ่อยหรือนักเทรดรายใหญ่ห้องธุรกรรมส่วนตัว EBC จะช่วยคุณรักษาข้อมูลการซื้อขายและกลยุทธ์ของคุณเป็นความลับ",
    "国际顶级流动性": "สภาพคล่องระดับสากล",
    "EBC与多家国际顶级银行建立了长期稳定的合作关系": "EBC ได้สร้างพันธมิตรระยะยาวและมั่นคงกับธนาคารชั้นนำระดับนานาชาติหลายแห่ง จึงมีสภาพคล่องสูง ทำให้มีค่าสเปรดที่ต่ำกว่า",
    "活动须知": "กิจกรรมประกาศ",
    "参与账户": "บัญชีที่เข้าร่วม",
    "新用户": "ผู้ใช้ใหม่: เปิดบัญชีใหม่บริการลูกค้าบนเว็บไซต์ EBC จากนั้นติดต่อฝ่ายบริการลูกค้าบนเว็บไซต์ เพื่อเปิดใช้งานบัญชี VIP และเริ่มต้นการท้าทาย",
    "现有用户": "ผู้ใช้ปัจจุบัน: ติดต่อฝ่ายบริการลูกค้าของ EBC เพื่อเปิดใช้งานบัญชี VIP และเริ่มต้นการท้าทาย",
    "对比交易商平台要求": "เงื่อนไขของแพลตฟอร์ม",
    "选择持有英国FCA或澳洲ASIC监管的交易商平台": "เลือกแพลตฟอร์มการซื้อขายที่ควบคุมโดย UK FCA หรือ ASIC ของออสเตรเลีย แต่สำหรับบัญชีที่ลงทะเบียนภายใต้ AR, EEA หรือชื่อการค้านั้นจะไม่มีสิทธิ์",
    "交易策略": "กลยุทธ์การเทรด",
    "仅限以下主流货币对": `อนุญาตให้ใช้คู่สกุลเงินหลักนี้เท่านั้น: AUD/USD, USD/CAD, EUR/USD, USD/CHF, GBP/USD, USD/JPY และ NZD/USD`,
    "在EBC和所选交易商使用相同的交易资金和交易策略": "แพลตฟอร์ม EBC และแพลตฟอร์มอื่นๆจะต้องใช้เงินทุนและกลยุทธ์เดียวกัน อีกทั้งต้องอยู่ภายใต้ขอบเขตข้อกำหนดการเทรดตามปกติ",
    "奖金结算": "การจ่ายเงินรางวัล",
    "挑战者在其他平台的交易返佣可计入盈利": "เงินคืนจากแพลตฟอร์มอื่นสามารถนับเป็นกำไรได้ แต่ผู้เข้าแข่งขันจะต้องแสดงหลักฐานที่เกี่ยวข้อง",
    "挑战者可在任意挑战时间内选择结算": "ผู้เข้าแข่งขันสามารถเบิกเงินได้ตลอดเวลาระหว่างการแข่งขัน โดยจะอนุญาตให้หนึ่งคนเข้าร่วมได้เพียงครั้งเดียวเท่านั้นต่อหนึ่งบัตรประชาชน",
    "挑战结束后": "หลังจากสิ้นสุดการแข่งขัน ให้ส่งเลขที่บัญชีเพื่อรับเงินรางวัล หลังจากการตรวจสอบ รางวัลจะถูกโอนเข้าบัญชีที่ทำการเทรดภายในสองวันทำการ ซึ่งเงินรางวัลนี้สามารถใช้ในการเทรดหรือถอนเงินได้",
    "重要声明": "หมายเหตุสำคัญ",
    "参赛者应知晓交易风险": "EBC ขอสงวนสิทธิ์ในการตัดสิทธิ์ผู้เข้าร่วมที่ละเมิดกฎการแข่งขันหรือการปลอมแปลงข้อมูล และอาจดำเนินการทางกฎหมาย",
    "任何不遵守比赛规则或篡改数据的行为": "EBC มีสิทธิ์ในการเรียกร้องให้ผู้เข้าแข่งขันจัดเตรียมเอกสารเพิ่มเติมเกี่ยวกับคำสั่งซื้อหรือบัญชีที่มีข้อโต้แย้งหรือมีปัญหาอื่นๆ",
    "EBC有权要求参赛者提供有争议的订单或账户的补充材料": "การแข่งขันครั้งนี้มีวัตถุประสงค์เพื่อมอบสภาพแวดล้อมที่เป็นธรรมและยุติธรรม EBC ขอสงวนสิทธิ์ในการแก้ไข ยกเว้น หรืออธิบายกฎการแข่งขัน",
    "本次挑战赛旨在提供公平": "การแข่งขันครั้งนี้มีวัตถุประสงค์เพื่อมอบสภาพแวดล้อมที่เป็นธรรมและยุติธรรม EBC ขอสงวนสิทธิ์ในการแก้ไข ยกเว้น หรืออธิบายกฎการแข่งขัน",
    "EBC有权根据法律法规": "EBC มีสิทธิ์ในการยกเลิกหรือยุติกิจกรรมตามกฎหมาย ข้อบังคับ หรือคำสั่งของศาลและหน่วยงานต่างๆ",
    "本活动最终解释权归EBC所有":"สิทธิ์ในการตีความกิจกรรมนี้เป็นของ EBC แต่เพียงผู้เดียว"

}