export default {
  type: "en",
  lang: "en",
  lang2: "en",
  "Trading Central": "Trading Central",
  市场分析: "마케팅 분석",
  EBC与TradingCentral携手合作:
    "EBC는 Trading Central과 협력하여 모든 활약적인 고객에게 전문적인 시장 분석 도구를 제공하여 고객이 시장을 깊이 이해하고 거래 기회를 발견하며 거래 전략을 최적화할 수 있도록 지원합니다.",
  分析师观点: "분석가의 관점",
  财经日历解析: "재경 캘린더 분석",
  "Alpha-MT4/MT5技术指标": "Alpha-MT4/MT5기술지표",
  "Martin Pring教学视频": "Martin Pring티칭 비디오",
  解锁完整版: "풀 버전 잠금 해제",
  "*本页面的信息不构成EBC":
    "*이 페이지의 정보는 EBC, Trading Central 또는 그 콘텐츠 제공자의 금융 상품에 대한 투자 조언이나 추천으로 간주되지 않습니다. EBC, Trading Central 및 그 콘텐츠 제공자는 이 정보를 사용하여 발생하는 손실이나 피해에 대해 책임을 지지 않습니다.",
  收起: "닫기 ",
  外汇: "Forex",
  指数: "Indices ",
  商品: "Commodities ",
  星号数代表支撑位和阻力位的强度: "Star Ratings indicate the strength of the support and resistance level",
  分析师观点: "분석가 관점",
  通过清晰的趋势线展示简明易行的交易计划:
    "간단하고 쉬운 거래 계획을 보여주고 거래 세션 내내 지속적으로 출시되는 명확한 추세선으로 새로운 거래 기회를 발견할 수 있도록 도와줍니다.",
  财经日历解析: "재경 캘린더 해석",
  提供38个国家:
    "8개국의 실시간 거시경제 데이터는 트레이더가 잠재적인 시장 변동성을 모니터링, 예측 및 대응하는 데 도움이 됩니다.",
  "Alpha-MT4/MT5 技术指标": "Alpha-MT4/MT5기술지표",
  通过三个简明的创新指标展示市场情绪:
    "트레이더가 의사 결정을 내리고 잠재적인 진입/출구 지점을 찾는 데 도움이 되는 세 가지 간결한 혁신 지표를 통해 시장 심리를 입증합니다.",
  技术观点: "기술적 관점：",
  通过技术分析结果: "기술 분석 결과를 통해 차트별로 선호하는 방향과 목표 지점을 제공합니다.",
  自适应K线图: "적응형 k라인 그라프：",
  支持16种与决策相关的K线图形模式: "16개의 의사 결정 관련 K-라인 그래픽 모드를 지원합니다.",
  "自适应趋异指标(ADC)": "적응형 발산 지수(ADC)：",
  "长短进/出信号": "길이에 맟춘 인/아웃 신호, 빠른 / 느린 가격 지표, 초기 / 부드러운 신호선 및 빠른 / 느린 발진기를 포함합니다.",
  下载指标: "지표 다운로드",
  "Martin Pring 教学视频": "Martin Pring티칭 비디오",
  "通过Martin Pring的视频课程":
    "Martin Pring의 비디오 강좌를 통해 기술 분석의 기초를 배우고, 추세선과 이동평균선 등의 기술을 숙달하며, 거래 기술을 향상시킵니다.",
  灵活的学习模式: "유연한 학습 모드：",
  可以自由安排学习进度:
    "풍부한 콘텐츠와 도구를 통해 자유롭게 공부 일정을 잡고 투자 기술을 쉽게 숙달할 수 있습니다.",
  广泛的内容覆盖: "광범위한 콘텐츠 범위：",
  从初级到高级:
    "초급부터 고급까지 종합적인 코스 콘텐츠는 다양한 수준의 사용자들의 요구를 충족시키며, 체계적으로 투자 스킬을 향상시킵니다.",
  互动学习体验: "대화형 학습 체험：",
  包含术语表和自我评估等互动工具:
    "학습 진행 상황을 감지하고 이해력과 기억 효과를 향상시키기 위한 용어집 및 자체 평가와 같은 대화형 도구를 포함합니다.",
  了解更多: "더 알아보기",
};
