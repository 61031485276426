export default {
  "screen0": {
    "title": "믿을수 있는<br>MAM 해결방안",
    "content": "EBC는 전문 자금 관리자들을 위하여 특별히 멀티계정 관리 시스템을 개발하였습니다. 이는 관리자로 하여금 맞춤 분배 방식, 집합식 주문 소프트웨어, 실시간 보고 기능 및 실시간 커미션 확인 등의 기능을 사용하여 여러 가지 계정에서 쉽게 모니터링할수 있게 합니다. 이는 펀드 매니저 혹은 자금 관리 전문가를 찾는 고객에게 신뢰할 수 있는 협업 경로를 제공합니다.",
    "list": [
      "유연한 할당",
      "즉시보고",
      "간단한 조작 인터페이스"
    ],
    "btn": "계정 신청",
    "rightTitle": "EBC멀티 계정 매니지먼트 시스템 (MAM) 은 효과적 배분, 실시간 보고, 간편한 프로세서 인터페이스를 실천하였습니다."
  },
  "screen1": {
    "pageEnTitle": "Asset Management",
    "pageTitle": "자산 관리",
    "title": "MetaTrader4 용으로 설계",
    "desc": "자동 프로그램(EA)를 활용하여 여러 계정을 관리하는 전문 트레이더에게 매우 적합합니다. 자금 관리 프로그램은 단일 MT4 인터페이스에서의 효과적인 거래와 멀티 계정 관리를 실현합니다.",
    "text": "빠 르 고 효과 적 이 며 정확 하 며 여러<span>투자 자</span>를 위해 거래 할 수 있 습 니 다.",
    "btn": "더 알 아 보기",
    "imgUrl": "en"
  },
  "screen2": {
    "pageEnTitle": "Intelligent Transaction",
    "pageTitle": "지능형 거래",
    "title": "단일 거래 계좌를 통해 여러 투자자와 빠르게 거래 할 수 있습니다.",
    "desc": "MAM계정은 하나의 메인 계정에 해당하며, 여러 개의 팔로우 계정이 있고 그들은 서로 상호 독립적이면서 일종의 어음 형식입니다. 투자자는 거래 총 랏수를 입력하기만 하면 시스템이 투자자가 선택한 배분방식에 따라 거래를 수행하여 다양한 개인 투자자에게 거래 랏수를 자동으로 배분합니다. 계정 관리자는 단일 거래계정을 통해 신속하고 효율적이고 정확하게 다수 투자자를 위해 거래를 진행할 수 있습니다."
  },
  "screen3": {
    "pageEnTitle": "Advantages & Functions",
    "pageTitle": "MAM 기능",
		"pageTitle2": "MAM의 장점",
    "title": "모든 유형의거래 및 자금 관리에 적합",
    "content": [
      "손익 분배 등 10 가지 분배 방법 사용 가능",
      "거래 계좌 수 제한 없음",
      "거래-풀 계정, 미니 계정, 최소 지분 계정",
      "16자리까지 정확한 손익 분배점",
      "모든 주문 유형, 손절/익절, 주문 보류 기능",
      "메인 계정 주문 실행 또는 개인 서브 주문의 부분 마감",
      "고객 관리 계정 지능형 자동 시스템 거래 지원"
    ],
    "desc": "Apply for a MAM Account",
    "btn": "MAM 펀드 매니저 계정 신청",
    "line1": "배분 파라미터 즉시 변경",
    "line2": "모든 관리계정 위치 공개, 실시간 컨트롤",
    "line3": "간단한 한개 인터페이스에서 모든 관리계정",
    "line4": "“그룹거래” 집행",
    "line5": "컨트롤 및 계정의 실시간 자산과 손익 모니터링",
    "line6": "과거 보고서 취득 및 배당이익 계산",
    "btn1": "MAM 펀드 매니저 계정 신청"
  },
	"styleName": "en-style"
}
