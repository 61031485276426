export default {
	"security-class-name": "security-en-box",
	"security-page1": {
		"text1": "Top-Tier Global Regulations",
		"text2": "View License",
	},
	"security-page2": {
		"text1": "Comprehensive Protection",
		"text2": "Up to £85,000 per complaint",
		"text3": "EBC boasts a full FCA licence. One of the few brokers that enables opening an FCA account, the group entitles each customer that has opened an FCA account at EBC to compensation of up to £85000 under the FSCS. The FSCS has been raising the amount of compensation since its inception. It is estimated to pay out £500 million - £600 million anually and £5.5 billion in total.",
		"text4": "Corporate Banking Account",
		"text5": "EBC has Barclays Corporate Banking Account which  requires a minimum turnover of £6.5m, strict financial audits and background check. Adhering to CASS, EBC group (UK) has customer funds held at a custody account through Trust Letter to ensure safety.",
		"text6": "Up to €20,000 per complaint",
		"text7": "The Financial Commission is the first of its kind independent external dispute resolution forum, established exclusively for the purpose of amicably resolving disputes in a transparent and efficient manner. The organization has processed tens of thousands of complaints and awarded over $51 million to traders. EBC enjoys its Compensation Fund that covers up to €20,000 per complaint.",
		"text8": "PI fee over $10 million",
		"text9": "EBC has purchased PI from Lloyds of London and AON with an insured amount of more than $10 million each year. PI safeguards investors against allegations of negligence or breach of duty resulting from acts, errors, or omissions during the provision of professional services, while offering sustainable and professional insurance solutions. ",
		"text10": "Learn more",
	},
}