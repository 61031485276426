export default {
	"cfa1": {
		"title": "Exceptional Brilliant Care for Every Committed Trader.",
		"h1": "Creator Incentive Programme",
		"h1wap": "EBC  Creator Incentive Programme",
		"text": "EBC encourages producing original contents in finance. EBC initiates",
		"text1": "the Creator Incentive Programme to promote original contents in",
		"text2": "favour of traders. exclusive bonuses for creator. ",
		"list": ["FCA and ASIC authority dual regulation", "competitive trading environment ", "order execution in milliseconds", "deep liquidity pools", "swap-free", "EBC Million Dollar Challenge", "order flow tools", "trading community", "shared media resources"],
		"list1":["professional instruction","economic indicator review","real-time analysis","valuable course "]
	},
	"cfa2":"Trust & Respect",
	"cfa3":{
		"title":"reward level ",
		"tips":"reward(USD) ",
		"tips1":"Est for reference only",
		"reward_list": [360, 880, 1800, 3200, 5400, 9000, '11000+'],
		"reward_list_m": ['11000+', 9000, 5400, 3200, 1800, 880, 360],
	},
	"cfa4":"EBC Institute",
	"cfa5":"·To qualify for application, the creator must have a column to produce quality content such as market analysis and trading instruction, in the form including but not limited to article, report or course.<br/>·The creators with submissions found to contain plagiarism will be disqualified. <br/>·Creators can apply for bonus reward once the volume of submitted content meets the requirement. <br/>·Rewards will be distributed on a monthly basis. <br/>·For application, please contact AM or e-mail to mkt@ebc.com <br/>·All creators in breach of the rules will be disqualified with all the rewards revoked. <br/>·EBC reserves the right to make additions or deletions at any time without prior notification.",
	"cfa6":{
		"title":"Exceptional Brilliant Care for Every Committed Trader.",
		"titlewap":"Exceptional Brilliant Care for Every Committed Trader.",
		"desc":"EBC Financial Group (UK) Ltd <br />authorised and regulated by the  FCA<br />Reference Number: 927552",
		"desc1":"EBC Financial Group (Australia) Pty Ltd<br />authorised and regulated by the ASIC<br />Reference Number:500991"
	},
	"lang": "en"
}