export default {
	"leftNav": [{ "id": "#Trading", "name": "取引" },
		{ "id": "#Regulation", "name": "金融規制" },
		{ "id": "#Security", "name": "資金安全" },
		{ "id": "#Fintech", "name": "テクノロジー" },
		{ "id": "#Black-Box", "name": "ブラックボックス" },
		{ "id": "#Private-Room", "name": "取引環境" },
		{ "id": "#Liquidity", "name": "流動性" },
		{ "id": "#Honor", "name": "受賞履歴" },
		//{ "id": "#News", "name": "ニュース" }
	],
	"pages1": {
		"h1":"Trust & Respect",
		"title": "すべてのトレーダーに、誠実な対応を。",
		"btn": " 動画を視聴する "
	},
	"pages2": {
		"title": "お客様に最適なFX取引プラットフォーム",
		"title1": "幅広い取引商品と魅力的なスプレッドで取引優位性を探求します。",
		"btn": "取引商品一覧はこちら",
		"text": " 外国為替取引 ",
		"text1": "コモディティCFD",
		"text2": "株価指数CFD",
		"text3": "個別株CFD"
	},
	"pages3": {
		"title": "トップレベルの金融ライセンス",
		"title1": "EBC Financial Group 傘下の子会社は、各所在地域のライセンスを保有し、認可を受けています。",
		"btn": "詳しくはこちら",
		"text": "英国 FCA ライセンス",
		"text1": "EBC Financial Group (UK) Ltdは、英国金融規制当局（FCA）の認可を受け、規制を遵守しています。登録番号：927552",
		"text4": "ケイマン諸島金融庁（CIMA）ライセンス",
		"text5": "EBC Financial Group (Cayman) Ltdはケイマン諸島金融庁（CIMA）の認可を受け、規制を遵守しています。登録番号：2038223",
		"text2": "オーストラリアASICライセンス",
		"text3": "EBC Financial Group (Australia) Pty Ltdは、オーストラリア証券投資委員会の認可を受け、規制を遵守しています。登録番号：500991"
	},
	"pages4": {
		"title": "テクノロジーで取引の可能性を広げる",
		"title1": "東京、ロンドン、ニューヨークなどの主要金融取引市場に専用サーバーを設置しています。また、専用光回線を活用して、約定力を高めます。",
		"btn": " 詳しくはこちら",
		"text": "1秒あたりの最大注文数 ",
		"text1": "迅速な約定スピード ",
		"text2": "データ通信最大安定度 ",
		"text3": "最低スプレッド 0.0pips",
			},
	"pages5": {
		"title": "85%以上の注文がより有利な価格で約定",
		"title1": "EBCブラックボックスは独自のアルゴリズムを使うことによって、従来の取引システムと比べても、お客様にとってより有利な約定価格と高い約定率を実現可能になります。",
		"btn": "詳しくはこちら"
	},
	"pages6": {
		"title": "EBCプライベートルーム",
		"title1": "EBCの「Private Room」とは、高頻度取引者（HFT）や大口顧客向け専用の取引チャネルのことです。このサービスは、取引に相応の流動性を提供することによって、大きなスリッページや約定拒否が発生することなく、そのうえ有利な価格で約定されることもあります。",
		"btn": "詳しくはこちら"
	},
	"pages7": {
		"title": "トップレベルの流動性",
		"title1": "EBCは世界有数のプライムブローカーと長期的な関係を築いております。トップレベルの流動性プールと連携することで、お客様が安定した取引環境と競争力のあるスプレッド水準でお取引いただけます。",
		"btn": "詳しくはこちら"
	},
	"pages8": {
		"btn": "その他のニュース"
	},
	"pages9": {
		"title": "安心の総合的プロテクション",
		"title1": "EBC は資金の安全を最優先事項と考えています。 顧客の資金はバークレイズ銀行の分離保管口座に保管されます。 FCA、金融委員会、ロイズ オブ ロンドン、AON と協力することで、EBC は包括的で多層的なセキュリティ メカニズムを確立しました。",
		"btn": "詳しくはこちら"
	},
	"lang": "ja"
}

