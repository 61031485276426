export default{
  "lang": "zt",
  "screen0": {
    "title": "金融世界<br />觸手可及",
    "desc": "There is no Trading Secret！<br>Only to find what works best for you and applying it with strick risk management",
    "quick": "快速瞭解",
    "quick1": "4種資產類別",
    "quick2": "100+交易品種",
    "trList": [
      "層級",
      "買入價",
      "買入的交易量",
      "點差",
      "賣出價",
      "賣出價交易量"
    ],
    "smallTrList": [
      "層級",
      "買入價",
      "點差",
      "賣出價"
    ],
    "btn": "開始投資"
  },
  "screen1": {
    "pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "MT4交易平臺",
    "desc": "長久以來,MetaTrader 4被廣泛視為全球零售衍生品交易平臺的行業基準,迄今為止最受歡迎的線上交易平臺。",
    "title": "滿足各種需求的交易平臺Metatrader 4",
    "desc1": "EBC MetaTrader4平臺提供了直觀的、高度定制化的使用者介面,以及强化的圖表功能和精細的訂單管理、分析和警報工具,是您隨時隨地實时與市場保持同步的理想管道。"
  },
  "screen2": {
    "num1": "3種",
    "text1": "不同的圖表類型",
    "num2": "9種",
    "text2": "時間範圍",
    "num3": "50種",
    "text3": "預安裝的技術指標",
    "title": "交易者可以通過訪問3種不同的圖表類型,9個時間範圍,超過50種預安裝的技術指標以及完全可定制的介面,這意味著無論您使用哪種交易風格或系統,都能輕鬆實現高效地頭寸管理和精確地市場監控。",
    "desc": "無論何時何地,您都可以通過我們Web瀏覽器,或PC端/移動設備快速下載MetaTrader 4,直通全球資本市場。",
    "btnText": "想更瞭解更多交易平臺？",
    "btn": "Lab學習中心/交易平臺板塊",
    "smallBtn": "Lab學習中心"
  },
  "screen3": {
    "title": "有機會駕馭的人生,即刻啟程。",
    "desc": " EBC也與MetaQuotes一起努力調整我們的光纖網絡和服務器,給交易者可大幅提高執行速度和所有資產類別的透明報價",
    "link1": "展示交易時段",
    "link2": "查看點差與全部商品",
    "list": [{
        "title": "全面的市場覆蓋",
        "desc": "訪問100+資本市場產品,包括衍生品、股票、指數、商品差價合約等"
      }, {
        "title": "極致的靈活性",
        "desc": "登入一次即可從任何地方訪問您的MT4帳戶。 移動版本提供的功能包括互動式報價圖表，全套交易訂單以及流行的分析工具"
      }, {
        "title": "實时透明的報價",
        "desc": "在交易中，每一毫秒都很重要。 由銀行同業及交易買賣系統提供實时報價，高效透明"
      }, {
        "title": "先進的圖表工具",
        "desc": "先進的圖表功能使您能够通過價格判斷更快更准地做出投資決策"
      }, {
        "title": "即時新聞",
        "desc": "全球各個資本市場的即時新聞讓您掌握第一手市場資訊"
      }, {
        "title": "快速執行訂單",
        "desc": "高速的買賣和即時成交交易指令。 無論您以市價或掛單交易，均可利用止損以控制虧損或是止盈以保護利潤。 支持所有類型的交易訂單，包括市場訂單、掛單、止損訂單以及追跡止損"
      }, {
        "title": "線上查閱帳戶",
        "desc": "您可以隨時查閱自己的持倉、餘額及盈虧等資訊及狀況，當前資產狀況一目了然"
      }, {
        "title": "安全可靠",
        "desc": "平臺與服務器之間的所有數據均採用高達256比特資料加密，EBC為客戶營造一個安全可靠的交易環境"
      }],
    "btn": "馬上下載"
  },
  "screen4": {
    "pageEnTitle": "MT4 Trading Platform",
    "pageTitle": "MT4交易平臺",
    "title": "從單一帳戶交易幾乎所有資本市場產品",
    "desc": "探索MT4平臺的優勢",
    "list": [
      {
        "title": "MT4 移動端下載",
        "desc": "通過MT4移動應用程序控制您的交易，絕不會錯過任何交易機會。"
      },
      {
        "title": "MT4 桌面版下載",
        "desc": "在屢獲殊榮的MT4平台體驗EBC超快的執行速度和強大的交易功能。"
      },
      {
        "title": "MT4 網頁端",
        "desc": "通過網頁瀏覽器在世界上任何一個地方都可以登陸我們的MT4。"
      }
    ],
    "text1": "下載我們",
    "text2": "MT4使用者指南",
    "text3": ",更深入地瞭解MT4所有功能",
    "text4": "指導您輕鬆安裝全球最受歡迎的交易終端",
    "btn": "開始投資"
  },
	"section1": {
		"title": "毫秒必達 &nbsp;交易全球",
	},
	"section2": {
		"title": "滿足各種需求的交易平台",
		"desc": "EBC MetaTrader4提供了簡捷、定制化的用戶界面，以全面的圖表功能和強大的分析工具，幫助您隨時隨地與市場保持同步，實現精細化的訂單管理。全球30多個國家、70%交易平台的選擇，承載全球90%的零售衍生品交易量，Metatrader 4是迄今最受歡迎的在線交易軟件。",
	},
	"section3": {
		"title": "什麼是MetaTrader 4？",
		"desc1": "MetaTrader 4 (MT4)是MetaQuotes Software Corporation開發的一款當今世界最受歡迎的交易平台。由於該平台的技術先進，易於使用，安全係數高，受到了全球交易者的普遍認可。 MT4最主要的功能是允許用戶方便地實現對市場的檢測，使用戶能夠及時有效的進場與退場、限價和止損訂單。",
		"desc2": "選擇EBC的MT4平台，不僅意味著您可以實現技術指標和EAs的定制化，而且您還能夠選擇包括貨幣、貴金屬、原油、股票、指數等一系列交易產品。"
	},
	"section4": {
		"title": "MT4 常見問題",
		"list": [{
			"title": "我已下載MT4，如何登錄我的真實賬戶？",
			"desc": "成功存入資金後，賬戶就會激活，系統會為您提供交易賬戶、密碼和服務器數據。您只需要輸入給定賬戶和密碼，選擇正確的服務器，即可登錄您的的真實賬戶。"
		},
		{
			"title": "如何在MT4上更改密碼？",
			"desc": "在登錄MT4平台後點擊工具—選項，然後在服務器欄即可修改密碼。"
		},
		{
			"title": "如何在MT4上插入圖表指標？",
			"desc": "請執行以下步驟：<br>1. 選擇“插入”->頂部菜單上的“技術指標”；<br>2. 或者可以通過“導航”插入“技術指標”。"
		},
		{
			"title": "如何創建對趨勢線或其他動態的研究？",
			"desc": "請執行以下步驟：<br>1. 在頂部菜單上選擇「插入」；<br>2. 選擇您想要的趨勢線；<br>3. 釋放點擊，十字線圖標會出現選擇的趨勢線。在選定的圖表上移動十字線，點擊鼠標並按住點擊，移動鼠標調整趨勢線的大小。也可以通過點擊「瀏覽」->「工具欄」->「線型研究」快速使用趨勢線研究快捷鍵，圖表會出現在菜單欄下方。您可以通過點擊趨勢線、十字線或斐波那契回調線選擇屏幕上方的其他選項。"
		},
		{
			"title": "如何在MT4上選擇用戶語言？",
			"desc": "前往頂部菜單選擇查看->語言->例如，簡體中文，然後重啟軟件，用戶語言就會變成簡體中文。"
		}
		],
	},
	"btn-text": "即刻下載MT4",
  "download-btn1": "下載Android版",
	"download-btn2": "下載iOS版",
	"download-btn3": "下載iPad版",
	"download-btn4": "下載Windows版",
	"download-btn5": "下載MacOS版",
	"download-btn6": "網頁版MT4",
}
