export default {
	"screen0": {
		"title": "<span>We Value Your Safety</span><br>持牌·安全·穩定",
	},
	"screen1": {
		"link1": "EBC簡介",
		"link2": "國際頂級監管",
		"link3": "國際榮譽",
		"link4": "EBC優勢",
		"link5": "企業文化",
		"link6": "合作夥伴",
		"link7": "聯繫我們",

	},
	"screen2": {
		"title": "EBC簡介",
		"desc1": "EBC Group，成立於英國倫敦，是一家集金融券商、資產管理、移民投資等服務為一體的綜合性金融集團。 EBC業務遍布全球，在東京、悉尼、新加坡、香港等國際金融中心設有分公司和辦事處，管理層擁有逾30年的環球金融市場營運經驗。",
		"desc2": "EBC始終把維護資金安全作為首要責任。除擁有全球最嚴格監管外，EBC還為合作夥伴提供巴克萊銀行獨立託管賬戶，每年購超千萬美元保額的保險，並嚴格執行CASS規定，以雄厚的資本實力和最高級別的風險管理措施，為客戶隔離不確定的風險因素。 ",
		"desc3": "EBC堅信，每個認真交易的人，都值得被認真對待。"
	},
	"screen3": {
		"title": "國際頂級監管",
		"desc": "EBC金融集團旗下各公司，在對應司法管轄區域內，均受到嚴格監管並獲得頂級資格許可。",
		"desc-l1": "EBC Financial Group (UK) Ltd",
		"desc-l2": "英國金融行為監管局 (FCA) 授權和監管",
		"desc-l3": "監管號: 927552",
		"desc-r1": "EBC Financial Group (Australia) Pty Ltd",
		"desc-r2": "澳大利亞證券和投資委員會 (ASIC) 授權和監管",
		"desc-r3": "監管號: 500991",
		"desc-rr1": "EBC Financial Group (Cayman) Ltd",
		"desc-rr2": "由開曼金融管理局（CIMA）授權和監管",
		"desc-rr3": "監管號: 2038223",
	},
	"screen4": {
		"title": "國際榮譽",
		"desc1": "全球最受信賴券商",
		"desc2": "最佳CFD券商",
		"desc3": "十大最受歡迎券商",
		"desc4": "全球最佳ECN券商",
		"desc5": "全球新銳券商",
		"desc6": "最透明交易券商",
		"desc7": "最受好評券商",
		"desc8": "卓越執行券商",
		"desc9": "全球最佳交易體驗獎",
	},
	"screen5": {
		"title": "EBC優勢",
		"list": [
			{
				"title": "1000<i>筆</i>",
				"desc": "每秒最高聚合訂單"
			},
			{
				"title": "<20<i>MS</i>",
				"desc": "平均執行速度"
			},
			{
				"title": "98<i>.75%</i>",
				"desc": "數據傳輸穩定性高達"
			},
			{
				"title": "25<i>+</i>",
				"desc": "對接國際頂級銀行和對沖基金"
			},
			{
				"title": "7*24",
				"desc": "VIP尊享服務"
			},
			{
				"title": "0.0<i>PIPS",
				"desc": "同業銀行級別的 RAW ECN點差"
			},
		]
	},
	"screen6": {
		"title": "企業文化",
		"desc": "我們的核心價值在於積極為客戶創造全方位<span>「Real Value」</span>",
		"list": [
			{
				"title": "安全",
				"desc": "英澳頂級監管，巴克萊託管<br>清算級安全保障"
			},
			{
				"title": "公平",
				"desc": "EBC秉承誠信與尊重的原則<br>認真對待每一位交易者"
			},
			{
				"title": "專注",
				"desc": "在細節中感知EBC服務理念<br>提供最卓越的用戶體驗"
			},
			{
				"title": "價值",
				"desc": "“安全、專業、穩定”<br>全方位提升個人財富價值"
			},
			{
				"title": "誠實",
				"desc": "EBC視誠實守信為根本<br>用心呈現完美交易體驗"
			},
			{
				"title": "責任",
				"desc": "每一次積極的傳遞<br>都讓世界向美好更進一步"
			},
		]
	},
	"screen7": {
		"title": "合作夥伴",
	},
	"screen8": {
		"title": "聯繫我們",
		"desc1": "市場合作",
		"desc2": "加入我們",
		"desc3": "投訴與建議",
	},
}

