export default {
    "box-class-name": "en-box",
    "lang": "ko",
    "MetaTrader 5 (MT5) 是新一代多资产交易平台": "MetaTrader 5(MT5)는 전 세계 트레이더의 다양한 요구를 충족시키기 위해 보다 발전된 트레이딩 기능과 분석 도구를 통합하는 차세대 다중 자산 트레이딩 플랫폼입니다.",
    "提供市场深度信息": "거래자가 시장의 유동성을 더 잘 이해할 수 있도록 심층적인 시장 정보를 제공합니다.",
    "支持6种挂单交易类型": "6가지 유형의 보류 중인 주문 거래를 지원하며 다양한 거래 요구 사항을 유연하게 만족합니다.",
    "内置经济日历": "내장된 경제 캘린더는 전 세계의 중요한 경제 이벤트를 실시간으로 업데이트합니다.",
    "立即注册": "지금 등록",
    "MetaTrader 5下载": "MetaTrader 5 다운로드",
    "MT5移动版": "MT5 모바일 버전",
    "随时随地进行交易": "언제 어디서나 거래하고 시장 트렌드를 따라가며 계정을 쉽게 관리할 수 있습니다.",
    "下载Android版": "Android 버전 다운로드",
    "下载IOS版": "IOS 버전 다운로드",
    "下载iPad版": "iPad 버전 다운로드",
    "MT5桌面版": "MT5 데스크톱 버전",
    "全面的交易工具和高级图表分析": "전문 트레이더의 고급 요구 사항을 충족하는 포괄적인 거래 도구와 고급 차트 분석입니다.",
    "下载Windows版": "Windows 버전 다운로드",
    "下载MacOS版": "MacOS 버전 다운로드",
    "MT5网页版": "MT5 웹트레이더",
    "无需下载": "다운로드 없이도 빠른 속도로 글로벌 시장에 접근할 수 있어 온라인 트랜잭션 관리를 쉽게 할 수 있습니다.",
    "打开网页版": "웹트레이더 열기",
    "MT4与MT5的区别": "MT4와MT5의 구별",
    "MT4和MT5均由MetaQuotes开发": "MT4와 MT5는 모두 MetaQuotes에 의해 개발되었지만 MT5는 MT4에서 단순하게 업그레이드된 것은 아닙니다. 두 가지는 기능과 적용 범위 면에서 큰 차이가 있습니다.",
    "项目": "항목들",
    "发布时间": "출고시간",
    "2005年": "2005년",
    "2010年": "2010년",
    "交易市场": "거래 시장",
    "主要是外汇": "주로 외환",
    "包括股票": "주식, 선물, 외환 등.",
    "挂单类型": "보류 중인 주문 유형",
    "4种": "4가지",
    "6种": "6가지",
    "时间框架": "타임 프레임",
    "9种": "9가지",
    "21种": "21가지",
    "技术指标": "기술지표",
    "30个": "30개",
    "38个": "38개",
    "市场深度":"시장 심도",
    "不支持":"지원하지 않음",
    "支持":"해당여부",
    "财经日历":"경제 캘린더",
    "内置":"내장",
    "操作界面":"조작 인터페이스",
    "更直观简单":"보다 단순하고 간단합니다",
    "更加现代化灵活":"보다 현대적이고 유연함"
}