export default {
  "box-class-name": "en-box",
  "lang": "en",
  "MT4是最受欢迎的交易平台之一": "MetaTrader 4 (MT4) بۈگۈنكى كۈندە دۇنيادىكى ئەڭ ئالقىشقا ئېرىشكەن سودا سۇپىلىرىنىڭ بىرى ، بۇ سۇپىنىڭ ئىلغار تېخنىكىسى ، ئىشلىتىش قولايلىقلىقى ۋە يۇقىرى بىخەتەرلىك ئامىلى دۇنيانىڭ ھەرقايسى جايلىرىدىكى سودىگەرلەر تەرىپىدىن كەڭ ئېتىراپ قىلىندى.",
  "内核稳定流畅": "مول دىئاگرامما ئانالىز ئىقتىدارلىرى بىلەن تەمىنلەپ ، سودىگەرلەرنىڭ ئېنىق سودا ئىستراتېگىيىسىنى تۈزۈشىگە ياردەم بېرىدۇ.",
  "支持EA交易": "EA ئەقلىي ئىقتىدارلىق سودا سىستېمىسىنى ئاپتوماتىك سودا مەشغۇلاتىنى ئەمەلگە ئاشۇرۇشنى قوللايدۇ.",
  "活跃用户社区": "ئۇنىڭدا ئوخشىمىغان سودىگەرلەرنىڭ ئېھتىياجىنى قاندۇرۇش ئۈچۈن ھەر خىل تېخنىكىلىق كۆرسەتكۈچ ۋە ئانالىز قوراللىرى بار.",
  "立即注册": "ھازىر تىزىملىتىڭ",
  "MetaTrader 4下载": "MetaTrader 4 چۈشۈرۈش",
  "MT4移动版": "MT4 كۆچمە نۇسخىسى",
  "随时随地进行交易": "ھەر ۋاقىت ، ھەر جايدا سودا قىلىڭ ، بازار يۈزلىنىشىنىڭ ئۈستىدە تۇرۇڭ ، ھېساباتنى ئاسان باشقۇرۇڭ.",
  "下载Android版": "ئاندروئىد نەشرىنى چۈشۈرۈڭ",
  "下载IOS版": "IOS نەشرىنى چۈشۈرۈڭ",
  "下载iPad版": "IPad نەشرىنى چۈشۈرۈڭ",
  "MT4桌面版": "MT4 ئۈستەل يۈزى نۇسخىسى",
  "全面的交易工具和高级图表分析": "ئۇنىۋېرسال سودا قوراللىرى ۋە ئىلغار جەدۋەل ئانالىزى كەسپىي سودىگەرلەرنىڭ ئىلغار ئېھتىياجىنى قاندۇرىدۇ.",
  "下载Windows版": "Windows نەشرىنى چۈشۈرۈڭ",
  "下载MacOS版": "MacOS نەشرىنى چۈشۈرۈڭ",
  "MT4网页版": "MT4 تور نەشرى",
  "无需下载": "چۈشۈرۈشنىڭ ھاجىتى يوق ، دۇنيا بازىرىغا تېز كىرىش ۋە تور سودىسىنى ئەتراپلىق باشقۇرۇشنى ئەمەلگە ئاشۇرۇش ئاسان.",
  "打开网页版": "تور نەشرىنى ئېچىڭ",
}