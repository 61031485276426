export default {
    "box-name": "",
    "public-btn-text1": "ھازىر تىزىملىتىڭ",
    "public-btn-text2": "بارلىق مەھسۇلاتلار",
    screen0: {
        "title": "CFD پاي ئۆسۈمى",
        "desc": "دىققەت قىلىڭ ، CFD پاي ئۆسۈمى MT4 ھېساباتىڭىزدىكى قالدۇق پۇلدا ئامانەت ياكى پۇل ئېلىش شەكلىدە ئىپادىلىنىدۇ ، «پاي ئۆسۈمى» دەپ ئىزاھلىنىدۇ.        ",
    },
    screen1: {
        "title": "بىخەتەر سودا  ",
    },
    screen2: {
        "title": "پۇل-مۇئامىلە دۇنياسى سىزنىڭ قولىڭىزدا   ",
    }
}