export default {
	"srceen0": {
		"title": "Đòn bẩy & Ký quỹ",
		"subTitle": "界定<span>交易方式</span>的关键",
		"desc": "Do sự không chắc chắn và biến động cao trên thị trường tài chính quốc tế, EBC rất coi trọng sự an toàn của tài sản của khách hàng. Chúng tôi cung cấp cho mọi khách hàng tùy chọn để chọn đòn bẩy ưa thích từ 1:1 (không có đòn bẩy) đến tối đa 500:1",
		"rateTitle": "杠杆率高达",
		"rateNum": "500:1",
		"rateDesc": "保证金比例0.20%"
	},
	"srceen1": {
		"pageEnTitle": "Secure Transaction",
		"pageTitle": "安全交易",
		"subTitle": "选择适合的杠杆比例",
		"desc1": "Vui lòng xem xét cẩn thận tỷ lệ đòn bẩy nào phù hợp với nhu cầu của bạn.",
		"desc2": "Vui lòng tham khảo bảng để xem Yêu cầu ký quỹ và Đòn bẩy tối đa trên mỗi tài sản của chúng tôi.",
		"btn": "Bắt đầu giao dịch",
		"title":""
	}
}

