export default {
	"pages1": {
		"title": "مەھەللىسى سودا ئىقتىدارلىق  ئەقلىي  EBC",
		"tips": "ھەر بىر سودا قىلغۇچىنىڭ قەلبىدە بىر قەھرىمانلىق ئارزۇسى بار.",
		"text": " سودا مەھەللە ئەۋزەللىكى",
		"text1": "سىگنال مەنبەسى نامايەن قىلىندى.",
		"text2": "سودا خادىملىرىنى مۇكاپاتلاش پىلانى.",
		"text3": "قوشۇلما قىممەت سوغىسى",
		"text4": "ھېسابات ئېچىش"
	},
	"pages2": {
		"title": " سودا مەھەللە ئەۋزەللىكى",
		"list": [{
			"tit": " ئەپچىل  سودىغا ئەگىشىش ئەندىزىسى  تېخىمۇ  ",
			"tips": "قىستۇرما دېتال قوش يۆنىلىشلىك ئەگىشىش ئىشقا ئاشۇرۇش ھاجەتسى  EA<br />ئەگىشىش ئۇسۇلىنى  ئەپچىل  تەڭشەش كېرەك.<br />ئەگىشىش ئۇسۇلىنى  ئەپچىل  تەڭشەش كېرەك."
		}, {
			"tit": " بىلەن ئەگىشىش تېزلىك  « مىللىسېكۇنت »",
			"tips": " ئاساسىي قاتلام ئالاقە كېلىشىمى  MT<br /> سىگنال تەرەپ ۋە  سودا ھېساباتىنىڭ ئۈنۈملۈك ئۇلىنىلىشى <br />دەرھال ئىنكاس قايتۇردى"
		}, {
			"tit": "مۇستەقىل ئالتە ئۆلچەملىك رەسىم كۆرسەتكۈچىنى كىرگۈزۈش",
			"tips": "مۇستەقىل ئالتە ئۆلچەملىك رەسىم كۆرسەتكۈچىنى كىرگۈزۈش<br />فوندنىڭ دەرىجە باھالاش سىستېمىسى يېڭى يۈكسەكلىككە قەدەم قويدى<br />ئەلا سۈپەتلىك سىگنال چىقىش ئالدىدا تۇرماقتا"
		}, {
			"tit": " ياردەمچىسىنى تاللايدۇ   AI « تېخىمۇ ئەقلىي ئىقتىدارلىق » ",
			"tips": " سودىغا ئەگىشىش مايىللىقىغا ئاساسەن<br />بىر كۇنۇپكا بىلەن نىشان سىگنالىنى تاللاش<br />يەر شارى بويىچە ئەڭ يۇقىرى دەرىجىلىك سودا تاكتىكىسى بار"
		}, {
			"tit": " سودا كۆرگەزمىسى   «   ئېنىق   تېخىمۇ»",
			"tips": "سىگنال مەنبەسى تارىخى زاكازنى  سۈرۈشتۈرۈشكە بولىدۇ<br />مەشغۇلات تەپسىلاتى تولۇق كۆرۈنۈپ تۇرىدۇ<br /> سودا ماھىرلىرنىڭ  سودا قىلىش بىلىملىرىنى ئۆزلەشتۈرۈپ ئۆگىنىش "
		}],
		"btn": " سودا مەھەللىسىگە تىزىملىتىپ كىرىش"
	},
	"pages3": {
		"title": "سىگنال مەنبەسى نامايەن قىلىندى",
		"text": "پايدا(ئېشىندى) نىسبىتى",
		"text1": "ئەڭ چوڭ قايتىش",
		"text2": "شارپ نىسبىتى",
		"text3": "سودىدا ئۇتۇش نىسبىتى",
		"text4": "ھەقسىز",
		"text5": "دەرھال مۇشتەرى بولۇڭ",
		"btn": "تېخىمۇ كۆپ سىگنالنى تەكشۈرۈش"
	},
	"pages4": {
		"title": "سودا خادىملىرىنى مۇكاپاتلاش پىلانى",
		"num": " ئايدا   $100    مۇكاپاتلايدۇ",
		"num1": "ئايدا   $300    مۇكاپاتلايدۇ",
		"num2": "  ئايدا   $1,000    مۇكاپاتلايدۇ",
		"num3": "ئايدا   $5,000    مۇكاپاتلايدۇ",
		"num4": " ئايدا   $10,000    مۇكاپاتلايدۇ",
		"text1": "مەبلەغنى باشقۇرۇش",
		"text2": " ئەگەشكۈچى خېرىدارلار مىقدارى",
		"name": " يۇلتۇز دەرىجىلىك سودا   ماھىرى",
		"name1": " قالتىس   سودا  ماھىرى",
		"name2": "چېمپىيون   سودا  ماھىرى",
		"name3": "چولپان   سودا  ماھىرى",
		"name4": " مۆجىزە   دەرىجىلىك سودا  ماھىرى",
		"tips": "+% 1 باشقۇرۇلىدىغان مەبلەغ / يىل (ھەر ئايدا تۆلىنىدۇ)",
		"btn": "دەرھال پىلانغا كىرىش"
	},
	"pages5": {
		"title": "قوشۇلما قىممەت سوغىسى",
		"text": " خېرىدارلار مۇلازىمىتى  7*24",
		"text1": " سودا سېتىق تەشۋىقات پائالىيىتى   EBC",
		"text2": " ياردەم بەرگۈچى  VPS",
		"text3": "سودا كىتاب ياردەم ",
		"text4": "زاكاز ئېقىم ئىشلىتىش ھوقۇقى",
		"text5": " چوڭ سوۋغىسى EBC",
		"text6": "سىگنال ئورنىتىلغان چوققا نامايەن قىلدى",
		"text7": "چەت ئەلدىكى ئىشخانىنى ئېكسكۇرسىيە قىلىش",
		"text8": "ئاخبارات ۋاستىلىرى يار-يۆلەك بولدى",
		"text9": "ئىستراتېگىيەلىك ھەمكارلىق",
		"text10": "چەت ئەل فوند مەھسۇلاتلىرىنى تارقىتىش مەسلىھەتچىلىك مۇلازىمىتى",
		"btn": " سودا مەھەللىسىگە تىزىملىتىپ كىرىش"
	}
}