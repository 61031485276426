export default {
	"box-name": "partner2-page-en",
	"section1": {
		"text1": "Quan hệ đối tác",
		"text2": "Một hệ sinh thái sáng tạo tập hợp các nhà sáng tạo nội dung, chuyên gia marketing và IB, cho phép các nhà giao dịch xuất sắc hợp tác cùng nhau, mở rộng cơ hội toàn cầu và khám phá những khả năng vô hạn.",
		"text3": "Hoạt động kinh doanh trải rộng tại hơn 20 quốc gia",
		"text4": "Tập hợp nhiều chuyên gia hàng đầu trong ngành",
		"text5": "Các giải pháp hỗ trợ hợp tác đa dạng",
		"text6": "Hệ thống hỗ trợ tài nguyên toàn diện",
	},
	"section2": {
		"text1": "Phương án hợp tác",
		"text2": "Introducing Broker (IB)",
		"text3": "Nhà giáo dục ngành, nhà giao dịch chuyên nghiệp, người đam mê giao dịch và công ty",
		"text4": "<li>Kế hoạch phát triển kinh doanh</li><li>Cơ hội tiếp cận và quảng bá thương hiệu</li><li>Hỗ trợ tài nguyên giao dịch & hoạt động marketing</li>",
		"text5": "Trở thành IB",
		"text6": "Đối tác tiếp thị CPA",
		"text7": "Chủ sở hữu website/blog, KOL, nhà tiếp thị internet",
		"text8": "<li>Mô hình thưởng kết hợp</li><li>Nhiều phương án hợp tác</li><li>Hỗ trợ marketing đa phương tiện</li>",
		"text9": "Trở thành đối tác marketing",
		"text10": "Người hỗ trợ chiến lược",
		"text11": "Chuyên gia phân tích, người đam mê giao dịch, chuyên gia chiến lược",
		"text12": "<li>Quà tặng độc quyền</li><li>Online - Offline kết hợp</li><li>Hỗ trợ tài nguyên giao dịch & hoạt động marketing</li>",
		"text13": "Trở thành người hỗ trợ chiến lược",
	},
	"section3": {
		"text1": "Hỗ trợ tài nguyên marketing",
		"text2": "Cơ hội quảng bá thương hiệu",
		"text3": "Cung cấp nơi để phơi bày kiến thức chuyên môn, hỗ trợ đối tác đạt được sự công nhận rộng rãi.",
		"text4": "Mở rộng mối quan hệ trong ngành",
		"text5": "Kết nối với các chuyên gia hàng đầu trong ngành, trao đổi kinh nghiệm, cùng khám phá xu hướng ngành",
		"text6": "Hỗ trợ tài nguyên marketing",
		"text7": "Cung cấp tài liệu marketing và hỗ trợ hoạt động, tùy chỉnh chiến lược quảng bá cho đối tác.",
		"text8": "Đào tạo chuyên sâu cho nhà phân tích cao cấp",
		"text9": "Đào tạo nhà phân tích chuyên nghiệp, nâng cao kỹ năng và kiến thức chuyên môn",
		"text10": "Order flow",
		"text11": "Nhận diện dòng vốn chủ lực, nâng cao tỷ lệ thắng trong giao dịch, công cụ cần thiết cho nhà giao dịch chuyên nghiệp",
		"text12": "Mở rộng tầm ảnh hưởng kiến thức",
		"text13": "Hỗ trợ nhà sáng tạo nội dung tích hợp kiến thức, mở rộng tầm ảnh hưởng, nâng cao hình ảnh chuyên nghiệp",
	},
	"section4": {
		"text1": "Thành viên liên minh",
		"text2": "Joe DiNapoli",
		"text3": "<li>Cha đẻ của Fibonacci</li><li>Nhà sáng lập DiNapoli Levels</li><li>Chuyên gia hàng đầu ứng dụng tỷ lệ vàng</li>",
		"text4": "Joe DiNapoli có 55 năm kinh nghiệm trên thị trường, từng dự đoán các điểm ngoặt chu kỳ như sự sụt giảm mạnh của chỉ số Dow vào năm 1987 và đáy của thị trường vào năm 2002, được tôn vinh trên toàn cầu. Là một cố vấn giao dịch hàng hóa (CTA) hơn 25 năm, ông đã viết cuốn sách \"Giao dịch theo các mức DiNapoli (DiNapoli Levels)\", cuốn sách này đã được dịch ra 13 ngôn ngữ và nổi tiếng trên toàn thế giới.",
		"text5": "Learn more",
		"text6": "<li>Lawrence, with a background in commercial construction, transitioned to the financial markets for a more fulfilling career. Discovering Joe DiNapoli's \"DiNapoli Levels\" transformed his trading approach. Since attending Joe's seminar in Bangkok 17 years ago, Lawrence accurately predicts market movements, including navigating the Covid Market Crash. Now a DiNapoli Expert, he mentors new traders to avoid pitfalls and accelerate learning.</li>",
		"text7": "<li>Pieter has been interested in trading since high school. As a Cum Laude qualified economist, he started full time trading at a local South African firm, intensely trading a broad spectrum of markets. He has been a student of DiNapoli studies since 2003 and teaching the DiNapoli method across the world since 2009. Today, he manages his own account, is part of a South African hedge fund and advisor to South African commodity firms. He is also the author of the D-Levels Financial Forecast newsletter. He attributes much of his success to Joe DiNapoli’s mentorship.</li>",
		"text8": "<li>Monchai Kongthanapakdi is founder of Technical Analysts Association in Thailand and Co-chair of CMT Association Thailand Chapters as well as president of Efuturesthai Trading Academy, an organization established for teaching and training technical analysis for Thai investors.<br><br>He is a full-time trader, technical analyst, and investor who specializes in Fibonacci (DiNapoli Levels) and Algorithmic trading. Monchai has been working with Mr. Joe DiNapoli since 2011 to conduct several public and private seminars in Thailand including a recent translation of Trading with DiNapoli Levels (Thai Edition) in 2022.</li>",
		"text9": "<li>President of Fibonacci Investment Consulting, LLC, USA; Guest Lecturer at the New York Institute of Finance Beijing Branch and the Chicago Board of Trade; Gold Medal Mentor at the National Real-Money Trading Competition of China Futures Daily; Translator of the Chinese edition of \"Trading with DiNapoli Levels\". As a senior expert of the DiNapoli-Levels, he has successfully predicted nearly all the turning points of the Shanghai Composite Index since 2008, as well as several intermediate tops and bottoms of the Dow Jones Index, the US Dollar Index, US crude oil futures, and US gold futures.</li>",
	},
	"section5": {
		"text1": "Tìm hiểu thêm",
		"text2": "Ngày 12 tháng 7 năm 2024, EBC Financial Group và bậc thầy giao dịch Joe DiNapoli đã tổ chức lễ ký kết toàn cầu tại Bangkok, thảo luận về các chiến lược giao dịch, nhằm nâng cao hơn nữa tầm ảnh hưởng của cả hai bên trên thị trường toàn cầu",
		"text3": "Chia sẻ chiến lược giao dịch",
		"text4": "DiNapoli và đội ngũ của ông đã chia sẻ các chiến lược giao dịch tiên tiến, thu hút sự chú ý của giới truyền thông và các nhà đầu tư trên toàn cầu",
		"text5": "Tặng sách kèm chữ ký",
		"text6": "Tặng sách phiên bản giới hạn có chữ ký của DiNapoli tại sự kiện, nâng cao độ nhận biết thương hiệu cá nhân.",
		"text7": "Nâng cao tầm ảnh hưởng đôi bên",
		"text8": "Sự hợp tác này thúc đẩy sự phát triển chuyên nghiệp trong giao dịch, mang lại sự công nhận và nâng cao ảnh hưởng cho cả hai bên trong ngành.",
	},
}