export default {
  "box-name": "en-style",
  "public-btn-text1": "سودا ۋاقتىنى كۆرۈڭ",
  "public-btn-text2": "بارلىق سودا تۈرى تەكشۈرۈش",
  screen0: {
      "title": "ۋاقىت ئارقىلىق سىز م<br>ېنى تېخىمۇ چۈشىنىسىز",
      "desc": "60 خىلدىن ئارتۇق پۇل مۇئامىلە مەھسۇلاتى ۋە رىقابەت كۈچىگە ئىگە ئورگان بېكىتكەن باھا دۇنيا بويىچە ئەڭ ئالقىشقا ئېرىشكەن ھەم يۆتكىلىشچانلىقى ئەڭ يۇقىرى بازارغا كىرىپ، 247 سودا پۇرسىتىنى كونترول قىلىدۇ",
      "btn": "كۆپرەك ئۆگىنىڭ",
  },
  screen1: {
      "link1": "نەق مال كۆرسەتكۈچى باھا پەرقى توختامى",
      "link2": "كۆپ مىقدارلىق تاۋارلار",
      "link3": "تۇغۇندى سودىسى",
      "link4": "CFD نى ئورتاقلىشىش",
  },
  screen2: {
      "title": "تۇغۇندى سودىسى",
      "text1": "ھەرىكەتچان پىشاڭ500:1",
      "text2": "20msئوتتۇرىچە ئىجرا قىلىش سۈرئىتى",
      "imgUrl": "trade-01",
      "title2": "ئەڭ ئاز بولغاندا 0 تۇرۇبا <br> 36 پۇل جۈپ",
      "desc2": "دۇنيادىكى ئەڭ چوڭ پۇل-مۇئامىلە سودا بازىرى خەلقئارا راسچوت بانكىسى (BIS) نىڭ 2019-يىلى 9-ئايدىكى دوكلاتىغا قارىغاندا ، يەر شارى تۇغۇندى بازىرىنىڭ سودا مىقدارى تارىختىكى ئەڭ يۇقىرى رېكورتقا ئۆرلىگەن ، كۈندىلىك ئوتتۇرىچە سودا سوممىسى 6 تىرىليون 600 مىليارد دوللار.",
  },
  screen3: {
      "title": "كۆپ مىقدارلىق تاۋارلار",
      "text1": "جانلىق پىشاڭ",
      "text2": "ناھايىتى رىقابەت كۈچىگە ئىگە",
      "text3": "جانلىق ئوچۇق ئورۇن سودىسى",
      "title2": "ئەگەر مەبلەغ سېلىش گۇرۇپپىڭىزنى كۆپ خىللاشتۇرماقچى بولسىڭىز ، تاۋار سىزنىڭ كۆڭۈلدىكىدەك تاللىشىڭىز بولىدۇ",
      "desc2": "قىممەتلىك مېتاللار ، خام نېفىت ، تەبىئىي گاز قاتارلىقلار.",
  },
  screen4: {
      "title": "نەق مال كۆرسەتكۈچى باھا پەرقى توختامى",
      "text1": "ھەقىقىي ۋاقىتتىكى جانلىق باھا",
      "text2": "ئوتتۇرىچە ئىجرا سۈرئىتى 20ms",
      "text3": "100: 1 گە يېتىدۇ",
      "text4": "يوشۇرۇن كومىسسىيە ۋە سودا ھەققى يوق",
      "title2": "سىز ئۈچۈن ئەڭ يۇقىرى كۆرسەتكۈچلەرنى تاللاڭ ۋە دۇنيا ئىقتىسادىنىڭ ئەڭ قىممەتلىك ۋە ئاكتىپ ساھەلىرىنى ئاكتىپلىق بىلەن تەتقىق قىلىڭ",
  },
  screen5: {
      "title": "CFD نى ئورتاقلىشىش",
      "text1": "رىقابەت كۈچىگە ئىگە باھا بېكىتىش، ئامېرىكا پاي چېكى سودىسى ئۈچ ئامېرىكا دوللىرىدىن باشلىنىد",
      "text2": "دۇنيا بويىچە پىشىپ يېتىلگەن ۋە يېڭىدىن گۈللەنگەن 36 بازارنىڭ پاي چېكى سودىسى",
      "text3": "ئادەتتىن تاشقىرى كۆچمە قوللاش",
      "text4": "پىشاڭ نىسبىتى 20:1 (كاپالەت پۇلى نىسبىتى% 5) گە يېتىدۇ",
      "title2": "CFDs نى زاپاسلاڭ ، دۇنيادىكى ئالدىنقى قاتاردىكى شىركەتلەرنى ئاسانلا مەبلەغ سېلىڭ",
  },
}