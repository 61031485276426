export default {
    "h1-title": "سودا قوراللىرى",
    "imgUrlLang": "",
    "public-btn-text": "دەرھال تەجرىبە",
    screen0: {
        "title": "سودا ئېھتىياجىڭىزنى قاندۇرۇش ئۈچۈن قورال ئامبىرى قۇرۇڭ",
    },
    screen1: {
        "title": "ئوخشاش رەسىمدىكى مەھسۇلات قاپلاش قورالى        ",
        "desc": "بۇ قورال مەبلەغ سالغۇچىلارنىڭ ھەر ئىككى دەۋردىكى ھەر قانداق K لىنىيىسىنىڭ نىسپىي كۈچىنى ئېنىق چۈشىنەلەيدۇ ، شۇنداقلا بۆسۈش خاراكتېرلىك ئۈنۈمنىمۇ پەرقلەندۈرەلەيدۇ.        ",
    },
    screen2: {
        "title": "MACD كەسپىي كۆرسەتكۈچ (قوش سىزىق)        ",
        "desc": "بۇ پاي چېكى ۋە قەرەللىك مال ئۈچۈن كۆپ ئىشلىتىلىدىغان MACD كۆرسەتكۈچ قورالى. MT4 بىلەن كەلگەن MACD بىلەن سېلىشتۇرغاندا ، ئۇ جۇڭگو ئابونتلىرىنىڭ ئادىتىگە تېخىمۇ ماس كېلىدۇ.        ",
    },
    screen3: {
        "title": "MTDriver پايدا ۋە زىيان ستاتىستىكا كۆرسەتكۈچى        ",
        "desc": "بۇ قورال مەبلەغ سالغۇچىلارنىڭ ئوخشىمىغان مەزگىلدىكى پايدا ۋە زىياننى ئېنىقلىشىغا ياردەم بېرەلەيدۇ        ",
    },
    screen4: {
        "title": "باھا ئۆزگەرتىش كۆچمە ئىتتىرىش ئۇقتۇرۇشى        ",
        "desc": "كۆپىنچە مەبلەغ سالغۇچىلارنىڭ بازارنى كۆرۈش ۋاقتى چەكلىك ، يانفوننىڭ باھا ئۆزگىرىشى توغرىسىدىكى ئۇقتۇرۇشى ئۇلارنىڭ بازار يۈزلىنىشىنى چۈشىنىشىگە ياردەم بېرىدۇ ، شۇنىڭ بىلەن بىر ۋاقىتتا بەزى بازار شاۋقۇنىدىن ساقلىنىدۇ.        ",
    },
    screen5: {
        "title": "تاقاش EA نى بىر چېكىڭ        ",
        "desc": "مەشغۇلات كۆرۈنمە يۈزى دىئاگرامما كۆرۈنمە يۈزىگە جايلاشقان بولۇپ ، چېكىدىن ئاشقان بازار شارائىتى يۈز بەرگەندە مەبلەغ سالغۇچىلارنىڭ زىياننى تېخىمۇ تېز ۋە قۇلايلىق توختىتىشىغا قۇلايلىق.        ",
    },
    screen6: {
        "title": "ۋاقىت تەرتىپى EA        ",
        "desc": "ئۇنى \ MQL4 \ مۇتەخەسسىسلەر قىسقۇچىغا قويۇڭ ، EA بەلگىلەنگەن ۋاقىتتا زاكاز قىلىدۇ ، ئىشلىتىش ئىنتايىن ئاددىي ، ئابونتلار ئۆزلىرى تەكشۈرۈپ بېقىڭ.        ",
    },
    screen7: {
        "title": "Equity Monitor EA        ",
        "desc": "ئۇنى \ MQL4 \ مۇتەخەسسىسلەر قىسقۇچىغا قويۇڭ ، ساپ قىممەت ئۆزگىرىشىنىڭ EA نى نازارەت قىلىڭ ، سىز تەلەپ قىلماقچى بولغان ئاۋاز ھۆججىتىنى \ ئاۋازنىڭ ئاستىغا قويۇڭ ، ئاندىن ماس مۇزىكا ھۆججىتىنىڭ نامىنى EA نىڭ مۇزىكا نامىغا تولدۇرۇڭ.        ",
    }
}