export default {
    "h1-title": "트레이딩 도구",
    "imgUrlLang": "-en",
    "public-btn-text": "지금 바로 체험하기",
    screen0: {
        "title": "거래 요구 사항을 충족하는 도구 라이브러리 생성",
    },
    screen1: {
        "title": "동일한 그림의 제품 오버레이 도구",
        "desc": "이 도구는 투자자에게 두 상품의 임의 주기 및 임의 K 라인의 상대적 강약을 명확하게 알 수 있게 하며, 돌파구의 유효성을 식별할 수 있게 합니다.",
    },
    screen2: {
        "title": "MACD 전문 지표(이중선)",
        "desc": "이 도구는 주식 및 선물에 일반적으로 사용되는 MACD 지표 도구입니다. MT4에 포함된 MACD에 비해 다양한 이점을 제공하여 거래자의 선호도 및 습관에 더욱 적합하게 합니다.",
    },
    screen3: {
        "title": "MTDriver 손익 통계 지표",
        "desc": "이 도구는 투자자가 다양한 기간 동안 손익을 정확히 파악하는 데 도움이 될 수 있습니다.",
    },
    screen4: {
        "title": "가격 변동 모바일 푸시알림",
        "desc": "대부분의 투자자는 시장을 볼 수 있는 시간이 한정되어 있기 때문에 가격 변동에 대한 모바일 푸시 알림 기능은 시장의 잡음을 피하면서 시장 동향을 파악하는 데 도움을 줄 수 있습니다.",
    },
    screen5: {
        "title": "원클릭 마감 EA",
        "desc": "운영 인터페이스는 차트 인터페이스에 위치하여 투자자가 극한의 시장 상황이 발생할 때 보다 빠르고 편리하게 손실을 막을 수 있습니다.",
    },
    screen6: {
        "title": "EA 정시 매매",
        "desc": "MQL4Experts 폴더에 넣고 지정된 시간에 매매하는 EA, 사용법은 매우 간단하니 직접 탐색해 보세요.",
    },
    screen7: {
        "title": "순자산 모니터링 EA",
        "desc": "MQL4Experts 폴더에 넣은 뒤 순자산 변화의 EA를 모니터링합니다. 그 후 Sounds에 프롬프트하려는 사운드 파일을 넣은 다음 EA의 음악 이름에 해당 음악 파일의 이름을 입력하세요.",
    }
}