<template>
	<div class="contact-us">
		<img src="../../assets/image/ContactUs/form-bg.png" class="form-bg d-md-none" />
		<div class="form-box">
			<page-title :enText="$t('CFooters.contact.pageEnTitle')" :text="$t('CFooters.contact.pageTitle')" />
			<div class="form-title">{{$t('CFooters.contact.title')}}</div>
			<p class="form-label mt110">{{$t('CFooters.contact.name')}}</p>
			<div class="row">
				<div class="col-md-6">
					<input type="text" class="form-control" v-model="infoData.name">
				</div>
				<div class="col-md-3" v-for="(item, index) in $t('CFooters.contact.sexList')" :key="index"
					@click="changeSix(index)">
					<span class="select-label"
						:class="{'select-label--active': sexIndex === index}">{{item.label}}</span>
				</div>
			</div>
			<p class="form-label">{{$t('CFooters.contact.email')}}</p>
			<div class="row">
				<div class="col-md-12">
					<input type="text" class="form-control" v-model="infoData.call">
				</div>
			</div>
			<div class="testing">
				<div class=" testing__title">
					{{num1}} + {{num2}} =
				</div>
				<div class=" testing__kind">
					<input type="text" class="input" v-model="kind">
				</div>
			</div>
			<div class="form-btn" @click="getContact">
				<span>{{$t('CFooters.contact.btn')}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import md5 from 'js-md5';
	export default {
		data() {
			return {
				sexIndex: 0,
				infoData: {
					call: '',
					name: ''
				},
				num1: 0,
				num2: 0,
				kind: null
			}
		},
		created() {
			this.num1 = this.selectFrom(1, 100)
			this.num2 = this.selectFrom(1, 100)
		},
		methods: {
			getContact() {
				let infoData = {
					...this.infoData
				}
				let sum = this.num1 + this.num2
				if(sum != this.kind){
					this.num1 = this.selectFrom(1, 100)
					this.num2 = this.selectFrom(1, 100)
					this.kind = null
					return this.$toast(this.$t('CFooters.contact.tips4'));
				} 
				if (!infoData.name) return this.$toast(this.$t('CFooters.contact.tips1'));
				if (!infoData.call) return this.$toast(this.$t('CFooters.contact.tips2'));
				let checksum = md5(infoData.call + "YISENCE")
				let parms = 'name=' + infoData.name + '&sex=' + this.$t('CFooters.contact.sexList')[this.sexIndex].label +
					'&mobile=' + infoData.call + '&checksum=' + checksum
				console.log(parms);
				let time = new Date().getMilliseconds()
				let checkSum1 =md5(infoData.name + infoData.call + time +  "YISENCE")
				this.$http('/ebc/api/setContact/run',{
					name:infoData.name,
					contact:infoData.call,
					date:time,
					checkSum:checkSum1
				}).then(res =>{
					this.$toast(this.$t('CFooters.contact.tips3'))
					this.num1 = this.selectFrom(1, 100)
					this.num2 = this.selectFrom(1, 100)
					this.kind = null
					this.infoData = {
						call: '',
						name: ''
					}
					
				})
				// axios.get('https://apinew.yisence.net/API/Yisenceebcmail.aspx?' + parms).then(res => {
				// 	this.$toast(this.$t('CFooters.contact.tips3'))
				// })
			},
			changeSix(index) {
				this.sexIndex = index
			},
			selectFrom(lowerValue, upperValue) {
				var choices = upperValue - lowerValue + 1;
				return Math.floor(Math.random() * choices + lowerValue);
			}
		}
	}
</script>

<style lang="scss" scoped>
  .contact-us {
    background: url(../../assets/image/ContactUs/form-bg.png) left top / auto 100% no-repeat;
    height: 1200px;
    position: relative;

    .d-md-none {
      font-family: NotoSansSC;
      font-size: 16px;
      line-height: 50px;
      color: #333333;
      margin: 0 43px;
      border-top: 1px solid #acacac;
    }

    .form-btn {
      box-shadow: 5px 5px 0 0 #7d1614;
      height: 73px;
      min-width: 173px;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
      z-index: 2;
      font-size: 16px;
      font-weight: bold;
      color: #646464;
      position: absolute;
      right: 54px;
      bottom: 150px;
      cursor: pointer;

      span {
        margin-right: 10px;
        display: inline-block;
        line-height: 2;
        border-bottom: 1px solid #8b8b8b;
      }
    }

    .form-box {
      width: 620px;
      height: 900px;
      position: absolute;
      top: 200px;
      right: 8%;
      padding: 100px 190px 160px 75px;
      background-color: #fff;
      border: 1px solid #cccc;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 190px;
        left: 0;
        border: 1px solid #7d1614;
        opacity: .5;
      }

      .form-control {
        height: 38px;
        padding: inherit;
      }

      .testing {
        margin-top: 40px;
        display: flex;
        align-items: center;

        &__title {
          font-size: 24px;
        }

        &__kind {
          padding-left: 10px;

          .input {
            border: 1px solid #ced4da;
            font-size: 1rem;
            font-weight: 400;
            line-height: 40px;
            color: #212529;
            background-color: #fff;
            border-radius: .25rem;
            width: 50px;
            height: 40px;
            text-align: center;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
          }
        }
      }
    }

    .col-md-3 {
      padding: 0 5px;
    }

    .select-label {
      cursor: pointer;
      display: flex;
      font-size: 15px;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 18px;
      height: 38px;
      border: 1px solid #ddd;
      color: #999;

      &--active {
        background-color: #0C63E4;
        color: #fff;
        border-color: #0C63E4;
      }
    }

    .form-label {
      font-size: 15px;
      color: #333;
      margin-top: 40px;
      font-weight: bold;
    }
    .form-title{
      margin-top: 20px;
      font-size: 36px;
      font-weight: 600;
      color: #333;
      line-height: 1.5;
    }
    .mt110 {
      margin-top: 80px;
    }

    .form-inquiry {
      height: 50px;
      width: 125px;
      background-color: #f5f5f5;
      padding: 15px 0 0 10px;

      &__en {
        font-size: 12px;
        color: #666;
      }

      &__text {
        margin-top: 8px;
        color: #333;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }



  @media (max-width: 767px) {
    .contact-us {
      background: none;
      height: auto;
      padding-bottom: 64px;

      .form-bg {
        width: 100%;
        display: block;
        margin: 0;
        border: none;
      }

      .form-box {
        height: auto;
        position: relative;
        background-color: transparent;
        left: 0;
        top: 0;
        width: 100%;
        border: none;
        padding: 0 43px;
        margin-top: -30px;

        &::after {
          display: none;
        }

        .form-btn {
          position: relative;
          left: 0;
          top: 0;
          margin-top: 60px;
        }

        .row {
          margin: 0;

          &>* {
            padding: 0;
          }
        }

        .mt110 {
          margin-top: 43px;
        }

        .col-md-3 {
          margin-top: 12px;
          margin-right: 20px;
          width: 62px;
          height: 28px;
        }

        .select-label {
          height: 100%;
        }

        .form-label {
          font-size: 12px;
          margin-top: 32px;
        }
      }
    }
  }
</style>
