export default {
	"faq-class-name": "en-faq-new",
	"faq-title":"FAQ",
	"faq-desc": "Hỏi về các câu hỏi thường gặp hoặc liên hệ trực tiếp với bộ phận dịch vụ khách hàng. Đội ngũ hỗ trợ khách hàng của EBC luôn chờ đợi câu hỏi của bạn.",
	"faq-search": "Nhập từ khóa tìm kiếm",
	"faq-tabs":[
		"Đăng ký và mở tài khoản",
		"Gửi tiền và rút tiền",
		"Giao dịch liên quan",
		"Thiết bị đầu cuối giao dịch",
		"Kiến thức",
	],
	"faq-data":{
		0: [
			{
				"Q": " Làm thế nào để bắt đầu giao dịch trên EBC?",
				"A": " Hoàn thành các bước sau để bắt đầu giao dịch trên EBC: 1. Đăng ký tài khoản EBC 2. Xác minh thông tin cá nhân 3. Mở tài khoản giao dịch và gửi tiền 4. Đăng nhập vào thiết bị đầu cuối giao dịch MT4 để giao dịch"
			},
			{
				"Q": " Có thể đăng ký nhiều Tài khoản EBC bằng cùng một địa chỉ email không?",
				"A": " Thật không may, một địa chỉ email chỉ có thể được đăng ký cho một tài khoản EBC. Tuy nhiên, một tài khoản EBC có thể tạo nhiều tài khoản giao dịch."
			},
			{
				"Q": " Mục đích của việc hoàn tất xác minh KYC là gì?",
				"A": " Là một công ty tuân thủ và được quản lý, chúng tôi phải tiến hành xác minh KYC theo các quy định do cơ quan quản lý có thẩm quyền đặt ra. Điều này đòi hỏi phải thu thập thông tin xác minh KYC của bạn, chẳng hạn như ID quốc gia hợp lệ và bằng chứng địa chỉ được cấp trong vòng sáu tháng qua."
			},
			{
				"Q": " Cần những tài liệu gì để xác minh tài khoản EBC?",
				"A": "Bạn được yêu cầu cung cấp cho chúng tôi ID, số điện thoại di động và địa chỉ email hợp lệ để hoàn tất xác minh KYC."
			},
			{
				"Q": " Làm cách nào để gửi lại tài liệu xác minh của tôi?",
				"A": " Đây là quy trình gửi lại tài liệu KYC của bạn: 1. Đăng nhập vào cổng khách hàng của bạn và truy cập Tài khoản của tôi. 2. Trong phần Thông tin cá nhân, hãy điền lại thông tin của bạn theo yêu cầu. 3. Nhấp vào nút Xóa bên cạnh tài liệu đã gửi trước đó, sau đó tải lên lại. 4. Sau khi hoàn tất, hãy ngồi lại và thư giãn vì tài liệu KYC của bạn sẽ sớm được kiểm tra."
			},
			{
				"Q": " Làm cách nào tôi có thể cập nhật thông tin cá nhân trong tài khoản EBC của mình?",
				"A": " Xin lưu ý rằng việc sửa đổi tên đã đăng ký của bạn là không được phép. Tuy nhiên, bạn vẫn có thể cập nhật số điện thoại di động, địa chỉ email và thông tin ngân hàng đã đăng ký của mình."
			},
			{
				"Q": " Tiền và dữ liệu cá nhân của tôi có an toàn không?",
				"A": "Sự an toàn của tiền của bạn là vô cùng quan trọng đối với chúng tôi. Chúng tôi được giám sát chặt chẽ bởi nhiều cơ quan quản lý hàng đầu trên toàn thế giới và tuân thủ nghiêm ngặt các luật và quy định có liên quan. Tiền của khách hàng được lưu trữ riêng trong tài khoản cá nhân tại các ngân hàng có uy tín ở nước ngoài, đảm bảo bảo vệ kép cho cả tiền và thu nhập đầu tư của bạn. Ngoài ra, chúng tôi tận tâm thực hiện tất cả các biện pháp bảo mật cần thiết để bảo vệ thông tin cá nhân của bạn."
			},
			{
				"Q": " Những loại tài khoản nào có sẵn?",
				"A": " Chúng tôi cung cấp 2 loại tài khoản: tài khoản tiêu chuẩn và tài khoản chuyên nghiệp. <a href=\"/TradingAccount\">Bấm vào đây để xem bảng so sánh tài khoản</a> ."
			},
			{
				"Q": " Làm cách nào để tìm số tài khoản giao dịch của tôi?",
				"A": " Để xác định số tài khoản giao dịch, hãy làm theo các bước dưới đây: 1. Đăng nhập vào tài khoản EBC của bạn 2. Nhấp vào \"Trang chủ\" - \"Tài khoản thật\" 3. Trong Tài khoản thực, bạn sẽ thấy Số tài khoản giao dịch của mình"
			},
			{
				"Q": " Có sự khác biệt nào giữa một sống tài khoản và tài khoản giao dịch demo?",
				"A": "Quy trình giao dịch, sản phẩm giao dịch và xu hướng K-line của cả hai đều nhất quán. Hoạt động của tài khoản trực tiếp đòi hỏi rủi ro tài chính thực tế. Tài khoản demo giúp người dùng tìm hiểu và hiểu được động lực thị trường mà không cần đến tiền thật."
			},
			{
				"Q": " Văn phòng EBC tọa lạc ở đâu?",
				"A": " Chúng tôi có văn phòng tại các trung tâm tài chính lớn như London, Tokyo, Sydney, Singapore và Hồng Kông. Vui lòng tham khảo trang web của chúng tôi để biết thêm thông tin nếu bạn cần."
			},
			{
				"Q": " Có giới hạn độ tuổi mở tài khoản không?",
				"A": " Để mở tài khoản, bạn phải ít nhất 18 tuổi và không quá 65 tuổi, ngoại trừ khách hàng cư trú tại Nhật Bản, nơi giới hạn độ tuổi tối đa là 70 tuổi"
			},
			{
				"Q": " EBC không chấp nhận khách hàng từ những quốc gia nào?",
				"A": "Vui lòng lưu ý rằng các sản phẩm và dịch vụ của chúng tôi không được cung cấp cho khách hàng cư trú ở một số khu vực nhất định, bao gồm Hoa Kỳ, Canada, Malaysia và các khu vực khác. Danh sách các quốc gia bị hạn chế có thể được cập nhật định kỳ. Để biết thêm chi tiết, vui lòng liên hệ với nhóm hỗ trợ khách hàng của chúng tôi thông qua LiveChat."
			},
			{
				"Q": " Làm cách nào để xóa tài khoản của tôi?",
				"A": " Chúng tôi rất tiếc khi biết rằng bạn muốn xóa tài khoản của mình. Vui lòng gửi yêu cầu tới cs@ebc.com bằng địa chỉ email đã đăng ký của bạn để chúng tôi có thể hỗ trợ bạn tốt hơn. Sau khi nhận được yêu cầu của bạn, bộ phận hỗ trợ khách hàng sẽ xử lý."
			},
			{
				"Q": " Làm cách nào tôi có thể cập nhật đòn bẩy tài khoản giao dịch của mình?",
				"A": " Chắc chắn, để cập nhật đòn bẩy của bạn, vui lòng làm theo các bước dưới đây: 1. Đăng nhập vào cổng thông tin khách hàng của bạn và nhấp vào Trang chủ 2. Trong Tài khoản của tôi - nhấn vào \"Cài đặt\" ở bên phải tài khoản - \"Sửa đổi đòn bẩy\" 3. Chọn đòn bẩy thích hợp và nhấp vào \"Có\" để cập nhật đòn bẩy của bạn"
			},
			{
				"Q": "Tôi có thể làm gì bằng cách liên hệ với dịch vụ khách hàng? Công việc hàng giờ là gì?",
				"A": " Nếu bạn có bất kỳ thắc mắc nào về việc đăng ký, tải xuống nền tảng hoặc các chủ đề liên quan khác, nhóm hỗ trợ khách hàng của chúng tôi sẵn sàng trợ giúp bạn 24/24. Bạn có thể gửi email cho chúng tôi theo địa chỉ cs@ebc.com hoặc sử dụng dịch vụ khách hàng trực tuyến của chúng tôi để liên hệ với chúng tôi."
			},
			{
				"Q": " Thủ tục khiếu nại nếu tôi cần phải làm như thế nào?",
				"A": " Chúng tôi xin lỗi vì bất kỳ sự bất tiện nào mà chúng tôi có thể đã gặp phải. Đảm bảo sự hài lòng của bạn là ưu tiên hàng đầu của chúng tôi và chúng tôi cam kết giải quyết mọi vấn đề bạn có thể gặp phải. Nếu bạn có bất kỳ khiếu nại nào, vui lòng gửi email cho chúng tôi theo địa chỉ cs@ebc.com."
			}
		],
		1: [
			{
				"Q": " Tôi có cần biết điều gì trước khi gửi tiền không?",
				"A": "EBC cung cấp phương thức gửi tiền 24/7. Điều quan trọng là phải hoàn tất thanh toán trong một khoảng thời gian nhất định khi gửi tiền, nếu không lệnh gửi tiền sẽ tự động bị hủy. Các phương thức gửi tiền khác nhau có thể có giới hạn gửi tiền khác nhau, vì vậy bạn nên kiểm tra các chi tiết liên quan trước khi gửi tiền."
			},
			{
				"Q": " Tôi cần biết điều gì trước khi gửi yêu cầu rút tiền?",
				"A": " Chúng tôi cung cấp một số phương thức rút tiền, bao gồm chuyển khoản ngân hàng địa phương, tiền điện tử và chuyển khoản ngân hàng quốc tế. Xin lưu ý rằng số tiền rút tối thiểu là 100 USD đối với chuyển khoản ngân hàng địa phương và chuyển khoản ngân hàng quốc tế và 50 USD đối với rút tiền điện tử."
			},
			{
				"Q": " Làm thế nào để gửi tiền?",
				"A": "Để gửi tiền, vui lòng làm theo các bước dưới đây: 1. Đăng nhập vào cổng khách hàng của bạn và nhấn vào Quỹ 2. Tìm Tiền gửi nằm ở phía bên phải của menu 3. Chọn phương thức gửi tiền ưa thích của bạn và chọn tài khoản giao dịch mà bạn muốn nhận tiền 4. Nhập số tiền và sau đó nhấp vào Xác nhận thanh toán 5. Sau đó, bạn sẽ được chuyển hướng đến trang của nhà cung cấp và vui lòng làm theo hướng dẫn để hoàn tất khoản tiền gửi của mình."
			},
			{
				"Q": " Tôi có thể gửi/rút tiền bằng thẻ ngân hàng của người khác không?",
				"A": "EBC không chấp nhận thanh toán của bên thứ ba. Tất cả các khoản tiền gửi phải đến từ một tài khoản đứng tên bạn. (Tất cả các khoản tiền gửi phải đến từ tài khoản ngân hàng của chính bạn.) Nếu chúng tôi nghi ngờ rằng số tiền đến từ bên thứ ba, chúng tôi có quyền trả lại tiền cho người gửi và tạm thời đóng băng số dư tài khoản của bạn cho đến khi xác minh được danh tính và nguồn tiền. hoàn thành. Trong trường hợp không xác minh, chúng tôi cũng có quyền đóng băng số dư tài khoản của bạn và bạn sẽ không thể rút số dư tài khoản của mình vào lúc này. EBC không xử lý việc rút tiền cho bên thứ ba. Trong trường hợp đặc biệt, vui lòng liên hệ với chúng tôi."
			},
			{
				"Q": " Giới hạn tiền gửi là gì?",
				"A": " Các phương thức thanh toán khác nhau có giới hạn gửi tiền khác nhau, vui lòng tham khảo trang gửi tiền để biết thêm thông tin"
			},
			{
				"Q": " EBC có tính phí gửi hoặc rút tiền không?",
				"A": " EBC không tính bất kỳ khoản phí xử lý nào đối với việc gửi và rút tiền. Xin lưu ý rằng một số nhà cung cấp dịch vụ tiền điện tử có thể tính phí xử lý nhất định."
			},
			{
				"Q": "Tôi có thể gửi và rút tiền ngoài giờ làm việc được không?",
				"A": " Bạn có thể gửi yêu cầu gửi và rút tiền như bình thường ngay cả vào cuối tuần và ngày lễ. Tuy nhiên, xin lưu ý rằng do những ngày không làm việc vào cuối tuần nên việc xử lý yêu cầu của bạn có thể bị trì hoãn và sẽ được xử lý muộn nhất vào ngày làm việc tiếp theo (Thứ Hai)."
			},
			{
				"Q": " Mất bao lâu để xử lý tiền gửi và rút tiền?",
				"A": " Trong trường hợp bình thường, thời gian xử lý tiền gửi trong ngày làm việc là khoảng 1 đến 15 phút. Đơn đăng ký rút tiền được gửi trước 16:00 UTC+8 vào các ngày làm việc có thể được ghi có vào tài khoản ngay trong ngày."
			},
			{
				"Q": " Tôi có thể gửi và rút tiền bằng nhiều thẻ ngân hàng không?",
				"A": " Có, bạn có thể sử dụng bất kỳ thẻ ngân hàng nào đứng tên bạn."
			},
			{
				"Q": " Tôi có thể rút tiền từ tài khoản giao dịch vẫn còn lệnh mở không?",
				"A": "Có, tuy nhiên xin lưu ý rằng phải có đủ tiền trong tài khoản để tránh việc thanh lý các lệnh đang mở do hoạt động rút tiền. Nếu bộ phận kiểm soát rủi ro xác định hoạt động rút tiền có thể dẫn đến việc thanh lý tài khoản thì đơn xin rút tiền sẽ bị từ chối. Hoạt động rút tiền chỉ có thể được thực hiện sau khi hoạt động thanh lý hoàn tất."
			},
			{
				"Q": " Tôi có thể sử dụng phương thức thanh toán khác với phương thức gửi tiền khi rút tiền không?",
				"A": " Bạn có thể rút khoản tiền gửi ban đầu của mình bằng phương thức tương tự như khoản tiền gửi của bạn. Tuy nhiên, bạn có thể linh hoạt rút lợi nhuận bằng phương thức rút tiền ưa thích của mình."
			},
			{
				"Q": " Tại sao việc rút/gửi tiền không thành công?",
				"A": "Việc gửi/rút tiền không thành công có thể do các nguyên nhân sau: 1. Bạn đang gửi tiền bằng thẻ ngân hàng không đứng tên bạn 2. Thẻ ngân hàng bạn sử dụng không hoạt động hoặc không được nhận dạng 3. Khi thực hiện chuyển khoản, ngân hàng thông tin hoặc thông tin tài khoản rút tiền điền sai. Nếu bạn gặp phải tình huống trên, vui lòng liên hệ với bộ phận hỗ trợ khách hàng của chúng tôi qua LiveChat hoặc gửi email đến cs@ebc.com để được hỗ trợ."
			},
			{
				"Q": " Tôi có thể chuyển tiền giữa các tài khoản giao dịch của mình không?",
				"A": " Đúng. Bạn có thể gửi yêu cầu chuyển tiền giữa các tài khoản giao dịch của mình."
			},
			{
				"Q": " Tại sao sau khi gửi một yêu cầu rút tiền, tôi không thể gửi một yêu cầu rút tiền khác?",
				"A": " Trước khi gửi lần rút tiền thứ hai, bạn phải đợi lần đầu tiên được phê duyệt để xem xét."
			},
			{
				"Q": " Tại sao sau khi hủy lệnh rút tiền, tiền không được trả lại ngay vào tài khoản giao dịch của tôi?",
				"A": "Sau khi hủy việc rút tiền, tiền sẽ tự động được trả lại vào ví chính của bạn và sau đó tiền có thể được chuyển từ ví chính sang tài khoản giao dịch."
			}
		],
		2: [
			{
				"Q": " Tôi có thể giao dịch những sản phẩm nào trên EBC?",
				"A": " EBC cung cấp nhiều loại sản phẩm và bạn chỉ cần một tài khoản giao dịch để tạo danh mục đầu tư đa dạng. <a href=\"/tradingproduct\">Nhấn vào đây để xem danh sách sản phẩm đầy đủ</a> ."
			},
			{
				"Q": " Khối lượng giao dịch tối đa và tối thiểu cho từng loại tài khoản là bao nhiêu?",
				"A": " Khối lượng giao dịch tối đa cho tài khoản STD và tài khoản PRO là 40 lô và khối lượng giao dịch tối thiểu thay đổi tùy theo loại giao dịch. Nếu bạn muốn biết thêm chi tiết xin vui lòng <a href=\"/TradingAccount\">Bấm vào đây để xem các quy tắc tài khoản</a> ."
			},
			{
				"Q": " Khớp lệnh thị trường là gì và Lệnh chờ là gì?",
				"A": "Khớp lệnh thị trường có nghĩa là lệnh được thực hiện ngay lập tức và nhà đầu tư mua hoặc bán theo mức giá tốt nhất hiện có trên thị trường hiện tại; giao dịch lệnh chờ xử lý là việc thực hiện theo lịch trình, trong đó nhà đầu tư xác định giá mua và bán dự kiến. Khi giá thị trường đạt hoặc vượt mức giá dự kiến, hệ thống giao dịch sẽ tự động thực hiện giao dịch."
			},
			{
				"Q": " Giới hạn mua, dừng mua, giới hạn bán, dừng bán là gì?",
				"A": " Giới hạn mua: Mua khi giá giảm xuống mức thấp hơn định trước, phù hợp với chiến lược mua giá thấp. Ví dụ: nếu giá vàng dự kiến sẽ giảm từ năm 2000 đến năm 1980 và sau đó tăng trở lại, bạn có thể đặt Giới hạn Mua là 1980 và chờ mua ở mức giá thấp hơn. Giới hạn bán:Bán khi giá tăng lên mức cao hơn định trước, phù hợp với chiến lược bán giá cao. Ví dụ: nếu giá vàng dự kiến sẽ tăng từ năm 2000 đến năm 2020 và sau đó giảm trở lại, bạn có thể đặt Giới hạn bán cho năm 2020 và chờ bán ở mức giá cao. Mua Dừng : Mua khi giá tăng lên mức cao hơn định trước, phù hợp với chiến lược đuổi theo. Ví dụ: nếu bạn tin rằng xu hướng tăng chỉ có thể được xác nhận khi giá vàng vượt quá năm 2020, bạn có thể đặt Điểm dừng mua cho năm 2020 và chờ đợi sự đột phá đi lên trước khi mua. Dừng bán: Bán khi giá giảm xuống mức thấp hơn định trước, phù hợp với chiến lược đuổi theo. Ví dụ: nếu bạn tin rằng xu hướng giảm chỉ có thể được xác nhận nếu giá vàng giảm xuống dưới mức 1980, bạn có thể đặt Điểm dừng bán của năm 1980 và đợi giá giảm xuống dưới mức 1980 trước khi bán."
			},
			{
				"Q": " Mức dừng là gì?",
				"A": " Mức dừng là 30%"
			},
			{
				"Q": "Làm cách nào để đặt 'chốt lời' và 'dừng lỗ' cho lệnh của tôi?",
				"A": " MT4: PC , các bước đặt mức dừng lỗ và chốt lời như sau: 1. Chọn lệnh giao dịch bạn muốn quản lý. 2. Nhấp chuột phải vào lệnh và chọn \"Sửa hoặc Xóa lệnh\". 3. Đặt \"Giá dừng lỗ\" và \"Giá lãi\" trong cửa sổ xuất hiện. MT4: Di động , các bước để đặt Dừng lỗ và Chốt lãi như sau: 1. Tìm thứ tự vị thế bạn muốn sửa đổi trên trang \"Giao dịch\" 2 .Nhấp hoặc nhấn và giữ lệnh và chọn \"Sửa đổi\" trong menu bật lên 3. Đặt \"Giá cắt lỗ\" và \"Giá lãi\" trong cửa sổ xuất hiện"
			},
			{
				"Q": " Phòng ngừa rủi ro là gì?",
				"A": " Phòng ngừa rủi ro là một lệnh mở có cùng loại, cùng kích thước lô nhưng theo hướng ngược lại."
			},
			{
				"Q": " EBC có cung cấp tín hiệu giao dịch không?",
				"A": "Mặc dù chúng tôi không cung cấp tín hiệu giao dịch trực tiếp nhưng chúng tôi cung cấp quyền truy cập vào cộng đồng giao dịch của mình. Trong cộng đồng này, bạn có thể chọn từ nhiều tín hiệu giao dịch khác nhau được cung cấp bởi những người dùng khác trong cộng đồng."
			},
			{
				"Q": " EBC có hỗ trợ EA (Expert Advisor) không?",
				"A": " Đúng. Bạn có thể sử dụng bất kỳ EA nào bạn thích."
			}
		],
		3: [
			{
				"Q": " Tôi có thể sử dụng thiết bị đầu cuối giao dịch nào?",
				"A": " Hiện tại EBC chỉ hỗ trợ sử dụng thiết bị đầu cuối giao dịch MT4, <a href=\"/MT4\">Nhấn vào đây để tải về ngay bây giờ</a> ."
			},
			{
				"Q": " Tôi nên sử dụng máy chủ nào cho nền tảng MT4?",
				"A": " Đối với tài khoản thực của EBC, chúng tôi sử dụng máy chủ EBCFinancialGroupKY-Live, và đối với tài khoản demo, chúng tôi sử dụng máy chủ EBCFinancialGroupKY-Demo."
			},
			{
				"Q": " Tại sao tôi không thể đăng nhập vào thiết bị đầu cuối giao dịch?",
				"A": " Đăng nhập thất bại vào nền tảng giao dịch có thể được gây ra bởi người dùng nhập sai mật khẩu của họ hoặc nó có thể liên quan đến mộtlỗi kết nối mạng. Khi gặp sự cố đăng nhập, bạn nên nhập lại mật khẩu hoặc kiểm tra kết nối mạng trước."
			},
			{
				"Q": " Không đăng nhập được vào MT4, tại sao lại xuất hiện lỗi \"tài khoản không hợp lệ\"?",
				"A": " Khi thông báo \"Tài khoản không hợp lệ\" xuất hiện, có thể do bạn nhập sai thông tin máy chủ hoặc mật khẩu không chính xác. Vấn đề này có thể được giải quyết bằng cách chọn đúng máy chủ và nhập đúng mật khẩu."
			},
			{
				"Q": " Tại sao nó hiển thị lời nhắc \"Không có kết nối\"?",
				"A": " Nếu bạn gặp phải lỗi 'Không có kết nối' Có thể là do máy chủ bị ngắt kết nối hoặc có vấn đề với kết nối mạng cục bộ. Trước tiên hãy xác nhận xem mạng cục bộ có thể duyệt web bình thường hay không. Nếu mạng bình thường, hãy thử chuyển sang máy chủ trung tâm dữ liệu (DC) khác và kết nối lại."
			},
			{
				"Q": " Tại sao nút \"Đơn hàng mới\" có màu xám?",
				"A": " Nếu bạn không thể tạo đơn hàng mớicó thể do các nguyên nhân sau: 1. Tài khoản chưa kết nối thành công hoặc đã bị ngắt kết nối. 2. Sử dụng mật khẩu chỉ đọc để đăng nhập. Loại mật khẩu này chỉ cho phép bạn xem tài khoản và không thể thực hiện các thao tác giao dịch. 3. Tài khoản STD chỉ có thể giao dịch các ký hiệu có hậu tố \".s\", còn các loại trong vùng màu xám không thể giao dịch."
			},
			{
				"Q": " Làm cách nào để thêm hoặc xóa biểu tượng?",
				"A": " Để Thêm hoặc Ẩn một biểu tượng nhất định trong MT4 thông qua Thiết bị đầu cuối dành cho máy tính để bàn 1. Đăng nhập vào thiết bị đầu cuối giao dịch và nhấp chuột phải vào bất cứ đâu. 2. Chọn \"Loại giao dịch\". 3. Chọn nhóm Biểu tượng giao dịch. 4. Nhấp đúp để thêm vào \"Báo giá thị trường\". 5. Để ẩn chọn \"Ẩn\". Thiết bị đầu cuối di động1. Đăng nhập và nhấp vào dấu \"+\" ở góc trên bên phải. 2. Chọn nhóm biểu tượng Giao dịch. 3. Nhấp vào \"+\" để thêm. Để xóa các loại trên thiết bị đầu cuối di động: 1. Nhấp vào nút hình cây bút ở góc trên bên trái. 2. Chọn hộp để xóa danh mục và nhấp vào Xóa."
			},
			{
				"Q": " Làm thế nào để thiết lập dòng giá thầu?",
				"A": " Máy tính để bàn 1. Nhấp chuột phải vào bất kỳ vị trí nào trên biểu đồ. 2. Chọn \"Thuộc tính\" để vào cài đặt hiển thị. 3. Chọn tùy chọn \"Mua dòng giá\". iOS: 1. Mở biểu đồ. 2. Nhấp vào bất kỳ đâu và chọn \"Cài đặt\". 3. Chọn tùy chọn \"Mua dòng giá\". Android: 1. Vào menu cài đặt. 2. Chọn \"Biểu đồ\". 3. Chọn tùy chọn Dòng giá thầu để bật nó."
			},
			{
				"Q": " Làm cách nào tôi có thể cập nhật múi giờ trong MT4?",
				"A": " Rất tiếc là không có tùy chọn cập nhật múi giờ trong MT4 Terminal"
			},
			{
				"Q": " Tại sao lệnh chờ của tôi không được thực thi?",
				"A": "Những lý do sau có thể ngăn lệnh chờ được thực thi: 1. Số tiền ký quỹ khả dụng trong tài khoản không đủ để mở một vị thế mới. 2. Giá của lệnh chờ không chính xác và không đáp ứng các tiêu chuẩn thực hiện của lệnh chờ. 3. Khi các sự kiện tài chính lớn được công bố, thị trường có thể biến động và giá thực tế của lệnh có thể khác với giá lệnh chờ. Xin lưu ý rằng việc không thực hiện được các lệnh chờ xử lý không chỉ giới hạn ở những lý do trên và phải được xác định dựa trên các trường hợp cụ thể tại thời điểm đó."
			},
			{
				"Q": " Cách tốt nhất để xác minh giờ giao dịch cho tất cả các biểu tượng là gì?",
				"A": " Đăng nhập vào thiết bị đầu cuối giao dịch MT4, nhấp chuột phải vào biểu tượng giao dịch bạn muốn xem và nhấp vào \"Thông số kỹ thuật\" để xem nó"
			},
			{
				"Q": " Tại sao nó báo lỗi \"SL/TP không hợp lệ\"?",
				"A": "Lỗi \"SL/TP không hợp lệ\" có thể do các nguyên nhân sau: 1. Giá hiện tại đã vượt quá điểm chốt lời và điểm dừng lỗ đã đặt và giao dịch không thể hoàn thành. 2. Giá đặt lệnh của tài khoản STD phải ≥ 50 điểm so với giá mới nhất."
			},
			{
				"Q": " Tại sao lời nhắc \"Thị trường đóng cửa\" lại xuất hiện?",
				"A": " Khi thị trường đóng cửa, dòng chữ \"Market Closed\" sẽ được hiển thị."
			},
			{
				"Q": " Tôi có thể đóng một phần vị thế của mình không (giảm số lượng vị thế mở)?",
				"A": " Vị thế của bạn có thể bị đóng một phần. Bạn có thể chọn đóng một phần vị thế nếu vị thế đó hiện vượt quá yêu cầu lô giao dịch tối thiểu đối với hàng hóa."
			},
			{
				"Q": " Tôi vẫn có thể giao dịch sau khi thị trường đóng cửa chứ?",
				"A": " Bạn không thể giao dịch nữa khi thị trường đóng cửa. Bạn chỉ có thể giao dịch (mở, đóng, thực hiện và sửa đổi lệnh) khi thị trường mở cửa."
			},
			{
				"Q": " Nếu MT4 của tôi ngoại tuyến, tôi có cần đóng tất cả các vị thế tôi đã mở không?",
				"A": "Nếu bạn đóng hệ thống giao dịch MT4, chốt lãi, dừng lỗ và các lệnh chờ vẫn sẽ được giữ nguyên. Nhưng khi bạn đóng hệ thống giao dịch MT4, điểm dừng lỗ và hệ thống giao dịch EA của bạn sẽ không còn hiệu lực."
			},
			{
				"Q": " Làm cách nào để xem lịch sử giao dịch của tôi?",
				"A": " 1. Đăng nhập vào thiết bị đầu cuối giao dịch MT4 2. Nhấp vào điều hướng \"Thiết bị đầu cuối\" - \"Lịch sử tài khoản\" 3. Xem lịch sử giao dịch đã đóng"
			},
			{
				"Q": " Làm cách nào để xuất lịch sử giao dịch?",
				"A": " 1. Đăng nhập vào thiết bị đầu cuối giao dịch MT4 và nhấp vào \"Lịch sử tài khoản\" 2. Nhấp chuột phải và chọn \"Lưu dưới dạng báo cáo tài khoản chi tiết\""
			},
			{
				"Q": " Làm cách nào để xem đường cong vốn trong MT4?",
				"A": " 1. Mở lịch sử tài khoản trong MT4 2. Nhấp chuột phải và chọn \"Lưu dưới dạng sao kê tài khoản chi tiết\" 3. Nhấp lại vào biểu đồ đã lưu để xem đường cong vốn"
			},
			{
				"Q": " PAMM là gì và cách sử dụng nó?",
				"A": "PAMM là viết tắt của Mô hình quản lý phân bổ phần trăm, là một hình thức quỹ giao dịch tập thể để quản lý. Nhà đầu tư có thể chọn một nhà giao dịch hoặc người quản lý quỹ đủ năng lực để sử dụng vốn của mình và vốn gộp để thực hiện các giao dịch nhằm thu được lợi nhuận và phân phối lợi nhuận theo tỷ lệ đã thỏa thuận."
			}
		],
		4: [
			{
				"Q": " Giao dịch phái sinh là gì?",
				"A": " Thị trường giao dịch phái sinh hiện là thị trường giao dịch tài chính lớn nhất thế giới, với khối lượng giao dịch trung bình hàng ngày khoảng 4 nghìn tỷ USD. Tỷ giá hối đoái của nhiều loại tiền tệ trên thế giới đang thả nổi và xuất hiện dưới dạng các cặp tiền tệ khi giao dịch, chẳng hạn như Euro/USD (EUR/USD) và USD/JPY (USD/JPY). Nó thể hiện phương thức giao dịch mua một loại tiền và bán loại tiền kia theo một cặp tiền tệ."
			},
			{
				"Q": " Sự lây lan là gì?",
				"A": " Spread là sự chênh lệch giữa giá mua và giá bán của một sản phẩm giao dịch nhất định, đồng thời nó cũng là chi phí giao dịch cần phải trả cho mỗi giao dịch."
			},
			{
				"Q": "Đòn bẩy là gì?",
				"A": " Đòn bẩy có nghĩa là các nhà đầu tư có thể sử dụng một tỷ lệ nhỏ quỹ để đầu tư vào các mục tiêu ngoại hối có giá trị lớn hơn mà không cần phải giao dịch đầy đủ. Mức đòn bẩy xác định số tiền ký quỹ được sử dụng.."
			},
			{
				"Q": " Ký quỹ là gì?",
				"A": "Ký quỹ, còn được gọi là khoản thanh toán tạm ứng, cho phép các nhà giao dịch sử dụng một phần tiền của họ để thiết lập các vị thế dựa trên nguyên tắc đầu tư có đòn bẩy. Nó đóng vai trò là tài sản thế chấp để duy trì vị thế giao dịch. Trên thị trường, đòn bẩy thường dao động từ 20 đến 500 lần, cho phép nhà đầu tư chủ động giao dịch với đòn bẩy cao. Tuy nhiên, điều quan trọng cần lưu ý là mặc dù hệ thống ký quỹ có thể tăng lợi nhuận nhưng nó cũng làm tăng rủi ro giao dịch. Ví dụ: hãy xem xét ông Zhao muốn thực hiện một giao dịch trị giá 100.000 đô la Mỹ ngay hôm nay. Với giao dịch ký quỹ và giả định tỷ lệ đòn bẩy là 500:1, ông Zhao chỉ cần có 200 USD để thực hiện giao dịch này (100.000/500 = 200 USD). Nói cách khác, với số vốn được tăng lên gấp 500 lần, khoản đầu tư 10.000 USD có thể tạo điều kiện thuận lợi cho giao dịch trị giá 5 triệu USD."
			},
			{
				"Q": " Làm thế nào để tính số tiền ký quỹ cần thiết để mở một lệnh?",
				"A": "Ký quỹ = quy mô hợp đồng * giá hiện tại / đòn bẩy * số lượng lô. Công thức này xác định mức ký quỹ cần thiết để bắt đầu một lệnh. Nó xem xét các yếu tố như quy mô hợp đồng (quy mô vị thế), giá hiện tại của tài sản, đòn bẩy do nhà môi giới cung cấp và số lượng lô được giao dịch."
			},
			{
				"Q": " Mối quan hệ giữa số dư, vốn chủ sở hữu và số tiền ký quỹ sẵn có trong một giao dịch là gì?",
				"A": " Vốn chủ sở hữu = Số dư – Tỷ lệ ký quỹ chiếm dụng ± lãi và lỗ thả nổi. Đây là ý nghĩa của mỗi thuật ngữ: Sự cân bằng : Đây là tổng số tiền có trong tài khoản giao dịch. Số tiền ký quỹ chiếm dụng: Số tiền ký quỹ hiện đang bị ràng buộc ở các vị thế mở. Lãi lỗ thả nổi Lãi hoặc lỗ chưa thực hiện trên các vị thế mở, dao động theo những thay đổi của thị trường. Vốn chủ sở hữu về cơ bản là giá trị ròng của tài khoản giao dịch sau khi tính đến số tiền ký quỹ gắn liền với các vị thế mở và bất kỳ khoản lãi hoặc lỗ thả nổi nào. Nó phản ánh giá trị thời gian thực của tài khoản và là yếu tố chính quyết định mức ký quỹ khả dụng cho các hoạt động giao dịch tiếp theo."
			},
			{
				"Q": " Tại sao số dư âm xảy ra?",
				"A": " Số dư âm có thể xảy ra trong điều kiện thị trường khắc nghiệt khi thị trường không thể đưa ra mức giá giao dịch hợp lý. Tình huống này có thể dẫn đến tổn thất giao dịch cuối cùng vượt quá số dư tài khoản và số dư ký quỹ, dẫn đến số dư trong tài khoản bị âm."
			},
			{
				"Q": " Sự khác biệt giữa tiền ký quỹ (tiền đặt cọc) và tiền ký quỹ khả dụng (tiền đặt cọc có sẵn) là gì?",
				"A": " Ký quỹ đề cập đến số tiền được nắm giữ bởi một nhà môi giới để duy trì các lệnh mở. Vốn chủ sở hữu còn lại trong tài khoản được gọi là số tiền ký quỹ khả dụng"
			},
			{
				"Q": " Trượt giá thị trường xảy ra như thế nào?",
				"A": "Trên thị trường, nếu khối lượng sẵn có không đủ để thực hiện lệnh ở mức giá hiện tại thì phần còn lại sẽ được thực hiện ở mức giá sẵn có tiếp theo. Sự khác biệt về giá này được gọi là trượt giá. Sự trượt giá có thể có lợi (trượt tích cực) hoặc bất lợi (trượt âm) cho nhà đầu tư, tùy thuộc vào việc giá thực hiện thuận lợi hơn hay kém thuận lợi hơn dự đoán."
			},
			{
				"Q": " Đòn bẩy có tác động gì đến Stop Out?",
				"A": " Đòn bẩy có thể khuếch đại lợi nhuận đầu tư nhưng cũng làm tăng rủi ro. Đòn bẩy cao hơn có nghĩa là cần ít tiền ký quỹ hơn, tăng khả năng sử dụng vốn và cho phép mở các vị thế giao dịch lớn hơn. Tuy nhiên, trong thời kỳ thị trường biến động mạnh với mức ký quỹ không đủ, lệnh Dừng giao dịch có thể xảy ra."
			},
			{
				"Q": " Lãi suất qua đêm là gì?",
				"A": "Lãi suất qua đêm, còn được gọi là hoán đổi, đề cập đến tiền lãi mà các nhà giao dịch kiếm được hoặc trả khi họ giữ một vị thế qua đêm. Tiền lãi này được tính vào lúc 22:00 tối giờ London. Lãi suất qua đêm phụ thuộc vào hướng vị thế của nhà giao dịch và chênh lệch lãi suất giữa các loại tiền tệ liên quan. Ví dụ: nếu lãi suất của đồng bảng Anh cao hơn đáng kể so với đồng Yên Nhật, nhà giao dịch nắm giữ GBP/JPY ở vị thế mua có thể kiếm được tiền lãi, trong khi nhà giao dịch nắm giữ GBP/JPY ở vị thế bán sẽ phải chịu phí lãi suất."
			},
			{
				"Q": " Cách kiểm tra lãi suất qua đêm?",
				"A": "Phương thức truy vấn hợp đồng hoán đổi như sau: 1. Đăng nhập vào thiết bị đầu cuối giao dịch MT4; 2. Nhấp vào menu chính \"Hiển thị\" - \"Danh sách sản phẩm giao dịch\"; 3. Nhấp chuột phải vào sản phẩm giao dịch mà bạn muốn xem trao đổi và nhấp vào \"Thông số kỹ thuật\"; 4. \"Phí mua/bán hàng tồn kho một kỳ\" là lãi suất qua đêm. (ps: Lãi qua đêm dương sẽ được nhà giao dịch trao cho khách hàng)"
			},
			{
				"Q": " Cổ tức là gì và chúng có tác động gì đến giao dịch?",
				"A": " Trong thị trường Hợp đồng chênh lệch (CFD), cổ tức là khoản thanh toán lãi được thực hiện cho các nhà đầu tư nắm giữ vị thế CFD mua. Ngược lại, các nhà đầu tư nắm giữ vị thế bán thường phải trả phí tương ứng."
			},
			{
				"Q": " Giờ giao dịch giao ngay của vàng và bạc là gì?",
				"A": " Giờ giao dịch vàng và bạc giao ngay của chúng tôi là từ 23:00 Chủ Nhật đến 22:00 Thứ Sáu theo giờ Luân Đôn. Xin lưu ý chợ sẽ đóng cửa trong thời gian ngắn từ 22:00 đến 23:00 hàng ngày"
			},
			{
				"Q": "Hợp đồng giao ngay vàng và bạc bằng bao nhiêu ounce?",
				"A": " Hợp đồng vàng giao ngay: 1 lô = 100 Ounce. Hợp đồng bạc giao ngay: 1 lô = 5.000 Ounce."
			},
			{
				"Q": " Làm cách nào để tính số tiền ký quỹ (thanh toán trước) cần thiết cho các giao dịch vàng và bạc giao ngay?",
				"A": " Nếu giá vàng hiện tại là 2.000 đô la Mỹ/ounce, đòn bẩy là 500 lần và giao dịch là 0,1 lô thì số tiền ký quỹ cần thiết cho vàng = 2000*100/500*0,1=40USD. Nếu giá bạc hiện tại là 23 đô la Mỹ/ounce, đòn bẩy là 500 lần và giao dịch là 0,1 lô. , thì số tiền ký quỹ yêu cầu đối với vàng = 23*5000/500*0,1=23USD"
			},
			{
				"Q": " Hợp đồng giao ngay vàng và bạc có thời hạn hiệu lực không?",
				"A": " Không. Miễn là bạn duy trì đủ số tiền ký quỹ trong tài khoản của mình, vị thế của bạn sẽ vẫn có hiệu lực cho đến khi bạn đóng nó."
			},
			{
				"Q": " Sự khác biệt giữa Dầu thô Brent (XBRUSD) và Dầu thô Tây Texas (XTIUSD) là gì?",
				"A": "Dầu thô có thể được chia thành dầu thô nhẹ và dầu thô nặng dựa trên trọng lượng API, và cũng có thể được chia thành dầu thô chứa lưu huỳnh và dầu thô ngọt dựa trên hàm lượng lưu huỳnh. Dầu thô Brent (XBRUSD) là loại dầu thô nhẹ, ngọt được sản xuất từ một số vùng có dầu ở Bắc Đại Tây Dương. Dầu trung cấp West Texas (XTIUSD), tương đối nhẹ hơn và có hàm lượng lưu huỳnh thấp hơn, thường được báo giá ở mức chiết khấu so với dầu Brent."
			}
		]
	}
}