export default {
  "screen0": {
    "title": "ئەستايىدىل سودىلاشقان ھەر بىر ئادەم ئەستايىدىل مۇئامىلە قىلىشقا ئەرزىيدۇ。",
    "enTitle": "ئىشىنىش &ھۆرمەت قىلىش",
    "btn": "مۇكەممەل ۋىدىئو كۆرۈش"
  },
  "screen1": {
    "line1":  "سىز ئىشىنىشكە بولىدىغان ھەمكارلىق ھەمراھى",
    "line2": "سودا دۇنياسىدا",
    "line3": "ئەڭ قارشى ئېلىنىدىغان مۈلۈك",
    "line4": "خەۋپ-خەتەر ئەسكەرتمىسى: باھا پەرقى توختامى (CFDs) بىر خىل كەسپىي ھەم مۇرەككەپ پۇل مۇئامىلە سودا مەھسۇلاتى بولۇپ، پىشاڭ سەۋەبىدىن زور زىيان ئېلىپ كېلىدۇ. يەر شارىدىكى% 75 مەبلەغ سالغۇچىدا ئىلگىرى زىيان كۆرۈلگەن. بۇ تۈردىكى مەبلەغ سېلىشنىڭ خاراكتېرى ۋە ئۇنىڭغا چېتىلىدىغان خەۋپ-خەتەرنى تولۇق بىلىشىڭىزگە ۋە چۈشىنىشىڭىزگە كاپالەتلىك قىلىپ، ئاندىن بىزنى چۈشىنىڭ。",
    "list": [{
			"enTitle": "Derivatives",
			"title": "تاشقى پېرېۋوت",
			"desc": "ئەڭ تۆۋەن 0 نۇقتا پەرقى بىلەن دۇنيا بويىچە ئەڭ قارشى ئېلىنىدىغان 36 خىل ئاساسلىق ۋە  ئىككىنچى ئورۇندا تۇرىدىغانچەت ئەل پۇلىنى نەقلەشتۈرگىلى بولىدۇ",
			"more": "تېخىمۇ كۆپ چۈشىنىش"
		}, {
			"enTitle": "Index CFDs",
			"title": "ﻧﻪق ﻣﺎل ﻛﯚرﺳﻪﺗﻜﯜﭼﻰ ﭘﻪرق",
			"desc": "EBC سىز ئۈچۈن بىر قاتار يۇقىرى دەرىجىلىك كۆرسەتكۈچلەرنى تاللاپ، دۇنيا ئىقتىسادىدىكى ئەڭ قىممىتى بار ۋە ئەڭ جانلىق ساھەلەر ئۈستىدە پائال ئىزدىنىدۇ ",
			"more": "تېخىمۇ كۆپ چۈشىنىش"
		}, {
			"enTitle": "Commodities",
			"title": "كۆپ مىقدارلىق تاۋار",
			"desc": "ئەگەر سىز مەبلەغ سېلىش بىرىكمىسىنى كۆپ مەنبەلەشتۈرۈشنى ئويلاشسىڭىز، ئۇنداقتا خەتەردىن ساقلىنىش مۈلكى (مەسىلەن، قىممەتلىك مېتال) ، ئېنېرگىيە بازىرى (مەسىلەن، خام نېفىت، تەبىئىي گاز) سىزنىڭ كۆڭۈلدىكىدەك تاللىشىڭىز بولالايدۇ. ",
			"more": "تېخىمۇ كۆپ چۈشىنىش"
		}, {
			"enTitle": "Share CFDs",
			"title": "پاي چېكى باھا پەرقى توختامى",
			"desc": "دۇنيادا پىشىپ يېتىلگەن ۋە بازىرى يېڭىدىن گۈللەنگەن 36 بېرژا پاي چېكى سودىسى قىلىدۇ، ھەر ۋاقىت ئىلاستىكىلىق باھا بېكىتىپ، ئەمەلىي پاي چېكى بازىرىدىكى ئىپادىسىگە باغلايدۇ ",
			"more": "تېخىمۇ كۆپ چۈشىنىش"
		}]
  },
  "screen2": {
		"title1": "EBC ئولتۇرۇشى ",
		"title2": "قىلىپ باشقۇرۇش<span>يەر شارىدىكى ئىككى چوڭ يۇقىرى دەرىجىلىك نازارەت </span>",
		"desc": "ۋە ئىقتىدارى ئۆلچەملىك بولغان ئاپپارات دەرىجىلىك سودا مۇ<br />سىز سەمىمىي، بىخەتەر ھىتىدىن بەھرىمەن بولالايسىز",
		"list": [{
			"title": "ئەنگلىيە FCA نازارەت قىلىپ باشقۇرۇش ",
			"desc": "EBC Financiall Group Ltd ئەنگلىيە پۇل مۇئامىلە ھەرىكىتىنى نازارەت قىلىپ باشقۇرۇش ئىدارىسى (FCA) ھوقۇق بەرگەن ۋە نازارەت قىلىپ باشقۇرغان。<br />نازارەت قىلىپ باشقۇرۇش نومۇرى:927552 <br/>URL：<a href='https://www.ebcfin.co.uk'>www.ebcfin.co.uk</a>"
		}, {
			"title": "ئاۋسترالىيە ASIC نازارەت قىلىپ باشقۇرۇش ",
			"desc": "EBC Global Pty Ltd غا ئاۋسترالىيە ئاكسىيە ۋە مەبلەغ سېلىش كومىتېتى (ASIC) ھوقۇق بېرىدۇ ۋە نازارەت قىلىپ باشقۇرىدۇ. 。<br />نازارەت قىلىپ باشقۇرۇش نومۇرى:500991 "
		}]
	},
  "screen3": {
		"line1": "سىز ئىشىنىشكە ئەرزىيدىغان ھەمكارلىق ھەمراھى. ",
		"line2": "Driving you",
		"line3": "to succeed",
		"line4": "كەسىپتە ئالدىنقى قاتاردا تۇرىدىغان پۇل مۇئامىلە پەن-تېخنىكىسى سودىنى چەكسىز ئىمكانىيەتكە ئىگە قىلدى. ",
		"line5": "ئورۇنلاشتۇرۇش <br />لوندون LD5؛ نيۇ يورك NY4؛ سىنگاپور SG1؛ توكيو TY3؛ شياڭگاڭ HK2 ",
		"line6": "ئۇلترا يۇقىرى سۈرئەتلىك مەخسۇس لىنىيىنى ئۇلايدىغان مۇستەقىل مۇلازىمېتىر ",
		"list": [{
			"num": "1000",
			"text": "سېكۇنتلۇق ئەڭ يۇقىرى پولىمىرلىق زاكاز تالونى"
		}, {
			"num": "5",
			"unit": "×24",
			"text": "تىن بەھرىمەن بولۇش مۇلازىمىتى"
		}, {
			"num": "50",
			"unit": "+",
			"text": "تىن ئارتۇق سودا تۈرى"
		}, {
			"num": "20",
			"unit": "+",
			"text": "تىن ئارتۇق  يەر شارى ئۇلترا يۇقىرى ئىقتىدارلىق سانلىق مەلۇمات مەركىزى"
		}, {
			"num": "100",
			"unit": "+",
			"text": " يەر شارى ئەزالىرى ئېشىپ كەتتى"
		}, {
			"num": "98.75",
			"unit": "%",
			"text": "سانلىق مەلۇمات يوللاش مۇقىملىقى يۇقىرى بولدى"
		}, {
			"num": "0.0",
			"unit": "PIPS",
			"text": " كەسىپداشلار بانكا دەرىجىسىدىكى RAW ECN نۇقتا پەرقى "
		}, {
			"btn": " تېخنىكىسى ئۈستىدە ئىزدىنىش EBC"
		}]
	},
  "screen4": {
		"enTitle": "Discover Prices & Transfer Orders",
		"title": " باھانىڭ بايقىلىشى ۋە زاكاز تالونىنىڭ يەتكۈزۈلۈشى ",
		"desc": "EBC fix (پۇل مۇئامىلە ئۇچۇرلىرىنى ئالماشتۇرۇش كېلىشىمى) ئارقىلىق ئۇلانغان بانكىلار ئارا باھانى پولىمېرلاپ، خېرىدارلارنى بىرىنچى دەرىجىلىك كۆچمە سورۇننىڭ بىۋاسىتە بازار ئىجازىتى بىلەن تەمىنلەيدۇ، مەخسۇس فىزىكىلىق HUB قاتتىق دېتالى ئارقىلىق پولىمېرلانغاندىن كېيىنكى باھا مەلۇم قىلىش تېخىمۇ تۆۋەن نۇقتا پەرقى ۋە ئىمكانىيەتلىك سىجىل سودا چوڭقۇرلۇقىغا ئىگە بولىدۇ. ",
		"line1": "EBC Trading Black-Box",
		"line2": "سودا قارا قۇتىسى ",
		"line3": "دەل جايىدا ئەلالاشتۇرۇلغان تالون تاشلاش ئالگورىتمى ",
		"line4": " تىن يۇقىرى زاكاز تالونىنى تېخىمۇ ياخشى باھاغا پۈتۈشۈش كېرەك%85",
		"list": ["سودا قارا قۇتىسى ", "سودىنىڭ جايلىشىش خەرىتىسى ", "تەجرىبە قىلىش ئارقىلىق، بىز شۇنى بايقايمىزكى:<br />باھانى ئەلالاشتۇرۇش نىسبىتى، زاكاس تالونىنىڭ پۈتۈشۈش نىسبىتى ۋە ھېسابلاش ئۇسۇلىنىڭ مۇقىملىقى جەھەتتە، EBC سودا قارا قۇتىسى ئەنئەنىۋى سودا سېستىمىسىدىن كۆپ ئۈستۈن تۇرىدۇ. "],
		"rightDesc": "EBC fix (پۇل مۇئامىلە ئۇچۇرلىرىنى ئالماشتۇرۇش كېلىشىمى) ئارقىلىق ئۇلانغان بانكىلار ئارا باھانى پولىمېرلاپ، خېرىدارلارنى بىرىنچى دەرىجىلىك كۆچمە سورۇننىڭ بىۋاسىتە بازار ئىجازىتى بىلەن تەمىنلەيدۇ، مەخسۇس فىزىكىلىق HUB قاتتىق دېتالى ئارقىلىق پولىمېرلانغاندىن كېيىنكى باھا مەلۇم قىلىش تېخىمۇ تۆۋەن نۇقتا پەرقى ۋە ئىمكانىيەتلىك سىجىل سودا چوڭقۇرلۇقىغا ئىگە بولىدۇ. ",
		"more": "تېخىمۇ كۆپ چۈشىنىش ",
		"btn": "مەبلەغ سېلىشنى باشلاش"
	},
	"screen5": {
		"btn": "مەبلەغ سېلىشنى باشلاش",
		"title": " سودا بوشلۇقى EBC",
		"enTitle": "EBC Priviate Room",
		"desc": "« ھېساب ئېلىش بىرلىككە كەلگەن ئۆلچەم ئەمەس، بەلكى بارلىق ئەھۋالغا ئۇيغۇن كېلىدىغان بارلىق جاۋاب» . ",
		"textList": [" سىزنىڭ بىر قىسىم سودا ئۇچۇرلىرىڭىزنى يوشۇرالايدۇ، بۇ سىز چۈشۈرگەن« چوڭ تالون» نى تېخىمۇ ياخشى باھادا پۈتۈشكىلى بولىدىغانلىقىدىن دېرەك بېرىدۇ، ئەمما ئېغىر بولغان سىيرىلىش نۇقتىسى  ياكى ئەڭ ئاخىرقى قاراپ چىقىش باھاسى (Last Look) سەۋەبىدىن زاكاز رەت قىلىش مەۋجۇت ئەمەس. ",
			"ئەگەر سىز بىر 'چوڭ سودىگەر' بولسىڭىز، سىز بازاردا باشقىلارغا ئۆزىڭىزنىڭ تەگلىكىڭىزنى بىلدۈرۈشنى خالىمايسىز، EBC نىڭ Private Room پىلانى سىزنىڭ ئەڭ ياخشى تاللىشىڭىز بولۇپ قالىدۇ."
		],
		"ftTitle": "EBC THE FUTURE",
		"ftDesc": ".مەبلەغ سالغۇچىلارغا نىسبەتەن بىر ھەقىقىي <span>EBC</span>",
		"ftDesc1": "يەر شارى بويىچە يۇقىرى دەرىجىلىك ئېقىشچانلىقىنى تەمىنلەيدۇ. ",
		"ftBtn": "بىز بىلەن ھەمكارلىق",
		"ftList": [{
			"title": "بىخەتەرلىك",
			"desc": "باراۋەر تور ئوپتىك تالا مەخسۇس لىنىيەسى ئارقىلىق يەر شارى Equinix نى تۇتاشتۇرۇپ، 20 مىللىسېكۇنتلۇق ئىنتايىن تېز زاكاز تالونىنى ئىجرا قىلىش ئىشقا ئاشۇرۇلدى."
		}, {
			"title": "بەلگىلىمىگە ئۇيغۇنلۇقى",
			"desc": "بارلىق خېرىدارلارنىڭ مەبلىغى دۇنيادىكى ئەڭ يۇقىرى دەرىجىلىك بانكىلارغا ھاۋالە قىلىنىپ، قاتتىق نازارەت قىلىندى ۋە باشقۇرۇلدى."
		}, {
			"title": "ئېقىشچانلىقى ",
			"desc": " سۇپىمىزخەلقئارادىكى نۇرغۇن يۇقىرى دەرىجىلىك بانكىلار بىلەن ئۇزاق مۇددەتلىك مۇقىم ھەمكارلىق مۇناسىۋىتى ئورنىتىپ، سىزنى ناھايىتى ئاسانلا ئادەتتىن تاشقىرى تۆۋەن سودا   تەننەرخىدىن بەھرىمەن قىلدى."
		}, {
			"title": "ھەمكارلاشقۇچى"
		}]
	},
  "urlLan":"vi"
}
