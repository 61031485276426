export default {
  "box-name": "en-style2",
  "public-btn-text": "로그인",
  "screen0": {
    "title": "EBC는 기관 투자자들에게 세계 <br>최고 수준의 유동성을 제공합니다.",
    "enTitle": "EBC THE FUTURE",
    "list": [{
        "title": "안전성",
        "desc": " 피어 투 피어 네트워킹의 전용 광섬유 회선을 통해 전 세계 Equinix 센터에 연결하여 20ms 이내의 매우 빠른 거래 속도를 달성합니다."
      },
      {
        "title": "준법성",
        "desc": "저희 자금은 세계 최고 은행에 위탁관리하여 엄격한 감독관리를 받고 있습니다."
      },
      {
        "title": "유동성",
        "desc": "EBC는 다수의 국제 최고 은행과 장기적이고 안정적인 제휴관계를 구축하고, 초저 거래 비용을 쉽게 누릴 수 있도록 합니다."
      }
    ]
  },
  "screen1": {
    title: "유동성 깊이",
    "trList": [
      "层级",
      "买入价",
      "买入的交易量",
      "点差",
      "卖出价",
      "卖出价交易量"
    ],
    "mobileTrList": [
      "层级",
      "买入价",
      "点差",
      "卖出价"
    ]
  },
  "screen2": {
    "pageEnTitle": "Clearing System",
    "pageTitle": "청산무역제",
    "title": "EBC의 안전하고 투명하며 신뢰성 있는 청산 생태 시스템"
  },
  "screen3": {
    "pageEnTitle": "EBC Priviate Room",
    "pageTitle": "EBC 거래 공간",
    "title": "\"청산은 통일된 기준이 아닌 모든 상황에 걸맞는 모든 대답입니다.\"",
    "desc": "만약 귀하께서 \"빅 플레이어\"이고, 시장에서 다른 사람에게 귀하의 \"카드\"를 알리고 싶지 않으시다면 EBC의 Private Room 서비스는 가장 좋은 선택이 될 것입니다."
  },
  "screen4": {
    "title": "전속 유동성",
    "desc": "Private Room은 고객님의 일부 거래정보를 숨길 수 있습니다. 이는 고객님이 주문한 “큰 오더”가 더욱 좋은 가격으로 거래될 수 있고 심각한 슬리피지 또는 라스트 룩(Last Look)으로 인한 주문 거절이 존재하지 않음을 의미합니다. 아래 그림과 같이 Private Room을 통해 거래하는 큰 오더의 40%에 마이너스 슬리피지가 발생하였습니다."
  },
  "screen5": {
    "it": "오직 시간만이 자신을 더 잘 알 수 있게 해줍니다",
    "ct": "*거래 중 에 미 끄 러 운 점 이 있 을 수 있 고 지연 이 있 을 수 있 으 며 여러 가지 불확실 성 요소 가 있 습 니 다.경험 이 풍부 한 거래 인의 귀 하 는 이런 작은 점 을 알 아차 리 지 못 할 수도 있 습 니 다.그러나 귀하 에 게 발생 하 는 불편 이 있 으 면 저 희 를 신속하게 피드백 해 주 십시오.EBC 는 고 품질 과 극단 주의 정신 을 주창 하 며 불확실 성의 미래 를 적극적으로 끌 어 안 고 있다.고객 에 게 간단 하고 깨끗 하 며 편안 한 거래 새로운 체험 을 가 져 다 주 려 고 노력 하 다.",
    "title": "기대 이상의 사용자 경험을 지속적으로 창출",
    "desc": "저희는 고객님의 의견을 적극적으로 경청하며 개인화된 거래 경험을 향상시키는 것을 매우 중시합니다. EBC 개발팀은 고객님의 온라인 거래 경험을 더욱 최적화하기 위해 항상 새로운 기능과 기술을 개발하고 개선하는 데 전념합니다.",
    "btn": "EBC의 장점 이해"
  }
}
