export default{
  "screen0": {
    "title": "值得信賴的<br>MAM解決方案",
    "content": "EBC 特別為專業資金管理者量身打造了一款Multi Account多帳戶資金管理系統,允許您使用定制的分配方法,集合式下單軟件,實时報告效能和即時更新傭金報告來實現多個帳戶的統籌管理。為基金經理和尋找專業人士管理資金的客戶提供了可靠的合作途徑。",
    "list": [
      "彈性分配",
      "即時報告",
      "簡易操作介面"
    ],
    "btn": "即刻申請MAM帳戶",
    "rightTitle": "EBC 多帳戶管理者系統（MAM)綜合了彈性分配、<br>即時報告,以及簡易化操作介面。"
  },
  "screen1": {
    "pageEnTitle": "Asset Management",
    "pageTitle": "資金管理",
    "title": "專為 MetaTrader4 設計",
    "desc": "MAM帳戶相當於一個主帳戶,下麵有若干個跟隨帳戶,他們之間是相互獨立的,是一種跟單形式。投資者只需輸入交易總手數,系統便會按照投資者選擇的分配管道執行交易,把交易手數自動分配給不同的個人投資者。帳戶管理者通過單一交易帳戶,便可快捷、有效和準確的為多個投資者進行交易。",
    "text": "便可快捷、有效、準確的,為多個<span>投資者</span>進行交易。",
    "btn": "瞭解更多",
    "imgUrl": "zh"
  },
  "screen2": {
    "pageEnTitle": "Intelligent Transaction",
    "pageTitle": "智能分配交易",
    "title": "通過單一交易帳戶,便可快捷的為多個投資者進行交易",
    "desc": "MAM帳戶相當於一個主帳戶,下麵有若干個跟隨帳戶,他們之間是相互獨立的,是一種跟單形式。投資者只需輸入交易總手數,系統便會按照投資者選擇的分配管道執行交易,把交易手數自動分配給不同的個人投資者。帳戶管理者通過單一交易帳戶,便可快捷、有效和準確的為多個投資者進行交易。"
  },
  "screen3": {
    "pageEnTitle": "Advantages & Functions",
    "pageTitle": "MAM功能",
		"pageTitle2": "MAM優勢",
    "title": "適用於任何類型的交易和資金管理",
    "content": [
      "十種分配管道可選,包括損益分配、交易複製分配等",
      "適用於任何類型的交易和資金管理",
      "交易帳號數不限",
      "交易—完整帳號,迷你帳號,最小份額帳號",
      "損益分配點精確到16比特",
      "任意訂單類型,止損/止盈,待辦訂單功能",
      "主帳號執行訂單或個人子訂單部分關閉",
      "支持用戶端管理帳號智慧全自動系統交易"
    ],
    "desc": "Apply for a MAM Account",
    "btn": "申請MAM資金管理者帳號",
    "line1": "分配參數即時變更",
    "line2": "所有管理帳號公開實时位置控制",
    "line3": "簡單一個介面控制所有管理帳號",
    "line4": "“群交易”執行",
    "line5": "管理帳戶實时資產和損益監控",
    "line6": "獲取歷史報告並計算分成利潤",
    "btn1": "申請MAM資金管理者帳號"
  },
  "styleName": ""
}
