export default {
  "box-class-name": "oxford-box-cn",
  "button-text": "了解更多",
  "oxford-page1": {
    text1: "EBC<br> 聯合牛津大學<br>研討會",
    text2: "「經濟學家都做了什麼」",
    text3: "Sarah Clifford & David Barrett",
    text4: "立即預約",
    text5: "2024年3月6日12:00 pm GMT（北京時間：20:00）",
  },
  "oxford-page2": {
    text1: "以面向未來的教育, 推動全球經濟發展",
    text2:
      "EBC金融集團聯合牛津大學經濟學系舉辦「經濟學家都做了什麼」研討會，積極實踐ESG理念，推動以知識為驅的社會進步發展",
    text3:
      "EBC與牛津大學達成合作，於2024年3月6日聯合舉辦主題為「經濟學家都做了什麼」 的研討會。 牛津大學以權威學術研究、對全球政策的傑出貢獻等享譽全球。 其經濟學系致力於透過創新的經濟研究，推動公共政策的改善及全球經濟發展。 與牛津大學攜手，是EBC實踐ESG理念的見證。 EBC積極承擔企業責任，長期致力於教育與人才培養，推動人類社會進步發展。 我們相信：透過發揮EBC集團和牛津大學在跨學科領域的雙向專業優勢，不僅能推動建構更專業安全、透明高效的投資環境，更有助於在全球範圍內建立開放包容的公共交流空間，產生 變革性及創新的經濟研究，以更好地促進公共政策的改進，全球經濟發展。 加入我們，共同創造更美好、平等的未來。",
    text4: "",
    text5: "研討會介紹",
    text6:
      " 「經濟學家究竟在做什麼」結合時下熱議話題，探討如何利用經濟學推動政策的改進，促進經濟繁榮。 屆時將深入探討“避稅經濟學”，全面解讀不同稅收環境所引起的經濟趨勢及經濟學家在全球經濟安全中所扮演的角色等。",
  },
  "oxford-page3": {
    text1: "與會嘉賓",
    text2: "牛津大學經濟學副教授",
    text3:
      "Sarah Clifford博士作為牛津大學「避稅經濟學」領域的學術巨擘，致力於探索避稅經濟學背後的經濟學原理，以及稅收相關的經濟行為模型。 Sarah Clifford博士是最早利用跨國面板資料研究MNE ETR的學者之一，主導並提出了經合組織OECD有關稅基侵蝕的「GloBE」法案，極大地推動了BEPS包容性框架的建構。 在其最新論文《避稅的全球影響及其對政策的啟示》中，她深入分析了避稅經濟學如何在全球範圍內對經濟產生影響，以及政府如何透過有效的政策幹預來減少這一現象。",
    text4: "牛津大學經濟學教授",
    text5:
      "Abi Adams-Prassl教授主攻應用微觀經濟學，擅長透過實證方法將數據引入決策模型中，輔助微觀個體和家庭做出決策。 作為英國政府科學辦公室的專家小組成員，Abi Adams-Prassl教授主導了英國政府的風險管理和彈性規劃，並將其從短期擴展為長期目標；同時，作為跨學術和政策研究領域的權威，Abi Adams-Prassl教授也制定了英國法律體系中有關司法的量化框架，確定了在大數據環境中平衡訴諸司法和開放司法原則。",
    text6: "EBC金融集團（英國）首席執行官",
    text7:
      "David Barrett在國際資產多角化配置、結構性風險防範領域有超過35年經驗，親歷多個市場週期，具備豐富的金融監理營運經驗。 擅長國際金融服務、商業策略諮詢、金融產品架構等，洞悉產業趨勢及FCA監理要求的移轉。 David曾主導全球最大投資集團美國AIG的執行和管理工作，推動了華爾街產品架構和合規風控等領域的一系列變革，為後金融危機時代的風險規避和流動性管理搭建了可行性框架。",
  },
  "oxford-page4": {
    text1: "關於EBC金融集團",
    text2:
      "EBC Financial Group成立於英國倫敦，是一家集金融券商、資產管理、移民投資等服務為一體的綜合性金融集團。 EBC業務遍佈全球，以全球頂級監管、毫秒級的訂單執行和機構級的清算池、全方位的保障體系，致力於為每位客戶提供「安全、專業、高效穩定」的交易環境。 EBC集團核心人員擁有逾30年的大型金融機構的工作經驗，親歷多個經濟週期，時間跨越85年《廣場協議》、97年亞洲金融危機、08年全球金融危機和15年瑞郎黑天鵝事件 等。 他們對突發事件及金融危機有優異的因應經驗。 基於實戰鍛鍊出的專業素養，促使EBC對自我要求更為嚴格。 EBC相信，每個認真交易的人，都值得被認真對待。",
    text3: "關於牛津大學經濟學系",
    text4:
      "牛津大學是享譽全球的頂尖高等學府，同時也是英國最具規模的研究機構。 在近千禧年的建校史上，牛津致力於創造開放包容的學術環境，產生創新的經濟研究，建構跨領域的國際合作與交流，推動人類社會發展與進步。 <br>牛津大學經濟學係以其專業權威的學術研究、為全球的經濟和公共政策做出的傑出貢獻而聞名於世，是世界上最多元化的學術經濟學家社群之一。 長期以來，致力於建構開放包容的學術環境，產生可變創新的經濟研究，推動全球經濟發展。",
  },
  lang: "/tw",
  经济学家都干了什么: "經濟學家都做了什麼",
  如何利用气候经济学: "如何利用氣候經濟學，",
  挖掘投资机遇: " 挖掘投資機會？ ",
  "2024年11月14日": "2024年11月14日 14:30 (GMT)",
  "牛津大学Michael Dummett讲座厅": "牛津大學Michael Dummett講座廳",
  预约报名: "預約報名",
  EBC邀您游览牛津大学: "EBC邀您遊覽牛津大學 參與全球研討會",
  EBC金融集团再次携手牛津大学: `EBC金融集團再次攜手牛津大學，將於2024年11月14日舉辦全球研討會，這將是我們合作的第一次Hybrid event（線下與線上結合研討會），同時，也是牛津「經濟學家都做了什麼」 首次以問答環節為特色進行圓桌討論，標誌著EBC與牛津大學深化合作夥伴關係的里程碑。本次研討會以經濟成長與氣候韌性的平衡為主題，探討氣候經濟學與投資前景的挑戰，並協助投資人挖掘投資先機。`,
  活动介绍: "活動介紹",
  "本次线下研讨会将于11月14日14:30": `本次線下研討會將於11月14日14:30 (GMT)在牛津大學基督教會學院展開，線上同步直播，並向全球投資者開放。`,
  EBC金融专家还将与您一同漫步在新学院的回廊:
    "EBC金融專家也將與您一起漫步在新學院的迴廊，重溫《哈利波特：火盃的考驗》的魔法瞬間；或在博德利圖書館，這座全球學者心馳神往的學術聖地，沉浸於牛津幾百年的文化底蘊，體驗牛津獨有的文化魅力。",
  与会嘉宾: "與會嘉賓",
  牛津大学环境复原力及马丁系统韧性负责人:
    "牛津大學環境復原力及馬丁系統韌性負責人",
  "Nicola Ranger是牛津大学环境复原力及马丁系统韧性负责人": `Nicola Ranger是牛津大學環境復原力及馬丁系統韌性負責人，創立了牛津大學環境變遷研究所的全球金融與經濟組，與金融機構、中央銀行和政府密切合作，推動金融、氣候、自然和分析交叉領域的領先研究。 Ranger 一直參與發展壓力測試和情境分析方法，包括與國際貨幣基金組織、世界銀行、NGFS 和英國氣候金融風險論壇合作。此外，她還在歐盟委員會 EMDE永續投資高級別專家小組、英國綠色技術（分類法）諮詢小組/LNAS、FCDO 和倫敦政治經濟學院擔任顧問。 `,
  查看更多: "看更多",
  牛津大学经济学系教授: "牛津大學經濟學系教授",
  牛津大学经济学系教授2: "牛津大學經濟學系教授",
  "Andrea Chiavari 是牛津大学经济学系教授": `Andrea Chiavari 是牛津大學經濟學系教授，專注於宏觀經濟學，尤其是企業動態和技術變革，探索如何利用經濟模型來支持永續發展。研究涉及氣候變遷對企業生產力和資本配置的影響，特別關注如何在不確定環境中提高經濟效率和韌性，以及科技在推動經濟成長中的作用。`,
  "Banu Demir Pakel 是牛津大学经济学系教授": `Banu Demir Pakel 是牛津大學經濟學系教授，專注於國際貿易和發展經濟學，主要探討企業如何應對與貿易相關的衝擊，以及這種調整如何影響整體經濟結果，例如經濟成長和收入水平。她同時也是歐洲經濟政策研究中心（CEPR）和慕尼黑CESifo研究網絡的研究成員。`,
  "EBC金融集团（英国）首席执行官": "EBC金融集團（英國）執行長",
  "David Barrett是EBC 金融集团 (英国) CEO": `David Barrett是EBC 金融集團(英國) CEO ，在國際資產多元化配置、結構性風險防範領域有超過35年經驗，親歷多個市場週期，曾主導全球最大投資集團美國AIG的執行與管理工作，推動了華爾街產品架構和合規風控等領域的一系列變革，為後金融危機時代的風險規避和流動性管理建立了可行性框架。`,
  往期回顾: "往期回顧",
  EBC金融集团联合牛津大学经济学系第一期:
    "EBC金融集團聯合牛津大學經濟學系第一期：「經濟學家都做了什麼 」研討會",
  "2024年3月6日EBC与牛津大学联合举办主题为": `2024年3月6日EBC與牛津大學共同舉辦主題為「經濟學家都做了什麼 」的研討會。探討如何運用經濟學推動政策的改進，促進經濟繁榮。深入探討「避稅經濟學」，全面解讀不同稅收環境所引起的經濟趨勢及經濟學家在全球經濟安全中所扮演的角色等。`,
  关于EBC金融集团: "關於EBC金融集團",
  "EBC Financial Group 成立于英国伦敦": `EBC Financial Group 成立於英國倫敦，是一家集金融券商、資產管理、移民投資等服務為一體的綜合性金融集團。 EBC在全球多個重要金融司法轄區獲得授權並受其監管，擁有英國FCA、澳洲ASIC、開曼CIMA全監管牌照，在倫敦、香港、東京、雪梨、開曼等多地設有分公司和辦事處。EBC集團核心人員擁有逾30年的大型金融機構工作經驗，親歷多個經濟週期，時間跨越85年《廣場協議》、97年亞洲金融危機、08年全球金融危機和15年瑞郎黑天鵝事件等。EBC是巴塞隆納足球俱樂部在金融領域的官方合作夥伴，同時，攜手聯合國基金會共同打敗瘧疾，改善全球衛生系統。`,
  关于牛津大学经济学系: "關於牛津大學經濟學系",
  牛津大学是享誉全球的顶尖高等学府: `牛津大學是享譽全球的頂尖高等學府，同時也是英國最具規模的研究機構。在近千禧年的建校史上，牛津致力於創造開放包容的學術環境，產生創新的經濟研究，建構跨領域的國際合作與交流，推動人類社會發展與進步。`,
  牛津大学经济学系以其专业权威的学术研究: `牛津大學經濟學係以其專業權威的學術研究、為全球的經濟和公共政策做出的傑出貢獻而聞名於世，是世界上最多元化的學術經濟學家社群之一。長期以來，致力於建構開放包容的學術環境，產生可變創新的經濟研究，推動全球經濟發展。`,
};
