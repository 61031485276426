export default {
	"screen0": {
		"title": "Pure Trading Pure Power",
		"subTitle": "Giao dịch đang tỏ ra cực kỳ khó khăn, có lẽ cần phải có một cú hích cuối cùng. Mức độ tiếp cận thị trường vô song. Cơ sở hạ tầng CNTT có độ trễ cực thấp"
	},
	"screen1": {
		"line1": "THỜI GIAN THI CÔNG TRUNG BÌNH ÍT HƠN",
		"line2": "THỜI GIAN TĂNG TỐC",
		"line3": "MẠNG SỢI QUANG CHUYÊN DỤNG ÍT NHẤT",
		"line4": "Theo kịp với thế giới"
	},
	"screen2": {
		"title": "Công nghệ hỗ trợ giao dịch",
		"desc": "Các máy chủ chuyên dụng của EBC được thiết lập tại Equinix LD4, HK3, TY3, NY4 để xây dựng phạm vi phủ sóng toàn cầu cho hệ sinh thái tài chính.",
		"title1": "科技赋能交易，EBC<br>交易执行速度低至20毫秒",
		"desc1": "在软件定义计算、贮存容量和网络基础上，超融合基础架构带来高达98.75%的系统稳定可用性",
		"desc2": "<p>在当今的金融市场中，速度等于利润，敏捷性对于交易获利至关重要；</p> <p>我们将交易服务器设于世界领先的数据中心Equinix LD4、HK3、TY3、NY4（待设），全球超高速专线连接最大程度地减少了区域间延迟，客户可以享受到稳定的交易；</p><p>由于防火墙带来的限制以及拥挤的国际带宽，造成了低质量的交易体验。我们配有近30个DC（含灾备服务器），欧亚服务器之间全部实现对等网络金融专线连接(Peer-to-peernetworking)；</p><p>相对于传统服务器的欧亚线路，EBC从伦敦到亚洲的延迟控制在100ms以内，而传统经纪商从伦敦到亚洲的传输时间在270ms以上，这无疑最有益于亚洲的客户。</p>"
	},
	"screen3": {
		"title": "我们深知：成功，<br>关键不限于软件技术，而是整体",
		"desc": "Chúng tôi đảm bảo tính thanh khoản tốt nhất trên thị trường giao dịch để bạn có thể linh hoạt hơn, đồng thời thu được nhiều Thanh khoản đa tài sản hơn.",
		"plant": "想更了解交易平台？",
		"btn": "Lab 学习中心 / 交易平台板块"
	},
	"screen4": {
		"enTitle": "Driving You to Succeed",
		"title": "Công nghệ tài chính chuyên nghiệp và hàng đầu giúp giao dịch với khả năng vô hạn"
	},
	"screen5": {
		"plant": "了解交易产品",
		"btn": "Lab 学习中心 / 交易产品板块",
		"title": "Giảm thiểu rủi ro và tối đa hóa cơ hội giao dịch",
		"desc": "Công cụ tổng hợp độ trễ cực thấp, định tuyến đơn hàng thông minh và tối ưu hóa báo giá của EBC cung cấp cho bạn độ tin cậy cao hơn và thực hiện đơn hàng tối ưu.",
		"list": [
			"Máy chủ giao dịch",
			"Báo giá tổng hợp",
			"Truyền lệnh",
			"Dòng thanh khoản"
		],
		"link1": "View Trading hour",
		"link2": "Tất cả sản phẩm"
	},
	"screen6": {
		"title": "专注于交易的您，不必担心IT基础架构，操作系统，网络和所有硬件方面的问题。因为您所在乎的，我们都已考虑在内——最佳流动性获取和高性能订单执行",
		"desc": "从前5档深度的订单执行完成情况看，EBC仅需75-125ms就完成了该交易"
	},
	"screen7": {
		"pageTitle": "Khám phá giá & lệnh chuyển nhượng",
		"pageEnTitle": "Discover Prices & Transfer Orders",
		"title": "EBC sử dụng FIX (Giao thức trao đổi thông tin tài chính) để tổng hợp báo giá tiền tệ liên ngân hàng nhằm cung cấp cho khách hàng quyền truy cập trực tiếp vào thị trường với tính thanh khoản tối ưu. Báo giá được tổng hợp thông qua phần cứng HUB vật lý chuyên dụng mang lại mức chênh lệch thấp hơn và cơ hội giao dịch bền vững.",
		"desc": "fix聚合报价的核心包括超低延迟的定单发送和价格发现引擎",
		"imgUrl": "en"
	},
	"screen8": {
		"title": "通过EBC bridge聚合后的<br>产品点差表，深度表",
		"desc": "快速了解",
		"link1": "4 种资产类别",
		"link2": "100+ 交易品种",
		"link3": "查看全部",
		"trList": [
			"层级",
			"买入价",
			"买入的交易量",
			"点差",
			"卖出价",
			"卖出价交易量"
		],
		"smallTrList": [
			"层级",
			"买入价",
			"点差",
			"卖出价"
		]
	},
	"screen9": {
		"title": "Hộp đen giao dịch",
		"smallTitle": "Cho phép hơn 85% đơn hàng được thực hiện ở mức giá tốt hơn",
		"desc": "Hộp đen giao dịch EBC, dựa trên hàng chục triệu dữ liệu giao dịch lịch sử, thói quen giao dịch của người giao dịch, thuộc tính người dùng và các chân dung khác, tình hình thị trường hiện tại và môi trường mạng giao dịch, sử dụng phân tích thống kê số lượng lớn, mô hình xác suất, mô hình mạng thần kinh, kiểm soát hệ thống mô hình lý thuyết và thuật toán trí tuệ nhân tạo để tiến hành nghiên cứu và phán đoán toàn diện, và cuối cùng khớp LP phù hợp hơn với thuộc tính của từng nhà giao dịch, Đạt được giá giao dịch tốt nhất",
		"link1": "View Trading hour",
		"link2": "Tất cả sản phẩm",
		"imgUrl": "en"
	},
	"screen10": {
		"desc": "Sơ đồ phân phối sau đây thể hiện kết quả so sánh giữa hộp đen giao dịch EBC và hệ thống giao dịch truyền thống (chứa ít nhất 100 lệnh):",
		"list": [
			"mỗi điểm dữ liệu đại diện cho giao dịch trong ngày",
			"trục hoành biểu thị tỷ lệ giao dịch của lệnh",
			"kích thước của điểm thể hiện kích thước tương đối của số lượng đặt hàng",
			"trục tung biểu thị tỷ lệ tối ưu của giá giao dịch"
		],
		"btn": "Đăng ký ngay",
		"title": "Kinh nghiệm này khẳng định rằng hộp đen giao dịch EBC vượt trội hơn nhiều so với hệ thống giao dịch truyền thống về tỷ lệ tối ưu hóa giá, tỷ lệ giao dịch lệnh và độ ổn định của thuật toán."
	}
}

