export default {
	"box-class-name": "en-no1-box",
	"lang": "ug",
	"button-text": "发起挑战",
	"no1-page2": {
		"text1": "سېكۇنتىغا ئەڭ كۆپ بولغاندا 1000 زاكاز قوبۇل قىلىنىدۇ",
		"text2": "20 ms تېز سۈرئەتتە زاكازنى ئىجرا قىلىش",
		"text3": "98.75% سانلىق مەلۇمات يوللاش مۇقىملىقى ئەڭ يۇقىرى بولغاندا		",
		"text4": "بانكا دەرىجىلىك ECN RAW نۇقتا پەرقى 0.0 pips",
	},
	"no1-page3": {
		"text1": "ئەنگلىيە FCA نازارەت قىلىپ باشقۇرۇش",
		"text2": "EBC Financial Group ( UK ) Ltd ئەنگلىيە پۇل مۇئامىلە ھەرىكىتىنى نازارەت قىلىش-باشقۇرۇش ئىدارىسى ( FCA ) ھوقۇق بەرگەن ۋە نازارەت قىلغان<br><span>نازارەت قىلىپ باشقۇرۇش نومۇرى:927552</span>",
		"text3": "ئاۋسترالىيە ASIC نازارەت قىلىپ باشقۇرۇش",
		"text4": "EBC Financial Group ( Australia ) Pty Ltd  ئاۋسترالىيە ئاكسىيە ۋە مەبلەغ سېلىش كومىتېتى ( ASIC ) ھوقۇق بېرىدۇ ۋە نازارەت قىلىپ باشقۇرىدۇ<br><span>نازارەت قىلىپ باشقۇرۇش نومۇرى:500991</span>",
	},
	"no1-page4": {
		"text1": "ئەڭ كۈچلۈك سودا مۇھىتى<br>EBC نىڭ چېكى ئەمەس، بەلكى سىزنى ئۆلچەملىك سىزىققا جەڭ ئېلان قىلىشقا تەكلىپ قىلىدۇ،<br>	پەقەت جەڭ ئېلان قىلىش مۇۋەپپەقىيەتلىك بولسىلا<br>EBC ۋەدە بېرىدۇكى، 150% پايدا پەرق سوممىسىنى مۇكاپاتلايدۇ، يۇقىرىسىنىڭ چېكى يوق<br>ىز پەقەت جەڭ ئېلان قىلسىڭىزلا بولىدۇ<br>	قالغىنىنى EBC نىڭ ئەمەلىي كۈچى ئارقىلىق ئىسپاتلايلى",
		"text2": "150% پايدا پەرق سوممىسى مۇكاپاتى",
	},
	"no1-page5": {
		"text1": "<span>85<i>%</i></span><br>تىن ئارتۇق زاكازنى تېخىمۇ ياخشى باھادا پۈتۈشىدۇ",
		"text2": "زاكاز سېتىش ھېسابلاش ئۇسۇلىنى دەل جايىدا ئەلالاشتۇرۇپ، باھانى ئەلالاشتۇرۇش نىسبىتى، زاكاز پۈتۈشۈش نىسبىتى ھەمدە ھېسابلاش ئۇسۇلىنىڭ مۇقىملىقى جەھەتتە، EBC سودا قارا قۇتىسى ئەنئەنىۋى سودا سىستېمىسىدىن خېلىلا ئۈستۈن تۇرىدۇ",
	},
	"no1-page6": {
		"text1": "پائالىيەت قائىدىسى",
		"text2": "سەمىمىيەت ۋە ھۆرمەتنى پىرىنسىپ قىلىپ، ئەڭ يۇقىرى ئۆلچەمگە جەڭ ئېلان قىلىش ئۈچۈن ",
		"text3": "پۇل كىرگۈزۈش تەلىپى",
		"text4": "سۇپا تاللاش",
		"text5": "ئوخشاش تاكتىكا",
		"text6": "رىقابەت ئۇتۇقلۇق بولسا",
		"text7": "پۇل كىرگۈزۈش تەلىپى يوق ",
		"text8": "ئەنگلىيە FCA نازارەت قىلىپ باشقۇرۇش",
		"text9": "ئاۋسترالىيە ASIC نازارەت قىلىپ باشقۇرۇش",
		"text10": "تاللانغان سودا سۇپىسى ئەنگلىيە FCA ياكى ئاۋسترالىيە ASIC نازارەت قىلىش-باشقۇرۇش سالاھىيىتىنى ھازىرلىغان سۇپا بولىشى كېرەك",
		"text11": "EBC ۋە تاللانغان سودا سۇپىسىدا ئوخشاش سودا مەبلىغى ۋە ئوخشاش تاكتىكا ئىشلىتىش كېرەك",
		"text12": "EBC نىڭ ئاخىرقى سودا پايدىسى باشقا سۇپىلاردىن تۆۋەن بولسا،% 150 پايدا پەرقىنى تولۇقلاپ بېرىدۇ",
		"text13": "	مۇسابىقىگە قاتناشقان بارلىق ماھىرلار EBC نىڭ  VIP ھېساباتىنى ھەقسىز ئىلتىماس قىلسا بولىدۇEBC سۇپا ھالقىغان ئەگەشمە يۇمشاق دېتال بىلەن ھەقسىز تەمىنلەيدۇ",
	},
	"no1-page7": {
		"text1": "پائالىيەتتە بىلىشكە تېگىشلىك ئىشلار",
		"text2": " 1.مۇسابىقىگە قاتنىشىش ھېساباتى<br>(aيېڭى ئابۇنىچى:ئورگان تورىدا يېڭى ھېسابات ئاچقاندىن كېيىن، توردىكى خېرىدارلار مۇلازىمىتى بىلەن ئالاقىلىشىپ VIP ئاكونت ئېچىپ رىقابەتكە قاتنىشىش<br>(bھېساباتى بار بولغان ئابۇنىچىلار:توردىكى خېرىدارلار مۇلازىمىتى بىلەن ئالاقىلىشىپ، VIP ئاكونتىنى ئاچقاندىن كېيىن مۇسابىقىسىگە قاتناشساق بولىدۇ",
		"text3": ".تاللىغان سۇپىدا چوقۇم ئەنگلىيە FCA ياكى ئاۋسترالىيە ASIC نىڭ نازارەت قىلىپ باشقۇرۇشى بولۇشى كېرەك. AR،EEA تىزىمغا ئالدۇرۇلغان ياكى Trading Name 2ئاكونتىنى ئۆز ئىچىگە ئالمايدۇ",
		"text4": "تۆۋەندىكى ئاساسىي ئېقىم پۇل جۈپى بىلەنلا چەكلىنىدۇ: AUD/USD、USD/CAD、EUR/USD、USD/CHF、GBP/USD、USD/JPY、NZD/USD",
		"text5": " ۋە تاللىغان سودا سۇپالىرى ئوخشاش سودا مەبلىغى ۋە تاكتىكىسىنى ئىشلىتىدۇ، بارلىق تاكتىكا نورمال سودا كاتېگورىيىسىگە تەۋە بولۇشى كېرەك EBC",
		"text6": "رىقابەتچى خالىغان ۋاقىتتا ھېساباتنى  راسچوت قىلىشنى تاللىسا بولىدۇ، ھەر بىر كىملىك پەقەت بىر قېتىملا مۇسابىقىگە قاتنىشالايدۇ",
		"text7": "رىقابەت ئاخىرلاشقاندىن كېيىن، سودىنى ئاياغلاشتۇرۇپ تالونىنى تاپشۇرساق بولىدۇ. مۇكاپات تەكشۈرۈشتىن كېيىنكى ئىككى خىزمەت كۈنىدە سودا ھېساباتىغا تارقىتىلىدۇ، سودىلىشىشقا ياكى نەق پۇل ئېلىپ ئىشلىتىشكە بولىدۇ",
		"text8": "ئەگەر ھېساباتتا شېرىنكانە قايتۇرۇش ئەھۋالى بولسا، خېرىدار ماسلىشىپ مۇناسىپ ئىسپات بىلەن تەمىنلىشى كېرەك.",
	},
	"no1-page8": {
		"text1": "پائالىيەت ئاخىرلىشىشقا ",
		"text2": "باشلىنىش: 00:00 GMT + 2 18-يانۋار ئاخىرلىشىش ۋاقتى: 23:59 GMT + 2 1-مارت.",
		"text2-style": "",
	},
	"button1-style": "left: -34px;",
	"button2-style": "left: 95px;",
	"button3-style": "right:95px",
	"button4-style": "right: -18px",
}