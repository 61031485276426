<template>
    <div class="public-slogan">
        <p>{{$t('Index.pages1.title')}}<br>Where Your Goals Begin</p>
    </div>
</template>

<script>
    export default {
    }
</script>

<style>
   .public-slogan{
        padding: 28px 0px;
        background: #F8F8F8;
        color: #3D0101;
        font-size: 16px;
        text-align: center;
        line-height: 30px;
        font-weight: 600;
    }
    @media(max-width:767px) {
        .public-slogan{
            padding: 20px 20px;
            line-height: 28px;
        }
        .partner-box-en .public-slogan{
            padding: 16px 0px;
            line-height: 28px;
            font-size: 13px;
        }
        .public-slogan {
            padding: 16px 0px;
            line-height: 28px;
            font-size: 13px;
        }
    }
</style>
