<template>
	<div class="fca7 contact-us">
		<div class="container">
			<div class="row">
				<div class="col-md-6 d1">
					<p class="p1" v-if="mode">{{$t('CFooters.contact.title')}}</p>
					<p class="p1" v-else>{{$t('CFooters.contactUs.title')}}</p>
					<p class="p2" v-if="mode != 'all'"><em></em><span>ebcfin.co.uk</span><em></em></p>
					<p class="p3">{{$t('CFooters.contactUs.title1')}}</p>
				</div>
				<div class="col-md-6 d2">
					<div class="form-box">
						<p class="form-label mt110">{{$t('CFooters.contact.name')}}</p>
						<div class="row">
							<div class="col-md-6"><input id="name" style="padding-left: 12px;" name="name" type="text"
									value="" class="form-control" v-model="infoData.name"></div>
							<div class="col-md-3" v-for="(item, index) in $t('CFooters.contact.sexList')"><span
									class="select-label" :class="{'select-label--active': sexIndex === index}"
									@click="changeSix(index)">{{item.label}}</span></div>
						</div>
						<p class="form-label">{{$t('CFooters.contact.email')}}</p>
						<div class="row">
							<div class="col-md-12"><input style="padding-left: 12px;" id="phone-email" name="phone"
									type="text" value="" class="form-control" v-model="infoData.call"></div>
						</div>
						<p class="form-label">{{$t('CFooters.contactUs.code')}}</p>
						<div class="row">
							<div class="col-md-12">
								<div class="col-md-6 verify-input" style="float: left;">
									<input id="verify" style="padding-left: 12px;" type="text" value=""
										class="form-control" v-model="code">
								</div>
								<div class="col-md-4 verify-code" style="float: left;" @click="refreshCode">
									<ss-code :identifyCode="identifyCode"></ss-code>
								</div>
							</div>
						</div>
						<input id="lang" type="hidden" value="cn">
						<div class="form-btn" id="contact-submit" @click="getContact">
							<span>{{$t('CFooters.contact.btn')}}</span><img src="../../assets/image/FCA/fca14.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import md5 from 'js-md5';
	export default {
		props:{
			mode: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				sexIndex: 0,
				infoData: {
					call: '',
					name: ''
				},
				code: null,
				identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz", //随机串内容,从这里随机抽几个显示验证码
				identifyCode: "", //验证码图片内容
			}
		},
		mounted() {
			// 初始化验证码
			this.identifyCode = "";
			//参数：（1）随机串内容。（2）验证码显示位数
			this.makeCode(this.identifyCodes, 4);
		},
		methods: {
			// 重置验证码
			refreshCode() {
				this.identifyCode = "";
				this.makeCode(this.identifyCodes, 4);
			},
			//获取验证码的值
			makeCode(o, l) {
				for (let i = 0; i < l; i++) {
					//通过循环获取字符串内随机几位
					this.identifyCode +=
						this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
				}
			},
			//随机数字：用于当角标拿字符串的值
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min);
			},
			getContact() {
				let infoData = {
					...this.infoData
				}
				if (this.identifyCode != this.code) {
					this.code = null
					return this.$toast(this.$t('CFooters.contact.tips4'));
				}
				if (!infoData.name) return this.$toast(this.$t('CFooters.contact.tips1'));
				if (!infoData.call) return this.$toast(this.$t('CFooters.contact.tips2'));
				let checksum = md5(infoData.call + "YISENCE")
				let parms = 'name=' + infoData.name + '&sex=' + this.$t('CFooters.contact.sexList')[this.sexIndex].label +
					'&mobile=' + infoData.call + '&checksum=' + checksum
				let time = new Date().getMilliseconds()
				let checkSum1 = md5(infoData.name + infoData.call + time + "YISENCE")
				this.$http('/ebc/api/setContact/run', {
					name: infoData.name,
					contact: infoData.call,
					date: time,
					checkSum: checkSum1
				}).then(res => {
					this.$toast(this.$t('CFooters.contact.tips3'))
					this.code = null
					this.infoData = {
						call: '',
						name: ''
					}
				})
				// axios.get('https://apinew.yisence.net/API/Yisenceebcmail.aspx?' + parms).then(res => {
				// 	this.$toast(this.$t('CFooters.contact.tips3'))
				// })
			},
			changeSix(index) {
				this.sexIndex = index
			},
		}
	}

</script>

<style lang="scss">
	.fca7 {
		background: url(../../assets/image/WhyEBC/about-06-bg.png) 100%/auto 100% no-repeat;
		background-size: 100% 100%;
		height: 963px;
		background-position: center center;
		position: relative;
	}

	.fca7 .d1 .p1 {
		font-size: 36px;
		line-height: 58px;
		color: #e5e5e5;
		width: 70%;
		margin-left: 12%;
		font-weight: 700;
		margin-top: 255px;
		letter-spacing: 2px;
	}

	.fca7 .d1 .p2 {
		color: #FFFFFF;
		font-size: 20px;
		letter-spacing: 2px;
		margin: 40px 0;
		margin-left: 12%;
	}
	.fca7 #contact-submit img {
	    width: 9px;
	    margin-top: 2px;
	}
	.fca7 .d1 .p2 em {
		width: 60px;
		height: 1px;
		display: inline-block;
		border-bottom: 1px #ffffff solid;
		float: left;
		margin-top: 12px;
	}

	.fca7 .d1 .p2 span {
		margin: 0 12px;
		float: left;
	}

	.fca7 .d1 .p3 {
		margin-left: 12%;
		letter-spacing: 2px;
		color: #FFFFFF;
		font-size: 16px;
		float: left;
		margin-top: 48px;
	}

	.fca7 .d2 {
		margin-top: 160px;
	}



	.fca7 .form-btn {
		margin-top: 40px;
		padding: 6px 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f5f5f5;
		font-size: 18px;
		font-weight: 700;
		cursor: pointer;
		color: #4D0101;
	}

	.fca7 .form-btn span {
		margin-right: 10px;
		display: inline-block;
		line-height: 2;
		/* border-bottom: 1px solid #8b8b8b; */
	}

	.fca7 .form-box {
		width: 640px;
		height: 530px;
		position: absolute;
		top: 150px;
		right: 15%;
		padding: 3px 71px 68px 200px;
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.39) 100%);
	}

	.fca7 .form-box:after {
		content: "";
		position: absolute;
		right: 0;
		bottom: 190px;
		left: 0;
		/* border: 1px solid #7d1614; */
		opacity: .5
	}

	.fca7 .form-box .form-control {
		height: 42px;
		background-color: rgba(255, 255, 255, 0);
		padding: inherit;
		border-radius: 0;
		color: #fff;
		
	}

	.fca7 .form-box .testing {
		margin-top: 40px;
		display: flex;
		align-items: center
	}

	.fca7 .form-box .testing__title {
		font-size: 24px
	}

	.fca7 .form-box .testing__kind {
		padding-left: 10px
	}

	.fca7 .form-box .testing__kind .input {
		border: 1px solid #ced4da;
		font-size: 1rem;
		font-weight: 400;
		line-height: 40px;
		color: #212529;
		background-color: #fff;
		border-radius: .25rem;
		width: 50px;
		height: 40px;
		text-align: center;
		transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
	}

	.fca7 .col-md-3 {
		padding: 0 5px
	}

	.fca7 .select-label {
		cursor: pointer;
		display: flex;
		font-size: 15px;
		align-items: center;
		justify-content: center;
		/* background-color: #fff; */
		border-radius: 21px;
		height: 42px;
		border: 1px solid #ddd;
		color: #fff;
	}

	.fca7 .select-label--active {
		background-color: #ffffff;
		color: #3D0101;
		border-color: #ffffff;
	}

	.fca7 .form-label {
		font-size: 16px;
		margin-top: 40px;
		/* font-weight: 700; */
		color: #ffffff;
		letter-spacing: 2px;
	}

	.fca7 .form-title {
		margin-top: 20px;
		font-size: 36px;
		font-weight: 600;
		color: #333;
		line-height: 1.5
	}

	.fca7 .mt110 {
		margin-top: 80px
	}

	.fca7 .form-inquiry {
		height: 50px;
		width: 125px;
		background-color: #f5f5f5;
		padding: 15px 0 0 10px
	}

	.fca7 .form-inquiry__en {
		font-size: 12px;
		color: #666
	}

	.fca7 .form-inquiry__text {
		margin-top: 8px;
		color: #333;
		font-size: 20px;
		font-weight: 700
	}

	.en-style .fca7 .form-label{
		letter-spacing: 0px;
	}
	.en-style .fca7 .d1 .p1{
		letter-spacing: 0px;
	}
	.en-style .fca7 .d1 .p3{
		letter-spacing: 0px;
	}

	@media(min-width:2000px) {
		.fca7 {
			height: 105vh;
		}

		.fca7 .form-box {
			right: 18%;
			top: 22%;
		}
	}

	@media(max-width:1400px) {
		.fca7 .form-box {
			right: 8%;
		}
	}

	@media(max-width:1300px) {
		.fca7 .form-box {
			right: 6%;
		}
	}

	@media(max-width:995px) {
		.contact-us {
			height: auto;
			padding-bottom: 64px
		}

		.contact-us .form-bg {
			width: 100%;
			display: block;
			margin: 0;
			border: none
		}

		.contact-us .form-box {
			height: auto;
			position: relative;
			left: 0;
			top: 0;
			width: 100%;
			border: none;
			padding: 40px 8%;
			margin-top: -30px
		}

		.contact-us .form-box:after {
			display: none
		}

		.contact-us .form-box .form-btn {
			position: relative;
			left: 0;
			top: 0;
			margin-top: 60px
		}

		.contact-us .form-box .row {
			margin: 0
		}

		.contact-us .form-box .row>* {
			padding: 0
		}

		.contact-us .form-box .mt110 {
			margin-top: 43px
		}

		.contact-us .form-box .col-md-3 {
			margin-top: 12px;
			margin-right: 20px;
			width: 62px;
			height: 28px
		}

		.contact-us .form-box .select-label {
			height: 100%
		}

		.contact-us .form-box .form-label {
			font-size: 16px;
			margin-top: 32px
		}

		.fca7 .d1 .p1 {
			margin: 90px auto 0px;
			font-size: 28px;
			line-height: 44px;
			text-align: center;
			width: 90%;
		}

		.fca7 .d1 .p2 {
			margin: 20px auto;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.fca7 .d1 .p2 em {
			margin-top: 5px;
		}

		.fca7 .d1 .p3 {
			margin-top: 16px;
			margin-bottom: 20px;
		}

		.fca7 .d2 {
			margin-top: 74px;
		}

		.fca7 .d1 .p2 {
			font-size: 18px;
		}

		.fca7 .verify-code {
			width: 50%;
		}

		.fca7 .verify-input {
			width: 50%;
		}

		.contact-us .form-box .select-label {
			height: 34px;
			width: 80px;
		}

		.contact-us .form-box .col-md-3 {
			margin-right: 40px;
		}

		.contact-us .form-box .form-btn {
			margin-top: 36px;
		}

		.contact-us .form-box {
			padding-bottom: 80px;
		}

		.fca7 .d1 .p3 {
			font-size: 14px;
		}

		.fca7 {
			background: url(../../assets/image/FCA/fca44-m.jpg) no-repeat;
			background-size: 100% 100%;
			position: relative;
		}
	}

	@media(max-width:406px) {
		.fca7 .d1 .p1 {
			font-size: 24px;
			line-height: 40px;
			text-align: center;
			width: 90%;
		}
	}

</style>

