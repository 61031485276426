export default{
  "screen0": {
    "enTitle1": "Together",
    "enTitle2": "Let's <text style='color:#800'>Build The Future.</text>",
    "title1": "成為EBC合作夥伴 <br style='display: none;'>",
    "title2": "解鎖更多市場機遇",
    "list": ["全球領導品牌之一",
      "全球開放合作",
      "全套科技解決方案"
    ],
    "desc": "EBC 認真對待每一位與我們展開深度合作的夥伴，如果您有更創新多樣的合作需求，歡迎隨時諮詢。我們將積極為您開拓更廣闊的投資視野，並提供多元化的支持方案，與您共同拓展全球機遇，解鎖無限可能。",
    "links": [{
      "url": "",
      "text": "Why EBC？"
    }, {
      "url": "",
      "text": "瞭解更多"
    }],
		"more": "瞭解更多"
  },
  "screen1": {
    "pageEnTitle": "Customization",
    "pageTitle": "定制化服務",
    "title": "介紹經紀人（IB）<span>百萬美金</span>扶持計畫",
    "desc": "EBC的發展離不開介紹經紀人（IB）的支持,我們為有實力的介紹經紀人（IB）提供良好的發展空間。",
    "list": ["傭金結構",
      "業務創新行銷&交易資源",
      "高效結算和支付流程",
      "專屬客戶經理",
      "多層級傭金系統",
      "多語言客戶支援團隊",
      "完善的後臺和報表工具",
      "業務發展規劃"
    ],
    "text": "全方位<span>定制</span>發展規劃",
    "more": "查看更多服務"
  },
  "screen2": {
    "pageEnTitle": "Trading service",
    "pageTitle": "交易社區服務",
    "title": "一整套完整的業務發展合作模式",
    "desc": "告訴我們您的需求,EBC不僅可為您提供實踐經驗和建議,更可根據您的要求提供個性化定制的科技解決方案,以配合不同業務發展階段的需求。",
    "list": ["交易社區", "交易訊號", "風控管理"],
    "btn": "進入交易社區"
  },
  "screen3": {
    "pageEnTitle": "Liquidity service",
    "pageTitle": "流動性接入服務",
    "title": "EBC支持直通式處理系統",
    "desc": "並接駁多間國際頂尖流動性提供商,可降低經紀商的成本及確保訂單快速執行,並有專屬科技團隊提供24*7小時技術支援服務。",
    "remark": "唯有時間,<span>讓您</span>,更瞭解我。"
  },
  "screen4": {
    "pageEnTitle": "Liquidity service",
    "pageTitle": "新興業態合作",
    "title": "EBC時刻具備擁抱變化的熱情和能力",
    "desc": "對於行業新的發展業態,不斷創新求變,我們相信只有順應市場發展的趨勢,才能穩步向前。如果您有創新的合作方案,敬請垂詢。",
    "list": ["<span>4</span>種資產類別", "<span>100</span><sup>+</sup>交易品種"]
  },
	"box-class": ""
}
