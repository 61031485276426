export default {
	"esg-class-name": "en-esg",
	"esg-banner": {
		"btn": "تېخىمۇ   كۆپ   چۈشىنىش",
		"text1": "EBC	گۇرۇھى    بىرلەشكەن     دۆلەتلەر    تەشكىلاتى    فوندى   جەمئىيىتىنىڭ   بەزگەكنى   مەغلۇپ    قىلىش    ھەرىكىتىنى   قوللايدۇ<br>بەزگەكنى   مەغلۇپ   قىلغان    بىر   ئەۋلادقا   ئايلىنىپ   ،   باراۋەر   دەۋىرنى   ئورتاق	بەرپا   قىلىش",
		"text2": "EBC	پۈتۈن   دۇنيا    مىقياسىدا    ،   باللارنىڭ   ساغلام   ئۆسۈپ   يىتىلشى   ئۈچۈن   كۈچ    چىقىردۇ",
		"text3": "EBC	ئوكسفورد   ئۇنىۋېرسىتېتى    بىرلەشمە    مۇھاكىمە    يىغىنى ",
		"text3-2": "«ئىقتىسادشۇناسلار   نېمە    ئىش   قىلىدۇ»",
		"text4": "EBC	دۇنيا   قىزلار   ھەربىي   جەمئىيىتى   بىلەن   ھەمكارلىشىپ<br> خانىم   -   قىزلارنىڭ     ساغلام      ئۆسۈپ     يىتىلشى     ئۈچۈن    كۈچ    چىقىرىپ   ، كەلگۈسىدىكى    ئىختىساسلىقلارنى    يىتىشتۈرۈپ   چىقىش",
		"text5": "Together , Let's Build The Future",
		"text6": "EBC	ESG 	ئىدىيىسىنى    پائال    ئەمەلىيەتتىن    ئۆتكۈزۈپ   ،    ئۆسمۈرلەر   خەير - ساخاۋىتى    ۋە  كەسپىي   مائارىپ  ،   ئىختىساسلىقلارنى   يېتىشتۈرۈشكە   داۋاملىق   كۈچ     چىقىرىپلا   قالماي   ،    يەنە    ئىمكانىيەتلىك    سىجىل    تەرەققىيات    مەسئۇلىيىتىنى     ئۈستىگە    ئالدى",
	},
	"esg-nav": {
		"text1": "كەلگۈسىگە   بولغان    ئۈمۈدىمىز",
		"text2": "بىزنىڭ تەدبىرىمىز",
		"text3": "بىزنىڭ قۇرۇلۇشىمىز",
		"text4": "بىز تىرىشچانلىق كۆرسىتىۋاتىمىز",
	},
	"esg-page1": {
		"text1": "كەلگۈسىگە   بولغان    ئۈمۈدىمىز",
		"text2": "مائارىپ   كەلگۈسىگە    يېتەكچىلىك    قىلىدۇ",
		"text3": "بالىلار   مائارىپىنى   مەركەزلەشتۈرۈپ  ،   مائارىپ   شارائىتىنى   ياخشىلاش  ؛  ئالىي 	مەكتەپلەر   بىلەن   ھەمكارلىشىپ  ،   ئېچىۋېتىلگەن  پىكىر   ئالماشتۇرۇش   بوشلۇقى  	بەرپا   قىلىش    ",
		"text4": "باراۋەر   جەمئىيەت   بەرپا   قىلىش",
		"text5": "خەير -  ساخاۋەت   تەشكىلاتلىرىنى   قوللاپ  ،  تېخىمۇ   كۆپ   كىشىلەرنى   باراۋەر  	سەھىيە   ھوقۇقى   بىلەن    تەمىنلەپ   ،    جەمئىيەتنىڭ    باراۋەرلىكىنى    ئىلگىرى     سۈرۈشكە   كۈچ   چىقىرىش	 ",
		"text6": "ئىشچى  -  خىزمەتچىلەرنى    پەخىرلەندۈرۈش",
		"text7": "ئىشچى  -  خىزمەتچىلەرنى   پەخىرلەندۈرىدىغان   ۋە   قىممەت   تۇيغۇسىغا   تولغان 	شىركەت   قۇرۇپ  ،   ئېچىۋېتىلگەن  دۇنيا   قۇرۇشنى   ئىلگىرى   سۈرۈش  ",
		"text8": "يېشىل   ماكان   قۇرۇش",
		"text9": "ئىمكانىيەتلىك   سىجىل   تەرەققىياتنى   شىركەتنىڭ   ئۇزاق   مۇددەتلىك   پىلانىغا  	كىرگۈزۈپ   ،    بايلىقنى   تېجەپ   ،   ئارزۇيىمىزدىكى    يۇرت     ماكاننى     قۇرۇشنى   تەشەببۇس     قىلىش",
	},
	"esg-page2": {
		"text1": "بىزنىڭ   تەدبىرىمىز",
		"text2": "مۇھىت ",
		"text3": "<span>ئىمكانىيەتلىك  سىجىل  بايلىقتىن  پايدىلىنىش ::</span><br>ئىمكانىيەتلىك   سىجىل   ماتىريالنى   ئىشلىتىلىپ  ،   ئەخلەت   قويۇپ  	بېرىشنى   ئازايتىپ  ،   ئايلانما   ئىقتىسادنى   ئىلگىرى   سۈرۈش    ",
		"text4": "<span>كاربوننى   ئازايتىش    سىياسىتى : </span><br>يەر   شارىنىڭ    ئىللىپ   كېتىشنى   ئۆزگەرتىش   ۋەزىپىسىگە   قاتنىشىپ  ،  ھاسىلىي	ئېنېرگىيە   ئىشلىتىدىغان   شىركەتلەر   بىلەن    ھەمكارلىشىش",
		"text5": "جەمئىيەت ",
		"text6": "<span>خەير  -  ساخاۋەت   تەشكىلاتلىرى   بىلەن   ھەمكارلىشىپ : </span><br>خەير  -  ساخاۋەت   ئىئانە   قىلىش  ياكى   پىدائىيلار   مۇلازىمىتى   قاتارلىق   ئۇسۇللار	ئارقىلىق   ،   باراۋەر   جەمئىيەت    بەرپا    قىلىشنى    ئىلگىرى    سۈرۈش ",
		"text7": "<span>ئوخشاش   ئۇقۇمدىكى   ھەمكارلاشقۇچى   سودىگەرلەر   بىلەن   بىرلىشىپ :</span><br>ھەمكارلاشقان    تەرەپنىڭمۇ    مۇھىت    ئاسراش    ۋە   ئىجتىمائىي   مەسئۇلىيەت  	ئۆلچىمىگە   رىئايە   قىلىشىغا    كاپالەتلىك   قىلىپ   ،   جەمئىيەت  	تەرەققىياتىنى   پۈتۈن   كۈچ   بىلەن   ئىلگىرى   سۈرىدۇ",
		"text8": "شىركەتنى   ئىدارە    قىلىش ",
		"text9": "<span>كۆپ   خىل    ۋە  سىغدۇرۇشچان  :</span><br>كۆپ  خىل   ۋە  سىغدۇرۇشچان   خىزمەت   مۇھىتى    يارتىپ  ،  كەمسىتىشكە   قارشى 	تۇرۇپ  ،  پۇرسەتنىڭ   باراۋەر   بولۇشىنى    ئەمەلگە  ئاشۇرۇش  ",
		"text10": "<span>ئىشچى - خىزمەتچىلەرگە   كۆڭۈل   بۈلۈش:</span><br>ئومۇميۈزلۈك   ئىشچى - خىزمەتچىلەر   پاراۋانلىق   پىلانى   ۋە   كەسپىي   تەرەققىيات 	يولىنى   قۇرۇپ   ،   ئىشچى  -  خىزمەتچىلەرنىڭ   مائارىپ   سەۋىيسىنى   ئۆستۈرۈشىنى    ۋە   ماھارەت   ئۈگىنشىنى    قوللايدۇ   ",
	},
	"esg-page3": {
		"text1": "بىزنىڭ   قۇرۇلۇشىمىز",
		"text2": "مائارىپ   فوندى",
		"text3": "EBC	مائارىپنىڭ   جەمئىيەت    تەرەققىياتىدىكى   ئۇل   تېشى   ئىكەنلىكىگە   قەتتىي  ئىشىنىدۇ ",
		"text4": "خەير -  ساخاۋەت   ئورگانلىرى   ۋە   ھەر   قايسى  چوڭ   ئالىي   مەكتەپلەر   بىلەن  	ھەمكارلىشىپ  ،   مائارىپ   يېڭىلىقىنى   ئىلگىرى   سۈردى  . ھەمدە    مەخسۇس   مەبلەغ   تەسىس   قىلىپ  ،   بايلىقى   كەمچىل   رايونلاردىكى   بالىلار  ۋە   جامائەت   مائارىپىغا    ياردەم    بەردى ",
		"text5": "داۋالاش   فوندى",
		"text6": "  ئاق    كۆڭۈل    قەلىپ   ،   دۇنيانى    تىخىمۇ     گۈزەل    قىلىدۇ",
		"text7": " داۋالاش    فوندى    تەسىس    قىلىش   ،    پاراۋانلىق   ئورنىغا    ياردەم    بېرىش	قاتارلىقلار   ،    مۇستەقىللىقىدىن   ئايرىلىپ   قالغان    ياشانغانلارغا  	تەسەللىي    بېرىپ   ،    كېسەلگە    گىرىپتار    بولغان    بالىلارغا    ئۈمىد     نۇرى    ئاتا    قىلدى",
		"text8": "غەمخورلۇق   فوندى ",
		"text9": "شەخسىي   ئائىلە  ،   شىركەتنىڭ   چوڭ   ئائىلسىنى   قولايدۇ",
		"text10": "   بىز   ئائىلىنى  سۆيۈش   مەدەنىيىتىنى   كۆزدە   تۇتۇپ  ،   ئىشچى  -  خىزمەتچىلەرنىڭ    ئاتا  -  ئانا    ،    ئۇرۇق  -  تۇققان    دوسىتلىرىغا     ۋاپادار    ،   كۆيۈمچان     بولۇشى    ئۈچۈن   خىزمەتچىلەرگە    غەمخورلۇق   قىلىش    مەبلىغى   تەسىس    قىلدۇق  ",
		"text11": "ئىئانە   فوندى",
		"text12": "ئاپەتتىن   كېيىن  ،   ھەممە   ئادەم   قايتا    قۇرغۇچى",
		"text13": " دۇنيا   خاراكتېرلىك    يۇقۇملۇق    كېسەللىكلەر    تارقالغاندائىچكى    EBC  ،	مەبلەغ    ئارقىلىق    ،     يۇقۇملۇق    كېسەل    رايوندىكى    خەلق    ۋە    ئائىلىلەرگە	غەمخورلۇق    يەتكۈزدى",
	},
	"esg-page4": {
		"text1": "بىز    تىرىشچانلىق    كۆرسىتىۋاتىمىز",
	}
}