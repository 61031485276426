export default {
  "screen0": {
    "title": "เทรดเดอร์ที่จริงจัง ต้องได้รับบริการที่จริงใจจากเรา",
    "enTitle": "ความไว้วางใจและการนับถือ",
    "btn": "ดูวิดีโอฉบับเต็ม"
  },
  "screen1": {
    "line1": "พาร์ทเนอร์ที่ซื่อสัตย์ของคุณ",
    "line2": "ในโลกใบนี้",
    "line3": "ซื้อขายสินทรัพย์ที่ได้รับความนิยมมากที่สุด",
    "line4": "คำเตือนความเสี่ยง : CFD เป็นเครื่องมือทางการเงินที่ซับซ้อนและมีความเสี่ยงสูงที่จะขาดทุนอย่างรวดเร็วเนื่องจากเลเวอเรจ 75% ของบัญชีนักลงทุนรายย่อยขาดทุนเมื่อใช้เครื่องมือ CFDs ในการซื้อขาย โปรดแน่ใจว่าคุณได้เข้าใจถึงความเสี่ยงนี้และยอมรับที่จะจัดการดูแลความเสี่ยงของตัวเอง",
    "list": [{
      "enTitle": "ฟอเร็กซ์",
      "title": "",
      "desc": "สกุลเงิน 36 คู่ที่นิยมเทรดมากที่สุด ซึ่งรวมถึง คู่สกุลเงินหลัก คู่สกุลเงินรอง และคู่สกุลเงินหายาก ด้วยสเปรดต่ำเป็นพิเศษที่ 0.0 pips",
      "more": "เรียนรู้เพิ่มเติม"
    }, {
      "enTitle": "ดัชนี CFD",
      "title": "",
      "desc": "ดัชนีหุ้นที่สำคัญของโลก",
      "more": "เรียนรู้เพิ่มเติม"
    }, {
      "enTitle": "สินค้าโภคภัณฑ์",
      "title": "",
      "desc": "หากคุณกำลังพิจารณากระจายพอร์ตความเสี่ยงของคุณ สินทรัพย์ที่ปลอดภัย (เช่นโลหะมีค่า) และตลาดพลังงาน (เช่นน้ำมันดิบและก๊าซธรรมชาติ) ให้ถือว่าเป็นตัวเลือกที่เหมาะสมของคุณ",
      "more": "เรียนรู้เพิ่มเติม"
    }, {
      "enTitle": "หุ้น CFD",
      "title": "",
      "desc": "เข้าถึงตลาดแลกเปลี่ยนหุ้นถึง 36 รายการ ทั้งในตลาดที่ผู้บริโภคมีความพร้อมและตลาดที่กำลังจะเกิดใหม่ เมื่อคุณทำการซื้อขายหุ้นแบบ CFD กับ EBC คุณจะได้ลงทุนในราคาตลาดจริง",
      "more": "เรียนรู้เพิ่มเติม"
    }]
  },
  "screen2": {
    "title1": "EBC ถูกควบคุมโดย",
    "title2": "<span>เขตอำนาจศาลทางการเงินชั้นนำของโลกสองแห่ง</span>",
    "desc": "คุณสามารถเพลิดเพลินกับสภาพแวดล้อมการซื้อขาย, <br />ที่มีความสมบูรณ์ ความปลอดภัย และประสิทธิภาพที่มีมาตรฐานสูง",
    "list": [{
      "title": "FCA ในสหราชอาณาจักร",
      "desc": "EBC Financial Group (UK) Ltd ได้รับอนุญาตและควบคุมโดยหน่วยงานทางการเงิน<br />หมายเลขอ้างอิง: 927552<br/>เว็บไซต์：<a href='https://www.ebcfin.co.uk'>www.ebcfin.co.uk</a>"
    }, {
      "title": "ASIC ในออสเตรเลีย",
      "desc": "บริษัท EBC Global Pty Ltd ถูกอนุญาตและควบคุมโดยสำนักงานคณะกรรมการกำกับหลักทรัพย์และการลงทุน ASIC<br /> AFSL:500991"
    }]
  },
  "screen3": {
    "line1": "พาร์ทเนอร์ที่ซื่อสัตย์ของคุณ",
    "line2": "พลังขับเคลื่อน",
    "line3": "สู่ความสำเร็จ",
    "line4": "ความเป็นมืออาชีพและการใช้เทคโนโลยีชั้นนำทำให้เกิดความเป็นไปได้ของการเทรดที่ไม่มีที่สิ้นสุด",
    "line5": "ตั้งอยู่ใน<br /> ลอนดอนLD5; นิวยอร์ก NY4; สิงคโปร์ SG1; โตเกียว TY3; ฮ่องกง HK2",
    "line6": "เซิร์ฟเวอร์ระหว่างภูมิภาค Equinix ได้เชื่อมต่อกับเซิฟเวอร์เฉพาะ",
    "list": [{
        "num": "1000",
        "text": "การประมวลผล 1,000 คำสั่งต่อวินาท"
      },
      {
        "num": "5",
        "unit": "×24",
        "text": "บริการ VIP ตลอด 24 ชั่วโมง 5 วันทำการ"
      },
      {
        "num": "50",
        "unit": "+",
        "text": "มีเครื่องมือสำหรับการเทรดมากกว่า 50 แบบ"
      },
      {
        "num": "20",
        "unit": "+",
        "text": "มีศูนย์ข้อมูล (DC) ที่ประสิทธิภาพสูงอยู่ทั่วโลกมากกว่า 20 แห่ง"
      },
      {
        "num": "100",
        "unit": "+",
        "text": "มีสมาชิกทั่วโลกมากกว่า"
      },
      {
        "num": "98.75",
        "unit": "%",
        "text": "ของเวลาทำงาน"
      },
      {
        "num": "0.0",
        "unit": "PIPS",
        "text": "ค่าสเปรด 0 pip ของ ECN อยู่ในระดับเดียวกันกับธนาคารระดับโลก"
      },
      {
        "btn": "ค้นหาเทคโนโลยีของ EBC"
      }
    ]
  },
  "screen4": {
    "enTitle": "ค้นหาราคาและคำสั่งโอน",
    "title": "",
    "desc": "EBC ใช้ FIX (โปรโตคอลแลกเปลี่ยนข้อมูลทางการเงิน) เพื่อรวบรวมราคาสกุลเงินระหว่างธนาคาร เพื่อให้ลูกค้าเข้าถึงตลาดโดยตรงด้วยสภาพคล่องที่เหมาะสมราคาที่รวบรวมผ่านฮาร์ดแวร์ HUB ที่มีอยู่จริง ทำให้มีค่าสเปรดที่ต่ำกว่าและมีโอกาสเทรดที่ยั่งยืน",
    "line1": "ที่บันทึกข้อมูลการเทรดใน EBC",
    "line2": "การส่งคำสั่งซื้อขาย",
    "line3": "ที่เหมาะสมและแม่นยำ",
    "line4": "ออเดอร์คำสั่งซื้อขายจะได้รับราคาที่ดีกว่าถึง 85%",
    "list": ["ที่บันทึกข้อมูลการเทรด", "แผนที่การกระจายคำสั่งซื้อขาย", "ประสบการณ์ใช้งานนี้เป็นสิ่งยืนยันว่าระบบเก็บข้อมูลการเทรดของ EBC เหนือกว่าระบบจัดการข้อมูลการเทรดทั่วไปในตลาด ทั้งในแง่ของการจัดการราคา การส่งคำสั่งซื้อขายไปยังตลาด และเสถียรภาพของระบบอัลกอริทึ่ม"],
    "rightDesc": "EBC ใช้ FIX (โปรโตคอลแลกเปลี่ยนข้อมูลทางการเงิน) เพื่อรวบรวมราคาสกุลเงินระหว่างธนาคาร เพื่อให้ลูกค้าเข้าถึงตลาดโดยตรงด้วยสภาพคล่องที่เหมาะสมราคาที่รวบรวมผ่านฮาร์ดแวร์ HUB ที่มีอยู่จริง ทำให้มีค่าสเปรดที่ต่ำกว่าและมีโอกาสเทรดที่ยั่งยืน",
    "more": "เรียนรู้เพิ่มเติม",
    "btn": "เริ่มต้นการเทรด"
  },
  "screen5": {
    "btn": "เริ่มต้นการเทรด",
    "title": "ห้องส่วนตัวของ EBC",
    "enTitle": "",
    "desc": "<span> “ไม่มีเกณฑ์ที่เหมือนกันสำหรับการชำระบัญชี</span> ดังนั้นต้องพิจารณาความเป็นไปได้ทั้งหมด”",
    "textList": ["ห้องส่วนตัวสามารถซ่อนข้อมูลการทำธุรกรรมของคุณบางส่วนได้ ซึ่งหมายความว่า “คำสั่งซื้อจำนวนมาก” อาจจะได้ราคาที่ดีกว่า โดยที่ไม่ต้องกังวลถึงความผันผวนของตลาดหรือการถูกปฎิเสธให้วางคำสั่งซื้อขายเพราะ Last Look",
      "หากคุณเป็น “นักลงทุนรายใหญ่” และไม่ต้องการเปิดเผย “ขนาดของคำสั่งซื้อขาย” ของคุณให้กับผู้อื่น EBC Private Room จะเป็นตัวเลือกที่ดีที่สุดของคุณ"
    ],
    "ftTitle": "EBC ในอนาคต",
    "ftDesc": "<i> <span> EBC </span> ให้สภาพคล่องระดับโลกอย่างแท้จริง </i>",
    "ftDesc1": "สำหรับนักลงทุนสถาบัน",
    "ftBtn": "เป็นพาร์ทเนอร์กับเรา",
    "ftList": [{
      "title": "ความปลอดภัย",
      "desc": "การเชื่อมต่อกับศูนย์ Equinix ทั่วโลกผ่านใยแก้วนำแสงของเครือข่ายเพียร์ทูเพียร์ เพื่อให้เกิดการดำเนินการตามคำสั่งที่รวดเร็วมากถึง 20 มิลลิวินาที "
    }, {
      "title": "การยินยอม",
      "desc": "เงินของลูกค้าทั้งหมดถูกเก็บไว้ในบัญชีทรัสต์อิสระที่แยกจากกันกับธนาคารชั้นนำและอยู่ภายใต้กฎระเบียบที่เข้มงวด"
    }, {
      "title": "สภาพคล่อง",
      "desc": "EBC ได้สร้างความสัมพันธ์ความร่วมมือระยะยาวและมั่นคงกับธนาคารระหว่างประเทศชั้นนำของโลก ช่วยให้คุณทำธุรกรรมได้อย่างง่ายดายด้วยต้นทุนที่ต่ำมาก"
    }, {
      "title": "พาร์ทเนอร์"
    }]
  },
  "urlLan":"th"
}
