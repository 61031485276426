export default {
	"faq-class-name": "",
	"faq-title":"幫助中心",
	"faq-desc": "查詢常見問題或直接聯絡客服，EBC客戶支援團隊隨時恭候您的詢問",
	"faq-search": "請輸入搜尋關鍵字",
	"faq-tabs":[
		"註冊與開戶",
		"出入金",
		"交易相關",
		"交易終端",
		"知識類",
	],
	"faq-data":{
		0: [
			{
				"Q": "如何開始在EBC交易？",
				"A": "完成以下步驟，即可在EBC開始交易： 1.註冊EBC帳戶2.認證個人資料3.開設交易帳號並存款4.登入MT4交易終端交易"
			},
			{
				"Q": "可以使用同一個電子郵件信箱註冊多個EBC帳戶嗎?",
				"A": "不可以。一個電子郵件信箱只能註冊一個EBC帳戶，但一個EBC帳戶可以建立多個交易帳號。"
			},
			{
				"Q": "為什麼需要上傳資料進行認證？",
				"A": "作為一家合規並受監管的公司，我們必須根據主管監管機構的規定進行所有業務操作。這包括收集客戶的認證信息，如有效身份證件和最近六個月內的住址證明。"
			},
			{
				"Q": " EBC帳號需要哪些資料驗證文件?",
				"A": "您需要提供有效的身份證件、手機號碼、電子郵件信箱即可完成驗證。"
			},
			{
				"Q": "如何重新提交我的驗證文件資料?",
				"A": "請依照下列步驟重新提交： 1.登入客戶管理後台2.點選「我的帳號」-「檔案管理」 可以刪除已上傳的文件，重新提交驗證文件重新提交後等待審核即可"
			},
			{
				"Q": "我可以更改我的真實帳戶個人資料嗎?",
				"A": "除了實名資訊無法修改外，手機、信箱、銀行卡等資訊都可以進行修改"
			},
			{
				"Q": "我的資金和個人資料安全嗎？",
				"A": "您的資金安全受到嚴格保護。 EBC在全球範圍內受到多個頂級監管機構的監管，並嚴格遵循法律法規。我們將客戶資金分開存放在海外大型銀行的獨立帳戶中，確保您的資金和投資收益得到雙重保障。同時，我們承諾採取所有必要的安全措施來保護您的個人資訊。"
			},
			{
				"Q": "有哪些帳戶類型可以選擇？",
				"A": " EBC提供2種帳戶類型：標準帳戶和專業帳戶。<a href=\"/TradingAccount\">點擊此處查看帳戶比較表</a>。"
			},
			{
				"Q": "如何找到我的交易帳戶帳號?",
				"A": "尋找交易帳號帳號依照下列步驟操作： 1.登入客戶管理後台2.點選「首頁」—「我的帳號」 3.建立帳號後將會顯示在真實帳號/模擬帳號下方"
			},
			{
				"Q": "真實帳戶和模擬交易帳戶有差別嗎?",
				"A": "兩者的交易流程、交易產品和K線走勢是一致的。真實帳戶的操作需承擔實際的財務風險。模擬帳戶則幫助用戶在不涉及真實資金的情況下，學習和理解市場動態。"
			},
			{
				"Q": " EBC辦事處位於哪裡?",
				"A": " EBC在英國倫敦、東京、雪梨、新加坡、香港等金融中心均設有辦公室。"
			},
			{
				"Q": " EBC開戶是否有年齡限制？",
				"A": " EBC開戶年齡為18-65歲（日本地區為18-70歲，更多相關資訊可諮詢客服）。"
			},
			{
				"Q": " EBC不接受來自哪些國家的客戶?",
				"A": "目前暫不支援美國、加拿大、馬來西亞等國家客戶開戶，合規展業地區將不定期更新，具體詳情可諮詢客戶經理。"
			},
			{
				"Q": "如何註銷我的EBC帳戶?",
				"A": "您可以透過聯絡您的帳戶經理獲得協助，或使用註冊時的信箱向cs@ebc.com發送註銷帳戶的申請。客服部門會在收到您的要求後處理。"
			},
			{
				"Q": "我可以改槓桿嗎?",
				"A": "可以。請依照下列步驟操作更改槓桿： 1.登入客戶管理後台2.點選「首頁」-「我的帳戶」-帳號右側「設定」-「修改槓桿」 3.選擇適合的槓桿，點擊「確定」即可修改成功。"
			},
			{
				"Q": "我可以聯絡客服人員做什麼？工作時間是？",
				"A": "我們的客戶支援部門全天候（24/7）隨時準備解答您在EBC交易過程中遇到的任何問題，無論是註冊問題、平台下載，或其他相關問題。您可以透過線上客服或發送電子郵件至cs@ebc.com與我們聯繫。"
			},
			{
				"Q": "如果我需要投訴，投訴程序是什麼？",
				"A": "如果您需要投訴，請先聯絡您的帳戶經理。他們將調查事件並尋求最快的解決方案。如果您希望進一步投訴請發送郵件並附上投訴內容發送至cs@ebc.com"
			}
		],
		1: [
			{
				"Q": "有哪些入金須知?",
				"A": " EBC支援全天候24*7入金，入金操作存款時須在有效時間內完成付款，否則訂單將會自動取消。請使用本人銀行帳戶入金。不同的入金方式可能有不同的限額，請在透過特定通道入金前，仔細查看該通道的相關細則和注意事項。"
			},
			{
				"Q": "有哪些出金須知?",
				"A": "出金時必須使用本人銀行帳戶作為收款帳戶。同時，請注意，最低出金金額為100美元。"
			},
			{
				"Q": "如何進行入金?",
				"A": "請依照下列步驟即可入金： 1.登入客戶管理後台2.選擇入金管道，注意入金的最大和最小限額，並依照步驟提示完成入金。"
			},
			{
				"Q": "我是否可以用他人的銀行卡入金/出金？",
				"A": "EBC不接受第三方付款。所有入金必須來自於您個人名下的帳戶。 （所有入金必須來自於您本人的銀行帳戶。） 如果我們懷疑資金來自第三方，我們保留權利將資金退還給匯款人並暫時凍結您的帳戶餘額，直至完成身份證明和資金來源的驗證。在未驗證的情況下，我們也保留凍結您帳戶餘額的權利，此時您將無法提取帳戶餘額。 EBC不處理向第三人的出金。如遇特殊情況，請與我們聯絡。"
			},
			{
				"Q": "入金的限額是多少?",
				"A": "不同支付管道入金限額不同，請參考通路備註限額資訊。"
			},
			{
				"Q": " EBC收取任何入金或出金手續費嗎?",
				"A": " EBC入金以及出金操作均不收取任何手續費請注意，一些USDT交易服務提供者可能會收取一定的手續費。"
			},
			{
				"Q": "我可以在工作時間以外的時間出入金嗎?",
				"A": "您可以在週末和假日正常提交出入金申請。但請留意，由於週末非工作日，處理您的申請可能會有所延遲，最晚將在下一個工作日（週一）處理到帳。"
			},
			{
				"Q": "出入金需要多久處理?",
				"A": "在正常情況下，工作日的入金處理時間大約為1至15分鐘在工作日下午四點前提交的出金申請，最快可在當天到達。"
			},
			{
				"Q": "可以透過多張銀行卡出入金嗎?",
				"A": "可以，本人名下銀行卡均可"
			},
			{
				"Q": "我可以從仍有未結單的交易帳戶出金嗎?",
				"A": "可以，但請留意帳戶中必須有足夠的資金保障，以避免因出金操作後帳戶遭受爆倉。若風險管制部門判斷出金操作後可能導致帳戶爆倉，將會拒絕執行出金申請，只有在平倉作業完成後，才能進行出金操作。"
			},
			{
				"Q": "出金時可以使用不同於入金的付款方式嗎?",
				"A": "出金時，本金部分必須以與入金相同的方式進行操作，而獲利部分則可選擇其他方式出金。"
			},
			{
				"Q": "為什麼出/入金失敗了?",
				"A": "出/入金失敗可能是由以下幾個原因造成的： 1.使用了非本人的銀行卡進行出入金操作2.銀行卡被凍結或處於非正常狀態。 3.進行轉帳時，銀行資料或出金帳戶資料填寫有誤。如果遇到上述情況，請及時聯絡官方線上客服以獲得協助處理。"
			},
			{
				"Q": "我的同名帳戶之間可以互轉資金嗎?",
				"A": "可以。同名帳戶內轉秒到帳。"
			},
			{
				"Q": "為什麼提交一筆出金後無法提交第二筆？",
				"A": "需等待第一筆出金審核成功後，即可提交第二筆出金。"
			},
			{
				"Q": "為什麼我取消提款後，資金沒有回到我的交易帳戶？",
				"A": "取消提款後資金將自動退回至後台主錢包，將資金從主錢包內轉至交易帳戶即可。"
			}
		],
		2: [
			{
				"Q": "我在EBC可以交易哪些產品?",
				"A": "EBC為客戶提供了大量的資本市場產品，因此您只需要一個交易帳戶就可以創建多元化的投資組合。<a href=\"/tradingproduct\">點擊此處查看完整的產品清單</a>。"
			},
			{
				"Q": "各帳戶類型的最大和最小交易量是多少?",
				"A": " STD帳戶和PRO帳戶的最大交易量是40手，最小交易量因交易品種而異。<a href=\"/TradingAccount\">按此查看帳戶規則</a>。"
			},
			{
				"Q": "什麼是市價成交？什麼是掛單交易？",
				"A": "市價成交，是指訂單立即執行成交，投資者按照當前市場可用的最優價格買進或賣出； 掛單交易，是一種預約執行，投資者輸入預期的買賣價格，當市場價格達到或超過預期價格時，交易系統便會自動執行交易。"
			},
			{
				"Q": "什麼是buy limit，buy stop，sell limit，sell stop？",
				"A": "Buy Limit：當價格下跌至預定較低水準時買入，適合低價買入策略。例如，若預期金價從2000下跌至1980再上漲，可設定1980的Buy Limit，等待低價買進。 Sell Limit：當價格上漲至預定較高水準時賣出，適合高價賣出策略。例如，若預計金價從2000上漲至2020再下跌，可設定2020的Sell Limit，等待高價賣出。 Buy Stop：當價格上漲至預定較高水準時買入，適合追漲策略。例如，若認為金價超過2020才能確認上升趨勢，可設定2020的Buy Stop，等待向上突破後買進。 Sell Stop：當價格下跌至預定較低水準時賣出，適合追跌策略。例如，若認為金價跌破1980才能確認下跌趨勢，可設定1980的Sell Stop，等待下跌破裂後賣出。"
			},
			{
				"Q": "爆倉比例是多少?",
				"A": "爆倉比例為30%。"
			},
			{
				"Q": "如何設定止盈和停損？",
				"A": "在電腦版MT4平台上，設定停損止盈的步驟如下： 1.選擇您想要管理的交易訂單。 2.使用滑鼠右鍵點選該訂單，選擇「修改或刪除訂單」。 3.在出現的視窗中設定「停損價」與「獲利價」 在行動版MT4平台上，設定停損止盈的步驟如下： 1.在「交易」頁面找到您要修改的持倉訂單2 .點擊或長按該訂單，在彈出式選單中選擇“修改” 3.在出現的視窗中設定“止損價”和“獲利價”"
			},
			{
				"Q": "什麼是鎖單?",
				"A": "鎖單是相同品種、相同手數，但相反方向的開倉單。"
			},
			{
				"Q": " EBC提供交易訊號嗎?",
				"A": " EBC不提供交易訊號，但EBC智慧跟單社群進駐眾多優質訊號方，支援AI智慧為您一鍵篩選目標訊號。"
			},
			{
				"Q": "平台是否支援EA或其它智慧交易系統?",
				"A": "支持。綁定交易終端即可使用。"
			}
		],
		3: [
			{
				"Q": "我應該使用哪個交易終端?",
				"A": " EBC支援使用MT4交易終端，<a href=\"/MT4\">點擊此處立即下載</a>。"
			},
			{
				"Q": "我應該使用MT4平台哪個伺服器？",
				"A": " EBC實盤帳戶使用伺服器 EBCFinancialGroupKY-Live，模擬帳戶使用伺服器 EBCFinancialGroupKY-Demo"
			},
			{
				"Q": "為什麼我登入交易終端失敗?",
				"A": "登入交易平台失敗可能由於密碼輸入錯誤或網路連線故障引起。遇到登入問題時，建議重新輸入密碼或檢查網路連線。"
			},
			{
				"Q": "無法登入MT4，為什麼出現了「無效帳號「錯誤?",
				"A": "當出現「無效帳號」提示時，可能是因為輸入了錯誤的伺服器資訊或密碼不正確。透過選擇正確的伺服器並正確輸入密碼，即可解決此問題。"
			},
			{
				"Q": "為什麼會顯示“無連接”?",
				"A": "可能是因為伺服器斷開連線或本地網路出現問題。首先，請確認本地網路是否能正常瀏覽網頁如果網路正常，請嘗試切換到不同的資料中心（DC）伺服器重新連線。"
			},
			{
				"Q": "為什麼「新訂單」按鍵是灰色的?",
				"A": "無法建立新訂單可能有以下原因： 1.帳戶未連線成功或已斷線。 2.使用的是唯讀密碼登錄，這類密碼僅允許查看帳戶，無法進行交易操作。 3.STD帳戶僅能交易有「.s」字尾的品種，而灰色區域內的品種則不可交易。"
			},
			{
				"Q": "如何新增或移除交易品種?",
				"A": " PC端新增品種： 1.登入交易終端，任意位置右鍵點擊。 2.選擇“交易品種”。 3.選取目標代碼組。 4.雙擊加入「市場報價」。 PC端移除品種： 1.右鍵需隱藏品種。 2.選擇“隱藏”。行動端新增品種： 1.登錄，點選右上角「+」。 2.選擇代碼組。 3.點選“+”新增。移動端移除品種： 1.點選左上方筆形按鈕。 2.勾選移除品種，點選刪除。"
			},
			{
				"Q": "如何設定買價線?",
				"A": "PC端： 1.在圖表上任意位置右鍵點選。 2.選擇“屬性”進入顯示設定。 3.勾選「買價線」選項。 iOS： 1.開啟圖表。 2.點擊任意位置後選擇“設定”。 3.勾選「買價線」選項。安卓： 1.進入設定選單。 2.選擇“圖表”。 3.勾選買價線選項以啟用。"
			},
			{
				"Q": "能否更改MT4上設定的時區?",
				"A": "不能"
			},
			{
				"Q": "為什麼我的掛單沒有執行?",
				"A": "掛單未執行可能有以下原因： 1. 帳戶可用預付款不足，不足以開倉； 2. 掛單的價格錯誤，不符合掛單執行標準； 3.可能由重大財經事件發佈時，市場行情劇烈波動，訂單實際成交價格會發生與掛單價格不同的情況。請注意，掛單沒有執行不限於以上原因，需依具體情況而定。"
			},
			{
				"Q": "如何查看所有品種的交易時間?",
				"A": "登入MT4交易終端，滑鼠右鍵點選想要查看的交易品種，點選「規格」即可查看"
			},
			{
				"Q": "為什麼提示“無效SL/TP”錯誤?",
				"A": "提示「無效SL/TP」錯誤可能是以下原因： 1.現價已超過設定的止盈止損點位，無法成交2.STD帳戶掛單價距離最新價需≥50點"
			},
			{
				"Q": "為什麼會出現”市場已關閉”提示?",
				"A": "當市場休市時，則會顯示「市場已關閉」。"
			},
			{
				"Q": "我可以部分平倉嗎（減少持倉手數）？",
				"A": "您可以部分平倉。當您的現有持股大於該商品的最小交易手數要求時，在這種情況下可以部分平倉。"
			},
			{
				"Q": "閉市後我還可以交易嗎？",
				"A": "當市場關閉後您不可以再交易。您只能在開市的時候交易（開倉，平倉，執行和修改訂單）。"
			},
			{
				"Q": "如果我的MT4下線了，需要關閉我開設的所有部位嗎？",
				"A": "如果您關閉MT4交易系統，止盈停損和掛單仍會保留。但是當您關閉MT4交易系統後，您的追蹤停損和EA交易系統將會失效的。"
			},
			{
				"Q": "如何查看我的交易紀錄?",
				"A": "1.登入MT4交易終端2.點選導航「終端」-「帳戶歷史記錄」 3.查看已平倉的交易歷史"
			},
			{
				"Q": "我該如何匯出交易歷史記錄?",
				"A": " 1.登入MT4交易終端，點選“帳戶歷史記錄” 2.點選滑鼠右鍵，選擇“儲存為詳細戶口結單”"
			},
			{
				"Q": " MT4中資金曲線如何看？",
				"A": " 1.開啟MT4中帳戶歷史記錄2.滑鼠右鍵選擇「儲存為詳細戶口結單」 3.再點選儲存的圖表即是資金曲線"
			},
			{
				"Q": "PAMM是什麼，如何使用？",
				"A": " PAMM即百分比分配管理模式，是一種集合外匯交易資金進行管理的形式。投資者可以選擇一個合格的交易員或資金經理，使用自己資金和集合資金進行交易以獲取利潤，並按照約定比例分配利潤。"
			}
		],
		4: [
			{
				"Q": "什麼是衍生性商品交易？",
				"A": "衍生性商品交易市場是目前全球最大的金融交易市場，日均成交量達到約4 兆美元。世界上各貨幣的匯率都是浮動的，並且在交易時以貨幣對的形式出現，例如歐元兌美元(EUR/USD)、 美元兌日圓(USD/JPY)。它表示在貨幣對中，買入一種貨幣的同時賣出另一種貨幣的交易方式。"
			},
			{
				"Q": "什麼是點差？",
				"A": "點差就是某一個交易產品買入價和賣出價之間的差價, 同時也是每一筆交易需要支付的交易成本。"
			},
			{
				"Q": "什麼是槓桿?",
				"A": "槓桿指的是投資人能以小比例的資金，投資更大價值的外匯標的，不需要全款交易。槓桿的高低決定佔用的保證金的大小。"
			},
			{
				"Q": "什麼是保證金（預付款）?",
				"A": "保證金（預付款）是指交易者以槓桿投資的原理，利用資金的一部分來建立頭寸，是維持交易頭寸的抵押金。在市場中，槓桿通常在20-500倍，這使得投資者能以高槓桿活躍地進行交易，當然，保證金制度在成倍放大利潤的同時，也增加了交易風險。舉例：今天趙先生要做等值100,000 美元的交易。透過保證金交易，假設槓桿比例為500:1，趙先生只需要有100,000/500＝200美元，便可以進行此交易。換句話說，也就是資金放大了500 倍，若投資1 萬美元，即可進行500 萬美元的交易。"
			},
			{
				"Q": "如何計算開立訂單所需的保證金（預付款）?",
				"A": "保證金（預付款）=合約量*現價/槓桿*手數"
			},
			{
				"Q": "交易中的餘額、淨值和可用保證金有什麼關係?",
				"A": "淨值=餘額－已用預付款（已佔用的保證金）±浮動盈損"
			},
			{
				"Q": "為什麼會出現負餘額狀況？",
				"A": "當有極端行情出現時，市場無法給予合理的成交價格，導致最終成交虧損額超出帳戶的餘額和保證金，帳戶會出現負餘額的情況。"
			},
			{
				"Q": "保證金（預付款）和可用保證金（可用預付款）之間有何區別?",
				"A": "保證金（預付款）是指經紀商持有的、用於維持持倉訂單的資金。帳戶中剩餘的淨值則稱為可用保證金（可用預付款）。"
			},
			{
				"Q": "市價的滑點是如何發生的?",
				"A": "在市場上，如果可成交數量不足以滿足當前價格的訂單，剩餘部分將以下一個價格成交，這種價格差異被稱為滑點。滑點可能對投資者有利（正滑點）或不利（負滑點），取決於成交價格與預期相比是更好還是更差。"
			},
			{
				"Q": "槓桿對爆倉有什麼影響?",
				"A": "槓桿可以放大投資收益，同時也增加了風險。槓桿越高，所佔用的保證金就會越少，資金的利用率越大，可交易的部位就會越大，當市場波動過大，保證金不充足，就會觸發爆倉。"
			},
			{
				"Q": "什麼是隔夜利息?",
				"A": "隔夜利息是交易者在持倉過夜時賺取或支付的利息，於倫敦時間晚上22點計算。隔夜利息依交易者持有部位的方向及相關貨幣的利率差異而定。例如，如果英鎊利率遠高於日圓，持有GBP/JPY的交易者透過做多可以獲得利息，反之做空則需支付利息。"
			},
			{
				"Q": "如何查詢隔夜利息？",
				"A": "合約的隔夜利息查詢方法如下： 1. 登入MT4 交易終端機； 2. 點選主選單「顯示」 -「交易品種清單」；3. 右鍵想要查看隔夜利息的交易品種，點選「規格」；4. 「賣/買單調期庫存費」 為隔夜利息。 （ps:正隔夜利息會交易商給客戶）"
			},
			{
				"Q": "什麼是股息，對交易有什麼影響",
				"A": "在差價合約（CFD）市場，所謂的「股息」實際上是對持有CFD多頭部位的投資者的利息支付，而持有空頭的投資者則需要支付相應的費用。"
			},
			{
				"Q": "黃金、白銀現貨交易的交易時間？",
				"A": " EBC的現貨黃金、白銀交易時間為：倫敦時間週日23:00 至週五22:00，期間每天的22:00 至23:00 市場會有短暫休市。"
			},
			{
				"Q": "黃金、白銀現貨合約分別等於多少盎司？",
				"A": "黃金現貨合約：1 手或1 張合約= 100 盎司。白銀現貨合約：1 手或1 張合約= 5000 盎司。"
			},
			{
				"Q": "黃金、白銀現貨交易所需的保證金（預付款）如何計算？",
				"A": "假如黃金現價2000美元/盎司，槓桿500倍，交易0.1手，則黃金所需保證金=2000*100/500*0.1=40USD 假如白銀現價23美元/盎司，槓桿500倍，交易0.1手，則黃金所需保證金=23*5000/500*0.1=23USD"
			},
			{
				"Q": "黃金、白銀現貨合約有有效期限嗎？",
				"A": "沒有。只要維持帳戶裡有充足的保證金，您的部位將一直有效，直到您平倉為止。"
			},
			{
				"Q": "布蘭特原油（XBRUSD）和西德州原油（XTIUSD）有何不同？",
				"A": "原油依API重度可分為輕質原油和重質原油，也可依硫含量分為含硫原油和低硫原油。布蘭特原油（XBRUSD）是一種輕質低硫原油，產自北大西洋的幾個油區。西德克薩斯中質油（XTIUSD）相對較輕且含硫量更低，其報價通常較布蘭特原油低。"
			}
		]
	}
}