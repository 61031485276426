export default {
	"cfa1": {
		"title": "真剣に取引をする人に、正面から向き合います",
		"h1": "ハッピー パートナー プログラム",
		"h1wap": "ハッピー パートナー プログラム",
		
		"text": "金融情報、取引に役立つ情報やEBC関連のコンテンツを",
		"text1": "作成されるパートナー様向けの、",
		"text2": "充実した報酬プログラムです",
		"list": ["トップレベルの金融ライセンス","優れた取引環境","迅速な注文執行","潤沢な流動性","100万ドルの取引大会","お得なキャンペーン","取引コミュニティ","メディアサポート"],
		"list1":["テクニカル分析","マーケットコメンテーター","経済指標分析","教育コンテンツ"]
	},
	"cfa2":"诚邀您加入",
	"cfa3":{
		"title":"報酬レベル",
		"tips":"-1",
		"tips1":"概算値",
		"reward_list": [360, 880, 1800, 3200, 5400, 9000, '11000+'],
		"reward_list_m": ['11000+', 9000, 5400, 3200, 1800, 880, 360],
	},
	"cfa4":"EBC研究所",
	"cfa5":"*クリエイターがこのイベントに参加するには、個人的なコンテンツコラムがあり、有意義な市場相場分析と取引知識などの内容のメディアを継続して作成する必要があります。形式は文章、レポート、講習コースなどに限定されません。<br />*クリエイターは、掲載されたコンテンツのオリジナリティを保証しなければならず、他者のオリジナルコンテンツを転載またはコピーしたことが判明した場合、EBCにはクリエイターの参加資格を取り消す権利があります。<br />*契約中の良質なオリジナルコンテンツを発信しているクリエイターは、コンテンツが一定の規模に達した後、追加報酬を申請することができます。<br />*クリエイターの報酬は月毎に決済されます。<br />*お問い合わせEメール：mkt@ebc.com<br />*本プログラムに関しての詳細な情報や質問、エントリーに関してはお問合せEメールに、お気軽にご連絡ください。<br />*契約中のクリエイターがキャンペーン期間中に当社のコンプライアンス要件に違反した場合、当社は当該クリエイターの契約を打ち切り、本キャンペーンにおける報酬の対象者から除外する権利を有します。<br />*解釈の最終的な権限はEBCグループに帰属します。",
	"cfa6":{
		"title":"真剣に取引をする人に、正面から向き合います",
		"titlewap":"お問い合わせEメール：mkt@ebc.com",
		"desc":"EBC Financial Group (UK) Ltd <br />英国金融行動監視機構FCAによって認可および規制されています。<br />規制番号：927552",
		"desc1":"EBC Financial Group (Australia) Pty Ltd<br />オーストラリア証券投資委員会ASICによって認可および規制されている。<br />規制番号：500991"
	},
	"lang": "en",
}