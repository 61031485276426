export default {
    "h1-title": "Công cụ giao dịch",
    "imgUrlLang": "-en",
    "public-btn-text": "Đăng ký ngay",
    screen0: {
        "title": "Tạo một thư viện công cụ để đáp ứng nhu cầu giao dịch của bạn",
    },
    screen1: {
        "title": "Công cụ chỉ số hiển thị chồng",
        "desc": "Công cụ này cho phép nhà đầu tư hiểu rõ sự mạnh mẽ tương đối của các nến trong bất kỳ giai đoạn nào đối với hai loại hàng hóa. Ngoài ra, nó cũng có thể nhận diện tính hiệu phá vỡ có hiệu quả.",
    },
    screen2: {
        "title": "Chỉ báo chuyên nghiệp MACD (đường kép)",
        "desc": "Công cụ chỉ báo MACD này được sử dụng rộng rãi cho cả cổ phiếu và hợp đồng tương lai. Nó mang lại một số ưu điểm so với MACD đi kèm với MT4, khiến nó phù hợp hơn với sở thích và thói quen của các nhà giao dịch.",
    },
    screen3: {
        "title": "Chỉ số thống kê lãi lỗ MTDriver",
        "desc": "Công cụ này có thể giúp nhà đầu tư xác định lãi lỗ trong các thời kỳ khác nhau",
    },
    screen4: {
        "title": "Thông báo thay đổi giá",
        "desc": "Hầu hết các nhà đầu tư có thời gian hạn chế để theo dõi thị trường, và thông báo trên điện thoại di động về thay đổi giá có thể giúp họ nắm bắt xu hướng thị trường mà không phải chịu nhiễu loạn của thị trường.",
    },
    screen5: {
        "title": "Đóng EA bằng một click chuột",
        "desc": "Nút nhanh được đặt trong giao diện biểu đồ, giúp nhà đầu tư dễ dàng cắt lỗ nhanh chóng và thuận tiện hơn khi thị trường trở nên khó đoán",
    },
    screen6: {
        "title": "Tự động khớp lệnh EA",
        "desc": "Đặt EA vào trong thư mục \MQL4\Experts, EA sẽ khớp lệnh vào một thời gian nhất định, cách sử dụng rất đơn giản, người dùng vui lòng tự khám phá.",
    },
    screen7: {
        "title": "Giám sát vốn EA",
        "desc": "Đặt EA vào trong thư mục \MQL4\Experts, giám sát vốn EA, đặt tệp âm thanh cảnh báo bạn muốn vào trong thư mục \Sounds, sau đó điền tên bài nhạc tương ứng vào ô nhạc của EA.",
    }
}