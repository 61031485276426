/*
 * @Descripttion: 
 * @version: 1.0
 * @Author: sanhui
 * @Date: 2021-05-29 18:13:53
 */

import { basename, dirname } from 'path'

const modules = {}
const files = require.context('.', true, /\.js$/)

files.keys().forEach(key => {
    if (key === './index.js') return false
    const name = basename(key, '.js')
    const dir = dirname(key).replace('./', '')
    !modules[dir] && (modules[dir] = {})
    modules[dir][name] = files(key).default
})
export default modules