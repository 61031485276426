export default {
	"fcb-class-name": "en-fcb-box",
	"video-btn": "XEM VIDEO ĐẦY ĐỦ",
	"fcb-page1": {
		"text1": "ĐỐI TÁC CHÍNH THỨC CỦA BARCELONA",
		"text2": "EBC trở thành đối tác tài chính độc quyền của FC Barcelona, bao gồm các dịch vụ ngoại hối, giao dịch, môi giới (bao gồm cả CFD) và dịch vụ tư vấn.",
		"text3": "",
	},
	"fcb-page2": {
		"text1": "Dành riêng cho niềm đam mê",
		"text2": "Mỗi một cuộc gặp gỡ cũng là một thử thách.<br>Vượt qua những con số, vượt qua cả thắng thua.<br>Trên hành trình thúc đẩy sự thay đổi, chúng tôi không đơn độc.<br>Chúng tôi thiết lập các tiêu chuẩn ngành và cách mạng hoá.<br>Chúng tôi hy vọng dùng kiến thức và công nghệ<br>để không quên mục tiêu ban đầu và cống hiến hết mình.",
	},
	"fcb-page3": {
		"text1": "Hơn cả một Câu Lạc Bộ",
		"text2": "Dù bạn có phải là fan bóng đá hay không thì bạn cũng biết đây là một huyền thoại",
		"text3": "FC Barcelona có lịch sử hơn 120 năm và là một trong những câu lạc bộ bóng đá nổi tiếng nhất thế giới, chứng kiến sự toả sáng của các huấn luyện viên và nhiều ngôi sao đẳng cấp thế giới như Maradona, Messi, Ronaldinho và Guardiola. Với phong cách tạo nên sự khác biệt, Barcelona thể hiện bản thân \"hơn cả một câu lạc bộ\"",
		"text4": "Và EBC? Chúng tôi không chỉ là nền tảng giao dịch mà còn cam kết xây dựng một hệ sinh thái tài chính toàn cầu toàn diện, thúc đẩy phát triển kinh tế thế giới.",
	},
	"fcb-page4": {
		"text1": "Tạo ra các tiêu chuẩn mới",
		"text2": "Một đội bóng toàn những cầu thủ ngôi sao, một hệ thống đào tạo nuôi dưỡng đam mê và ước mơ, với vô số danh hiệu minh chứng cho những thành tích đáng tự hào và cam kết tích cực đóng góp cho xã hội, tất cả những điều đó đã giúp FC Barcelona trở thành một tượng đài.",
		"text3": "Sự hợp tác giữa EBC và FC Barcelona là minh chứng cho việc thừa nhận thế mạnh của nhau. EBC giữ cam kết đối xử nghiêm túc với mọi người đam mê giao dịch. Với quy định toàn cầu hàng đầu, hệ thống bảo vệ toàn diện và tầm ảnh hưởng quốc tế rộng rãi khiến EBC trở thành chuẩn mực của ngành. ",
	},
	"fcb-page5": {
		"text1": "Đổi mới công nghệ tạo nên \"Đội bóng trong mơ\"",
		"text2": "Barcelona nổi tiếng thế giới với lối chuyền và kiểm soát độc đáo. Chiến thuật dựa trên kiểm soát bóng, chuyền ngắn và tinh thần đồng đội chứng minh rằng bóng đá có thể giành chiến thắng nhờ công nghệ và trí tuệ chiến thuật, thay vì chỉ dựa vào đối đầu thể lực, điều này đã ảnh hưởng đến sự phát triển của chiến thuật bóng đá hiện đại.",
		"text3": "Tương tự, EBC cũng kiên trì hướng tới đột phá công nghệ, luôn đi đầu trên thị trường quốc tế nhằm xây dựng một hệ sinh thái giao dịch toàn cầu an toàn, chuyên nghiệp, hiệu quả và ổn định cho các nhà đầu tư."
	},
	"fcb-page6": {
		"text1": "Phấn đấu trở thành người xuất sắc để thế giới trở nên tốt đẹp hơn",
		"text2": "Sự tôn trọng, nỗ lực, tham vọng, làm việc tập thể và khiêm nhường là năm giá trị của Barcelona. Barca luôn đề cao các giá trị của sự nỗ lực, tham vọng và tinh thần thúc đẩy các cầu thủ trở thành người xuất sắc nhất, điều này trùng khớp với các giá trị của EBC.",
		"text3": "EBC luôn tuân thủ các nguyên tắc liêm chính và tôn trọng. Chúng tôi trân trọng niềm yêu thích và kỳ vọng của những người đam mê giao dịch, nỗ lực thúc đẩy tính minh bạch trong ngành, thúc đẩy sự phát triển của ngành và góp phần xây dựng một xã hội công bằng.",
		"text4": "Together, Let's Build The Future.",
	},
	"fcb-page7": {
		"text1": "Tin tức liên quan",
	}
}