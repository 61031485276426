export default {
	"official-class-name": "official-en-box",
	"lang": "vn",
	"official-page1": {
		"text1": "Xác minh kênh chính thức",
		"text2": "Để ngăn chặn các bên trái phép thu lợi bằng cách lạm dụng tên của EBC, bạn có thể kiểm tra tính xác thực của chanel thông qua trang này.",
		"text3": "Vui lòng nhập link website đầy đủ",
		"text4": "Xác minh",
	},
	"official-page2": {
		"text1": "Thông cáo chính thức EBC:",
		"text2": "1. EBC hiện tại chỉ có mặt trên nền tảng giao dịch MetaTrader 4 (MT4). Vui lòng tải phần mềm MT4 từ trang web chính thức.",
		"text3": "2. EBC hiện không tham gia vào bất kỳ giao dịch tiền điện tử hay CFD nào liên quan đến tiền điện tử.",
		"text4": "3. EBC không cung cấp bất kỳ hình thức tư vấn đầu tư nào, cũng như không đưa ra bất kỳ cam kết hay đảm bảo về lợi nhuận nào.",
		"text5": "4. Đối với hành vi chiếm đoạt tên tuổi EBC, chúng tôi sẽ có hành động pháp lý và xử lý ngay lập tức.",
		"text6": "5. EBC cam kết bảo vệ quyền lợi và lợi ích của khách hàng. Nếu bạn phát hiện bất kỳ hoạt động đáng ngờ nào, vui lòng gửi bằng chứng cho nhóm Chăm sóc Khách hàng của chúng tôi, hoặc liên hệ thông qua email <a href=\"mailto:cs@ebc.com\" style=\"color: #4D0101;\">cs@ebc.com</a>. Chúng tôi sẽ phản hồi nhanh chóng và cung cấp sự trợ giúp pháp lý cần thiết cho những người bị lừa đảo.",
	},
	"official-page3": {
		"text1": "Thông tin được hỏi được xác minh chính thức",
		"text2": "Trở về",
		"text3": "Thông tin được hỏi chưa được xác minh chính thức, vui lòng không tương tác với trang web chưa được xác minh",
		"text4": "Báo cáo",
		"text5": "Nếu bạn cho rằng thông tin này là sai hoặc bị chiếm dụng, vui lòng gửi báo cáo cho chúng tôi",
		"text6": "Thông tin báo cáo",
		"text7": "URL",
		"text8": "*Nếu bạn cần hỗ trợ pháp lý, vui lòng nhấp ",
		"text8-1": "vào đây và lại tin nhắn",
		"text8-2": "",
		"text9": "Đóng bảng tin",
		"text10": "Họ Tên/Nickname",
		"text11": "Vui lòng nhập Họ Tên/Nickname",
		"text12": "Email",
		"text13": "Vui lòng nhập email của bạn",
		"text14": "Số điện thoại",
		"text15": "Vui lòng nhập số điện thoại của bạn",
		"text16": "Thông tin phản hồi",
		"text17": "Vui lòng nhập thông tin phản hồi",
		"text18": "Mã xác nhận",
		"text19": "Vui lòng nhập mã xác nhận",
		"text20": "Hủy bỏ",
		"text21": "Gửi báo cáo",
		"text22": "Đã gửi, cảm ơn phản hồi của bạn.",
	},
}