export default {
	"screen0": {
		"title": "化繁为简的<br>PAMM解决方案",
		"content": "EBC为寻找专业人士管理账户的投资者以及资金管理者定制了耳目一新的百分比分配管理模式PAMM（Percentage Allocation Management Module），投资者和资金管理者均可登录网页版后台界面实现交易资金管理，利润分配，细节制定，记录下载等全面的功能；网页版界面简洁明了，功能全面，条理清晰。",
		"tips": "EBC为资金管理者，投资者提供了权责清晰，功能全面的百分比分配管理模式（PAMM）；网页版后台简洁清晰，化繁为简。"
	},
	"screen1": {
		"pageEnTitle": "CLEAR DUTIES",
		"pageTitle": "权责清晰",
		"title": "PAMM账户能够满足多方面参与者要求",
		"c1Title": "投资者",
		"c1Desc": "享受一站式交易服务，并分享资金管理者的交易成果。投资者可以自由选择符合自身风险偏好和盈利目标的资金管理者。高水位（high water mark）条款激励资金管理者并保护自身权益。",
		"c2Title": "资金管理者",
		"c2Desc": "拥有丰富交易经验的交易员能够扮演PAMM账户资金管理者的角色，资金管理者可以同时管理多个交易账户，使用自己资金和投资者的集合资金进行交易以获取利润。交易收益或成本的一部分作为账户资金管理者的劳动薪酬。",
		"desc": "投资者：享受一站式交易服务，并分享资金管理者的交易成果。<br>投资者可以自由选择符合自身风险偏好和盈利目标的资金管理者。<br>高水位（high water mark）条款激励资金管理者并保护自身权益。<br><br>资金管理者：拥有丰富交易经验的交易员能够扮演PAMM账户资金管理者的角色，<br>资金管理者可以同时管理多个交易账户，使用自己资金和投资者的集合资金进行交易以获取利润。交易收益或成本的一部分作为账户资金管理者的劳动薪酬。"
	},
	"screen2": {
		"pageEnTitle": "SIMPLE IS THE BEST",
		"pageTitle": "化繁为简",
		"title": "网页版后台简洁清晰，化繁为简",
		"desc": "提供涵盖日常信息管理，交易手数/利润分配，记录下载等全面的解决方案，网页版后台简洁清晰，化繁为简。"
	},
	"screen3": {
		"pageEnTitle": "FEATURES AND FUNCTIONS",
		"pageTitle": "优势",
		"title": "适用于任何类型的交易和资金管理",
		"text1": "多达9种语言",
		"text2": "资金安全交易顺畅",
		"text3": "2种主流利润分配方式（按净值分配及按余额分配）",
		"text4": "高水位条款（high water mark）激励资金管理者保护投资者",
		"text5": "网页版操作界面友好清晰明了",
		"text6": "为资金管理者提供5种回报方式（盈利分成/管理费/管理年费/每手佣金/每单佣金)"
	},
	"imgUrl": "zh",
	"styleName": "en-style",
	"btn-text": "即刻申请PAMM账户"
}