export default {
  "screen0": {
    "title": "過去に学び、未来を見つめる",
    "desc": "EBCの数多くのデータセンターは、世界の主要な10種類以上のアセットクラスをカバーしています",
    "list": [
      "100年間のインフレ調整後の​​主要市場指数",
      "20年以上に及ぶ主要通貨市場の動向",
      "半世紀以上に及ぶ貴金属価格の乱高下",
      "1946年以来の原油価格の上昇と下降",
      "過去23年間の北米天然ガス市場の浮沈"
    ],
    "text": "過去と未来<br />すべてがあなたの手の中に"
  },
  "screen1": {
    "pageEnTitle": "Dow Jones - DJIA - 100 Year Historical Chart",
    "pageTitle": "ダウ平均株価指数（DJIA）100年のヒストリカルチャート",
    "title": "<text style='color:#6c100f'>ダウ平均株価指数（DJIA）</text>の過去100年間のヒストリカルチャート",
    "list": [
      "ヒストリカルデータはインフレ調整済み​​CPIデータを利用し、各データポイントは月末の終値を表します",
      "当月価格は、直近1時間ごとの終値が対象",
      "2020年10月20日現在、ダウ平均株価指数の現在値は28,308.79です"
    ]
  },
  "screen2": {
   "pageEnTitle": "NASDAQ Composite - 45 Year Historical Chart",
    "pageTitle": "過去45年間のナスダック総合指数のヒストリカルチャート",
    "title": "1971年以降の<text style='color:#6c100f'>ナスダック総合指数</text>のヒストリカルチャート",
    "list": [
      "ヒストリカルデータはインフレ調整済み​​CPIデータを使用し、各データポイントは月末の終値を表します",
      "当月価格は、直近1時間ごとの終値が対象",
      "2020年10月20日現在、ナスダック総合指数の現在値は11,516.49です"
    ],
    "text": "CFD取引について詳しく見る",
    "btn": "Lab 研究センター/CFD取引部門"
  },
  "screen3": {
    "pageEnTitle": "EUR USD- Historical Chart",
    "pageTitle": "ユーロから米ドルへの為替レートのヒストリカルチャート",
    "title": "1999年以降の<text style='color:#6c100f'>米ドル（EUR USD）に対するユーロの日次チャート</text>価格動向"
  },
  "screen4": {
    "pageEnTitle": "GBP USD - Historical Chart",
    "pageTitle": "英ポンドから米ドルへの為替レートのヒストリカルチャート",
    "title": "1971年以降<text style='color:#6c100f'>英国ポンドと米ドルの日次チャート</text>価格動向",
    "text": "デリバティブ取引について詳しく見る",
    "btn": "Lab 研究センター/デリバティブ取引部門"
  },
  "screen5": {
    "pageEnTitle": "Gold Price - Last 10 Years",
    "pageTitle": "過去10年間の金価格",
    "title": "過去10年間の<text style='color:#6c100f'>米ドルでの金</text>価格動向",
    "list": [
      "2020年10月19日現在、現在値は1,911.70米ドルです"
    ]
  },
  "screen6": {
    "pageEnTitle": "WTI Crude Oil Prices - 10 Year Daily Chart",
    "pageTitle": "WTI原油価格10年日足チャートトレンド",
    "title": "過去10年間の<text style='color:#6c100f'>WTI原油</text>の1日あたりの終値",
    "list": [
      "表示価格は米ドルです",
      "2020年10月20日現在、WTI原油の現在値は1バレル41.65米ドルです"
    ]
  },
  "screen7": {
    "pageEnTitle": "Natural Gas Prices - Historical Chart",
    "pageTitle": "天然ガス価格ヒストリカルチャート",
    "title": "<text style='color:#6c100f'>ヘンリーハブ天然ガス</text>の過去の価格",
    "list": [
      "表示価格は米ドルです",
      "2020年10月14日現在、天然ガスの現在値は2.02米ドルです"
    ],
    "text": "コモディティ商品について詳しく見る",
    "btn": "Lab 研究センター/コモディティ商品"
  }
}
