import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		Lan: 'zh_CN',
		title: '简体中文',
		khUrl: 'https://client.ebccrm.com/signup',
		loginUrl: 'https://client.ebccrm.com/',
		mnUrl: 'https://client.ebccrm.com/simulation',
		showModal: false,
		suffix: ''
	},
	mutations: {
		setPosition(state, val) {
		  state.Lan = val
		  let parms;
		  if (val === 'zh_TW') {
		    parms = 'tw'
		  } else if (val === 'ja_JP') {
		    parms = 'jp'
		  } else if (val === 'weiwuer') {
		    parms = 'zh'
		  } else if (val === 'zh_CN') {
		    parms = 'zh'
		  } else if (val === 'ko_KR') {
		    parms = 'ko'
		  } else if (val === 'en_US') {
		    parms = 'en'
		  }else if (val === 'th_TH') {
		  	parms = 'th'
		  }else if (val === 'vn') {
			parms = 'vi'
		  }
		  state.khUrl = 'https://client.ebccrm.com/signup?hl=' + parms
		  state.loginUrl = 'https://client.ebccrm.com/?hl=' + parms
		  state.mnUrl = 'https://client.ebccrm.com/simulation?hl=' + parms
		},
		setTitle(state, val) {
		  state.title = val
		},
		setShowModal(state, val) {
		  state.showModal = val
		},
		setSuffix(state, val) {
		  state.suffix = val
		},
	},
	actions: {},
	modules: {},
});

