export default {
    "box-name": "en-style2",
    "public-btn-text": "Đăng ký ngay",
    screen0: {
        title: 'EBC cung cấp thanh khoản toàn cầu<br> thực sự hàng đầu cho các nhà đầu tư tổ chức',
        enTitle: 'EBC THE FUTURE',
        list: [
            {
                title: '安全性',
                desc: 'Kết nối với các trung tâm Equinix trên toàn thế giới thông qua đường dây cáp quang chuyên dụng của mạng ngang hàng để đạt được khả năng thực hiện lệnh cực nhanh, chỉ trong 20 mili giây.'
            },
            {
                title: '合规性',
                desc: 'Tất cả tiền của khách hàng được giữ trong các tài khoản ủy thác độc lập tách biệt với các ngân hàng hàng đầu và tuân theo quy định nghiêm ngặt.'
            },
            {
                title: '流动性',
                desc: 'EBC đã thiết lập mối quan hệ hợp tác lâu dài và ổn định với các ngân hàng quốc tế hàng đầu thế giới, cho phép bạn thực hiện giao dịch dễ dàng với chi phí cực thấp.'
            }
        ]
    },
    screen1: {
        title: 'Độ sâu thanh khoản',
        trList: [
            '层级',
            '买入价',
            '买入的交易量',
            '点差',
            '卖出价',
            '卖出价交易量'
        ],
        mobileTrList: [
            '层级',
            '买入价',
            '点差',
            '卖出价'
        ]
    },
    screen2: {
        pageEnTitle: 'Clearing System',
        pageTitle: 'Hệ thống thanh toán bù trừ',
        title: 'EBC có hệ sinh thái thanh toán bù trừ an toàn, minh bạch và đáng tin cậy'
    },
    screen3: {
        pageEnTitle: 'EBC Priviate Room',
        pageTitle: 'Phòng Riêng EBC',
        title: 'Không có tiêu chí thống nhất cho việc thanh lý và do đó, mọi khả năng đều phải được xem xét.',
        desc: 'Nếu bạn là “tay chơi lớn” và không muốn tiết lộ “đơn hàng lớn” của mình cho người khác thì EBC Private Room sẽ là sự lựa chọn tốt nhất dành cho bạn.'
    },
    screen4: {
        title: '掌舵你的专属流动性<span>轻松驾驭全球市场</span>',
        desc: 'Private Room 可以隐藏您的部分交易信息，意味着您下的“大单”可以在一个更好的价格成交，而不存在严重的滑点或是因最后观望价格（LastLook）而遭到订单拒绝。如图所示，通过Private Room 交易的订单成交价格更优。'
    },
    screen5: {
        it: 'Chỉ có thời gian, cho bạn biết tôi nhiều hơn',
        ct: '*交易中可能会有滑点、有延迟有各种不确定性因素，或许资深交易人士的您不会注意到这些细微之处，但如有任何对您产生的不便，请及时反馈我们。EBC倡导高品质与极客精神，积极拥抱不确定性的未来。力求为客户带来极简、纯净、舒适的交易新体验。',
        title: 'Đáp ứng và vượt quá mong đợi của bạn',
        desc: 'Chúng tôi sẽ tích cực lắng nghe phản hồi của bạn và rất coi trọng việc nâng cao trải nghiệm giao dịch được cá nhân hóa của bạn. Nhóm phát triển EBC luôn cam kết R&D và cải tiến các chức năng và công nghệ mới để tối ưu hóa hơn nữa trải nghiệm giao dịch trực tuyến của khách hàng.',
        btn: 'Tìm hiểu thêm',
    }
}