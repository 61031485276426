export default {
	"fca1": "كۆچمە راسچوت ئىسچوتىنى ئېچىش تەرتىپى FCA",
	"fca1-m": "كۆچمە راسچوت ئىسچوتىنى ئېچىش تەرتىپى FCA",
	"fca2": "EBC Financial Group (UK) Ltd ئەنگلىيە پۇل مۇئامىلە ھەرىكىتىنى نازارەت قىلىپ باشقۇرۇش ئىدارىسى ھوقۇق بەرگەن ۋە نازارەت قىلىپ باشقۇرىدۇ.",
	"fca3": "« (MiFID)ياۋروپا ئىتتىپاقىنىڭ « پۇل مۇئامىلە قوراللىرى بازىرى قانۇن - نىزامى MiFID II ۋە ئۇنىڭ كېيىنكى تۈزىتىلگەن نۇسخىسى خېرىدارلارنىڭ بىلىم سەۋىيەسى ، تەجرىبىسى ۋە ئارتۇقچىلىقىغا ئاساسەن ، مەبلەغ سالغۇچىلارنى « پارچە ساتقۇچىلار » ياكى « كەسپىي مەبلەغ سالغۇچىلار » دەپ ئايرىشقا بولىدۇ.<br><br>  قانۇنىغا ئاساسلانغاندا FCA EBC Financial Group (UK) Ltd FCAپەقەت كەسپىي مەبلەغ سالغۇچى ۋە ئاپپاراتقا مەبلەغ سالغۇچىلارنى قوبۇل قىلىدۇ ، پىشاڭ 100 ھەسسە بولغان  كۆچمە راسچوت ئىسچوتى تەسىس قىلىشقا بولىدۇ ، مەبلەغ سالغۇچىلار مۇستەقىل ۋاكالىتەن باشقۇرۇش - يىلى باكلاي بانكىسى ئايرىش ئىسچوتى.",
    "fca4":{
		"title":"كەسپىي مەبلەغ سالغۇچىلار تۆۋەندىكى ئۈچ ئۆلچەم ئىچىدىكى ئىككىسىنى قاندۇرۇشى كېرەك.",
		"title-m":"كەسپىي مەبلەغ سالغۇچىلار تۆۋەندىكى ئۈچ ئۆلچەم ئىچىدىكى ئىككىسىنى قاندۇرۇشى كېرەك.",
		"desc":"ئۆتكەن بىر يىلدا ئوتتۇرىچە سودا چاستوتىسى پەسىلگە10 قېتىمغا يەتتى",
		"desc1":"500مىڭ ياۋرۇدىن ئاشىدىغان مەبلەغ سېلىش بىرىكمىسى ، مەبلەغ سېلىش بىرىكمىسى نەق پۇل ئامانىتى ۋە پۇل مۇئامىلە قورالىنى ئۆز ئىچىگە ئالىدۇ. ",
		"desc2":"پۇل مۇئامىلە ساھەسىدە خىزمەت قىلغان ياكى ئاز دېگەندە بىر يىل ئىشلەپ ، باھا پەرقى توختامى ياكى ھاسىلىي مەھسۇلات سودىسىنى چۈشىنىش كېرەك."
	},
	"fca5":{
		"title":". كۆچمە راسچوت ئىسچوتىنى ئېچىش تەرتىپى FCA",
		"desc":"ئىلتىماس جەدۋىلىنى چۈشۈرۈش ھەمدە تولدۇرۇش .",
		"desc1":"ماتېرىيال تەييارلاش ۋە ئىسپاتلاش.",
		"desc2":"ماتېرىيال تاپشۇرۇش ھەمدە پوچتا يوللانمىسى ئىلتىماس قىلىش",
		"desc3":"بەلگىلىمىگە ئۇيغۇن تەكشۈرۈش ۋە ھۆججەت ئىمزالاش ",
		"desc4":".ھېساب ئېچىش مۇۋەپپەقىيەتلىك بولدىFCA",
		"btn":"كەسپىي مەبلەغ سالغۇچىلارنىڭ ئىلتىماس جەدۋىلىنى چۈشۈرۈش.",
		"btn1":"ئاپپارات مەبلەغ سالغۇچىلارنىڭ ئىلتىماس جەدۋىلىنى چۈشۈرۈش "
	},
	"fca6":{
		"title":".كۆچمە راسچوت ھېسابى ئېچىش FCA<br>ئەڭ نوپۇزلۇق نازارەت قىلىپ باشقۇرۇش قوغداش ۋە ئەڭ بىخەتەر مەبلەغ كاپالىتى.",
		"title-m":".كۆچمە راسچوت ھېسابى ئېچىش FCA",
		"desc":"ئەنگلىيە پۇل مۇئامىلە بازىرى ھەرىكەت باشقۇرۇش ئىدارىسى.(FCA)",
		"desc1":"ۆچمە ھېسابات ئېنىقلاش ئىسچوتىدىن مەسلىھەت سوراش پوچتا ساندۇقىFCA"
	},
	"lang":"cn"
}

