export default {
	"screen0": {
		"enTitle1": "Together",
		"enTitle2": "Let's <text style='color:#800'>Build The Future.</text>",
		"title1": "Hợp tác với chúng tôi để mở khóa <br>",
		"title2": "nhiều cơ hội hơn ở các thị trường",
		"list": ["国际权威监管", "全球开放合作", "全套技术解决方案"],
		"desc": "EBC coi trọng mọi đối tác có quan hệ hợp tác sâu rộng với chúng tôi. Hệ thống thưởng đa cấp hàng đầu trong ngành mở ra những khả năng không giới hạn cho bạn. Chúng tôi cung cấp các giải pháp hỗ trợ dịch vụ đa dạng nhằm đảm bảo toàn diện cho sự hợp tác.",
		"links": [{
			"url": "/about-us",
			"text": "Why EBC?"
		}, {
			"url": "",
			"text": "tìm hiểu thêm"
		}],
		"more": "tìm hiểu thêm"
	},
	"screen1": {
		"pageEnTitle": "Customization",
		"pageTitle": "tùy biến",
		"title": "Chương trình hỗ trợ Nhà môi giới giới thiệu (IB) Triệu đô la",
		"desc": "Vì EBC không thể đạt được sự phát triển lâu dài nếu không có sự hỗ trợ của IB, chúng tôi cung cấp triển vọng phát triển hợp lý cho IB hùng mạnh.",
		"list": ["Cơ cấu hoa hồng", "Tiếp thị đổi mới & tài nguyên giao dịch", "Thủ tục giải quyết và thanh toán hiệu quả", "Quản lý khách hàng độc quyền", "Hệ thống hoa hồng đa cấp", "Nhóm hỗ trợ khách hàng đa ngôn ngữ", "Công cụ hỗ trợ và báo cáo hoàn hảo", "kế hoạch phát triển kinh doanh"],
		"text": "全方位<span>定制</span>发展规划",
		"more": "查看更多服务"
	},
	"screen2": {
		"pageEnTitle": "Trading  service",
		"pageTitle": "Dịch vụ Thương mại",
		"title": "Một bộ hoàn chỉnh của chế độ hợp tác phát triển kinh doanh",
		"desc": "Vui lòng cho chúng tôi biết nhu cầu của bạn và EBC sẽ không chỉ cung cấp cho bạn kinh nghiệm và đề xuất thực tế mà còn cung cấp các giải pháp kỹ thuật tùy chỉnh dựa trên nhu cầu của bạn để đáp ứng các yêu cầu trong các giai đoạn phát triển kinh doanh khác nhau.",
		"list": ["Cộng đồng giao dịch", "Tín hiệu giao dịch", "Quản lý kiểm soát rủi ro"],
		"btn": "进入交易社区"
	},
	"screen3": {
		"pageEnTitle": "Liquidity service",
		"pageTitle": "Dịch vụ thanh khoản",
		"title": "EBC áp dụng mô hình Xử lý Trực tiếp (STP)",
		"desc": "Nhận quyền truy cập trực tiếp vào các nhà cung cấp thanh khoản quốc tế hàng đầu để giảm chi phí phòng ngừa rủi ro cho nhà môi giới và đảm bảo thực hiện các đơn đặt hàng nhanh chóng, đồng thời chúng tôi cũng có một nhóm kỹ thuật chuyên dụng để cung cấp dịch vụ hỗ trợ kỹ thuật 24*5.",
		"remark": "唯有时间，<span>让您</span>，更了解我。"
	},
	"screen4": {
		"pageEnTitle": "Liquidity service",
		"pageTitle": "Hợp tác kinh doanh mới nổi",
		"title": "Hợp tác trong các mô hình kinh doanh mới nổi",
		"desc": "EBC luôn có niềm đam mê và khả năng nắm bắt những thay đổi. Đối mặt với các mô hình phát triển mới trong ngành, chúng tôi đang tiếp tục thực hiện những đổi mới và thay đổi vì chúng tôi tin rằng chỉ bằng cách đi theo xu hướng phát triển của thị trường, chúng tôi mới có thể tiến lên một cách vững chắc. Nếu bạn có bất kỳ kế hoạch hợp tác sáng tạo nào, vui lòng liên hệ với chúng tôi.",
		"list": ["<span>4</span> loại tài sản", "Hơn 100 công cụ giao dịch"]
	},
	"box-class": "partner-box-en"
}

