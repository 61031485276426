export default {
  "screen0": {
    "title": "단순화된 <br>PAMM 솔루션",
    "content": "EBC는 전문적인 계정 관리를 원하는 투자자와 펀드 매니저를 위해 새로운 퍼센트 할당 관리 모듈 관리자PAMM(Percentage Allocation Management Module)를 제공합니다.  투자자와 펀드 매니저는 웹 버전 백오피스에 로그인하여 거래 자금 관리, 수익배분, 상세포맷, 매매 기록 다운로드 등의 포괄적인 기능을 이용할 수 있으며, 웹 버전 인터페이스는 명확한 레이아웃과 포괄적인 기능으로 사용자 친화적입니다.",
    "tips":"EBC는 펀드 매니저와 투자자에게 명확한 권리와 책임과 포괄적인 기능을 갖춘 백분율 할당 관리 모델(PAMM)을 제공합니다. 웹 버전의 배경은 간결하고 명확하여 복잡성을 단순화합니다."
  },
  "screen1": {
    "pageEnTitle": "CLEAR DUTIES",
    "pageTitle": "명확한 권력과 책임",
    "c1Title": "투자자",
		"c1Desc": "원스톱 거래 서비스를 누리며 펀드 매니저와의 거래 성과를 공유하세요. 투자자는 자신의 위험 선호도와 수익 목표를 충족하는 펀드 매니저를 자유롭게 선택할 수 있습니다. HWM은 펀드 매니저에게 인센티브를 제공하고 그들의 권리를 보호합니다.",
		"c2Title": "펀드 매니저",
		"c2Desc": "풍부한 거래 경험을 가진 트레이더는 PAMM 계정 펀드 매니저의 자격을 가질 수 있습니다. 펀드 매니저는 자신의 자금과 투자자의 공동 자금을 활용하여 거래 및 수익 창출을 위해 다수의 매매 계정을 동시에 관리할 수 있습니다. 거래 수익 또는 비용의 일부는 계정 펀드 매니저의 서비스의 보수로 사용됩니다.",
    "title": "PAMM 계정은 많은 참가자의 요구 사항을 충족할 수 있습니다.",
    "desc": "투자자: 원스톱 거래 서비스를 즐기고 펀드 매니저의 거래 결과를 공유하십시오. <br>투자자는 자신의 위험 선호도와 수익 목표를 충족하는 펀드 매니저를 자유롭게 선택할 수 있습니다. <br>높은 수위 표시 조항은 자금 관리자에게 인센티브를 제공하고 그들의 권리를 보호합니다. <br><br>펀드 매니저: 풍부한 거래 경험을 가진 트레이더가 PAMM 계정 펀드 매니저의 역할을 할 수 있습니다.<br>펀드 매니저는 자신의 자금과 투자자의 공동 자금을 사용하여 여러 거래 계정을 동시에 관리할 수 있습니다. 이익. 거래 이익 또는 비용의 일부를 계정 펀드 매니저에 대한 노동 보상으로 제공합니다."
  },
  "screen2": {
    "pageEnTitle": "SIMPLE IS THE BEST",
    "pageTitle": "단순화",
    "title": "웹 버전의 배경은 간결하고 명확하여 복잡한 것을 단순화합니다.",
    "desc": "일일 정보 관리, 거래 건수/이익 분배, 기록 다운로드 등을 포괄하는 종합 솔루션을 제공하며, 웹 버전 백오피스는 간결하고 명확하여 복잡성을 단순화했습니다."
  },
  "screen3": {
    "pageEnTitle": "FEATURES AND FUNCTIONS",
    "pageTitle": "PAMM 기능",
    "title": "모든 유형의 거래 및 자금 관리에 적합",
    "text1":"최대9개 언어",
    "text2":"자금 안전 및 원활한 거래",
    "text3":"2주류 수익 배분 방식 (순가치 배분, 잔고 배분)",
    "text4":"펀드 매니저의 투자자 보호를 위한 HWM(high water mark) 인센티브",
    "text5":"명확하고 직관적인 사용자 친화적 인터페이스의 웹 버전",
    "text6":"펀드매니저를 위한 5가지 수익방식 제공(이익분배/관리 수수료/연간관리비/로트당 수수료/주문당 수수료)"
  },
	"imgUrl": "en",
	"styleName": "en-style en-style2",
	"btn-text": "PAMM 계정 즉시 신청"
}
