export default {
	"esg-class-name": "",
	"esg-banner": {
		"btn": "了解更多",
		"text1": "EBC集团支持联合国基金会打败疟疾全球运动<br>成为打败疟疾的一代 共创平等新世代",
		"text2": "赋能儿童成长<br>  EBC在全球行动",
		"text3": "EBC 联合牛津大学研讨会",
		"text3-2": "“经济学家都干了什么”",
		"text4": "EBC携手世界女童军协会<br>赋能女性成长 培养明日领袖",
		"text5": "Together , Let's Build The Future",
		"text6": "EBC积极践行ESG理念，不仅持续致力于儿童慈善与专业教育、人才培养，同时肩负起可持续发展的责任。"
	},
	"esg-nav": {
		"text1": "我们的愿景",
		"text2": "我们的措施",
		"text3": "我们的建设",
		"text4": "我们在行动",
	},
	"esg-page1": {
		"text1": "我们的愿景",
		"text2": "教育引领未来",
		"text3": "聚焦儿童教育，改善教育条件；与高等学府合作，构建开放交流空间",
		"text4": "共建平等社会",
		"text5": "支持慈善组织，赋予更多人平等的卫生权利，倾力推动社会平等",
		"text6": "让员工引以为傲",
		"text7": "打造让员工感到骄傲与充满价值感的公司，推动建立开放包容的世界",
		"text8": "建设绿色家园",
		"text9": "将可持续发展纳入公司的长期规划中，倡导节约资源，建设理想家园",
	},
	"esg-page2": {
		"text1": "我们的措施",
		"text2": "环境",
		"text3": "<span>可持续资源使用：</span><br>采用可持续的包装材料，减少垃圾排放，促进循环经济。",
		"text4": "<span>碳足迹减少政策：</span><br>参与改变全球变暖任务，与使用可再生能源的公司建立合作。",
		"text5": "社会",
		"text6": "<span>联结慈善组织：</span><br>通过慈善捐赠或志愿服务等方式，推动构建平等包容的社会。",
		"text7": "<span>联合同频合作商：</span><br>确保合作伙伴遵守环境保护和社会责任的标准，全力推动社会发展。",
		"text8": "公司治理",
		"text9": "<span>多样性与包容：</span><br>提供多样性和包容性的工作场合，反对歧视，实现机会均等。",
		"text10": "<span>员工关怀：</span><br>建立全面的员工福利计划和职业发展路径，支持员工教育和技能提升。",
	},
	"esg-page3": {
		"text1": "我们的建设",
		"text2": "教育基金",
		"text3": "EBC深信教育是社会进步的基石",
		"text4": "与慈善机构及各大高校建立合作，推动教育革新。内设专项资金，资助资源贫乏地区的儿童及公共教育。",
		"text5": "医疗基金",
		"text6": "一颗善意之心，让美好发生",
		"text7": "内设医疗基金，资助福利院等，为失独老人提供慰藉之手，为患病儿童送去希望之光。",
		"text8": "关爱基金",
		"text9": "个人家庭，支撑公司大“家”",
		"text10": "我们推崇爱家文化，内设员工关怀资金，用于协助员工孝敬父母，友爱兄弟姐妹。",
		"text11": "捐赠基金",
		"text12": "灾难之后，每个人都是重建者",
		"text13": "在全球性的流行病爆发时，EBC通过内部资金，为疫区人民及家庭送去关怀。",
	},
	"esg-page4": {
		"text1": "我们在行动",
	}
}