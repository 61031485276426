export default {
  "box-name": "",
  "public-btn-text1": "今すぐ取引",
  "public-btn-text2": "取引商品一覧はこちら",
  screen0: {
    title: "CFD配当金",
    desc: `CFD配当金は、MT4アカウントの残高に"Dividend"と注記され、入金または出金として付与されます。`,
  },
  screen1: {
    title: "最大レバレッジと証拠金率",
  },
  screen2: {
    title: "金融市場に簡単アクセス",
  },
  指数差价合约与股息调整: "株価指数CFDと配当金調整",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "個別株CFDおよび株価指数CFD取引における配当金調整と分配の仕組みについて",
  了解指数差价合约: "株価指数CFDについて",
  指数差价合约: "株価指数CFD",
  指数差价合约允许投资者通过股票市场指数的价格波动进行交易:
    "株価指数CFDは、投資家が実物資産を保有することなく、株式市場の指数の価格変動を通じて取引することを可能にします。S&P500、ダウ・ジョーンズ、ドイツDAXなど、世界のあらゆる株価指数に対応しており、柔軟な取引オプションを提供します。",
  股息调整: "配当金",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "配当金とは、上場企業が株主に対して利益や内部留保から資金を定期的に分配するお金のことです。 配当金を受取る場合、その配当金に応じて原資産と株価指数CFD商品も調整されます。",
  除息日与交易优化: "配当落ち日",
  指数差价合约的除息日是由指数提供商根据成分股的除息日设定的:
    "株価指数CFDの配当落ち日は、構成銘柄の配当落ち日に基づいて指数プロバイダーによって設定され、ロング保有銘柄は配当金を受け取り、ショート保有銘柄は配当金を支払う必要があります。 この配当落ち日を事前に知ることは、利回り管理の最適化に役立ちます。",
  股息调整明细: " 配当金一覧",
  差价合约交易中相关产品的股息数据:
    "CFD取引における原資産の配当金は、買いと売りに分けて表示されます。",
  即刻交易: "今すぐ取引をする",
  股息的常见问题: "配当金に関するよくある質問",
  什么是股息和除息日: "1. 配当金と配当落ち日とは",
  股息是上市公司从利润或保留资金中定期分配给股东的资金answer:
    "配当金とは、上場企業が株主に対して利益や内部留保から資金を定期的に分配するお金のことです。 配当金を受取る場合、その配当金に応じて原資産と株価指数CFD商品も調整されます。<br /><br />株価指数CFDの配当落ち日（配当受払日でもある）は、構成銘柄の配当落ち日に基づいて指数プロバイダーが設定したものです。",
  股息调整的时间: "2. 配当金受払のタイミング",
  指数差价合约股息调整通常会在除息日的answer:
    "配当金は一般的に、配当落ち日の日本時間午前11時から午後12時に入金または出金されます。MT4/MT5取引口座には「Dividend」と表示されます。",
  股息对指数交易的影响: "3. 保有ポジションと配当金の関係",
  持有多头仓位answer:
    "ロング（買）ポジションを保有している場合：配当金はMT4/MT5アカウントに入金されます。<br /><br />ショート（売）ポジションを保有している場合：配当金はMT4/MT5アカウントから出金されます。",

  股息调整的计算公式: "4. 配当金の計算方法",
  多头仓位answer:
    "ロング（買）ポジションの場合：取引ロット数 x コントラクトサイズ x 配当（買）額（決済通貨建てでの計算となります）<br /><br />ショート（売）ポジションの場合：取引ロット数 x コントラクトサイズ x 配当（売）額（決済通貨建てでの計算となります）",
  股息调整计算示例: "5.配当金の計算例",
  以SPXUSD为例answer: `取引銘柄がSPXUSD、配当金受取日が2024年10月11日、配当（買）額が0.050 USD、配当（売）額が-0.050 USDのケースを考えます。<br /><br />
配当受取日前日（10月10日）に1ロットのロングポジションを保有している場合、配当金は以下の計算式によって求められます。<br /><br />

配当金額 = 1（ロット）x 100（コントラクトサイズ）x 0.050（配当（買）額）= 5 USD
<br /><br />
配当金は日本時間午前11時から午後12時のあいだにMT4/MT5アカウントに配当金（Dividend）と注記され入金されます。<br /><br />

同様のケースでショートポジションを保有していた場合、5ドルが配当金（Dividend）と注記され、MT4/MT5アカウントから出金されます。`,
  注意事项: "6.注意事項",
  股息调整仅适用于指数和股票差价合约交易answer: `CFD配当金は株価指数および個別株CFDにのみ適用されます。また保有ポジションにより配当金額が残高へ入金または出金されます。配当金額は各取引銘柄や市場動向により変動します。<br /><br />
ご不明な点がございましたら、弊社カスタマーサポートまでお問い合わせいただくか、弊社ウェブサイトをご覧ください。`,
  list: [
    "Trading Products",
    "Long Dividend",
    "Short Dividend",
    "EX-Dividend Day",
  ],
};
