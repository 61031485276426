import config from '@/config/index.js'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import requireFn from '@/serve'
import getSeoInfo from '../js/tools.js'
export default {
	install(Vue) {
		Vue.prototype.$config = config;
		Vue.prototype.$http = requireFn;
		Vue.prototype.$getSeoInfo = getSeoInfo
		// 自动导入组件
		const requireComponent = require.context(
			// 其组件目录的相对路径
			'../../components',
			// 是否查询其子目录
			true,
			// 匹配基础组件文件名的正则表达式
			/\w+\.vue$/
		)

		requireComponent.keys().forEach(fileName => {
			// 获取组件配置
			const componentConfig = requireComponent(fileName)

			// 获取组件的 PascalCase 命名
			const componentName = upperFirst(
				camelCase(
					// 获取和目录深度无关的文件名
					fileName
					.split('/')
					.pop()
					.replace(/\.\w+$/, '')
				)
			)

			// 全局注册组件
			Vue.component(
				componentName,
				// 如果这个组件选项是通过 `export default` 导出的，
				// 那么就会优先使用 `.default`，
				// 否则回退到使用模块的根。
				componentConfig.default || componentConfig
			)
		})
	}
}