export default{
  "srceen0": {
    "title": "槓桿與保證金",
    "subTitle": "界定<span>交易方式</span>的關鍵",
    "desc": "鑑於金融市場的不確定性和潛在的巨幅波動，出於保護客戶交易安全的考量，EBC目前提供的最大槓桿為 500:1",
    "rateTitle": "杠杆率高達",
    "rateNum": "500:1",
    "rateDesc": "保證金比例0.20%"
  },
  "srceen1": {
    "pageEnTitle": "Secure Transaction",
    "pageTitle": "安全交易",
    "subTitle": "選擇適合的杠杆比例",
    "desc1": "請謹慎考慮您的投資目標及風險承受能力,選擇適合的杠杆比例。",
    "desc2": "為了更好地實現風險管控，請參考下表，了解每項資產的保證金要求和最大槓桿",
    "btn": "開始投資",
    "title":""
  }
}
