export default {
  "screen0": {
    "title": "역사를 거울로 삼고 미래를 내다봅시다",
    "desc": "10 개 이상의 글로벌 주류 자산 클래스를 포함하는 EBC 다차원 데이터 센터",
    "list": [
      "100년 인플레이션 조정을 거친 후의 주요 시장지수",
      "20년 이상의 주요 화폐시장 변화",
      "반세기 동안 귀금속의 변화",
      "1946년부터 원유의 과거 등락 변화",
      "지난 23 년간 북미 천연 가스 시장의 기복"
    ],
    "text": "과거 와 미래<br/>모든 것 이 당신 의 손아귀 에 있 습 니 다."
  },
  "screen1": {
    "pageEnTitle": "Dow Jones - DJIA - 100 Year Historical Chart",
    "pageTitle": "Dow Jones Index (DJIA) 100 년 역사 차트",
    "title": "<text style='color:#6c100f'>DJIA (Dow Jones Industrial Average)</text> 지수 지난 100 년 동안의 기록 차트",
    "list": [
      "과거 데이터는 인플레이션 조정 CPI 데이터를 사용하며 각 데이터 포인트는 월말의 종가를 나타냅니다.",
      "당월 가격은 최신 시간당 종가가 적용됩니다.",
      "2020 년 10 월 20 일 현재 Dow Jones Industrial Average의 현재 가격은 28,308.79입니다."
    ]
  },
  "screen2": {
    "pageEnTitle": "NASDAQ Composite - 45 Year Historical Chart",
    "pageTitle": "지난 45 년 동안 나스닥 종합 지수의 역사적 차트",
    "title": "1971 년 이후<text style='color:#6c100f'>Nasdaq 종합 지수</text>의 과거 차트",
    "list": [
      "과거 데이터는 인플레이션 조정 CPI 데이터를 사용하며 각 데이터 포인트는 월말의 종가를 나타냅니다.",
      "당월 가격은 최신 시간당 종가가 적용됩니다.",
      "2020 년 10 월 20 일 현재 Nasdaq 종합 지수의 현재 가격은 11,516.49입니다."
    ],
    "text": "주식 가격차 계약을 이해하다",
    "btn": "Lab 학습 센터/외환 분야"
  },
  "screen3": {
    "pageEnTitle": "EUR USD- Historical Chart",
    "pageTitle": "유로에서 미국 달러 환율 차트",
    "title": "<text style='color:#6c100f'>1999 년 이후 미국 달러 (EUR USD)에 대한 유로의 일일 차트 </text>가격 추세"
  },
  "screen4": {
    "pageEnTitle": "GBP USD - Historical Chart",
    "pageTitle": "영국 파운드-미국 달러 환율 기록 그래프",
    "title": "1971 년 이후<text style='color:#6c100f'>미국 달러 일일 차트 대비 영국 파운드</text>가격 추세",
    "text": "외환 거래 를 알아보다",
    "btn": "Lab 학습 센터/외환 분야"
  },
  "screen5": {
    "pageEnTitle": "Gold Price - Last 10 Years",
    "pageTitle": "지난 10 년간 금 가격",
    "title": "지난 10 년 동안 <text style='color:#6c100f'>달러 표시 금</text>의 가격 추세",
    "list": [
      "2020 년 10 월 19 일 현재 가격은 $ 1,911.70입니다."
    ]
  },
  "screen6": {
    "pageEnTitle": "WTI Crude Oil Prices - 10 Year Daily Chart",
    "pageTitle": "WIT 원유 가격 10 년 일간 차트 추이",
    "title": "지난 10 년간<text style='color:#6c100f'>Recy Texas Intermediate 원유</text>의 일일 종가",
    "list": [
      "표시된 가격은 미국 달러입니다.",
      "2020 년 10 월 20 일 현재 WTI 원유 가격은 배럴당 41.65 달러입니다."
    ]
  },
  "screen7": {
   "pageEnTitle": "Natural Gas Prices - Historical Chart",
    "pageTitle": "천연 가스 가격 이력 차트",
    "title": "<text style='color:#6c100f'>헨리 허브의 천연 가스</text>역사적 가격",
    "list": [
      "표시된 가격은 미국 달러입니다.",
      "2020 년 10 월 14 일 현재 천연 가스 가격은 $ 2.02입니다."
    ],
    "text": "대량의 상품을 이해하다.",
    "btn": "Lab 학습 센터/외환 분야"
  }
}
