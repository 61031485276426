export default {
	"leftNav": [{ "id": "#Trading", "name": "سودا" },
		{ "id": "#Regulation", "name": "قوش نازارەت" },
		{ "id": "#Security", "name": "بىخەتەرلىك" },
		{ "id": "#Fintech", "name": "پەن-تېخنىكا" },
		{ "id": "#Black-Box", "name": "سودا قارا قۇتىسى" },
		{ "id": "#Private-Room", "name": "سودا بوشلۇقى" },
		{ "id": "#Liquidity", "name": "سۇيۇقلۇق" },
		{ "id": "#Honor", "name": "شەرەپ		" },
		//{ "id": "#News", "name": "خەۋەر" }
	],
	"pages1": {
		"h1": "ئىشىنىش &ھۆرمەت قىلىش",
		"title": "ئەستايىدىل سودىلاشقان ھەر بىر ئادەم ئەستايىدىل مۇئامىلە قىلىشقا ئەرزىيدۇ。",
		"btn": " مۇكەممەل ۋىدىئو كۆرۈش "
	},
	"pages2": {
		"title": "\u202Eسىز ئۈچۈن لاھيلەنگەن مەخسۇس ئېقشچانلىق",
		"title1": "\u202Eپۇرسەت ۋە رىقابەت ئۈستۈنلۈكىگە تولغان دۇنيا ئۈستىدە ئىزدىنىش",
		"btn": " \u202Eتېخىمۇ كۆپ چۈشىنىش ",
		"text": " تاشقى پېرېۋوت ",
		"text1": " كۆپ مىقدارلىق تاۋار ",
		"text2": " ﻧﻪق ﻣﺎل ﻛﯚرﺳﻪﺗﻜﯜﭼﻰ ﭘﻪرق ",
		"text3": " پاي چېكى باھا پەرقى توختامى "
	},
	"pages3": {
		"title": "يەر شارى بويىچە ئەڭ يۇقىرى دەرىجىلىك نازارەت قىلىپ باشقۇرۇش",
		"title1": "EBC Financial Group ئەۋلادلىرىنىڭ ئورۇنلۇق جايلاشقان ئورۇنلىرىدا تۈزۈمگە ماسلاشتۇرۇش ۋە رۇخسەتنامە بار.",
		"btn": "تېخىمۇ كۆپ چۈشىنىش",
		"text": "نازارەت قىلىپ باشقۇرۇش FCA ئەنگىليە",
		"text1": "EBC Financial Group (UK) Ltd ئەنگلىيە پۇل مۇئامىلە ھەرىكىتىنى نازارەت قىلىپ باشقۇرۇش ئىدارىسى ھوقۇق بەرگەن ۋە نازارەت قىلىپ باشقۇرىدۇ نازارەت قىلىپ باشقۇرۇش نومۇرى: 927552",
		"text2": "نازارەت قىلىپ باشقۇرۇشASIC ئاۋســترالىیه",
		"text3": "EBC Financial Group (Australia) Pty Ltd ئاۋسترالىيە ئاكسىيە ۋە مەبلەغ سېلىش كومىتېتى ھوقۇق بېرىدۇ ۋە نازارەت قىلىپ باشقۇرىدۇ نازارەت قىلىپ باشقۇرۇش نومۇرى: 500991",
		"text4": "كايمان CIMA نازارەت قىلىپ باشقۇرۇش",
		"text5": "EBC Financial Group (Cayman) Ltd كايمان تاقىم ئارىلى پۇل باشقۇرۇش ئورگىنى ھوقۇق بەرگەن ۋە نازارەت قىلغان (CIMA) نازارەت نومۇرى:2038223		"
	},
	"pages4": {
		"title": " ئەلالايدۇ  سودىسىڭىز تېخنىكا-پەن",
		"title1": "كەسىپتە ئالدىنقى قاتاردا تۇرىدىغان پۇل مۇئامىلە پەن-تېخنىكىسى سودىنى چەكسىز ئىمكانىيەتكە ئىگە قىلدى.",
		"btn": " تېخىمۇ كۆپ چۈشىنىش",
		"text": " سېكۇنتلۇق ئەڭ يۇقىرى پولىمىرلىق زاكاز تالونى ",
		"text1": " ھەددىدىن زىيادە بۇيرۇق ئىجرا قىلىش ",
		"text2": " سانلىق مەلۇمات يوللاش مۇقىملىقى يۇقىرى بولدى ",
		"text3": "كەسىپداشلار بانكا دەرىجىسىدىكى RAW ECN نۇقتا پەرقى",
	},
	"pages5": {
		"title": "\u202Eتىن يۇقىرى زاكاز تالونىنى تېخىمۇ ياخشى باھاغا پۈتۈشۈش كېرەك%58",
		"title1": "\u202Eسودا قارا قۇتىسى دەل جايىدا ئەلالاشتۇرۇلغان تالون تاشلاش ئالگورىتمى",
		"btn": " تېخىمۇ كۆپ چۈشىنىش"
	},
	"pages6": {
		"title": "\u202ECBE سودا بوشلۇقى",
		"title1": "\u202Eشەخسىي ئۆي سىزنىڭ سودا ئۇچۇرلىرىڭىزنىڭ بىر قىسمىنى يوشۇرۇپ قالالايدۇ ، يەنى سىزنىڭ زاكازڭىز تېخىمۇ ياخشى ئومۇمىي نەقىلگە ئېرىشىپلا قالماي ، يەنە سودا جەريانىدا ئوغرىلىقچە ئۆتۈشتىن ساقلىنالايدۇ.",
		"btn": " تېخىمۇ كۆپ چۈشىنىش"
	},
	"pages7": {
		"title": "ئېقىشچانلىقى",
		"title1": "\u202Eسۇپىمىزخەلقئارادىكى نۇرغۇن يۇقىرى دەرىجىلىك بانكىلار بىلەن ئۇزاق مۇددەتلىك مۇقىم ھەمكارلىق مۇناسىۋىتى ئورنىتىپ، سىزنى ناھايىتى ئاسانلا ئادەتتىن تاشقىرى تۆۋەن سودا تەننەرخىدىن بەھرىمەن قىلدى.",
		"btn": "تېخىمۇ كۆپ چۈشىنىش"
	},
	"pages8": {
		"btn": "تېخىمۇ كۆپ چۈشىنىش"
	},
	"pages9": {
		"title": "ئومۇميۈزلۈك  كاپالەت	خاتىرجەملىك ",
		"title1": "EBC مەبلەغنىڭ بىخەتەرلىكىنى ئەڭ مۇھىم ئىش دەپ قارايدۇ. خېرىدارلارنىڭ مەبلىغى Barclays بانكىسىدىكى ئايرىم ساقلاش ھېساباتىدا ساقلىنىدۇ. EBC FCA ، پۇل-مۇئامىلە كومىتېتى ، لوندوندىكى للويىد ۋە AON بىلەن ھەمكارلىشىش ئارقىلىق ، EBC ئەتراپلىق ۋە كۆپ قاتلاملىق بىخەتەرلىك مېخانىزمى ئورناتتى.",
		"btn": "بىخەتەرلىك"
	},
	"lang": "cn"
}

