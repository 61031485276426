export default {
	"box-name": "DiNapoli-page-en",
	"section1": {
		"text1": "Joe<br> DiNapoli",
		"text2": "<li>Godfather of Fibonacci</li><li>Founder of DiNapoli Levels</li><li>Recognised as the authoritative figure in the application of the Golden Ratio trading method</li>",
		"text3": "Joe is a veteran trader with over 55 years of real trading experience. He still trades daily. His history includes trading the Nifty 50 on the NYSE in the sixties, predicting the 1987 market crash, and was involved with trading futures immediately after 9/11. Since 1982, when the contract opened, Joe has traded S&P futures. Renowned for his research on Displaced Moving Averages, Advanced Fibonacci techniques, the Oscillator Predictor, and the MACD Predictor, Joe’s creation of Leading Indicators has made him a sought-after expert. As a registered CTA for over 25 years, he has lectured globally and authored the acclaimed book \"Trading with DiNapoli Levels,\" which has been translated into 13 languages around the globe with more to come.",
		"text4": "Trading with DiNapoli Levels",
		"text5": "\"Trading with DiNapoli Levels\" is the most comprehensive book ever published on the practical application of Fibonacci Analysis to the price axis. This authoritative guide offers invaluable insights and techniques for traders, enabling them to enhance their trading strategies with precision and confidence.",
		"text6": "<li>The foremost authoritative work on global golden ratio investment trading</li><li>Due to its recognised performance in practical trading, the book \"Trading with DiNapoli Levels\" is considered one of the greatest trading books of all time </li><li>Translated into over 13 languages worldwide</li>",
		"text7": "Get it for free",
		"text8": "DiNapoli MT4/MT5 Indicators",
		"text9": "DiNapoli's leading indicators are highly favoured by traders worldwide for their efficient trend identification and precise measurement of support and resistance levels. By combining various technical analysis methods, they provide robust decision support for traders, significantly enhancing trading success rates.",
		"text10": "Precise Trend Capture",
		"text11": "The DMA system quickly identifies market trends for timely opportunities.",
		"text12": "Effective Overbought/Oversold Signals",
		"text13": "The oscillator reliably signals market turning points.",
		"text14": "Accurate Support and Resistance",
		"text15": "Fibonacci-based levels optimise trading strategies.",
		"text16": "Top Investors' Endorsements",
		"text17": "All of the great traders I have known for the last so many years that have really made money, have taken your course.",
		"text18": "President of Watseka, Illinois, and Founder of the Stochastic Oscillator",
		"text19": "You can't buy experience, but Joe's book can save you expensive lessons.",
		"text20": "A market expert and trading specialist with over 30 years of industry experience Bressert Trading Group",
		"text21": "His methods in \"Trading with DiNapoli Levels\" are so practical they work equally well in bull and bear markets - equities and futures . New traders can take a few years off their learning curve and old traders CAN learn new tricks.",
		"text22": "LBR Group great trader, author, educator",
		"text23": "Learn More About DiNapoli Levels",
		"text24": "Learn more about DiNapoli Levels and DiNapoli Experts",
		"text25": "Learn more",
	},
}