export default {
  "screen0": {
    "title": "柔軟なPAMMシステム",
    "content": "EBCのPAMMシステムはマネーマネージャーと投資家の双方に利用しやすいシステムになっています。PAMM専用のポータルサイトにアクセスすることで、取引資金管理、収益分配状況、詳細な設定、取引明細のダウンロードなどの機能が揃えています。",
    "tips":"EBCは、ファンドマネージャーと投資家に明確な権利と責任および包括的な機能を備えたパーセンテージアロケーションマネジメントモデル（PAMM）を提供します。Webバージョンの画面がシンプルで、複雑な情報を分かりやすくご覧いただけます。"
  },
  "screen1": {
    "pageEnTitle": "CLEAR DUTIES",
    "pageTitle": "PAMMの仕組み",
    "c1Title": "投資家",
		"c1Desc": "リスク許容度や目標利益額に応じて投資家はファンドマネージャーを自由に選ぶことができます。ファンドマネージャーの成果報酬はハイウォーターマーク方式となっており、投資家にとって有利な報酬体系となります。",
		"c2Title": "マネーマネージャー",
		"c2Desc": "マネーマネージャーは複数の投資家から集められた資金を用いて取引を行います。取引の利益または費用の一部はマネーマネージャーに対する報酬となります。",
    "title": "PAMMアカウントが、多くの人の多様なニーズにお応えします",
    "desc": "投資家：ワンストップな取引サービスによって、ファンドマネージャーと取引結果を共有できます。<br>投資家は、リスク許容度と収益目標に合わせて自由にファンドマネージャーを選択できます。<br>ハイウォーターマーク条項（HWM)によって、ファンドマネージャーにインセンティブを与え、その権利が保護されます。 <br> <br>ファンドマネージャー：豊富な取引経験を持つトレーダーは、PAMMアカウントマネージャーとしての役割をします。<br>ファンドマネージャーは、複数の取引アカウントを同時にマネジメントし、自己資金と投資家から募った資金を利用して取引をして収益を得ることができます。取引による収益またはコストの一部はアカウントマネージャーの報酬として支払われます。"
  },
  "screen2": {
    "pageEnTitle": "SIMPLE IS THE BEST",
    "pageTitle": "明瞭な資金管理",
    "title": "Web版はシンプルな画面で、情報が分かりやすいレイアウトにしています",
    "desc": "PAMM専用のポータルサイトは取引状況やロット数、利益配分、取引記録のダウンロードなどの機能が備わっており、簡単に操作できます。"
  },
  "screen3": {
    "pageEnTitle": "FEATURES AND FUNCTIONS",
    "pageTitle": "PAMMの機能",
    "title": "あらゆる種類の取引およびマネーマネジメントに最適化",
    "text1":"9つの言語に対応",
    "text2":"資金安全の強化とシームレスな取引",
    "text3":"主流の利益分配方法（純資産ベース、残高ベース）",
    "text4":"ハイウォーターマーク方式によるマネーマネージャーの実績促進と投資家の保護の両立",
    "text5":"操作しやすい管理画面",
    "text6":"5つの報酬体系（パフォーマンス報酬、管理報酬、年間コミッション、ロット毎手数料、注文毎手数料）から選択可能"
  },
	"imgUrl": "en",
	"styleName": "en-style en-style2",
	"btn-text": "PAMM を申請する"
}
