export default {
	"fcb-class-name": "cn-fcb-box",
	"video-btn": "观看全视频",
	"fcb-page1": {
		"text1": "OFFICIAL PARTNER OF FC BARCELONA",
		"text2": "EBC成为巴塞罗那足球俱乐部金融领域独家合作伙伴，合作范围为外汇、交易、经纪（包括差价合约）和咨询服务。",
		"text3": "",
	},
	"fcb-page2": {
		"text1": "为热爱 全力以赴",
		"text2": "所有关乎热爱的较量，绝非易事。<br>超乎数字、超越输赢。<br>在推动改变的路上，我们并非孤军奋战。<br>以定义行业的标准，颠覆而来。<br>我们希望用知识、用技术<br>在目标开始的地方不忘初心，全力以赴。",
	},
	"fcb-page3": {
		"text1": "不仅仅是一个俱乐部",
		"text2": "无论您是否为球迷，都知道，它是个传奇。",
		"text3": "巴塞罗那足球俱乐部拥有120多年历史，是世界上最受欢迎的足球俱乐部之一，见证了马拉多纳、梅西、罗纳尔迪尼奥、瓜迪奥拉等多位世界级伟大球星以及教练，以华丽球风独步足坛，成为“不仅仅是一个俱乐部”。",
		"text4": "而EBC，不止于交易，致力于构建全球金融生态综合体，推动全球经济发展。",
	},
	"fcb-page4": {
		"text1": "定义标杆",
		"text2": "群星闪耀的球队、珍视热爱与梦想的训练系统、见证傲人成就的奖杯及对社会的积极贡献，让巴塞罗那足球俱乐部树立了标杆的行业地位。",
		"text3": "EBC与巴萨的合作，见证双方对彼此实力的认可，EBC坚守认真对待每一位交易爱好者的初心，以全球顶级监管，全方位的保障体系，及广受赞誉的国际影响力，定义行业标杆。 ",
	},
	"fcb-page5": {
		"text1": "以技术 创造“宇宙队”",
		"text2": "巴萨以其独特的传控流闻名全球，基于控球、短传和团队协作的战术证明了足球能以高技术和战术智慧取胜，而非仅仅依赖身体对抗，影响了现代足球战术演进。",
		"text3": "EBC在对技术的突破上亦有自身的执着，始终走在国际前沿，为投资者构建安全、专业、高效稳定的全球交易生态。"
	},
	"fcb-page6": {
		"text1": "成为最好的 让世界变更好",
		"text2": "尊重、努力、雄心、团队合作和谦逊是巴萨五大价值观,其始终倡导努力与雄心的价值观和推动球员成为最好的精神，与EBC的价值观不谋而合。",
		"text3": "EBC始终秉承着诚信与尊重的原则，珍视每一位交易爱好者的热爱与期待，倾力透明行业信息，推动行业发展，共建平等社会。",
		"text4": "Together, Let's Build The Future.",
	},
	"fcb-page7": {
		"text1": "相关动态",
	}
}