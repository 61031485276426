export default {
	"leftNav": [{ "id": "#Trading", "name": "Trading" },
		{ "id": "#Regulation", "name": "Regulation" },
		{ "id": "#Security", "name": "Security" },
		{ "id": "#Fintech", "name": "Fintech" },
		{ "id": "#Black-Box", "name": "Black Box" },
		{ "id": "#Private-Room", "name": "Private Room" },
		{ "id": "#Liquidity", "name": "Liquidity" },
		{ "id": "#Honor", "name": "Honour" },
		//{ "id": "#News", "name": "News" }
	],
	"pages1": {
		"h1":"Trust & Respect",
		"title": "Exceptional Brilliant Care for Every Committed Trader",
		"btn": " WATCH FULL VIDEO "
	},
	"pages2": {
		"title": "Professional Liquidity Tailored To Your World",
		"title1": "Exploring a World of Opportunities and Competitive Advantages.",
		"btn": " Trading Product ",
		"text": " Forex ",
		"text1": " Commodities ",
		"text2": " Index CFDs ",
		"text3": " Share CFDs "
	},
	"pages3": {
		"title": "Top-Tier Global Regulations",
		"title1": "EBC Financial Group's subsidiaries are regulated and licensed in their local jurisdictions.",
		"btn": "Regulation of EBC",
		"text": "FCA (UK)",
		"text1": "EBC Financial Group (UK) Ltd is authorised and regulated by the Financial Conduct Authority.Reference Number: 927552",
		"text2": "ASIC (AU)",
		"text3": "EBC Financial Group (Australia) Pty Ltd is authorised and regulated by the Australian Securities and Investments Commission. Reference Number: 500991",
		"text4": "CIMA (CAYMAN)",
		"text5": "EBC Financial Group (Cayman) Ltd is authorised and regulated by the Cayman Islands Monetary Authority (CIMA). Reference Number: 2038223"
	},
	"pages4": {
		"title": "Driving You To Succeed",
		"title1": "Professional And Leading Financial Technology Makes Trading With Infinite Possibilities.",
		"btn": " Explore EBC Tech",
		"text": "ORDERS PROCESSED PER SECOND ",
		"text1": "EXTREME ORDER EXECUTION ",
		"text2": "UPTIME ",
		"text3": "INTERBANK LEVEL OF RAW ECN SPREAD",
	},
	"pages5": {
		"title": "Enabling More Than 85% Of Orders Filled",
		"title1": "EBC Trading Black-Box With Optimum, AccurateSell-Order Algorithm.",
		"btn": " Trading Black-Box"
	},
	"pages6": {
		"title": "EBC Private Room",
		"title1": "Private Room Can Hide Part Of Your Transaction Information, Which Means That Your 'Large Order' May Be Filled At A Better Price Without Serious Slippage Or Rejection Due To Last Look.",
		"btn": " Private Room"
	},
	"pages7": {
		"title": "LIQUIDITY",
		"title1": "EBC Has Established Long-Term And Stable Cooperative Relationships With World's Leading International Banks, Allowing You To Make Transactions Easily At Ultra-Low Costs.",
		"btn": " EBC Liquidity"
	},
	"pages8": {
		"btn": "More News"
	},
	"pages9": {
		"title": "Comprehensive Protection for Peace of Mind",
		"title1": "EBC considers the safety of funds as its top priority. Customer funds are held in segregated custodial accounts at Barclays Bank. By collaborating with the FCA, Financial Commission, Lloyds of London, and AON, EBC has established a comprehensive and multi-layered security mechanism.",
		"btn": "Security"
	},
	"lang": "en"
}

