export default {
	"lang": "cn",
	"screen0": {
		"title": "金融世界<br />触手可及",
		"desc": "There is no Trading Secret!<br>Only to find what works best for you and applying it with strick risk management",
		"quick": "快速了解",
		"quick1": "4种资产类别",
		"quick2": "100+交易品种",
		"trList": [
			"层级",
			"买入价",
			"买入的交易量",
			"点差",
			"卖出价",
			"卖出价交易量"
		],
		"smallTrList": [
			"层级",
			"买入价",
			"点差",
			"卖出价"
		],
		"btn": "开始投资"
	},
	"screen1": {
		"pageEnTitle": "MT4 Trading Platform",
		"pageTitle": "MT4 交易平台",
		"desc": "长久以来，MetaTrader 4被广泛视为全球零售衍生品交易平台的行业基准，迄今为止最受欢迎的在线交易平台。",
		"title": "满足各种需求的交易平台 Metatrader 4",
		"desc1": "EBC MetaTrader4平台提供了直观的、高度定制化的用户界面，以及强化的图表功能和精细的订单管理、分析和警报工具，是您随时随地实时与市场保持同步的理想方式。"
	},
	"screen2": {
		"num1": "3种",
		"text1": "不同的图表类型",
		"num2": "9种",
		"text2": "时间范围",
		"num3": "50种",
		"text3": "预安装的技术指标",
		"title": "交易者可以通过访问3种不同的图表类型，9个时间范围，超过50种预安装的技术指标以及完全可定制的界面，这意味着无论您使用哪种交易风格或系统，都能轻松实现高效地头寸管理和精确地市场监控。",
		"desc": "无论何时何地，您都可以通过我们 Web 浏览器, 或PC端/移动设备快速 下载 MetaTrader 4，直通全球资本市场。",
		"btnText": "想更了解更多交易平台？",
		"btn": "Lab 学习中心 / 交易平台板块",
		"smallBtn": "Lab 学习中心"
	},
	"screen3": {
		"title": "有机会驾驭的人生，即刻启程。",
		"desc": " EBC 也与 MetaQuotes 一起努力调整我们的光纤网络和服务器，给交易者可大幅提高执行速度和所有资产类别的透明报价",
		"link1": "展示交易时段",
		"link2": "查看点差与全部商品",
		"list": [{
			"title": "全面的市场覆盖",
			"desc": "访问 100+资本市场产品，包括衍生品、股票、指数、商品差价合约等"
		}, {
			"title": "极致的灵活性",
			"desc": "登录一次即可从任何地方访问您的MT4账户。移动版本提供的功能包括交互式报价图表，全套交易订单以及流行的分析工具"
		}, {
			"title": "实时透明的报价",
			"desc": "在交易中，每一毫秒都很重要。由银行同业及交易买卖系统提供实时报价，高效透明"
		}, {
			"title": "先进的图表工具",
			"desc": "先进的图表功能使您能够通过价格判断更快更准地做出投资决策"
		}, {
			"title": "即时新闻",
			"desc": "全球各个资本市场的即时新闻让您掌握第一手市场资讯"
		}, {
			"title": "快速执行订单",
			"desc": "高速的买卖和即时成交交易指令。无论您以市价或挂单交易，均可利用止损以控制亏损或是止盈以保护利润。支持所有类型的交易订单，包括市场订单、挂单、止损订单以及追踪止损"
		}, {
			"title": "在线查阅账户",
			"desc": "您可以随时查阅自己的持仓、余额及盈亏等信息及状况，当前资产状况一目了然"
		}, {
			"title": "安全可靠",
			"desc": "平台与服务器之间的所有数据均采用高达256位数据加密，EBC为客户营造一个安全可靠的交易环境"
		}],
		"btn": "马上下载"
	},
	"screen4": {
		"pageEnTitle": "MT4 Trading Platform",
		"pageTitle": "MT4 交易平台",
		"title": "从单一账户交易几乎所有资本市场产品",
		"desc": "探索MT4平台的优势",
		"list": [{
				"title": "MT4 移动端下载",
				"desc": "通过MT4移动应用程序控制您的交易，绝不会错过任何交易机会。"
			},
			{
				"title": "MT4 桌面版下载",
				"desc": "在屡获殊荣的MT4平台体验EBC超快的执行速度和强大的交易功能。"
			},
			{
				"title": "MT4 网页端",
				"desc": "通过网页浏览器在世界上任何一个地方都可以登陆我们的MT4。"
			}
		],
		"text1": "下载我们",
		"text2": "MT4用户指南",
		"text3": "，更深入地了解MT4所有功能",
		"text4": "指导您轻松安装全球最受欢迎的交易终端",
		"btn": "开始投资"
	},
	"section1": {
		"title": "毫秒必达 &nbsp;交易全球",
	},
	"section2": {
		"title": "满足各种需求的交易平台",
		"desc": "EBC MetaTrader4提供了简捷、定制化的用户界面，以全面的图表功能和强大的分析工具，帮助您随时随地与市场保持同步，实现精细化的订单管理。 全球30多个国家、70%交易平台的选择，承载全球90%的零售衍生品交易量，Metatrader 4是迄今最受欢迎的在线交易软件。",
	},
	"section3": {
		"title": "什么是MetaTrader 4？",
		"desc1": "MetaTrader 4 (MT4)是MetaQuotes Software Corporation开发的一款当今世界最受欢迎的交易平台。由于该平台的技术先进，易于使用，安全系数高，受到了全球交易者的普遍认可。MT4最主要的功能是允许用户方便地实现对市场的检测，使用戶能够及时有效的进场与退场、限价和止损订单。",
		"desc2": "选择EBC的MT4平台，不仅意味着您可以实现技术指标和EAs的定制化，而且您还能够选择包括货币、贵金属、原油、股票、指数等一系列交易产品。"
	},
	"section4": {
		"title": "MT4 常见问题",
		"list": [{
			"title": "我已下载MT4，如何登录我的真实账户？",
			"desc": "成功存入资金后，账户就会激活，系统会为您提供交易账户、密码和服务器数据。您只需要输入给定账户和密码，选择正确的服务器，即可登录您的的真实账户。"
		},
		{
			"title": "如何在MT4上更改密码？",
			"desc": "在登录MT4平台后点击工具—选项，然后在服务器栏即可修改密码。"
		},
		{
			"title": "如何在MT4上插入图表指标？",
			"desc": "请执行以下步骤：<br>1. 选择“插入”->顶部菜单上的“技术指标”；<br>2. 或者可以通过“导航”插入“技术指标”。"
		},
		{
			"title": "如何创建对趋势线或其他动态的研究？",
			"desc": "请执行以下步骤：<br>1. 在顶部菜单上选择「插入」；<br>2. 选择您想要的趋势线；<br>3. 释放点击，十字线图标会出现选择的趋势线。在选定的图表上移动十字线，点击鼠标并按住点击，移动鼠标调整趋势线的大小。也可以通过点击「浏览」->「工具栏」->「线型研究」快速使用趋势线研究快捷键，图表会出現在菜单栏下方。您可以通过点击趋势线、十字线或斐波那契回调线选择屏幕上方的其他选项。"
		},
		{
			"title": "如何在MT4上选择用户语言？",
			"desc": "前往顶部菜单选择查看->语言->例如，简体中文，然后重启软件，用户语言就会变成简体中文。"
		}
		],
	},
	"btn-text": "即刻下载MT4",
	"download-btn1": "下载Android版",
	"download-btn2": "下载iOS版",
	"download-btn3": "下载iPad版",
	"download-btn4": "下载Windows版",
	"download-btn5": "下载MacOS版",
	"download-btn6": "网页版MT4",
}